import { Image, Select, SelectItem } from "@nextui-org/react";
import { useAuth } from "../../../../../../utils/AppwriteConfig/AuthContext";
import { useEffect, useState } from "react";
import axios from "axios";

export default function Completed({
  users = [],
  currentGradeLevel = 0,
  loadingstate,
  updateParent
}) {
  const { server, DataServer, user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [isrefresh, setisrefresh] = useState(false);

  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedCourseName, setSelectedCourseName] = useState("");
  const [enroll, setenroll] = useState({});

  useEffect(() => {
    loadingstate(isLoading);
  }, [isLoading]);

  useEffect(() => {
    const CselectedCourse = {
      id: selectedCourse || "",
      name: selectedCourseName || ""
    };
    updateParent(CselectedCourse);
  }, [selectedCourse, selectedCourseName]);

  //Renders courses
  useEffect(() => {
    setIsLoading(true);
    async function fetchCourses() {
      try {
        const response = await axios.get(`${DataServer}/course/toselect`);
        console.log(response, "g");
        setCourses(response.data.documents);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchCourses();
  }, [DataServer, isrefresh]);

  return (
    <div>
      <div className="flex-1 h-full p-4 overflow-auto">
        <div className="flex flex-col flex-1 justify-center items-center h-full ">
          <Image
            isZoomed
            width={240}
            alt="NextUI Fruit Image with Zoom"
            src={require("../../../../../../Img/course.jpg")}
          />
          <span className="text-xl">
            Move {users.length} {users.length > 1 ? "students" : "student"} up
            to
          </span>
          <Select
            label="Course"
            placeholder="Select a Course"
            className="max-w-xs my-2"
            value={selectedCourse}
            onSelectionChange={(value) => {
              if (value && value.size > 0) {
                // Convert the Set to an array and get the first item
                const selectedCourseId = [...value][0];
                setSelectedCourse(selectedCourseId);
              } else {
                // Handle deselection
                setSelectedCourse(null); // or whatever value represents "no selection"
              }
            }}
          >
            {courses.map((course) => (
              <SelectItem
                onClick={() => {
                  setSelectedCourseName(course.courseName);
                }}
                key={course.$id}
                value={course.$id}
              >
                {course.courseName}
              </SelectItem>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
}
