import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  SelectSection,
  Select,
  SelectItem,
  Skeleton
} from "@nextui-org/react";
import StudentTable from "../components/renders/StudentTable";
import { useAuth } from "../../../../utils/AppwriteConfig/AuthContext";
import axios from "axios";

export default function AssignEnrolledStudents({
  Course,
  children,
  isrefreshed,
  refreshparent
}) {
  const { role, server, user } = useAuth();



  const { isOpen, onOpen, onClose, onOpenChange } = useDisclosure();
  const [isLoading, setisLoading] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [MainError, setMainError] = useState();

  const [selectedStudents, setSelectedStudents] = useState([]);
  const [selectedStudentsData, setSelectedStudentsData] = useState([]);

  const [Sections, setSections] = useState([]);
  const [sectionName, setSectionName] = useState();
  const [SelectedSection, setSelectedSection] = useState(new Set([]));
  const [studentsection, setstudentsection] = useState();

  const [value, setValue] = useState(new Set([]));
  const [touched, setTouched] = useState(false);

  const isValid = true;

  useEffect(() => {
    setstudentsection({
      course: Course.$id,
      courseName: Course.courseName,
      section: SelectedSection.anchorKey,
      sectionName: sectionName,
      selectedStudents,
      creator: user.$id
    });
  }, [Course, SelectedSection, selectedStudents, sectionName]);

  useEffect(() => {
    
  }, [studentsection]);

  //Gets all section
  useEffect(() => {
    async function getsections() {
      try {
        setisLoading(true);
        const response = await axios.get(`${server}/v1/course/sections`, {
          params: { CourseId: Course.$id }
        });
      
        setSections(response.data);
      } catch (e) {
        setMainError(e.message);
      } finally {
        setRefresh(!refresh);
        setisLoading(false);
      }
    }
    getsections();
  }, [Course]);

  const handleAssignStudents = async () => {
    if (true) {
      setisLoading(true);
      try {
       
        const response = await axios.post(
          `${server}/v1/students/assign`,
          studentsection
        );
   
      } catch (e) {
        setMainError(e.message);
      } finally {
        setRefresh(!refresh);
        setisLoading(false);
        setSelectedStudents([]);
        refreshparent();
      }
    } else {
      console.log("Errors found:");
      
    }
  };

  return (
    <>
      <button color="secondary" className=" font-poppins" onClick={onOpen}>
        {children}
      </button>

      <Modal
        backdrop="opaque"
        isDismissable={true}
        isKeyboardDismissDisabled={true}
        isOpen={isOpen}
        size="lg"
        className="font-poppins"
        onOpenChange={onOpenChange}
        motionProps={{
          variants: {
            enter: {
              y: 0,
              opacity: 1,
              transition: {
                duration: 0.3,
                ease: "easeOut"
              }
            },
            exit: {
              y: -20,
              opacity: 0,
              transition: {
                duration: 0.2,
                ease: "easeIn"
              }
            }
          }
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex gap-1 pb-0 items-center">
                Assign Students
              </ModalHeader>
              <ModalBody className=" space-y-1">
                <Skeleton
                  className=" rounded-md p-2 pb-0"
                  isLoaded={!isLoading}
                >
                  <Select
                    labelPlacement="outside"
                    label="Section"
                    placeholder="Assign to"
                    className="w-full"
                    errorMessage={
                      isValid || !touched ? "" : "You must select a cat"
                    }
                    isInvalid={isValid || !touched ? false : true}
                    selectedKeys={SelectedSection}
                    onSelectionChange={setSelectedSection}
                    onClose={() => setTouched(true)}
                  >
                    {Sections.map((section) => (
                      <SelectItem
                        onClick={() => {
                          setSectionName(section.sectionName);
                        }}
                        key={section.$id}
                        value={section.$id}
                      >
                        {section.sectionName}
                      </SelectItem>
                    ))}
                  </Select>
                </Skeleton>
                <StudentTable
                  course={Course.$id}
                  SelectedStudents={setSelectedStudents}
                  SelectedStudentsData={setSelectedStudentsData}
                  isrefreshed={refresh}
                  type="unassignedstudents"
                />
              </ModalBody>
              <ModalFooter>
                {isLoading !== true && (
                  <Button color="danger" variant="light" onPress={onClose}>
                    Close
                  </Button>
                )}
                {isLoading ? (
                  <Button
                    isLoading
                    color="secondary"
                    spinner={
                      <svg
                        className="animate-spin h-5 w-5 text-current"
                        fill="none"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          fill="currentColor"
                        />
                      </svg>
                    }
                  >
                    Processing...
                  </Button>
                ) : (
                  <>
                    <Button
                      color="secondary"
                      isDisabled={
                        !selectedStudents.length > 0 ||
                        !SelectedSection.size > 0
                      }
                      onPress={handleAssignStudents}
                    >
                      Assign to {SelectedSection?.sectionName}
                    </Button>
                  </>
                )}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
