import React from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure
} from "@nextui-org/react";

export default function CancelModal({
  CancelTitle = "Title",
  CancelBody = "Body",
  CancelButtonTitle = "Button",
  CancelActionFunction,
  ModalOpenButtonTitle = "ButtonTItleModal",
  isIcon = false,
  data
}) {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  return (
    <>
      <Button
        isIconOnly={isIcon}
        className=" bg-[#FFDE59] text-xs"
        onPress={onOpen}
      >
        {ModalOpenButtonTitle}
      </Button>
      <Modal
        className=" font-poppins"
        isOpen={isOpen}
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex pb-1 flex-col gap-1">
                {CancelTitle}
              </ModalHeader>
              <ModalBody>{CancelBody}</ModalBody>
              <ModalFooter className=" pt-1">
                <Button color="danger" variant="light" onPress={onClose}>
                  cancel
                </Button>
                <Button
                  color="secondary"
                  onPress={() => {
                    if (
                      CancelActionFunction(
                        data.teacherId,
                        data.teacherName,
                        data.course,
                        data.section
                      )
                    ) {
                      onClose();
                    }
                  }}
                >
                  {CancelButtonTitle}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
