import {
  Button,
  Avatar,
  Card,
  CardBody,
  CardFooter,
  Image,
  Switch
} from "@nextui-org/react";

import ScheduleTable from "./components/ScheduleTable";
import { useEffect, useState } from "react";
import ActiveSchedules from "./components/renders/ActiveSchedules";
import InActiveSchedule from "./components/renders/InActiveSchedules";
import { useNavigate, useNavigation } from "react-router-dom";
import GenerateSchedConfigs from "./components/GenerateSchedConfigs";
import OldSchedules from "./components/renders/OldSchedules";
import ResponsiveToolTip from "../../../Components/ResponsiveTooltip";
import PerDepartmentView from "./PerDepartmentView";
import ScheduleStatistics from "./components/StatisticsSchedule";
import { useAuth } from "../../../utils/AppwriteConfig/AuthContext";
import axios from "axios";
import useRealtimeSubscription from "../../../utils/AppwriteConfig/RealtimeServices";
import RequestOffCanvas from "./components/RequestCanvas";

export default function Schedule() {
  const navigate = useNavigate();

  const { SchedulingServer } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [refresher, setIsRefresher] = useState(false);
  const [MainError, setMainError] = useState("");

  const [Status, setStatus] = useState({
    hasActiveSchedule: false,
    hasAllDeptScheduled: false
  });

  const [dept, setDept] = useState({});
  const [academicYear, setAcademicYear] = useState("");

  useEffect(() => {
    async function getSectionSchedules() {
      try {
        setIsLoading(true);
        const sectionResponse = await axios.get(
          `${SchedulingServer}/schedule/current/active`
        );

        setStatus(sectionResponse.data);
      } catch (error) {
        setMainError(
          error?.response?.data?.error
            ? error.response.data.error
            : error.message
        );
      } finally {
        setIsLoading(false);
      }
    }

    getSectionSchedules();
  }, [refresher]);

  const handleRealtimeMessage = (message) => {
    console.log("Received Realtime message:", message);
    setIsRefresher(!refresher);
  };
  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_TEACHER,
    handleRealtimeMessage
  );

  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_GROUP_SCHED,
    handleRealtimeMessage
  );

  return (
    <div className=" w-full h-full flex flex-col flex-1 p-2  rounded-lg">
      <div className=" max-h-full h-full max-w-full w-full  ">
        <div className="  flex-initial flex">
          <div className="flex justify-between items-center flex-1">
            <span className=" text-4xl  font-semibold text-[#72539b]">
              Schedules
            </span>
          </div>
          <div className=" flex-initial"></div>
        </div>

        <div className="  min-h-[400px] p-2 rounded-lg">
          <div className={`flex sm:hidden `}>
            <Button
              onPress={() => {
                document.querySelector("#Configs").click();
              }}
              className="bg-[#ffde59] flex-1"
            >
              Generate Schedule
            </Button>
          </div>
          <div className="flex sm:flex-row flex-col justify-between items-center border-b flex-1 border-gray-200">
            <div class=" dark:border-neutral-700 max-w-full w-full overflow-auto">
              <nav class="flex space-x-1" aria-label="Tabs" role="tablist">
                <button
                  type="button"
                  class="hs-tab-active:font-semibold hs-tab-active:border-secondary hs-tab-active:text-secondary py-4 px-1 inline-flex items-center gap-x-2 border-b-2 border-transparent text-sm whitespace-nowrap text-gray-500 hover:text-secondary focus:outline-none focus:text-secondary disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-400 dark:hover:text-blue-500 active"
                  id="tabs-with-badges-item-4"
                  data-hs-tab="#tabs-with-badges-4"
                  aria-controls="tabs-with-badges-4"
                  role="tab"
                >
                  Statistics{" "}
                </button>

                <button
                  type="button"
                  class="hs-tab-active:font-semibold hs-tab-active:border-secondary hs-tab-active:text-secondary py-4 px-1 inline-flex items-center gap-x-2 border-b-2 border-transparent text-sm whitespace-nowrap text-gray-500 hover:text-secondary focus:outline-none focus:text-secondary disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-400 dark:hover:text-blue-500"
                  id="tabs-with-badges-item-1"
                  data-hs-tab="#tabs-with-badges-1"
                  aria-controls="tabs-with-badges-1"
                  role="tab"
                >
                  Active Schedules{" "}
                </button>

                <button
                  type="button"
                  class="hs-tab-active:font-semibold hs-tab-active:border-secondary hs-tab-active:text-secondary py-4 px-1 inline-flex items-center gap-x-2 border-b-2 border-transparent text-sm whitespace-nowrap text-gray-500 hover:text-secondary focus:outline-none focus:text-secondary disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-400 dark:hover:text-blue-500"
                  id="tabs-with-badges-item-3"
                  data-hs-tab="#tabs-with-badges-3"
                  aria-controls="tabs-with-badges-3"
                  role="tab"
                >
                  Old Schedules{" "}
                </button>
              </nav>
            </div>
            <div className="sm:flex hidden">
              <GenerateSchedConfigs
                department={dept}
                academicyear={academicYear}
              />
            </div>
          </div>

          <div class="mt-3">
            <div
              id="tabs-with-badges-1"
              role="tabpanel"
              aria-labelledby="tabs-with-badges-item-1"
              class="hidden space-y-4 w-full flex-col  "
            >
              <RequestOffCanvas />
              <div>
                <PerDepartmentView
                  department={{ code: "Pre", name: "Pre-School" }}
                  AcademicYear={academicYear}
                  isOld={false}
                  groupId={Status.groupId}
                />
                <PerDepartmentView
                  department={{ code: "Elem", name: "Elementary" }}
                  AcademicYear={academicYear}
                  isOld={false}
                  groupId={Status.groupId}
                />
                <PerDepartmentView
                  department={{ code: "JHS", name: "Junior High-School" }}
                  AcademicYear={academicYear}
                  isOld={false}
                  groupId={Status.groupId}
                />
                <PerDepartmentView
                  department={{ code: "SHS", name: "Senior High-School" }}
                  AcademicYear={academicYear}
                  isOld={false}
                  groupId={Status.groupId}
                />
              </div>
            </div>

            <div
              id="tabs-with-badges-4"
              role="tabpanel"
              aria-labelledby="tabs-with-badges-item-4"
            >
              <ScheduleStatistics
                ScheduleStatus={Status}
                setParent={setDept}
                setAcademic={setAcademicYear}
              />
            </div>

            <div
              id="tabs-with-badges-3"
              class="hidden"
              role="tabpanel"
              aria-labelledby="tabs-with-badges-item-3"
            >
              <OldSchedules AcademicYear={academicYear} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
