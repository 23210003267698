import { Select, SelectItem, Spinner } from "@nextui-org/react";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import { useEffect, useState } from "react";
import axios from "axios";

export default function SectionFilter({ select }) {
  const { server } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [mainError, setMainError] = useState(null);
  const [info, setInfo] = useState([]);
  const [value, setValue] = useState("");

  useEffect(() => {

  }, [value]);

  useEffect(() => {
    async function getSections() {
      try {
        setIsLoading(true);
        const infoResponse = await axios.get(`${server}/v1/course/allsections`);
      
        setInfo(infoResponse.data.documents);
      } catch (e) {
        setMainError(e.message);
      } finally {
        setIsLoading(false);
      }
    }
    getSections();
  }, [refresh, server]); // Added server to the dependency array
  const [selectedValue, setSelectedValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (section) => {
    setSelectedValue(section.sectionName);
    select(section);
    setIsOpen(false);

  };
  return (
    <div className=" flex w-full justify-end gap-2">
      {isLoading && <Spinner size="sm" color="secondary" />}
      <div className="relative max-w-72 w-full">
        <div
          className="border border-gray-300 rounded-lg p-2 bg-white cursor-pointer"
          onClick={() => setIsOpen(!isOpen)}
        >
          {selectedValue || "Select a section"}
        </div>
        {isOpen && (
          <div className="absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded-lg max-h-48 overflow-y-auto">
            {info.length === 0 ? (
              <div className="p-2 text-gray-500">No sections yet</div>
            ) : (
              info.map((section) => (
                <div
                  key={section.sectionName}
                  className="p-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    handleSelect(section);
                  }}
                >
                  {section?.course?.courseName} | {section?.sectionName}
                </div>
              ))
            )}
          </div>
        )}
      </div>
    </div>
  );
}
