import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Checkbox,
  Input,
  Link,
  Select,
  SelectItem,
  Image
} from "@nextui-org/react";
import { useAuth } from "../../utils/AppwriteConfig/AuthContext";
import axios from "axios";
import { GetCurrentAcademicYear } from "../../utils/helpers/stringformat";
import useRealtimeSubscription from "../../utils/AppwriteConfig/RealtimeServices";
import { messaging } from "../../utils/AppwriteConfig/Config";
import { ID } from "appwrite";

export default function Enrollstudents({ users, reload, refreshparent }) {
  const { server, DataServer, user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [isrefresh, setisrefresh] = useState(false);
 
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();

  const [courses, setCourses] = useState([]);
  const [sections, setSections] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState();
  const [selectedCourseName, setSelectedCourseName] = useState();
  const [selectedSection, setSelectedSection] = useState();
  const [selectedSectionName, setSelectedSectionName] = useState();
  const [enroll, setenroll] = useState({});

  useEffect(() => {
   
  }, [selectedCourseName]);

  //Renders courses
  useEffect(() => {
    setIsLoading(true);
    async function fetchCourses() {
      try {
        const response = await axios.get(`${DataServer}/course/toselect`);
       
        setCourses(response.data.documents);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchCourses();
  }, [DataServer, isrefresh]);

  //set enrollment info
  useEffect(() => {
    if (selectedCourse) {
    
      const selectedCourseObject = courses.find(
        (course) => course.$id === selectedCourse
      );
      const selectedSectionObject = sections.find(
        (section) => section.$id === selectedSection
      );
   
      if (selectedCourseObject) {
        let sec = "";
        if (selectedSectionObject) {
          sec = selectedSectionObject;
        }

        setenroll({
          selectedCourse,
          selectedCourseName,
          selectedSection,
          selectedSectionName,
          users,
          creator: user.$id,
          academicYear: GetCurrentAcademicYear()
        });
      }
    } else {
   
      setenroll();
    }
  }, [
    selectedCourse,
    selectedSection,
    courses,
    sections,
    selectedCourseName,
    selectedSectionName
  ]);

  //disable sections if the course is not selected
  useEffect(() => {
    if (selectedCourse) {
      const course = courses.find((course) => course.$id === selectedCourse);

      if (course) {
        setSections(course.section);
      }
    } else {
      setSections([]);
    }
  }, [selectedCourse, courses]);

  useEffect(() => {
    
  }, [enroll]);

  const handleEnrollstudents = async () => {
  
    if (enroll) {
      setIsLoading(true);
      try {
        const response = await axios.post(
          `${server}/v1/students/enroll`,
          enroll
        );

        setIsLoading(false);
        refreshparent();
        onClose();
      } catch (e) {
        console.log(e);
      }
    }
  };

  //Get realtime updates
  const handleRealtimeMessage = (message) => {
    setisrefresh(!isrefresh);
    // Handle the Realtime message as needed
  };

  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_COURSE,
    handleRealtimeMessage
  );
  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_SECTION,
    handleRealtimeMessage
  );

  return (
    <>
      <Button
        onPress={onOpen}
        className="bg-[#FFDE59] ml-1 hidden sm:flex rounded-lg text-black font-semibold hover:bg-[#fcd84b] overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
      >
        Enroll Students
      </Button>
      <Button
        onPress={onOpen}
        isIconOnly
        className="bg-[#FFDE59] ml-1 rounded-lg flex sm:hidden text-black font-semibold hover:bg-[#fcd84b] overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-person-plus-fill"
          viewBox="0 0 16 16"
        >
          <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
          <path
            fill-rule="evenodd"
            d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5"
          />
        </svg>
      </Button>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        className=" z-50"
        placement="top-center"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Enroll Student to
              </ModalHeader>
              <ModalBody>
                <div className="flex-1 h-full p-4 overflow-auto">
                  <div className="flex flex-col flex-1 justify-center items-center h-full ">
                    <Image
                      isZoomed
                      width={240}
                      alt="NextUI Fruit Image with Zoom"
                      src={require("../../Img/course.jpg")}
                    />
                    <span className="text-xl">
                      Enroll {users.length}{" "}
                      {users.length > 1 ? "students" : "student"} ?
                    </span>
                    <Select
                      label="Course"
                      placeholder="Select a Course"
                      className="max-w-xs my-2"
                      value={selectedCourse}
                      onSelectionChange={(value) => {
                        if (value && value.size > 0) {
                          // Convert the Set to an array and get the first item
                          const selectedCourseId = [...value][0];
                          setSelectedCourse(selectedCourseId);
                          setSelectedSection();
                        } else {
                          // Handle deselection
                          setSelectedCourse(null); // or whatever value represents "no selection"
                          setSelectedSection(null); // clear the selected section as well if needed
                        }
                      }}
                    >
                      {courses.map((course) => (
                        <SelectItem
                          onClick={() => {
                            setSelectedCourseName(course.courseName);
                          }}
                          key={course.$id}
                          value={course.$id}
                        >
                          {course.courseName}
                        </SelectItem>
                      ))}
                    </Select>
                    <Select
                      label="Section"
                      placeholder="Select a Section"
                      className="max-w-xs"
                      onSelectionChange={(value) => {
                        // Convert the Set to an array and get the first item
                        const data = [...value][0];
                        setSelectedSection(data);
                      }}
                      isDisabled={!selectedCourse}
                    >
                      {sections &&
                        sections.length > 0 &&
                        sections.map((section) => (
                          <SelectItem
                            key={section.$id}
                            onClick={() => {
                              setSelectedSectionName(section.sectionName);
                            }}
                            value={section.$id}
                          >
                            {section.sectionName}
                          </SelectItem>
                        ))}
                    </Select>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="flat" onPress={onClose}>
                  Close
                </Button>
                {isLoading ? (
                  <Button
                    isLoading
                    color="secondary"
                    spinner={
                      <svg
                        className="animate-spin h-5 w-5 text-current"
                        fill="none"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          fill="currentColor"
                        />
                      </svg>
                    }
                  >
                    Loading
                  </Button>
                ) : (
                  <Button
                    color="secondary"
                    isDisabled={!selectedCourse}
                    onPress={handleEnrollstudents}
                  >
                    Enroll Students
                  </Button>
                )}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
