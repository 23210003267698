import Dashcard from "./renders/D_Dashcard";
import { useAuth } from "../../../../utils/AppwriteConfig/AuthContext";

export default function Statistics() {
  const { user } = useAuth();
  return (
    <div className="flex-initial hidden lg:flex flex-col w-full  p-2">
      <div className=" flex-1 flex">
        <div className="w-full">
          <div class="max-w-[85rem] ">
            <div class="grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-3 sm:gap-6">
              <Dashcard
                info={{
                  user: "student",
                  title: "Total Students",
                  description: "Total number of BSA students",
                }}
              />
              <Dashcard
                info={{
                  user: "teacher",
                  title: "Total Teachers",
                  description: "Total number of BSA teachers",
                }}
              />

              {/* Show Employee Dashcard only for Adminhead and Registrar */}
              {(user.labels.includes("Adminhead") || user.labels.includes("Registrar")) && (
                <Dashcard
                  info={{
                    user: "employee",
                    title: "Total Employees",
                    description: "Total number of BSA employees",
                  }}
                />
              )}

              {/* Show Account Dashcards for Adminhead and Registrar */}
              {user.labels.includes("Adminhead") && (
                <>
                  <Dashcard
                    info={{
                      user: "account",
                      title: "Total Accounts",
                      description: "Total number of BSA Accounts",
                    }}
                  />
                  <Dashcard
                    info={{
                      user: "archived",
                      title: "Total Archived Accounts",
                      description: "Total number of BSA archived Accounts",
                    }}
                  />
                </>
              )}

              {user.labels.includes("Registrar") && (
                <>
                  <Dashcard
                    info={{
                      user: "account",
                      title: "Total Accounts",
                      description: "Total number of BSA Accounts",
                    }}
                  />
                  <Dashcard
                    info={{
                      user: "archived",
                      title: "Total Archived Accounts",
                      description: "Total number of BSA archived Accounts",
                    }}
                  />
                </>
              )}
              
              {user.labels.includes("Scheduler") && (
                <>
                  <Dashcard
                info={{
                  user: "schedule",
                  title: "Active Schedules",
                  description: "Total number of active schedules",
                }}
              />
              <Dashcard
                info={{
                  user: "event",
                  title: "Events in a Year",
                  description: "Total number of events in the year",
                }}
              />
              <Dashcard
                info={{
                  user: "undertime",
                  title: "Undertime Teachers",
                  description: "Total number of undertime teachers",
                }}
              />
                </>
              )}
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
