export function AlinkY({ children = 'Link',size ='text-sm' }) {
  return (
    <a className={`${size} font-medium hover:text-[#FFDE59] cursor-pointer dark:text-gray-400 dark:hover:text-gray-500 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600`}>
      {children}
    </a>
  );
}
export function AlinkP({ children = 'Link',size  ='text-sm' }) {

    return (
      <a className={`${size} font-medium hover:text-[#A16AE8] cursor-pointer dark:text-gray-400 dark:hover:text-gray-500 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600`}>
        {children}
      </a>
    );
    
  }
