import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Divider,
  Link,
  Image,
  Button
} from "@nextui-org/react";
import TodoRender from "./renders/TodoRender";
import { useAuth } from "../../../../utils/AppwriteConfig/AuthContext";
import { useEffect, useState } from "react";
import axios from "axios";
import NewTodo from "../actions/NewTodo";
import Todoskeleton from "../../../../Components/skeleton/Todo";
export default function ToDolist() {
  const { server, user } = useAuth();
  const [isLoading, setisLoading] = useState(true);
  const [isrefresh, setisrefresh] = useState(true);
  const [Todo, setTodo] = useState();
  useEffect(() => {
    setisLoading(true);
    async function fetchsubj() {
      try {
        const response = await axios.get(`${server}/v1/todos`, {
          params: { id: user.$id }
        });
       
        setTodo(response.data);
      } catch (error) {
        // Handle any errors
        console.error(error);
      } finally {
        setisLoading(false);
      }
    }
    fetchsubj();
  }, [user, isrefresh]);

  const refresh = () => {
    setisrefresh(!isrefresh);
    setisLoading(false);
  };
  return (
    <Card className="rounded-md shadow-sm h-full">
      <CardHeader className="flex gap-3 justify-between">
        <div className="flex gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="lucide lucide-list-todo"
          >
            <rect x="3" y="5" width="6" height="6" rx="1" />
            <path d="m3 17 2 2 4-4" />
            <path d="M13 6h8" />
            <path d="M13 12h8" />
            <path d="M13 18h8" />
          </svg>
          <p className="text-xl">To-do</p>
        </div>
        <NewTodo reload={refresh} />
      </CardHeader>
      <Divider />
      <CardBody className="flex w-full flex-1 overflow-auto">
        {isLoading ? (
          <>
            {/* Render skeleton cards when loading */}
            <Todoskeleton />
            <Todoskeleton />
            <Todoskeleton />
          </>
        ) : (
          <>
            {Todo && Todo.documents && Todo.documents.length > 0 ? (
              Todo.documents.map((todo) => (
                <TodoRender
                  key={todo.$id}
                  tododetails={todo}
                  rerenderparent={refresh}
                  loading={setisLoading}
                />
              ))
            ) : (
              <div className=" h-full flex justify-center items-center ">
                No Todo yet
              </div>
            )}
          </>
        )}
      </CardBody>
    </Card>
  );
}
