import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner
} from "@nextui-org/react";
import AcceptModal from "./AcceptModal";
import { useAuth } from "../../../../utils/AppwriteConfig/AuthContext";
import axios from "axios";

function detectUserType(record) {
  const id = record.id?.toString();
  if (id?.startsWith("2")) return "student";
  if (id?.startsWith("4")) return "teacher";
  if (id?.startsWith("6")) return "employee";
  return null;
}

function validateRecord(record, requiredFields) {
  const errors = [];
  requiredFields.forEach((field) => {
    const value = record[field];
    if (value == null || value.toString().trim() === "") {
      errors.push(`${field} is missing`);
    } else if (
      field === "age" &&
      (isNaN(Number(value)) || Number(value) <= 0)
    ) {
      errors.push(`${field} must be a positive integer`);
    } else if (field === "birthDate" && isNaN(new Date(value).getTime())) {
      errors.push(`${field} must be a valid date (MM/DD/YYYY)`);
    } else if (field === "contactNumber" && !/^\+?[0-9]{7,15}$/.test(value)) {
      errors.push(`${field} must be a valid phone number`);
    } else if (
      field === "teachingHoursPerWeek" &&
      (!Number.isInteger(Number(value)) || Number(value) < 0)
    ) {
      errors.push(`${field} must be a non-negative integer`);
    } else if (
      field === "teachingExperience" &&
      !Number.isInteger(Number(value))
    ) {
      errors.push(`${field} must be an integer`);
    } else if (field === "email" && !value.endsWith("@bsa.online.ph")) {
      errors.push(`${field} must end with @bsa.online.ph`);
    } else if (
      field === "department" &&
      !["Elem", "Pre", "JHS", "SHS"].includes(value)
    ) {
      errors.push(`${field} must be one of "Elem", "Pre", "JHS", or "SHS"`);
    } else if (
      field === "role" &&
      !["Adminhead", "Scheduler", "Registrar"].includes(value)
    ) {
      errors.push(
        `${field} must be one of "Adminhead", "Scheduler", or "Registrar"`
      );
    }
  });
  return errors;
}

function validateData(data) {
  const validationResults = [];

  if (data.length <= 0) {
    validationResults.push("No Valid account was given in the CSV");
  }
  data.forEach((record, index) => {
    const userType = detectUserType(record);
    let requiredFields = [
      "id",
      "firstName",
      "lastName",
      "middleName",
      "contactNumber",
      "age",
      "birthDate",
      "gender",
      "nationality",
      "block",
      "lot",
      "street",
      "subdivision",
      "barangay",
      "city",
      "province",
      "country",
      "userCredentials",
      "email",
      "deleteFlag"
    ];

    if (userType === "student") {
      requiredFields.push("lrnId", "isCurrentlyEnrolled");
    } else if (userType === "teacher") {
      requiredFields.push(
        "teachingHoursPerWeek",
        "teachingExperience",
        "department"
      );
    } else if (userType === "employee") {
      requiredFields.push("role"); // Role is mandatory for employees
    } else {
      validationResults.push({
        row: index + 1,
        errors: ["Unable to determine entity type"]
      });
      return;
    }

    const recordErrors = validateRecord(record, requiredFields);
    if (recordErrors.length > 0) {
      validationResults.push({
        row: index + 1,
        entity: userType,
        errors: recordErrors
      });
    }
  });
  return validationResults;
}

export default function SaveAccountModal({
  isOpen,
  onClose,
  data = [],
  removeRow = [],
  currentErr,
  success,
  err
}) {
  const { DataServer, user } = useAuth();
  const [validationErrors, setValidationErrors] = useState([]);
  const [confirmationText, setConfirmationText] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [acceptModalOpen, setAcceptModalOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const students = data.students || [];
      const teachers = data.teachers || [];
      const employees = data.employees || [];
      const errors = validateData(
        [...students, ...teachers, ...employees].filter(
          (data) => !removeRow.includes(data.mainId) // Filter out rows with mainId in removeRow array
        )
      );

      setValidationErrors(errors);
    }
  }, [isOpen, data]);

  const handleChange = (e) => {
    setConfirmationText(e.target.value);
    if (error) setError(""); // Clear error when the input is updated
  };

  const validateInput = () => {
    if (confirmationText !== "I confirm to add all accounts") {
      setError("Please type the exact confirmation text.");
      return false;
    }
    return true;
  };

  const handleAccept = async () => {
    if (
      currentErr.students.length === 0 &&
      currentErr.teachers.length === 0 &&
      currentErr.employees.length === 0
    ) {
      console.log("File processed successfully!");
      const students =
        data.students.filter((data) => !removeRow.includes(data.mainId)) || [];
      const teachers =
        data.teachers.filter((data) => !removeRow.includes(data.mainId)) || [];
      const employees =
        data.employees.filter((data) => !removeRow.includes(data.mainId)) || [];
      const dataToAdd = { students, teachers, employees };
      try {
        console.log(dataToAdd);
        setisLoading(true);
        const body = {
          data: dataToAdd,
          creator: user
        };
        const AddResponse = await axios.post(
          `${DataServer}/info/csv/add`,
          body
        );
        success(true);
      } catch (e) {
        console.log(e.message);
        err(true);
      } finally {
        setisLoading(false);
        setAcceptModalOpen(false);
        onClose();
      }
    }
  };

  useEffect(() => {
    console.log(validationErrors);
  }, [validationErrors]);

  return (
    <Modal scrollBehavior="inside" isOpen={isOpen} onOpenChange={onClose}>
      <ModalContent>
        {(onCloseModal) => (
          <>
            <ModalHeader className="flex pb-1 flex-col gap-1 font-bold cursor-default text-xl">
              Account Integration Process
            </ModalHeader>
            <ModalBody>
              <div className="text-justify cursor-default mt-2">
                {[...data.students, ...data.teachers, ...data.employees].filter(
                  (data) => !removeRow.includes(data.mainId) // Filter out rows with mainId in removeRow array
                ).length <= 0 ||
                currentErr.students.length > 0 ||
                currentErr.teachers.length > 0 ||
                currentErr.employees.length > 0 ? (
                  <div className="p-4 bg-red-50 border-l-4 border-red-500 rounded-lg text-sm text-red-700 flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 mr-2 text-red-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10c0 4.418-3.582 8-8 8s-8-3.582-8-8 3.582-8 8-8 8 3.582 8 8zm-8 3a1 1 0 100-2 1 1 0 000 2zm-1-8a1 1 0 012 0v5a1 1 0 01-2 0V5z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span>
                      There are still errors to resolve before we can add the
                      data to BSA.
                    </span>
                  </div>
                ) : (
                  <>
                    <div className="p-4 bg-white rounded-lg shadow-md">
                      <h2 className="font-bold text-gray-800 mb-4">
                        Total Account Additions
                      </h2>
                      <div className="space-y-2">
                        {data?.students?.length > 0 && (
                          <div className="flex justify-between items-center bg-blue-100 rounded-md p-2">
                            <span className="font-medium text-blue-800">
                              Student
                              {data.students.filter(
                                (data) => !removeRow.includes(data.mainId) // Filter out rows with mainId in removeRow array
                              ).length !== 1
                                ? "s"
                                : ""}
                            </span>
                            <span className="font-semibold text-blue-900">
                              {
                                data.students.filter(
                                  (data) => !removeRow.includes(data.mainId) // Filter out rows with mainId in removeRow array
                                ).length
                              }
                            </span>
                          </div>
                        )}
                        {data?.teachers?.length > 0 && (
                          <div className="flex justify-between items-center bg-green-100 rounded-md p-2">
                            <span className="font-medium text-green-800">
                              Teacher
                              {data.teachers.filter(
                                (data) => !removeRow.includes(data.mainId) // Filter out rows with mainId in removeRow array
                              ).length !== 1
                                ? "s"
                                : ""}
                            </span>
                            <span className="font-semibold text-green-900">
                              {
                                data.teachers.filter(
                                  (data) => !removeRow.includes(data.mainId) // Filter out rows with mainId in removeRow array
                                ).length
                              }
                            </span>
                          </div>
                        )}
                        {data?.employees?.length > 0 && (
                          <div className="flex justify-between items-center bg-yellow-100 rounded-md p-2">
                            <span className="font-medium text-yellow-800">
                              Employee
                              {data.employees.filter(
                                (data) => !removeRow.includes(data.mainId) // Filter out rows with mainId in removeRow array
                              ).length !== 1
                                ? "s"
                                : ""}
                            </span>
                            <span className="font-semibold text-yellow-900">
                              {
                                data.employees.filter(
                                  (data) => !removeRow.includes(data.mainId) // Filter out rows with mainId in removeRow array
                                ).length
                              }
                            </span>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="p-3 bg-primary-100 rounded-xl mt-5 text-[12px] text-primary-600 cursor-default">
                      Warning: The process of adding accounts is irreversible.
                      Please ensure that all data is accurate before proceeding.
                    </div>
                  </>
                )}
              </div>
            </ModalBody>

            <ModalFooter>
              <Button color="danger" variant="light" onPress={onClose}>
                Cancel
              </Button>
              <Button
                color="secondary"
                isDisabled={
                  [
                    ...data.students,
                    ...data.teachers,
                    ...data.employees
                  ].filter(
                    (data) => !removeRow.includes(data.mainId) // Filter out rows with mainId in removeRow array
                  ).length <= 0 ||
                  currentErr.students.length > 0 ||
                  currentErr.teachers.length > 0 ||
                  currentErr.employees.length > 0
                }
                onPress={handleAccept}
              >
                {isLoading ? (
                  <div className=" flex gap-1">
                    <Spinner size="sm" color="white" /> Adding Accounts
                  </div>
                ) : (
                  "Add Accounts"
                )}
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
