import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Skeleton,
  Spinner,
  Avatar,
  SelectItem,
  Select,
  Autocomplete,
  AutocompleteItem
} from "@nextui-org/react";
import axios from "axios";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import Error from "../../../../../Components/ErrorIndicator";

export default function AddAdviser({
  headerTitleContent = "headerTitle",
  isScheduleCreation = false,
  buttonContent,
  course,
  section,
  courseid,
  sectionid,
  department,
  refresh
}) {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const { DataServer } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [mainError, setMainError] = useState("");
  const [isAdvisor, setIsAdvisor] = useState(true);

  const [teachers, setTeachers] = useState([]); // load all availbel teachers
  const [initialTeachers, setInitialTeachers] = useState([]); // random 5 teachhers

  const [inputValue, setInputValue] = useState("");
  const [selectedAdvisor, setSelectedAdvisor] = useState({});

  // Fetch advisors when the component mounts
  useEffect(() => {
    const getAvailableAdvisors = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${DataServer}/user/teacher/advisor/available`,
          { params: { department: department } }
        );
        setTeachers(response.data.documents);
      } catch (err) {
        setMainError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    getAvailableAdvisors();
  }, [DataServer]);

  useEffect(() => {
    if (isAdvisor) {
      onClose();
      console.log("gggsgsgsgsgsg");
    }
  }, [isAdvisor]);

  // Randomly select teachers when input is empty
  useEffect(() => {
    if (!inputValue) {
      setInitialTeachers(getRandomTeachers(teachers));
    }
  }, [inputValue, teachers]);

  const getRandomTeachers = (teachersArray) => {
    return teachersArray.sort(() => 0.5 - Math.random()).slice(0, 5);
  };

  useEffect(() => {
    console.log(selectedAdvisor);
  }, [selectedAdvisor]);

  async function AssignTeacher(id, name, course, section) {
    try {
      setIsLoading(true);
      const body = {
        teacherId: id,
        teacherName: name,
        courseId: course,
        sectionId: section
      };
      const asssignTeacherResponse = await axios.put(
        `${DataServer}/user/teacher/advisory/assign`,
        body
      );
      console.log(asssignTeacherResponse);
      return true;
    } catch (err) {
      setMainError(err.message);
      return false;
    } finally {
      setIsLoading(false);
      refresh();
      onClose();
    }
  }

  return (
    <>
      <Button
        variant={isScheduleCreation ? "light" : "icon"}
        className="bg-[#FFDE59]  "
        onPress={onOpen}
      >
        {buttonContent}
      </Button>

      <Modal
        className="font-poppins"
        isOpen={isOpen}
        onOpenChange={onOpenChange}
      >
        {mainError && <Error errorMessage={mainError} reset={setMainError} />}
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col pb-0 gap-1">
                <div className="relative flex">
                  <div
                    className={`transition-opacity duration-500  items-start  ${
                      isLoading ? "opacity-100 visible" : "opacity-0 invisible"
                    }`}
                  >
                    Loading Teachers to assign{" "}
                    <Spinner color="secondary" size="sm" className="pl-2" />
                  </div>
                  <span
                    className={`transition-opacity duration-500 absolute left-0 ${
                      !isLoading
                        ? "opacity-100 visible"
                        : "opacity-0 invisible "
                    }`}
                  >
                    {headerTitleContent} {course} | {section}
                  </span>
                </div>
              </ModalHeader>
              <ModalBody>
                <Skeleton isLoaded={!isLoading} className=" rounded-md">
                  <Autocomplete
                    classNames={{
                      listboxWrapper: "max-h-[320px]",
                      selectorButton: "text-secondary-500"
                    }}
                    defaultItems={teachers}
                    inputProps={{
                      classNames: {
                        base: "h-[45px] w-full",
                        input:
                          "ml-1 border-0 ring-0 outline-none focus:border-0 focus:ring-0 focus:outline-none",
                        inputWrapper:
                          "h-[48px] rounded-md border-secondary-500 hover:border-secondary-400 active:border-secondary-500"
                      }
                    }}
                    listboxProps={{
                      hideSelectedIcon: true,
                      itemClasses: {
                        base: [
                          "rounded-medium",
                          "text-secondary-500",
                          "transition-opacity",
                          "data-[hover=true]:text-foreground",
                          "dark:data-[hover=true]:bg-secondary-50",
                          "data-[pressed=true]:opacity-70",
                          "data-[hover=true]:bg-secondary-200",
                          "data-[selectable=true]:focus:bg-secondary-100",
                          "data-[focus-visible=true]:ring-secondary-500"
                        ],
                        wrapper: ""
                      }
                    }}
                    aria-label="Select a teacher"
                    placeholder="Enter teacher name"
                    popoverProps={{
                      offset: 10,
                      classNames: {
                        base: "",
                        content:
                          "p-1 border-small border-secondary-100 bg-background"
                      }
                    }}
                    startContent={
                      <svg
                        aria-hidden="true"
                        fill="none"
                        focusable="false"
                        height={20}
                        role="presentation"
                        viewBox="0 0 24 24"
                        width={20}
                      >
                        <path
                          d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2.5}
                        />
                        <path
                          d="M22 22L20 20"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2.5}
                        />
                      </svg>
                    }
                    radius="full"
                    variant="bordered"
                    onInput={(value) => {
                      console.log(value);
                    }}
                  >
                    {(teacher) => (
                      <AutocompleteItem
                        key={teacher.$id}
                        textValue={teacher.userCredentials.Username}
                      >
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <Avatar
                              alt={teacher.userCredentials.Username}
                              className="flex-shrink-0"
                              size="sm"
                            />
                            <div className="flex flex-col">
                              <span className="text-small">
                                {teacher.userCredentials.Username}
                              </span>
                              <span className="text-tiny text-default-400">
                                {teacher.department}
                              </span>
                            </div>
                          </div>
                          <Button
                            className="border-small mr-0.5 font-medium shadow-small"
                            radius="full"
                            size="sm"
                            variant="bordered"
                            onPress={() => {
                              setSelectedAdvisor(teacher);
                            }}
                          >
                            Add
                          </Button>
                        </div>
                      </AutocompleteItem>
                    )}
                  </Autocomplete>
                </Skeleton>
              </ModalBody>
              <ModalFooter className="py-2">
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                <Button
                  isDisabled={
                    isLoading || !Object.keys(selectedAdvisor).length > 0
                  }
                  color="secondary"
                  onPress={() => {
                    console.log(
                      selectedAdvisor.$id,
                      selectedAdvisor.userCredentials.Username,
                      courseid,
                      sectionid
                    );
                    if (
                      AssignTeacher(
                        selectedAdvisor.$id,
                        selectedAdvisor.userCredentials.Username,
                        courseid,
                        sectionid
                      )
                    ) {
                      console.log("Exit");
                    }
                  }}
                >
                  Assign
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
