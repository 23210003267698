import Registrar_Dash from "./components/RDashboard";
import Scheduler_Dash from "./components/SDashboard";
import { useAuth } from "../../../utils/AppwriteConfig/AuthContext";

import Admin_Dash from "./components/Admin_Dash";

export default function Dashboard() {
  const { role } = useAuth();
 
  let dashboardContent = null;
  switch (role.toString().toLowerCase()) {
    case "adminhead":
      dashboardContent = <Admin_Dash />;
      break;
    case "registrar":
      dashboardContent = <Registrar_Dash />;
      break;
    case "scheduler":
      dashboardContent = <Scheduler_Dash />;
      break;
    case "teacher":
      // Render teacher dashboard component or any other logic
     
      break;
    case "student":
      // Render student dashboard component or any other logic
  
      break;
    default:
      // Handle unknown role or navigate to a default page
     
      break;
  }
  return <div className="w-full h-full">{dashboardContent}</div>;
}
