import React from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure
} from "@nextui-org/react";
import PerDepartmentView from "../../PerDepartmentView";

export default function ShowScheduleModal({ academicYear, data }) {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  return (
    <>
      <Button id="ShowSched" onPress={onOpen} className="hidden">
        Open Modal
      </Button>
      <Modal
        size="5xl"
        scrollBehavior="inside"
        isOpen={isOpen}
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Academic Schedule | {data?.academicYear}
              </ModalHeader>
              <ModalBody>
                <PerDepartmentView
                  department={{ code: "Pre", name: "Pre-School" }}
                  AcademicYear={academicYear}
                  isOld={true}
                  groupId={data.$id}
                />
                <PerDepartmentView
                  department={{ code: "Elem", name: "Elementary" }}
                  AcademicYear={academicYear}
                  isOld={true}
                  groupId={data.$id}
                />
                <PerDepartmentView
                  department={{ code: "JHS", name: "Junior High-School" }}
                  AcademicYear={academicYear}
                  isOld={true}
                  groupId={data.$id}
                />
                <PerDepartmentView
                  department={{ code: "SHS", name: "Senior High-School" }}
                  AcademicYear={academicYear}
                  isOld={true}
                  groupId={data.$id}
                />
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
