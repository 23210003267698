import {
  Button,
  Card,
  CardBody,
  Select,
  SelectItem,
  Tab,
  Tabs
} from "@nextui-org/react";
import AnnouncementRender from "../DashboardPage/components/renders/D_AnnouncementRender";
import CardSkeleton from "../../../Components/skeleton/Cards";
import Announcementcard from "./components/Announcementscard";
import { useEffect, useState } from "react";
import { useAuth } from "../../../utils/AppwriteConfig/AuthContext";
import axios from "axios";
import Announcementdetails from "./components/Announcmentdetails";
import NewAnnouncement from "./Actions/NewAnnouncement";
import useRealtimeSubscription from "../../../utils/AppwriteConfig/RealtimeServices";

export default function Annoouncements() {
  const { server, DataServer } = useAuth();
  const [isLoading, setisLoading] = useState(false);
  const [isrefresh, setisrefresh] = useState(true);
  const [Announcements, setAnnouncements] = useState({});
  const [Selectedtab, setSelectedtab] = useState("posts");
  const [Selectedgroup, setSelectedgroup] = useState("all");
  const [SelectedAnnouncement, setSelectedAnnouncement] = useState({});
  const [Selectedimageurl, setSelectedimageurl] = useState();

  const [isPriority, setisPriority] = useState("All");

  // Sorting function
  const sortAnnouncements = (announcements) => {
    return announcements.sort((a, b) => {
      // Sort by priority (true comes first)
      if (a.priority && !b.priority) return -1;
      if (!a.priority && b.priority) return 1;
      // If priority is the same, sort by dateAdded in descending order
      if (new Date(a.dateAdded) > new Date(b.dateAdded)) return -1;
      if (new Date(a.dateAdded) < new Date(b.dateAdded)) return 1;
      return 0;
    });
  };

  //Get announcement based on selected tab and group
  useEffect(() => {
    async function fetchsubj() {
      try {
        setisLoading(true);
        let ispost = Selectedtab === "posts" ? true : false;
        if (Selectedgroup === "all") {
          //Get ALL Announcements
          const response = await axios.get(`${DataServer}/announcement/all`, {
            params: { post: ispost, priority: isPriority }
          });
      
          const sortedAnnouncements = sortAnnouncements(
            response.data.documents
          );
          setAnnouncements(sortedAnnouncements);
        } else {
          //Get specific announcement for a group
          const response = await axios.get(`${DataServer}/announcement/all`, {
            params: {
              group: Selectedgroup,
              post: ispost,
              priority: isPriority
            }
          });
          const sortedAnnouncements = sortAnnouncements(
            response.data.documents
          );
          setAnnouncements(sortedAnnouncements);
        }
      } catch (error) {
        // Handle any errors
        console.error(error);
      } finally {
        setisLoading(false);
      }
    }
    fetchsubj();
  }, [isrefresh, Selectedgroup, isPriority, Selectedtab, DataServer]);

  //Handle realtime changes
  const handleRealtimeMessage = (message) => {
    

    // Find the index of the announcement in the state array
    const announcementIndex = Announcements.findIndex(
      (announcement) => announcement.$id === message.payload.$id
    );

    // Check if the message is a 'create' event
    if (
      message.changetype === "update" &&
      announcementIndex !== -1 && // Check if announcementIndex is valid
      message.payload.post === Announcements[announcementIndex]?.post // Check if announcementIndex is valid
    ) {
      // If the announcement is found, update its data
      setAnnouncements((prevAnnouncements) => {
        const updatedAnnouncements = [...prevAnnouncements];
        updatedAnnouncements[announcementIndex] = message.payload;
        return sortAnnouncements(updatedAnnouncements);
      });
    } else if (
      (message.changetype === "delete" ||
        (message.changetype === "update" &&
          message.payload.post !==
            (Announcements[announcementIndex]?.post || undefined))) &&
      announcementIndex !== -1
    ) {
      // Remove the announcement with the same $id from the state
      setAnnouncements((prevAnnouncements) =>
        sortAnnouncements(
          prevAnnouncements.filter(
            (announcement) => announcement.$id !== message.payload.$id
          )
        )
      );
    } else if (
      message.changetype === "create" ||
      (message.changetype === "update" &&
        (message.payload.sendType === Selectedgroup ||
          Selectedgroup === "all") &&
        message.payload.post !==
          (Announcements[announcementIndex]?.post || undefined))
    ) {
      // Update the state to include the new announcements
      setAnnouncements((prevAnnouncements) =>
        sortAnnouncements([...prevAnnouncements, message.payload])
      );
    }
  };

  //Suscribe to realtimechanges
  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_ANNOUNCEMENT,
    handleRealtimeMessage
  );

  //Used to get data of the selected announcment and to pass it to child comp
  const selectannounce = (data, url) => {
    setSelectedAnnouncement(data);
    setSelectedimageurl(url);
    document.querySelector("#Announcementdetails").click();
  };

  //refresh announcment
  const refresh = () => {
    setisrefresh(!isrefresh);
  };

  return (
    <div className="flex-1 h-full w-full">
      <div className="h-full w-full flex flex-col space-y-2 ">
        <div className="  flex-initial p-2">
          <span className="text-[#72539b] text-3xl font-semibold">
            Announcements
          </span>
        </div>
        <div className="flex-1 bg-white rounded-md overflow-auto">
          <div className="flex w-full h-full flex-col overflow-auto">
            <div class=" flex-initial flex border-gray-200 dark:border-gray-700">
              <nav
                class="flex space-x-2 flex-initial px-2 text-secondary-400"
                aria-label="Tabs"
                role="tablist"
              >
                <button
                  disabled={isLoading}
                  type="button"
                  class="hs-tab-active:font-semibold hs-tab-active:border-secondary-400 hs-tab-active:text-secondary-400 py-4 px-1 inline-flex items-center gap-x-2 border-b-2 border-transparent text-sm whitespace-nowrap text-gray-500 hover:text-secondary-400 focus:outline-none focus:text-secondary-400 disabled:opacity-50 disabled:pointer-events-none dark:text-gray-400 dark:hover:text-blue-500 active"
                  id="tabs-with-underline-item-1"
                  data-hs-tab="#tabs-with-underline-1"
                  aria-controls="tabs-with-underline-1"
                  role="tab"
                  onClick={() => {
                    setSelectedtab("posts");
                  }}
                >
                  Posted
                </button>
                <button
                  type="button"
                  disabled={isLoading}
                  class="hs-tab-active:font-semibold hs-tab-active:border-secondary-400 hs-tab-active:text-secondary-400 py-4 px-1 inline-flex items-center gap-x-2 border-b-2 border-transparent text-sm whitespace-nowrap text-gray-500 hover:text-secondary-400 focus:outline-none focus:text-secondary-400 disabled:opacity-50 disabled:pointer-events-none dark:text-gray-400 dark:hover:text-blue-500"
                  id="tabs-with-underline-item-2"
                  data-hs-tab="#tabs-with-underline-2"
                  aria-controls="tabs-with-underline-2"
                  onClick={() => {
                    setSelectedtab("drafts");
                  }}
                  role="tab"
                >
                  Drafts
                </button>
              </nav>
              <div className="flex-1 flex space-x-2 justify-end p-2">
                <select
                  disabled={isLoading}
                  id="hs-select-label"
                  class="py-3 px-4 pe-9 shadow-sm block flex-initial border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                  onChange={(e) => {
                    setisPriority(e.target.value);
                  }}
                >
                  <option selected value={"All"}>
                    All
                  </option>
                  <option value={"true"}>Priority</option>
                  <option value={"false"}>Non-Priority</option>
                </select>
                <select
                  disabled={isLoading}
                  id="hs-select-label"
                  class="py-3 px-4 pe-9 shadow-sm block flex-initial border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                  onChange={(e) => {
                    setSelectedgroup(e.target.value);
                  }}
                >
                  <option selected value={"all"}>
                    All
                  </option>
                  <option value={"employees"}>Employee</option>
                  <option value={"teachers"}>Teacher</option>
                  <option value={"students"}>Student</option>
                </select>
              </div>
            </div>

            <div class=" flex-1 overflow-auto">
              <div
                id="tabs-with-underline-1"
                className={`h-full p-4 ${
                  Selectedtab === "posts" ? "grid" : "hidden"
                }${
                  Announcements.length > 0
                    ? " grid p-3 gap-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 overflow-auto flex-1"
                    : " flex"
                } `}
                role="tabpanel"
                aria-labelledby="tabs-with-underline-item-1"
              >
                {!isLoading ? (
                  <>
                    {Announcements &&
                    Announcements.length > 0 &&
                    Selectedtab === "posts" ? (
                      <>
                        {Announcements &&
                          Announcements.map((announcement) => (
                            <Announcementcard
                              key={announcement.id} // Make sure to provide a unique key
                              data={announcement}
                              select={selectannounce}
                            />
                          ))}
                      </>
                    ) : (
                      <div
                        className={`flex-1 ${
                          Selectedtab === "posts" ? "flex" : "hidden"
                        } flex justify-center items-center`}
                      >
                        No Announcements post
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {/* Render skeleton cards when loading */}
                    <CardSkeleton />
                    <CardSkeleton />
                    <CardSkeleton />
                    <CardSkeleton />
                    <CardSkeleton />
                  </>
                )}
              </div>

              <div
                id="tabs-with-underline-2"
                className={`h-full p-2 ${
                  Selectedtab === "drafts" ? "grid" : "hidden"
                } ${
                  Announcements.length > 0
                    ? " grid p-3 gap-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 overflow-auto flex-1"
                    : " flex"
                } `}
                role="tabpanel"
                aria-labelledby="tabs-with-underline-item-2"
              >
                {Announcements.length > 0}
                {!isLoading ? (
                  <>
                    {Announcements &&
                    Announcements.length > 0 &&
                    Selectedtab === "drafts" ? (
                      <>
                        {Announcements &&
                          Announcements.map((announcement) => (
                            <Announcementcard
                              key={announcement.id} // Make sure to provide a unique key
                              data={announcement}
                              select={selectannounce}
                            />
                          ))}
                      </>
                    ) : (
                      <div
                        className={`flex-1 flex justify-center items-center ${
                          Selectedtab === "drafts" ? "flex" : "hidden"
                        }`}
                      >
                        No Announcements draft
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {/* Render skeleton cards when loading */}
                    <CardSkeleton />
                    <CardSkeleton />
                    <CardSkeleton />
                    <CardSkeleton />
                    <CardSkeleton />
                  </>
                )}
              </div>
              {SelectedAnnouncement && (
                <Announcementdetails
                  Announcement={SelectedAnnouncement}
                  imageurl={Selectedimageurl}
                  refreshparent={refresh}
                />
              )}
            </div>
          </div>
        </div>
        <div className="flex-initial flex-col sm:flex-row flex p-2 bg-white rounded-md">
          <span className="flex-1 sm:flex-initial">
            <div class="inline-flex h-full p-1 justify-center items-center">
              <span class=" inline-block rounded-full me-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#ffce2a"
                  class="bi bi-star-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                </svg>
              </span>
              <span class="text-gray-600 dark:text-gray-400">Prioritize</span>
            </div>
          </span>
          <span className="flex-1 flex justify-end">
            <NewAnnouncement rerenderParent={refresh} />
          </span>
        </div>
      </div>
    </div>
  );
}
