import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Spinner
} from "@nextui-org/react";
import axios from "axios";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import Error from "../../../../../Components/ErrorIndicator";

export default function UnAssignedSection({ reset, sections }) {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();

  const { DataServer } = useAuth();

  const [MainError, setMainError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  console.log("gg");
  async function RandomAssignAdvisor() {
    try {
      setIsLoading(true);
      const AssignAdvisor = await axios.post(
        `${DataServer}/course/section/randomAssign`,
        { dept: sections[0].course.department, sections: sections }
      );
      console.log(AssignAdvisor.data);
      reset();
      onClose();
    } catch (err) {
      setMainError(err.message);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      {MainError && <Error errorMessage={MainError} reset={setMainError} />}
      <div className="flex" onClick={onOpen}>
        <div className=" flex-initial flex items-center justify-center size-20">
          <div className="bg-warning-200 size-14 rounded-full flex justify-center items-center">
            <div className="bg-warning-300 size-10 rounded-full flex justify-center items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-exclamation-triangle-fill"
                viewBox="0 0 16 16"
              >
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
              </svg>
            </div>
          </div>
        </div>
        <div className="flex-1 w-full py-2 p-1 h-full  flex flex-col">
          <div className="  w-full text-sm font-semibold text-warning-700 flex-1 flex items-end">
            Something needs to be fixed.
          </div>
          <div className="  text-xs w-full flex-1">
            There are sections with no Advisors
          </div>
        </div>
      </div>
      <Modal
        className=" font-poppins"
        isOpen={isOpen}
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex pb-2 flex-col gap-1">
                Section with no Advisors
              </ModalHeader>
              <ModalBody>
                <div className=" flex flex-col gap-2 ">
                  {sections.length > 0 &&
                    sections.map((section) => (
                      <div className=" flex p-2 bg-warning-200 rounded-md">
                        {section.course.courseName} | {section.sectionName}
                      </div>
                    ))}
                </div>
              </ModalBody>
              <ModalFooter className=" pt-2">
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                {isLoading ? (
                  <Button color="secondary" isDisabled>
                    <Spinner color="white" size="sm" />
                    Assigning
                  </Button>
                ) : (
                  <Button color="secondary" onPress={RandomAssignAdvisor}>
                    Randomly Assign Advisors
                  </Button>
                )}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
