import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure
} from "@nextui-org/react";
import EditEvent from "./EditEvent";
import Delete from "../../../Components/modals/DeleteConfirm";
import {
  formatDate,
  formatDateFromDB,
  formatToISO
} from "../../../utils/helpers/helper";
import { useAuth } from "../../../utils/AppwriteConfig/AuthContext";

export default function CalendarEvent({ event, Refreshcalendar }) {
  const { user, role } = useAuth();
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();

  const [isEdit, setisEdit] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);

  // Convert start and end dates to Date objects
  useEffect(() => {
    if (event && event.start && event.end) {
      console.log("sssg", event.start);
      setStart(formatToISO(event.start));
      setEnd(formatToISO(event.end));
    }
    // setStart(event.start);
    // setEnd(event.end);
  }, [event]);

  const refresheditchanges = () => {
    setisEdit(false);
    onClose();
    Refreshcalendar();
  };

  useEffect(() => {
    setisLoading(false);
  }, [isEdit]);
  return (
    <>
      <Button id="calendareventbtn" className="hidden" onPress={onOpen}>
        Open Event
      </Button>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        className=" min-h-[200px] max-h-[500px] "
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-row gap-1 items-center">
                <div className=" flex flex-col">
                  <div>Event Details</div>
                </div>
                {!isEdit && (
                  <>
                    {" "}
                    {((!isEdit && event?.userCredentials?.$id === user?.$id) ||
                      role.includes("Adminhead") ||
                      role.includes("Scheduler")) && (
                      <Button
                        isIconOnly
                        className=" bg-[#ffde59]"
                        onPress={() => {
                          setisEdit(true);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-pencil-square"
                          viewBox="0 0 16 16"
                        >
                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                          <path
                            fill-rule="evenodd"
                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                          />
                        </svg>
                      </Button>
                    )}
                  </>
                )}
              </ModalHeader>
              <ModalBody>
                {isEdit ? (
                  <EditEvent
                    eventdata={event}
                    RefreshParent={refresheditchanges}
                  />
                ) : (
                  <div className="flex flex-col">
                    <div className="flex flex-col">
                      <div className=" flex justify-between">
                        <span className="text-md font-medium">Title</span>
                        <div className=" text-xs font-normal-">
                          {" "}
                          posted by{" "}
                          {event?.userCredentials?.$id !== user.$id
                            ? event?.userCredentials?.Username
                            : "you"}
                        </div>
                      </div>
                      <span className="text-lg p-2 bg-slate-100 rounded-lg">
                        {event.title}
                      </span>
                    </div>
                    {event.description && (
                      <div className="flex flex-col">
                        <span className="text-md font-medium">Description</span>
                        <span className="text-lg p-2 bg-slate-100 rounded-lg">
                          {event.description}
                        </span>
                      </div>
                    )}
                    <div className="flex flex-col">
                      <span className="text-md font-medium">Start</span>
                      <span className="text-lg p-2 bg-slate-100 rounded-lg">
                        {start && formatDate(start)}
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <span className="text-md font-medium">End</span>
                      <span className="text-lg p-2 bg-slate-100 rounded-lg">
                        {start && formatDate(end)}
                      </span>
                    </div>
                    {event.group && (
                      <div className="flex flex-col">
                        <span className="text-md font-medium">
                          Invited Group
                        </span>
                        <span className="text-lg p-2 bg-slate-100 rounded-lg">
                          {event.group}
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </ModalBody>
              <ModalFooter className="flex-initial">
                {isEdit && (
                  <>
                    {isLoading ? (
                      <Button
                        isLoading
                        color="secondary"
                        spinner={
                          <svg
                            className="animate-spin h-5 w-5 text-current"
                            fill="none"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            />
                            <path
                              className="opacity-75"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              fill="currentColor"
                            />
                          </svg>
                        }
                      >
                        Loading
                      </Button>
                    ) : (
                      <>
                        <Button
                          onPress={() => {
                            setisEdit(false);
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          onPress={() => {
                            setisLoading(true);
                            document.querySelector("#savebtnedit").click();
                          }}
                        >
                          Save
                        </Button>
                        <Delete
                          title={"calendar"}
                          subject={event}
                          refresher={refresheditchanges}
                        />
                      </>
                    )}
                  </>
                )}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
