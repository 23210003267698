import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Checkbox,
  Input,
  Link
} from "@nextui-org/react";
import { useAuth } from "../../../../utils/AppwriteConfig/AuthContext";
import axios from "axios";
import Delete from "../../../../Components/modals/DeleteConfirm";
import Error from "../../../../Components/ErrorIndicator";
import SectionNameValidation from "./SectionNameValidation";

export default function EditSection({ CurrentData, courseId, Refresh }) {
  const { server, user } = useAuth();
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();

  const [isLoading, setisLoading] = useState(false);
  const [MainError, setMainError] = useState();

  const [data, setdata] = useState({});
  const [currentTitle, setcurrentTitle] = useState();
  const [Defvalue, setDefvalue] = useState();
  const [Errors, setErrors] = useState({});

  const [configs, setconfigs] = useState({
    isvalidating: false,
    istyping: false,
    duplicates: 0
  });

  //Added process on adding the current data to the input on modal open
  const handleOpen = () => {
    if (CurrentData) {
      setDefvalue({
        name: CurrentData.sectionName,
        capacity: CurrentData.capacity,
        description: CurrentData.sectionDescription
      });
      setdata({
        creator: user.$id,
        id: CurrentData.$id,
        CID: CurrentData.$collectionId,
        name: CurrentData.sectionName,
        capacity: CurrentData.capacity,
        description: CurrentData.sectionDescription
      });
    }

    onOpen();
  };

  //Validate logic
  const validate = () => {
    let errors = {};
    if (!data.name || !data.name.trim()) {
      errors.name = `Section name iss required`;
    } else if (data.name.length > 50) {
      errors.name = `Section name is too long`;
    }

    setErrors(errors);
    return Object.keys(errors).length === 0; // Return true if there are no errors
  };

  //Validate on each user changes
  useEffect(() => {
    validate();
  }, [Defvalue]);

  //Handle Saving changes in section process
  const handleSave = async () => {
    if (validate()) {
      // Validate before saving
      setisLoading(true);
      try {
        const response = await axios.post(`${server}/v1/section/edit`, data);

        Refresh();
        onClose();
      } catch (error) {
        setMainError(error.message);
      } finally {
        setisLoading(false);
      }
    }
  };

  const sectionvalidations = (datas, isvalidating, istyping, duplicate) => {

    setdata({ ...data, name: datas });
    setconfigs({
      isvalidating: isvalidating,
      istyping: istyping,
      duplicates: duplicate
    });
  };

  //Refresh parent
  const refreshhandler = () => {
    Refresh();
    onClose();
  };

  return (
    <>
      {MainError && <Error errorMessage={MainError} />}
      <button
        onClick={handleOpen}
        className="bg-[#FFDE59] opacity-100 rounded-lg p-3 py-2 text-black hover:bg-[#fcd84b] text-sm"
      >
        Edit
      </button>

      <Modal
        isOpen={isOpen}
        size="lg"
        onOpenChange={onOpenChange}
        hideCloseButton="true"
      >
        <ModalContent className=" font-poppins">
          {(onClose) => (
            <>
              <ModalHeader className="flex relative justify-between items-center gap-1">
                <span className=" font-bold text-2xl">
                  Section {CurrentData.sectionName}
                </span>
                <Delete
                  title={"section"}
                  subject={{ ...data, courseId }}
                  refresher={refreshhandler}
                />
              </ModalHeader>
              <ModalBody>
                <form className="w-full flex flex-col">
                  <>
                    <SectionNameValidation
                      SectionName={sectionvalidations}
                      defname={Defvalue?.name}
                      isEdit={true}
                    />

                    <label
                      htmlFor={`description`}
                      className="block text-sm mb-2 font-medium dark:text-white"
                    >
                      Description
                    </label>
                    <input
                      type="text"
                      name={`description`}
                      id={`description`}
                      className={`py-2 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
                        Errors.description == null
                          ? "border-slate-200"
                          : "border-red-500"
                      }`}
                      required
                      defaultValue={Defvalue.description}
                      onChange={(e) =>
                        setdata({
                          ...data,
                          description: e.target.value
                        })
                      }
                    />
                    {Errors.description && (
                      <span className="text-red-500 text-sm mt-1">
                        {Errors.description}
                      </span>
                    )}
                  </>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button
                  className=" bg-slate-300"
                  variant="flat"
                  onPress={onClose}
                >
                  Close
                </Button>
                {isLoading ? (
                  <Button
                    isLoading
                    color="secondary"
                    spinner={
                      <svg
                        className="animate-spin h-5 w-5 text-current"
                        fill="none"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          fill="currentColor"
                        />
                      </svg>
                    }
                  >
                    Loading
                  </Button>
                ) : (
                  <Button
                    isDisabled={
                      Object.keys(Errors).length > 0 ||
                      configs.isvalidating ||
                      configs.istyping ||
                      configs.duplicates > 0
                    }
                    color="secondary"
                    onPress={handleSave}
                  >
                    Save
                  </Button>
                )}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
