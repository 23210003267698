import { Image, Select, SelectItem, Spinner } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { useAuth } from "../utils/AppwriteConfig/AuthContext";
import axios from "axios";

export default function Coursestep({ user, setenroll }) {
  const { DataServer } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [courses, setCourses] = useState([]);
  const [sections, setSections] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState();
  const [selectedSection, setSelectedSection] = useState();
  const [sTry, setTry] = useState();

  //Renders courses
  useEffect(() => {
    setIsLoading(true);
    async function fetchCourses() {
      try {
        const response = await axios.get(`${DataServer}/course/all`);
       
        setCourses(response.data.documents);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchCourses();
  }, [DataServer]);

  //set enrollment info
  useEffect(() => {
    if (selectedCourse) {
      const selectedCourseObject = courses.find(
        (course) => course.$id === selectedCourse
      );
      const selectedSectionObject = sections.find(
        (section) => section.$id === selectedSection
      );

      if (selectedCourseObject) {
        let sec = "";
        if (selectedSectionObject) {
          sec = selectedSectionObject;
        }

        setenroll(
          selectedCourse,
          selectedSection,
          selectedCourseObject.courseName,
          sec.sectionName
        );
      }
    } else {
      setenroll();
      setSections([]);
      setSelectedSection();
    }
  }, [selectedCourse, selectedSection, courses, sections]);

  //disable sections if the course is not selected
  useEffect(() => {
    if (selectedCourse) {
      const course = courses.find((course) => course.$id === selectedCourse);

      if (course) {
        setSections(course.section);
      }
    } else {
      setSections([]);
    }
  }, [selectedCourse, courses]);

  return (
    <div className="flex-1 h-full p-4 overflow-auto">
      <div className="flex flex-col flex-1 justify-center items-center h-full ">
        <Image
          isZoomed
          width={240}
          alt="NextUI Fruit Image with Zoom"
          src={require("../Img/course.jpg")}
        />
        <span className="text-xl">Enroll {user && user.Username} ?</span>
        <span className="text-sm">leave blank to skip enrollment process</span>
        <div className=" relative w-full flex justify-center">
          {isLoading && (
            <Spinner
              size="lg"
              color="secondary"
              className="absolute flex w-full h-full justify-center items-center"
            ></Spinner>
          )}
          <Select
            label="Course"
            placeholder="Select a Course"
            className="max-w-xs my-2"
            value={selectedCourse}
            onSelectionChange={(value) => {
              if (value && value.size > 0) {
                // Convert the Set to an array and get the first item
                const selectedCourseId = [...value][0];
                setSelectedCourse(selectedCourseId);
                setSelectedSection();
              } else {
                // Handle deselection
                setSelectedCourse(null); // or whatever value represents "no selection"
                setSelectedSection(null); // clear the selected section as well if needed
              }
            }}
          >
            {courses.map((course) => (
              <SelectItem key={course.$id} value={course.$id}>
                {course.courseName}
              </SelectItem>
            ))}
          </Select>
        </div>

        <Select
          label="Section"
          placeholder="Select a Section"
          className="max-w-xs"
          selectedKeys={[selectedSection]}
          onSelectionChange={(value) => {
            // Convert the Set to an array and get the first item
            const data = [...value][0];
            setSelectedSection(data);
          }}
          isDisabled={!selectedCourse}
        >
          {sections &&
            sections.length > 0 &&
            sections.map((section) => (
              <SelectItem key={section.$id} value={section.$id}>
                {section.sectionName}
              </SelectItem>
            ))}
        </Select>
      </div>
    </div>
  );
}
