import { useState } from "react";
import PendingRequest from "./PendingRequest";
import ApprovedRequest from "./ApprovedRequest";
import DeclinedRequest from "./DeclinedRequest";

export default function RequestStatusTab({ sched }) {
  const [MainResfreshstatus, setMainRefreshStatus] = useState(false);
  const [SelectedTab, setSelectedTab] = useState("pending");

  function refreshMainResfreshstatus() {
    setMainRefreshStatus(!MainResfreshstatus);
  }

  return (
    <div className="flex flex-col w-full h-full ">
      <nav
        className="pb-1 flex-initial min-h-14 flex px-3 gap-x-1 overflow-x-auto [&::-webkit-scrollbar]:h-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500"
        aria-label="Tabs"
        role="tablist"
        aria-orientation="horizontal"
      >
        <button
          type="button"
          className="hs-tab-active:font-semibold   hs-tab-active:border-secondary-600 hs-tab-active:text-secondary-600 py-4 px-1 inline-flex items-center gap-x-2 border-b-2 border-transparent text-sm whitespace-nowrap text-gray-500 hover:text-secondary-600 focus:outline-none focus:text-secondary-600 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-400 dark:hover:text-secondary-500 active"
          id="horizontal-scroll-tab-item-1"
          aria-selected="true"
          data-hs-tab="#horizontal-scroll-tab-preview"
          aria-controls="horizontal-scroll-tab-preview"
          role="tab"
          onClick={(e) => {
            refreshMainResfreshstatus();
            setSelectedTab("pending");
          }}
        >
          Pending Request
        </button>
        <button
          type="button"
          className="hs-tab-active:font-semibold hs-tab-active:border-secondary-600 hs-tab-active:text-secondary-600 py-4 px-1 inline-flex items-center gap-x-2 border-b-2 border-transparent text-sm whitespace-nowrap text-gray-500 hover:text-secondary-600 focus:outline-none focus:text-secondary-600 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-400 dark:hover:text-secondary-500"
          id="horizontal-scroll-tab-item-2"
          aria-selected="false"
          data-hs-tab="#horizontal-scroll-tab-2"
          aria-controls="horizontal-scroll-tab-2"
          role="tab"
          onClick={(e) => {
            refreshMainResfreshstatus();
            setSelectedTab("approved");
          }}
        >
          Approved Request
        </button>
        <button
          type="button"
          className="hs-tab-active:font-semibold hs-tab-active:border-secondary-600 hs-tab-active:text-secondary-600 py-4 px-1 inline-flex items-center gap-x-2 border-b-2 border-transparent text-sm whitespace-nowrap text-gray-500 hover:text-secondary-600 focus:outline-none focus:text-secondary-600 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-400 dark:hover:text-secondary-500"
          id="horizontal-scroll-tab-item-3"
          aria-selected="false"
          data-hs-tab="#horizontal-scroll-tab-3"
          aria-controls="horizontal-scroll-tab-3"
          role="tab"
          onClick={(e) => {
            refreshMainResfreshstatus();
            setSelectedTab("declined");
          }}
        >
          Declined Request
        </button>
      </nav>

      <div className=" flex flex-1 w-full  h-full    px-2">
        <div
          className=" flex flex-1 w-full h-[calc(100vh-115px)]"
          id="horizontal-scroll-tab-preview"
          role="tabpanel"
          aria-labelledby="horizontal-scroll-tab-item-1"
        >
          <PendingRequest
            currentSchedules={sched}
            refreshMain={refreshMainResfreshstatus}
            RefreshStatus={MainResfreshstatus}
            tab={SelectedTab}
          />
        </div>
        <div
          id="horizontal-scroll-tab-2"
          className="hidden w-full h-[calc(100vh-115px)]"
          role="tabpanel"
          aria-labelledby="horizontal-scroll-tab-item-2"
        >
          <ApprovedRequest
            refreshMain={refreshMainResfreshstatus}
            RefreshStatus={MainResfreshstatus}
            tab={SelectedTab}
          />
        </div>
        <div
          id="horizontal-scroll-tab-3"
          className="hidden w-full h-[calc(100vh-115px)]"
          role="tabpanel"
          aria-labelledby="horizontal-scroll-tab-item-3"
        >
          <DeclinedRequest
            refreshMain={refreshMainResfreshstatus}
            RefreshStatus={MainResfreshstatus}
            tab={SelectedTab}
          />
        </div>
      </div>
    </div>
  );
}
{
  /* */
}
