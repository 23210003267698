import ConfigurationForm from "./ConfigurationForm";
import ConfigurationItems from "./ConfigurationItem";

export default function Configurations({ container }) {
  return (
    <div>
      <ConfigurationForm data={container} />
      <ConfigurationItems data={container} />
    </div>
  );
}
