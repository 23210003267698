import { useEffect, useState } from "react";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import {
  Tooltip,
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@nextui-org/react";
import axios from "axios";
import Picture_Tittle_Item from "../../../../../Components/Cards/Picture_Title_Item";
import ContainerSkeleton from "../../../../../Components/skeleton/Containerskeleton";
import Add_ContainerItem from "./Add_ContainerItem";
import useRealtimeSubscription from "../../../../../utils/AppwriteConfig/RealtimeServices";
import Edit_ContainerItem from "./Edit_ContainerItem";

export default function ConfigurationItems({ data }) {
  const { server, user, isItemLoading, setisItemLoading } = useAuth();
  const [isOpen, setIsOpen] = useState(false);

  const [isLoading, setisLoading] = useState(false);
  const [Refresh, setRefresh] = useState(false);
  const [MainError, setMainError] = useState();

  const [CurrentItem, setCurrentItem] = useState({});
  const [Items, setItem] = useState([]);

  //Gets all the Items on  Specific container
  useEffect(() => {
    async function GetData() {
      try {
        setisLoading(true);
        setisItemLoading(true);
        const getcontainer = await axios.get(
          `${server}/v1/home/container/items`,
          {
            params: { id: data.$id },
          }
        ); // this lods the data fetched
        setItem(getcontainer.data.documents);
      } catch (e) {
        setMainError(e.message);
      } finally {
        setisItemLoading(false);
        setisLoading(false);
      }
    }
    GetData();
  }, [data]);

  //Realtime Updates
  const handleRealtimechanges = (changes) => {

    const changedata = Items.findIndex(
      (item) => item.$id === changes.payload.$id
    );

    if (changes.changetype === "create") {
      setItem((prevItems) => [...prevItems, changes.payload]);
    } else if (changes.changetype === "delete") {
      setItem((prevItems) =>
        prevItems.filter((item) => item.$id !== changes.payload.$id)
      );
    } else if (changes.changetype === "update") {
      setItem((prevItem) => {
        const items = [...prevItem];
        items[changedata] = changes.payload;
        return items;
      });
    }
  };

  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_CONTAINER_ITEM,
    handleRealtimechanges
  );

  const GetCurrentItem = (data) => {

    setCurrentItem(data);
    document.querySelector("#edititembtn").click();
  };
  return (
    <div className="mix-h-[550px] w-full">
      <div className=" bg-[#A16AE8] w-full rounded-md p-2 flex items-center justify-between">
        <div className="flex">
          <span className="text-md font-medium">Container Items</span>
          <Tooltip
            className=" "
            isOpen={isOpen}
            onOpenChange={(open) => setIsOpen(open)}
            onPress={() => {
         
            }}
            closeDelay={2000}
            placement="right-start"
            content={
              <div className="px-1 py-2 max-w-[300px] font-poppins space-y-2">
                <div className="text-small font-bold">
                  What Are Container Items?
                </div>
                <div className="text-tiny">
                  Container items are the building blocks of our institute's
                  homepage. They allow you to input various types of
                  information, images, or media to be showcased to visitors.
                </div>
                <div className="text-small font-bold">
                  How Do Container Items Work?
                </div>
                <div className="text-tiny">
                  Each container item represents a specific piece of content
                  that you can customize and post on our homepage. For instance,
                  you might use container items to feature upcoming events,
                  student testimonials, or faculty profiles.
                </div>
              </div>
            }
          >
            <span className="sm:flex hidden">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="1.75"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-circle-help"
              >
                <circle cx="12" cy="12" r="10" />
                <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                <path d="M12 17h.01" />
              </svg>
            </span>
          </Tooltip>
          <Popover
            placement="right-end"
            className={"flex sm:hidden"}
            showArrow={true}
          >
            <PopoverTrigger className="p-0">
              <button className={"flex sm:hidden p-0"}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1.75"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-circle-help"
                >
                  <circle cx="12" cy="12" r="10" />
                  <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                  <path d="M12 17h.01" />
                </svg>
              </button>
            </PopoverTrigger>
            <PopoverContent>
              <div className="px-1 py-2 max-w-[300px] font-poppins space-y-2">
                <div className="text-small font-bold">
                  What Are Container Items?
                </div>
                <div className="text-tiny">
                  Container items are the building blocks of our institute's
                  homepage. They allow you to input various types of
                  information, images, or media to be showcased to visitors.
                </div>
                <div className="text-small font-bold">
                  How Do Container Items Work?
                </div>
                <div className="text-tiny">
                  Each container item represents a specific piece of content
                  that you can customize and post on our homepage. For instance,
                  you might use container items to feature upcoming events,
                  student testimonials, or faculty profiles.
                </div>
              </div>
            </PopoverContent>
          </Popover>
        </div>

        <Add_ContainerItem Container={data} loadingstatus={isItemLoading} />
      </div>
      <div className="grid p-3 gap-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 mt-4 flex-1">
        {isLoading ? (
          <>
            <ContainerSkeleton />
            <ContainerSkeleton />
            <ContainerSkeleton />
            <ContainerSkeleton />
          </>
        ) : (
          <>
            {Items.length > 0 && Items ? (
              Items.map((item) => (
                <Picture_Tittle_Item
                  key={item.$id}
                  container={item}
                  type={"item"}
                  Currentcontainer={GetCurrentItem}
                  loadingstate={isItemLoading}
                />
              ))
            ) : (
              <div className=" w-full h-full flex justify-center items-center">
                No containers yet
              </div>
            )}
          </>
        )}
      </div>
      <Edit_ContainerItem Item={CurrentItem} Container={data} />
    </div>
  );
}
