import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAuth } from "../../utils/AppwriteConfig/AuthContext";
import axios from "axios";
import { Button, Spinner } from "@nextui-org/react";
import CalendarEvent from "./actions/CalendarEvent";
import CreateEvent from "./actions/CreateEvent";
import useRealtimeSubscription from "../../utils/AppwriteConfig/RealtimeServices";
import Error from "../../Components/ErrorIndicator";

// and, for optional time zone support
import "moment-timezone";
moment.tz.setDefault("Asia/Manila");

const localizer = momentLocalizer(moment);

export default function UserCalendar() {
  const { user, server, role } = useAuth();
  const [isLoading, setisLoading] = useState(false);
  const [Refresh, setRefresh] = useState(true);
  const [MainError, setMainError] = useState();
  const [Events, setEvents] = useState([]);
  const [SelectedEvents, setSelectedEvents] = useState({});
  const [Selectedslot, setSelectedslot] = useState({});
  const clickRef = useRef(null);

  // Load events
  useEffect(() => {
    async function getEvents() {
      try {
        setisLoading(true);
        const response = await axios.get(`${server}/v1/calendar/events`, {
          params: { userid: user.$id, role: role }
        });
        const eventData = response.data.documents.map((event) => ({
          ...event,
          start: new Date(
            new Date(event.start).setHours(new Date(event.start).getHours() - 8)
          ),
          end: new Date(
            new Date(event.end).setHours(new Date(event.end).getHours() - 8)
          )
        }));

        console.log("gg", eventData);
        setEvents(eventData);
      } catch (error) {
        setMainError(error.message);
      } finally {
        setisLoading(false);
      }
    }

    getEvents();
  }, [Refresh]);

  // Refresh the calendar
  const refreshcalendar = () => setRefresh(!Refresh);

  // Handle new events in real-time
  const handleRealtimeMessage = (message) => {
    if (
      message.changetype === "create" &&
      message.payload.userCredentials.$id === user.$id
    ) {
      const newEvent = {
        ...message.payload,
        start: moment.utc(message.payload.start).toDate(),
        end: moment.utc(message.payload.end).toDate()
      };
      setEvents([...Events, newEvent]);
    } else if (
      message.changetype === "update" ||
      message.changetype === "delete"
    ) {
      refreshcalendar();
    } else {
      setMainError("Error fetching updates.");
    }
  };

  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_CALENDAR,
    handleRealtimeMessage
  );

  const handleSelectSlot = useCallback(({ start, end }) => {
    setSelectedslot({
      start: start,
      end: end
    });
    console.log(moment.utc(start).toDate(), moment.utc(end).toDate());
    document.querySelector("#createeventbtn").click();
  }, []);

  const onSelectEvent = useCallback((calEvent) => {
    window.clearTimeout(clickRef?.current);
    clickRef.current = window.setTimeout(() => {
      setSelectedEvents(calEvent);
      document.querySelector("#calendareventbtn").click();
    }, 250);
  }, []);

  const onDoubleClickEvent = useCallback((calEvent) => {
    window.clearTimeout(clickRef?.current);
    clickRef.current = window.setTimeout(() => {
      alert(calEvent);
    }, 250);
  }, []);

  return (
    <div className="w-full h-full relative p-2">
      {MainError && <Error errorMessage={MainError} />}
      {isLoading && (
        <div className="absolute bg-[#ededed]/90 z-50 w-full h-full flex flex-col justify-center items-center">
          <Spinner size="lg" color="secondary" />
          <span>Loading...</span>
        </div>
      )}
      <Calendar
        localizer={localizer}
        events={Events}
        startAccessor="start"
        endAccessor="end"
        allDayAccessor="allDay"
        selectable
        onDoubleClickEvent={onDoubleClickEvent}
        onSelectEvent={onSelectEvent}
        onSelectSlot={handleSelectSlot}
      />
      <CreateEvent event={Selectedslot} RefreshParent={refreshcalendar} />
      <CalendarEvent event={SelectedEvents} Refreshcalendar={refreshcalendar} />
    </div>
  );
}
