import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Input,
  Select,
  SelectItem,
  Switch
} from "@nextui-org/react";
import { useAuth } from "../../../utils/AppwriteConfig/AuthContext";
import Error from "../../../Components/ErrorIndicator";
import axios from "axios";
import { formatToISO } from "../../../utils/helpers/helper";

export default function CreateEvent({ event, RefreshParent }) {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const { user, role, server } = useAuth();

  const [isLoading, setisLoading] = useState(false);
  const [mainError, setMainError] = useState("");
  const [errors, setErrors] = useState({});
  const [data, setData] = useState({
    title: "",
    start: "",
    end: "",
    isEmail: false,
    userCredentials: user.$id,
    description: "",
    group: ""
  });

  const [values, setValues] = React.useState(new Set([]));

  useEffect(() => {
    let defaultStartDateTimeValue;
    let defaultEndDateTimeValue;
    // if (event.start && event.end) {
    //   defaultStartDateTimeValue = new Date(event.start)
    //     .toISOString()
    //     .slice(0, -8);
    //   defaultEndDateTimeValue = new Date(event.end).toISOString().slice(0, -8);
    //   console.log(
    //     event.start,
    //     formatToISOString(event.start),
    //     defaultStartDateTimeValue
    //   );
    // }

    // console.log(event.start, formatToISOString(event.start));
    // console.log(event.end, formatToISOString(event.end));
    console.log("ggey", new Date(event.start), event.start);
    setData({
      ...data,
      start: event && event.start && formatToISO(event.start),
      end: event && event.end && formatToISO(event.end)
    });
  }, [event]);

  console.log(event.start, event.end);
  const handleChanges = (e) => {
    console.log(e.target.value);
    setData({ ...data, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    validate();
  }, [data]);

  const validate = () => {
    const currentDate = new Date();
    const startDate = new Date(data.start);
    const endDate = new Date(data.end);
    const newErrors = {};

    if (!data.title.trim()) {
      newErrors.title = "Event title is required";
    } else if (data.title.length > 50) {
      newErrors.title = "Event title is too long";
    }

    if (!data.start) {
      newErrors.start = "Event start is required";
    }

    if (!data.end) {
      newErrors.end = "Event end is required";
    } else if (endDate < startDate) {
      newErrors.end = "Event end cannot be before the start date";
    }

    setErrors(newErrors);
  };

  // Handle create event
  const handleCreateEvent = async () => {
    if (Object.keys(errors).length === 0) {
      try {
        setisLoading(true);
        const dataTGive = { ...data, group: Array.from(values) };

        const response = await axios.post(
          `${server}/v1/calendar/new`,
          dataTGive
        );

        setisLoading(false);
        onClose();
        setData({
          title: "",
          start: "",
          end: "",
          userCredentials: user.$id,
          userName: user.name,
          isEmail: false,
          description: "",
          group: ""
        });
        RefreshParent();
      } catch (e) {
        setMainError(e.message);
        console.error("Errorsss found:", e);
      } finally {
        setisLoading(false);
      }
    }
  };

  return (
    <>
      <Button
        id="createeventbtn"
        isIconOnly
        className="absolute bottom-0 right-2 h-auto w-auto p-4 rounded-full bg-[#a16ae8] z-20"
        onPress={onOpen}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          fill="currentColor"
          className="bi bi-calendar-plus"
          viewBox="0 0 16 16"
        >
          <path d="M8 7a.5.5 0 0 1 .5.5V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5A.5.5 0 0 1 8 7" />
          <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
        </svg>
      </Button>
      {mainError && <Error errorMessage={mainError} />}

      <Modal
        className=" min-h-[300px] max-h-[500px] h-full"
        isOpen={isOpen}
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                New Event
              </ModalHeader>
              <ModalBody className="overflow-auto">
                <div className="flex flex-col ">
                  <div className=" p-1 space-y-1 flex flex-col">
                    <label
                      htmlFor="title"
                      className="block text-sm font-medium dark:text-white"
                    >
                      Title
                    </label>
                    <input
                      type="text"
                      name="title"
                      id="title"
                      className={`py-2 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
                        errors.title ? "border-red-500" : "border-slate-200"
                      }`}
                      required
                      onChange={handleChanges}
                    />
                    {errors.title && (
                      <span className="text-red-500 text-sm">
                        {errors.title}
                      </span>
                    )}
                  </div>
                  <div className=" p-1 space-y-1 flex flex-col">
                    <label
                      htmlFor="start"
                      className="block text-sm font-medium dark:text-white"
                    >
                      Event start
                    </label>
                    <input
                      type="datetime-local"
                      name="start"
                      value={data.start}
                      id="start"
                      className={`py-2 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
                        errors.start ? "border-red-500" : "border-slate-200"
                      }`}
                      required
                      onChange={handleChanges}
                    />
                    {errors.start && (
                      <span className="text-red-500 text-sm mt-1">
                        {errors.start}
                      </span>
                    )}
                  </div>
                  <div className=" p-1 space-y-1 flex flex-col">
                    <label
                      htmlFor="end"
                      className="block text-sm font-medium dark:text-white"
                    >
                      Event end
                    </label>
                    <input
                      type="datetime-local"
                      name="end"
                      value={data.end}
                      id="end"
                      className={`py-2 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
                        errors.end ? "border-red-500" : "border-slate-200"
                      }`}
                      required
                      onChange={handleChanges}
                    />
                    {errors.end && (
                      <span className="text-red-500 text-sm mt-1">
                        {errors.end}
                      </span>
                    )}
                  </div>
                  <div className=" p-1 space-y-1 flex flex-col">
                    <label
                      htmlFor="description"
                      className="block text-sm font-medium dark:text-white"
                    >
                      Description
                    </label>
                    <textarea
                      type="text"
                      name="description"
                      id="description"
                      className={`py-2 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
                        errors.description
                          ? "border-red-500"
                          : "border-slate-200"
                      }`}
                      required
                      onChange={handleChanges}
                    />
                    {errors.description && (
                      <span className="text-red-500 text-sm">
                        {errors.description}
                      </span>
                    )}
                  </div>

                  {(role.includes("Adminhead") ||
                    role.includes("Scheduler")) && (
                    <div className="flex w-full flex-col gap-2">
                      <Select
                        label="Invite Group"
                        selectionMode="multiple"
                        placeholder="Select group to invite"
                        labelPlacement="outside"
                        selectedKeys={values}
                        className=" w-full bg-white font border rounded-lg"
                        onSelectionChange={setValues}
                        classNames={{
                          base: "ring-0",
                          mainWrapper: " ring-0",
                          innerWrapper: "bg-white ",
                          selectorIcon: "bg-white",
                          label: " font-medium",
                          trigger:
                            "bg-white data-[hover=true]:bg-white-500 data-[focus-visible=true]:outline-[#A16AE8] data-[focus-visible=true]:outline-2  data-[focus-visible=true]:outline-offset-2 "
                        }}
                      >
                        <SelectItem key={"Employee"}>Employee</SelectItem>
                        <SelectItem key={"Teachers"}>Teachers</SelectItem>
                        <SelectItem key={"Students"}>Students</SelectItem>
                      </Select>
                      <p className="text-small text-default-500">
                        Selected: {Array.from(values).join(", ")}
                      </p>
                    </div>
                  )}
                </div>
              </ModalBody>
              <ModalFooter className=" justify-between flex-col sm:flex-row">
                <div>
                  {" "}
                  <Switch
                    isSelected={data.isEmail}
                    onValueChange={(value) => {
                      setData({ ...data, isEmail: value });
                    }}
                    defaultSelected
                    size="md"
                    color="secondary"
                    className={` ${
                      role.includes("Adminhead") || role.includes("Scheduler")
                        ? "flex"
                        : "hidden"
                    } `}
                    thumbIcon={({ isSelected, className }) =>
                      isSelected ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          fill="black"
                          class="bi bi-envelope-plus-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.5 4.5 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586zM16 4.697v4.974A4.5 4.5 0 0 0 12.5 8a4.5 4.5 0 0 0-1.965.45l-.338-.207z" />
                          <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0m-3.5-2a.5.5 0 0 0-.5.5v1h-1a.5.5 0 0 0 0 1h1v1a.5.5 0 0 0 1 0v-1h1a.5.5 0 0 0 0-1h-1v-1a.5.5 0 0 0-.5-.5" />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15px"
                          height="15px"
                          fill="black"
                          class="bi bi-envelope-x-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.5 4.5 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586zM16 4.697v4.974A4.5 4.5 0 0 0 12.5 8a4.5 4.5 0 0 0-1.965.45l-.338-.207z" />
                          <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0m-4.854-1.354a.5.5 0 0 0 0 .708l.647.646-.647.646a.5.5 0 0 0 .708.708l.646-.647.646.647a.5.5 0 0 0 .708-.708l-.647-.646.647-.646a.5.5 0 0 0-.708-.708l-.646.647-.646-.647a.5.5 0 0 0-.708 0" />
                        </svg>
                      )
                    }
                  >
                    Send Email
                  </Switch>
                </div>
                <div className=" flex justify-end">
                  <Button color="danger" variant="light" onPress={onClose}>
                    Close
                  </Button>
                  {isLoading ? (
                    <Button
                      isLoading
                      color="secondary"
                      spinner={
                        <svg
                          className="animate-spin h-5 w-5 text-current"
                          fill="none"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          />
                          <path
                            className="opacity-75"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            fill="currentColor"
                          />
                        </svg>
                      }
                    >
                      Loading
                    </Button>
                  ) : (
                    <Button color="secondary" onPress={handleCreateEvent}>
                      Create Event
                    </Button>
                  )}
                </div>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
