import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure
} from "@nextui-org/react";
import { useNavigation } from "react-router-dom";
import { useAuth } from "../../../../utils/AppwriteConfig/AuthContext";
import axios from "axios";
import Error from "../../../../Components/ErrorIndicator";

export default function DeleteCourse({ title, course, refresher, close }) {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const { server, user } = useAuth();
  const [isLoading, setisLoading] = useState(false);
  const [MainError, setMainError] = useState();
  const [Sections, setSections] = useState([]);

  useEffect(() => {
    async function fetchsubj() {
      try {
        setisLoading(true);
        const sec = await axios.get(`${server}/v1/course/sections`, {
          params: { CourseId: course.$id }
        });

        setSections(sec.data);
      } catch (error) {
        // Handle any errors
        console.error(error);
      } finally {
        setisLoading(false);
      }
    }
    if (course) fetchsubj();
  }, [course]);

  const handledelete = async () => {
    // Check the updated state value after setting the errors
    if (course) {
      setisLoading(true);
      try {
        const response = await axios.delete(`${server}/v1/${title}/delete`, {
          params: {
            id: course.$id,
            name: course.courseName,
            creator: user.$id
          }
        });
        onClose(); //closes modal
        close();
        refresher(); //Refresh parent
      } catch (e) {
        setMainError(e.message); //Get error message
      } finally {
        onClose(); //closes modal
        setisLoading(false);
      }
    }
  };
  return (
    <>
      {MainError && <Error errorMessage={MainError} />}
      {/**Show Error for 5 sec */}
      <Button color="danger" variant="flat" onPress={onOpen}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-trash"
          viewBox="0 0 16 16"
        >
          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
          <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
        </svg>
        <span>Delete</span>
      </Button>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        isDismissable={false}
        isKeyboardDismissDisabled={true}
        scrollBehavior="inside"
        backdrop="opaque"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 pb-0">
                {!Sections.length > 0
                  ? `Are you sure you want to delete ${course.courseName} ?`
                  : `Unable to delete ${course.courseName}`}
              </ModalHeader>
              <ModalBody>
                {!Sections.length > 0 ? (
                  <p>Any changes made will not be saved and be discarded.</p>
                ) : (
                  <p className="px-2">
                    <b>{course.courseName}</b> have <b>{Sections.length}</b>{" "}
                    sections that might have students enrolled in. please make
                    sure that there are no sections and enrolled students to the
                    course before deleting
                  </p>
                )}
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Cancel
                </Button>
                {isLoading ? (
                  <Button
                    isLoading
                    color="secondary"
                    spinner={
                      <svg
                        className="animate-spin h-5 w-5 text-current"
                        fill="none"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          fill="currentColor"
                        />
                      </svg>
                    }
                  >
                    Loading
                  </Button>
                ) : (
                  <Button
                    isDisabled={course?.section?.length > 0}
                    color="secondary"
                    onClick={() => {
                      handledelete();
                    }}
                  >
                    Continue
                  </Button>
                )}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
