import { useEffect } from "react";
import { useAuth } from "./AuthContext";
import useRealtimeSubscription from "./RealtimeServices";

const { Outlet, Navigate } = require("react-router-dom");

const PrivateRoutes = () => {
  const { user } = useAuth();

  return user ? <Outlet /> : <Navigate to={"/"} />;
};

export default PrivateRoutes;
