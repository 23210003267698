import React, { useEffect, useRef, useState } from "react";
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownSection,
  DropdownItem,
  Button,
  cn,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Badge,
  Slider
} from "@nextui-org/react";
import { functions } from "../../../../utils/AppwriteConfig/Config";
import ResponsiveToolTip from "../../../../Components/ResponsiveTooltip";

export default function ScheduleOptions({
  isSchedDone, // is schedule is done? used for loading purposes
  selectedView, //get selected view
  setSelectedView, // update selected view
  selectedPriority,
  setSelectedPriority,
  Configs, //get parent config data
  isChangedSchedConfigs,
  setConfigs, //Update parent config data
  data
}) {
  const [isPopOverOpen, setIsPopOverOpen] = useState(false);
  const [isPriorityStarSection, setIsPriorityStarSection] = useState(false);
  const [isPriorityMajorSubjects, setIsPriorityMajorSubjects] = useState(false);
  const [isPriorityHigherGrades, setIsPriorityHigherGrades] = useState(false);

  const [isChanged, setIsChanged] = useState(false);
  const [iscancelled, setiscancelled] = useState(false);
  const [applyChanges, setApplyChanges] = useState(false);

  const [sortPriority, setSortPriority] = useState(Configs?.priorities || []);
  const [PrioNumbering, setPrioNumbering] = useState(Configs?.priorities || []);

  //Suitability factors
  const [isSubjectTaughtConsidered, setisSubjectTaughtConsidered] = useState(
    Configs?.factors.includes("subjectTaught") ? true : false
  );
  const [isDegreeConsidered, setisDegreeConsidered] = useState(
    Configs?.factors.includes("degree") ? true : false
  );
  const [isExperienceConsidered, setisExperienceConsidered] = useState(
    Configs?.factors.includes("experience") ? true : false
  );
  const [isPreferencesConsidered, setisPreferencesConsidered] = useState(
    Configs?.factors.includes("preferences") ? true : false
  );
  const [factors, setFactors] = useState(Configs?.factors || []);

  //Factors changer
  useEffect(() => {
    // Use a Set to manage unique factors
    const factorsSet = new Set(factors);

    // Add or remove factors based on state values
    if (isSubjectTaughtConsidered) {
      factorsSet.add("subjectTaught");
    } else {
      factorsSet.delete("subjectTaught");
    }

    if (isDegreeConsidered) {
      factorsSet.add("degree");
    } else {
      factorsSet.delete("degree");
    }

    if (isExperienceConsidered) {
      factorsSet.add("experience");
    } else {
      factorsSet.delete("experience");
    }

    if (isPreferencesConsidered) {
      factorsSet.add("preferences");
    } else {
      factorsSet.delete("preferences");
    }

    // Convert Set to array and update state only if it has changed
    const newFactorsArray = Array.from(factorsSet);
    if (newFactorsArray.join() !== factors.join()) {
      setFactors(newFactorsArray);
    }
  }, [
    isSubjectTaughtConsidered,
    isDegreeConsidered,
    isExperienceConsidered,
    isPreferencesConsidered,
    factors
  ]);

  //Save changes and reload data
  useEffect(() => {
    if (applyChanges) {
      setConfigs({
        ...Configs,
        priorities: sortPriority,
        factors: factors,
        totalFactors: totalWeights
      });
      setApplyChanges(false);
    }
  }, [sortPriority, setConfigs, applyChanges, Configs]);

  //
  useEffect(() => {
    const PrioritizationNumbering = Configs.priorities;

    // Reset state to false initially
    setIsPriorityStarSection(false);
    setIsPriorityMajorSubjects(false);
    setIsPriorityHigherGrades(false);

    // Update state based on presence in the array
    for (const priority of PrioritizationNumbering) {
      if (priority === "StarSection") {
        setIsPriorityStarSection(true);
      }
      if (priority === "MajorSubjects") {
        setIsPriorityMajorSubjects(true);
      }
      if (priority === "HigherGrades") {
        setIsPriorityHigherGrades(true);
      }
    }

    // Logging and setting the prioritization number
    setPrioNumbering(PrioritizationNumbering);
  }, [Configs, applyChanges, isPopOverOpen, iscancelled]);

  useEffect(() => {
    const factors = Configs?.factors || [];
    for (const factor of factors) {
      if (factor === "subjectTaught") {
        setisSubjectTaughtConsidered(true);
      }
      if (factor === "degree") {
        setisDegreeConsidered(true);
      }
      if (factor === "experience") {
        setisExperienceConsidered(true);
      }
      if (factor === "preferences") {
        setisPreferencesConsidered(true);
      }
    }
  }, [iscancelled, Configs]);

  const [weights, setWeights] = useState(
    Configs.weights || {
      subjectTaught: 80,
      degree: 40,
      experience: 20,
      preference: 15
    }
  );
  const [totalWeights, ssetTotalWeights] = useState({
    subjectTaught: 0,
    degree: 0,
    experience: 0,
    preferences: 0
  });

  const debounceTimeout = useRef(null);
  useEffect(() => {
    const timerId = setTimeout(() => {
      const { subjectTaught, degree, experience, preference } = weights;
      const total = subjectTaught + degree + experience + preference;

      if (total !== 0) {
        const normalizedWeights = {
          subjectTaught: isSubjectTaughtConsidered
            ? (subjectTaught / total) * 100
            : 0,
          degree: isDegreeConsidered ? (degree / total) * 100 : 0,
          experience: isExperienceConsidered ? (experience / total) * 100 : 0,
          preferences: isPreferencesConsidered ? (preference / total) * 100 : 0
        };
        ssetTotalWeights({ ...normalizedWeights });
      }
    }, 500);

    // Clean up the timer
    return () => clearTimeout(timerId);
  }, [weights]);

  useEffect(() => {
    let PrioritizationNumbering = sortPriority;
    setPrioNumbering(PrioritizationNumbering);
  }, [sortPriority]);

  // async function sorts() {
  //   const body = JSON.stringify(data);
  //   const sort = await functions.createExecution(
  //     process.env.REACT_APP_SCHEDULE_EXECUTION,
  //     body,
  //     false,
  //     "/schedule/sorting",
  //     "GET"
  //   );
  // }

  // Function to update sortPriority based on checkbox state
  const togglePriority = (priority) => {
    // Check if priority is already in the array
    const currentIndex = sortPriority.indexOf(priority);

    if (currentIndex === -1) {
      // Priority is not in the array, add it to the beginning
      setSortPriority([...sortPriority, priority]);
    } else {
      // Priority is already in the array, remove it
      const newPriorityOrder = [...sortPriority];
      newPriorityOrder.splice(currentIndex, 1);
      setSortPriority(newPriorityOrder);
    }
  };

  useEffect(() => {
    if (JSON.stringify(weights) !== JSON.stringify(Configs.weights)) {
      setIsChanged(true);
    }
  }, [totalWeights]);
  return (
    <Popover
      isOpen={isPopOverOpen}
      onOpenChange={(isOpen) => setIsPopOverOpen(isOpen)}
      placement="bottom-end"
      shouldBlockScroll
      showArrow={true}
    >
      <PopoverTrigger>
        <Button
          variant="bordered"
          color="secondary"
          className="bg-[#ededed] px-1 group button-container"
          isDisabled={!isSchedDone}
        >
          <div className="button-content">
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-sliders2 button-icon"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M10.5 1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4H1.5a.5.5 0 0 1 0-1H10V1.5a.5.5 0 0 1 .5-.5M12 3.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m-6.5 2A.5.5 0 0 1 6 6v1.5h8.5a.5.5 0 0 1 0 1H6V10a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5M1 8a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2A.5.5 0 0 1 1 8m9.5 2a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V13H1.5a.5.5 0 0 1 0-1H10v-1.5a.5.5 0 0 1 .5-.5m1.5 2.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5"
              />
            </svg>
            <span className="button-text">Configurations</span>
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-3">
        <div className="max-w-[390px] max-h-[300px] overflow-auto  [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-secondary-500 dark:[&::-webkit-scrollbar-track]:bg-slate-700  dark:[&::-webkit-scrollbar-thumb]:bg-slate-500">
          <div className="py-1">
            <span className="text-xs font-normal text-[#71717a]">Views</span>
          </div>
          {/* View Type */}
          <div className=" border-b-1 p-1">
            <button
              onClick={() => {
                setSelectedView("courses");
                setIsPopOverOpen(false);
              }}
              className={`${
                selectedView === "courses" && "bg-secondary-50"
              } flex w-full items-center gap-x-3.5 py-2 px-3 rounded-lg  text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700`}
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-journals"
                  viewBox="0 0 16 16"
                >
                  <path d="M5 0h8a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2 2 2 0 0 1-2 2H3a2 2 0 0 1-2-2h1a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1H1a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v9a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1H3a2 2 0 0 1 2-2" />
                  <path d="M1 6v-.5a.5.5 0 0 1 1 0V6h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V9h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 2.5v.5H.5a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1H2v-.5a.5.5 0 0 0-1 0" />
                </svg>
              </div>
              <div className="flex flex-col">
                <div className="w-full flex flex-col items-start justify-center">
                  <span
                    id="react-aria3808636772-:r265:"
                    className="flex-1 text-small font-normal truncate"
                  >
                    <span className=" flex h-[25px] items-center">
                      Course{" "}
                      <sup className=" text-secondary-600"> Adjustable</sup>
                    </span>
                  </span>
                  <span
                    id="react-aria3808636772-:r266:"
                    className="w-full text-tiny text-left text-foreground-500 group-hover:text-current"
                  >
                    <span>View by individual Courses</span>
                  </span>
                </div>
              </div>
            </button>
            <button
              onClick={() => {
                setSelectedView("teachers");
                setIsPopOverOpen(false);
              }}
              className={`${
                selectedView === "teachers" && "bg-secondary-50"
              } flex w-full items-center gap-x-3.5 py-2 px-3 rounded-lg  text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700`}
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-people-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />
                </svg>
              </div>
              <div className="flex flex-col">
                <div className="w-full flex flex-col items-start justify-center">
                  <span className="flex-1 text-small font-normal truncate">
                    Teachers
                  </span>
                  <span className="w-full text-tiny text-left text-foreground-500 group-hover:text-current">
                    View by specific Teachers
                  </span>
                </div>
              </div>
            </button>
            <button
              onClick={() => {
                setSelectedView("specific assignments");
                setIsPopOverOpen(false);
              }}
              className={`${
                selectedView === "specificassignments" && "bg-secondary-50"
              } flex w-full items-center gap-x-3.5 py-2 px-3 rounded-lg  text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700`}
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-list-columns-reverse"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M0 .5A.5.5 0 0 1 .5 0h2a.5.5 0 0 1 0 1h-2A.5.5 0 0 1 0 .5m4 0a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1h-10A.5.5 0 0 1 4 .5m-4 2A.5.5 0 0 1 .5 2h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-4 2A.5.5 0 0 1 .5 4h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m-4 2A.5.5 0 0 1 .5 6h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 0 1h-8a.5.5 0 0 1-.5-.5m-4 2A.5.5 0 0 1 .5 8h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 0 1h-8a.5.5 0 0 1-.5-.5m-4 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1h-10a.5.5 0 0 1-.5-.5m-4 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5m-4 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5"
                  />
                </svg>
              </div>
              <div className="flex flex-col">
                <div className="w-full flex flex-col items-start justify-center">
                  <span className="flex-1 text-small font-normal truncate">
                    Specific Assignments
                  </span>
                  <span className="w-full text-tiny text-left text-foreground-500 group-hover:text-current">
                    View by specific assigned subjects to teachers
                  </span>
                </div>
              </div>
            </button>
          </div>
          {/* Assignment Configs */}
          <div className="border-b-1 p-1">
            <div className="py-1">
              <span className="text-xs font-normal text-[#71717a]">
                Assignment Data Prioritazion
              </span>
            </div>
            <button
              onClick={() => {
                setIsPriorityStarSection(!isPriorityStarSection);
                togglePriority("StarSection");
                setIsChanged(true);
              }}
              className="flex w-full relative items-center gap-x-3.5 py-2 px-3 rounded-lg  text-secondary hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700"
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-stars"
                  viewBox="0 0 16 16"
                >
                  <path d="M7.657 6.247c.11-.33.576-.33.686 0l.645 1.937a2.89 2.89 0 0 0 1.829 1.828l1.936.645c.33.11.33.576 0 .686l-1.937.645a2.89 2.89 0 0 0-1.828 1.829l-.645 1.936a.361.361 0 0 1-.686 0l-.645-1.937a2.89 2.89 0 0 0-1.828-1.828l-1.937-.645a.361.361 0 0 1 0-.686l1.937-.645a2.89 2.89 0 0 0 1.828-1.828zM3.794 1.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387A1.73 1.73 0 0 0 4.593 5.69l-.387 1.162a.217.217 0 0 1-.412 0L3.407 5.69A1.73 1.73 0 0 0 2.31 4.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387A1.73 1.73 0 0 0 3.407 2.31zM10.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.16 1.16 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.16 1.16 0 0 0-.732-.732L9.1 2.137a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732z" />
                </svg>
              </div>
              <div className="flex flex-col">
                <div className="w-full flex flex-col  items-start justify-center">
                  <span className="flex-1 text-small font-normal truncate">
                    Prioritize Star Sections
                  </span>
                  <span className="w-full text-tiny text-left text-foreground-500 text-left group-hover:text-current">
                    Reload data to prioritize assignments for all Section 1
                    first.
                  </span>
                </div>
              </div>
              <input
                id="PrioritizeStarSection"
                name="PrioritizeStarSection"
                type="checkbox"
                checked={isPriorityStarSection}
                className={`${
                  isPriorityStarSection && "hidden"
                } shrink-0 border-gray-200 rounded text-secondary focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800`}
              ></input>
              <div
                className={`${
                  !isPriorityStarSection && "hidden"
                } items-center text-white  bg-secondary rounded-full px-[7px] py-[1px]`}
              >
                {PrioNumbering.indexOf("StarSection") + 1}
              </div>
            </button>
            <button
              onClick={() => {
                setIsPriorityMajorSubjects(!isPriorityMajorSubjects);
                togglePriority("MajorSubjects");
                setIsChanged(true);
              }}
              className="flex w-full items-center gap-x-3.5 py-2 px-3 rounded-lg  text-secondary hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700"
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-award-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="m8 0 1.669.864 1.858.282.842 1.68 1.337 1.32L13.4 6l.306 1.854-1.337 1.32-.842 1.68-1.858.282L8 12l-1.669-.864-1.858-.282-.842-1.68-1.337-1.32L2.6 6l-.306-1.854 1.337-1.32.842-1.68L6.331.864z" />
                  <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1z" />
                </svg>
              </div>
              <div className="flex flex-col">
                <div className="w-full flex flex-col text-secondary items-start justify-center">
                  <span className="flex-1 text-small font-normal truncate">
                    Prioritize Majors Subjects
                  </span>
                  <span className="w-full text-tiny text-left text-foreground-500 text-left group-hover:text-current">
                    Reload data to prioritize assignments for subjects with the
                    most hours per week first.
                  </span>
                </div>
              </div>
              <input
                id="PrioritizeMajorsSubjects"
                name="PrioritizeMajorsSubjects"
                type="checkbox"
                checked={isPriorityMajorSubjects}
                className={`${
                  isPriorityMajorSubjects && "hidden"
                } shrink-0 border-gray-200 rounded text-secondary focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800`}
              ></input>
              <div
                className={`${
                  !isPriorityMajorSubjects && "hidden"
                } items-center text-white  bg-secondary rounded-full px-[7px] py-[1px]`}
              >
                {PrioNumbering.indexOf("MajorSubjects") + 1}
              </div>
            </button>{" "}
            <button
              onClick={() => {
                setIsPriorityHigherGrades(!isPriorityHigherGrades);
                togglePriority("HigherGrades");
                setIsChanged(true);
              }}
              className="flex w-full items-center gap-x-3.5 py-2 px-3 rounded-lg  text-secondary hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700"
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-filter-square-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm.5 5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1 0-1M4 8.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m2 3a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5" />
                </svg>
              </div>
              <div className="flex flex-col">
                <div className="w-full flex flex-col text-secondary items-start justify-center">
                  <span className="flex-1 text-small font-normal truncate">
                    Prioritize Higher Grades
                  </span>
                  <span className="w-full text-tiny text-left text-foreground-500 text-left group-hover:text-current">
                    Reload data to prioritize assignments for higher grade
                    levels first.
                  </span>
                </div>
              </div>
              <input
                id="PrioritizeHigherGrades"
                name="PrioritizeHigherGrades"
                type="checkbox"
                checked={isPriorityHigherGrades}
                className={`${
                  isPriorityHigherGrades && "hidden"
                } shrink-0 border-gray-200 rounded text-secondary focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800`}
              ></input>
              <div
                className={`${
                  !isPriorityHigherGrades && "hidden"
                } items-center text-white  bg-secondary rounded-full px-[7px] py-[1px]`}
              >
                {PrioNumbering.indexOf("HigherGrades") + 1}
              </div>
            </button>
          </div>
          {/* Teacher Assignment COnfigs */}
          <div className="border-b-1 p-1">
            <div className="py-1">
              <span className="text-xs font-normal text-[#71717a]">
                Teacher Assignment Configuration
              </span>
            </div>
            <button
              onClick={() => {
                setConfigs({
                  ...Configs,
                  configs: "DefaultAssignments"
                });
                isChangedSchedConfigs(true);
                setIsPopOverOpen(false);
              }}
              className={`${
                Configs.configs === "DefaultAssignments" && "bg-warning-50"
              } flex w-full items-center gap-x-3.5 py-2 px-3 rounded-lg  text-warning-600 hover:bg-gray-100 focus:outline-none focus:bg-warning-50 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700`}
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-list-columns-reverse"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M0 .5A.5.5 0 0 1 .5 0h2a.5.5 0 0 1 0 1h-2A.5.5 0 0 1 0 .5m4 0a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1h-10A.5.5 0 0 1 4 .5m-4 2A.5.5 0 0 1 .5 2h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-4 2A.5.5 0 0 1 .5 4h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m-4 2A.5.5 0 0 1 .5 6h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 0 1h-8a.5.5 0 0 1-.5-.5m-4 2A.5.5 0 0 1 .5 8h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 0 1h-8a.5.5 0 0 1-.5-.5m-4 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1h-10a.5.5 0 0 1-.5-.5m-4 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5m-4 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5"
                  />
                </svg>
              </div>
              <div className="flex flex-col">
                <div className="w-full flex flex-col items-start justify-center">
                  <span
                    id="react-aria1555630211-:r69:"
                    className="flex-1 text-small font-normal truncate"
                  >
                    Most Suitable Teacher
                  </span>
                  <span
                    id="react-aria1555630211-:r6a:"
                    className="w-full  text-tiny text-left text-foreground-500 group-hover:text-current"
                  >
                    Reload data to assign the most suitable teachers to each
                    subject.
                  </span>
                </div>
              </div>
            </button>
            <button
              onClick={() => {
                setConfigs({
                  ...Configs,
                  configs: "BalanceScheduleperTeacher"
                });
                isChangedSchedConfigs(true);
                setIsPopOverOpen(false);
              }}
              className={`${
                Configs.configs === "BalanceScheduleperTeacher" &&
                "bg-warning-50"
              } flex w-full items-center gap-x-3.5 py-2 px-3 rounded-lg  text-warning-600 hover:bg-gray-100 focus:outline-none focus:bg-warning-50 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700`}
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-diagram-3-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H14a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 2 7h5.5V6A1.5 1.5 0 0 1 6 4.5zm-6 8A1.5 1.5 0 0 1 1.5 10h1A1.5 1.5 0 0 1 4 11.5v1A1.5 1.5 0 0 1 2.5 14h-1A1.5 1.5 0 0 1 0 12.5zm6 0A1.5 1.5 0 0 1 7.5 10h1a1.5 1.5 0 0 1 1.5 1.5v1A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5zm6 0a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5z"
                  />
                </svg>
              </div>
              <div className="flex flex-col">
                <div className="w-full flex flex-col items-start justify-center">
                  <span
                    id="react-aria1555630211-:r69:"
                    className="flex-1 text-small font-normal truncate"
                  >
                    Balance Schedule per Teacher
                  </span>
                  <span
                    id="react-aria1555630211-:r6a:"
                    className="w-full text-tiny text-left text-left text-foreground-500 group-hover:text-current"
                  >
                    Reload data to ensure a balanced schedule for each teacher.
                  </span>
                </div>
              </div>
            </button>
          </div>
          {/* Suitability Factors */}
          <div
            className={`${
              factors.length <= 0 && "border border-red-400 p-2 pt-0 rounded-lg"
            }`}
          >
            <div className="py-1 flex items-center gap-1">
              <span className="text-xs font-normal text-[#71717a]">
                Suitability Evaluation Factors
              </span>
              <ResponsiveToolTip
                tooltipIconColor="#71717a"
                tooltipTitle="What is Suitability Evaluation Factors ?"
                tooltipMessage="
Suitability Evaluation Factors allow you to define and adjust the criteria for assessing suitability, customizing the evaluation to emphasize the most relevant aspects for your specific needs."
              />
            </div>
            {/* Teacher's Taught Subject relevance */}
            <div></div>
            <div className="flex flex-col w-full  items-center  rounded-lg  text-secondary hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700">
              <button
                onClick={() => {
                  setisSubjectTaughtConsidered(!isSubjectTaughtConsidered);
                  setIsChanged(true);
                  if (!isSubjectTaughtConsidered) {
                    setWeights({ ...weights });
                  } else {
                    ssetTotalWeights({
                      ...totalWeights,
                      subjectTaught: 0
                    });
                  }
                }}
                className="flex w-full relative items-center gap-x-3.5 py-2 px-3"
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="bi bi-journal-bookmark-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M6 1h6v7a.5.5 0 0 1-.757.429L9 7.083 6.757 8.43A.5.5 0 0 1 6 8z"
                    />
                    <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2" />
                    <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z" />
                  </svg>
                </div>
                <div className="flex flex-col">
                  <div className="w-full flex flex-col  items-start justify-center">
                    <span className="flex-1 text-small font-normal truncate">
                      Subject Taught {totalWeights.subjectTaught.toFixed(2)}%
                    </span>
                    <span className="w-full text-tiny text-foreground-500 text-left group-hover:text-current">
                      Consider the subjects the teacher has previously taught.
                    </span>
                  </div>
                </div>
                <input
                  id="SubjectTaught"
                  name="SubjectTaught"
                  type="checkbox"
                  checked={isSubjectTaughtConsidered}
                  className={` shrink-0 border-gray-200 rounded text-secondary focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800`}
                ></input>
              </button>
              <div
                className={`p-8 py-2 w-full ${
                  !isSubjectTaughtConsidered && "hidden"
                }`}
              >
                <Slider
                  size="sm"
                  color="secondary"
                  label={
                    <div className="flex gap-2 items-center">
                      <span>Weights</span>
                      <ResponsiveToolTip
                        tooltipMessage="Weights are numerical values that determine the importance of different factors."
                        tooltipTitle="What is Weights?"
                      />
                    </div>
                  }
                  step={1}
                  maxValue={100}
                  minValue={1}
                  value={weights.subjectTaught}
                  onChange={(value) => {
                    setWeights({
                      ...weights,
                      subjectTaught: value
                    });
                  }}
                  className=" w-full"
                />
              </div>
            </div>
            {/* Degree Relevance */}
            <div className=" rounded-lg  text-secondary hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700">
              <button
                onClick={() => {
                  setisDegreeConsidered(!isDegreeConsidered);
                  setIsChanged(true);
                  if (!isDegreeConsidered) {
                    setWeights({ ...weights });
                  } else {
                    ssetTotalWeights({
                      ...totalWeights,
                      degree: 0
                    });
                  }
                }}
                className="flex w-full items-center gap-x-3.5 py-2 px-3"
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="bi bi-mortarboard-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8.211 2.047a.5.5 0 0 0-.422 0l-7.5 3.5a.5.5 0 0 0 .025.917l7.5 3a.5.5 0 0 0 .372 0L14 7.14V13a1 1 0 0 0-1 1v2h3v-2a1 1 0 0 0-1-1V6.739l.686-.275a.5.5 0 0 0 .025-.917z" />
                    <path d="M4.176 9.032a.5.5 0 0 0-.656.327l-.5 1.7a.5.5 0 0 0 .294.605l4.5 1.8a.5.5 0 0 0 .372 0l4.5-1.8a.5.5 0 0 0 .294-.605l-.5-1.7a.5.5 0 0 0-.656-.327L8 10.466z" />
                  </svg>
                </div>
                <div className="flex flex-col">
                  <div className="w-full flex flex-col text-secondary items-start justify-center">
                    <span className="flex-1 text-small font-normal truncate">
                      Degree {totalWeights.degree.toFixed(2)}%
                    </span>
                    <span className="w-full text-tiny text-foreground-500 text-left group-hover:text-current">
                      Assess the teacher’s educational qualifications and
                      degrees.
                    </span>
                  </div>
                </div>
                <input
                  id="PrioritizeMajorsSubjects"
                  name="PrioritizeMajorsSubjects"
                  type="checkbox"
                  checked={isDegreeConsidered}
                  className={` shrink-0 border-gray-200 rounded text-secondary focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800`}
                ></input>
              </button>
              <div
                className={`p-8 py-2 w-full ${!isDegreeConsidered && "hidden"}`}
              >
                <Slider
                  size="sm"
                  color="secondary"
                  label={
                    <div className="flex gap-2 items-center">
                      <span>Weights</span>
                      <ResponsiveToolTip
                        tooltipMessage="Weights are numerical values that determine the importance of different factors."
                        tooltipTitle="What is Weights?"
                      />
                    </div>
                  }
                  step={1}
                  maxValue={100}
                  minValue={1}
                  value={weights.degree}
                  onChange={(value) => {
                    setWeights({
                      ...weights,
                      degree: value
                    });
                  }}
                  defaultValue={50}
                  className=" w-full"
                />
              </div>
            </div>
            {/* Experience */}
            <div className=" rounded-lg  text-secondary hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700">
              <button
                onClick={() => {
                  setisExperienceConsidered(!isExperienceConsidered);
                  setIsChanged(true);
                  if (!isExperienceConsidered) {
                    setWeights({ ...weights });
                  } else {
                    ssetTotalWeights({
                      ...totalWeights,
                      experience: 0
                    });
                  }
                }}
                className="flex w-full items-center gap-x-3.5 py-2 px-3"
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="bi bi-bar-chart-steps"
                    viewBox="0 0 16 16"
                  >
                    <path d="M.5 0a.5.5 0 0 1 .5.5v15a.5.5 0 0 1-1 0V.5A.5.5 0 0 1 .5 0M2 1.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5z" />
                  </svg>
                </div>
                <div className="flex flex-col">
                  <div className="w-full flex flex-col text-secondary items-start justify-center">
                    <span className="flex-1 text-small font-normal truncate">
                      Experience {totalWeights.experience.toFixed(2)}%
                    </span>
                    <span className="w-full text-tiny text-foreground-500 text-left group-hover:text-current">
                      Evaluate the teacher’s relevant professional experience.
                    </span>
                  </div>
                </div>
                <input
                  id="PrioritizeHigherGrades"
                  name="PrioritizeHigherGrades"
                  type="checkbox"
                  checked={isExperienceConsidered}
                  className={`shrink-0 border-gray-200 rounded text-secondary focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800`}
                ></input>{" "}
              </button>
              <div
                className={`p-8 py-2 w-full ${
                  !isExperienceConsidered && "hidden"
                }`}
              >
                <Slider
                  size="sm"
                  color="secondary"
                  label={
                    <div className="flex gap-2 items-center">
                      <span>Weights</span>
                      <ResponsiveToolTip
                        tooltipMessage="Weights are numerical values that determine the importance of different factors."
                        tooltipTitle="What is Weights?"
                      />
                    </div>
                  }
                  step={1}
                  maxValue={100}
                  minValue={1}
                  value={weights.experience}
                  onChange={(value) => {
                    setWeights({
                      ...weights,
                      experience: value
                    });
                  }}
                  className=" w-full"
                />
              </div>
            </div>
            {/* Preferrences */}
            <div className=" rounded-lg  text-secondary hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700">
              <button
                onClick={() => {
                  setisPreferencesConsidered(!isPreferencesConsidered);

                  setIsChanged(true);
                  if (!isPreferencesConsidered) {
                    setWeights({ ...weights });
                  } else {
                    ssetTotalWeights({
                      ...totalWeights,
                      preference: 0
                    });
                  }
                }}
                className="flex w-full items-center gap-x-3.5 py-2 px-3"
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="bi bi-search-heart"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.5 4.482c1.664-1.673 5.825 1.254 0 5.018-5.825-3.764-1.664-6.69 0-5.018" />
                    <path d="M13 6.5a6.47 6.47 0 0 1-1.258 3.844q.06.044.115.098l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1-.1-.115h.002A6.5 6.5 0 1 1 13 6.5M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11" />
                  </svg>
                </div>
                <div className="flex flex-col">
                  <div className="w-full flex flex-col text-secondary items-start justify-center">
                    <span className="flex-1 text-small font-normal truncate">
                      Preference {totalWeights.preferences.toFixed(2)}%
                    </span>
                    <span className="w-full text-tiny text-foreground-500 text-left group-hover:text-current">
                      Include the teacher’s preferred or specialized teaching
                      areas.
                    </span>
                  </div>
                </div>
                <input
                  id="PrioritizeHigherGrades"
                  name="PrioritizeHigherGrades"
                  type="checkbox"
                  checked={isPreferencesConsidered}
                  className={`shrink-0 border-gray-200 rounded text-secondary focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800`}
                ></input>
              </button>

              <div
                className={`p-8 py-2 w-full ${
                  !isPreferencesConsidered && "hidden"
                }`}
              >
                <Slider
                  size="sm"
                  color="secondary"
                  label={
                    <div className="flex gap-2 items-center">
                      <span>Weights</span>
                      <ResponsiveToolTip
                        tooltipMessage="Weights are numerical values that determine the importance of different factors."
                        tooltipTitle="What is Weights?"
                      />
                    </div>
                  }
                  step={1}
                  maxValue={100}
                  minValue={1}
                  value={weights.preference}
                  onChange={(value) => {
                    setWeights({
                      ...weights,
                      preference: value
                    });
                  }}
                  defaultValue={50}
                  className=" w-full"
                />
              </div>
            </div>
          </div>

          {/* Apply btn */}
          <div
            className={` ${
              !isChanged && "hidden"
            } flex justify-end space-x-2 pt-3 sticky bottom-0 bg-white`}
          >
            <Button
              onPress={() => {
                setSortPriority(Configs?.priorities || []);
                setPrioNumbering(Configs?.priorities || []);
                setFactors(Configs?.factors || []);
                setWeights(Configs?.weights || []);
                setiscancelled(!iscancelled);
                setIsChanged(false);
              }}
              variant="light"
              size="sm"
            >
              Cancel
            </Button>

            <Button
              isDisabled={factors.length <= 0}
              size="sm"
              color="secondary"
              onPress={() => {
                isChangedSchedConfigs(true);
                setApplyChanges(true);
                setIsChanged(false);
                setIsPopOverOpen(false);
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
}
