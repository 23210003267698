import { Skeleton, User } from "@nextui-org/react";
import AddAdviser from "../modals/assignAdviser";
import { useEffect, useState } from "react";
import CancelModal from "../../../../../Components/GeneralCancellation";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import axios from "axios";
import { storage } from "../../../../../utils/AppwriteConfig/Config";

export default function Adviser({
  sectionName = "section",
  courseName = "course",
  courseid,
  sectionid
}) {
  const { DataServer } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [mainError, setMainError] = useState("");

  const [advisor, setAdvisor] = useState({});
  const [resetAdvisor, setResetAdvisor] = useState(false);

  //Checks if there is an Adviser
  useEffect(() => {
    async function GetAdvisor() {
      try {
        setIsLoading(true);
        const body = {
          params: {
            courseId: courseid,
            sectionId: sectionid
          }
        };
        const adviserResponse = await axios.get(
          `${DataServer}/course/section/advisor`,
          body
        );
        setAdvisor(adviserResponse.data);
      } catch (err) {
        setMainError(err.message);
      } finally {
        setIsLoading(false);
      }
    }
    GetAdvisor();
  }, [DataServer, resetAdvisor]);

  const [imageURL, setImageURL] = useState(null);

  useEffect(() => {
    const loadImage = async () => {
      try {
        if (advisor.userCredentials.profilePicture) {
          const result = storage.getFilePreview(
            "66198605bb373176f557",
            advisor.userCredentials.profilePicture
          );
          setImageURL(result);
        }
      } catch (error) {
        console.error("Error loading image:", error);
      }
    };

    loadImage();
  }, [advisor]);

  //Used to remomve current advisor for the specific course
  async function RemoveAdvier(id, name, course, section) {
    try {
      setIsLoading(true);
      const body = {
        teacherId: id,
        teacherName: name,
        course: course,
        section: section
      };
      console.log(body);
      const asssignTeacherResponse = await axios.put(
        `${DataServer}/user/teacher/advisory/remove`,
        body
      );

      return true;
    } catch (err) {
      setMainError(err.message);
      return false;
    } finally {
      setIsLoading(false);
      setResetAdvisor(!resetAdvisor);
    }
  }

  function Refresher() {
    setResetAdvisor(!resetAdvisor);
  }
  if (isLoading) {
    return (
      <Skeleton className=" bg-secondary-400 rounded-lg p-2 justify-between flex items-center">
        <div className=" size-10"></div>
      </Skeleton>
    );
  }
  return advisor && Object.keys(advisor).length > 0
    ? CurrentAdviser(advisor, imageURL, courseName, sectionName, RemoveAdvier)
    : NoAdviser(courseName, sectionName, courseid, sectionid, Refresher);
}

function CurrentAdviser(
  advisordata,
  profileUrl,
  course,
  section,
  RemoveAdviserFunction
) {
  return (
    <div className=" bg-secondary-400 rounded-lg p-2 justify-between flex items-center">
      <User
        className=" text-white"
        name={
          advisordata?.userCredentials?.Username
            ? advisordata?.userCredentials?.Username
            : "Jane Doe"
        }
        classNames={{ description: "text-[#FFDE59]" }}
        description={`${course} | ${section} Adviser`}
        avatarProps={{
          src: profileUrl
        }}
      />
      <CancelModal
        ModalOpenButtonTitle={"Remove Adviser"}
        CancelBody={`You are currently removing ${advisordata?.userCredentials?.Username} to advise ${course} | ${section}`}
        CancelTitle="Are you sure you want to continue?"
        CancelButtonTitle={`Remove ${advisordata?.userCredentials?.Username}`}
        CancelActionFunction={RemoveAdviserFunction}
        data={{
          teacherId: advisordata?.$id,
          teacherName: advisordata?.userCredentials?.Username,
          course: course,
          section: section
        }}
      />
    </div>
  );
}

function NoAdviser(course, section, courseId, sectionId, reset) {
  return (
    <div className=" bg-secondary-400 rounded-lg p-2 justify-between flex items-center">
      <div className=" flex flex-initial  items-center">
        <div className="">
          <div className=" bg-slate-200 size-10 rounded-full"></div>
        </div>
        <div className=" px-2">
          <div className=" text-sm text-white">No Advisor yet...</div>
          <div className=" text-xs text-[#FFDE59]">
            {course} | {section}
          </div>
        </div>
      </div>
      <AddAdviser
        headerTitleContent="Assign Adviser to "
        buttonContent={<span>Assign Adviser</span>}
        course={course}
        section={section}
        courseid={courseId}
        sectionid={sectionId}
        refresh={reset}
      />
    </div>
  );
}
