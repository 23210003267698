import { useEffect, useState } from "react";
import { storage } from "../../../../utils/AppwriteConfig/Config";

export default function GetFileforDownload({ FileIDtoDownload }) {
  const [isLoading, setisLoading] = useState(true);
  const [MainError, setMainError] = useState();

  const [DowloadableFile, setDownloadableFile] = useState("");

  useEffect(() => {
    setisLoading(true);
    async function fetchFile() {
      try {
        var x = await storage.getFileDownload(
          "65fcd52c23d228e24999",
          FileIDtoDownload
        );
        console.log("DOWNLOAD: ", x.href);
        setDownloadableFile(x.href);
      } catch (error) {
        // Handle any errors
        setMainError(error.message);
      } finally {
        setisLoading(false);
      }
    }
    fetchFile();
  }, [FileIDtoDownload]);
  return DowloadableFile ? (
    <a
      href={DowloadableFile}
      className="bg-[#ffde59] text-black px-4 py-2 rounded-lg"
    >
      Download
    </a>
  ) : (
    <button
      disabled={true}
      className="bg-[#ffde59] text-black px-4 py-2 rounded-lg"
    >
      Loading...
    </button>
  );
}
