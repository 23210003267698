import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Spinner,
  Popover,
  PopoverTrigger,
  PopoverContent,
  AccordionItem,
  Accordion,
  Skeleton,
  Tooltip
} from "@nextui-org/react";
import { useEffect, useState } from "react";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import axios from "axios";
import SubTeacherModal from "./SubTeacherModal";
import { convertTo12HourRange } from "../../../../../utils/helpers/stringformat";
import { ProfilePicture } from "../../../../../Components/ProfilePictureImg";
import ScheduleView from "../sectionViews/ScheduleView";

export default function ScheduleAdjustment({ data, reseter }) {
  const { DataServer } = useAuth();
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();

  useEffect(() => {
    if (data.type && data.data) {
      onOpenChange(true);
    }
  }, [data]);

  function CloseModal() {
    reseter();
    onClose();
  }
  const [internalError, setInternalError] = useState();
  const [MainError, setMainError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingstate, setloadingstate] = useState(false);

  const [tempData, setTempData] = useState({});

  console.log(data);

  async function Submit() {
    if (data.type === "Temporary Leave") {
      console.log(tempData);
      try {
        setIsLoading(true);
        const submitResponse = await axios.put(
          `${DataServer}/info/substitute`,
          tempData
        );
      } catch (error) {
        setInternalError(error.message);
      } finally {
        setIsLoading(false);
        CloseModal();
      }
    } else {
      console.log(tempData);
      try {
        setIsLoading(true);
        const submitResponse = await axios.put(
          `${DataServer}/info/save/retry`,
          tempData
        );
      } catch (error) {
        setInternalError(error.message);
      } finally {
        setIsLoading(false);
        CloseModal();
      }
    }
  }

  return (
    <>
      <Button onPress={onOpen}>Open Modal</Button>
      <Modal
        onClose={CloseModal}
        size={
          data && data.type && data.type === "Temporary Leave" ? "2xl" : "5xl"
        }
        className=" font-poppins"
        scrollBehavior="inside"
        isOpen={isOpen}
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          {(CloseModal) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Applying {data.type}
              </ModalHeader>
              <ModalBody>
                {data && data.type && data.type === "Temporary Leave" ? (
                  <TemporaryAdjustments
                    Cdata={data.data}
                    setError={setMainError}
                    setLoading={setloadingstate}
                    setSubmitData={setTempData}
                  />
                ) : (
                  <FullTimeAdjustments
                    Cdata={data.data}
                    setError={setMainError}
                    setLoading={setloadingstate}
                    setSubmitData={setTempData}
                  />
                )}
              </ModalBody>
              <ModalFooter>
                <Button
                  aria-haspopup="dialog"
                  aria-expanded="false"
                  aria-controls="hs-offcanvas-body-scrolling-with-backdrop"
                  data-hs-overlay="#hs-offcanvas-body-scrolling-with-backdrop"
                  color="danger"
                  variant="light"
                  onPress={CloseModal}
                >
                  Close
                </Button>
                <Button
                  isDisabled={MainError || loadingstate || isLoading}
                  color="secondary"
                  onPress={Submit}
                >
                  {isLoading ? (
                    <div className="flex gap-1">
                      <Spinner size="sm" color=" white" /> Applying...
                    </div>
                  ) : (
                    "Apply"
                  )}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}

function FullTimeAdjustments({
  Cdata = {},
  setError,
  setLoading,
  setSubmitData
}) {
  const { DataServer, user, SchedulingServer } = useAuth();
  const [isLoading, setisLoading] = useState(false);
  const [isALoading, setAisLoading] = useState(false);
  const [isSchedLoading, setSchedisLoading] = useState(false);
  const [isSchedaLoading, setaSchedisLoading] = useState(false);
  const [mainError, setMainError] = useState();

  const [ScheduleData, setScheduleData] = useState({});

  const [contradiction, setContradiction] = useState([]);
  const [Adjustments, setAdjustments] = useState([]);

  const [courses, setCourses] = useState([]);

  const [IsSchedRefresh, setIsSchedRefresh] = useState(false);

  useEffect(() => {
    function isApplaible() {
      if (!contradiction) return true;

      if (contradiction && contradiction.total && contradiction.total > 0)
        return true;
    }

    setError(isApplaible());
  }, [contradiction]);

  useEffect(() => {
    setLoading(isALoading || isLoading || isSchedLoading);
  }, [isALoading, isLoading, isSchedLoading]);

  //Give total adjusted fulltime of the specific user
  useEffect(() => {
    async function getAdjustedTime() {
      try {
        setAisLoading(true);
        const checkResponse = await axios.get(`${DataServer}/info/adjusted`, {
          params: {
            ...Cdata
          }
        });
        console.log(checkResponse.data);
        if (checkResponse.data) setAdjustments(checkResponse.data);
      } catch (error) {
        setMainError(error.message);
      } finally {
        setAisLoading(false);
      }
    }
    if (Cdata) getAdjustedTime();
  }, [Cdata]);

  //Check if any schedule of teacher contradicts to given time
  useEffect(() => {
    async function CheckContradiction() {
      try {
        setisLoading(true);
        const checkResponse = await axios.get(`${DataServer}/info/conflict`, {
          params: {
            ...Cdata
          }
        });
        console.log("GG", checkResponse.data);
        if (checkResponse.data) setContradiction(checkResponse.data);

        if (checkResponse.data.total === 0) {
          const teacherSchedToAdd = {
            day: Cdata.day,
            department: Cdata.currentTeacher.department,
            endHour: Cdata.endHour,
            endMinute: Cdata.endMinute,
            groupSchedule: "",
            isAdjustments: true,
            isSlot: null,
            startHour: Cdata.startHour,
            startMinute: Cdata.startMinute,
            subDate: null,
            substitute: null,
            teacherId: Cdata.currentTeacher.teacherId,
            userId: Cdata.currentTeacher.$id
          };
          setSubmitData({ Cdata, teacherSchedToAdd });
        }
      } catch (error) {
        setMainError(error.message);
      } finally {
        setisLoading(false);
      }
    }
    if (Cdata && (!contradiction || Object.keys(contradiction).length <= 0))
      CheckContradiction();
  }, [Cdata]);

  //Check if any schedule of teacher contradicts to given time
  useEffect(() => {
    async function CheckContradiction() {
      try {
        setaSchedisLoading(true);

        const checkResponse = await axios.get(
          `${DataServer}/info/schedule/retry/section`,
          {
            params: {
              course: contradiction.documents[0].courseSubjectTeacher.course,
              section: contradiction.documents[0].courseSubjectTeacher.section
            }
          }
        );
        console.log("OY", checkResponse.data);

        setScheduleData(checkResponse.data);
      } catch (error) {
        setMainError(error.message);
      } finally {
        setaSchedisLoading(false);
      }
    }
    if (contradiction && contradiction.total && contradiction.total > 0)
      CheckContradiction();
  }, [contradiction]);
  console.log(Cdata);
  //CALL BACKEND SERVER TO CREATE SCHEDULE
  useEffect(() => {
    async function CreateSchedules() {
      try {
        setSchedisLoading(true);

        if (ScheduleData && Object.keys(ScheduleData).length > 0) {
          const teacherSchedToAdd = {
            day: Cdata.day,
            department: ScheduleData.dept,
            endHour: Cdata.endHour,
            endMinute: Cdata.endMinute,
            groupSchedule: "",
            isAdjustments: true,
            isSlot: null,
            startHour: Cdata.startHour,
            startMinute: Cdata.startMinute,
            subDate: null,
            substitute: null,
            teacherId: Cdata.currentTeacher.teacherId,
            userId: Cdata.currentTeacher.$id
          };

          //Arrange Needed Data for Schedule Creation

          let url = `${SchedulingServer}/schedule/create/weeklyschedule/v3`;

          //Request Schedule From Backend Server
          const ScheduleResponse = await axios.post(url, {
            ...ScheduleData,
            teacherSchedToAdd
          });
          console.log("s", ScheduleResponse.data);
          setCourses(ScheduleResponse.data);
        } else {
          setMainError("Required Data Must be Loaded First");
        }
      } catch (error) {
        setMainError(error.message);
      } finally {
        setSchedisLoading(false);
      }
    }

    if (ScheduleData && Object.keys(ScheduleData).length > 0) CreateSchedules();
  }, [ScheduleData, IsSchedRefresh]);
  const teacherSchedToAdd = {
    day: Cdata.day,
    department: Cdata.currentTeacher.deptartment,
    endHour: Cdata.endHour,
    endMinute: Cdata.endMinute,
    groupSchedule: "",
    isAdjustments: true,
    isSlot: null,
    startHour: Cdata.startHour,
    startMinute: Cdata.startMinute,
    subDate: null,
    substitute: null,
    teacherId: Cdata.currentTeacher.teacherId,
    userId: Cdata.currentTeacher.$id
  };

  useEffect(() => {
    const ids = ScheduleData?.toDelete || [];
    console.log({ ids, courses });
    const teacherSchedToAdd = {
      day: Cdata.day,
      department: ScheduleData.dept,
      endHour: Cdata.endHour,
      endMinute: Cdata.endMinute,
      groupSchedule: "",
      isAdjustments: true,
      isSlot: null,
      startHour: Cdata.startHour,
      startMinute: Cdata.startMinute,
      subDate: null,
      substitute: null,
      teacherId: Cdata.currentTeacher.teacherId,
      userId: Cdata.currentTeacher.$id
    };
    console.log(teacherSchedToAdd);
    if (courses && courses.length > 0 && ids.length > 0) {
      setSubmitData({
        ids,
        courses,
        Cdata,
        teacherSchedToAdd,
        dept: ScheduleData.dept
      });
      setError(false);
    }
  }, [courses]);

  const [isOpen, setIsOpen] = useState(false);

  if (isLoading) {
    return (
      <div className=" w-full flex flex-col">
        <div className=" w-full h-full flex items-center justify-center">
          {" "}
          <div className=" flex text-base gap-2 font-medium text-secondary-600 bg-secondary-100 py-3 px-4 rounded-lg ">
            <Spinner size="sm" color="secondary" /> Checking if there is time
            contradiction
          </div>
        </div>
      </div>
    );
  }

  const itemClasses = {
    base: "py-0 w-full",
    title: "font-normal text-medium",
    trigger:
      "px-2 py-0 data-[hover=true]:bg-default-100 rounded-lg h-14 flex items-center",
    indicator: "text-medium",
    content: "text-small px-2"
  };

  const defaultContent =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.";

  return (
    <div className=" w-full flex flex-col gap-1">
      <div className=" w-full flex px-3 py-4 bg-secondary-400 rounded-lg items-center justify-center">
        <div className="flex items-center gap-2">
          <div>
            <ProfilePicture
              userId={Cdata?.currentTeacher?.$id}
              gender={Cdata?.currentTeacher?.gender}
            />
          </div>
          <div className=" flex-1 flex flex-col">
            <div className=" text-base font-medium text-white">
              {Cdata?.currentTeacher?.userCredentials?.Username}
            </div>
            <div className=" text-sm flex text-white">
              {Cdata?.requestReason} |{" "}
              <b className=" ml-1">
                {convertTo12HourRange(
                  Cdata?.startHour,
                  Cdata?.startMinute,
                  Cdata?.endHour,
                  Cdata?.endMinute
                )}
              </b>
            </div>
          </div>
        </div>
        <div className=" flex-1 flex justify-end">
          <div className="flex flex-col gap-2 w-auto">
            <Popover
              placement="bottom-end"
              isOpen={isOpen}
              onOpenChange={(open) => setIsOpen(open)}
            >
              <PopoverTrigger>
                <Button className="bg-[#FFDE59]">
                  Schedule Conflicts & Adjustments
                </Button>
              </PopoverTrigger>
              <PopoverContent className=" w-[350px] p-0">
                <Accordion
                  showDivider={false}
                  className="p-2 flex flex-col gap-1 w-full "
                  variant="shadow"
                  itemClasses={itemClasses}
                >
                  <AccordionItem
                    key="1"
                    aria-label="Connected devices"
                    startContent={
                      <svg
                        aria-hidden="true"
                        fill="none"
                        focusable="false"
                        height="24"
                        role="presentation"
                        viewBox="0 0 24 24"
                        width="24"
                      >
                        <path
                          d="M10 16.95H6.21C2.84 16.95 2 16.11 2 12.74V6.74003C2 3.37003 2.84 2.53003 6.21 2.53003H16.74C20.11 2.53003 20.95 3.37003 20.95 6.74003"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                        <path
                          d="M10 21.4699V16.95"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                        <path
                          d="M2 12.95H10"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                        <path
                          d="M6.73999 21.47H9.99999"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                        <path
                          d="M22 12.8V18.51C22 20.88 21.41 21.47 19.04 21.47H15.49C13.12 21.47 12.53 20.88 12.53 18.51V12.8C12.53 10.43 13.12 9.83997 15.49 9.83997H19.04C21.41 9.83997 22 10.43 22 12.8Z"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                        <path
                          d="M17.2445 18.25H17.2535"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        />
                      </svg>
                    }
                    subtitle={
                      Adjustments &&
                      Adjustments.total &&
                      Adjustments.total > 0 ? (
                        <p className="flex">
                          {Adjustments?.total} total adjustments made
                        </p>
                      ) : (
                        ""
                      )
                    }
                    title="Adjusments"
                  >
                    {isALoading ? (
                      <div className=" w-full flex text-secondary-500">
                        <Spinner size="sm" color="secondary" />
                        Checking adjustments made...
                      </div>
                    ) : (
                      <>
                        {" "}
                        {Adjustments &&
                        Adjustments.total &&
                        Adjustments.total > 0 ? (
                          <div className=" w-full h-full max-h-[200px] p-2 flex flex-col  overflow-x-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500 ">
                            <div>
                              {" "}
                              {Adjustments &&
                                Adjustments.documents &&
                                Adjustments.documents.map((adjustment) => (
                                  <div className=" rounded-lg p-2 bg-slate-100 text-slate-500 flex justify-between w-full">
                                    <div>{adjustment.day}</div>
                                    <div>
                                      {convertTo12HourRange(
                                        adjustment?.startHour,
                                        adjustment?.startMinute,
                                        adjustment?.endHour,
                                        adjustment?.endMinute
                                      )}
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        ) : (
                          <div className="w-full flex justify-center items-center">
                            No adjustments made...
                          </div>
                        )}
                      </>
                    )}
                  </AccordionItem>

                  <AccordionItem
                    key="4"
                    aria-label="Card expired"
                    classNames={{
                      subtitle: "text-danger-600",
                      title: "text-danger-600"
                    }}
                    startContent={
                      <svg
                        aria-hidden="true"
                        fill="none"
                        focusable="false"
                        height="24"
                        role="presentation"
                        viewBox="0 0 24 24"
                        width="24"
                        className="text-danger-600"
                      >
                        <path
                          d="M10 16.95H6.21C2.84 16.95 2 16.11 2 12.74V6.74003C2 3.37003 2.84 2.53003 6.21 2.53003H16.74C20.11 2.53003 20.95 3.37003 20.95 6.74003"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                        <path
                          d="M10 21.4699V16.95"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                        <path
                          d="M2 12.95H10"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                        <path
                          d="M6.73999 21.47H9.99999"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                        <path
                          d="M22 12.8V18.51C22 20.88 21.41 21.47 19.04 21.47H15.49C13.12 21.47 12.53 20.88 12.53 18.51V12.8C12.53 10.43 13.12 9.83997 15.49 9.83997H19.04C21.41 9.83997 22 10.43 22 12.8Z"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                        <path
                          d="M17.2445 18.25H17.2535"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        />
                      </svg>
                    }
                    subtitle={
                      contradiction &&
                      contradiction.total &&
                      contradiction.total > 0 &&
                      `There are ${contradiction.total} conflicts to fix`
                    }
                    title={<p className="flex gap-1 items-center">Conflicts</p>}
                  >
                    {isALoading ? (
                      <div className=" w-full flex text-secondary-500">
                        <Spinner size="sm" color="secondary" />
                        Checking adjustments made...
                      </div>
                    ) : (
                      <>
                        {" "}
                        {contradiction &&
                        contradiction.total &&
                        contradiction.total > 0 ? (
                          <div className=" w-full h-full max-h-[200px] p-2 flex flex-col  overflow-x-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500 ">
                            <div>
                              {" "}
                              {contradiction &&
                                contradiction.documents &&
                                contradiction.documents.map((conflict) => (
                                  <div className=" rounded-lg p-2 bg-danger-100 text-danger-500 flex justify-between w-full">
                                    <div>{conflict.day}</div>
                                    <div>
                                      {convertTo12HourRange(
                                        conflict?.startHour,
                                        conflict?.startMinute,
                                        conflict?.endHour,
                                        conflict?.endMinute
                                      )}
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        ) : (
                          <div className="w-full flex justify-center items-center">
                            No current schedule conflicts...
                          </div>
                        )}
                      </>
                    )}
                  </AccordionItem>
                </Accordion>
              </PopoverContent>
            </Popover>
          </div>
        </div>
      </div>
      <div className=" w-full h-[500px] max-h-full overflow-auto  rounded-lg">
        {contradiction && contradiction.total && contradiction.total > 0 ? (
          <div>
            {isSchedLoading || isSchedaLoading ? (
              <Skeleton className=" w-full h-[400px] rounded-md bg-secondary-200"></Skeleton>
            ) : (
              <>
                {courses && courses.length > 0 ? (
                  courses.map((course) => (
                    <div class=" space-y-3 w-full">
                      <Skeleton
                        className=" rounded-lg "
                        isLoaded={!isSchedLoading}
                      >
                        <div className=" bg-[#72539bcc] text-white px-2 py-3 inline-flex items-center justify-between gap-x-3 w-full font-semibold text-start rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:hs-accordion-active:text-blue-500 dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400">
                          <div>
                            {" "}
                            {course?.courseName || "GRADE LEVEL"} |{" "}
                            {course?.sectionName || "SECTION"}
                          </div>
                          <Tooltip
                            className=" bg-secondary-600 text-white"
                            content="Re-Arrange Schedule"
                          >
                            <Button
                              isIconOnly
                              isDisabled={isLoading}
                              onClick={() => {
                                setIsSchedRefresh(!IsSchedRefresh);
                              }}
                              className=" bg-secondary-600 text-white justify-center items-center rounded-full flex p-2"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                class="bi bi-arrow-clockwise"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"
                                />
                                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                              </svg>
                            </Button>
                          </Tooltip>
                        </div>
                        <div class=" w-full overflow-hidden transition-[height] duration-300">
                          <div className="p-2">
                            <div class=" space-y-3 w-full">
                              <ScheduleView schedules={course.Schedule} />
                            </div>
                          </div>
                        </div>
                      </Skeleton>
                    </div>
                  ))
                ) : (
                  <div>No Created Schedule</div>
                )}
              </>
            )}
          </div>
        ) : (
          <div className=" w-full h-full flex justify-center items-center font-medium text-base px-4 py-3 bg-secondary-100 text-secondary-600">
            There Are no Affected Schedule...
          </div>
        )}
      </div>
    </div>
  );
}

function TemporaryAdjustments({
  Cdata = {},
  setError,
  setLoading,
  setSubmitData
}) {
  const { DataServer } = useAuth();
  const [subjects, setSubjects] = useState([]);
  const [subs, setsubs] = useState({});

  const [isLoading, setIsLoading] = useState(false); // State to handle loading

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    const fetchSubjects = async () => {
      if (Cdata && Cdata.schedules && Cdata.schedules.length > 0) {
        setIsLoading(true); // Set loading to true when fetching starts
        try {
          const subjects = await Promise.all(
            Cdata.schedules.map(async (schedule) => {
              const response = await axios.get(
                `${DataServer}/info/schedule/subjectinfo`,
                {
                  params: {
                    id: schedule.courseSubjectTeacher.$id,
                    SID: schedule.$id
                  }
                }
              );
              return response.data; // Extract data from response
            })
          );
          setSubjects(subjects); // Update the subjects state
        } catch (error) {
          console.error("Error fetching subjects:", error);
        } finally {
          setIsLoading(false); // Stop loading regardless of success or error
        }
      }
    };

    fetchSubjects();
  }, [Cdata.schedules]);

  useEffect(() => {
    const transformedSubjects = subjects.reduce((acc, subject) => {
      acc[subject.id] = null; // Replace `{ ...subject }` with `null` if needed
      return acc;
    }, {});

    console.log(transformedSubjects);
    setsubs(transformedSubjects);
  }, [subjects]);

  useEffect(() => {
    setSubmitData({ subdata: subs, Cdata });
    console.log(Object.values(subs).some((value) => value === null));
    setError(Object.values(subs).some((value) => value === null));
  }, [subs]);
  return (
    <div className="flex flex-col w-full max-w-4xl mx-auto  ">
      {/* Header Section */}
      <div className="flex-initial w-full text-center p-4">
        <h2 className="text-lg font-semibold text-gray-800">
          Subjects needing substitutes for{" "}
          <span className="text-secondary-600">
            {Cdata.currentTeacher.userCredentials.Username}
          </span>{" "}
          on {Cdata.Date}
        </h2>
      </div>

      {/* Content Section */}
      <div className="flex-1 w-full">
        <div className="flex h-full justify-center items-center">
          {isLoading ? (
            // Loading Spinner
            <div className="flex justify-center items-center">
              <Spinner
                size="md"
                color="secondary"
                className="text-secondary"
                title="Loading..."
              >
                <span className="text-secondary">Loading...</span>
              </Spinner>
            </div>
          ) : (
            // Subjects List
            <div className="flex flex-col w-full gap-4">
              {subjects && subjects.length > 0 ? (
                subjects.map((subject, index) => (
                  <div
                    key={index}
                    className="flex justify-between items-center px-6 py-4 bg-secondary-100 rounded-md shadow-sm hover:shadow-md transition-shadow duration-200"
                  >
                    <span className="text-base text-secondary-700 font-medium">
                      {subject?.subjectName}
                    </span>

                    {subs[subject.id] ? (
                      <div className=" p-2 rounded-full bg-secondary-400 text-white">
                        {subs[subject.id].userCredentials.Username}
                      </div>
                    ) : (
                      <SubTeacherModal
                        key={index}
                        data={Cdata.schedules.find(
                          (sched) => sched.$id === subject.id
                        )}
                        updateTeacher={setsubs}
                        subss={subs}
                      />
                    )}
                  </div>
                ))
              ) : (
                // No Subjects Message
                <div className="text-gray-500 text-sm text-center">
                  No subjects currently need substitutes.
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
