import { birthdateformat } from "./stringformat";

export default function Validate(Userinfo, users) {
  let errors = {};
  // Validate Firstname
  if (!Userinfo.firstName.trim()) {
    errors.firstName = "First name is required";
  } else if (Userinfo.firstName.length > 50) {
    errors.firstName = "First name is too long...";
  }

  //validate teacher experience
  if (
    (isNaN(Userinfo.teachingExperience) ||
      Userinfo.teachingExperience === "") &&
    users === "teachers"
  ) {
    errors.teachingExperience = `Min is 20 up to 25 hours`;
  }

  if (!Userinfo.department && users === "teachers") {
    errors.department = `Please select a Department`;
  }
  //validate teacher hour
  if (
    (isNaN(Userinfo.teachingHoursPerWeek) ||
      Userinfo.teachingHoursPerWeek === "" ||
      Userinfo.teachingHoursPerWeek <= 20) &&
    users === "teachers"
  ) {

    errors.teachingHoursPerWeek = `Min is 20 up to 25 hours`;
  }

  // Validate Lastname
  if (!Userinfo.lastName.trim()) {
    errors.lastName = "Last name is required";
  } else if (Userinfo.lastName.length > 50) {
    errors.lastName = "Last name is too long...";
  }

  // Validate Nationality
  if (!Userinfo.nationality.trim()) {
    errors.nationality = "Nationality is required";
  } else if (Userinfo.nationality.length > 15) {
    errors.nationality = "Nationality is too long...";
  }

  // Validate Birthdate
  Userinfo.birthDate = birthdateformat(Userinfo.birthDate, "format");
  const currentYear = new Date().getFullYear();
  const NewcurrentYear = currentYear - 4;
  const isValidFormat = /^\d{4}-\d{2}-\d{2}$/.test(Userinfo.birthDate);
  const year = parseInt(Userinfo.birthDate.split("-")[0], 10);
  if (!Userinfo.birthDate.trim()) {
    errors.birthDate = "Birthdate is required.";
  } else if (!isValidFormat) {
    errors.birthDate = "Please enter a valid date format (DD-MM-YYYY).";
  } else if (year < 1950 || year > NewcurrentYear) {
    errors.birthDate = `Please enter a valid birth year between 1950 and ${NewcurrentYear}.`;
  }

  // Validate Gender
  if (!Userinfo.gender) {
    errors.gender = "Gender is required";
  }
  // Validate Address
  if (!Userinfo.block.trim()) {
    errors.block = "Block is required";
  } else if (!/^\d+$/.test(Userinfo.block.trim())) {
    errors.block = "Numbers only";
  } else if (parseInt(Userinfo.block.trim()) > 1000) {
    errors.block = `Block value cannot exceed 1000`;
  }

  if (!Userinfo.lot.trim()) {
    errors.lot = "Lot is required";
  } else if (!/^\d+$/.test(Userinfo.lot.trim())) {
    errors.lot = "Numbers only";
  } else if (parseInt(Userinfo.lot.trim()) > 1000) {
    errors.lot = `Lot value cannot exceed 1000`;
  }

  if (!Userinfo.subdivision.trim()) {
    errors.subdivision = "Subdivision is required";
  } else if (Userinfo.subdivision.length > 35) {
    errors.subdivision = "Subdivision is too long";
  }

  if (!Userinfo.barangay.trim()) {
    errors.barangay = "Barangay is required";
  } else if (Userinfo.barangay.length > 55) {
    errors.barangay = "Barangay is too long";
  }
  if (!Userinfo.city.trim()) {
    errors.city = "City is required";
  } else if (Userinfo.city.length > 65) {
    errors.city = "City is too long";
  }

  if (!Userinfo.province.trim()) {
    errors.province = "Province is required";
  } else if (Userinfo.province.length > 35) {
    errors.province = "Province is too long..";
  }

  if (Userinfo.role == "employee" || !Userinfo.role.trim()) {
    errors.role = "Role is required";
  }

  // Validate Contact number
  if (!Userinfo.contactNumber.trim()) {
    errors.contactNumber = "Contact Number is required";
  } else if (!/^[1-9][0-9]{9}$/.test(Userinfo.contactNumber.trim())) {
    errors.contactNumber =
      "Contact Number must have 10 digits and cannot start with 0";
  } else if (!Userinfo.contactNumber.startsWith("9")) {
    errors.contactNumber = "Contact Number must be a valid number";
  }

  //Validate ID
  if (Userinfo.employeeId) {
    if (!Userinfo.employeeId.trim()) {
      errors.employeeId = "ID number has not been rendered";
    }
  }
  if (Userinfo.teacherId) {
    if (!Userinfo.teacherId.trim()) {
      errors.teacherId = "ID number has not been rendered";
    }
  }
  if (Userinfo.studentId) {
    if (!Userinfo.studentId.trim()) {
      errors.studentId = "ID number has not been rendered";
    }
  }

  //Validate LRNID

  if (users.slice(0, -1) === "student") {
    const regex = /^\d{12}$/;
    if (Userinfo.lrnId === null || !Userinfo.lrnId.trim()) {
      errors.lrnId = "LRNID is required";
    } else if (!regex.test(Userinfo.lrnId) && !isNaN(Userinfo.lrnId)) {
      errors.lrnId = "LRNID must be a 12 number input";
    }
  }

  // Validate Email
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!Userinfo.userCredentials.Email.trim()) {
    errors.Email = "Email is required";
  } else if (!emailRegex.test(Userinfo.userCredentials.Email)) {
    errors.Email = "Invalid email format";
  }
  return errors;
}
