import { Link, useParams } from "react-router-dom";
import Filter from "./components/CoursenSectionFilter";
import { useEffect, useRef, useState } from "react";
import Tableskeleton from "../../../Components/skeleton/TableRowkeleton";
import {
  Pagination,
  SelectSection,
  Select,
  SelectItem,
  Skeleton,
  Tooltip
} from "@nextui-org/react";
import Archive from "./Actions/Archiveconfirm";
import { useAuth } from "../../../utils/AppwriteConfig/AuthContext";
import TableUserRender from "./components/TableUserRender";
import axios from "axios";
import Error from "../../../Components/ErrorIndicator";
import SelectedCourse from "../DocumentsPage/components/SelectCourse";
import useRealtimeSubscription from "../../../utils/AppwriteConfig/RealtimeServices";
import { functions } from "../../../utils/AppwriteConfig/Config";

export default function UserManagment() {
  /**
   *
   * THIS COMPONENT HANDLES THE GETTING OF USERS
   *
   */

  const { users } = useParams();

  const { server, DataServer, user, setUmLoad } = useAuth();
  let lastPage = localStorage.getItem(`${users.slice(0, -1)}page`) || 1;
  console.log(`${users.slice(0, -1)}page`, lastPage);
  const [isLoading, setisLoading] = useState(false);
  const [Refresh, setRefresh] = useState(false);
  const [MainError, setMainError] = useState();

  const [data, setdata] = useState([]); //User data are stored here
  const [Course, setCourse] = useState("All");
  const [Section, setSection] = useState("All");
  const [AccountRole, setAccountRole] = useState("");

  const [search, setsearch] = useState(""); //User changes
  const [searchQuery, setsearchQuery] = useState(""); //Query to request in the db
  const [Pages, setPages] = useState({
    total: 0,
    current: lastPage
  });

  useEffect(() => {
    if (users === "teachers" || users === "employee" || users === "students") {
      setPages({
        ...Pages,
        current: parseInt(lastPage) || 1
      });
      console.log("ey");
    } else {
      setPages({
        total: 0,
        current: 1
      });
    }
  }, [users]);

  let cancelTokenSource = {};

  // useEffect(() => {
  //   console.log(AccountRole);
  // }, [AccountRole]);

  // used to prevent multiple request, Itll only request after the user is done typing
  useEffect(() => {
    setisLoading(true);
    const timerId = setTimeout(() => {
      // Perform search operation here with the current value of 'search'
      setsearchQuery(search);
    }, 500);

    // Clean up the timer
    return () => clearTimeout(timerId);
  }, [search]);

  //Get user base on role
  useEffect(() => {
    async function GetUsers() {
      try {
        setisLoading(true);
        setUmLoad(true);
        let cusers;
        let endpoint;
        let body;

        const cancelTokenSource = axios.CancelToken.source();
        if (
          users === "teachers" &&
          (AccountRole || (AccountRole && searchQuery)) &&
          AccountRole !== "All"
        ) {
          body = {
            page: Pages.current,
            search: searchQuery,
            role: AccountRole
          };
          endpoint = `/user/teachers/filter`;
        } else if (users === "accounts") {
          // Default get all accounts based on role
          setdata([]);
          console.log("acc");
          body = {
            page: Pages.current,
            search: searchQuery,
            role: AccountRole
          };
          endpoint = `/user/disabled`;
        } else if (
          searchQuery &&
          Course !== "All" &&
          Course &&
          users === "students"
        ) {
          console.log("a");
          // When search query contains characters, this runs
          setdata([]);
          body = {
            page: Pages.current,
            search: searchQuery,
            course: Course,
            section: Section
          };
          endpoint = `/search/v2/${users}/course/search`;
        } else if (searchQuery) {
          console.log("as");
          // When search query contains characters, this runs
          setdata([]);
          body = {
            page: Pages.current,
            search: searchQuery
          };
          endpoint = `/user/search/${users}`;
        } else if (Course !== "All" && Course && users === "students") {
          // When user management is for student in a specific course and section
          setdata([]);
          body = {
            page: Pages.current,
            search: searchQuery,
            course: Course,
            section: Section
          };

          endpoint = `/user/student/enrolled/data`;
        } else {
          // Default get all student/teacher/employee/archives/and account
          setdata([]);
          body = {
            page: Pages.current,
            search: searchQuery
          };
          console.log(users);
          endpoint = `/user/${users}`;
        }

        // // Log the request details for debugging
        console.log(
          "Requesting data from endpoint:",
          endpoint,
          "with body:",
          body
        );
        const getUsers = await axios.get(`${DataServer}${endpoint}`, {
          params: body
        });

        cusers = getUsers.data;

        const totalPages = Math.ceil(cusers.total / 10);
        setPages((prevPages) => ({ ...prevPages, total: totalPages }));

        if (
          Course !== "All" &&
          Course &&
          users === "students" &&
          searchQuery === ""
        ) {
          const filteredData = cusers.documents.filter((item) => {
            return item.student && item.student.deleteFlag === false;
          });
          setdata(filteredData);
        } else {
          setdata(cusers.documents);
        }
      } catch (e) {
        setMainError(e.message);
        console.log("Error fetching users:", e);
      } finally {
        setisLoading(false);
        setUmLoad(false);
      }
    }

    GetUsers();
    return () => {
      if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel("Operation canceled by the user.");
      }
      setdata([]);

      setisLoading(false);
      setUmLoad(false);
    };
  }, [
    Refresh,
    Course,
    Section,
    searchQuery,
    users,
    Pages.current,
    AccountRole
  ]);

  //Get realtime updates
  const handleRealtimeMessage = (message) => {
    console.log("Received Realtime message:", message);
    if (users === "archived") {
      console.log("gg");
      if (
        message.changetype === "update" &&
        (message.payload.deleteFlag === true ||
          message.payload.deleteFlag === false)
      ) {
        refreshtable();
      }
    } else {
      console.log("g");
      refreshtable();
    }
  };

  //Update realtime
  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    users,
    handleRealtimeMessage
  );

  //Filter course and section selection
  const CoursenSection = (course, section) => {
    setCourse(course);
    setSection(section);
  };

  //Used to refresh this component
  const refreshtable = () => {
    setRefresh(!Refresh);
  };

  useEffect(() => {
    setsearch("");
    setAccountRole("All");
  }, [users]);

  return (
    <div className="w-full h-full overflow-hidden bg-white rounded-lg">
      {MainError && <Error errorMessage={MainError} reset={setMainError} />}
      <div className=" flex flex-col max-h-full h-full">
        {/* Header */}
        <div className="flex flex-initial">
          <div className="  sm:block hidden md:flex items-center capitalize flex-initial p-4 text-2xl font-semibold">
            {users.slice(0, -1)} User Management
          </div>
          <div className=" flex flex-1 justify-end p-2 flex-col sm:flex-row items-middle">
            <div className="m-1 flex flex-row flex-wrap items-center">
              {/* For Tab section to sort */}
              {users === "students" && (
                <Filter
                  currentcourse={Course}
                  updatechanges={CoursenSection}
                  isSearch={search}
                  isDataLoaded={!isLoading}
                />
              )}
              {users === "accounts" && (
                <Select
                  size="sm"
                  label="Select a role"
                  className="flex-initial min-w-[150px]"
                  onChange={(e) => {
                    setAccountRole(e.target.value);
                  }}
                  defaultSelectedKeys={["All"]}
                >
                  <SelectItem key={"All"} value={"All"}>
                    All
                  </SelectItem>
                  <SelectItem key={"employee"} value={"employee"}>
                    Employee
                  </SelectItem>
                  <SelectItem key={"teacher"} value={"teacher"}>
                    Teacher
                  </SelectItem>
                  <SelectItem key={"student"} value={"student"}>
                    Student
                  </SelectItem>
                </Select>
              )}
              {users === "teachers" && (
                <Select
                  size="sm"
                  label="department"
                  className="flex-initial min-w-[150px]"
                  onChange={(e) => {
                    setAccountRole(e.target.value);
                  }}
                  defaultSelectedKeys={["All"]}
                >
                  <SelectItem key={"Pre"} value={"Pre"}>
                    Pre-School
                  </SelectItem>
                  <SelectItem key={"Elem"} value={"Elem"}>
                    Elementary
                  </SelectItem>
                  <SelectItem key={"JHS"} value={"JHS"}>
                    Junior High-school
                  </SelectItem>
                  <SelectItem key={"SHS"} value={"SHS"}>
                    Senior High-school
                  </SelectItem>
                </Select>
              )}
            </div>
            <div className="flex sm:flex-initial flex-1 items-center">
              {users !== "archived" && users !== "accounts" && (
                <Link
                  to={`/user/employee/manage/${users}/new`}
                  className=" flex flex-1"
                >
                  <button
                    type="button"
                    className={`bg-[#ffde59] flex-1 sm:w-auto p-2 m-1 sm:flex-initial flex-1 justify-center inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-black hover:bg-[#ffce2a] disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600`}
                  >
                    Add Account
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-plus"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                    </svg>
                  </button>
                </Link>
              )}
            </div>
          </div>
        </div>
        {/* Search */}
        <div className="p-2 flex flex-initial border-t-1 border-b-1">
          <div className="relative  w-full">
            <div className="absolute inset-y-0 left-3 flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
              </svg>
            </div>
            {/* Search user input */}
            <input
              type="text"
              placeholder="Search"
              className="py-2 pl-10 pr-2 border-0 shadow-md block w-full rounded-lg bg-default text-black/90 dark:text-white/90 text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
              value={search}
              onChange={(e) => setsearch(e.target.value)}
            />
            {/* Clear button */}
            {searchQuery && (
              <div
                className="absolute inset-y-0 right-9 flex cursor-pointer items-center "
                onClick={() => {
                  setsearchQuery("");
                  setsearch("");
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-x"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                </svg>
              </div>
            )}
          </div>
        </div>

        {/* Table container */}
        <div className="flex-1 overflow-auto">
          {" "}
          {/* Apply overflow here */}
          <div className="h-full w-full relative">
            <div className="">
              {isLoading ? (
                <Tableskeleton />
              ) : (
                <>
                  {data.length > 0 ? (
                    <TableUserRender
                      Users={data}
                      rerenderTable={refreshtable}
                      isSearch={searchQuery}
                      isloaded={!isLoading}
                    />
                  ) : (
                    <div className="absolute w-full h-full flex justify-center items-center">
                      <span>No {users.slice(0, -1)} here</span>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="flex-initial flex bg justify-center p-1">
          <Skeleton
            isLoaded={!isLoading}
            className=" rounded-full bg-secondary"
          >
            <Pagination
              className=" flex-initial"
              loop
              showControls
              color="secondary"
              total={Pages.total}
              initialPage={1}
              page={Pages.current}
              onChange={(setCurrentPage) => {
                setPages((prevPages) => ({
                  ...prevPages,
                  current: setCurrentPage
                }));

                // Save the current page to localStorage based on user type
                if (users === "teachers") {
                  localStorage.setItem("teacherpage", setCurrentPage);
                } else if (users === "employee") {
                  localStorage.setItem("employeepage", setCurrentPage);
                } else if (users === "students") {
                  localStorage.setItem("studentpage", setCurrentPage);
                }
              }}
            />
          </Skeleton>
        </div>
      </div>
    </div>
  );
}
