import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import Announcementdetails from "../../../AnnouncementPage/components/Announcmentdetails";
import Announcementdashs from "../../../../../Components/Cards/Announcementdash";
import Anouncementdashskeleton from "../../../../../Components/skeleton/DashAnnouncment";
import useRealtimeSubscription from "../../../../../utils/AppwriteConfig/RealtimeServices";

export default function AnnouncementRender() {
  const { server } = useAuth();
  const [isLoading, setisLoading] = useState(false);
  const [announcements, setAnnouncements] = useState([]);
  const [isrefresh, setisrefresh] = useState(true);
  const [SelectedAnnouncement, setSelectedAnnouncement] = useState({});
  const [Selectedimageurl, setSelectedimageurl] = useState();

  const selectannounce = (data, url) => {
    setSelectedAnnouncement(data);
    setSelectedimageurl(url);
    document.querySelector("#Announcementdetails").click();
  };

  const refresh = () => {
    setisrefresh(!isrefresh);
  };

  // Sorting function
  const sortAnnouncements = (announcements) => {
    return announcements.sort((a, b) => {
      // Sort by priority (true comes first)
      if (a.priority && !b.priority) return -1;
      if (!a.priority && b.priority) return 1;
      // If priority is the same, sort by dateAdded in descending order
      if (new Date(a.dateAdded) > new Date(b.dateAdded)) return -1;
      if (new Date(a.dateAdded) < new Date(b.dateAdded)) return 1;
      return 0;
    });
  };

  useEffect(() => {
    async function fetchsubj() {
      try {
        setisLoading(true);
        const response = await axios.get(
          `${server}/v1/announcements/specific`,
          {
            params: { group: "employees", post: true }
          }
        );
        const sortedAnnouncements = sortAnnouncements(response.data.documents);
        setAnnouncements(sortedAnnouncements);
      } catch (error) {
        console.error(error);
      } finally {
        setisLoading(false);
      }
    }
    fetchsubj();
  }, [isrefresh]);

  const handleRealtimeMessage = (message) => {
    console.log("Received Realtime message:", message);

    // Find the index of the announcement in the state array
    const announcementIndex = announcements.findIndex(
      (announcement) => announcement.$id === message.payload.$id
    );

    //IF THIS CODE IS REUSABLE< CHANGE THE SENDTYPE TO A REAL ROLE GETTER
    // Check if the message is a 'create' event
    if (
      message.changetype === "update" &&
      (message.payload.sendType === `employees` ||
        message.payload.sendType === "all") &&
      announcementIndex !== -1 && // Check if announcementIndex is valid
      message.payload.post === announcements[announcementIndex]?.post
    ) {
      // If the announcement is found, update its data
      setAnnouncements((prevAnnouncements) => {
        const updatedAnnouncements = [...prevAnnouncements];
        updatedAnnouncements[announcementIndex] = message.payload;
        return sortAnnouncements(updatedAnnouncements);
      });
    } else if (
      (message.changetype === "delete" ||
        (message.changetype === "update" &&
          message.payload.post !==
            (announcements[announcementIndex]?.post || undefined))) &&
      announcementIndex !== -1
    ) {
      // Remove the announcement with the same $id from the state
      setAnnouncements((prevAnnouncements) =>
        sortAnnouncements(
          prevAnnouncements.filter(
            (announcement) => announcement.$id !== message.payload.$id
          )
        )
      );
    } else if (
      message.changetype === "create" ||
      (message.changetype === "update" &&
        (message.payload.sendType === `employees` ||
          message.payload.sendType === "all") &&
        message.payload.post !==
          (announcements[announcementIndex]?.post || undefined))
    ) {
      // Update the state to include the new announcements
      setAnnouncements((prevAnnouncements) =>
        sortAnnouncements([...prevAnnouncements, message.payload])
      );
    }
  };
  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_ANNOUNCEMENT,
    handleRealtimeMessage
  );

  return (
    <>
      <div className="flex flex-col h-full">
        {!isLoading ? (
          <>
            {announcements && announcements.length > 0 ? (
              announcements.map((announcement, index) => (
                <Announcementdashs
                  key={announcement.$id}
                  data={announcement}
                  select={selectannounce}
                />
              ))
            ) : (
              <div className="h-full w-full flex justify-center items-center">
                No Announcement Posted yet
              </div>
            )}
          </>
        ) : (
          <>
            <Anouncementdashskeleton />
            <Anouncementdashskeleton />
            <Anouncementdashskeleton />
          </>
        )}
      </div>
      {SelectedAnnouncement && (
        <Announcementdetails
          Announcement={SelectedAnnouncement}
          imageurl={Selectedimageurl}
          refreshparent={refresh}
        />
      )}
    </>
  );
}
