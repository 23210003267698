import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Parallax, Pagination, Navigation } from "swiper/modules";

export default function Admission() {
  return (
    <div id="admission">
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff"
        }}
        speed={600}
        parallax={true}
        pagination={{
          clickable: true
        }}
        navigation={true}
        modules={[Parallax, Pagination, Navigation]}
        className="mySwiper relative font-poppins min-h-[500px] sm:h-[500px]"
      >
        <div
          slot="container-start"
          className="parallax-bg h-full float-start absolute bottom-0 left-0 w-[150%] bg-no-repeat object-center bg-center object-cover bg-cover "
          style={{
            "background-image": `url(${require("../../../Img/LandingPage/bsaintro3.jpg")})`
          }}
          data-swiper-parallax="-23%"
        ></div>
        <span className="text-4xl hidden sm:flex text-purple-500 font-bold absolute top-8 left-8">
          Admission
        </span>
        <SwiperSlide className="">
          <TitleDesign
            title={"Excellence in Academic Programs"}
            content={
              "Bulacan Standard Academy (BSA) is committed to delivering a high-quality education through a well-structured and comprehensive curriculum. Our academic programs are carefully designed to develop critical thinking, creativity, and problem-solving abilities. With dedicated and experienced teachers, BSA ensures that students are equipped with the knowledge and skills they need to excel in higher education and their future careers."
            }
          />
        </SwiperSlide>
        <SwiperSlide>
          <TitleDesign
            title={"Holistic Student Development"}
            content={
              "At BSA, we believe in nurturing not only the intellectual growth of our students but also their personal and social development. Our diverse range of extracurricular activities—including arts, sports, and leadership programs—allows students to explore their interests and talents outside the classroom. We aim to develop well-rounded individuals with strong values, confidence, and the ability to collaborate with others in diverse environments."
            }
          />
        </SwiperSlide>
        <SwiperSlide>
          <TitleDesign
            title={"Innovative Learning Environment"}
            content={
              "BSA prides itself on creating a modern, engaging, and interactive learning experience. Our state-of-the-art classrooms, advanced technology, and innovative teaching methods ensure that students are fully immersed in a dynamic learning environment. This approach encourages student participation, enhances academic performance, and prepares them for a rapidly changing world."
            }
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
function TitleDesign({ title, content }) {
  return (
    <div className="  h-full font-poppins max-w-[800px] flex flex-col justify-end ml-10 pb-10 p-4">
      <div className="bg-gray-500/80 text-white p-4 rounded-lg">
        <div
          className="title text-xl font-medium mb-2"
          data-swiper-parallax="-300"
        >
          {title}
        </div>
        <div className="text-l" data-swiper-parallax="-100">
          <p className="text-justify">{content}</p>
        </div>
      </div>
    </div>
  );
}
