export default function TRYLANG() {
  return (
    <div
      style={{
        display: "block",
        height: "100%",
        width: "100%",
        position: "relative",
        backgroundColor: "#f9fafb"
      }}
    >
      {/* Header */}
      <div
        style={{
          backgroundColor: "#7828c8",
          padding: "1rem",
          display: "block",
          position: "sticky",
          top: 0, // Add this line to make it sticky
          zIndex: 1000 // Optional: Ensures the header stays on top of other content
        }}
      >
        <img
          alt="BSA logo"
          src="https://scontent.fmnl9-6.fna.fbcdn.net/v/t39.30808-6/418532473_10228542990412804_9191207637444555333_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=6ee11a&_nc_eui2=AeGKujNBi5sLPXfKWx2hUATWO5X6sQSfr7g7lfqxBJ-vuLng0ptyhTdUKv6kE357VGCV1sbq9cFmvvabXVFVOS16&_nc_ohc=uYFSgXr2a-oQ7kNvgGT5T6q&_nc_ht=scontent.fmnl9-6.fna&_nc_gid=AD7Bh9bKzcnz9pVfnLy2iWW&oh=00_AYD3OLz0SbIW-EYUkrPqPdOuEi0EZ1PbP9lUExyVC17ONg&oe=671BF67C"
          style={{
            width: "3rem",
            height: "3rem",
            borderRadius: "50%",
            display: "inline-block"
          }}
        />
        <span
          style={{
            fontWeight: "600",
            fontSize: "1.25rem",
            color: "white",
            display: "inline-block",
            marginLeft: "1rem",
            margin: "auto",
            verticalAlign: "middle"
          }}
        >
          Bulacan Standard Academy
        </span>
      </div>

      {/* Content */}
      <div
        style={{
          padding: "1rem",
          marginBottom: "1rem",
          overflow: "auto",
          width: "100%",
          scrollbarWidth: "thin"
        }}
      >
        {/* Image Section */}
        <div
          style={{
            width: "100%",
            marginBottom: "1rem",
            textAlign: "center"
          }}
        >
          <div
            style={{
              aspectRatio: "16 / 9",
              width: "100%",
              border: "2px solid #e5e7eb",
              borderRadius: "0.5rem",
              backgroundColor: "#f3f4f6",
              display: "inline-block"
            }}
          >
            <img
              className="w-full h-full object-cover rounded-lg"
              alt="NextUI hero Image"
              src="https://cloud.appwrite.io/v1/storage/buckets/66024b7d93a9464cacb6/files/66398dc0be92285f23a1/view?project=65dfbbf8df393c500651&project=65dfbbf8df393c500651&mode=admin"
              style={{ width: "100%", height: "100%" }}
              // Uncomment below if you have imageurl
              // src={imageurl}
            />
          </div>
        </div>

        {/* Post Details */}
        <div
          style={{
            backgroundColor: "white",
            padding: "1.5rem",
            borderRadius: "0.75rem",
            boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
            display: "block"
          }}
        >
          {/* Who and When */}
          <div
            style={{
              backgroundColor: "#ae7ede",
              padding: "0.75rem",
              borderRadius: "0.5rem",
              position: "relative"
            }}
          >
            <div style={{ display: "inline-block", verticalAlign: "middle" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-person-circle"
                viewBox="0 0 16 16"
                style={{ display: "inline-block", verticalAlign: "middle" }}
              >
                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                <path
                  fillRule="evenodd"
                  d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"
                />
              </svg>
              <span
                style={{
                  fontWeight: 500,
                  marginLeft: "0.5rem",
                  display: "inline-block",
                  verticalAlign: "middle"
                }}
              >
                Posted by John Doe
              </span>
            </div>

            <span
              style={{
                color: "#4a5568",
                position: "absolute",
                right: "1rem",
                top: "50%",
                transform: "translateY(-50%)"
              }}
            >
              October 20, 2024
            </span>
          </div>

          {/* Post Content */}
          <div
            style={{
              marginTop: "4px",
              backgroundColor: "#e4d4f4",
              padding: "1.5rem",
              borderRadius: "0.5rem",
              textAlign: "justify",
              lineHeight: "1.75"
            }}
          >
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
              malesuada ligula ut erat cursus, id interdum lacus iaculis. Nunc
              ac tempus leo. Nullam eleifend, tortor ut rutrum porta, elit lacus
              mollis lacus, et gravida ligula augue et eros. Mauris mattis,
              risus imperdiet iaculis tempus, elit ex volutpat sem, nec euismod
              justo nisi sit amet erat...
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

// https://scontent.fmnl9-6.fna.fbcdn.net/v/t39.30808-6/418532473_10228542990412804_9191207637444555333_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=6ee11a&_nc_eui2=AeGKujNBi5sLPXfKWx2hUATWO5X6sQSfr7g7lfqxBJ-vuLng0ptyhTdUKv6kE357VGCV1sbq9cFmvvabXVFVOS16&_nc_ohc=uYFSgXr2a-oQ7kNvgGT5T6q&_nc_ht=scontent.fmnl9-6.fna&_nc_gid=AD7Bh9bKzcnz9pVfnLy2iWW&oh=00_AYD3OLz0SbIW-EYUkrPqPdOuEi0EZ1PbP9lUExyVC17ONg&oe=671BF67C
// 7828c8, ae7ede, e4d4f4
//https://cloud.appwrite.io/v1/storage/buckets/66024b7d93a9464cacb6/files/66398dc0be92285f23a1/view?project=65dfbbf8df393c500651&project=65dfbbf8df393c500651&mode=admin
