import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell
} from "@nextui-org/react";
import CoursesTooltip from "./renders/CoursesTooltip";
import { useEffect, useState } from "react";
import TecaherSuitabilityTooltip from "./renders/TeacherSuitabilityTooltip";

export default function AssignedSubjectsSpecific({ Data, factors }) {
  // State for storing the data to be displayed in the table

  console.log(factors, Data);
  // Initialize Preline when assignedTeachers or viewType changes
  useEffect(() => {
    require("preline/preline");
  }, [Data]);

  // Initialize HSStaticMethods if available
  useEffect(() => {
    if (window.HSStaticMethods) {
      window.HSStaticMethods.autoInit();
    }
  }, [Data]);
  return (
    <div className="flex flex-col">
      <div className=" w-full overflow-auto">
        <Table
          removeWrapper
          className="px-4 py-2"
          aria-label="Example static collection table"
        >
          <TableHeader>
            <TableColumn className="font-semibold text-black text-center">
              SUBJECT
            </TableColumn>
            <TableColumn className="font-semibold text-black text-center">
              COURSE
            </TableColumn>
            <TableColumn className="font-semibold text-black text-center">
              SECTION
            </TableColumn>
            <TableColumn className="text-center">MINUTES</TableColumn>
            <TableColumn className="text-center">DAYS</TableColumn>
            <TableColumn className="text-center">TOTAL</TableColumn>
          </TableHeader>
          <TableBody>
            <TableRow className="">
              <TableCell className="border-r-1 ">
                <div className="rounded-md p-2 flex flex-col">
                  <span className="font-normal flex-initial">
                    {Data.subjectName}
                  </span>
                </div>
              </TableCell>
              <TableCell className="border-r-1 ">
                <div className="rounded-md p-2 flex flex-col">
                  <span className="font-normal flex-initial">
                    {Data.courseName}
                  </span>
                </div>
              </TableCell>
              <TableCell className="border-r-1 ">
                <div className="rounded-md p-2 flex flex-col">
                  <span className="font-normal flex-initial">
                    {Data.sectionName}
                  </span>
                </div>
              </TableCell>

              <TableCell className="border-r-1">
                <div className="rounded-md p-2 flex flex-col">
                  <span className="font-normal">{Data.subjectTimePerDay}</span>
                </div>
              </TableCell>
              <TableCell className="border-r-1">
                <div className="rounded-md p-2 flex flex-col">
                  <span className="font-normal">{Data.subjectDaysInAWeek}</span>
                </div>
              </TableCell>
              <TableCell>
                <div className="rounded-md p-2 flex flex-col">
                  <span className="font-normal">
                    {Data.totalSubjectTimeInAWeek}
                  </span>
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>

      <TecaherSuitabilityTooltip
        currentFactors={factors}
        suitability={Data}
        isWFull={true}
      />
    </div>
  );
}
