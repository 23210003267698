import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Divider,
  Link,
  Image,
} from "@nextui-org/react";
import AnnouncementRender from "./renders/D_AnnouncementRender";
import ActivitiesRender from "./renders/AcitivitiesRender";
import { GetDateToday } from "../../../../utils/helpers/stringformat";

export default function LeftSide_ActivityLogs() {
  const [selectedDate, setselectedDate] = useState(GetDateToday());
 
  return (
    <Card className="max-w-full font-poppins flex-1 border shadow-lg shadow">
      <CardHeader className="flex gap-3">
        <div className="flex flex-1 items-center justify-between pr-2">
          <p className="text-xl font-semibold">Activity Logs</p>
          <div className="text-small text-default-500">
            Date :
            <input
              type="date"
              defaultValue={GetDateToday()}
              onChange={(e) => {
                setselectedDate(e.target.value);
              }}
              className=" rounded-md border-0  text-default-500"
            />
          </div>
        </div>
      </CardHeader>
      <Divider />
      <CardBody>
        <ActivitiesRender currentdate={selectedDate} />
      </CardBody>
    </Card>
  );
}
