// heres the datastructure that will be recieved by dataToSort
//{
//     courseId: course.$id,
//     sectionId: section.$id,
//     subjectId: subject.$id,
//     teacherId: teacher.$id,
//     teacherDept: teacher.department,
//     courseName: course.courseName,
//     sectionName: section.sectionName,
//     teacherName: `${teacher.userCredentials.Username} - ${teacher.teacherId}`,
//     // currentTeachingTime: ` ${teacher.assignedTeachingTime}/${teacher.teachingHoursPerWeek}`,
//     subjectTimePerDay: subjectTimePerDay,
//     subjectDaysInAWeek: subjectDaysInAWeek,
//     subjectName: subject.subjectName,
//     totalSubjectTimeInAWeek: SUBJECT_MINUTES_PER_WEEK,
//   }
export function ByTeachersView(dataToSort, SchedErr, SchedWarn) {
  const teachersMap = new Map();
  // Main processing loop

  dataToSort.forEach(item => {
    if (item.err) {
      SchedErr.push(item);
      return;
    }

    if (item.teacherId === null) {
      SchedWarn.push(item);
      return;
    }

    // Get or create teacher
    if (!teachersMap.has(item.teacherId)) {
      teachersMap.set(item.teacherId, {
        teacherId: item.teacherId,
        teacherName: item.teacherName,
        teacherDept: item.teacherDept,
        currentTeachingTime: item.currentTeachingTime,
        AssignedSubjects: new Map()
      });
    }

    const teacher = teachersMap.get(item.teacherId);

    // Get or create subject
    if (!teacher.AssignedSubjects.has(item.subjectId)) {
      teacher.AssignedSubjects.set(item.subjectId, {
        subjectId: item.subjectId,
        subjectName: item.subjectName,
        subjectTimePerDay: item.subjectTimePerDay,
        subjectDaysInAWeek: item.subjectDaysInAWeek,
        scores: item.scores,
        totalSubjectTimeInAWeek: item.totalSubjectTimeInAWeek,
        preferenceScore: item.preferenceScore,
        experienceScore: item.experienceScore,
        prefAndExpReason: item.prefAndExpScore,
        degreeRelevanceScore: item.degreeRelevanceScore,
        degreeRelevanceReason: item.degreeRelevanceReason,
        taughtSubjectRelevanceScore: item.taughtSubjectRelevanceScore,
        tuaghtSubjectRelevanceReason: item.tuaghtSubjectRelevanceReason,
        totalSuitability: item.totalSuitability,
        load: []
      });
    }

    const subject = teacher.AssignedSubjects.get(item.subjectId);

    // Add section and course details to the subject's load
    subject.load.push({
      sectionId: item.sectionId,
      sectionName: item.sectionName,
      courseName: item.courseName,
      courseId: item.courseId,
      time: item.totalSubjectTimeInAWeek
    });
  });

  // Transform the Map structure back into arrays
  const transformedData = Array.from(teachersMap.values()).map(teacher => ({
    ...teacher,
    AssignedSubjects: Array.from(teacher.AssignedSubjects.values())
  }));
  return transformedData;
}

export function ByCourseView(dataToSort, SchedErr, SchedWarn) {
  const coursesMap = new Map();

  dataToSort.forEach(item => {
    if (item.err) {
      SchedErr.push(item);
      return;
    }
    if (item.teacherId === null) {
      SchedWarn.push(item);
    }

    // Get or create course
    if (!coursesMap.has(item.courseId)) {
      coursesMap.set(item.courseId, {
        courseName: item.courseName,
        courseId: item.courseId,
        courseLevel: item.courseLevel,
        sections: []
      });
    }

    const course = coursesMap.get(item.courseId);

    // Get or create section within the course
    let section = course.sections.find(sec => sec.sectionId === item.sectionId);
    if (!section) {
      section = {
        sectionName: item.sectionName,
        sectionId: item.sectionId,
        subjects: []
      };
      course.sections.push(section);
    }

    // Add subject to section's subjects
    section.subjects.push({
      id: item.id,
      subjectId: item.subjectId,
      subjectName: item.subjectName,
      subjectTimePerDay: item.subjectTimePerDay,
      subjectDaysInAWeek: item.subjectDaysInAWeek,
      totalSubjectTimeInAWeek: item.totalSubjectTimeInAWeek,
      teacherId: item.teacherId || null,
      teacherName: item.teacherName || null,
      userId: item.userId || null,
      teacherDept: item.teacherDept || null,
      preferenceScore: item.preferenceScore,
      experienceScore: item.experienceScore,
      prefAndExpReason: item.prefAndExpScore,
      degreeRelevanceScore: item.degreeRelevanceScore,
      degreeRelevanceReason: item.degreeRelevanceReason,
      scores: item.scores,
      taughtSubjectRelevanceScore: item.taughtSubjectRelevanceScore,
      tuaghtSubjectRelevanceReason: item.tuaghtSubjectRelevanceReason,
      totalSuitability: item.totalSuitability
    });
  });

  // Transform the Map structure back into an array
  const transformedData = Array.from(coursesMap.values());
  return transformedData;
}

export function SpecificAssignments(dataToSort, SchedErr, SchedWarn) {
  const validData = [];
  dataToSort.forEach(item => {
    // Check if there is an error for this item
    if (item.err) {
      SchedErr.push(item);
      return;
    }
    if (item.teacherId === null) {
      SchedWarn.push(item);
      return;
    }

    validData.push(item);
  });
  return validData;
}
