import { Progress } from "@nextui-org/react";
import { ping, mirage } from "ldrs";

mirage.register();
ping.register();

export default function LoadingInfo({
  title,
  progress,
  isCompleted = false,
  completedMessage = "Complete",
  isPending = false,
  pendingMessage = "Waiting for some data to finish"
}) {
  return (
    <div
      className={`flex flex-col ${
        isCompleted ? "bg-secondary-400" : "bg-secondary-200"
      }  p-2 rounded-lg`}
    >
      <div className=" flex items-center">
        <span className="">
          {isCompleted ? (
            <div className="  p-1 text-slate-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-check2-all"
                viewBox="0 0 16 16"
              >
                <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
              </svg>
            </div>
          ) : (
            <>
              <l-ping
                size="35"
                speed={isPending ? "7" : "2"}
                color="hsl(270 66.67% 47.06% / 1)"
              ></l-ping>
            </>
          )}
        </span>
        <span className={` ${isCompleted && "font-semibold text-slate-100 "}`}>
          {isCompleted ? completedMessage : title}
        </span>
      </div>
      <div>
        {isCompleted ? (
          <div></div>
        ) : (
          <>
            {isPending ? (
              <div className=" ps-6 flex">
                <div className="text-black">
                  <l-mirage
                    size="35"
                    speed="4.5"
                    color="currentColor"
                  ></l-mirage>
                </div>
                {pendingMessage}
              </div>
            ) : (
              <Progress
                size="sm"
                isIndeterminate
                color="secondary"
                aria-label="Loading..."
                className=""
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}
