import { useEffect, useState } from "react";
import { storage } from "../../../utils/AppwriteConfig/Config";

//Card component
export function InfoCard({ img, title, context, isimgonly = false }) {
  const [imgurl, setimgurl] = useState();

  useEffect(() => {
    const loadImage = async () => {
      try {
        if (img) {
          // Load image from storage using data.fileid
          const result = await storage.getFilePreview(
            "66024b7d93a9464cacb6",
            img
          );
          setimgurl(result);
        }
      } catch (error) {}
    };

    loadImage();
  }, [img]);
  return (
    <div className="flex flex-1 h-full">
      <a
        className="flex flex-1 m-4 h-full flex-col group bg-white border shadow-sm rounded-xl overflow-hidden hover:shadow-lg transition dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7]"
        href="#"
      >
        <div className="relative pt-[50%] sm:pt-[60%] lg:pt-[60%] rounded-t-xl overflow-hidden">
          {imgurl ? (
            <img
              className="absolute top-0 start-0 object-cover w-full h-full group-hover:scale-105 transition-transform duration-500 ease-in-out rounded-t-xl"
              src={imgurl}
              alt="Image Description"
            />
          ) : (
            <img
              className="absolute top-0 start-0 object-cover w-full h-full group-hover:scale-105 transition-transform duration-500 ease-in-out rounded-t-xl"
              alt="Image Description"
            />
          )}
          {title && isimgonly ? (
            <div className="p-4 md:p-5 flex flex-col flex-grow">
              <h3 className="bottom-4 left-2 z-50 absolute text-purple-500">
                {title}
              </h3>
            </div>
          ) : (
            ""
          )}
        </div>
        {(title || context) && !isimgonly ? (
          <div className="p-4 md:p-5 flex flex-col flex-grow">
            <h3 className="text-md font-bold text-[#A16AE8] dark:text-white">
              {title}
            </h3>
            <p className="mt-1 text-sm whitespace-pre-wrap breaal text-black dark:text-gray-400">
              {context}
            </p>
          </div>
        ) : (
          ""
        )}
      </a>
    </div>
  );
}

//Life landing section
export function Card() {
  return (
    <div
      data-hs-carousel='{
          "loadingClasses": "opacity-0",
          "isAutoPlay": false
          }'
      className="relative h-auto"
    >
      <div className="relative overflow-hidden w-full h-auto min-h-[400px] bg-white">
        <div className="hs-carousel-body h-auto flex transition-transform duration-1000 opacity-0">
          <div className="hs-carousel-slide flex-shrink-0 w-full md:w-1/2 lg:w-1/3">
            <div className="flex flex-col md:flex-row justify-center flex-wrap overflow-auto bg-gray-100 p-6">
              <InfoCard
                img={require("../../../Img/LandingPage/bsaintro4.jpg")}
                title={"Teamwork Triumphs"}
                context={
                  "Unlocking success through collaborative effort, teamwork in sports showcases the synergy of skill and camaraderie, driving athletes to triumph together on and off the field."
                }
              />
              <InfoCard
                img={require("../../../Img/LandingPage/bsaintro5.jpg")}
                title={"Harmonies of the Heart"}
                context={
                  "Singing is a universal art form that transcends cultures, emotions, and expressions, providing a profound outlet htmlFor creativity and connecting individuals through the shared language of melody and song."
                }
              />
              <InfoCard
                img={require("../../../Img/LandingPage/bsaintro6.jpg")}
                title={"Capturing Creativity"}
                context={
                  "With each stroke, drawing unveils a world where imagination dances freely, transforming blank spaces into vibrant expressions of creativity."
                }
              />
            </div>
          </div>
          <div className="hs-carousel-slide">
            <div className="flex flex-col md:flex-row  justify-center bg-gray-200 p-6">
              <InfoCard
                img={require("../../../Img/LandingPage/bsaintro7.jpg")}
                title={"Team Sports"}
              />
              <InfoCard
                img={require("../../../Img/LandingPage/bsaintro8.jpg")}
                title={"Enjoying Vibes"}
              />
            </div>
          </div>
        </div>
      </div>

      <button
        type="button"
        className="hs-carousel-prev hs-carousel:disabled:opacity-50 disabled:pointer-events-none absolute inset-y-0 start-0 inline-flex justify-center items-center w-[46px] h-full text-gray-800 hover:bg-gray-800/[.1]"
      >
        <span className="text-2xl" aria-hidden="true">
          <svg
            className="w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
          </svg>
        </span>
        <span className="sr-only">Previous</span>
      </button>
      <button
        type="button"
        className="hs-carousel-next hs-carousel:disabled:opacity-50 disabled:pointer-events-none absolute inset-y-0 end-0 inline-flex justify-center items-center w-[46px] h-full text-gray-800 hover:bg-gray-800/[.1]"
      >
        <span className="sr-only">Next</span>
        <span className="text-2xl" aria-hidden="true">
          <svg
            className="w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
            />
          </svg>
        </span>
      </button>

      <div className="hs-carousel-pagination flex justify-center absolute bottom-3 start-0 end-0 space-x-2">
        <span className="hs-carousel-active:bg-[#A16AE8] hs-carousel-active:border-[#A16AE8] w-3 h-3 border border-gray-400 rounded-full cursor-pointer"></span>
        <span className="hs-carousel-active:bg-[#A16AE8] hs-carousel-active:border-[#A16AE8] w-3 h-3 border border-gray-400 rounded-full cursor-pointer"></span>
      </div>
    </div>
  );
}
//Activities landing section
export function ActivitiesCardCarousel() {
  return (
    <div
      data-hs-carousel='{
            "loadingClasses": "opacity-0",
            "isAutoPlay": false
            }'
      className="relative"
    >
      <div className="relative overflow-hidden w-full h-auto min-h-[400px] bg-white">
        <div className="hs-carousel-body h-auto flex transition-transform duration-1000 opacity-0">
          <div className="hs-carousel-slide flex-shrink-0 w-full md:w-1/2 lg:w-1/3">
            <div className="flex flex-col md:flex-row justify-center bg-gray-200 p-6">
              <InfoCard
                img={require("../../../Img/LandingPage/bsaintro7.jpg")}
              />
              <InfoCard
                img={require("../../../Img/LandingPage/bsaintro8.jpg")}
              />
            </div>
          </div>
          <div className="hs-carousel-slide">
            <div className="flex flex-col md:flex-row justify-center bg-gray-200 p-6">
              <InfoCard
                img={require("../../../Img/LandingPage/bsaintro7.jpg")}
              />
              <InfoCard
                img={require("../../../Img/LandingPage/bsaintro8.jpg")}
              />
            </div>
          </div>
        </div>
      </div>

      <button
        type="button"
        className="hs-carousel-prev hs-carousel:disabled:opacity-50 disabled:pointer-events-none absolute inset-y-0 start-0 inline-flex justify-center items-center w-[46px] h-full text-gray-800 hover:bg-gray-800/[.1]"
      >
        <span className="text-2xl" aria-hidden="true">
          <svg
            className="w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
          </svg>
        </span>
        <span className="sr-only">Previous</span>
      </button>
      <button
        type="button"
        className="hs-carousel-next hs-carousel:disabled:opacity-50 disabled:pointer-events-none absolute inset-y-0 end-0 inline-flex justify-center items-center w-[46px] h-full text-gray-800 hover:bg-gray-800/[.1]"
      >
        <span className="sr-only">Next</span>
        <span className="text-2xl" aria-hidden="true">
          <svg
            className="w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
            />
          </svg>
        </span>
      </button>

      <div className="hs-carousel-pagination flex justify-center absolute bottom-3 start-0 end-0 space-x-2">
        <span className="hs-carousel-active:bg-[#A16AE8] hs-carousel-active:border-[#A16AE8] w-3 h-3 border border-gray-400 rounded-full cursor-pointer"></span>
        <span className="hs-carousel-active:bg-[#A16AE8] hs-carousel-active:border-[#A16AE8] w-3 h-3 border border-gray-400 rounded-full cursor-pointer"></span>
      </div>
    </div>
  );
}
