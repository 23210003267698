import axios from "axios";
import { useEffect, useState } from "react";
import Error from "../../../../../Components/ErrorIndicator";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import { Skeleton } from "@nextui-org/react";
import { GetCurrentAcademicYear } from "../../../../../utils/helpers/stringformat";

export default function IsEnrolled({ userid }) {
  const { server, user } = useAuth();

  const [isLoading, setisLoading] = useState(true);
  const [Refresh, setRefresh] = useState(false);
  const [MainError, setMainError] = useState();

  const [CurrentEnrollment, setCurrentEnrollment] = useState();
  const [EnrollmentTotal, setEnrollmentTotal] = useState(0);
  useEffect(() => {
    async function GetEnrollment() {
      try {
        setisLoading(true);
        const enrollments = await axios.get(
          `${server}/v1/student/enrollments/current`,
          {
            params: { accid: userid, academicyear: GetCurrentAcademicYear() },
          }
        );
        
        setEnrollmentTotal(enrollments.data.total);
        setCurrentEnrollment(enrollments.data.documents[0]);
      } catch (e) {
        setMainError(e.message);
      } finally {
        setisLoading(false);
      }
    }
    GetEnrollment();
  }, [userid]);

  useEffect(() => {
    console.log(CurrentEnrollment);
  }, [CurrentEnrollment]);
  return (
    <>
      {MainError && <Error errorMessage={MainError} reset={setMainError} />}
      <Skeleton isLoaded={!isLoading} className="rounded-lg">
        <div className=" w-full h-full ">
          {EnrollmentTotal > 0
            ? CurrentEnrollment?.course?.courseName
            : "Not Enrolled"}
        </div>
      </Skeleton>
    </>
  );
}
