import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Spacer,
  Avatar,
  SelectItem,
  Select
} from "@nextui-org/react";

import { useAuth } from "../../../../utils/AppwriteConfig/AuthContext";
import axios from "axios";
import { id, storage } from "../../../../utils/AppwriteConfig/Config";
import Selectuser from "../../../../Components/Selectuser";
import Error from "../../../../Components/ErrorIndicator";
import { ID } from "appwrite";

export default function NewFile({
  type,
  CurrentDoctype,
  docTypes,
  currentuser,
  refreshparent
}) {
  const { user, server, DataServer, role } = useAuth();
  const { isOpen, onOpen, onClose, onOpenChange } = useDisclosure();

  const [isLoading, setisLoading] = useState(false);
  const [MainError, setMainError] = useState();
  const [Errors, setErrors] = useState({});

  const [currentrole, setcurrentrole] = useState("");

  const [value, setValue] = useState(new Set([]));
  const [data, setdata] = useState({
    creator: user.$id,
    fileTitle: "",
    accountType: "",
    userCredentials: currentuser.$id,
    dateadded: "",
    fileId: "",
    type: CurrentDoctype
  });
  const [NewFile, setNewFile] = useState();
  const [previewUrl, setPreviewUrl] = useState(null);
  const [DocumentTypes, setDocumentTypes] = useState(docTypes);
  if (currentuser) {
    if (currentuser.student) currentuser = currentuser.student.userCredentials;
  }

  //Show the corresponding Document type id in the select input
  useEffect(() => {
    setValue([CurrentDoctype]);
  }, [CurrentDoctype]);

  //Every input changes will update the data usestate
  useEffect(() => {
    setdata({ ...data, type: value.currentKey ? value.currentKey : value[0] });
  }, [value]);
  //Gets all Document Type for a specific role
  useEffect(() => {
    async function getDocumentTypes() {
      try {
        setisLoading(true);
        let Crole = currentuser.role;
        if (Crole) {
          const response = await axios.get(
            `${DataServer}/user/document/${Crole}/DocumentType`
          );
          setDocumentTypes(response.data.documents);
        }
      } catch (error) {
        // Handle any errors
        console.error(error);
      } finally {
        setisLoading(false);
      }
    }
    if (docTypes === undefined && currentuser) {
      getDocumentTypes();
    }
  }, [currentuser]);
  // Get the role of the account
  useEffect(() => {
    try {
      if (currentuser) {
        if (currentuser.role && currentuser?.role.includes("student")) {
          setcurrentrole("Student");
        } else if (currentuser.role && currentuser?.role.includes("teacher")) {
          setcurrentrole("Teacher");
        } else {
          setcurrentrole("Employee");
        }
      }
    } catch (e) {
      setMainError(e.message);
    }
  }, []);

  useEffect(() => {
 
  }, [currentrole]);

  //Used to set role dependeing on the current user role
  useEffect(() => {
    setdata({ ...data, accountType: currentrole });
  }, [currentrole]);

  useEffect(() => {
    setdata({ ...data, userCredentials: currentuser.$id });
  }, [currentuser]);

  //Handles file changes
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setNewFile(file);
    // Check if a file is selected
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        // Set the preview URL to the result of the FileReader
        setPreviewUrl(reader.result);
      };

      // Read the file as a data URL
      reader.readAsDataURL(file);
    }
  };

  //Handles Upload
  const Handleupload = async (e) => {
    if (true) {
      setisLoading(true);
      try {
        //This code saves the image in the storage
        var uploadimage = await storage.createFile(
          "65fcd52c23d228e24999",
          ID.unique(),
          NewFile
        );

        //Gets the id of the stored image and save it to the db
        const Filedoc = {
          creator: user.$id,
          username: currentuser.Username,
          role: currentrole,
          fileTitle: data.fileTitle,
          accountType: data.accountType,
          userCredentials: data.userCredentials,
          fileId: uploadimage.$id,
          type: data.type
        };
        //image details in db
        const response = await axios.post(
          `${DataServer}/user/document/new`,
          Filedoc
        );
        // if (!MainError) {
        onClose();
      } catch (err) {
        setMainError(err.message);
      } finally {
        setisLoading(false);
        refreshparent(true);
        setdata({
          creator: user.$id,
          fileTitle: "",
          accountType: currentrole,
          userCredentials: currentuser.$id,
          dateadded: "",
          fileId: "",
          type: CurrentDoctype
        });
        setNewFile();
      }
    }
  };
  return (
    <>
      {/* Used to show toast error when something went wrong*/}
      {MainError && <Error errorMessage={MainError} reset={setMainError} />}
      <Button className="bg-[#FFDE59] px-6" onPress={onOpen}>
        Upload Files
      </Button>
      <Modal
        size="lg"
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        isKeyboardDismissDisabled
        isDismissable={false}
        hideCloseButton
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Select a file
              </ModalHeader>
              <ModalBody>
                <div className="flex-1 max-h-96 min-h-56  ">
                  {/* Body to render */}
                  <div>
                    <form className=" space-y-2">
                      <div className="p-2 w-full rounded-md bg-[#F4F4F5]">
                        <div className="flex items-center gap-2">
                          <Avatar
                            alt={currentuser.Username}
                            className="flex-shrink-0 "
                            size="sm"
                          />
                          <div className="flex flex-col">
                            <span>{currentuser.Username}</span>
                            <span className="text-default-500 text-tiny">
                              {currentuser.Email}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div>
                        <label
                          for={`sectionname`}
                          className="block text-sm mb-2 dark:text-white font-medium"
                        >
                          File Name
                        </label>

                        <input
                          type="text"
                          name={`sectionname`}
                          id={`sectionname`}
                          className={`py-2 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
                            Errors.name == null
                              ? "border-slate-200"
                              : "border-red-500"
                          }`}
                          required
                          onChange={(e) =>
                            setdata({
                              ...data,
                              fileTitle: e.target.value
                            })
                          }
                        />
                        {Errors.name && (
                          <span className="text-red-500 text-sm mt-1">
                            {Errors.name}
                          </span>
                        )}
                      </div>
                      <div className="pt-[6px] pb-2">
                        <Select
                          labelPlacement="outside"
                          label="Document Type"
                          placeholder="Select a type"
                          className="w-full font-medium"
                          selectedKeys={value}
                          onSelectionChange={setValue}
                        >
                          {DocumentTypes &&
                            DocumentTypes.map((type) => (
                              <SelectItem key={type.$id} value={type.$id}>
                                {type.type}
                              </SelectItem>
                            ))}
                        </Select>
                      </div>
                      <div>
                        <label class="block">
                          <input
                            accept=".doc, .docx, .pdf, .txt"
                            onChange={handleFileChange}
                            type="file"
                            class="block w-full text-sm bg-default-100 p-2 rounded-lg text-black
                            file:me-4 file:py-2 file:px-4
                            file:rounded-lg file:border-0
                            file:text-sm file:font-semibold
                            file:bg-secondary-400 file:text-white
                            hover:file:bg-secondary-300
                            file:disabled:opacity-50 file:disabled:pointer-events-none
                            dark:file:bg-blue-500
                            dark:hover:file:bg-blue-400
                            "
                          />
                        </label>
                      </div>
                    </form>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                {!isLoading && (
                  <Button
                    color="danger"
                    variant="light"
                    onPress={() => {
                      onClose();
                      setdata({
                        creator: user.$id,
                        fileTitle: "",
                        accountType: "",
                        userCredentials: currentuser.$id,
                        dateadded: "",
                        fileId: "",
                        type: CurrentDoctype
                      });
                      setNewFile();
                    }}
                  >
                    Cancel
                  </Button>
                )}

                {isLoading ? (
                  <Button isLoading disabled color="secondary">
                    Uploading
                  </Button>
                ) : (
                  <Button
                    isDisabled={!NewFile || data.fileTitle === "" || !value}
                    color="secondary"
                    onPress={() => {
                      Handleupload();
                    }}
                  >
                    Upload
                  </Button>
                )}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
