import { Button, Spinner, Switch } from "@nextui-org/react";
import Landing from "../../../../LandingPage/Landing";
import Whole from "../../../../LandingPage/containers/Whole";
import { useEffect, useState } from "react";
import Configurations from "../actions/Configuration";
import Card from "../../../../LandingPage/containers/CardsContainer";
import useRealtimeSubscription from "../../../../../utils/AppwriteConfig/RealtimeServices";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import axios from "axios";
import Picture from "../../../../LandingPage/containers/Picture";

export default function ContainerViewer({ view, isedit }) {
  const {
    server,
    user,
    isContainerLoading,
    setisContainerLoading,
    isItemLoading,
  } = useAuth();

  const [isLoading, setisLoading] = useState(false);
  const [MainError, setMainError] = useState();

  const [isConfig, setisConfig] = useState(false);
  const [isShow, setisShow] = useState(); //This Gets the Landing page status
  const [isRefresh, setisRefresh] = useState(false);

  //This should be one selected data that came from data
  const [data, setdata] = useState({});
  useEffect(() => {
    setdata(view);
  }, [view, isRefresh]);

  //GET CONTAINERS
  useEffect(() => {
    async function getStatus() {
      try {
        setisLoading(true);
        const Landing = await axios.get(`${server}/v1/landingstatus`);

        setisShow(Landing.data.documents[0].isActive);
      } catch (e) {
        setMainError(e.message);
      } finally {
        setisLoading(false);
      }
    }

    view.name === "Landing" && getStatus();
  }, [view]);

  //UPDATE ACTIVE STATUS OF LANING PAGE EVERYTIME IT IS CLICKED
  const updateActivestatus = async () => {
    try {
      setisLoading(true);
      console.log(user.name);
      const data = { isActive: !isShow, creator: user.$id, name: user.name };
      const Landing = await axios.post(
        `${server}/v1/landingstatus/update`,
        data
      );
      console.log(Landing);
    } catch (e) {
      setMainError(e.message);
    } finally {
      setisLoading(false);
    }
  };

  const handleRealtimechanges = (changes) => {
    // Check if the changes affect the currently selected data
    if (changes.changetype === "update" && changes.payload.name === "Landing") {
      setisShow(changes.payload.isActive);
    }
    if (data && changes.payload.$id === data.$id) {
      // If the change type is "update", update the selected data with the payload
      if (changes.changetype === "update") {
        setdata(changes.payload);
        if (changes.payload.name === "Landing") {
          console.log(changes.payload);
          setisShow(changes.payload.isActive);
        }
      }
      // If the change type is "delete", set the data to null
      else if (changes.changetype === "delete") {
        setdata(null);
      }
    }
  };

  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_CONTAINER,
    handleRealtimechanges
  );

  return data ? (
    <div className=" p-2  w-full bg-white rounded-lg">
      {/* HEADER */}
      <div className=" w-full flex justify-between sm:flex-row flex-col p-2 ">
        <div className="text-lg font-medium flex space-x-2 items-center">
          <span>{data.name}</span>
          {"  "}
          {data.name === "Landing" && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              fill={isShow ? "#4CAF50" : "#F44336"}
              class="bi bi-circle-fill"
              viewBox="0 0 16 16"
            >
              <circle cx="8" cy="8" r="8" />
            </svg>
          )}
          {data.name !== "Landing" && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              fill={data?.isActive ? "#4CAF50" : "#F44336"}
              class="bi bi-circle-fill"
              viewBox="0 0 16 16"
            >
              <circle cx="8" cy="8" r="8" />
            </svg>
          )}
          {(isContainerLoading || isItemLoading) && (
            <Spinner size="sm" color="secondary"></Spinner>
          )}
        </div>
        <div className=" space-x-2">
          {data.name === "Landing" && (
            <Switch
              isDisabled={isLoading}
              isSelected={isShow}
              onValueChange={() => {
                setisShow(!isShow);
                updateActivestatus();
              }}
            >
              Activate
            </Switch>
          )}
          <Button
            isDisabled={
              data.name === "Landing" || isItemLoading || isContainerLoading
            }
            onPress={() => {
              setisConfig(!isConfig);
            }}
          >
            {isConfig ? (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-eye-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                  <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                </svg>
                View {data?.name}
              </>
            ) : (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-sliders2"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.5 1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4H1.5a.5.5 0 0 1 0-1H10V1.5a.5.5 0 0 1 .5-.5M12 3.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m-6.5 2A.5.5 0 0 1 6 6v1.5h8.5a.5.5 0 0 1 0 1H6V10a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5M1 8a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2A.5.5 0 0 1 1 8m9.5 2a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V13H1.5a.5.5 0 0 1 0-1H10v-1.5a.5.5 0 0 1 .5-.5m1.5 2.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5"
                  />
                </svg>
                Configuration
              </>
            )}
          </Button>
        </div>
      </div>

      {/* VIEW CONTENT */}
      <div className=" w-full min-h-[300px]">
        <div
          className={`${
            data.type === "Whole" && isConfig === false ? "" : "hidden"
          }`}
        >
          <Whole container={data} specific={true} />
        </div>
        <div
          className={`${
            data.type === "Card" && isConfig === false ? "" : "hidden"
          }`}
        >
          <Card container={data} />
        </div>
        <div
          className={`${
            data.type === "Picture" && isConfig === false ? "" : "hidden"
          }`}
        >
          <Picture container={data} />
        </div>

        <div
          className={`${data.name === "Landing" ? "" : "hidden"} w-full h-full`}
        >
          <Landing isenabled={false} click={data.name} />
        </div>

        {isConfig && view?.name !== "Landing" && (
          <Configurations container={data} />
        )}
      </div>
    </div>
  ) : (
    <div className="bg-slate-200 w-full h-[200px] flex justify-center items-center">
      Please Select A View
    </div>
  );
}
