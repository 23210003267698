import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../utils/AppwriteConfig/AuthContext";
import ForgotPassword from "./forgotPassword";

export default function Login() {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  useEffect(() => {
    require("preline/preline");
  }, [isOpen]);

  useEffect(() => {
    if (window.HSStaticMethods) {
      window.HSStaticMethods.autoInit();
    }
  }, [isOpen]);

  const { user, loginerr, loginUser } = useAuth();
  const [data, setData] = useState({
    email: "",
    password: ""
  });

  useEffect(() => {
    if (loginerr.show) {
      document.querySelector("#loginbtn").click();
    }
  }, [loginerr]);

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  // Login function
  function login(e) {
    e.preventDefault();
    const validationErrors = validateForm(data);
    if (Object.keys(validationErrors).length === 0) {
      // Proceed with login if no errors
      loginUser(data);
      // user.login(data.email, data.password);
    } else {
      setErrors(validationErrors);
    }
  }

  // Form validation
  function validateForm(data) {
    let errors = {};

    if (!data.email.trim()) {
      errors.email = "Email or User ID is required";
    }

    if (!data.password.trim()) {
      errors.password = "Password is required";
    } else if (data.password.length < 8) {
      errors.password = "Password must be at least 8 characters long";
    }

    return errors;
  }

  // Update data state and remove corresponding error
  function handleChange(e) {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value
    }));
    // Remove error if input is corrected
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined
    }));
  }

  return (
    <>
      <Button
        onPress={onOpen}
        id="loginbtn"
        className="text-sm z-50 hidden font-semibold rounded-lg border border-transparent bg-[#FFDE59] text-black hover:bg-[#fcd84b] disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
      >
        Login
      </Button>
      <Modal
        size="sm"
        isOpen={isOpen}
        placement="center"
        className="p-0 "
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalBody className="p-0">
                <div className="bg-white m-0 p-4 border border-gray-200 rounded-xl shadow-sm dark:bg-gray-800 dark:border-gray-700">
                  <div className="p-4 sm:p-7">
                    <div className="text-center">
                      <div className="flex flex-row flex-wrap flex-1 justify-center">
                        <h2 className="text-2xl font-bold whitespace-nowrap text-gray-800 dark:text-gray-200 pr-[5px]">
                          Log in to{" "}
                        </h2>
                        <h2 className="text-2xl font-bold text-[#A16AE8] dark:text-gray-200">
                          Edusync
                        </h2>
                      </div>
                    </div>
                    <span
                      className={`${
                        loginerr.show ? "" : "hidden"
                      } w-full text-sm flex p-2 justify-center items-center mt-1 bg-red-200 rounded-lg`}
                    >
                      {loginerr.err.message}{" "}
                      {/* Access the message property of the error object */}
                    </span>

                    <div className="mt-5">
                      <form>
                        <div className="grid gap-y-4">
                          <div>
                            <label
                              htmlFor="email"
                              className="block text-sm mb-2 dark:text-white"
                            >
                              Email
                            </label>
                            <div className="relative">
                              <input
                                type="email"
                                id="email"
                                name="email"
                                placeholder="Enter Email"
                                className={`py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
                                  errors.email ? "border-red-500" : ""
                                }`}
                                required
                                aria-describedby="email-error"
                                value={data.email}
                                onChange={handleChange}
                              />
                              {errors.email && (
                                <div className="absolute inset-y-0 end-0 flex items-center pointer-events-none pe-3">
                                  <svg
                                    className="h-5 w-5 text-red-500"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    viewBox="0 0 16 16"
                                    aria-hidden="true"
                                  >
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                                  </svg>
                                </div>
                              )}
                            </div>
                            {errors.email && (
                              <p
                                className="text-xs text-red-600 mt-2"
                                id="email-error"
                              >
                                {errors.email}
                              </p>
                            )}
                          </div>
                          <div>
                            <div className="flex justify-between items-center">
                              <label
                                htmlFor="password"
                                className="block text-sm mb-2 dark:text-white"
                              >
                                Password
                              </label>
                            </div>
                            <div class="relative">
                              <input
                                id="hs-toggle-password"
                                type="password"
                                name="password"
                                className={`py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
                                  errors.password ? "border-red-500" : ""
                                }`}
                                onChange={handleChange}
                                placeholder="Enter password"
                              />
                              <button
                                type="button"
                                data-hs-toggle-password='{
                                    "target": "#hs-toggle-password"
                                  }'
                                className="absolute top-0 end-0 p-3.5 h-full hover:bg-slate-300 rounded-e-md"
                              >
                                <svg
                                  class="flex-shrink-0 size-3.5 text-gray-400 dark:text-neutral-600"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                >
                                  <path
                                    class="hs-password-active:hidden"
                                    d="M9.88 9.88a3 3 0 1 0 4.24 4.24"
                                  ></path>
                                  <path
                                    class="hs-password-active:hidden"
                                    d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"
                                  ></path>
                                  <path
                                    class="hs-password-active:hidden"
                                    d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"
                                  ></path>
                                  <line
                                    class="hs-password-active:hidden"
                                    x1="2"
                                    x2="22"
                                    y1="2"
                                    y2="22"
                                  ></line>
                                  <path
                                    class="hidden hs-password-active:block"
                                    d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"
                                  ></path>
                                  <circle
                                    class="hidden hs-password-active:block"
                                    cx="12"
                                    cy="12"
                                    r="3"
                                  ></circle>
                                </svg>
                              </button>
                              {errors.password && (
                                <div className="hidden absolute inset-y-0 end-0 flex items-center pointer-events-none pe-3">
                                  <svg
                                    className="h-5 w-5 text-red-500"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    viewBox="0 0 16 16"
                                    aria-hidden="true"
                                  >
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                                  </svg>
                                </div>
                              )}
                            </div>
                            {/* <div className="relative">
                              <input
                                type="password"
                                id="password"
                                name="password"
                                className={`py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
                                  errors.password ? "border-red-500" : ""
                                }`}
                                required
                                aria-describedby="password-error"
                                value={data.password}
                                onChange={handleChange}
                              />
                              {errors.password && (
                                <div className="hidden absolute inset-y-0 end-0 flex items-center pointer-events-none pe-3">
                                  <svg
                                    className="h-5 w-5 text-red-500"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    viewBox="0 0 16 16"
                                    aria-hidden="true"
                                  >
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                                  </svg>
                                </div>
                              )}
                            </div> */}
                            {errors.password && (
                              <p
                                className="text-xs text-red-600 mt-2"
                                id="password-error"
                              >
                                {errors.password}
                              </p>
                            )}
                          </div>
                          <div className="flex items-center">
                            <div className="flex">
                              <input
                                id="remember-me"
                                name="remember-me"
                                type="checkbox"
                                className="shrink-0 mt-0.5 border-gray-200 rounded dark:bg-gray-800 dark:border-gray-700 dark:focus:ring-offset-gray-800"
                              />
                            </div>
                            <div className=" flex w-full justify-between">
                              <div className="ms-3">
                                <label
                                  htmlFor="remember-me"
                                  className="text-sm dark:text-white"
                                >
                                  Remember me
                                </label>
                              </div>
                              <ForgotPassword />
                            </div>
                          </div>
                          <div className=" px-16">
                            <button
                              type="submit"
                              onClick={login}
                              className="w-full py-3 px-4 inline-flex bg-[#ffde59]  text-black justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent hover:bg-[#ffce2a] disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                            >
                              Sign in
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
