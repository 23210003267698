import React, { Suspense, useEffect, useState } from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  getKeyValue,
  Spinner,
  Pagination
} from "@nextui-org/react";
import LoginLoading from "../../../../Statuspages/LoginLoading";
import axios from "axios";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import Error from "../../../../../Components/ErrorIndicator";
import Viewdocument from "../../../DocumentsPage/components/Viewdocument";
import useRealtimeSubscription from "../../../../../utils/AppwriteConfig/RealtimeServices";
export default function EnrolledStudentTable({
  course,
  section,
  status,
  searchs
}) {
  const { server } = useAuth();

  const [isLoading, setisLoading] = useState(false);
  const [MainError, setMainError] = useState("");
  const [isrefresh, setisrefresh] = useState(false);

  const [selectedKeys, setSelectedKeys] = useState([]);
  const [selectedStudent, setselectedStudent] = useState({});
  const [Students, setStudents] = useState([]);
  const [total, settotal] = useState(0);
  const [searchQuery, setsearchQuery] = useState("");
  const [search, setSearch] = useState("");
  const [Pages, setPages] = useState({
    total: 0,
    current: 1
  });

  //Used to delay to prevent multiple requests
  useEffect(() => {
    setisLoading(true);

    const timerId = setTimeout(() => {
      // Perform search operation here with the current value of 'search'
     
      setsearchQuery(search);
    }, 500);
    setisLoading(false);
    // Clean up the timer
    return () => clearTimeout(timerId);
  }, [search]);

  useEffect(() => {
    async function GetAssignedStudents() {
      try {
        setisLoading(true);
        status(true);
        const AssignedStudents = await axios.get(
          `${server}/v1/students/enrolled/docs`,
          {
            params: {
              course: course,
              section: section,
              search: searchQuery,
              page: Pages.current
            }
          }
        );
   
        const totalPages = Math.ceil(AssignedStudents.data.total / 10);
        setPages({ ...Pages, total: totalPages });
        status(false);
        setStudents(AssignedStudents.data.documents);
        settotal(AssignedStudents.data.total);
      } catch (e) {
        setMainError(e.message);
      } finally {
        setisLoading(false);
      }
    }

    GetAssignedStudents();
  }, [course, section, searchQuery, Pages.current, isrefresh]);

  useEffect(() => {
    searchs(search);
  }, [search]);

  const handleSelectionChange = (keys) => {

    // Check if "all" option is selected
    if (keys === "all") {
      // Set SelectedStudents to an array containing all student IDs
      setSelectedKeys(Students.map((student) => student.$id));
    } else {
      // Handle the case where individual rows are selected
      setSelectedKeys(Array.from(keys));
    }
  };

  const handleRealtimechanges = (changes) => {

    if (changes.changetype === "create" || changes.changetype === "delete") {
      setisrefresh(!isrefresh);
    }
    if (changes.changetype === "update") {
    }
  };

  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_ENROLLMENT,
    handleRealtimechanges
  );

  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_DOCUMENT,
    handleRealtimechanges
  );

  const Rerenderpage = () => {};

  return (
    <>
      {MainError && <Error errorMessage={MainError} />}

      <div className="flex flex-col">
        <div className=" flex-1 flex space-x-2 p-2">
          <div className="flex-initial flex w-full pr-0 mb-2 border-0 ring-0 sm:mb-0 relative">
            {/* Search icon */}
            <div className="absolute inset-y-0 left-3 flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
              </svg>
            </div>
            {/* Search user input */}
            <input
              disabled={(course && course !== "All") || isLoading}
              type="text"
              placeholder="Search"
              className="py-2 pl-10 pr-2 border-0 shadow-md block w-full rounded-lg bg-default text-black/90 dark:text-white/90 text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
            {/* Clear button */}
            {searchQuery && (
              <div
                className="absolute inset-y-0 right-9 flex cursor-pointer items-center "
                onClick={() => {
                  setSearch("");
                  setsearchQuery("");
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-x"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                </svg>
              </div>
            )}
          </div>
          <div className=" hidden sm:flex flex-initial justify-center">
            {/* Pagination for the documents */}
            <Pagination
              className="flex-initial "
              loop
              isDisabled={isLoading}
              showControls
              color="secondary"
              total={Pages.total}
              initialPage={1}
              page={Pages.current}
              onChange={(setCurrentPage) =>
                setPages((prevPages) => ({
                  ...prevPages,
                  current: setCurrentPage
                }))
              }
            />
          </div>
        </div>
        {isLoading ? (
          <div className="w-full h-full flex p-1 flex-col  rounded-xl  justify-center item-center">
            <div className=" bg-slate-200 p-5 rounded-xl"></div>
            <Spinner color="secondary" className="m-4" />
          </div>
        ) : (
          <>
            {Students && Students.length > 0 ? (
              <Table
                removeWrapper
                selectionBehavior="replace"
                color="secondary"
                aria-label="Controlled table example with dynamic content"
                selectionMode="single"
                selectedKeys={selectedKeys}
                onSelectionChange={handleSelectionChange}
                className=" max-h-full flex-1 "
                title="Students"
              >
                <TableHeader>
                  <TableColumn>Name</TableColumn>
                  <TableColumn>ID</TableColumn>
                  <TableColumn className=" text-center">Documents</TableColumn>
                </TableHeader>

                <TableBody>
                  {Students &&
                    Students.map((student) => (
                      <TableRow
                        key={student.$id}
                        onClick={() => {
                          
                          setselectedStudent(student.userCredentials);
                          document.querySelector("#viewdocs").click();
                        }}
                      >
                        <TableCell>
                          {student && student.userCredentials?.Username}
                        </TableCell>
                        <TableCell>{student && student.studentId}</TableCell>
                        <TableCell className=" text-center">
                          {student && student.totalDocuments}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            ) : (
              <div className="w-full h-full flex  justify-center item-center">
                <span>No Student Assigned to this section...</span>
              </div>
            )}
          </>
        )}
        <div className=" sm:hidden m-2 flex flex-initial justify-center">
          {/* Pagination for the documents */}
          <Pagination
            className="flex-initial "
            loop
            isDisabled={isLoading}
            showControls
            color="secondary"
            total={Pages.total}
            initialPage={1}
            page={Pages.current}
            onChange={(setCurrentPage) =>
              setPages((prevPages) => ({
                ...prevPages,
                current: setCurrentPage
              }))
            }
          />
        </div>
      </div>
      {selectedKeys && selectedStudent && (
        <Viewdocument user={selectedStudent} rerenderparent={Rerenderpage} />
      )}
    </>
  );
}
