import { Card, CardBody, CardHeader, Divider } from "@nextui-org/react";
import { useState } from "react";

export default function MissionVission() {
  const [purpose, setPurpose] = useState("mission");

  return (
    <div className=" flex justify-center items-center h-full w-full ">
      <div className=" pr-2 bg-white rounded-xl">
        <div class="max-w-[85rem] max h-[600px] bg-white rounded-xl px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto overflow-auto">
          <div class="grid md:grid-cols-2 gap-12">
            <div class="lg:w-3/4">
              <h2 class="text-3xl text-secondary-600 font-bold lg:text-4xl dark:text-white">
                Objectives
              </h2>
              <p class="mt-3 text-gray-800 dark:text-neutral-400">
                <b className=" text-secondary-500">BULACAN STANDARD ACADEMY</b>{" "}
                commits itself in:{" "}
              </p>
              <ol className="text-md space-y-2 font-normal list-decimal pl-5 gap-2">
                <li>
                  Promoting mastery of the basic knowledge, skills, and
                  abilities vital to the child’s academic excellence and
                  acceptable values.
                </li>
                <li>
                  Encouraging acceptance of individual uniqueness to enhance
                  harmonious relationships free from pressures and tensions.
                </li>
                <li>
                  Conducting activities that develop skills and attitudes
                  necessary for positive interpersonal and group relationships,
                  recognizing the importance of ethical and moral behavioral
                  standards.
                </li>
                <li>
                  Preparing students for the acquisition of a responsible adult
                  life in line with the global demands of future generations.
                </li>
              </ol>

              {/* <p class="mt-5">
              <a
                class="inline-flex items-center gap-x-1 text-sm text-blue-600 decoration-2 hover:underline focus:outline-none focus:underline font-medium dark:text-blue-500"
                href="#"
              >
                Contact sales to learn more
                <svg
                  class="shrink-0 size-4 transition ease-in-out group-hover:translate-x-1 group-focus:translate-x-1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="m9 18 6-6-6-6" />
                </svg>
              </a>
            </p> */}
            </div>

            <div class="space-y-6 lg:space-y-10">
              <div class="flex gap-x-5 sm:gap-x-8">
                <span class="shrink-0 inline-flex justify-center items-center size-[46px] rounded-full border border-gray-200 bg-white text-secondary-600 shadow-sm mx-auto dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-200">
                  <svg
                    class="shrink-0 size-5"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z" />
                    <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z" />
                  </svg>
                </span>
                <div class="grow">
                  <h3 class="text-base sm:text-lg font-semibold text-secondary-600 dark:text-neutral-200">
                    Mission
                  </h3>
                  <p class="mt-1 text-gray-600 dark:text-neutral-400">
                    Bulacan Standard Academy is committed to the formation of a
                    child's holistic framework to best ensure a lasting heritage
                    of quality education
                  </p>
                </div>
              </div>

              <div class="flex gap-x-5 sm:gap-x-8">
                <span class="shrink-0 inline-flex justify-center items-center size-[46px] rounded-full border border-gray-200 bg-white text-secondary-600 shadow-sm mx-auto dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-200">
                  <svg
                    class="shrink-0 size-5"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M14 9a2 2 0 0 1-2 2H6l-4 4V4c0-1.1.9-2 2-2h8a2 2 0 0 1 2 2v5Z" />
                    <path d="M18 9h2a2 2 0 0 1 2 2v11l-4-4h-6a2 2 0 0 1-2-2v-1" />
                  </svg>
                </span>
                <div class="grow">
                  <h3 class="text-base sm:text-lg font-semibold text-secondary-600 dark:text-neutral-200">
                    Vission
                  </h3>
                  <p class="mt-1 text-gray-600 dark:text-neutral-400">
                    A community of Leaders empowered with 21st Century
                    Knowledge, Skills and Values toward excellence.
                  </p>
                </div>
              </div>

              <div class="flex gap-x-5 sm:gap-x-8">
                <span class="shrink-0 inline-flex justify-center items-center size-[46px] rounded-full border border-gray-200 bg-white text-secondary-600 shadow-sm mx-auto dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-200">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="lucide lucide-brain"
                  >
                    <path d="M12 5a3 3 0 1 0-5.997.125 4 4 0 0 0-2.526 5.77 4 4 0 0 0 .556 6.588A4 4 0 1 0 12 18Z" />
                    <path d="M12 5a3 3 0 1 1 5.997.125 4 4 0 0 1 2.526 5.77 4 4 0 0 1-.556 6.588A4 4 0 1 1 12 18Z" />
                    <path d="M15 13a4.5 4.5 0 0 1-3-4 4.5 4.5 0 0 1-3 4" />
                    <path d="M17.599 6.5a3 3 0 0 0 .399-1.375" />
                    <path d="M6.003 5.125A3 3 0 0 0 6.401 6.5" />
                    <path d="M3.477 10.896a4 4 0 0 1 .585-.396" />
                    <path d="M19.938 10.5a4 4 0 0 1 .585.396" />
                    <path d="M6 18a4 4 0 0 1-1.967-.516" />
                    <path d="M19.967 17.484A4 4 0 0 1 18 18" />
                  </svg>
                </span>
                <div class="grow">
                  <h3 class="text-base sm:text-lg font-semibold text-secondary-600 dark:text-neutral-200">
                    Philosophy
                  </h3>
                  <p class="mt-1 text-gray-600 dark:text-neutral-400">
                    BULACAN STANDARD ACADEMY uphold on every child’s right to
                    quality education, thus, promoting individual worth and
                    dignity as a person. This fundamental principle is a vital
                    instrument for human upliftment in the development of a
                    child’s total personality characterized in his intellectual,
                    emotional, spiritual, social and physical maturity. The
                    abilities, expertise and knowledge gained become beneficial
                    to life’s existence.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

{
  /* <div className="  h-[600px] w-full max-w-[900px] flex flex-col md:flex-row">
        <div className="  lg:p-12 flex-1 flex relative items-center justify-center">
          <div
            className={`${
              purpose !== "mission" && "hidden"
            }    bg-cover object-cover rounded-xl p-6 flex w-full `}
            style={{
              backgroundImage:
                "url('https://images.unsplash.com/photo-1605629921711-2f6b00c6bbf4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&h=1220&q=80')"
            }}
          >
            <div class=" bg-gray-500/70 p-2  rounded-lg text-white">
              <div class="text-lg  font-bold">Mission</div>
              <div class=" sm:text-justify p-2">
                Our commitment that drives us forward with passion and
                determination.
              </div>
            </div>
          </div>
          <div
            className={`${
              purpose !== "vision" && "hidden"
            }    bg-cover object-cover rounded-xl p-6 flex w-full `}
            style={{
              backgroundImage:
                "url('https://images.unsplash.com/photo-1665686306574-1ace09918530?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&h=1220&q=80')"
            }}
          >
            <div class=" bg-gray-500/70 p-2  rounded-lg text-white">
              <div class="text-lg  font-bold">Vision</div>
              <div class=" sm:text-justify p-2">
                Our roadmap to a brighter future which unite our efforts and
                aspirations to turn our vision into reality.
              </div>
            </div>
          </div>
        </div>
        <div className="  p-2 flex-1 flex flex-col justify-center">
          <span class="text-xl text-secondary mb-8 font-bold sm:text-3xl dark:text-neutral-200">
            Discover the driving force behind everything we do
          </span>
          <div className=" flex flex-col gap-2 ">
            <button
              type="button"
              class={`${
                purpose === "mission" && "bg-white hover:bg-white"
              } hs-tab-active:bg-white hs-tab-active:shadow-md hs-tab-active:hover:border-transparent text-start hover:bg-gray-200 p-4 md:p-5 rounded-xl dark:hs-tab-active:bg-neutral-700 dark:hover:bg-neutral-700`}
              onClick={() => {
                setPurpose("mission");
              }}
            >
              <span class="flex">
                <svg
                  class={`${
                    purpose === "mission" && "text-secondary"
                  } flex-shrink-0 mt-2 size-6 md:size-7 hs-tab-active:text-secondary text-secondary-600 dark:hs-tab-active:text-blue-500 dark:text-neutral-200`}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M5 5.5A3.5 3.5 0 0 1 8.5 2H12v7H8.5A3.5 3.5 0 0 1 5 5.5z"></path>
                  <path d="M12 2h3.5a3.5 3.5 0 1 1 0 7H12V2z"></path>
                  <path d="M12 12.5a3.5 3.5 0 1 1 7 0 3.5 3.5 0 1 1-7 0z"></path>
                  <path d="M5 19.5A3.5 3.5 0 0 1 8.5 16H12v3.5a3.5 3.5 0 1 1-7 0z"></path>
                  <path d="M5 12.5A3.5 3.5 0 0 1 8.5 9H12v7H8.5A3.5 3.5 0 0 1 5 12.5z"></path>
                </svg>
                <span class="grow ms-6">
                  <span
                    class={`${
                      purpose === "mission" && "text-secondary"
                    } block text-lg font-semibold hs-tab-active:text-secondary text-gray-800 dark:hs-tab-active:text-blue-500 dark:text-neutral-200`}
                  >
                    Mission
                  </span>
                  <span class="block mt-1 text-gray-800 dark:hs-tab-active:text-gray-200 dark:text-neutral-200">
                    Our commitment that drives us forward with passion and
                    determination.
                  </span>
                </span>
              </span>
            </button>
            <button
              type="button"
              class={`${
                purpose === "vision" && "bg-white hover:bg-white"
              } hs-tab-active:bg-white hs-tab-active:shadow-md hs-tab-active:hover:border-transparent text-start hover:bg-gray-200 p-4 md:p-5 rounded-xl dark:hs-tab-active:bg-neutral-700 dark:hover:bg-neutral-700`}
              onClick={() => {
                setPurpose("vision");
              }}
            >
              <span class="flex">
                <svg
                  class={`${
                    purpose === "vision" && "text-secondary"
                  } flex-shrink-0 mt-2 size-6 md:size-7 hs-tab-active:text-secondary text-gray-800 dark:hs-tab-active:text-blue-500 dark:text-neutral-200`}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z"></path>
                  <path d="M5 3v4"></path>
                  <path d="M19 17v4"></path>
                  <path d="M3 5h4"></path>
                  <path d="M17 19h4"></path>
                </svg>
                <span class="grow ms-6">
                  <span
                    class={`${
                      purpose === "vision" && "text-secondary"
                    } block text-lg font-semibold hs-tab-active:text-secondary text-gray-800 dark:hs-tab-active:text-blue-500 dark:text-neutral-200`}
                  >
                    Vision
                  </span>
                  <span class="block mt-1 text-gray-800 dark:hs-tab-active:text-gray-200 dark:text-neutral-200">
                    our roadmap to a brighter future which unite our efforts and
                    aspirations to turn our vision into reality.
                  </span>
                </span>
              </span>
            </button>
          </div>
        </div>
      </div> */
}
