import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure
} from "@nextui-org/react";
import axios from "axios";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import Error from "../../../../../Components/ErrorIndicator";
import { birthdateformat } from "../../../../../utils/helpers/stringformat";

export default function ResetPasswordModal({ UserDetails }) {
  const { user } = useAuth();
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();

  const { DataServer } = useAuth();
  const [isLoading, setisLoading] = useState(false);
  const [MainError, setMainError] = useState();

  const ResetPassword = async () => {
    if (UserDetails) {
      try {
        setisLoading(true);
        const data = {
          creator: user.name,
          creatorid: user.$id,
          userData: UserDetails,
          passdate: birthdateformat(UserDetails.birthDate, "format")
        };
        const User = await axios.post(
          `${DataServer}/user/password/reset`,
          data
        );
      } catch (e) {
        setMainError(e.message);
      } finally {
        setisLoading(false);
        onClose();
      }
    }
  };

  return (
    <>
      {MainError && <Error errorMessage={MainError} reset={setMainError} />}
      <Button
        onPress={onOpen}
        className="py-3 px-4 bg-[#FFDE59] text-black text-sm font-semibold hover:bg-[#fcd84b] capitalize block  text-center w-full h-[45px] border-gray-200 rounded-lg"
      >
        Reset Password
      </Button>
      <Modal
        isOpen={isOpen}
        className=" font-poppins"
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 pb-0">
                Password Reset
              </ModalHeader>
              <ModalBody>
                <p>
                  The password of {UserDetails?.userCredentials?.Username} will
                  be set to default.
                </p>
              </ModalBody>
              <ModalFooter className="pt-0">
                {isLoading ? (
                  <Button
                    isLoading
                    color="secondary"
                    spinner={
                      <svg
                        className="animate-spin h-5 w-5 text-current"
                        fill="none"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          fill="currentColor"
                        />
                      </svg>
                    }
                  >
                    Loading
                  </Button>
                ) : (
                  <>
                    <Button color="danger" variant="light" onPress={onClose}>
                      Close
                    </Button>
                    <Button color="secondary" onPress={ResetPassword}>
                      Reset
                    </Button>
                  </>
                )}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
