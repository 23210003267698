import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Divider,
  Link,
  Image,
  Button
} from "@nextui-org/react";
import Announcement from "../../../../Components/modals/Announcements";
import Announcements from "../../../../Components/modals/Announcements";
import LatestAnnouncementRender from "../../../../Components/Renders/LatestAnnouncementRender";

import { useAuth } from "../../../../utils/AppwriteConfig/AuthContext";
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Announcementskeleton from "../../../../Components/skeleton/Announcement";
import useRealtimeSubscription from "../../../../utils/AppwriteConfig/RealtimeServices";
import Announcementdetails from "../../../{Employee}/AnnouncementPage/components/Announcmentdetails";
export default function LatestAnnouncements() {
  const { server, role, user } = useAuth();
  const [isLoading, setisLoading] = useState(false);
  const [announcements, setAnnouncements] = useState([]);
  const sortAnnouncements = (announcements) => {
    return announcements.sort((a, b) => {
      // Sort by priority (true comes first)
      if (a.priority && !b.priority) return -1;
      if (!a.priority && b.priority) return 1;
      // If priority is the same, sort by dateAdded in descending order
      if (new Date(a.dateAdded) > new Date(b.dateAdded)) return -1;
      if (new Date(a.dateAdded) < new Date(b.dateAdded)) return 1;
      return 0;
    });
  };

  const selectannounce = (data, url) => {
    setSelectedAnnouncement(data);
    setSelectedimageurl(url);
    document.querySelector("#Announcementdetails").click();
  };

  useEffect(() => {
    async function fetchsubj() {
      try {
        setisLoading(true);

        const response = await axios.get(`${server}/v1/announcements`, {
          params: { group: `${role}s`, post: true }
        });
        //sort data
        const sortedAnnouncements = sortAnnouncements(response.data.documents);
        setAnnouncements(sortedAnnouncements);
      } catch (error) {
        // Handle any errors
        console.error(error);
      } finally {
        setisLoading(false);
      }
    }
    fetchsubj();
  }, []);

  const [isrefresh, setisrefresh] = useState(true);
  const [Selectedimageurl, setSelectedimageurl] = useState();
  const refresh = () => {
    setisrefresh(!isrefresh);
  };
  const [SelectedAnnouncement, setSelectedAnnouncement] = useState({});
  //specific

  useEffect(() => {
    async function fetchsubj() {
      try {
        setisLoading(true);
        const response = await axios.get(
          `${server}/v1/announcements/specific`,
          {
            params: { group: "employees", post: true }
          }
        );
        const sortedAnnouncements = sortAnnouncements(response.data.documents);
        setAnnouncements(sortedAnnouncements);
      } catch (error) {
        console.error(error);
      } finally {
        setisLoading(false);
      }
    }
    fetchsubj();
  }, [isrefresh]);

  const handleRealtimeMessage = (message) => {
  

    // Find the index of the announcement in the state array
    const announcementIndex = announcements.findIndex(
      (announcement) => announcement.$id === message.payload.$id
    );

    //IF THIS CODE IS REUSABLE< CHANGE THE SENDTYPE TO A REAL ROLE GETTER
    // Check if the message is a 'create' event
    if (
      message.changetype === "update" &&
      (message.payload.sendType === `employees` ||
        message.payload.sendType === "all") &&
      announcementIndex !== -1 && // Check if announcementIndex is valid
      message.payload.post === announcements[announcementIndex]?.post
    ) {
      // If the announcement is found, update its data
      setAnnouncements((prevAnnouncements) => {
        const updatedAnnouncements = [...prevAnnouncements];
        updatedAnnouncements[announcementIndex] = message.payload;
        return sortAnnouncements(updatedAnnouncements);
      });
    } else if (
      (message.changetype === "delete" ||
        (message.changetype === "update" &&
          message.payload.post !==
            (announcements[announcementIndex]?.post || undefined))) &&
      announcementIndex !== -1
    ) {
      // Remove the announcement with the same $id from the state
      setAnnouncements((prevAnnouncements) =>
        sortAnnouncements(
          prevAnnouncements.filter(
            (announcement) => announcement.$id !== message.payload.$id
          )
        )
      );
    } else if (
      message.changetype === "create" ||
      (message.changetype === "update" &&
        (message.payload.sendType === `employees` ||
          message.payload.sendType === "all") &&
        message.payload.post !==
          (announcements[announcementIndex]?.post || undefined))
    ) {
      // Update the state to include the new announcements
      setAnnouncements((prevAnnouncements) =>
        sortAnnouncements([...prevAnnouncements, message.payload])
      );
    }
  };

  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_ANNOUNCEMENT,
    handleRealtimeMessage
  );
  return (
    <Card className="rounded-md shadow-md h-full">
      <CardHeader className="flex gap-3 justify-between">
        <div className="flex gap-2 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="lucide lucide-bell-ring"
          >
            <path d="M6 8a6 6 0 0 1 12 0c0 7 3 9 3 9H3s3-2 3-9" />
            <path d="M10.3 21a1.94 1.94 0 0 0 3.4 0" />
            <path d="M4 2C2.8 3.7 2 5.7 2 8" />
            <path d="M22 8c0-2.3-.8-4.3-2-6" />
          </svg>
          <p className="text-xl">Latest Announcements</p>

          <div className=" h-[40px]"></div>
        </div>
      </CardHeader>
      <Divider />
      <CardBody className="flex-1 p-4 gap-2  overflow-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-secondary-500 dark:[&::-webkit-scrollbar-track]:bg-slate-700  dark:[&::-webkit-scrollbar-thumb]:bg-slate-500 ">
        {!isLoading ? (
          <>
            {announcements && announcements.length > 0 ? (
              announcements.map((announcement, index) => (
                <LatestAnnouncementRender
                  select={selectannounce}
                  announcementdata={announcement}
                />
              ))
            ) : (
              <div className=" flex flex-col w-full h-full justify-center items-center">
                No Announcements yet...
              </div>
            )}
          </>
        ) : (
          <>
            <Announcementskeleton />
            <Announcementskeleton />
            <Announcementskeleton />
            <Announcementskeleton />
            <Announcementskeleton />
          </>
        )}

        {SelectedAnnouncement && (
          <Announcementdetails
            Announcement={SelectedAnnouncement}
            imageurl={Selectedimageurl}
            refreshparent={refresh}
          />
        )}
      </CardBody>
    </Card>
  );
}
