import { useAuth } from "../../../utils/AppwriteConfig/AuthContext";
import { Image, Skeleton, Spinner } from "@nextui-org/react";
import { storage } from "../../../utils/AppwriteConfig/Config";
import useRealtimeSubscription from "../../../utils/AppwriteConfig/RealtimeServices";
import { useEffect, useState } from "react";
import axios from "axios";
import AddAccountPicture from "../actions/AddProfilepicture";

export default function AccountHeader({ type }) {
  const { user, server } = useAuth();

  const [isLoading, setisLoading] = useState(false);
  const [MainError, setMainError] = useState();
  const [Errors, setErrors] = useState({});

  const [UserCred, setUserCred] = useState({});
  const [Cover, setCover] = useState(null);
  const [imageURL, setImageURL] = useState(null);

  useEffect(() => {
    async function GetUserCred() {
      try {
       
        setisLoading(true);
        const usercred = await axios.get(`${server}/v1/usercred`, {
          params: { accid: user.$id }
        });

        setUserCred(usercred.data);
        setCover(usercred.data.coverPicture);
      } catch (e) {
        setMainError(e.message);
      } finally {
        setisLoading(false);
      }
    }
    GetUserCred();
  }, [user]);
  let currentrole;

  if (
    user.labels.includes("Adminhead") ||
    user.labels.includes("Registrar") ||
    user.labels.includes("Scheduler")
  ) {
    currentrole = "employee";
  } else if (user.labels.includes("teacher")) {
    currentrole = "teacher";
  } else if (user.labels.includes("student")) {
    currentrole = "student";
  } else {
  }

  useEffect(() => {
    const loadImage = async () => {
      try {
        if (Cover) {
          // Load image from storage using data.fileid
          const result = await storage.getFilePreview(
            "66198605bb373176f557",
            Cover
          );
          console.log("rere");
          setImageURL(result);
        }
      } catch (error) {
        console.error("Error loading image:", error);
      }
    };

    loadImage();
  }, [Cover]);

  const handleRealtimeChanges = (changes) => {
    console.log("changes");
    if (changes.changetype === "update" && changes.payload.coverPicture) {
      console.log(changes.payload.coverPicture);
      setCover(changes.payload.coverPicture);
    } else if (
      changes.changetype === "update" &&
      !changes.payload.coverPicture
    ) {
      setCover();
      console.log("cover");
    }
  };

  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_USERCRED,
    handleRealtimeChanges
  );

  return (
    <div className="relative w-full max-h-48 flex rounded-t-xl  bg-no-repeat bg-cover bg-center">
      {isLoading ? (
        <Skeleton isLoaded={!isLoading} className=" w-full">
          <div className=" w-full h-48 bg-slate-200 rounded-xl flex justify-center items-center">
            <Spinner size="md" content="Loading..."></Spinner>
          </div>
        </Skeleton>
      ) : (
        <div className=" w-full h-48 bg-slate-200">
          <img
            className=" h-full w-full object-cover p-0 m-0"
            src={
              Cover
                ? imageURL
                : "https://preline.co/assets/svg/examples/abstract-bg-1.svg"
            }
          />

          {type === "edit" && (
            <button
              onClick={() => {
                document.querySelector("#coverpic").click();
              }}
              className="absolute z-10 top-0 left-0 w-full h-full bg-slate-200  flex justify-center items-center opacity-0 transition-opacity duration-300 hover:opacity-50"
            >
              {/* Content to be shown on hover */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="#72539b"
                class="bi bi-file-arrow-up"
                viewBox="0 0 16 16"
              >
                <path d="M8 11a.5.5 0 0 0 .5-.5V6.707l1.146 1.147a.5.5 0 0 0 .708-.708l-2-2a.5.5 0 0 0-.708 0l-2 2a.5.5 0 1 0 .708.708L7.5 6.707V10.5a.5.5 0 0 0 .5.5" />
                <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1" />
              </svg>
            </button>
          )}

          <div className="absolute z-10 top-0 end-0 p-4">
            <div className="py-2 capitalize px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800">
              {currentrole}
            </div>
          </div>
        </div>
      )}

      <AddAccountPicture />
    </div>
  );
}
