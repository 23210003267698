import { useState } from "react";
import EditUser from "../Actions/EditUser";
import Documentrender from "./renders/Documentrender";
import { useParams } from "react-router-dom";
import Enrollments from "./renders/Enrollments";

export default function UserDetails() {
  const { users } = useParams();
  const [isEdit, setisEdit] = useState(false);
  const [SelectedUserRole, setSelectedUserRole] = useState("");
  return (
    <div className="  w-full h-full">
      <div className="flex flex-col space-y-4">
        <EditUser
          setEditState={setisEdit}
          setCurrentRole={setSelectedUserRole}
        />
        {!isEdit &&
          (users === "students" ||
            (users === "accounts" && SelectedUserRole === "student")) && (
            <Enrollments />
          )}
        {!isEdit && <Documentrender />}
      </div>
    </div>
  );
}
