import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Tabs,
  Tab,
  Card,
  CardBody,
  CardHeader,
  Spinner,
  Image,
  Switch,
  cn
} from "@nextui-org/react";
import Edit from "../../../../Components/modals/Edit";
import {
  SubjectCard,
  SubjectCardSkeleton
} from "../../../../Components/modals/Subjectcard";
import AddSubject from "../../CoursePage/actions/AddSubject";
import { useAuth } from "../../../../utils/AppwriteConfig/AuthContext";
import axios from "axios";
import Sections from "../../../../Components/sections";
import { Sectioncard } from "../../../../Components/Cards/CardRender";
import AddSection from "../../CoursePage/actions/AddSection";
import SubjectDetails from "../../CoursePage/components/modals/SectionDetails";
import SectionDetails from "../../CoursePage/components/modals/SectionDetails";
import Editannouncement from "../Actions/EditAnnouncement";
import Delete from "../../../../Components/modals/DeleteConfirm";
import DeleteAnnouncement from "../../../../Components/modals/deleteannouncement";

export default function Announcementdetails({
  Announcement,
  refreshparent,
  imageurl
}) {
  const { server, user } = useAuth();
  const { isOpen, onOpen, onClose, onOpenChange } = useDisclosure();

  const [Edit, setEdit] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [image, setImage] = useState();

  const refresh = () => {
    setEdit(!Edit);
    onClose();
    refreshparent();
  };

  const closeModal = () => {
    setEdit(false);
    setImage();
    setisLoading(false);
    onClose();
  };

  return (
    <>
      <button
        id="Announcementdetails"
        className="bg-[#FFDE59] hidden opacity-100 rounded-lg p-3 py-2 text-black hover:bg-[#fcd84b] text-sm"
        onClick={onOpen}
      >
        View
      </button>
      <Modal
        backdrop="opaque"
        isOpen={isOpen}
        // scrollBehavior="inside"
        size="5xl"
        className=" font-poppins max-h-[600px] "
        onOpenChange={onOpenChange}
        onClose={closeModal}
      >
        <ModalContent className=" font-poppins ">
          {(onClose) => (
            <>
              <ModalHeader
                className={`${
                  Edit ? " justify-between" : ""
                } flex gap-4 items-center justify-center  text-secondary-600 text-2xl space-x-1 pb-0 `}
              >
                {Announcement?.title}
                {user.labels.includes("Adminhead") && (
                  <Button
                    isIconOnly
                    className={`${Edit ? "hidden" : ""}`}
                    onPress={() => {
                      setEdit(true);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-pencil-square"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path
                        fill-rule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                      />
                    </svg>
                  </Button>
                )}
              </ModalHeader>
              <ModalBody className="min-h-[100px] p-2 flex justify-center sm:flex-row flex-col ">
                {isLoading ? (
                  <div className=" flex w-full h-[200px]">
                    <div className="flex flex-col w-full h-full justify-center items-center ">
                      <Spinner
                        size="lg"
                        color="secondary"
                        label={`Saving Announcement...`}
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    {Edit ? (
                      <Editannouncement
                        Loadingstate={setisLoading}
                        currentdata={Announcement}
                        rerenderParent={refresh}
                        currentimage={imageurl}
                      />
                    ) : (
                      <>
                        {/* <div className=" w-full hidden items-center mb-2 sm:min-w-[200px] justify-center sm:flex sm:max-w-[300px] flex-initial">
                          {imageurl ? (
                            <Image
                              isZoomed
                              alt="NextUI hero Image"
                              src={imageurl}
                              fallbackSrc={require("../../../../Img/BSA.jpg")}
                            />
                          ) : (
                            <Image
                              alt="NextUI hero Image"
                              src={require("../../../../Img/BSA.jpg")}
                              fallbackSrc={require("../../../../Img/BSA.jpg")}
                            />
                          )}
                        </div> */}
                        <div className=" p-2 gap-2  mb-2 overflow-auto w-full [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-secondary-500 dark:[&::-webkit-scrollbar-track]:bg-slate-700  dark:[&::-webkit-scrollbar-thumb]:bg-slate-500 ">
                          <div className=" w-full mb-2 items-center   justify-center flex  flex-initial">
                            {imageurl ? (
                              <div className=" aspect-[16/9]  w-full">
                                <img
                                  className=" w-full h-full bg-cover object-cover "
                                  alt="NextUI hero Image"
                                  src={imageurl}
                                />
                              </div>
                            ) : (
                              <img
                                alt="NextUI hero Image"
                                src={require("../../../../Img/BSA.jpg")}
                              />
                            )}
                          </div>
                          <div className="  flex flex-col space-y-2">
                            <div className=" flex flex-col">
                              {/* <span className="text-md flex-initial font-medium">
                                Title
                              </span> */}
                            </div>
                            {/*  */}
                            {user.labels.includes("Adminhead") && (
                              <div className=" flex flex-col">
                                <span className="text-md flex-initial font-medium">
                                  Announce to
                                </span>
                                <span className="flex-1 capitalize bg-slate-300 p-2 rounded-lg flex justify-start items-center">
                                  {Announcement.sendType}
                                </span>
                              </div>
                            )}
                            {/* CONTENTE of THE ANNOUNCEMENT */}
                            <div className=" flex flex-1  flex-col">
                              {/* TITLE */}
                              {/* <span className="text-md flex-initial font-medium">
                                Content
                              </span> */}
                              {/* content*/}
                              <span className="text-xl p-2 break-words bg-secondary-100 rounded-lg flex justify-start">
                                {Announcement.content}
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </ModalBody>
              <ModalFooter className="pt-0">
                <div
                  className={`${
                    Edit ? "flex" : "hidden"
                  } justify-between space-x-2 w-full`}
                >
                  {isLoading ? (
                    <Button
                      color="danger"
                      isDisabled
                      variant="flat"
                      onPress={onOpen}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-trash"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                      </svg>
                      <span>Delete</span>
                    </Button>
                  ) : (
                    <DeleteAnnouncement
                      title={"announcement"}
                      subject={Announcement}
                      refresher={refresh}
                      imgurl={imageurl}
                    />
                  )}

                  <div className="flex space-x-2">
                    <Button
                      isDisabled={isLoading}
                      onPress={() => {
                        setEdit(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      isDisabled={isLoading}
                      className="bg-secondary text-white"
                      onClick={() => {
                        document.querySelector("#savechangesbtn").click();
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
