import React, { useState } from "react";
import { usePagination, PaginationItemType, cn } from "@nextui-org/react";

export default function TecaherSuitabilityTooltip({
  suitability,
  currentFactors,
  isWFull = false
}) {
  const { activePage, range, setPage, onNext, onPrevious } = usePagination({
    total: currentFactors?.includes("preferences")
      ? currentFactors.length - 1
      : currentFactors.length,
    showControls: true,
    loop: true
  });
  console.log("ggss", suitability);

  return (
    <div
      className={`${
        isWFull ? "w-full" : "max-w-[450px]"
      }  max-h-[250px] space-y-2 py-2 rounded-lg `}
    >
      <div className="w-full">
        {suitability.scores &&
          currentFactors
            .filter((factor) => factor !== "preferences")
            .map((factor, index) => {
              return (
                <>
                  {factor === "subjectTaught" && activePage === index + 1 && (
                    <SuitableFormat
                      header={`Taught subjects Relevance score is ${
                        suitability.scores.subjectTaught !== null &&
                        suitability.scores.subjectTaught !== undefined
                          ? parseFloat(
                              suitability.scores.subjectTaught.toFixed(2)
                            )
                          : "N/A"
                      }%`}
                      reason={suitability.tuaghtSubjectRelevanceReason}
                    />
                  )}

                  {factor === "degree" && activePage === index + 1 && (
                    <SuitableFormat
                      header={`Degree Relevance score is ${
                        suitability.degreeRelevanceScore !== null &&
                        suitability.degreeRelevanceScore !== undefined
                          ? parseFloat(suitability.scores.degree.toFixed(2))
                          : "N/A"
                      }%`}
                      reason={suitability.degreeRelevanceReason}
                    />
                  )}

                  {(factor === "experience" || factor === "preferences") &&
                    activePage === index + 1 && (
                      <SuitableFormat
                        header={`Teaching Experience is ${
                          suitability.scores.experience !== null &&
                          suitability.scores.experience !== undefined
                            ? parseFloat(
                                suitability.scores.experience.toFixed(2)
                              )
                            : "N/A"
                        }% ${
                          suitability.scores.preferences > 0 &&
                          suitability.scores.preferences !== null &&
                          suitability.scores.preferences !== undefined
                            ? `and preferences is ${parseFloat(
                                suitability.scores.preferences.toFixed(2)
                              )}%`
                            : ""
                        }`}
                        reason={
                          suitability.prefAndExpReason ||
                          suitability.prefAndExpScore
                        }
                      />
                    )}
                </>
              );
            })}
      </div>

      {/* Pagination */}
      <div className="flex justify-center items-center mx-4  gap-2">
        {/* <p>Active page: {activePage}</p> */}
        <div className="flex-1">
          <span className=" font-medium">Suitability Score:</span>{" "}
          {parseFloat(suitability.totalSuitability?.toFixed(2))}%
        </div>
        <ul className="flex gap-2 flex-initial items-center justify-center">
          {range.map((page) => {
            if (page === PaginationItemType.NEXT) {
              return (
                <li key={page} aria-label="next page" className="w-4 h-4">
                  <button
                    className="w-full h-full bg-default-200 rounded-full"
                    onClick={onNext}
                  >
                    <svg
                      aria-hidden="true"
                      fill="none"
                      focusable="false"
                      height="16"
                      role="presentation"
                      viewBox="0 0 24 24"
                      width="16"
                      className=" rotate-180"
                    >
                      <path
                        d="M15.5 19l-7-7 7-7"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                      />
                    </svg>
                  </button>
                </li>
              );
            }

            if (page === PaginationItemType.PREV) {
              return (
                <li key={page} aria-label="previous page" className="w-4 h-4">
                  <button
                    className="w-full h-full bg-default-200 rounded-full"
                    onClick={onPrevious}
                  >
                    <svg
                      aria-hidden="true"
                      fill="none"
                      focusable="false"
                      height="16"
                      role="presentation"
                      viewBox="0 0 24 24"
                      width="16"
                    >
                      <path
                        d="M15.5 19l-7-7 7-7"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                      />
                    </svg>
                  </button>
                </li>
              );
            }

            if (page === PaginationItemType.DOTS) {
              return (
                <li key={page} className="w-4 flex h-4">
                  ...
                </li>
              );
            }

            return (
              <li key={page} aria-label={`page ${page}`} className="w-3 h-3">
                <button
                  className={cn(
                    "w-full h-full flex items-center bg-default-300 rounded-full",
                    activePage === page && "bg-secondary"
                  )}
                  onClick={() => setPage(page)}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

function SuitableFormat({ header, reason }) {
  return (
    <div class=" py-1 px-2  text-sm font-medium text-black rounded  dark:bg-neutral-700">
      <div className="px-2 py-2 bg-secondary-400 text-white rounded-lg flex flex-col space-y-2 text-meduim font-poppins">
        {header}
      </div>
      <div className=" flex flex-col font-normal px-4 pt-1">
        {/* <span>Reason:</span> */}
        <span>{reason}</span>
      </div>
    </div>
  );
}
