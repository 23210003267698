import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { calculateAge } from '../../../../utils/helpers/stringformat';
import IDrender from '../components/renders/StudentIDRender';
import axios from 'axios';
import { useAuth } from '../../../../utils/AppwriteConfig/AuthContext';
import { Spinner } from '@nextui-org/react';
import Error from '../../../../Components/ErrorIndicator';
import { functions } from '../../../../utils/AppwriteConfig/Config';

export default function CreateUser({ setUser }) {
  //get DataServer address
  const { DataServer } = useAuth();

  //Get User role in URL param
  const { users } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [MainError, setMainError] = useState();

  const [EmailLoading, setEmailLoading] = useState(false);
  const [LrnLoading, setLrnLoading] = useState(false);

  const [emailduplicate, setemailduplicate] = useState(0);
  const [lrnduplicate, setlrnduplicate] = useState(0);
  const [Userinfo, SetUserInfo] = useState({
    Username: '',
    Firstname: '',
    Middlename: '',
    Lastname: '',
    ContactNumber: '',
    Nationality: '',
    Birthdate: '',
    Age: 'N/A',
    Gender: '',
    Address: {
      Block: '',
      Lot: '',
      Street: '',
      Subd: '',
      Barangay: '',
      City: '',
      Province: '',
      Country: 'Philippines',
    },
    Role: '',
    ID: '',
    LRNID: '',
    Email: '',
    Password: '',
  });
  const [Errors, SetErrors] = useState({
    Username: null,
    Firstname: null,
    Middlename: null,
    Lastname: null,
    ContactNumber: null,
    Nationality: null,
    Birthdate: null,
    Age: null,
    Gender: null,
    Address: {
      Block: null,
      Lot: null,
      Street: null,
      Subd: null,
      Barangay: null,
      City: null,
      Province: null,
      Country: null,
    },
    Role: null,
    ID: null,
    LRNID: null,
    Email: null,
    Password: null,
  });

  useEffect(() => {
    setUser(Userinfo, Errors, EmailLoading, LrnLoading);
  }, [Errors, Userinfo, EmailLoading, LrnLoading]);

  //Get Latestid data
  const HandledataID = (data) => {
    SetUserInfo({ ...Userinfo, ID: data, Role: users.slice(0, -1) });
  };
  let errors = {};

  //Used to get the role in the url params and set the role in first render
  useEffect(() => {
    console.log('role');
    SetUserInfo({ ...Userinfo, Role: users.slice(0, -1) });
  }, []);

  //Checker of userinfo data every changes
  useEffect(() => {
    console.log('data:', Userinfo);
  }, [Userinfo]);

  //Set the password
  useEffect(() => {
    const generatedPassword = Userinfo.Lastname + Userinfo.Birthdate;
    const generatedUsername = Userinfo.Firstname + ' ' + Userinfo.Lastname;
    SetUserInfo((prevData) => ({
      ...prevData,
      Password: generatedPassword,
      Username: generatedUsername,
    }));
  }, [Userinfo.Lastname, Userinfo.Birthdate, Userinfo.Firstname]);

  //Get Age base on the Birthdate
  const handleInputChange = (e) => {
    const birthdate = e.target.value;
    const age = calculateAge(birthdate);
    console.log(age);
    SetUserInfo({ ...Userinfo, Birthdate: birthdate, Age: age });
  };

  const validate = async () => {
    // Validate Firstname
    if (!Userinfo.Firstname.trim()) {
      errors.Firstname = 'First name is required';
    } else if (Userinfo.Firstname.length > 50) {
      errors.Firstname = 'First name is too long...';
    }

    // Validate Lastname
    if (!Userinfo.Lastname.trim()) {
      errors.Lastname = 'Last name is required';
    } else if (Userinfo.Lastname.length > 50) {
      errors.Lastname = 'Last name is too long...';
    }

    // Validate Contact number
    if (!Userinfo.ContactNumber.trim()) {
      errors.ContactNumber = 'Contact Number is required';
    } else if (!/^[1-9][0-9]{9}$/.test(Userinfo.ContactNumber.trim())) {
      errors.ContactNumber =
        'Contact Number must have 10 digits and cannot start with 0';
    } else if (!Userinfo.ContactNumber.startsWith('9')) {
      errors.ContactNumber = 'Contact Number must be a valid number';
    }

    // Validate Nationality
    if (!Userinfo.Nationality.trim()) {
      errors.Nationality = 'Nationality is required';
    } else if (Userinfo.Nationality.length > 15) {
      errors.Nationality = 'Nationality is too long...';
    }

    // Validate Birthdate
    const currentYear = new Date().getFullYear();
    const NewcurrentYear = currentYear - 4;
    const isValidFormat = /^\d{4}-\d{2}-\d{2}$/.test(Userinfo.Birthdate);
    const year = parseInt(Userinfo.Birthdate.split('-')[0], 10);
    if (!Userinfo.Birthdate.trim()) {
      errors.Birthdate = 'Birthdate is required.';
    } else if (!isValidFormat) {
      errors.Birthdate = 'Please enter a valid date format (DD-MM-YYYY).';
    } else if (year < 1950 || year > NewcurrentYear) {
      errors.Birthdate = `Please enter a valid birth year between 1950 and ${NewcurrentYear}.`;
    }

    //Validate Age
    console.log('Age: ', users !== 'students');
    if (users !== 'students' && Userinfo.Age < 18) {
      errors.Birthdate = `Valid age must be 18 and above for a ${users.slice(
        0,
        -1
      )}`;
    }

    //validate teacher hour
    if (
      (isNaN(Userinfo.HoursPerWeek) ||
        Userinfo.HoursPerWeek === '' ||
        Userinfo.HoursPerWeek <= 20) &&
      users === 'teachers'
    ) {
      errors.HoursPerWeek = `Min is 20 up to 25 hours`;
    }
    //validate teacher experience
    if (
      (isNaN(Userinfo.teachingExperience) ||
        Userinfo.teachingExperience === '') &&
      users === 'teachers'
    ) {
      errors.teachingExperience = `Min is 20 up to 25 hours`;
    }

    // Validate Gender
    if (!Userinfo.Gender) {
      errors.Gender = 'Gender is required';
    }
    // Initialize Address object in errors
    errors.Address = {};
    // Validate Address
    if (!Userinfo.Address.Block.trim()) {
      errors.Address = { ...errors.Address, Block: 'Block is required' };
    } else if (!/^\d+$/.test(Userinfo.Address.Block.trim())) {
      errors.Address = { ...errors.Address, Block: 'Numbers only' };
    } else if (parseInt(Userinfo.Address.Block.trim()) > 100) {
      errors.Address = {
        ...errors.Address,
        Block: `Block value cannot exceed 1000`,
      };
    }

    if (!Userinfo.Address.Lot.trim()) {
      errors.Address = { ...errors.Address, Lot: 'Lot is required' };
    } else if (!/^\d+$/.test(Userinfo.Address.Lot.trim())) {
      errors.Address = { ...errors.Address, Lot: 'Numbers only' };
    } else if (parseInt(Userinfo.Address.Lot.trim()) > 500) {
      errors.Address = {
        ...errors.Address,
        Lot: `Lot value cannot exceed 1000`,
      };
    }

    if (!Userinfo.Address.Subd.trim()) {
      errors.Address = { ...errors.Address, Subd: 'Subdivision is required' };
    }

    if (!Userinfo.Address.Barangay.trim()) {
      errors.Address = { ...errors.Address, Barangay: 'Barangay is required' };
    }
    if (!Userinfo.Address.City.trim()) {
      errors.Address = { ...errors.Address, City: 'City is required' };
    }

    if (!Userinfo.Address.Province.trim()) {
      errors.Address = { ...errors.Address, Province: 'Province is required' };
    }

    if (Userinfo.Role == 'employee') {
      errors.Role = 'Role is required';
    }
    if (!Userinfo.Department && users === 'teachers') {
      errors.Department = `Please select a Department`;
    }

    //Validate ID
    if (!Userinfo.ID.trim()) {
      errors.ID = 'ID number has not been rendered';
    }
    //Validate LRNID
    if (users.slice(0, -1) === 'student') {
      const regex = /^\d{12}$/;
      if (!Userinfo.LRNID.trim()) {
        errors.LRNID = 'LRNID is required';
      } else if (lrnduplicate > 0) {
        errors.LRNID = 'LRNID is Already used';
      } else if (!regex.test(Userinfo.LRNID)) {
        errors.LRNID = 'LRNID must be a 12 number input';
      }
    }
    // Validate Email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!Userinfo.Email.trim()) {
      errors.Email = 'Email is required';
    } else if (!emailRegex.test(Userinfo.Email)) {
      errors.Email = 'Invalid email format';
    } else if (emailduplicate > 0) {
      errors.Email = 'Email is Already used';
    }

    console.log('test',errors);

    SetErrors(errors);
  };

  useEffect(() => {
    setEmailLoading(true);
    const timerId = setTimeout(() => {
      const fetchData = async () => {
        try {
          setEmailLoading(true);
          const response = await axios.get(`${DataServer}/user/verify/email`, {
            params: {
              email: Userinfo.Email,
            },
          });
          console.log('duplicates: ', response.data.total);
          setemailduplicate(response.data.total);
        } catch (error) {
          setMainError(error.message);
        } finally {
          setEmailLoading(false);
        }
      };

      fetchData();
    }, 500);
    setEmailLoading(false);
    return () => clearTimeout(timerId); // Clear the timer when the component unmounts or when the effect is re-run
  }, [Userinfo.Email]);

  useEffect(() => {
    setLrnLoading(true);
    const timerId = setTimeout(() => {
      const fetchData = async () => {
        try {
          setLrnLoading(true);
          const response = await axios.get(
            `${DataServer}/user/accounts/lrnverify`,
            {
              params: {
                lrn: Userinfo.LRNID,
              },
            }
          );
          console.log('duplicates: ', response.data.total);
          setlrnduplicate(response.data.total);
        } catch (error) {
          setMainError(error.message);
        } finally {
          setLrnLoading(false);
        }
      };

      fetchData();
    }, 500);
    setLrnLoading(false);
    return () => clearTimeout(timerId); // Clear the timer when the component unmounts or when the effect is re-run
  }, [Userinfo.LRNID]);

  //Handles err message of duplicates
  useEffect(() => {
    if (emailduplicate > 0) {
      SetErrors((prevErrors) => ({
        ...prevErrors,
        Email: 'Email is already in use',
      }));
    } else {
      SetErrors((prevErrors) => ({
        ...prevErrors,
        Email: null,
      }));
    }
  }, [emailduplicate]);

  useEffect(() => {
    if (lrnduplicate > 0) {
      SetErrors((prevErrors) => ({
        ...prevErrors,
        LRNID: 'LRN is already in use',
      }));
    } else {
      SetErrors((prevErrors) => ({
        ...prevErrors,
        LRNID: null,
      }));
    }
  }, [lrnduplicate]);

  useEffect(() => {
    console.log(Userinfo.Email);
  }, [Userinfo.Email]);

  //validate onthe spot
  useEffect(() => {
    validate();
  }, [Userinfo, emailduplicate, lrnduplicate]);

  //submit
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(errors);
    validate();
    // Update the Errors state with the validation results

    // If there are no errors, proceed with form submission
    if (Object.keys(errors).length === 1) {
      try {
        const user = users.slice(0, -1).toLocaleLowerCase();
        const response = await axios.post(
          `${DataServer}/user/${user}/create`,
          Userinfo
        );
        console.log(response);
        // const body = JSON.stringify(Userinfo);

        // const response = await functions.createExecution(
        //   "667bc0d000106435b059",
        //   body,
        //   false,
        //   `/User/v1/create/${user}`,
        //   "POST"
        // );
        // Redirect or handle success as needed
      } catch (error) {
        setMainError(error.message);
      } finally {
        navigate(`/user/employee/manage/${users}`);
      }
    } else {
      console.log('Validation errors:', errors);
      // Optionally, you can display validation errors to the user
    }
  };

  return (
    <div className=" w-full h-full p-4 overflow-auto ">
      {MainError && <Error errorMessage={MainError} reset={setMainError} />}
      <form className="flex flex-col" onSubmit={handleSubmit} noValidate>
        {/* Forms */}
        <div className="">
          <div className=" ">
            <div className="text-center">
              <h1 className="text-3xl font-bold text-gray-800 sm:text-4xl dark:text-white">
                Create new {users.slice(0, -1)}
              </h1>
              <p className="mt-1 text-gray-600 dark:text-gray-400">
                Complete the following inputs.
              </p>
            </div>
          </div>

          {/* User Basic INformation */}
          <span className="text-md font-medium">User information</span>
          {/* Name */}
          <div className="flex flex-1 flex-col sm:flex-row p-2">
            <div className="flex-1  m-1">
              {/* Firstname */}
              <div>
                <label
                  htmlFor="Firstname"
                  className="block mb-2 text-md text-gray-700  dark:text-white"
                >
                  First name
                  {Errors.Firstname && (
                    <span className="text-red-500 text-sm mt-1 ml-1">*</span>
                  )}
                </label>
                <input
                  type="text"
                  name="Firstname"
                  id="Firstname"
                  className={`py-2 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
                    Errors.Firstname == null
                      ? 'border-gray-200'
                      : 'border-red-500'
                  }`}
                  onKeyPress={(event) => {
                    if (
                      /[0-9]/.test(event.key) ||
                      Userinfo.Firstname.length >= 50
                    ) {
                      event.preventDefault();
                    }
                  }}
                  required
                  onChange={(e) =>
                    SetUserInfo({ ...Userinfo, Firstname: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="  m-1">
              {/* Middlename */}
              <div>
                <label
                  htmlFor="Middlename"
                  className="block mb-2 text-md text-gray-700  dark:text-white"
                >
                  Middle name
                </label>
                <input
                  type="text"
                  name="Middlename"
                  id="Middlename"
                  className={`py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600
              `}
                  onKeyPress={(event) => {
                    if (
                      /[0-9]/.test(event.key) ||
                      Userinfo.Middlename.length >= 50
                    ) {
                      event.preventDefault();
                    }
                  }}
                  required
                  onChange={(e) =>
                    SetUserInfo({ ...Userinfo, Middlename: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="flex-1 m-1">
              {/* Lastname */}
              <div>
                <label
                  htmlFor="Lastname"
                  className="block mb-2 text-md text-gray-700  dark:text-white"
                >
                  Last name
                  {Errors.Lastname && (
                    <span className="text-red-500 text-sm mt-1 ml-1">*</span>
                  )}
                </label>
                <input
                  type="text"
                  name="Lastname"
                  id="Lastname"
                  className={`py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600
                  ${
                    Errors.Lastname == null
                      ? 'border-gray-200'
                      : 'border-red-500'
                  }
              `}
                  onKeyPress={(event) => {
                    if (
                      /[0-9]/.test(event.key) ||
                      Userinfo.Lastname.length >= 50
                    ) {
                      event.preventDefault();
                    }
                  }}
                  required
                  onChange={(e) =>
                    SetUserInfo({ ...Userinfo, Lastname: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
          {/* Number n natiionality */}
          <div className="flex flex-1 flex-col sm:flex-row p-2">
            <div className="flex-1  m-1">
              <div>
                <label
                  htmlFor="ContactNumber"
                  className="block mb-2 text-md text-gray-700  dark:text-white"
                >
                  Contact Number
                  {Errors.ContactNumber && (
                    <span className="text-red-500 text-sm mt-1 ml-1">*</span>
                  )}
                </label>

                <div className="flex rounded-lg shadow-sm">
                  <span
                    className={`px-4 inline-flex items-center min-w-fit rounded-s-md border bg-slate-300 border-e-0 border-gray-200 text-sm dark:text-gray-400 ${
                      Errors.ContactNumber
                        ? 'border-red-500'
                        : 'border-gray-200'
                    }`}
                  >
                    +63
                  </span>
                  <input
                    type="text"
                    name="ContactNumber"
                    id="ContactNumber"
                    className={`py-2 px-3 pe-11 block w-full border shadow-sm rounded-e-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
                      Errors.ContactNumber
                        ? 'border-red-500'
                        : 'border-gray-200'
                    }`}
                    value={Userinfo.ContactNumber}
                    onKeyPress={(event) => {
                      if (
                        event.key === '0' &&
                        Userinfo.ContactNumber.length === 0
                      ) {
                        event.preventDefault(); // Prevents entering '0' as the first digit
                      } else if (!/^\d$/.test(event.key)) {
                        event.preventDefault(); // Prevents entering non-digit characters
                      } else if (Userinfo.ContactNumber.length >= 10) {
                        event.preventDefault(); // Prevents entering more than 10 digits
                      }
                    }}
                    required
                    onChange={(e) => {
                      const userInput = e.target.value;
                      if (userInput.length === 1 && userInput[0] === '0') {
                        SetUserInfo({
                          ...Userinfo,
                          ContactNumber: '',
                        });
                      } else {
                        SetUserInfo({
                          ...Userinfo,
                          ContactNumber: userInput,
                        });
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex-1 m-1">
              {/* Nationality */}
              <div>
                <label
                  htmlFor="Nationality"
                  className="block mb-2 text-md text-gray-700  dark:text-white"
                >
                  Nationality
                  {Errors.Nationality && (
                    <span className="text-red-500 text-sm mt-1 ml-1">*</span>
                  )}
                </label>
                <input
                  type="text"
                  name="Nationality"
                  id="Nationality"
                  className={`py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600
                  ${
                    Errors.Nationality == null
                      ? 'border-gray-200'
                      : 'border-red-500'
                  }
              `}
                  onKeyPress={(event) => {
                    if (
                      /[0-9]/.test(event.key) ||
                      Userinfo.Nationality.length >= 50
                    ) {
                      event.preventDefault();
                    }
                  }}
                  required
                  onChange={(e) =>
                    SetUserInfo({ ...Userinfo, Nationality: e.target.value })
                  }
                />
              </div>
            </div>
          </div>

          {/* Additional info */}
          <div className="flex flex-1 flex-col sm:flex-row p-2 mb-3">
            {/* Birthdate */}
            <div className=" flex-grow-[1] m-1">
              <label
                htmlFor="birthdate"
                className="block mb-2 text-sm text-gray-700  dark:text-white"
              >
                Birthdate (DD/MM/YY)
                {Errors.Birthdate && (
                  <span className="text-red-500 text-xs mt-1 ml-1">
                    {Errors.Birthdate.includes('Valid age') && Errors.Birthdate}
                    *
                  </span>
                )}
              </label>
              <input
                type="date"
                name="birthdate"
                id="birthdate"
                className={`py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 
                ${
                  Errors.Birthdate == null
                    ? 'border-gray-200'
                    : 'border-red-500'
                }
                `}
                required
                onChange={handleInputChange}
                onBlur={(e) =>
                  SetUserInfo({
                    ...Userinfo,
                    Birthdate: e.target.value,
                    Age: calculateAge(e.target.value),
                  })
                }
              />
            </div>

            {/* Age */}
            <div className="flex-initial m-1">
              <label
                htmlFor="Lastname"
                className="block mb-2 text-sm text-gray-700  dark:text-white"
              >
                Age
              </label>
              <span className="py-3 px-4 block bg-slate-100 w-full h-[45px] border-gray-200 rounded-lg">
                {Userinfo.Age}
              </span>
            </div>

            {/* Gender */}
            <div className="flex-1 m-1">
              <label
                htmlFor="Gender"
                className="block mb-2 text-sm text-gray-700  dark:text-white"
              >
                Gender
                {Errors.Gender && (
                  <span className="text-red-500 text-sm mt-1 ml-1">*</span>
                )}
              </label>
              <div className="relative">
                <select
                  className={`py-3 px-4 pe-9 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 
                  ${
                    Errors.Gender == null ? 'border-gray-200' : 'border-red-500'
                  }
                  `}
                  onChange={(e) =>
                    SetUserInfo({ ...Userinfo, Gender: e.target.value })
                  }
                >
                  <option value="" disabled selected hidden>
                    Select Gender
                  </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>

                <div className="absolute top-1/2 end-3 -translate-y-1/2">
                  <svg
                    className="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="m7 15 5 5 5-5" />
                    <path d="m7 9 5-5 5 5" />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          {/* Address Info */}
          <span className="text-md font-medium">Address</span>
          <div className="flex flex-1 flex-col p-2">
            <div className="flex-1 m-1">
              <div className="flex flex-col sm:flex-row">
                <div className="flex-1 m-1">
                  <label
                    htmlFor="Block"
                    className="block mb-2 text-md text-gray-700  dark:text-white"
                  >
                    Block
                    {Errors &&
                      Errors &&
                      Errors.Address &&
                      Errors.Address.Block && (
                        <span className="text-red-500 text-sm mt-1 ml-1">
                          *
                        </span>
                      )}
                  </label>
                  <input
                    type="text"
                    name="Block"
                    id="Block"
                    className={`py-2 px-3 block w-full  rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600
                    ${
                      Errors &&
                      Errors.Address &&
                      Errors.Address.Block == undefined
                        ? 'border-gray-200'
                        : 'border-red-500'
                    }
                    `}
                    onKeyPress={(event) => {
                      if (
                        !/[0-9]/.test(event.key) ||
                        Userinfo.Address.Block.length >= 3
                      ) {
                        console.log('Preventing default action...');
                        event.preventDefault();
                      }
                    }}
                    required
                    onChange={(e) =>
                      SetUserInfo({
                        ...Userinfo,
                        Address: { ...Userinfo.Address, Block: e.target.value },
                      })
                    }
                  />
                </div>
                <div className="flex-1 m-1">
                  <label
                    htmlFor="Lot"
                    className="block mb-2 text-md text-gray-700  dark:text-white"
                  >
                    Lot
                    {Errors && Errors.Address && Errors.Address.Lot && (
                      <span className="text-red-500 text-sm mt-1 ml-1">*</span>
                    )}
                  </label>
                  <input
                    type="text"
                    name="Lot"
                    id="Lot"
                    className={`py-2 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600
                    ${
                      Errors && Errors.Address && Errors.Address.Lot == null
                        ? 'border-gray-200'
                        : 'border-red-500'
                    }
                    `}
                    onKeyPress={(event) => {
                      const input = event.key;
                      const lot = Userinfo.Address.Lot;

                      // If the input is not a digit or Backspace, or if the length is already 2
                      if (
                        (!/^\d$/.test(input) && input !== 'Backspace') ||
                        lot.length >= 3
                      ) {
                        event.preventDefault();
                        return;
                      }

                      // If the input is "0" and the length is 0, prevent input
                      if (input === '0' && lot.length === 0) {
                        event.preventDefault();
                        return;
                      }

                      // If the input is Backspace or the length is 0 or 1, allow input
                      if (input === 'Backspace' || lot.length <= 1) {
                        return;
                      }

                      // If the length is 1 and the current input makes it greater than 9, prevent input
                      if (lot.length === 1 && parseInt(lot + input) > 9) {
                        event.preventDefault();
                      }
                    }}
                    required
                    onChange={(e) =>
                      SetUserInfo({
                        ...Userinfo,
                        Address: { ...Userinfo.Address, Lot: e.target.value },
                      })
                    }
                  />
                </div>
                <div className="flex-1 m-1">
                  <label
                    htmlFor="Street"
                    className="block mb-2 text-md text-gray-700  dark:text-white"
                  >
                    Street
                  </label>
                  <input
                    type="text"
                    name="Street"
                    id="Street"
                    className={`py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600
                    
                    `}
                    onKeyPress={(event) => {
                      if (Userinfo.Address.Street.length >= 50) {
                        event.preventDefault();
                      }
                    }}
                    required
                    onChange={(e) =>
                      SetUserInfo({
                        ...Userinfo,
                        Address: {
                          ...Userinfo.Address,
                          Street: e.target.value,
                        },
                      })
                    }
                  />
                </div>

                <div className="flex-1 m-1">
                  <label
                    htmlFor="Subd"
                    className="block mb-2 text-md text-gray-700  dark:text-white"
                  >
                    Subdivision
                    {Errors && Errors.Address && Errors.Address.Subd && (
                      <span className="text-red-500 text-sm mt-1 ml-1">*</span>
                    )}
                  </label>
                  <input
                    type="text"
                    name="Subd"
                    id="Subd"
                    className={`py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600
                    ${
                      Errors && Errors.Address && Errors.Address.Subd == null
                        ? 'border-gray-200'
                        : 'border-red-500'
                    }
                    `}
                    onKeyPress={(event) => {
                      if (Userinfo.Address.Subd.length >= 50) {
                        event.preventDefault();
                      }
                    }}
                    required
                    onChange={(e) =>
                      SetUserInfo({
                        ...Userinfo,
                        Address: { ...Userinfo.Address, Subd: e.target.value },
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="flex-1 m-1">
              <div className="flex flex-col sm:flex-row">
                <div className="flex-1 m-1">
                  <label
                    htmlFor="Barangay"
                    className="block mb-2 text-md text-gray-700  dark:text-white"
                  >
                    Barangay
                    {Errors && Errors.Address && Errors.Address.Barangay && (
                      <span className="text-red-500 text-sm mt-1 ml-1">*</span>
                    )}
                  </label>
                  <input
                    type="text"
                    name="Barangay"
                    id="Barangay"
                    className={`py-2 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600
                    ${
                      Errors &&
                      Errors.Address &&
                      Errors.Address.Barangay == null
                        ? 'border-gray-200'
                        : 'border-red-500'
                    }
                    `}
                    onKeyPress={(event) => {
                      if (Userinfo.Address.Barangay.length >= 50) {
                        event.preventDefault();
                      }
                    }}
                    required
                    onChange={(e) =>
                      SetUserInfo({
                        ...Userinfo,
                        Address: {
                          ...Userinfo.Address,
                          Barangay: e.target.value,
                        },
                      })
                    }
                  />
                </div>
                <div className="flex-1 m-1">
                  <label
                    htmlFor="City"
                    className="block mb-2 text-md text-gray-700  dark:text-white"
                  >
                    City
                    {Errors && Errors.Address && Errors.Address.City && (
                      <span className="text-red-500 text-sm mt-1 ml-1">*</span>
                    )}
                  </label>
                  <input
                    type="text"
                    name="City"
                    id="City"
                    className={`py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600
                      ${
                        Errors && Errors.Address && Errors.Address.City == null
                          ? 'border-gray-200'
                          : 'border-red-500'
                      }
                    `}
                    onKeyPress={(event) => {
                      if (Userinfo.Address.City.length >= 50) {
                        event.preventDefault();
                      }
                    }}
                    required
                    onChange={(e) =>
                      SetUserInfo({
                        ...Userinfo,
                        Address: { ...Userinfo.Address, City: e.target.value },
                      })
                    }
                  />
                </div>
                <div className="flex-1 m-1">
                  <label
                    htmlFor="Province"
                    className="block mb-2 text-md text-gray-700  dark:text-white"
                  >
                    Province
                    {Errors && Errors.Address && Errors.Address.Province && (
                      <span className="text-red-500 text-sm mt-1 ml-1">*</span>
                    )}
                  </label>
                  <input
                    type="text"
                    name="Province"
                    id="Province"
                    className={`py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600
                    ${
                      Errors &&
                      Errors.Address &&
                      Errors.Address.Province == null
                        ? 'border-gray-200'
                        : 'border-red-500'
                    }
                    `}
                    onKeyPress={(event) => {
                      if (Userinfo.Address.Province.length >= 50) {
                        event.preventDefault();
                      }
                    }}
                    required
                    onChange={(e) =>
                      SetUserInfo({
                        ...Userinfo,
                        Address: {
                          ...Userinfo.Address,
                          Province: e.target.value,
                        },
                      })
                    }
                  />
                </div>
                <div className="flex-1 m-1">
                  <label
                    htmlFor="Country"
                    className="block mb-2 text-md text-gray-700  dark:text-white"
                  >
                    Country
                  </label>
                  <span className="py-3 px-4 block text-center bg-slate-100 w-full h-[45px] border-gray-200 rounded-lg">
                    Philippines
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* Account Info */}
          <>
            <span className="text-md font-medium">Account Information</span>
            <div className="flex flex-1 flex-col sm:flex-row p-2">
              {/* ID
                ID depends on the users(student/teacher/employee) variable that is from the URL parameter 
                */}
              <div className="flex-1 ">
                <label
                  htmlFor="ID"
                  className="block mb-2 capitalize text-md text-gray-700  dark:text-white"
                >
                  {users.slice(0, -1)} ID
                </label>

                {/* //Render the latest ID to create new account */}
                <IDrender
                  IDtype={users.slice(0, -1)}
                  setuserinfo={HandledataID}
                />

                <input
                  type="text"
                  name="ID"
                  id="ID"
                  className={`py-2 hidden px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600
              `}
                  required
                  onChange={(e) =>
                    SetUserInfo({ ...Userinfo, ID: e.target.value })
                  }
                />
              </div>

              {/* LRN ID */}
              {users === 'students' && (
                <div className="flex-1 m-1">
                  <label
                    htmlFor="LRNID"
                    className="flex mb-2 capitalize text-md text-gray-700  dark:text-white"
                  >
                    LRN ID
                    {Errors.LRNID && (
                      <span className="text-red-500 text-xs mt-1 ml-1">
                        {lrnduplicate > 0 && !LrnLoading && Errors.LRNID}*
                      </span>
                    )}
                    {LrnLoading && (
                      <span className="flex space-x-2">
                        <Spinner
                          size="sm"
                          className="flex ml-2"
                          color="secondary"
                        ></Spinner>
                        <span>Checking for duplicates</span>
                      </span>
                    )}
                  </label>

                  <input
                    type="text"
                    name="LRNID"
                    id="LRNID"
                    className={`py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600
                    ${
                      Errors.LRNID == null
                        ? 'border-gray-200'
                        : 'border-red-500'
                    }
                    `}
                    required
                    onChange={(e) =>
                      SetUserInfo({ ...Userinfo, LRNID: e.target.value })
                    }
                  />
                </div>
              )}
              {users === 'teachers' && (
                <>
                  <div className="flex-initial m-1">
                    <label
                      htmlFor="Department"
                      className="block mb-2 text-sm text-gray-700  dark:text-white"
                    >
                      Department{' '}
                      {Errors.Department && (
                        <span className="text-red-500">*</span>
                      )}
                    </label>

                    {/* Selecting role will only be available when adding employee,
                  Static student/teacher role when selected teacher or student to create account*/}
                    {users === 'teachers' ? (
                      <div className="relative">
                        <select
                          className={` pe-9 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 
                      ${
                        Errors.Department == null
                          ? 'border-gray-200'
                          : 'border-red-500'
                      }
                      `}
                          onChange={(e) =>
                            SetUserInfo({
                              ...Userinfo,
                              Department: e.target.value,
                            })
                          }
                        >
                          <option value="" disabled selected hidden>
                            Select Department
                          </option>
                          <option value="Pre">Pre-School</option>
                          <option value="Elem">Elementary</option>
                          <option value="JHS">Junior High-School</option>
                          <option value="SHS">Senior High-School</option>
                        </select>
                      </div>
                    ) : (
                      <span className="py-3 px-4 capitalize text-center block bg-slate-100 w-full h-[45px] border-gray-200 rounded-lg">
                        {users.slice(0, -1)}
                      </span>
                    )}
                  </div>

                  <div className="flex-initial">
                    <label
                      for={`HoursPerWeek`}
                      className="block mb-3 text-sm text-gray-700  dark:text-white"
                    >
                      Teaching hours per Week
                      {Errors.HoursPerWeek && (
                        <span className="text-red-500">*</span>
                      )}
                    </label>
                    <input
                      type="text" // Use type text
                      name={`HoursPerWeek`}
                      id={`HoursPerWeek`}
                      className={`py-2 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
                        Errors.HoursPerWeek == null
                          ? 'border-slate-200'
                          : 'border-red-500'
                      }`}
                      required
                      value={Userinfo.HoursPerWeek} // Use value instead of defaultValue
                      onChange={(e) =>
                        SetUserInfo({
                          ...Userinfo,
                          HoursPerWeek: e.target.value.replace(/\D/g, ''), // Ensure only numeric characters are stored in state
                        })
                      }
                      onKeyDown={(e) => {
                        const isNumeric = /^\d+$/.test(e.key);
                        const isBackspaceOrDelete =
                          e.key === 'Backspace' || e.key === 'Delete';
                        const isMaxLengthReached = e.target.value.length >= 4;
                        const isInputEmpty = e.target.value === '';
                        // Allow only numeric keys, backspace, delete, arrow keys, and navigation keys
                        if (
                          (isNumeric || isBackspaceOrDelete) &&
                          (!isMaxLengthReached || isBackspaceOrDelete)
                        ) {
                          return;
                        } else {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                  <div className="flex-initial ml-1">
                    <label
                      for={`teachingExperience`}
                      className="block mb-3 text-sm text-gray-700  dark:text-white"
                    >
                      Experience in Teaching
                      {Errors.teachingExperience && (
                        <span className="text-red-500">*</span>
                      )}
                    </label>
                    <input
                      type="text" // Use type text
                      name={`teachingExperience`}
                      id={`teachingExperience`}
                      className={`py-2 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
                        Errors.teachingExperience == null
                          ? 'border-slate-200'
                          : 'border-red-500'
                      }`}
                      required
                      value={Userinfo.teachingExperience} // Use value instead of defaultValue
                      onChange={(e) =>
                        SetUserInfo({
                          ...Userinfo,
                          teachingExperience: e.target.value.replace(/\D/g, ''), // Ensure only numeric characters are stored in state
                        })
                      }
                      onKeyDown={(e) => {
                        const isNumeric = /^\d+$/.test(e.key);
                        const isBackspaceOrDelete =
                          e.key === 'Backspace' || e.key === 'Delete';
                        const isMaxLengthReached = e.target.value.length >= 4;
                        const isInputEmpty = e.target.value === '';
                        // Allow only numeric keys, backspace, delete, arrow keys, and navigation keys
                        if (
                          (isNumeric || isBackspaceOrDelete) &&
                          (!isMaxLengthReached || isBackspaceOrDelete)
                        ) {
                          return;
                        } else {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                </>
              )}

              {users === 'employees' && (
                <div className="flex-initial m-1">
                  <label
                    htmlFor="Role"
                    className="block mb-2 text-sm text-gray-700  dark:text-white"
                  >
                    Role
                  </label>

                  {/* Selecting role will only be available when adding employee,
             Static student/teacher role when selected teacher or student to create account*/}
                  {users === 'employees' ? (
                    <div className="relative">
                      <select
                        className={`py-3 px-4 pe-9 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 
                      ${
                        Errors.Role == null
                          ? 'border-gray-200'
                          : 'border-red-500'
                      }
                      `}
                        onChange={(e) =>
                          SetUserInfo({ ...Userinfo, Role: e.target.value })
                        }
                      >
                        <option value="" disabled selected hidden>
                          Select Role
                        </option>
                        <option value="Adminhead">Head</option>
                        <option value="Registrar">Registrar</option>
                        <option value="Scheduler">Scheduler</option>
                      </select>
                      {Errors.Role && (
                        <span className="text-red-500 text-sm mt-1">
                          {Errors.Role}
                        </span>
                      )}
                    </div>
                  ) : (
                    <span className="py-3 px-4 capitalize text-center block bg-slate-100 w-full h-[45px] border-gray-200 rounded-lg">
                      {users.slice(0, -1)}
                    </span>
                  )}
                </div>
              )}
            </div>
            <div className="flex flex-1 flex-col sm:flex-row p-2">
              <div className="flex-1 m-1">
                <label
                  htmlFor="Email"
                  className="flex space-x-2 mb-2 text-md text-gray-700  dark:text-white"
                >
                  Email{' '}
                  {Errors.Email && (
                    <span className="text-red-500 text-xs mt-1 ml-1">
                      {emailduplicate > 0 && !EmailLoading && Errors.Email}*
                    </span>
                  )}
                  {EmailLoading && (
                    <span className="flex space-x-2">
                      <Spinner
                        size="sm"
                        className="flex ml-2"
                        color="secondary"
                      ></Spinner>
                      <span>Checking for duplicates</span>
                    </span>
                  )}
                </label>
                <div
                  className={` shadow-sm  flex w-full border rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
                    Errors.Email == null ? 'border-gray-200' : 'border-red-500'
                  }`}
                >
                  <input
                    type="text"
                    name="Email"
                    id="Email"
                    className={`py-2 px-3 block w-full border-gray-200 rounded-l-lg rounded-bl-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600
                    
                  `}
                    onKeyPress={(event) => {
                      if (event.key === '@') {
                        event.preventDefault();
                      }
                    }}
                    required
                    onChange={(e) =>
                      SetUserInfo({
                        ...Userinfo,
                        Email: e.target.value + '@bsa.com.ph',
                      })
                    }
                  />
                  <span class="px-4 inline-flex items-center min-w-fit rounded-e-md border border-s-0 border-gray-200 bg-slate-300  text-sm dark:bg-gray-700 dark:border-gray-700 ">
                    <span class="text-sm text-gray-500 dark:text-gray-400">
                      @bsa.com.ph
                    </span>
                  </span>
                </div>
              </div>
              <div className="flex-1 m-1">
                <label
                  htmlFor="Password"
                  className="block mb-2 text-md text-gray-700  dark:text-white"
                >
                  Password
                </label>
                <span className="py-3 px-4 capitalize block bg-slate-100 text-center w-full h-[45px] border-gray-200 rounded-lg">
                  {Userinfo.Lastname + Userinfo.Birthdate}
                </span>
              </div>
            </div>
          </>
        </div>

        {/* Button */}
        {/* <div className="flex justify-between">
          <Link
            to={`/user/employee/manage/${users}`}
            className="flex-1 flex mx-1 bg-slate-200 text-center items-center rounded-lg"
          >
            <span className="flex-1 mx-1 bg-slate-200 rounded-lg">
              Cancel
            </span>
          </Link>

          <button type="submit" className="flex-1 mx-1 text-center  py-2 px-3 text-sm font-semibold rounded-lg border border-transparent bg-[#FFDE59] text-black hover:bg-[#fcd84b]">
            Save
          </button>
        </div> */}
      </form>
    </div>
  );
}
