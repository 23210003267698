import { functions } from "../AppwriteConfig/Config";

export function autoSize(textarea) {
  textarea.style.height = "auto"; // Reset the height to auto to recalculate the height based on content
  textarea.style.height = textarea.scrollHeight + "px"; // Set the height to match the content height
}

export async function AppwriteServerStringtoJSON(path, type, data) {
  const body = JSON.stringify(data);

  const getUsers = await functions.createExecution(
    "667bc0d000106435b059",
    body,
    false,
    path,
    type
  );

  return JSON.parse(getUsers.responseBody);
}

export function formatDateFromDB(databaseDate) {
  // Parse the date string directly
  const date = new Date(databaseDate);

  // Extract components
  const year = date.getFullYear();
  const monthIndex = date.getMonth();
  const day = date.getDate();
  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");

  // Determine AM/PM and convert hours to 12-hour format
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12; // Convert 0 hours to 12 for 12-hour format

  // Array of full month names
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  const month = monthNames[monthIndex];

  // Format the date string
  return `${month} ${day}, ${year} - ${hours}:${minutes} ${ampm}`;
}

export function formatDate(isoDate) {
  const [datePart, timePart] = isoDate.split("T");
  const [year, month, day] = datePart.split("-");
  let [hour, minute] = timePart.split(":");

  // Convert to 12-hour format
  hour = parseInt(hour, 10);
  const period = hour >= 12 ? "PM" : "AM";
  hour = hour % 12 || 12; // Adjust hour for 12-hour format

  // Convert month number to full month name
  const monthName = new Date(`${month}/1`).toLocaleString("en-US", {
    month: "long"
  });

  // Format as "Month day, year - 12hr time"
  return `${monthName} ${day}, ${year} - ${hour}:${minute} ${period}`;
}

export function formatToISO(date) {
  if (typeof date === "string") {
    // Assume date is in the format 'YYYY-MM-DDTHH:mm' or 'YYYY-MM-DD'
    const [fullDate, fullTime] = date.split("T");
    const [year, month, day] = fullDate.split("-");
    const [hours = "00", minutes = "00"] = fullTime ? fullTime.split(":") : [];

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  // If date is a Date object, proceed as before
  if (!(date instanceof Date) || isNaN(date)) {
    throw new Error("Invalid date");
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}`;
}

export function convertTo12HourFormat(
  startHour,
  startMinute,
  endHour,
  endMinute
) {
  // Helper function to convert hours to 12-hour format with AM/PM
  function to12HourFormat(hour, minute) {
    const suffix = hour >= 12 ? "PM" : "AM";
    const newHour = hour % 12 || 12; // Converts hour 0 to 12
    const newMinute = minute < 10 ? "0" + minute : minute; // Pads minute with leading zero if needed
    return `${newHour}:${newMinute} ${suffix}`;
  }

  // Convert start time and end time to 12-hour format
  const startTime = to12HourFormat(startHour, startMinute);
  const endTime = to12HourFormat(endHour, endMinute);

  return { startTime, endTime };
}
