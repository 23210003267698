import { useEffect } from "react";
import { client } from "./Config";

const useRealtimeSubscription = (databaseId, collectionId, callback) => {
  let channel;
  switch (collectionId) {
    case "session":
      channel = `account`;
      break;
    case "students":
      channel = `databases.${databaseId}.collections.${process.env
        .REACT_APP_STUDENT}.documents`;
      break;
    case "teachers":
      channel = `databases.${databaseId}.collections.${process.env
        .REACT_APP_TEACHER}.documents`;
      break;
    case "employees":
      channel = `databases.${databaseId}.collections.${process.env
        .REACT_APP_EMPLOYEE}.documents`;
      break;
    case "archived":
  
      channel = [
        `databases.${databaseId}.collections.${process.env
          .REACT_APP_EMPLOYEE}.documents`,
        `databases.${databaseId}.collections.${process.env
          .REACT_APP_TEACHER}.documents`,
        `databases.${databaseId}.collections.${process.env
          .REACT_APP_STUDENT}.documents`
      ];
    
      break;
    default:
      channel = `databases.${databaseId}.collections.${collectionId}.documents`;
      break;
  }
  useEffect(
    () => {
      // Subscribe to Realtime updates for the 'files' channel

      const unsubscribe = client.subscribe(channel, response => {
        // Callback will be executed on changes for all files.

        if (
          response.events.includes(
            "databases.*.collections.*.documents.*.create"
          )
        ) {
          response.changetype = "create";
        } else if (
          response.events.includes(
            "databases.*.collections.*.documents.*.update"
          )
        ) {
          response.changetype = "update";
        } else if (
          response.events.includes(
            "databases.*.collections.*.documents.*.delete"
          )
        ) {
          response.changetype = "delete";
        }

        callback(response);
      });

      // Clean up subscription when component unmounts
      return () => {
        unsubscribe(); // Unsubscribe from Realtime updates
      };
    },
    [databaseId, collectionId, callback]
  );
};
export default useRealtimeSubscription;
