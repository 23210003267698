import { Link } from "react-router-dom";
import { useAuth } from "../utils/AppwriteConfig/AuthContext";
import Account from "../pages/AccountDetails/Account";
import { useEffect, useState } from "react";
import axios from "axios";
import useRealtimeSubscription from "../utils/AppwriteConfig/RealtimeServices";
import { storage } from "../utils/AppwriteConfig/Config";

export function Accprofile({
  Accordionplacement = "top",
  bgcolor = "#af95da"
}) {
  const { user, DataServer, logoutUser } = useAuth();

  const [isLoading, setisLoading] = useState(false);
  const [MainError, setMainError] = useState();

  const [ProfilePicture, setProfilePicture] = useState();
  const [UserCred, setUserCred] = useState({});
  const [imageURL, setImageURL] = useState(null);

  useEffect(() => {
    async function GetUserCred() {
      try {
        setisLoading(true);
        const usercred = await axios.get(`${DataServer}/user/${user.$id}/data`);
        setUserCred(usercred.data);
        setProfilePicture(usercred.data.profilePicture);
      } catch (e) {
        setMainError(e.message);
      } finally {
        setisLoading(false);
      }
    }
    GetUserCred();
  }, [user]);

  useEffect(() => {
    const loadImage = async () => {
      try {
     
        if (ProfilePicture) {
  
          // Load image from storage using data.fileid
          const result = await storage.getFilePreview(
            "66198605bb373176f557",
            ProfilePicture
          );
          setImageURL(result);
        }
      } catch (error) {
        console.error("Error loading image:", error);
      }
    };

    loadImage();
  }, [ProfilePicture]);

  const handleRealtimeChanges = (changes) => {
    if (changes.changetype === "update" && changes.payload.profilePicture) {
      if (changes.payload.$id === user.$id) {
        setProfilePicture(changes.payload.profilePicture);
      }
    } else if (
      changes.changetype === "update" &&
      !changes.payload.profilePicture
    ) {
      if (changes.payload.$id === user.$id) {
        setProfilePicture();
      }
    }
  };

  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_USERCRED,
    handleRealtimeChanges
  );
  return (
    <div
      className={`hs-tooltip cursor-pointer [--trigger:click] w-full flex items-end  sm:[--placement:${Accordionplacement}]`}
    >
      <div
        className={`hs-tooltip-toggle py-2 w-full p-1 flex items-center gap-x-3 bg-[${bgcolor}] dark:bg-[#A16AE8] transition ease-in-out hover:bg-[#ac93d4] shadow-sm`}
      >
        <img
          alt="USer profile picture"
          src={imageURL ? imageURL : require("../Img/BSA.jpg")}
          className="w-[40px] object-cover h-[40px] rounded-full"
        />
        <div className="grow">
          <h4 className="font-semibold text-sm text-gray-800 dark:text-white">
            {UserCred?.Username?.slice(0, 20)}
          </h4>
          <p className="text-sm text-gray-800 md:text-gray-500 dark:text-white md:dark:text-gray-500">
            {user.email.slice(0, 16)}
          </p>
        </div>
        <svg
          className="hs-tooltip-shown:rotate-180 mr-2 w-4 h-4"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="m6 9 6 6 6-6" />
        </svg>
        <div
          className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible hidden opacity-0 transition-opacity inline-block absolute invisible z-10 max-w-xs w-full bg-[#b082ec] text-start rounded-xl shadow-md after:absolute after:top-0 after:-start-4 after:w-4 after:h-full dark:bg-gray-800 dark:border-gray-700"
          role="tooltip"
        >
          <ul>
            <li
              onClick={() => {
                document.querySelector("#accbtn").click();
              }}
            >
              <span className="flex items-center gap-x-3.5 py-2 px-2.5 bg-[#b082ec] text-sm text-slate-700 rounded-lg hover:bg-[#b58de9] dark:bg-gray-900 dark:text-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-person"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                </svg>
                Account
              </span>
            </li>
            <Account />
            <li>
              <span
                onClick={() => logoutUser()}
                className="flex items-center gap-x-3.5 py-2 px-2.5 bg-[#b082ec] text-sm text-slate-700 rounded-lg hover:bg-[#b58de9] dark:bg-gray-900 dark:text-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-box-arrow-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0z"
                  />
                  <path
                    fillRule="evenodd"
                    d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708z"
                  />
                </svg>
                Logout
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
