import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure
} from "@nextui-org/react";
import ConfigurationForm from "./ConfigurationForm";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import axios from "axios";

export default function NewContainer({ totalContainers }) {
  const { isOpen, onOpen, onClose, onOpenChange } = useDisclosure();
  const { server, user } = useAuth();
  const [isLoading, setisLoading] = useState(false);
  const [MainError, setMainError] = useState();
  const [errors, setErrors] = useState({});
  const [newdata, setnewdata] = useState({
    creator: user.$id,
    creatorname: user.name
  });

  const AddnewContainer = async () => {
    if (newdata) {
      try {

        setisLoading(true);
        const additem = await axios.post(
          `${server}/v1/home/container/new`,
          newdata
        );
      } catch (e) {
        setMainError(e.message);
      } finally {
        setisLoading(false);
      }
      onClose();
    }
  };

  const updatenewdata = (data) => {
    const newErrors = {};
    if (!data.name) newErrors.name = "Name is required.";
    if (!data.title) newErrors.title = "Title is required.";
    if (!data.type) newErrors.type = "Type is required.";
    if (!data.order) newErrors.order = "Order is required.";

    setErrors(newErrors);
    setnewdata({ ...newdata, ...data });
  };

  return (
    <>
      <div
        onClick={onOpen}
        className=" h-full bg-[#A16AE8] hover:bg-[#9259dd] flex flex-col space-y-1 bg hover: justify-center items-center rounded-lg w-60"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="currentColor"
          class="bi bi-plus-circle"
          viewBox="0 0 16 16"
        >
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
        </svg>
        Add container
      </div>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 pb-0">
                Add New Container
              </ModalHeader>
              <ModalBody>
                <ConfigurationForm
                  type="new"
                  pushdata={updatenewdata}
                  isform={true}
                  containerLength={totalContainers}
                />
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>

                {isLoading ? (
                  <Button isLoading disabled color="secondary">
                    Uploading
                  </Button>
                ) : (
                  <Button
                    isDisabled={Object.keys(errors).length > 0}
                    color="secondary"
                    onPress={AddnewContainer}
                  >
                    Add Item
                  </Button>
                )}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
