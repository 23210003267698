import React from "react";
import {
  Tooltip,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent
} from "@nextui-org/react";

export default function ResponsiveToolTip({
  tooltipIconColor = "currentColor",
  tooltipPlacement = "right",
  tooltipTitle = "title",
  tooltipMessage = "Message"
}) {
  return (
    <div className=" max-w-20">
      <div className="flex md:hidden">
        <Popover placement={tooltipPlacement}>
          <PopoverTrigger>
            <button isIconOnly className=" rounded-full p-0 flex flex-initial">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill={tooltipIconColor}
                class="bi bi-info-circle-fill"
                viewBox="0 0 16 16"
              >
                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2" />
              </svg>
            </button>
          </PopoverTrigger>
          <PopoverContent className=" max-w-40">
            <div className="px-1 py-2">
              <div className="text-small font-bold">{tooltipTitle}</div>
              <div className="text-tiny">{tooltipMessage}</div>
            </div>
          </PopoverContent>
        </Popover>
      </div>
      <div className="hidden md:flex">
        <Tooltip
          placement={tooltipPlacement}
          content={
            <div className="px-1 py-2 max-w-40">
              <div className="text-small font-bold">{tooltipTitle}</div>
              <div className="text-tiny">{tooltipMessage}</div>
            </div>
          }
          delay={0}
          closeDelay={0}
          motionProps={{
            variants: {
              exit: {
                opacity: 0,
                transition: {
                  duration: 0.1,
                  ease: "easeIn"
                }
              },
              enter: {
                opacity: 1,
                transition: {
                  duration: 0.15,
                  ease: "easeOut"
                }
              }
            }
          }}
        >
          <button variant="flat" isIconOnly className="p-1 rounded-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill={tooltipIconColor}
              class="bi bi-info-circle-fill"
              viewBox="0 0 16 16"
            >
              <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2" />
            </svg>
          </button>
        </Tooltip>
      </div>
    </div>
  );
}
