import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure
} from "@nextui-org/react";
import axios from "axios";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import Error from "../../../../../Components/ErrorIndicator";

export default function CancelRequestConfirm({ refresher, data, unselector }) {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const { DataServer, user } = useAuth();
  const [isLoading, setisLoading] = useState(false);
  const [MainError, setMainError] = useState();

  useEffect(() => {
    if (data) {
      document.querySelector("#DeleteREQ").click();
      onOpenChange(true);
    }
  }, [data]);

  const handledelete = async () => {
    // Check the updated state value after setting the errors

    if (true) {
      setisLoading(true);
      try {
        const response = await axios.delete(
          `${DataServer}/info/request/delete`,
          {
            params: {
              id: data
            }
          }
        );
      } catch (e) {
        setMainError(e.message);
      } finally {
        setisLoading(false);
        unselector && unselector(null);
        onClose();
        refresher && refresher();
      }
    } else {
      console.log("Errors found:");
      // Optionally, you can handle errors in UI or perform other actions
    }
  };

  return (
    <>
      {MainError && <Error errorMessage={MainError} reset={setMainError} />}
      <button
        id="DeleteREQ"
        aria-haspopup="dialog"
        aria-expanded="false"
        aria-controls="hs-offcanvas-body-scrolling-with-backdrop"
        data-hs-overlay="#hs-offcanvas-body-scrolling-with-backdrop"
        className=" hidden"
      ></button>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        isDismissable={false}
        isKeyboardDismissDisabled={true}
        scrollBehavior="inside"
        backdrop="opaque"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col w-full max-w-full break-words gap-1 ">
                <div>Are you sure you want to cancel the request ?</div>
              </ModalHeader>
              <ModalBody>
                <p>Any changes made will not be saved and be discarded.</p>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Cancel
                </Button>
                {isLoading ? (
                  <Button
                    isLoading
                    color="secondary"
                    spinner={
                      <svg
                        className="animate-spin h-5 w-5 text-current"
                        fill="none"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          fill="currentColor"
                        />
                      </svg>
                    }
                  >
                    Loading
                  </Button>
                ) : (
                  <Button
                    aria-haspopup="dialog"
                    aria-expanded="false"
                    aria-controls="hs-offcanvas-body-scrolling-with-backdrop"
                    data-hs-overlay="#hs-offcanvas-body-scrolling-with-backdrop"
                    color="secondary"
                    onClick={() => {
                      handledelete();
                    }}
                  >
                    Continue
                  </Button>
                )}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
