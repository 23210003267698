import axios from "axios";
import { useEffect, useState } from "react";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  Button,
  Skeleton
} from "@nextui-org/react";
import AdvisorSubjectSelect from "../modal/AdviserSubjectSelector";
import UnAssignedSection from "../modal/UnAssignedAdvisor";

export default function AdvisorWarning({
  isPriority = false,
  subjectToTeach,
  ParentUpdate,
  department,
  Advisors
}) {
  const { DataServer } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [unassignedSections, setUnassignedSections] = useState([]);

  const [selectedSubject, setSelectedSubject] = useState({});

  const [isrefresh, setIsRefresh] = useState(false);

  console.log(department);
  // paranet status updater
  useEffect(() => {
    ParentUpdate(
      !isLoading && unassignedSections && unassignedSections.length === 0
    );
  }, [isLoading, unassignedSections]);

  useEffect(() => {
    subjectToTeach(selectedSubject);
  }, [selectedSubject]);

  console.log("adad: ", isPriority);

  useEffect(() => {
    async function unAssignedSections() {
      try {
        setIsLoading(true);
        const unassignedSection = await axios.get(
          `${DataServer}/course/section/advisory/unassigned`,
          { params: { dept: department } }
        );
        console.log("ETOs:", unassignedSection);
        Advisors(unassignedSection.data.advisorSet);
        setUnassignedSections(unassignedSection.data.documents);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
    if (isPriority && department) {
      unAssignedSections();
      console.log("HSHHS");
    }
  }, [isPriority, department, isrefresh]);

  //If loading
  if (isLoading && isPriority && department) {
    return (
      <Skeleton
        isLoaded={!isLoading}
        className={`flex rounded-lg h-20 transition-all delay-150 ease-in-out ${
          unassignedSections && unassignedSections.length > 0
            ? "bg-warning-50"
            : "bg-secondary-50"
        }  ease-in-out transition-all delay-200 ${
          isPriority ? "opacity-100 visible" : "opacity-0 invisible"
        }`}
      ></Skeleton>
    );
  }

  function resfresher() {
    setIsRefresh(!isrefresh);
  }

  return (
    isPriority &&
    department && (
      <div className=" flex flex-col w-full relative">
        <div
          className={`flex  my-2 h-10  ${
            isPriority ? "opacity-100 visible" : "opacity-0 invisible"
          }`}
        >
          <div className=" flex w-full">
            <div className="flex-initial font-meduim text-sm max-w-[90px]">
              Subject to teach :{" "}
            </div>
            {Object.keys(selectedSubject).length > 0 ? (
              <div className=" w-full text-black rounded-md flex bg-[#F4F4F5] p-2 justify-between">
                <span className=" text-ellipsis">
                  {selectedSubject.subjectName}
                </span>
                <button
                  onClick={() => {
                    setSelectedSubject({});
                  }}
                >
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-x"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                  </svg>
                </button>
              </div>
            ) : (
              <div className="flex flex-1 w-full flex-col relative">
                {Object.keys(selectedSubject).length > 0 ? (
                  <div className=" w-full p-2 bg-secondary-500"></div>
                ) : (
                  <AdvisorSubjectSelect
                    selectSubject={setSelectedSubject}
                    disable={unassignedSections.length > 0}
                    dept={department}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        <div
          className={`flex h-20 ${
            unassignedSections && unassignedSections.length > 0
              ? "bg-warning-50"
              : "bg-secondary-50"
          } ease-in-out transition-transform rounded-md transform hover:delay-0 cursor-pointer delay-200 ${
            isPriority ? "opacity-100 visible" : "opacity-0 invisible"
          } hover:scale-105`}
        >
          {unassignedSections && unassignedSections.length > 0 ? (
            <UnAssignedSection
              reset={resfresher}
              sections={unassignedSections}
            />
          ) : (
            <div className="flex rounded-md">
              <div className=" flex-initial flex items-center justify-center size-20">
                <div className="bg-secondary-200 size-14 rounded-full flex justify-center items-center">
                  <div className="bg-secondary-300 text-secondary-800 size-10 rounded-full flex justify-center items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-hand-thumbs-up-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a10 10 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733q.086.18.138.363c.077.27.113.567.113.856s-.036.586-.113.856c-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.2 3.2 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.8 4.8 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z" />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="flex-1 w-full py-2 p-1 h-full  flex flex-col">
                <div className="  w-full text-sm font-semibold text-secondary-700 flex-1 flex items-end">
                  You are all good
                </div>
                <div className="  text-xs w-full flex-1">
                  All sections have advisor
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  );
}
