import {
  Card,
  CardHeader,
  CardBody,
  Divider,
  Select,
  SelectItem,
  Button
} from "@nextui-org/react";
import { useAuth } from "../../../utils/AppwriteConfig/AuthContext";
import { useEffect, useState } from "react";
import StudentSchedule from "./components/studentSchedule";
import TeacherSchedule from "./components/TeacherSchedule";
import axios from "axios";
import Error from "../../../Components/ErrorIndicator";
import useRealtimeSubscription from "../../../utils/AppwriteConfig/RealtimeServices";
import RequestTeacherOffCanvas from "./components/RequestTeacherCanvas";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export default function Schedules() {
  const { user, role, DataServer } = useAuth();
  const days = [
    { label: "Monday", value: "Mon" },
    { label: "Tuesday", value: "Tue" },
    { label: "Wednesday", value: "Wed" },
    { label: "Thursday", value: "Thu" },
    { label: "Friday", value: "Fri" }
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [MainError, setMainError] = useState("");
  const [isRefresh, setIsRefresh] = useState(true);
  const [selectedDay, setSelectedDay] = useState("Mon");
  const [schedule, setSchedule] = useState({});

  // Initializes day
  useEffect(() => {
    const currentDay = new Date().getDay();
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    setSelectedDay(
      daysOfWeek[currentDay] === "Sun" || daysOfWeek[currentDay] === "Sat"
        ? daysOfWeek[1]
        : daysOfWeek[currentDay]
    );
  }, [isRefresh]);

  // Get schedule
  useEffect(() => {
    async function scheduleResponse() {
      try {
        setIsLoading(true);
        const scheduleResponse = await axios.get(
          `${DataServer}/user/${role}/${user.$id}/schedule/${selectedDay}`
        );
        setSchedule(scheduleResponse.data);
      } catch (error) {
        setMainError(error.message);
      } finally {
        setIsLoading(false);
      }
    }

    if (selectedDay !== "") scheduleResponse();
  }, [isRefresh, selectedDay]);
  const saveAsPDF = async () => {
    try {
      const printableElement = document.querySelector(".printable-schedule");
      if (!printableElement) {
        console.error("Schedule content not found.");
        return;
      }
  
      // Apply a violet border to the schedule table before capture
      printableElement.style.border = "2px solid violet";  // Add violet border
  
      // Ensure fonts are loaded
      await document.fonts.ready;
  
      // Clone the printable element to avoid zooming effects
      const cloneElement = printableElement.cloneNode(true);
  
      // Remove any inline styles that could affect the capture
      cloneElement.style.transform = "none"; // Reset transformation (no zoom)
      cloneElement.style.zoom = "1"; // Force no zoom (override browser zoom)
  
      // Temporarily append the clone to the document for capturing
      const body = document.body;
      body.appendChild(cloneElement);
  
      // Calculate the full height and width of the cloned element
      const fullHeight = cloneElement.scrollHeight;
      const fullWidth = cloneElement.scrollWidth;
  
      // Capture the entire content without relying on zoom
      const canvas = await html2canvas(cloneElement, {
        scale: 4, // Use a higher scale for better resolution
        useCORS: true, // Allow external images
        scrollX: -window.scrollX, // Correct horizontal scrolling
        scrollY: -window.scrollY, // Correct vertical scrolling
        width: fullWidth, // Full width of the cloned element
        height: fullHeight, // Full height of the cloned element
        x: 0, // Starting x-coordinate for capture
        y: 0, // Starting y-coordinate for capture
      });
  
      // Remove the cloned element after capture
      body.removeChild(cloneElement);
  
      // Convert canvas to PDF
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4"); // Portrait mode, A4 size
      const pageWidth = 210; // A4 width in mm
      const pageHeight = 297; // A4 height in mm
      const imgWidth = pageWidth;
      let imgHeight = (canvas.height * imgWidth) / canvas.width;
  
      // Check if content fits within one page
      if (imgHeight <= pageHeight) {
        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      } else {
        // Handle multi-page content
        let remainingHeight = canvas.height;
        let yOffset = 0;
  
        while (remainingHeight > 0) {
          const pageCanvas = document.createElement("canvas");
          pageCanvas.width = canvas.width;
          pageCanvas.height = Math.min(
            pageHeight * (canvas.width / pageWidth),
            remainingHeight
          );
  
          const ctx = pageCanvas.getContext("2d");
          ctx.drawImage(
            canvas,
            0,
            yOffset,
            canvas.width,
            pageCanvas.height,
            0,
            0,
            canvas.width,
            pageCanvas.height
          );
  
          const imgPart = pageCanvas.toDataURL("image/png");
          pdf.addImage(
            imgPart,
            "PNG",
            0,
            0,
            imgWidth,
            (pageCanvas.height * pageWidth) / canvas.width
          );
  
          remainingHeight -= pageCanvas.height;
          yOffset += pageCanvas.height;
  
          if (remainingHeight > 0) {
            pdf.addPage();
          }
        }
      }
  
      // Save the PDF file
      pdf.save(`Schedule.pdf`);
    } catch (error) {
      console.error("Error saving PDF:", error);
    }
  };
  
  
  
  
  

  

  return (
    <div className="flex flex-1 flex-col w-full ">
      {MainError && <Error errorMessage={MainError} reset={setMainError} />}
      <div className="flex-initial flex items-center mb-2 justify-between p-1 pt-6">
        <span className="text-4xl font-semibold text-[#72539b]">
          Weekly Class Schedule
        </span>
        {/* Buttons Section */}
        <div className="flex flex-row items-center gap-2 p-2">
  {/* RequestTeacherOffCanvas */}
  <div className="flex items-center justify-center">
    <RequestTeacherOffCanvas wholeSchedule={schedule.schedules} />
  </div>

  {/* Print PDF Button - Visible only for teachers */}
  {role.includes("teacher") && (
    <div className="flex items-center justify-center">
      <Button
        type="button"
        className="m-1 py-3 px-4 text-black inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-[#FFDE59] hover:bg-[#efcf4c] focus:outline-none focus:bg-[#FFDE59] disabled:opacity-50 disabled:pointer-events-none relative transition-transform duration-300 transform hover:scale-105"
        onClick={(e) => {
          e.stopPropagation(); // Prevent interaction with other components
          saveAsPDF(e); // Call the PDF save logic
        }}
      >
        Print PDF
      </Button>
    </div>
  )}
</div>
      </div>
      {isLoading ? (
        <div className="w-full relative flex flex-col h-full justify-center items-center p-2">
          <div className="absolute w-[90%] h-[80%] rounded-lg bg-secondary-200 transition-opacity animate-pulse"></div>
          <img
            className="z-10 size-60 bg-cover object-cover rounded-xl"
            src={require("../../../Img/scheduleFetching.gif")}
          />
          <div className="z-10 font-xs text-secondary p-2 text-xl w-full flex justify-center">
            Fetching your Schedules
          </div>
        </div>
      ) : (
        <div className="flex-1 overflow-auto ">
          <Card className="font-poppins rounded-md h-full max-w-full printable-schedule">
            {!role.includes("teacher") &&
              schedule.courseName &&
              schedule.sectionName &&
              schedule.schedules && (
                <CardHeader className="flex gap-3 justify-between">
                  <div className="font-medium text-md">
                    {schedule?.courseName || "Course"} |{" "}
                    {schedule?.sectionName || "Section"}
                  </div>
                  <Select
                    size="sm"
                    onSelectionChange={(key) => {
                      setSelectedDay(key.currentKey);
                    }}
                    label="Day"
                    selectedKeys={[selectedDay]}
                    placeholder="Select a day"
                    className="max-w-xs"
                  >
                    {days.map((day) => (
                      <SelectItem key={day.value} value={day.value}>
                        {day.label}
                      </SelectItem>
                    ))}
                  </Select>
                </CardHeader>
              )}

            <Divider />
            <CardBody className="p-0 h-full w-full max-w-full overflow-hidden">
              <div className=" w-full h-full overflow-auto">
                {schedule &&
                Object.keys(schedule).length > 0 &&
                schedule.schedules &&
                ((role.includes("teacher") &&
                  Object.keys(schedule.schedules).length > 0) ||
                  (role.includes("student") &&
                    Object.keys(schedule.schedules).length > 2)) ? (
                  <>
                    {user.labels.includes("teacher") && (
                      <TeacherSchedule schedule={schedule.schedules} />
                    )}
                    {user.labels.includes("student") && (
                      <StudentSchedule
                        schedule={schedule.schedules}
                        dayz={selectedDay}
                      />
                    )}
                  </>
                ) : (
                  <div className=" w-full h-full flex flex-col items-center justify-center">
                    <div className=" w-full flex justify-center items-center p-2">
                      <img
                        className=" size-80 bg-cover object-cover rounded-lg"
                        src={require("../../../Img/loading schedule.gif")}
                      />
                    </div>
                    <div className=" font-xs p-2 text-xl w-full flex justify-center">
                      {schedule.courseName && schedule.sectionName
                        ? "No Schedule yet"
                        : schedule.courseName && !schedule?.sectionName
                        ? "You are not assigned to any section yet"
                        : role === "teacher"
                        ? "You dont have any subject scheduled yet"
                        : "You are not assigned to any grade level yet"}
                    </div>
                  </div>
                )}
              </div>
            </CardBody>
          </Card>
        </div>
      )}
    </div>
  );
}
