import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Dashboard from "./DashboardPage/Dashboard";
import { useEffect } from "react";
import { UserHeader } from "../../Components/Headers/UserHeader";
import Sidebar from "../../Components/Sidebar";
import EditUser from "./UserManagment/Actions/EditUser";
import { useAuth } from "../../utils/AppwriteConfig/AuthContext";
import Subjects from "./SubjectPage/Subjects";
import Courses from "./CoursePage/Course";
import Pages from "./{Admin_Head}/EditPages_Page/EditPages";
import Media from "./{Admin_Head}/Media";
import Creationstep from "./UserManagment/components/Creationstep";
import Documents from "./DocumentsPage/Documents";
import Annoouncements from "./AnnouncementPage/Announcement";
import UserCalendar from "../CalendarPage/Calendar";
import UserManagment from "./UserManagment/NewUsermanagment";
import EditAccount from "../AccountDetails/actions/EditAccount";
import EditDetails from "../AccountDetails/components/EditDetails";
import UserDetails from "./UserManagment/components/UserDetails";
import Schedule from "./SchedulePage/Schedules";
import GenerateSchedule from "./SchedulePage/GenerateSchedule";
import NotificationPage from "../NotificationPage/NotificationPage";
import Rooms from "./{Admin_Head}/RoomPage/Rooms";
import MultipleCSV from "./UserManagment/MultipleCSV";

export default function Employee({ role }) {
  const { user } = useAuth();

  return user ? (
    <div className="flex flex-col w-screen h-screen">
      <UserHeader />
      <Sidebar Role={role} />
      <div class=" w-screen h-screen relative flex lg:ps-[276px] p-2 md:px-6  overflow-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-slate-700  dark:[&::-webkit-scrollbar-thumb]:bg-slate-500 ">
        {/* <!-- Page Heading --> */}
        <div className="flex  w-full h-full">
          <Routes>
            <Route path="/add/multiple" element={<MultipleCSV />} />
            <Route path="/manage/:users" element={<UserManagment />} />
            <Route path="/manage/schedules" element={<Schedule />} />
            <Route
              path="/manage/schedules/generate/:dept/:schedtype"
              element={<GenerateSchedule />}
            />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/manage/subjects" element={<Subjects />} />
            <Route path="/manage/courses" element={<Courses />} />
            <Route path="/manage/rooms" element={<Rooms />} />
            <Route path="/manage/pages" element={<Pages />} />
            <Route path="/manage/container/whole" element={<Pages />} />
            <Route path="/manage/announcements" element={<Annoouncements />} />
            <Route path="/manage/:users/new" element={<Creationstep />} />
            <Route
              path="/manage/account/:users/:action/:id"
              element={<UserDetails />}
            />
            <Route path="/manage/documents" element={<Documents />} />
            <Route path="/manage/calendar" element={<UserCalendar />} />
            <Route path="/manage/notification" element={<NotificationPage />} />
            <Route path="/manage/account" element={<EditDetails />} />
          </Routes>
        </div>
      </div>
    </div>
  ) : (
    <div>Pls Login</div>
  );
}
// // <!-- ========== MAIN CONTENT ========== -->
// // <!-- Sidebar Toggle -->
// <div class="sticky top-0 inset-x-0 z-20 bg-white border-y px-4 sm:px-6 md:px-8 lg:hidden dark:bg-gray-800 dark:border-gray-700">
//   <div class="flex items-center py-4">
//     {/* <!-- Navigation Toggle --> */}
//     <button type="button" class="text-gray-500 hover:text-gray-600" data-hs-overlay="#application-sidebar" aria-controls="application-sidebar" aria-label="Toggle navigation">
//       <span class="sr-only">Toggle Navigation</span>
//       <svg class="size-5" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
//         <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
//       </svg>
//     </button>
//     {/* <!-- End Navigation Toggle -->

//     <!-- Breadcrumb --> */}
//     <ol class="ms-3 flex items-center whitespace-nowrap">
//       <li class="flex items-center text-sm text-gray-800 dark:text-gray-400">
//         Application Layout
//         <svg class="flex-shrink-0 mx-3 overflow-visible size-2.5 text-gray-400 dark:text-gray-600" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
//           <path d="M5 1L10.6869 7.16086C10.8637 7.35239 10.8637 7.64761 10.6869 7.83914L5 14" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
//         </svg>
//       </li>
//       <li class="text-sm font-semibold text-gray-800 truncate dark:text-gray-400" aria-current="page">
//         Dashboard
//       </li>
//     </ol>
//     {/* <!-- End Breadcrumb --> */}
//   </div>
// </div>
// // <!-- End Sidebar Toggle -->

// // <!-- Sidebar -->
// <Sidebar Role={role} />
// // <!-- End Sidebar -->

// // <!-- Content -->
// <div class="w-full pt-10 px-4 sm:px-6 md:px-8 lg:ps-72">
//   {/* <!-- Page Heading --> */}
//   <Routes>
//               <Route path="/manage/:users" element={<UserManagment />} />
//               <Route path="/dashboard" element={<Dashboard />} />
//               <Route path="/manage/subjects" element={<Subjects />} />
//               <Route path="/manage/courses" element={<Courses />} />
//               <Route path="/manage/pages" element={<Pages />} />
//               <Route
//                 path="/manage/announcements"
//                 element={<Annoouncements />}
//               />
//               <Route path="/manage/media" element={<Media />} />
//               <Route path="/manage/:users/new" element={<Creationstep />} />
//               <Route
//                 path="/manage/account/:users/:action/:id"
//                 element={<EditUser />}
//               />
//               <Route path="/manage/documents" element={<Documents />} />
//               <Route path="/manage/calendar" element={<UserCalendar />} />
//               <Route path="/manage/account" element={<EditDetails />} />
//             </Routes>
//   {/* <!-- End Page Heading --> */}
// </div>
// // <!-- End Content -->
