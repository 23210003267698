import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Spinner,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell
} from "@nextui-org/react";
import TableNoWrapper from "../../../../../Components/Table/TableNoWrapper";
import { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";

export default function SubTeacherModal({ sub, data, updateTeacher, subss }) {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();

  const { DataServer } = useAuth();

  const [isLoading, setisLoading] = useState(false);
  const [MainError, setMainError] = useState("");

  const [Teachers, setTeachers] = useState([]);
  const [selectedTeacher, setselectedTeacher] = useState({});

  const [selectedKeys, setSelectedKeys] = useState([]);
  const [searchQuery, setsearchQuery] = useState("");
  const [search, setSearch] = useState("");
  // const [Pages, setPages] = useState({
  //   total: 0,
  //   current: 1
  // });

  //Used to delay to prevent multiple requests
  useEffect(() => {
    setisLoading(true);

    const timerId = setTimeout(() => {
      // Perform search operation here with the current value of 'search'

      setsearchQuery(search);
    }, 500);
    setisLoading(false);
  }, [search]);

  useEffect(() => {
    async function getTeachers() {
      try {
        console.log(data);
        setisLoading(true);
        const cusers = await axios.get(
          `${DataServer}/info/available/teachers`,
          {
            params: {
              data
            }
          }
        );

        // const totalPages = Math.ceil(cusers.data.total / 10);
        // setPages({ ...Pages, total: totalPages });
        console.log(cusers.data);
        setTeachers(cusers.data.documents);
      } catch (e) {
        setMainError(e.message);
      } finally {
        setisLoading(false);
      }
    }
    if (isOpen) getTeachers();
  }, [searchQuery, isOpen]);

  //   useEffect(() => {
  //     updateteacher(selectedTeacher);
  //   }, [selectedTeacher]);

  function CloseModal() {
    setselectedTeacher({});
    onClose();
  }

  return (
    <>
      <Button
        color="secondary"
        className="px-4 py-2 text-sm font-medium text-white bg-secondary-500 rounded-md hover:bg-secondary-700 transition-colors"
        onPress={onOpen}
      >
        Assign Teacher
      </Button>
      <Modal
        scrollBehavior="inside"
        isOpen={isOpen}
        onClose={CloseModal}
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          {(CloseModal) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Available Teachers
              </ModalHeader>
              <ModalBody>
                <div className=" flex-initial">
                  <div className=" p-4 bg-secondary-100 text-secondary-500 rounded-lg text-base flex justify-between">
                    <div>
                      {selectedTeacher &&
                      Object.keys(selectedTeacher).length > 0
                        ? selectedTeacher?.userCredentials?.Username
                        : "Select a Teacher"}
                    </div>
                    <div>{selectedTeacher && selectedTeacher?.department}</div>
                  </div>
                </div>
                <div className=" flex-1 ">
                  <div className=" h-full flex flex-col">
                    <div className="flex flex-1 mt-2 overflow-hidden">
                      <div className="w-full max-h-full h-full  overflow-hidden">
                        <div className="block  max-h-full h-full overflow-hidden">
                          {/* This section will be the only one scrollable */}
                          <div className="bg-white rounded-lg max-h-full h-full flex overflow-auto">
                            {isLoading ? (
                              <div className="w-full h-full flex pt-1 p-4 shadow-md flex-col border-1 rounded-xl  justify-center item-center">
                                <div className=" bg-slate-200 p-5 rounded-xl"></div>
                                <Spinner color="secondary" className="m-4" />
                              </div>
                            ) : (
                              <>
                                {Teachers && Teachers.length > 0 ? (
                                  <Table
                                    selectionMode="single"
                                    removeWrapper
                                    onSelectionChange={(keys) => {
                                      const st = Teachers.find(
                                        (teacher) =>
                                          teacher.$id === keys.anchorKey
                                      ); // Assuming Teachers is your array of teachers
                                      setselectedTeacher(st);
                                    }}
                                    isHeaderSticky
                                    className="overflow-auto max-h-full h-full"
                                    aria-label="Example static collection table"
                                  >
                                    <TableHeader>
                                      <TableColumn>NAME</TableColumn>
                                      <TableColumn>EMAIL</TableColumn>
                                    </TableHeader>
                                    <TableBody>
                                      {Teachers &&
                                        Teachers.map((teacher) => (
                                          <TableRow key={teacher.$id}>
                                            <TableCell>
                                              {teacher.userCredentials.Username}
                                            </TableCell>
                                            <TableCell>
                                              {teacher.userCredentials.Email}
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                    </TableBody>
                                  </Table>
                                ) : (
                                  <div className="w-full h-full flex p-4  flex-col rounded-xl  justify-center item-center">
                                    No Teacher named {searchQuery}
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className=" flex-initial flex justify-center">
                      {/* Pagination for the documents */}
                      {/* <Pagination
          className="flex-initial p-4"
          loop
          showControls
          color="secondary"
          total={Pages.total}
          initialPage={1}
          page={Pages.current}
          onChange={(setCurrentPage) =>
            setPages((prevPages) => ({
              ...prevPages,
              current: setCurrentPage
            }))
          }
        /> */}
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={CloseModal}>
                  Close
                </Button>
                <Button
                  isDisabled={Object.keys(selectedTeacher).length <= 0}
                  color="secondary"
                  onPress={() => {
                    updateTeacher({ ...subss, [data.$id]: selectedTeacher });
                    CloseModal();
                  }}
                >
                  Select
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
