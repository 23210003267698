export default function Anouncementdashskeleton() {
  return (
    <a
      className="group flex mb-4  p-2 animate-pulse rounded-xl overflow-hidden bg-gradient-to-r bg-[#cc78db] hover:from-[#8551c9] hover:to-[#5b2a68] rounded-lg dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 border-[#452063]"
      href="#"
    >
      <div className="flex-shrink-0 h-[25px] relative rounded-xl overflow-hidden w-1/3  flex items-center justify-center"></div>

      <div className="flex flex-col pl-4">
        {/* Added padding to create gap */}
        <h3 className="text-xl font-semibold p-2 text-white w-28 bg-slate-200 rounded-full  dark:text-white mt-2">
          {/* Added mt-2 for additional space */}
        </h3>
        <p className="mt-2 text-white bg-slate-200 w-14 text-sm p-2 dark:text-gray-400 rounded-full"></p>
        <p className="mt-1 inline-flex p-2 rounded-full w-16 items-center p-2 gap-x-1 text-[#ffde59] hover:text-[#ffce2a] decoration-2 hover:underline text-xs">
          <svg
            className="flex-shrink-0 w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="m9 18 6-6-6-6" />
          </svg>
        </p>
      </div>
    </a>
  );
}
