import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardBody, Button } from "@nextui-org/react";
import { account } from "../../../utils/AppwriteConfig/Config";
import { useAuth } from "../../../utils/AppwriteConfig/AuthContext";
import Error from "../../../Components/ErrorIndicator";

export default function EditPassword() {
  const { user, server } = useAuth();

  const [isLoading, setisLoading] = useState(false);
  const [MainError, setMainError] = useState();
  const [Errors, setErrors] = useState({});
  const [Password, setPassword] = useState({
    old: "",
    new: "",
    retype: ""
  });
  const [Feedback, setFeedback] = useState();

  // Validation useEffect for Password.new length and matching retype
  useEffect(() => {
    // Check if new password meets length requirement
    if (Password.new.length < 8) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        new: "Password must be at least 8 characters long"
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, new: "" }));
    }

    // Check if retype password matches new password
    if (Password.retype && Password.retype !== Password.new) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        retype: "Password do not match"
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, retype: "" }));
    }
  }, [Password.new, Password.retype]);

  const handleUpdatepassword = async () => {
    // Clear main error
    setMainError();

    if (
      Password.old !== Password.new &&
      Password.new === Password.retype &&
      Password.new
    ) {
      try {
        setisLoading(true);
        await account.updatePassword(Password.new, Password.old);
        setFeedback("Password has been successfully changed");
        setPassword({
          old: "",
          new: "",
          retype: ""
        });
      } catch (e) {
        setMainError(e.message);
      } finally {
        setisLoading(false);
      }
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setFeedback();
    }, 5000);

    return () => clearTimeout(timer);
  }, [Feedback]);

  return (
    <Card shadow="none" className="rounded-md">
      <CardHeader className="pb-0 bg-[#f7f7f7f7] rounded-md rounded-b-none p-2 border-[#a16ae8] border-b-3 pt-2 px-4 flex-col items-start">
        <span className="text-lg">Edit Password</span>
      </CardHeader>
      <CardBody className="overflow-auto space-y-2 py-2">
        {MainError && <Error errorMessage={MainError} reset={setMainError} />}
        <div className="w-full h-full flex flex-col">
          <div className="h-full w-full max-h-full overflow-auto">
            <div className="p-2 ">
              <div className="flex flex-col space-y-2">
                <div className="flex flex-col">
                  <label
                    htmlFor="CurrentPassword"
                    className="block mb-2 text-md text-gray-700 font-medium dark:text-white"
                  >
                    Current Password
                  </label>
                  <input
                    type="text"
                    name="CurrentPassword"
                    value={Password.old}
                    id="CurrentPassword"
                    className="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8]"
                    onChange={(e) => {
                      setPassword({ ...Password, old: e.target.value });
                    }}
                  />
                </div>

                <div className="flex flex-col">
                  <label
                    htmlFor="NewPassword"
                    className="block mb-2 text-md text-gray-700 font-medium dark:text-white"
                  >
                    New Password
                    {Errors.new && (
                      <span className="text-red-600 ml-2 text-xs mt-1">
                        {Errors.new}*
                      </span>
                    )}
                  </label>
                  <input
                    type="text"
                    value={Password.new}
                    name="NewPassword"
                    id="NewPassword"
                    className={`py-2 px-3 block w-full border-gray-200 rounded-lg text-sm  ${
                      Errors.new
                        ? "border-red-500"
                        : "focus:border-[#A16AE8] focus:ring-[#A16AE8]"
                    }`}
                    onChange={(e) => {
                      setPassword({ ...Password, new: e.target.value });
                    }}
                  />
                </div>

                <div className="flex flex-col">
                  <label
                    htmlFor="ReTypePassword"
                    className="block mb-2 text-md text-gray-700 font-medium dark:text-white"
                  >
                    Re-Type Password
                    {Errors.retype && (
                      <span className="text-red-600 ml-2 text-xs mt-1">
                        {Errors.retype}*
                      </span>
                    )}
                  </label>
                  <input
                    type="text"
                    name="ReTypePassword"
                    value={Password.retype}
                    id="ReTypePassword"
                    className={`py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] ${
                      Errors.retype
                        ? "border-red-500"
                        : "focus:border-[#A16AE8] focus:ring-[#A16AE8]"
                    }`}
                    onChange={(e) => {
                      setPassword({ ...Password, retype: e.target.value });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-end w-full">
            {Feedback && (
              <div className="flex text-green-600 font-semibold">
                {Feedback}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  fill="currentColor"
                  className="bi bi-check-all"
                  viewBox="0 0 16 16"
                >
                  <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486z" />
                </svg>
              </div>
            )}

            {isLoading ? (
              <Button isLoading disabled color="secondary">
                Updating
              </Button>
            ) : (
              <Button
                isDisabled={
                  Password.old === Password.new ||
                  Password.new !== Password.retype ||
                  !Password.new ||
                  !Password.old
                }
                color="secondary"
                onPress={handleUpdatepassword}
              >
                Update Password
              </Button>
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  );
}
