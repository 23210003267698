import { useEffect, useState } from "react";
import RequestStatusTab from "./RequestCanvasComponents/RequestStatus";

export default function RequestTeacherOffCanvas({ wholeSchedule }) {
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    require("preline/preline");
  }, [isClicked, wholeSchedule]);

  useEffect(() => {
    if (window.HSStaticMethods) {
      window.HSStaticMethods.autoInit();
    }
  }, [isClicked, wholeSchedule]);

  return (
    <div>
      <button
  type="button"
  className="m-1 ms-0 py-3 px-4 text-black inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-[#FFDE59] hover:bg-[#efcf4c] focus:outline-none focus:bg-[#FFDE59] disabled:opacity-50 disabled:pointer-events-none relative transition-transform duration-300 transform hover:scale-105"
  aria-haspopup="dialog"
  aria-expanded="false"
  aria-controls="hs-offcanvas-body-scrolling-with-backdrop"
  data-hs-overlay="#hs-offcanvas-body-scrolling-with-backdrop"
  onClick={(e) => {
    e.stopPropagation(); // Ensure this button's click does not interfere with others
    setIsClicked(!isClicked);
  }}
>
  Schedule Adjustments
</button>


      <div
        id="hs-offcanvas-body-scrolling-with-backdrop"
        class="hs-overlay font-poppins bg-white [--body-scroll:true] hs-overlay-open:translate-x-0 hidden translate-x-full fixed top-0 end-0 transition-all duration-300 transform  max-w-[500px] w-full h-full z-[60]  border-e dark:bg-neutral-800 dark:border-neutral-700"
        role="dialog"
        tabindex="-1"
        aria-labelledby="hs-offcanvas-body-scrolling-with-backdrop-label"
      >
        <div className="flex flex-col w-full h-full">
          <div class="flex justify-between flex-initial items-center py-3 px-4 border-b dark:border-neutral-700">
            <h3
              id="hs-offcanvas-body-scrolling-with-backdrop-label"
              class="font-bold text-gray-800 dark:text-white"
            >
              Schedule Adjustment Requests
            </h3>
            <button
              type="button"
              class="size-8 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700 dark:hover:bg-neutral-600 dark:text-neutral-400 dark:focus:bg-neutral-600"
              aria-label="Close"
              data-hs-overlay="#hs-offcanvas-body-scrolling-with-backdrop"
            >
              <span class="sr-only">Close</span>
              <svg
                class="shrink-0 size-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M18 6 6 18"></path>
                <path d="m6 6 12 12"></path>
              </svg>
            </button>
          </div>
          <div class=" flex-1 flex w-full h-full">
            <RequestStatusTab sched={wholeSchedule} />
          </div>
        </div>
      </div>
    </div>
  );
}
