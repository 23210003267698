import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Switch,
  cn,
  Image,
  useDisclosure,
  Spinner,
} from "@nextui-org/react";

import axios from "axios";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import Error from "../../../../../Components/ErrorIndicator";
import { id, storage } from "../../../../../utils/AppwriteConfig/Config";

export default function Edit_ContainerItem({ Item, Container }) {
  const { isOpen, onOpen, onClose, onOpenChange } = useDisclosure();
  const { server, user } = useAuth();

  const [isLoading, setisLoading] = useState(false);
  const [isIMGLoading, setisIMGLoading] = useState(false);
  const [isChangeIMG, setChangeIMG] = useState(false);
  const [MainError, setMainError] = useState();

  const [validationErrors, setValidationErrors] = useState({});

  const [selectedImage, setSelectedImage] = useState(null);
  const [imgurl, setimgurl] = useState();
  const [changes, setChanges] = useState({
    id: Container.$id,
    containername: Container.name,
    creator: user.$id,
    creatorname: user.name,
    itemTitle: "",
    itemContent: "",
    itemPicture: "",
  });

  //Input Item data to the changes
  useEffect(() => {
    setChanges({ ...changes, ...Item });
  }, [Item]);

  //Handles Changing of file
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const fileType = file.type;
      if (fileType === "image/jpeg" || fileType === "image/png") {
        setSelectedImage(file);
        setChanges({ ...changes, itemPicture: "added" });
      } else {
        // Handle invalid file type
        setMainError("Jpeg or PNG file only");
      }
    }
  };

  useEffect(() => {
    const loadImage = async () => {
      try {
        setisIMGLoading(true);
        if (Item.itemPicture) {
          // Load image from storage using data.fileid
          const result = await storage.getFilePreview(
            "66024b7d93a9464cacb6",
            Item.itemPicture
          );
          setimgurl(result);
        }
      } catch (error) {
        setMainError(error.message);
      } finally {
        setisIMGLoading(false);
      }
    };

    loadImage();
  }, [Item.itemPicture]);

  const validateInputs = () => {
    const errors = {};

    switch (Item.type) {
      case "Whole":
        if (!changes.itemPicture) {
          errors.itemPicture = "Picture is required";
        }
        if (changes.container && !changes.container.trim()) {
          errors.container = "container is required";
        }
        break;
      case "Picture":
        if (!changes.itemPicture) {
          errors.itemPicture = "Picture is required";
        }
        if (changes.container && !changes.container.trim()) {
          errors.container = "container is required";
        }
        if (!changes.itemTitle.trim()) {
          errors.itemTitle = "Title is required";
        }
        break;
      case "Card":
        if (!changes.itemPicture) {
          errors.itemPicture = "Picture is required";
        }
        if (changes.container && !changes.container.trim()) {
          errors.container = "container is required";
        }
        if (!changes.itemTitle.trim()) {
          errors.itemTitle = "Title is required";
        }

        if (!changes.itemContent.trim()) {
          errors.itemContent = "Content is required";
        }
        break;
      default:
        break;
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    validateInputs();
  }, [changes]);

  const createItem = async () => {
    try {
      if (!validateInputs()) return;

      setisLoading(true);
      let data;
      if (selectedImage) {
        var deleteimage = await storage.deleteFile(
          process.env.REACT_APP_MEDIA,
          Item.itemPicture,
          selectedImage
        );
        var uploadimage = await storage.createFile(
          process.env.REACT_APP_MEDIA,
          id,
          selectedImage
        );
     
        data = {
          ...changes,
          itemPicture: uploadimage.$id,
        };
      } else {
    
        data = {
          ...changes,
        };
      }

      const additem = await axios.post(
        `${server}/v1/home/container/item/edit`,
        data
      );
    } catch (e) {
      setMainError(e.message);
    } finally {
      onClose();
      setisLoading(false);
    }
  };

  return (
    <>
      <Button id="edititembtn" onPress={onOpen} className="bg-[#ffde59] hidden">
        edit Item
      </Button>
      {MainError && <Error errorMessage={MainError} reset={setMainError} />}
      <Modal
        size="4xl"
        isOpen={isOpen}
        onClose={() => {
          setSelectedImage();
          setimgurl();
          setChangeIMG(false);
          setChanges({
            container: Item.$id,
            itemTitle: "",
            itemContent: "",
            itemPicture: "",
          });
          onClose();
        }}
        className="max-h-[500px]"
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Add Item
              </ModalHeader>
              <ModalBody className=" overflow-auto flex md:flex-row flex-col">
                <div className="px-4 space-y-2 my-2 min-w-[300px]">
                  <div className="m-1">
                    {/* Title */}
                    {Container &&
                      (Container.type === "Picture" ||
                        Container.type === "Card") && (
                        <div>
                          <label
                            htmlFor="Title"
                            className="block mb-2 text-md text-gray-700 font-medium dark:text-white"
                          >
                            Title <span className="text-red-500">*</span>
                          </label>

                          <input
                            defaultValue={changes.itemTitle}
                            type="text"
                            name="Title"
                            id="Title"
                            className={`py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
                              validationErrors.itemTitle ? "border-red-500" : ""
                            }`}
                            required
                            onChange={(e) =>
                              setChanges({
                                ...changes,
                                itemTitle: e.target.value,
                              })
                            }
                          />
                          {validationErrors.itemTitle && (
                            <p className="text-red-500 text-xs mt-1">
                              {validationErrors.itemTitle}
                            </p>
                          )}
                        </div>
                      )}
                  </div>
                  {Container && Container.type === "Card" && (
                    <div className="m-1">
                      {/* Content */}
                      <div>
                        <label
                          htmlFor="Content"
                          className="block mb-2 text-md text-gray-700 font-medium dark:text-white"
                        >
                          Content <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          defaultValue={changes.itemContent}
                          name="Content"
                          id="Content"
                          className={`py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
                            validationErrors.itemContent ? "border-red-500" : ""
                          }`}
                          required
                          onChange={(e) =>
                            setChanges({
                              ...changes,
                              itemContent: e.target.value,
                            })
                          }
                        />
                        {validationErrors.itemContent && (
                          <p className="text-red-500 text-xs mt-1">
                            {validationErrors.itemContent}
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="flex-1 space-x-1 flex sm:flex-row flex-col">
                    <div className="flex-1">
                      <label
                        htmlFor="Type"
                        className="block mb-2 text-md text-gray-700 font-medium dark:text-white"
                      >
                        Container
                      </label>
                      <div className="p-2 bg-slate-200 rounded-lg">
                        {Container && Container.name}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex-1">
                  {isChangeIMG ? (
                    <label className="block">
                      <input
                        type="file"
                        accept=".jpg, .jpeg, .png"
                        onChange={handleFileChange}
                        className="block w-full text-sm bg-default-100 p-2 rounded-lg text-black file:me-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-secondary-400 file:text-white hover:file:bg-secondary-300 file:disabled:opacity-50 file:disabled:pointer-events-none dark:file:bg-blue-500 dark:hover:file:bg-blue-400"
                      />
                    </label>
                  ) : (
                    <Button
                      color="warning"
                      className="m-1"
                      onPress={() => {
                        setChangeIMG(true);
                      }}
                    >
                      Change Image
                    </Button>
                  )}

                  {selectedImage ? (
                    <Image
                      alt="NextUI hero Image"
                      src={URL.createObjectURL(selectedImage)}
                      fallbackSrc={require("../../../../../Img/BSA.jpg")}
                    />
                  ) : (
                    <div className="relative">
                      {!isIMGLoading && imgurl ? (
                        <Image
                          alt="NextUI hero Image"
                          className="object-cover"
                          src={imgurl}
                          fallbackSrc={require("../../../../../Img/BSA.jpg")}
                        />
                      ) : (
                        <div className="flex justify-center">
                          <Image
                            alt="NextUI hero Image"
                            className="object-cover size-64"
                            src={require("../../../../../Img/BSA.jpg")}
                            fallbackSrc={require("../../../../../Img/BSA.jpg")}
                          />
                          <div
                            className={`absolute top-0 z-10 bg-slate-500/60 text-white w-full h-full flex justify-center items-center`}
                          >
                            <Spinner
                              size="lg"
                              itemContent="Loading..."
                              className=" text-white"
                            >
                              Loading...
                            </Spinner>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Cancel
                </Button>

                {isLoading ? (
                  <Button isLoading disabled color="secondary">
                    Uploading
                  </Button>
                ) : (
                  <Button
                    color="secondary"
                    isDisabled={
                      Item.itemTitle === changes.itemTitle &&
                      Item.itemContent === changes.itemContent &&
                      Item.itemPicture === changes.itemPicture
                    }
                    onPress={createItem}
                  >
                    Save
                  </Button>
                )}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
