import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Spinner
} from "@nextui-org/react";
import { useState } from "react";
import { useAuth } from "../../../../utils/AppwriteConfig/AuthContext";
import axios from "axios";

export default function FinishAcademicSchedule({
  status,
  CurrentAcademicYear
}) {
  const { isOpen, onOpen, onClose, onOpenChange } = useDisclosure();
  const [academicYear, setAcademicYear] = useState("");

  const { SchedulingServer } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [MainError, setMainError] = useState("");

  const [error, setError] = useState("");
  // Function to validate the academic year format (YYYY-YYYY)
  const validateAcademicYear = (value) => {
    const regex = /^\d{4}-\d{4}$/;
    const [startYear, endYear] = value.split("-").map(Number);

    // Validate format and logical order of years
    if (!regex.test(value) || endYear - startYear !== 1) {
      setError("Please enter a valid academic year (e.g., 2023-2024).");
    } else {
      setError("");
    }
  };
  const handleChange = (e) => {
    const value = e.target.value;
    setAcademicYear(value);
    validateAcademicYear(value);
  };

  const finalizeSchedule = async () => {
    try {
      setIsLoading(true);
      const sectionResponse = await axios.post(
        `${SchedulingServer}/schedule/complete`
      );
    } catch (error) {
      setMainError(
        error?.response?.data?.error ? error.response.data.error : error.message
      );
    } finally {
      setIsLoading(false);
      setAcademicYear("");
      onClose();
    }
  };
  return (
    <>
      <Button
        onPress={onOpen}
        isDisabled={!status?.hasAllDeptScheduled || false}
        color="secondary"
        className=" flex-initial"
      >
        {" "}
        Archive{" "}
      </Button>
      <Modal
        className=" font-poppins"
        isOpen={isOpen}
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex pb-1 flex-col gap-1 font-bold cursor-default text-xl ">
                Archive Schedule
              </ModalHeader>
              <ModalBody>
                <div className=" text-justify cursor-default mt-2">
                  {" "}
                  <p>
                    This {CurrentAcademicYear} Schedule will be archived,
                    removing it from active view while keeping it accessible for
                    future reference. <br />
                    <div className="p-3 bg-warning-100 rounded-xl mt-5 text-[12px] text-warning-600 cursor-default">
                      {" "}
                      Warning: This action deactivates and archives the
                      schedule.
                    </div>
                  </p>{" "}
                  <br />
                  <p>
                    Enter the <b>current academic year</b> to continue.
                  </p>
                </div>
                <div className=" w-full">
                  <div className="w-full flex gap-2  items-center">
                    <input
                      type="text"
                      id="academicYear"
                      value={academicYear}
                      placeholder="YYYY-YYYY (e.g., 2023-2024)"
                      onChange={handleChange}
                      className={`w-full p-2  rounded-xl flex-1 bg-[#f4f4f5] text-[15px] py-3 ${
                        error ? "border-red-500 border" : "border-none"
                      }`}
                    />
                  </div>
                  {error && (
                    <p className="text-red-500 text-sm mt-1">{error}</p>
                  )}
                  {/* <span className=" flex-initial text-sm max-w-[85px] ">
                              Academic year:
                            </span>
                            <span className="flex-1 bg-[#f4f4f5] rounded-xl flex justify-center items-center">
                              {GetCurrentAcademicYear()}
                            </span> */}
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                <Button
                  color="secondary"
                  isDisabled={academicYear !== CurrentAcademicYear}
                  onPress={() => {
                    finalizeSchedule();
                  }}
                >
                  {isLoading ? (
                    <div className=" flex ">
                      <Spinner size="sm" className=" mr-1" />
                      Loading...
                    </div>
                  ) : (
                    "Continue"
                  )}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
