import {
  Button,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell
} from "@nextui-org/react";
import {
  isDateFinished,
  isToday
} from "../../../../utils/helpers/stringformat";

export default function TeacherSchedule({ schedule }) {
  console.log("g", schedule);
  // Convert the schedule object into an array for easier rendering
  const scheduleArray = schedule
    ? Object.entries(schedule).map(([time, subjects]) => ({
        time,
        subjects: subjects || [] // Ensure subjects is always an array
      }))
    : [];

  // Print handler function
  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="print-area max-w-full w-full overflow-auto">
      {/* <Button onClick={handlePrint} className=" printHide">
        Print
      </Button> */}
      <Table
        removeWrapper
        className="px-4 py-2"
        aria-label="Teacher Schedule Table"
      >
        <TableHeader>
          <TableColumn className="font-semibold text-black text-center">
            TIME
          </TableColumn>
          <TableColumn className="text-center">MONDAY</TableColumn>
          <TableColumn className="text-center">TUESDAY</TableColumn>
          <TableColumn className="text-center">WEDNESDAY</TableColumn>
          <TableColumn className="text-center">THURSDAY</TableColumn>
          <TableColumn className="text-center">FRIDAY</TableColumn>
        </TableHeader>
        <TableBody>
          {scheduleArray.map((sched, index) => (
            <TableRow key={index}>
              <TableCell className="border-r-1 border-b text-center min-w-[120px] text-sm font-semibold">
                {sched.time}
              </TableCell>
              {["Mon", "Tue", "Wed", "Thu", "Fri"].map((day) => {
                const subjectForDay = sched.subjects.find(
                  (subj) => subj.day === day
                );
                return (
                  <TableCell
                    key={day}
                    className={`${
                      subjectForDay &&
                      (subjectForDay.type === "break" ||
                        subjectForDay.type === "lunch")
                        ? "bg-secondary-100"
                        : "border-r-1"
                    } min-w-[150px] border-b`}
                  >
                    {subjectForDay ? (
                      <>
                        {subjectForDay.subjectName ? (
                          <div className="rounded-md p-2 gap-2 flex flex-col">
                            <span className="font-medium text-center">
                              {subjectForDay.subjectName}
                            </span>
                            <div className="gap-2 flex w-full bg-slate-200 p-1 justify-center items-center rounded-md">
                              {subjectForDay.courseName} |{" "}
                              {subjectForDay.section}
                            </div>
                            {subjectForDay?.substitute &&
                              !isDateFinished(subjectForDay.subDate) && (
                                <div className="gap-2  flex flex-col w-[200px] bg-warning-50 text-warning-600 p-1 justify-center items-center rounded-md">
                                  <b>
                                    {" "}
                                    {
                                      subjectForDay.substitute.userCredentials
                                        .Username
                                    }
                                  </b>{" "}
                                  <div>
                                    {" "}
                                    will Sub{" "}
                                    {isToday(subjectForDay.subDate)
                                      ? "Today"
                                      : `on ${subjectForDay.subDate}`}
                                  </div>
                                </div>
                              )}
                          </div>
                        ) : (
                          <div className="rounded-md p-2 flex flex-col">
                            <span className="font-medium text-center">
                              {subjectForDay.type}
                            </span>
                          </div>
                        )}
                      </>
                    ) : (
                      <span>No Class</span>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
