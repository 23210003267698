import { Button, Card, CardBody, Skeleton, Spinner } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import axios from "axios";
import { useAuth } from "../../../../utils/AppwriteConfig/AuthContext";
import { storage } from "../../../../utils/AppwriteConfig/Config";
import { birthdateformat } from "../../../../utils/helpers/stringformat";
import Delete from "../../../../Components/modals/DeleteDocument";
import Error from "../../../../Components/ErrorIndicator";
import DocumentView from "./DocumentView";
import useRealtimeSubscription from "../../../../utils/AppwriteConfig/RealtimeServices";

export default function DocumentRender({
  currentUser,
  Cdocument,
  refresher,
  isRefresh
}) {
  const [isLoading, setisLoading] = useState(true);
  const [MainError, setMainError] = useState();
  const { DataServer } = useAuth();
  const [isrefresh, setisrefresh] = useState(true);

  const [Documents, setDocuments] = useState([]);
  const [SelectedDocument, setSelectedDocument] = useState({});

  useEffect(() => {
    setSelectedDocument({});
  }, [Cdocument]);

  useEffect(() => {
    setisLoading(true);
    async function fetchUser() {
      try {
        const response = await axios.get(
          `${DataServer}/user/document/${Cdocument.$id}/all`,
          {
            params: { id: currentUser.$id }
          }
        );
       
        setDocuments(response.data.documents);
      } catch (error) {
        // Handle any errors
        console.error(error);
      } finally {
        setisLoading(false);
      }
    }
    if (currentUser) {
     
      fetchUser();
    }
  }, [currentUser, Cdocument, isRefresh]);

  const HandleRealtimeMessages = (changes) => {
 

    if (changes.changetype === "update") {
      //
      const updatedUserIndex = Documents.findIndex(
        (doc) => doc.$id === changes.payload.$id
      );
      if (updatedUserIndex !== -1) {
        const updatedUsers = [...Documents];
        updatedUsers[updatedUserIndex] = changes.payload;
        setDocuments(updatedUsers);
      } else {
        setisrefresh(!isrefresh);
      }
    } else if (changes.changetype === "delete") {
      const filteredUsers = Documents.filter(
        (doc) => doc.$id !== changes.payload.$id
      );
      setDocuments(filteredUsers);
    } else if (changes.changetype === "create") {
      setDocuments((prevDocs) => [...prevDocs, changes.payload]);
    }
  };

  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_DOCUMENT,
    HandleRealtimeMessages
  );

  return (
    <>
      {MainError && <Error errorMessage={MainError} />}
      <div className="flex flex-col flex-1 min-h-64 space-y-2 rounded-md">
        <DocumentView
          CurrentDocument={SelectedDocument}
          currenttype={Cdocument}
          ParentReloader={refresher}
        />

        <Skeleton className="rounded-md " isLoaded={!isLoading}>
          <div className=" bg-slate-200 py-2  rounded-lg min-h-60 flex-1 h-full flex flex-col justify-center items-center overflow-auto w-full">
            <div className="p-2 h-full flex-1 flex space-x-2  max-w-[563px] w-full overflow-auto">
              {Documents && Documents.length > 0 ? (
                Documents.map((doc) => (
                  <Card
                    shadow="sm"
                    key={doc.$id}
                    className="min-w-[200px] relative max-h-full group border-1 shadow-md rounded-lg"
                    isPressable
                    onPress={() => setSelectedDocument(doc)}
                  >
                    <CardBody className="">
                      <div className="w-full h-full absolute inset-0">
                        <img
                          className="w-full h-full object-cover transition duration-500 ease-in-out rounded-lg"
                          src={require("../../../../Img/BSA.jpg")}
                          alt="logo"
                        />
                      </div>

                      <div className="absolute text-left bottom-0 left-0 right-0 rounded-lg bg-[#A16AE8]  text-white p-2">
                        {doc.fileTitle}
                      </div>
                    </CardBody>
                  </Card>
                ))
              ) : (
                <div className="absolute w-full h-full flex justify-center items-center   ">
                  No Documents yet
                </div>
              )}
            </div>
          </div>
        </Skeleton>
      </div>
    </>
  );
}
