import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Spinner,
  Switch,
  cn
} from "@nextui-org/react";
import axios from "axios";
import { useState, useEffect } from "react";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import Error from "../../../../../Components/ErrorIndicator";
import DeleteRoomConfirm from "./DeleteRoom";

export default function NewRoom({ refresher, room = {}, selectedReset }) {
  console.log(room);
  const { DataServer, user } = useAuth();
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [MainError, setMainError] = useState();
  const [isNameUnique, setIsNameUnique] = useState(false);
  const [isNameUniqueLoading, setIsNameUniqueLoading] = useState(false);
  const [data, setData] = useState({
    roomName: "",
    capacity: "",
    location: "",
    roomType: "",
    creator: user
  });

  const maxRoomNameChars = 100;
  const maxLocationChars = 1000;
  const minCapacity = 1;
  const maxCapacity = 100;

  // Validation logic
  const validate = () => {
    const newErrors = {};

    if (room && room.roomName === data.roomName) {
    } else {
      if (!data.roomName || !data.roomName.trim()) {
        newErrors.roomName = "Room name is required";
      } else if (data.roomName.length > maxRoomNameChars) {
        newErrors.roomName = `Room name cannot exceed ${maxRoomNameChars} characters`;
      } else if (!isNameUnique) {
        newErrors.roomName = `Room name should be unique`;
      }
    }

    if (
      !data.capacity ||
      isNaN(data.capacity) ||
      data.capacity < minCapacity ||
      data.capacity > maxCapacity
    ) {
      newErrors.capacity = `Capacity must be a number between ${minCapacity} and ${maxCapacity}`;
    }

    if (data?.location?.length > maxLocationChars) {
      newErrors.location = `Location cannot exceed ${maxLocationChars} characters`;
    }

    if (!data.roomType) {
      newErrors.roomType = "Room type is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    validate();
  }, [data, isNameUniqueLoading, room]);

  const handleChange = (field, value) => {
    setData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleSubmit = async () => {
    if (!validate()) return;
    console.log("Datas submitted:", data); // Replace with your submit logic

    if (Object.keys(room).length > 0) {
      //edit
      try {
        setIsLoading(true);
        const NewRoomResponse = await axios.put(
          `${DataServer}/info/room/edit`,
          {
            ...data,
            creator: user
          }
        );
        console.log(NewRoomResponse.data);
      } catch (error) {
        setMainError(error.message);
      } finally {
        setIsLoading(false);
        refresher();
        closeModal();
      }
    } else {
      //save
      try {
        setIsLoading(true);
        const NewRoomResponse = await axios.post(
          `${DataServer}/info/new/room`,
          {
            ...data,
            creator: user
          }
        );
        console.log(NewRoomResponse.data);
      } catch (error) {
        setMainError(error.message);
      } finally {
        setIsLoading(false);
        refresher();
        closeModal();
      }
    }
  };

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      if (data.roomName && data.roomName.trim() !== "") {
        async function NameChecker() {
          try {
            setIsNameUniqueLoading(true);
            const RoomNameResponse = await axios.get(
              `${DataServer}/info/room/name/check`,
              { params: { name: data.roomName } }
            );
            console.log(RoomNameResponse.data.isNameUnique);
            setIsNameUnique(RoomNameResponse.data.isNameUnique);
          } catch (error) {
            setMainError(error.message);
          } finally {
            setIsNameUniqueLoading(false);
          }
        }
        NameChecker();
      }
    }, 500); // 500ms delay for debouncing

    return () => clearTimeout(debounceTimeout); // Cleanup on re-render
  }, [data.roomName]);

  function closeModal() {
    if (MainError) {
      setTimeout(() => {
        setErrors({});
        setData({
          roomName: "",
          capacity: "",
          roomType: "",
          location: ""
        });
        onClose();
      }, 2500); // Delay of 2.5 seconds
    } else {
      // Immediate close if there's no MainError
      setErrors({});
      setData({
        roomName: "",
        capacity: "",
        roomType: "",
        location: ""
      });
      onClose();
    }

    if (Object.keys(room).length > 0) {
      selectedReset({});
    }
  }

  useEffect(() => {
    if (Object.keys(room).length > 0) {
      setData({ ...room, roomType: room.department });
    }
  }, [room]);

  function hasChanges(roomData, originalRoomData) {
    // Check if any key-value pairs are different between the two objects
    return Object.keys(roomData).some(
      (key) => roomData[key] !== originalRoomData[key]
    );
  }

  function reseter() {
    refresher();
    selectedReset({});
    closeModal();
  }

  return (
    <>
      {MainError && <Error errorMessage={MainError} reset={setMainError} />}
      <Button
        onPress={onOpen}
        id="editRoomBtn"
        className=" bg-[#FFDE59]  flex-initial flex ml-1 rounded-lg text-black font-semibold hover:bg-[#fcd84b] overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-door-open"
          viewBox="0 0 16 16"
        >
          <path d="M8.5 10c-.276 0-.5-.448-.5-1s.224-1 .5-1 .5.448.5 1-.224 1-.5 1" />
          <path d="M10.828.122A.5.5 0 0 1 11 .5V1h.5A1.5 1.5 0 0 1 13 2.5V15h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V1.5a.5.5 0 0 1 .43-.495l7-1a.5.5 0 0 1 .398.117M11.5 2H11v13h1V2.5a.5.5 0 0 0-.5-.5M4 1.934V15h6V1.077z" />
        </svg>
        New Room
      </Button>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        isDismissable={false}
        scrollBehavior="inside"
        onClose={closeModal}
        isKeyboardDismissDisabled={true}
        className=" font-poppins"
      >
        <ModalContent>
          <ModalHeader className="flex w-full max-w-full break-words items-center gap-1 text-secondary-700 pb-2">
            <div className=" p-2  w-full ">
              {" "}
              {Object.keys(room).length > 0
                ? `Edit ${room.roomName}`
                : "Add New"}{" "}
              Room{" "}
              {Object.keys(room).length > 0 && (
                <DeleteRoomConfirm data={room} refresher={reseter} />
              )}
            </div>
          </ModalHeader>
          <ModalBody className=" gap-2">
            <div className="">
              <label
                htmlFor="roomName"
                className="block text-sm font-medium mb-2 "
              >
                <div className=" flex  items-center">
                  Room Name
                  {isNameUniqueLoading && (
                    <div className=" mx-2 gap-2 text-secondary-700 flex items-center">
                      <div className=" text-xs">Checking room name</div>
                      <Spinner color="secondary" size="sm" />
                    </div>
                  )}
                </div>
              </label>
              <input
                type="text"
                id="roomName"
                maxLength={maxRoomNameChars}
                className={`py-2 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] dark:bg-slate-900 dark:border-gray-700 dark:focus:ring-gray-600 ${
                  errors.roomName ? "border-danger-600" : "border-slate-200"
                }`}
                value={data.roomName}
                onChange={(e) => handleChange("roomName", e.target.value)}
              />
              {errors.roomName && (
                <p className="text-danger-600 text-sm mt-1">
                  {errors.roomName}
                </p>
              )}
            </div>
            <div>
              {room && Object.keys(room).length > 0 && room.section ? (
                <div className=" flex flex-col">
                  <div className="text-sm font-medium ">
                    {/* Title */}
                    Section Assigned
                  </div>
                  <div className="flex flex-1 text-white text-sm justify-between p-2 py-3 bg-[#A16AE8] rounded-lg">
                    <div>
                      {/* Course */}
                      {room?.section?.course?.courseName} |{" "}
                      {room.section?.sectionName}
                    </div>
                    <div>{/* Section */}</div>
                  </div>
                </div>
              ) : (
                <div className=" flex flex-col">
                  <div className="text-sm font-medium ">
                    {/* Title */}
                    Section Assigned
                  </div>
                  <div className="flex flex-1 text-white text-sm justify-between p-2 py-3 bg-[#A16AE8] rounded-lg">
                    <div>No Section Assigned yet</div>
                  </div>
                </div>
              )}
            </div>
            <div className="">
              <label
                htmlFor="capacity"
                className="block text-sm font-medium mb-2"
              >
                Capacity
              </label>
              <input
                type="number"
                id="capacity"
                value={data.capacity}
                className={`py-2 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] dark:bg-slate-900 dark:border-gray-700 dark:focus:ring-gray-600 ${
                  errors.capacity ? "border-danger-600" : "border-slate-200"
                }`}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value === "" || (value > 0 && value <= 100)) {
                    handleChange("capacity", value);
                  }
                }}
                placeholder="Enter capacity (1–100)"
              />
              {errors.capacity && (
                <p className="text-danger-600 text-sm mt-1">
                  {errors.capacity}
                </p>
              )}
            </div>

            <div className="">
              <label
                htmlFor="roomType"
                className="block text-sm font-medium mb-2"
              >
                Room Type
              </label>
              <select
                id="roomType"
                value={data.roomType || ""}
                onChange={(e) => handleChange("roomType", e.target.value)}
                className={`py-2 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] dark:bg-slate-900 dark:border-gray-700 dark:focus:ring-gray-600 hover:bg-gray-100 hover:border-[#A16AE8] cursor-pointer ${
                  errors.roomType ? "border-danger-600" : "border-slate-200"
                }`}
              >
                <option value="" disabled className="text-gray-400">
                  Select Room Type
                </option>
                <option
                  value="Elem"
                  className="hover:bg-[#A16AE8] hover:text-white"
                >
                  Elem
                </option>
                <option
                  value="Pre"
                  className="hover:bg-[#A16AE8] hover:text-white"
                >
                  Pre
                </option>
                <option
                  value="JHS"
                  className="hover:bg-[#A16AE8] hover:text-white"
                >
                  JHS
                </option>
                <option
                  value="SHS"
                  className="hover:bg-[#A16AE8] hover:text-white"
                >
                  SHS
                </option>
                <option
                  value="College"
                  className="hover:bg-[#A16AE8] hover:text-white"
                >
                  College
                </option>
                <option
                  value="Others"
                  className="hover:bg-[#A16AE8] hover:text-white"
                >
                  Others
                </option>
              </select>
              {errors.roomType && (
                <p className="text-danger-600 text-sm mt-1">
                  {errors.roomType}
                </p>
              )}
            </div>
            {Object.keys(room).length > 0 && (
              <div>
                <Switch
                  isSelected={data.Maintainance}
                  onValueChange={(value) => {
                    setData({ ...data, Maintainance: value });
                  }}
                  classNames={{
                    base: cn(
                      "inline-flex border-1 flex-row-reverse w-full max-w-md bg-content1 hover:bg-content2 items-center",
                      "justify-between cursor-pointer rounded-lg gap-2 p-4 border-2 border-transparent",
                      "data-[selected=true]:border-primary"
                    ),
                    wrapper: "p-0 h-4 overflow-visible",
                    thumb: cn(
                      "w-6 h-6 border-2 shadow-lg",
                      "group-data-[hover=true]:border-primary",
                      //selected
                      "group-data-[selected=true]:ml-6",
                      // pressed
                      "group-data-[pressed=true]:w-7",
                      "group-data-[selected]:group-data-[pressed]:ml-4"
                    )
                  }}
                >
                  <div className="flex flex-col gap-1">
                    <p className="text-medium">Room Maintenance</p>
                    <p className="text-tiny text-default-400">
                      Mark this room as under maintenance and inaccessible at
                      the moment.
                    </p>
                  </div>
                </Switch>
              </div>
            )}

            <div className=" relative">
              <label
                htmlFor="location"
                className="block text-sm font-medium mb-2"
              >
                Location
              </label>
              <textarea
                id="location"
                rows="3"
                maxLength={maxLocationChars}
                className={`py-2 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] dark:bg-slate-900 dark:border-gray-700 dark:focus:ring-gray-600 ${
                  errors.location ? "border-danger-600" : "border-slate-200"
                }`}
                value={data.location}
                onChange={(e) => handleChange("location", e.target.value)}
              />
              <div className="absolute bottom-1 right-3 text-sm text-gray-500">
                {(data && data.location && data?.location?.length) || 0}/
                {maxLocationChars}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" variant="light" onPress={closeModal}>
              Close
            </Button>
            <Button
              isDisabled={
                Object.keys(room).length > 0
                  ? Object.keys(errors).length > 0 ||
                    isLoading ||
                    isNameUniqueLoading ||
                    !hasChanges(room, data)
                  : Object.keys(errors).length > 0 ||
                    isLoading ||
                    isNameUniqueLoading
              }
              color="secondary"
              onPress={handleSubmit}
            >
              {Object.keys(room).length > 0 ? (
                <>
                  {isLoading ? (
                    <div className=" flex items-center gap-1">
                      <Spinner size="sm" />
                      <div>Saving Room</div>
                    </div>
                  ) : (
                    "Save Room"
                  )}
                </>
              ) : (
                <>
                  {isLoading ? (
                    <div className=" flex items-center gap-1">
                      <Spinner size="sm" />
                      <div>Adding Room</div>
                    </div>
                  ) : (
                    "Add Room"
                  )}
                </>
              )}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
