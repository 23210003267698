import { Image } from "@nextui-org/react";

export default function Failed({ users = [] }) {
  return (
    <div>
      <div className="flex-1 h-full p-4 overflow-auto">
        <div className="flex flex-col flex-1 justify-center items-center h-full ">
          <Image
            isZoomed
            width={240}
            className=" object-cover bg-cover w-full h-full"
            alt="NextUI Fruit Image with Zoom"
            src={require("../../../../../../Img/sad.jpg")}
          />
          <span className="text-xl text-center">
            Do you want to mark these {users.length}{" "}
            {users.length > 1 ? "Students" : "Student"} as not meeting course
            requirements
          </span>
        </div>
      </div>
    </div>
  );
}
