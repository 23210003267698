import { useEffect, useState } from "react";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import axios from "axios";
import { input, Skeleton } from "@nextui-org/react";
import Edit from "../../../../../Components/modals/Edit";

export default function SubjectTime({ Valid, Getdata, pushData, EditData }) {
  const { DataServer } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [isCourseLoading, setIsCourseLoading] = useState(false);
  const [courses, setCourses] = useState([]);
  const [inputGroups, setInputGroups] = useState([]);
  const [errors, setErrors] = useState({});

  const [isValid, setIsValid] = useState(false);
  const [data, setData] = useState({});

  // Load preline script and auto initialize HSStaticMethods on loading state change
  useEffect(() => {
    require("preline/preline");
  }, [isLoading]);

  useEffect(() => {
    if (window.HSStaticMethods) {
      window.HSStaticMethods.autoInit();
    }
  }, [isLoading]);

  // Fetch courses from the server on component mount
  useEffect(() => {
    async function GetCourses() {
      try {
        setIsLoading(true);
        setIsCourseLoading(true);
        const getCourse = await axios.get(`${DataServer}/course/all`);
        setCourses(getCourse.data.documents);

      } catch (error) {
        console.error(error);
      } finally {
        setIsCourseLoading(false);
        setIsLoading(false);
      }
    }

    // if (EditData?.totalSpecificTime.length > 0) {
    GetCourses();
  }, [DataServer, EditData]);

  // Add a new input group with default subject time per week
  const addInputGroup = () => {
    setInputGroups([
      ...inputGroups,
      {
        id: Date.now(),
        name: "defaultSubjectTime",
        courseId: "",
        course: "",
        defaultSubjectTime: "",
        type: EditData ? "newSubjectEdit" : "newSubject"
      }
    ]);
  };

  useEffect(() => {
  
    if (EditData) {
      setData({
        totalSubjectTimePerWeek: EditData.totalSubjectTimePerWeek,
        subjectType: EditData.type
      });

      if (EditData.totalSpecificTime) {
        let specificTimes = EditData.totalSpecificTime.map((time, index) => ({
          id: time.$id,
          course: time.courseName,
          defaultSubjectTime: time.totalMinutesPerWeek,
          courseId: time.courseId
        }));
        setInputGroups(specificTimes);
      }
    }
  }, [EditData]);

  // Handle changes in input fields
  const handleInputChange = (id, field, value) => {
    if (field === "course") {
      const selectedCourse = courses.find(
        (course) => course.courseName === value
      );
      if (selectedCourse) {
        setInputGroups(
          inputGroups.map((group) =>
            group.id === id
              ? { ...group, courseId: selectedCourse.$id, [field]: value }
              : group
          )
        );
      }
    } else {
      setInputGroups(
        inputGroups.map((group) =>
          group.id === id ? { ...group, [field]: value } : group
        )
      );
    }
  };

  // Handle deletion of an input group
  const handleDeleteGroup = (id) => {
    setInputGroups(inputGroups.filter((group) => group.id !== id));
  };

  // Validate the input fields and return boolean status
  const validate = () => {
    let errors = {};
    if (!data.totalSubjectTimePerWeek) {
      errors.totalSubjectTimePerWeek = "Subject Time Per Week is required";
    }

    if (!data.subjectType) {
      errors.subjectType = "Please select a subject Type";
    }

    inputGroups.forEach((group) => {
      if (!group.defaultSubjectTime) {
        errors[`defaultSubjectTime-${group.id}`] = "Total Minutes is required";
      }
      if (!group.course) {
        errors[`courseSelect-${group.id}`] = "Course selection is required";
      }
    });

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Get available courses excluding those already selected in other input groups
  function getAvailableCourses(selectedCourses, currentGroupId) {
    return courses.filter(
      (course) =>
        !selectedCourses.includes(course.courseName) ||
        course.courseName ===
          inputGroups.find((group) => group.id === currentGroupId)?.course
    );
  }

  // Push data to parent component whenever data or input groups change
  useEffect(() => {
    pushData({
      ...Getdata,
      ...data,
      specificSubjectTime: inputGroups
    });
  }, [data, inputGroups]);

  // Validate input fields whenever data or input groups change
  useEffect(() => {
    Valid(validate());
  }, [data, inputGroups]);

  return (
    <div className="w-full flex p-1 flex-col max-h-[350px] overflow-auto">
      <div className="flex flex-col w-full flex-initial space-y-4">
        <span>Default</span>
        <div className="w-full space-y-4 px-2">
          <div className="flex flex-col sm:flex-row gap-2">
            <div className=" flex flex-col">
              <label
                htmlFor="subjectname"
                className="block text-sm mb-2 dark:text-white font-medium"
              >
                Subject Time per Week
                {errors.totalSubjectTimePerWeek && (
                  <span className="text-red-500 text-sm mt-1">*</span>
                )}
              </label>
              <input
                type="text"
                name="subjectname"
                placeholder="Total Minutes"
                id="subjectname"
                className={`${
                  errors.totalSubjectTimePerWeek
                    ? "border-red-500"
                    : "border-slate-200"
                } py-2 ring-0 border-gray-200 placeholder:text-gray-500 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600`}
                value={data.totalSubjectTimePerWeek || ""}
                onChange={(e) =>
                  setData({ ...data, totalSubjectTimePerWeek: e.target.value })
                }
                required
              />
            </div>
            <div className="flex-initial">
              <div className="flex-1">
                <label
                  htmlFor="Type"
                  className="block text-sm mb-2 dark:text-white font-medium"
                >
                  Subject Type
                  {errors.subjectType && (
                    <span className="text-red-500 text-sm mt-1">*</span>
                  )}
                </label>
                <select
                  className={`${
                    errors.subjectType ? "border-red-500" : "border-slate-200"
                  } pe-9 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600`}
                  value={data.subjectType || ""}
                  onChange={(e) =>
                    setData({ ...data, subjectType: e.target.value })
                  }
                >
                  <option value="" disabled hidden>
                    Select Subject Type
                  </option>
                  <option value="Major">Major Subject</option>
                  <option value="Minor">Minor Subject</option>
                  <optgroup
                    label="SHS Subjects"
                    className=" text-xs font-normal"
                  >
                    <option value="Core">Core Subject</option>
                    <option value="Applied">Applied Subject</option>
                    <option value="Specialized">Specialized Subject</option>
                  </optgroup>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="border-t-2 text-sm border-slate-200 mt-4 mx-2 p-2">
          {isCourseLoading
            ? "Loading all specific subject time"
            : "Add or edit specified subject Time for a specific course"}
        </div>
      </div>
      <div className=" flex flex-col gap-1">
        {inputGroups.map((group) => (
          <Skeleton isLoaded={!isCourseLoading} className="rounded-lg">
            <div key={group.id}>
              <div className="relative flex w-full border-1 shadow-md p-1 rounded-xl ">
                <div className="flex initial">
                  <div className="flex flex-1items-center text-gray-500">
                    <label
                      htmlFor={`courseSelect-${group.id}`}
                      className="sr-only"
                    >
                      Course
                    </label>
                    <select
                      disabled={isCourseLoading}
                      id={`courseSelect-${group.id}`}
                      name={`courseSelect-${group.id}`}
                      className={`block w-[150px] max-h-[200px]  overflow-auto focus:ring-secondary text-black focus:border-secondary dark:text-neutral-500 dark:bg-neutral-800 ${
                        errors[`courseSelect-${group.id}`]
                          ? "border-red-500"
                          : "border-transparent"
                      }`}
                      value={group.course}
                      onChange={(e) =>
                        handleInputChange(group.id, "course", e.target.value)
                      }
                    >
                      <option value="">Select Course</option>
                      {getAvailableCourses(
                        inputGroups.map((g) => g.course),
                        group.id
                      ).map((course) => (
                        <option key={course.$id} value={course.courseName}>
                          {course.courseName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className=" flex-1">
                  <input
                    disabled={isCourseLoading}
                    type="text"
                    placeholder="Total Minutes"
                    id={`defaultSubjectTime-${group.id}`}
                    className={` w-full h-full block flex-initial border-none text-sm focus:border-secondary focus:ring-secondary disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600 ${
                      errors[`defaultSubjectTime-${group.id}`]
                        ? "border-red-500"
                        : ""
                    }`}
                    value={group.defaultSubjectTime}
                    onChange={(e) =>
                      handleInputChange(
                        group.id,
                        "defaultSubjectTime",
                        e.target.value
                      )
                    }
                    onKeyPress={(e) => {
                      // Allow only digits and backspace
                      const isValidInput =
                        /^\d$/.test(e.key) || e.key === "Backspace";
                      // Allow input if less than 4 digits
                      const isWithinMaxLength = e.target.value.length < 4;
                      if (!isValidInput || !isWithinMaxLength) {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
                <button
                  type="button"
                  onClick={() => handleDeleteGroup(group.id)}
                  className="absolute right-0 top-0 h-full  px-2 py-1 text-red-500  rounded-lg"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-x-circle-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                  </svg>
                </button>
              </div>
            </div>
          </Skeleton>
        ))}
      </div>

      <button
        type="button"
        disabled={inputGroups.length >= courses.length}
        onClick={addInputGroup}
        className="mt-4 py-2 px-4 bg-secondary text-sm text-white rounded-lg"
      >
        Add Specific Subject
      </button>
    </div>
  );
}
