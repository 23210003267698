import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  TimeInput,
  Select,
  SelectItem,
  DatePicker
} from "@nextui-org/react";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import { useEffect, useState } from "react";
import {
  getCurrentDateTime,
  getFormattedCurrentDateTime
} from "../../../../../utils/helpers/stringformat";
import { Time } from "@internationalized/date";
import axios from "axios";
import Error from "../../../../../Components/ErrorIndicator";
import { parseDate, getLocalTimeZone } from "@internationalized/date";
import { useDateFormatter } from "@react-aria/i18n";

export default function NewRequest({ schedules, refresher }) {
  const { DataServer, user } = useAuth();
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [MainError, setMainError] = useState("");

  const [data, setData] = useState({
    requestMessage: "Requesting a Full-time Adjustment",
    requestReason: "",
    day: "",
    date: "",
    schedules: [],
    statusTime: "",
    startHour: 0,
    startMinute: 0,
    endHour: 0,
    endMinute: 0,
    currentTeacher: user,
    type: "Full-time Adjustment"
  });
  const [value, setValue] = useState(null);
  let formatter = useDateFormatter({ dateStyle: "full" });

  const handleChange = (field, value) => {
    setData((prev) => ({ ...prev, [field]: value }));
    setErrors((prev) => ({ ...prev, [field]: "" })); // Clear error on change
  };

  const validateForm = () => {
    const newErrors = {};

    // Validate `type`
    if (!data.type) newErrors.type = "Request type is required.";

    // Validate `requestReason`
    if (!data.requestReason || data.requestReason.trim() === "") {
      newErrors.requestReason = "Reason is required.";
    }

    if (data.type === "Full-time Adjustment") {
      // Validate time inputs for "Full-time Adjustment"
      if (data.startHour === null || data.startHour === 0) {
        newErrors.startHour = "Start Hour time is required.";
      } else if (data.startMinute === null) {
        newErrors.startMinute = "Start Minute time is required.";
      }

      if (data.endHour === null || data.endHour === 0) {
        newErrors.endHour = "End Hour time is required.";
      } else if (data.endMinute === null) {
        newErrors.endMinute = "End Minute time is required.";
      }

      if (data.endHour < data.startHour) {
        newErrors.time = "Input valid time span.";
      }
    }
    if (!data.day || data.day === "Sun" || data.day === "Sat") {
      newErrors.day = "Day is required.";
      console.log("GG");
    }

    if (data.type === "Temporary Leave" && !data.date) {
      newErrors.day = "Date is required.";
    }

    return newErrors;
  };

  useEffect(() => {
    console.log("CHECKDE");
    const validationErrors = validateForm();
    setErrors(validationErrors);
  }, [data, data.type]);

  const handleSubmit = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    const schedulesSpecific = Object.values(schedules)
      .flat() // Combine all arrays into one
      .filter((schedule) => schedule.day === data.day);

    const dataToGive =
      data.type === "Full-time Adjustment"
        ? {
            type: data.type,
            requestMessage: data.requestMessage,
            startHour: data.startHour,
            startMinute: data.startMinute,
            endHour: data.endHour,
            endMinute: data.endMinute,
            day: data.day,
            requestReason: data.requestReason,
            currentTeacher: data.currentTeacher
          }
        : {
            type: data.type,
            requestMessage: data.requestMessage,
            requestReason: data.requestReason,
            day: data.day,
            schedules: schedulesSpecific,
            Date: data.date,
            currentTeacher: data.currentTeacher
          };

    console.log("", schedulesSpecific);
    const request = {
      ...dataToGive,
      statusTime: getFormattedCurrentDateTime()
    };

    console.log(request);

    try {
      setIsLoading(true);
      console.log(request);

      const newRequestResponse = await axios.post(
        `${DataServer}/info/request/teacher`,
        request
      );
      console.log(newRequestResponse);
    } catch (error) {
      setMainError("Failed to submit request. Please try again.");
    } finally {
      setIsLoading(false);
      refresher();
      closeModal();
    }
  };

  //For RequestMessage
  useEffect(() => {
    setData({ ...data, requestMessage: `Requesting a ${data.type}` });
  }, [data.type]);

  function closeModal() {
    if (MainError) {
      setTimeout(() => {
        setErrors({});
        setData({
          requestMessage: "Requesting a Full-time Adjustment",
          requestReason: "",
          day: "Mon",
          schedules: [],
          statusTime: "",
          startHour: 0,
          startMinute: 0,
          endHour: 0,
          endMinute: 0,
          currentTeacher: user,
          type: "Full-time Adjustment"
        });
        onClose();
      }, 2500); // Delay of 2.5 seconds
    } else {
      // Immediate close if there's no MainError
      setErrors({});
      setData({
        requestMessage: "Requesting a Full-time Adjustment",
        requestReason: "",
        day: "Mon",
        schedules: [],
        statusTime: "",
        startHour: 0,
        startMinute: 0,
        endHour: 0,
        endMinute: 0,
        currentTeacher: user,
        type: "Full-time Adjustment"
      });
      onClose();
    }
  }

  useEffect(() => {
    console.log(data.date);
  }, [data.date]);

  return (
    <>
      {MainError && <Error errorMessage={MainError} reset={setMainError} />}
      <Button
        aria-haspopup="dialog"
        aria-expanded="false"
        aria-controls="hs-offcanvas-body-scrolling-with-backdrop"
        data-hs-overlay="#hs-offcanvas-body-scrolling-with-backdrop"
        className="w-full bg-[#FFDE59] font-semibold"
        onPress={onOpen}
      >
        Add New Request
      </Button>
      <Modal
        scrollBehavior="inside"
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        onClose={closeModal}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>New Request</ModalHeader>
              <ModalBody className=" gap-2">
                {/* Request Type */}
                <div className="">
                  <label className="block text-sm font-medium mb-2">
                    Request Type
                  </label>
                  <select
                    value={data.type}
                    onChange={(e) => handleChange("type", e.target.value)}
                    className={`py-2 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] dark:bg-slate-900 dark:border-gray-700 dark:focus:ring-gray-600 ${
                      errors.type ? "border-danger-600" : "border-slate-200"
                    }`}
                  >
                    <option disabled value="">
                      Select type
                    </option>
                    <option value="Full-time Adjustment">
                      Full-time Adjustment
                    </option>
                    <option value="Temporary Leave">Temporary Leave</option>
                  </select>
                  {errors.type && (
                    <p className="text-danger-600 text-sm mt-1">
                      {errors.type}
                    </p>
                  )}
                </div>
                {data.type === "Temporary Leave" && (
                  <div className=" flex w-full ">
                    <DatePicker
                      className="w-full"
                      label="Date"
                      value={value}
                      onChange={(value) => {
                        setValue(value);
                        setData({
                          ...data,
                          date: `${value.month}/${value.day}/${value.year}`,
                          day:
                            formatter
                              .format(value.toDate(getLocalTimeZone()))
                              .slice(0, 3) || null
                        });
                      }}
                    />
                  </div>
                )}
                {data.type === "Full-time Adjustment" && (
                  <div>
                    <div
                      className={` flex gap-1 flex-col sm:flex-row ${
                        errors.time
                          ? "border-danger-600 border rounded-lg p-1"
                          : "border-slate-200"
                      }`}
                    >
                      <TimeInput
                        classNames={{ label: " font-semibold" }}
                        labelPlacement="outside"
                        label="Start Time"
                        value={new Time(data.startHour, data.startMinute)}
                        errorMessage={errors.startHour}
                        onChange={(value) => {
                          setData({
                            ...data,
                            startHour: value.hour,
                            startMinute: value.minute
                          });
                        }}
                      />
                      <TimeInput
                        classNames={{ label: " font-semibold" }}
                        labelPlacement="outside"
                        label="End Time"
                        value={new Time(data.endHour, data.endMinute)}
                        errorMessage={errors.endHour}
                        onChange={(value) => {
                          setData({
                            ...data,
                            endHour: value.hour,
                            endMinute: value.minute
                          });
                        }}
                      />
                    </div>
                    {errors.time && (
                      <p className="text-danger-600 text-sm mt-1">
                        {errors.time}
                      </p>
                    )}
                  </div>
                )}

                {/* Day */}
                <div>
                  <div className="flex w-full flex-wrap md:flex-nowrap gap-4">
                    <Select
                      isDisabled={data.type === "Temporary Leave"}
                      classNames={{
                        label: " font-semibold",
                        mainWrapper: `${
                          errors.day
                            ? "border-danger-600 border rounded-lg "
                            : "border-slate-200"
                        }`
                      }}
                      labelPlacement="outside"
                      label="Day"
                      selectedKeys={[data.day]}
                      placeholder="Select a day"
                      onSelectionChange={(value) => {
                        const extractedValue = [...value][0];
                        setData({ ...data, day: extractedValue });
                      }}
                    >
                      <SelectItem key={"Mon"}>Monday</SelectItem>
                      <SelectItem key={"Tue"}>Tuesday</SelectItem>
                      <SelectItem key={"Wed"}>Wednesday</SelectItem>
                      <SelectItem key={"Thu"}>Thursday</SelectItem>
                      <SelectItem key={"Fri"}>Friday</SelectItem>
                    </Select>
                  </div>
                  {errors.day && (
                    <p className="text-danger-600 text-sm mt-1">{errors.day}</p>
                  )}
                </div>
                {/* Reason */}
                <div className=" relative">
                  <label
                    htmlFor="requestReason"
                    className="block text-sm font-medium mb-2"
                  >
                    Request Reason
                  </label>
                  <textarea
                    id="requestReason"
                    rows="3"
                    maxLength={500}
                    className={`py-2 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] dark:bg-slate-900 dark:border-gray-700 dark:focus:ring-gray-600 ${
                      errors.requestReason
                        ? "border-danger-600"
                        : "border-slate-200"
                    }`}
                    value={data.requestReason}
                    onChange={(e) =>
                      handleChange("requestReason", e.target.value)
                    }
                  />
                  <div className="absolute bottom-1 right-3 text-sm text-gray-500">
                    {(data &&
                      data.requestReason &&
                      data?.requestReason?.length) ||
                      0}
                    /{500}
                  </div>
                  {errors.requestReason && (
                    <p className="text-danger-600 text-sm mt-1">
                      {errors.requestReason}
                    </p>
                  )}
                </div>

                {/* Add more fields as needed */}
              </ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  variant="light"
                  onPress={closeModal}
                  isDisabled={isLoading}
                >
                  Close
                </Button>
                <Button
                  color="secondary"
                  aria-haspopup="dialog"
                  aria-expanded="false"
                  aria-controls="hs-offcanvas-body-scrolling-with-backdrop"
                  data-hs-overlay="#hs-offcanvas-body-scrolling-with-backdrop"
                  isDisabled={Object.keys(errors).length > 0}
                  onPress={handleSubmit}
                  isLoading={isLoading}
                >
                  Submit
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
