import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Checkbox,
  Input,
  Link,
} from "@nextui-org/react";
import { useAuth } from "../../../../utils/AppwriteConfig/AuthContext";
import axios from "axios";

export default function AddNewCourse({ Title, reload }) {
  /**
   *
   * THIS COMPONENT IS USED TO CREATE NEW COURSES
   *
   */
  const { server, user } = useAuth();

  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const [data, setdata] = useState({
    creator: user.$id,
    name: "",
    description: "",
    gradeLevel: "",
    department: "",
    daysToAttend: "",
  });
  const [isLoading, setisLoading] = useState(false);
  const [MainError, setMainError] = useState();
  const [Errors, setErrors] = useState({});

  let errors = {};
  const validate = () => {
    // Validate name
    if (!data.name.trim()) {
      errors.name = `${Title} name is required`;
    } else if (data.name.length > 50) {
      errors.name = `${Title} name is too long`;
    }

    if (!data.department) {
      errors.department = `Please select a department`;
    }

    if (
      isNaN(data.gradeLevel) ||
      data.gradeLevel < 0 ||
      data.gradeLevel > 20 ||
      data.gradeLevel === ""
    ) {
      errors.gradeLevel = `Grade level must be between 1 and 12`;
    }

    if (
      isNaN(data.daysToAttend) ||
      data.daysToAttend < 1 ||
      data.daysToAttend > 5
    ) {
      errors.daysToAttend = `Days to attend must be between 1 and 5`;
    }
    setErrors(errors);
  };

  //Used to Validate every input of user
  useEffect(() => {
    validate();
  }, [data]);

  //Handles the Add process of the new course
  const handleAdd = async () => {
    const type = Title;
    validate();
    if (Object.keys(errors).length === 0) {
      setisLoading(true);
      try {
        const response = await axios.post(
          `${server}/v1/${type.toLowerCase()}/new`,
          data
        );
      } catch (e) {
        setMainError(e.message);
      } finally {
        setisLoading(false);
      }
      setdata({
        creator: user.$id,
        name: "",
        description: "",
        gradeLevel: "",
        department: "",
        daysToAttend: "",
      });
      reload();
      onClose();
    } else {
    }
    // Additional logic for handling add action
  };

  return (
    <>
      <Button
        onPress={onOpen}
        className="bg-[#FFDE59]  flex-initial flex ml-1 rounded-lg text-black font-semibold hover:bg-[#fcd84b] overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-window-plus"
          viewBox="0 0 16 16"
        >
          <path d="M2.5 5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1M4 5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1m2-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0" />
          <path d="M0 4a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v4a.5.5 0 0 1-1 0V7H1v5a1 1 0 0 0 1 1h5.5a.5.5 0 0 1 0 1H2a2 2 0 0 1-2-2zm1 2h13V4a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1z" />
          <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0m-3.5-2a.5.5 0 0 0-.5.5v1h-1a.5.5 0 0 0 0 1h1v1a.5.5 0 0 0 1 0v-1h1a.5.5 0 0 0 0-1h-1v-1a.5.5 0 0 0-.5-.5" />
        </svg>{" "}
        {Title}
      </Button>
      <Modal
        isOpen={isOpen}
        scrollBehavior="inside"
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Add new {Title}
              </ModalHeader>
              <ModalBody>
                <form className="w-full flex flex-col space-y-2">
                  <>
                    <label
                      for={`${Title}name`}
                      className="block text-sm mb-2 dark:text-white font-medium"
                    >
                      {Title} Name
                    </label>
                    <input
                      type="text"
                      name={`${Title}name`}
                      id={`${Title}name`}
                      className={`py-2 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
                        Errors.name == null
                          ? "border-slate-200"
                          : "border-red-500"
                      }`}
                      required
                      defaultValue={data.name}
                      onChange={(e) =>
                        setdata({
                          ...data,
                          name: e.target.value,
                        })
                      }
                    />
                    {Errors.name && (
                      <span className="text-red-500 text-sm mt-1">
                        {Errors.name}
                      </span>
                    )}
                    <div>
                      <label
                        for={`Department`}
                        className="block text-sm mb-2 dark:text-white font-medium"
                      >
                        Department
                      </label>
                      <select
                        className={` pe-9 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 
                      ${
                        Errors.department == null
                          ? "border-gray-200"
                          : "border-red-500"
                      }
                      `}
                        onChange={(e) =>
                          setdata({ ...data, department: e.target.value })
                        }
                      >
                        <option value="" disabled selected hidden>
                          Select Department
                        </option>
                        <option value="Pre">Pre-school</option>
                        <option value="Elem">Elementary</option>
                        <option value="JHS">Junior High School</option>
                        <option value="SHS">Senior High School</option>
                      </select>
                      {Errors.department && (
                        <span className="text-red-500 text-sm mt-1">
                          {Errors.department}
                        </span>
                      )}
                    </div>

                    <div className="flex  space-x-2">
                      <div className="flex-1">
                        <label
                          for={`gardelvl`}
                          className="block text-sm mb-2 dark:text-white font-medium"
                        >
                          Grade level{" "}
                          {Errors.gradeLevel && (
                            <span className="text-red-500">*</span>
                          )}
                        </label>
                        <select
                          disabled={!data.department}
                          className={` pe-9 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 
                      ${
                        Errors.gradeLevel == null
                          ? "border-gray-200"
                          : "border-red-500"
                      }
                      `}
                          onChange={(e) =>
                            setdata({ ...data, gradeLevel: e.target.value })
                          }
                        >
                          <option value="" disabled selected hidden>
                            Select Grade level
                          </option>

                          {data.department === "Pre" && (
                            <>
                              <option value="1">1</option>
                              <option value="2">2</option>
                            </>
                          )}
                          {data.department === "Elem" &&
                            Array.from({ length: 6 }, (_, index) => (
                              <option key={index + 1} value={index + 1}>
                                {index + 1}
                              </option>
                            ))}
                          {data.department === "JHS" &&
                            Array.from({ length: 4 }, (_, index) => (
                              <option key={index + 7} value={index + 7}>
                                {index + 7}
                              </option>
                            ))}
                          {data.department === "SHS" &&
                            Array.from({ length: 2 }, (_, index) => (
                              <option key={index + 11} value={index + 11}>
                                {index + 11}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="flex-initial">
                        <label
                          for={`daystoattend`}
                          className="block text-sm mb-2 dark:text-white font-medium"
                        >
                          Days to Attend per week{" "}
                          {Errors.daysToAttend && (
                            <span className="text-red-500">*</span>
                          )}
                        </label>
                        <input
                          type="text"
                          name={`daystoattend`}
                          id={`daystoattend`}
                          className={`py-2 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
                            Errors.daysToAttend == null
                              ? "border-slate-200"
                              : "border-red-500"
                          }`}
                          required
                          defaultValue={data.daysToAttend}
                          onChange={(e) =>
                            setdata({
                              ...data,
                              daysToAttend: e.target.value,
                            })
                          }
                          onKeyDown={(e) => {
                            const isNumeric = /^\d+$/.test(e.key);
                            const isBackspaceOrDelete =
                              e.key === "Backspace" || e.key === "Delete";
                            const isLengthOne = e.target.value.length === 1;
                            // Allow only numeric keys, backspace, delete, arrow keys, and navigation keys
                            if (
                              (isNumeric || isBackspaceOrDelete) &&
                              !(isLengthOne && !isBackspaceOrDelete)
                            ) {
                              return;
                            } else {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        for={`${Title}description`}
                        className="block text-sm mb-2 font-medium dark:text-white"
                      >
                        Short {Title} Description
                      </label>
                      <input
                        type="text"
                        name={`${Title}description`}
                        id={`${Title}description`}
                        className={`py-2 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
                          Errors.description == null
                            ? "border-slate-200"
                            : "border-red-500"
                        }`}
                        required
                        defaultValue={data.description}
                        onChange={(e) =>
                          setdata({
                            ...data,
                            description: e.target.value,
                          })
                        }
                      />
                      {Errors.description && (
                        <span className="text-red-500 text-sm mt-1">
                          {Errors.description}
                        </span>
                      )}
                    </div>
                  </>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  variant="flat"
                  onPress={() => {
                    setdata({
                      creator: user.$id,
                      name: "",
                      description: "",
                      gradeLevel: "",
                      department: "",
                      daysToAttend: "",
                    });
                    onClose();
                  }}
                >
                  Close
                </Button>
                {isLoading ? (
                  <Button
                    isLoading
                    color="secondary"
                    spinner={
                      <svg
                        className="animate-spin h-5 w-5 text-current"
                        fill="none"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          fill="currentColor"
                        />
                      </svg>
                    }
                  >
                    Loading
                  </Button>
                ) : (
                  <Button color="secondary" onPress={handleAdd}>
                    Add {Title}
                  </Button>
                )}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
