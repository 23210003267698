import axios from "axios";
import { useEffect, useState } from "react";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import { Skeleton, Spinner } from "@nextui-org/react";
import useRealtimeSubscription from "../../../../../utils/AppwriteConfig/RealtimeServices";
import { GetCurrentAcademicYear } from "../../../../../utils/helpers/stringformat";

export default function AssignedStudentTotal({ id, sectionid, isrefreshed }) {
  const { role, server } = useAuth();
  const [isLoading, setisLoading] = useState(false);
  const [students, setstudents] = useState([]);

  useEffect(() => {
    setisLoading(true);
    async function fetchenrollee() {
      try {
        const response = await axios.get(`${server}/v1/course/students`, {
          params: {
            id: id,
            section: sectionid,
            academicYear: GetCurrentAcademicYear(),
          },
        });
        console.log(response.data);
        setstudents(response.data);
      } catch (error) {
        // Handle any errors
        console.error(error);
      } finally {
        setisLoading(false);
      }
    }
    fetchenrollee();
  }, [id, isrefreshed]);

  const HandleRealtimeChanges = (changes) => {
    console.log("changes:", changes.payload);
    console.log(changes?.payload?.course?.$id, id);
    console.log(changes?.payload?.section?.$id, sectionid);

    if (
      changes?.payload?.course?.$id === id &&
      changes?.payload?.section?.$id === sectionid
    ) {
      console.log(changes.changetype);
    }
  };

  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_ENROLLMENT,
    HandleRealtimeChanges
  );

  return (
    <Skeleton isLoaded={!isLoading} className=" rounded-full">
      <span className="text-sm font-medium">
        Total Students : {students.total}
      </span>
    </Skeleton>
  );
}
