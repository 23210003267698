import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Spinner
} from "@nextui-org/react";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import { useState } from "react";
import axios from "axios";

export default function AssignedRoom({ data, section, refresh }) {
  const { DataServer, user } = useAuth();
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();

  const [isLoading, setIsLoading] = useState(false);
  const [mainError, setMainError] = useState(null);

  const handleSubmit = async () => {
    // Replace with your submit logic

    if (Object.keys(data).length > 0) {
      //edit
      try {
        setIsLoading(true);
        const NewRoomResponse = await axios.put(
          `${DataServer}/info/room/remove/section`,
          { selectedRoom: data, user, section }
        );
        console.log(NewRoomResponse.data);
      } catch (error) {
        setMainError(error.message);
      } finally {
        setIsLoading(false);
        closeModal();
        refresh();
      }
    }
  };

  function closeModal() {
    if (mainError) {
      setTimeout(() => {
        onClose();
      }, 2500); // Delay of 2.5 seconds
    } else {
      // Immediate close if there's no MainError
      onClose();
    }
  }
  return (
    <>
      <button onClick={onOpen}>
        <div className=" border  rounded-lg p-2 px-4 hover:shadow-md transition-all  duration-75 cursor-pointer transform hover:scale-[101%] bg-[#A16AE8]">
          <div className=" text-sm text-white ">
            {data?.roomName || "Room 401"}
          </div>
        </div>
      </button>
      <Modal
        className=" font-poppins"
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        onClose={closeModal}
      >
        <ModalContent>
          {(closeModal) => (
            <>
              <ModalHeader className="flex flex-col gap-1 pb-0">
                Room Info
              </ModalHeader>
              <ModalBody>
                <div className="">
                  <label
                    htmlFor="roomName"
                    className="block text-sm font-medium mb-2 "
                  >
                    <div className=" flex  items-center">Room Name</div>
                  </label>
                  <div className=" bg-[#EDEDED] rounded-lg px-3 py-2">
                    {data.roomName}
                  </div>
                </div>
                <div className="">
                  <label
                    htmlFor="capacity"
                    className="block text-sm font-medium mb-2"
                  >
                    Capacity
                  </label>
                  <div className=" bg-[#EDEDED] rounded-lg px-3 py-2">
                    {data.capacity}
                  </div>
                </div>

                <div className="">
                  <label
                    htmlFor="roomType"
                    className="block text-sm font-medium mb-2"
                  >
                    Department
                  </label>
                  <div className=" bg-[#EDEDED] rounded-lg px-3 py-2">
                    {data.department}
                  </div>
                </div>

                {data.location && (
                  <div className=" relative">
                    <label
                      htmlFor="location"
                      className="block text-sm font-medium mb-2"
                    >
                      Location
                    </label>
                    <div className=" bg-[#EDEDED] rounded-lg px-3 py-2">
                      {data.location}
                    </div>
                  </div>
                )}
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={closeModal}>
                  Close
                </Button>
                <Button
                  color="secondary"
                  isDisabled={Object.keys(data).length <= 0}
                  onPress={() => {
                    handleSubmit();
                  }}
                >
                  {isLoading && Object.keys(data).length > 0 ? (
                    <div className=" flex items-center gap-1">
                      <Spinner size="sm" color=" white" />
                      <div>Removing Room</div>
                    </div>
                  ) : (
                    "Remove Room"
                  )}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
