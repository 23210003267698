import { Button } from "@nextui-org/react";
import axios from "axios";
import { useState, useEffect } from "react";
import { useAuth } from "../../../../utils/AppwriteConfig/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import Error from "../../../../Components/ErrorIndicator";

export default function FinalCheck({
  AssignedTeachers,
  ValidSchedules,
  currentStep,
  academicYear,
  department
}) {
  const { user } = useAuth();
  console.log(user);
  const navigate = useNavigate();
  const { SchedulingServer } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [MainError, setMainError] = useState(false);
  const [isError, setIsError] = useState(false);

  const [isSaved, setIsSaved] = useState(false);
  const [isRefresh, setIsRefresh] = useState(true);
  const [isFirstTry, setIsFirstTry] = useState(true);
  console.log(department);
  async function SaveData() {
    try {
      setIsLoading(true);
      const teacherSubjects = {};
      AssignedTeachers.forEach((assignment) => {
        const { userId, totalSubjectTimeInAWeek } = assignment;

        // If the userId key already exists, add to the existing value
        if (teacherSubjects[userId]) {
          teacherSubjects[userId] += totalSubjectTimeInAWeek;
        } else {
          // Otherwise, create a new key with the initial value
          teacherSubjects[userId] = totalSubjectTimeInAWeek;
        }
      });
      const body = {
        AssignedTeachers: AssignedTeachers,
        TotalTimes: teacherSubjects,
        ValidSchedules: ValidSchedules,
        academicYear: academicYear,
        dept: department,
        creator: user
      };
      console.log(body);

      const ScheduleResponse = await axios.post(
        `${SchedulingServer}/schedule/save`,
        body
      );
      console.log("HHssH", ScheduleResponse.data.gg);
      setIsSaved(ScheduleResponse.data.isDone);
    } catch (error) {
      console.log(error);
      setMainError(error.message);
      setIsError(true);
    } finally {
      setIsLoading(false);
      setIsFirstTry(false);
    }
  }
  //Save
  useEffect(() => {
    if (
      AssignedTeachers.length > 0 &&
      ValidSchedules.length > 0 &&
      currentStep === 2
    ) {
      console.log("GG");

      if (isFirstTry) SaveData();
    }
  }, [AssignedTeachers, ValidSchedules, currentStep, isRefresh]);

  return (
    <div className=" flex flex-col items-center justify-center  p-4">
      {MainError && <Error errorMessage={MainError} reset={setMainError} />}
      {(!isSaved || isLoading) && !isError ? <SavingData /> : <Continue />}
    </div>
  );

  function Continue() {
    return (
      <div
        // onClick={() => {
        //   SaveData();
        // }}
        className="p-4 gap-2 relative rounded-md bg-white shadow-lg min-w-[250px] max-w-[400px] max-h-[300px] flex flex-col items-center"
      >
        <img
          alt="loading"
          src={require("../../../../Img/COmpleted.gif")}
          className="min-w-[200px] transition-width delay-500 min-h-[100px] max-w-[300px] max-h-[200px] object-contain mb-4 rounded-md"
        />
        <span className="  text-secondary-500 p-2 bg-[#f3f4f6] rounded-md">
          {isError
            ? "Im sorry, Something went wrong..."
            : "All Schedules is posted and saved"}
        </span>
        <Link to={`/user/employee/manage/schedules`}>
          <div className=" bg-[#7828c8] p-3 rounded-lg text-white py-2">
            {isError ? "Go Back" : "Continue"}
          </div>
        </Link>

        {/* <Button
          color="secondary"
          onPress={() => {
            setIsRefresh(!isRefresh);
          }}
        >
          retryss
        </Button> */}
      </div>
    );
  }
}

function SavingData() {
  const loadingMessages = [
    "Customizing schedules for teachers, almost done!",
    "Sorting out student schedules, thanks for your patience!",
    "Fine-tuning your schedule, this won't take long!",
    "Checking for conflicts, getting everything in order...",
    "Wrapping up the final details, your schedule is on the way!"
  ];

  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [fade, setFade] = useState(true); // State to control fade effect

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false); // Start fade-out

      setTimeout(() => {
        setCurrentMessageIndex(
          (prevIndex) => (prevIndex + 1) % loadingMessages.length
        );
        setFade(true); // Start fade-in after changing message
      }, 1000); // Delay between fade-out and fade-in
    }, 3500); // Change message every 2.5 seconds to accommodate the fade

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [loadingMessages.length]);

  return (
    <div className="p-4 rounded-md bg-white shadow-lg min-w-[250px] max-w-[400px] max-h-[300px] flex flex-col items-center">
      <img
        alt="loading"
        src={require("../../../../Img/dribble-planner.gif")}
        className="min-w-[200px] transition-width delay-500 min-h-[100px] max-w-[300px] max-h-[200px] object-contain mb-4 rounded-md"
      />
      <div
        className="text-center text-secondary-500 font-semibold"
        style={{
          opacity: fade ? 1 : 0,
          transition: "opacity 0.5s ease-in-out",
          padding: "10px 20px",
          borderRadius: "8px",
          backgroundColor: "#f3f4f6",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"
        }}
      >
        {loadingMessages[currentMessageIndex]}
      </div>
    </div>
  );
}
