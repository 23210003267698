import {
  Avatar,
  Card,
  CardBody,
  CardFooter,
  Image,
  Switch
} from "@nextui-org/react";

export default function ScheduleCard({ setselected, data, type = "inactive" }) {
  return (
    <Card
      // key={user?.$id}
      isPressable
      onPress={() => {
        setselected(data);
        document.querySelector("#ShowSched").click();
      }}
      shadow="sm"
      className=" rounded-md min-h-44 max-h-52 overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
    >
      <Image
        removeWrapper
        alt="Card background"
        className="z-0 w-full h-full object-cover"
        src={require("../../../../Img/BSA.jpg")}
      />
      <CardFooter className="absolute bg-[#e5dbf9]/90 bottom-0 h-full z-10 rounded-md">
        <div className=" relative flex flex-col justify-end w-full h-full">
          <div className={` absolute top-2 right-2 text-secondary-600 `}>
            {data?.academicYear}
          </div>
          <div className=" flex flex-col justify-start text-start w-full">
            <div className=" text-lg flex items-center  gap-1 text-[#4a306d] font-semibold mb-1">
              <Avatar
                src="https://i.pravatar.cc/150?u=a042581f4e29026024d"
                className="w-5 h-5 text-tiny"
              />
              <div>{data?.userCredentials?.Username}</div>
            </div>
            <div className="text-sm text-[#7d5ca3]">
              Role: {data?.userCredentials?.role}
            </div>
          </div>
        </div>
        {/* <div className=" text-medium flex flex-col items-start text-black/90">
          <span>{user?.accountType}</span>
          <span className="">
            {user?.Username}
            {user?.student?.firstName}{" "}
            {user?.student?.lastName}
          </span>
        </div>
        <p className="text-tiny text-white/90">
          {user?.role}
          {user.student && (
            <>Student | {user?.course?.courseName}</>
          )}
        </p> */}
      </CardFooter>
    </Card>
  );
}
