import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Tabs,
  Tab,
  User,
  Card,
  Chip,
  CardBody,
  CardHeader,
  useDisclosure
} from "@nextui-org/react";
import TableNoWrapper from "../../../../../Components/Table/TableNoWrapper";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import axios from "axios";
import TeacherTable from "../renders/TeacherTable";
import AvailableTeacherTable from "../renders/TeacherTable";
import MatchedTeacherTable from "../renders/MatchTeacherTable";
import SuitableTeacherTable from "../renders/SuitableTeacherTable";

export default function ReAssignTeacherbtn({
  Data,
  totalTimes,
  suitableTeachers,
  updateAssignedTeacher,
  totalCounts
}) {
  const [isLoading, setisLoading] = useState(false);
  const [MainError, setMainError] = useState("");
  const { isOpen, onOpen, onClose, onOpenChange } = useDisclosure();

  const [selected, setSelected] = useState("Suggested");
  const [Teacher, setTeacher] = useState({});

  //   useEffect(() => {
  //     console.log(Teacher);
  //   }, [Teacher]);

  const [total, setTotal] = useState({ available: 0, matched: 0 });
  return (
    <>
      <Chip
        onClick={onOpen}
        className=" hover:cursor-pointer items-center"
        endContent={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-person-fill-add"
            viewBox="0 0 16 16"
          >
            <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0m-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
            <path d="M2 13c0 1 1 1 1 1h5.256A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1 1.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4" />
          </svg>
        }
      >
        Assign Teacher
      </Chip>
      <Modal
        size="3xl"
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        onOpen={onOpen}
        onClose={onClose}
        isDismissable={false}
        isKeyboardDismissDisabled={true}
      >
        <ModalContent className=" font-poppins">
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Assign Teacher
              </ModalHeader>
              <ModalBody>
                <div className="flex-initial">
                  <div className="w-full p-2 space-y-2 bg-slate-200 rounded-lg">
                    <label>Selected Teacher</label>
                    <div className="flex flex-col space-y-2 sm:space-y-0 items-start sm:flex-row sm:items-center justify-between">
                      <User
                        name={
                          Teacher?.userCredentials?.Username
                            ? Teacher?.userCredentials?.Username
                            : "Select A Teacher"
                        }
                        description={
                          Teacher !== null
                            ? Teacher?.userCredentials?.Email
                            : ""
                        }
                        avatarProps={{
                          src: "https://images.unsplash.com/broken"
                        }}
                      />
                      <div>
                        <span className="font-semibold">
                          {Data?.subjectName}
                        </span>{" "}
                        {Data?.courseName} | {Data?.sectionName}
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" flex-1 flex w-full flex-col">
                  <Tabs
                    color="secondary"
                    className=" overflow-auto max-h-200"
                    aria-label="Options"
                    selectedKey={selected}
                    onSelectionChange={setSelected}
                  >
                    {/* <Tab
                      key="Available"
                      title={
                        <div className="flex justify-center items-center space-x-2">
                          Available Teacher{" "}
                          {total.available > 0 && (
                            <div
                              className={`  ml-1 text-xs ${
                                selected === "Available"
                                  ? "bg-secondary-700"
                                  : "bg-[#b6b6bd]"
                              }  rounded-full p-1`}
                            >
                              <span className="p-1">{total.available}</span>
                            </div>
                          )}
                        </div>
                      }
                      className="relative"
                    >
                      <Card onScroll={true}>
                        <CardBody className="max-h-[300px] p-1  overflow-auto">
                          <AvailableTeacherTable
                            teachertimes={totalTimes}
                            subject={Data}
                            suitable={suitableTeachers}
                            setTotal={setTotal}
                            updateteacher={setTeacher}
                          />
                        </CardBody>
                      </Card>
                    </Tab>

                    <Tab
                      key="Match"
                      title={
                        <div className="flex justify-center items-center space-x-2">
                          Matched Teacher{" "}
                          {total.matched > 0 && (
                            <div
                              className={`  ml-1 text-xs ${
                                selected === "Match"
                                  ? "bg-secondary-700"
                                  : "bg-[#b6b6bd]"
                              }  rounded-full p-1`}
                            >
                              <span className="p-1">{total.matched}</span>
                            </div>
                          )}
                        </div>
                      }
                    >
                      <Card>
                        <CardBody>
                          <MatchedTeacherTable
                            teachertimes={totalTimes}
                            subject={Data}
                            suitable={suitableTeachers}
                            setTotal={setTotal}
                            updateteacher={setTeacher}
                          />
                        </CardBody>
                      </Card>
                    </Tab> */}
                    <Tab
                      key="Suggested"
                      title={<div className="">AI Suggested Teachers </div>}
                    >
                      <Card>
                        <CardBody>
                          <SuitableTeacherTable
                            teachertimes={totalTimes}
                            subject={Data}
                            setTotal={setTotal}
                            count={totalCounts}
                            updateteacher={setTeacher}
                            teachers={suitableTeachers}
                          />
                        </CardBody>
                      </Card>
                    </Tab>
                  </Tabs>
                </div>
              </ModalBody>
              <ModalFooter>
                {isLoading !== true && (
                  <Button color="danger" variant="light" onPress={onClose}>
                    Close
                  </Button>
                )}
                {isLoading ? (
                  <Button
                    isLoading
                    color="secondary"
                    spinner={
                      <svg
                        className="animate-spin h-5 w-5 text-current"
                        fill="none"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          fill="currentColor"
                        />
                      </svg>
                    }
                  >
                    Processing...
                  </Button>
                ) : (
                  <>
                    <Button
                      color="secondary"
                      isDisabled={!Object.keys(Teacher).length}
                      onPress={() => {
                        if (Object.keys(Teacher).length > 0) {
                          console.log({
                            ...Data,
                            teacherId: Teacher.teacherId,
                            scores: Teacher.ComputedSuitabilityScores,
                            preferenceScore: Teacher._preferredSubjectPercent,
                            experienceScore: Teacher._experiencePercent,
                            prefAndExpScore: Teacher._prefExReason,
                            degreeRelevanceScore: Teacher._degreePercent,
                            degreeRelevanceReason: Teacher._degreeReason,
                            taughtSubjectRelevanceScore:
                              Teacher._SuitabilityScore,
                            tuaghtSubjectRelevanceReason: Teacher._prefExReason,
                            totalSuitability: Teacher._totalSuitabilityScore,
                            teacherDept: Teacher.department,
                            teacherName: Teacher.userCredentials.Username,
                            teacherExperience: Teacher.teachingExperience,
                            currentTeachingTime:
                              Teacher?.assignedTime ||
                              Teacher.assignedTeachingTime,
                            teachingTimePerWeek: `${
                              Teacher?.assignedTime ||
                              Teacher.assignedTeachingTime
                            }/1800`
                          });
                          updateAssignedTeacher({
                            ...Data,
                            teacherId: Teacher.teacherId,
                            userId: Teacher.$id,
                            scores: Teacher.ComputedSuitabilityScores,
                            preferenceScore: Teacher._preferredSubjectPercent,
                            experienceScore: Teacher._experiencePercent,
                            prefAndExpScore: Teacher._prefExReason,
                            degreeRelevanceScore: Teacher._degreePercent,
                            degreeRelevanceReason: Teacher._degreeReason,
                            taughtSubjectRelevanceScore:
                              Teacher._SuitabilityScore,
                            tuaghtSubjectRelevanceReason:
                              Teacher._suitabilityReason,
                            totalSuitability: Teacher._totalSuitabilityScore,
                            teacherDept: Teacher.department,
                            teacherName: Teacher.userCredentials.Username,
                            teacherExperience: Teacher.teachingExperience,
                            currentTeachingTime:
                              Teacher?.assignedTime ||
                              Teacher.assignedTeachingTime,
                            teachingTimePerWeek: `${
                              Teacher?.assignedTime ||
                              Teacher.assignedTeachingTime
                            }/1800`,
                            status: "edited"
                          });
                        } else {
                          setMainError("No Selected Teacher");
                        }
                        // updateAssignedTeacher();
                      }}
                    >
                      Assign to {Data.courseName} | {Data.sectionName}
                    </Button>
                  </>
                )}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
