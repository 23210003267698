import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Tabs,
  Tab
} from "@nextui-org/react";
import ReAssignTeacherbtn from "../modal/Re_AssignBTN";

export default function ScheduleErrors({
  type = "err",
  errors,
  warnings,
  selectedWarning,
  totalTimes,
  updateAssigned,
  suitableTeachers
}) {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();

  function closeSched() {
    selectedWarning({});
    onClose();
  }
  return (
    <>
      <div
        className={`${
          type === "err"
            ? "bg-secondary-50 border-s-4 border-secondary-500"
            : "bg-warning-100 border-s-4 border-warning-500"
        } p-4 dark:bg-red-800/30 hover:scale-[102%] transition-transform duration-200 hover:cursor-pointer ease-in-out`}
        role="alert"
        onClick={onOpen}
      >
        {type === "err" ? (
          <div className="flex">
            <div className="flex-shrink-0">
              <span className="inline-flex justify-center items-center size-8 rounded-full border-4 border-red-100 bg-red-200 text-red-800 dark:border-red-900 dark:bg-red-800 dark:text-red-400">
                <svg
                  className="flex-shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M18 6 6 18"></path>
                  <path d="m6 6 12 12"></path>
                </svg>
              </span>
            </div>
            <div className="ms-3">
              <h3 className="text-gray-800 font-semibold dark:text-white">
                Error!
              </h3>
              <p className="text-sm text-gray-700 dark:text-neutral-400">
                There are Errors that we have encountered...
              </p>
            </div>
          </div>
        ) : (
          <div className="flex">
            <div className="flex-shrink-0">
              <div className="flex-shrink-0">
                <span className="inline-flex justify-center items-center size-8 rounded-full border-4 border-warning-100 bg-warning-200 text-warning-800 dark:border-warning-900 dark:bg-warning-800 dark:text-warning-400">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="13"
                    fill="currentColor"
                    class="bi bi-exclamation-triangle-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
                  </svg>
                </span>
              </div>
            </div>
            <div className="ms-3">
              <h3 className="text-gray-800 font-semibold dark:text-white">
                Warning
              </h3>
              <p className="text-sm text-gray-700 dark:text-neutral-400">
                There are few warnings that you need to fix...
              </p>
            </div>
          </div>
        )}
      </div>

      <Modal
        isOpen={isOpen}
        className=" max-h-[500px]"
        onOpenChange={onOpenChange}
        isDismissable={false}
        onClose={onClose}
        isKeyboardDismissDisabled={true}
        scrollBehavior="inside"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>
                {type === "err" ? (
                  <div className=" flex items-center">
                    <div className="flex">
                      <span className="inline-flex justify-center items-center size-8 rounded-full border-4 border-red-100 bg-red-200 text-warning-800 dark:border-warning-900 dark:bg-warning-800 dark:text-warning-400">
                        <svg
                          className="flex-shrink-0 size-4"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path d="M18 6 6 18"></path>
                          <path d="m6 6 12 12"></path>
                        </svg>
                      </span>
                    </div>
                    <div className="ms-3">
                      <h3 className="text-gray-800 font-semibold dark:text-white">
                        {errors.length + " " || ""} Error
                        {errors.length > 2 && "s"}
                      </h3>
                      <p className="text-sm text-gray-700 dark:text-neutral-400">
                        There are few errors that you need to fix...
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center">
                    <div className="flex">
                      <span className="inline-flex justify-center items-center size-8 rounded-full border-4 border-warning-100 bg-warning-200 text-warning-800 dark:border-warning-900 dark:bg-warning-800 dark:text-warning-400">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13"
                          height="13"
                          fill="currentColor"
                          class="bi bi-exclamation-triangle-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
                        </svg>
                      </span>
                    </div>
                    <div className="ms-3">
                      <h3 className="text-gray-800 font-semibold dark:text-white">
                        {warnings.length + " " || ""} Warning
                        {warnings.length > 2 && "s"}
                      </h3>
                      <p className="text-sm text-gray-700 dark:text-neutral-400">
                        There are few warnings that you need to fix...
                      </p>
                    </div>
                  </div>
                )}
              </ModalHeader>
              <ModalBody className=" transition duration-100 ease-in-out min-h-[250px] ">
                {type === "err" ? (
                  <>
                    {errors && errors.length > 0 ? (
                      errors.map((err, index) => (
                        <div
                          key={index}
                          className="bg-secondary-50 border-s-4 border-secondary-500 p-4 dark:bg-red-800/30 hover:scale-[102%] transition-transform duration-200 ease-in-out"
                          role="alert"
                        >
                          <div className="flex">
                            <div className="flex-shrink-0">
                              <span className="inline-flex justify-center items-center size-8 rounded-full border-4 border-red-100 bg-red-200 text-red-800 dark:border-red-900 dark:bg-red-800 dark:text-red-400">
                                <svg
                                  className="flex-shrink-0 size-4"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                >
                                  <path d="M18 6 6 18"></path>
                                  <path d="m6 6 12 12"></path>
                                </svg>
                              </span>
                            </div>
                            <div className="ms-3">
                              <h3 className="text-gray-800 font-semibold dark:text-white">
                                {err.courseName}
                              </h3>
                              <p className="text-sm text-gray-700 dark:text-neutral-400">
                                {err.err}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div
                        className="bg-secondary-50 border-s-4 border-secondary-500 p-4 dark:bg-red-800/30 hover:scale-[102%] transition-transform duration-200 ease-in-out"
                        role="alert"
                      >
                        <div className="flex">
                          <div className="flex-shrink-0 items-center flex">
                            <span className="inline-flex justify-center items-center size-8 rounded-full border-4 border-red-100 bg-red-200 text-red-800 dark:border-red-900 dark:bg-red-800 dark:text-red-400">
                              <svg
                                className="flex-shrink-0 size-4"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path d="M18 6 6 18"></path>
                                <path d="m6 6 12 12"></path>
                              </svg>
                            </span>
                          </div>
                          <div className="ms-3">
                            <h3 className="text-gray-800 font-semibold dark:text-white">
                              There is no Error here
                            </h3>
                            <p className="text-sm text-gray-700 dark:text-neutral-400"></p>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {warnings && warnings.length > 0 ? (
                      warnings.map((warn, index) => (
                        <>
                          <div
                            key={index}
                            className="bg-warning-50 cursor-pointer border-s-4 border-warning-500 p-4 dark:bg-red-800/30 hover:scale-[102%] transition-transform duration-200 ease-in-out"
                            role="alert"
                            onClick={() => {
                              // selectedWarning(warn);
                              console.log(warn);
                              // document.querySelector("#ReAssignTeacher").click();
                            }}
                          >
                            <div className="flex">
                              <div className="flex-shrink-0">
                                <span className="inline-flex justify-center items-center size-8 rounded-full border-4 border-warning-100 bg-warning-200 text-warning-800 dark:border-warning-900 dark:bg-warning-800 dark:text-warning-400">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="13"
                                    height="13"
                                    fill="currentColor"
                                    class="bi bi-exclamation-triangle-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
                                  </svg>
                                </span>
                              </div>
                              <div className="ms-3">
                                <h3 className="text-gray-800 font-semibold dark:text-white">
                                  {`${warn.subjectName} ${warn.courseName} | ${warn.sectionName}`}
                                </h3>
                                <p className="text-sm text-gray-700 dark:text-neutral-400">
                                  No Available teacher |{" "}
                                  <ReAssignTeacherbtn
                                    Data={{
                                      id: warn.id,
                                      courseName: warn.courseName,
                                      courseId: warn.courseId,
                                      sectionId: warn.sectionId,
                                      subjectId: warn.subjectId,
                                      subjectDaysInAWeek:
                                        warn.subjectDaysInAWeek,
                                      subjectName: warn.subjectName,
                                      sectionName: warn.sectionName,
                                      subjectTimePerDay: warn.subjectTimePerDay,
                                      totalSubjectTimeInAWeek:
                                        warn.totalSubjectTimeInAWeek
                                    }}
                                    totalTimes={totalTimes}
                                    updateAssignedTeacher={updateAssigned}
                                    suitableTeachers={
                                      suitableTeachers.find(
                                        (subject) =>
                                          subject.subjectId === warn.subjectId
                                      )?.teachers || []
                                    }
                                  />
                                </p>
                              </div>
                            </div>
                          </div>
                        </>
                      ))
                    ) : (
                      <div
                        className="bg-warning-50 border-s-4 border-warning-500 p-4 dark:bg-warning-800/30 hover:scale-[102%] transition-transform duration-200 ease-in-out"
                        role="alert"
                      >
                        <div className="flex">
                          <div className="flex-shrink-0 items-center flex">
                            <span className="inline-flex justify-center items-center size-8 rounded-full border-4 border-warning-100 bg-warning-200 text-warning-800 dark:border-warning-900 dark:bg-warning-800 dark:text-warning-400">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-exclamation-triangle-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
                              </svg>
                            </span>
                          </div>
                          <div className="ms-3">
                            <h3 className="text-gray-800 font-semibold dark:text-white">
                              There is no Warning here
                            </h3>
                            <p className="text-sm text-gray-700 dark:text-neutral-400"></p>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
