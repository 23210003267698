import { Select, SelectItem, Spinner } from "@nextui-org/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useAuth } from "../../../../utils/AppwriteConfig/AuthContext";
import SelectedCourse from "../../DocumentsPage/components/SelectCourse";
import { functions } from "../../../../utils/AppwriteConfig/Config";

export default function CoursenSectionFilter({
  currentcourse,
  updatechanges,
  isSearch,
  isDataLoaded
}) {
  const { DataServer, user } = useAuth();

  const [isLoading, setisLoading] = useState(false);
  const [Refresh, setRefresh] = useState(false);
  const [MainError, setMainError] = useState();

  const [Courses, setCourses] = useState([]);
  const [Sections, setSections] = useState([]);
  const [selectedCourse, setselectedCourse] = useState();
  const [selectedSection, setselectedSection] = useState();

  useEffect(() => {
    async function GetCoursesnSections() {
      try {
        setisLoading(true);

        if (isDataLoaded) {
          // const getUsers = await functions.createExecution(
          //   "667bc0d000106435b059",
          //   "",
          //   false,
          //   `/course/v1/allcourses`,
          //   "GET"
          // );
          // console.log(getUsers)
          // const allcourses = JSON.parse(getUsers.responseBody)
          const allcourses = await axios.get(`${DataServer}/course/all`);
          // console.log("h");
          setCourses(allcourses.data.documents);
        }
      } catch (e) {
        setMainError(e.message);
        console.log(e);
        console.log("err");
      } finally {
        setisLoading(false);
      }
    }

    GetCoursesnSections();
  }, []);

  useEffect(() => {
    console.log(selectedCourse, selectedSection);
    updatechanges(selectedCourse, selectedSection);
  }, [selectedCourse, selectedSection]);

  return (
    <div className=" w-full h-full ">
      <div className="flex w-full gap-4 ">
        <div className="relative">
          {isLoading && (
            <Spinner
              size="xl"
              color="warning"
              className=" absolute w-full h-full flex justify-center items-center"
            ></Spinner>
          )}
          <Select
            isDisabled={isSearch !== "" || !isDataLoaded || isLoading}
            label="Course"
            disallowEmptySelection
            placeholder="Select a Course"
            className=" min-w-[120px] s"
            onChange={(e) => {
              setselectedCourse(e.target.value);
              const course = Courses.find(
                (course) => course.$id === e.target.value
              );
              setSections(course ? course.section : []);
              setselectedSection();
            }}
            defaultSelectedKeys={["All"]}
          >
            <SelectItem key={"All"} value={"All"}>
              All
            </SelectItem>
            {Courses.map((course) => (
              <SelectItem key={course.$id} value={course.courseName}>
                {course.courseName}
              </SelectItem>
            ))}
          </Select>
        </div>
        <Select
          label="Section"
          isDisabled={
            !selectedCourse || selectedCourse === "" || selectedCourse === "All"
          }
          placeholder="Select a Section"
          className="min-w-[120px]"
          selectedKeys={[selectedSection]}
          onChange={(e) => {
            setselectedSection(e.target.value);
          }}
        >
          {Sections.map((section) => (
            <SelectItem key={section.$id} value={section.sectionName}>
              {section.sectionName}
            </SelectItem>
          ))}
        </Select>
      </div>
    </div>
  );
}
