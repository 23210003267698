import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Spinner
} from "@nextui-org/react";
import Completed from "../renders/CompletionRenders/Completed";
import Failed from "../renders/CompletionRenders/Failed";
import { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import Error from "../../../../../Components/ErrorIndicator";
import { GetCurrentAcademicYear } from "../../../../../utils/helpers/stringformat";

export default function CourseCompletionModal({
  type = "Passed",
  mainRefresher,
  course = {},
  selectedStudent = [],
  selectedStudentData = []
}) {
  const { DataServer, user } = useAuth();
  const [MainError, setMainError] = useState();
  const [errors, setErrors] = useState({});
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const [selectedCourse, setSelectedCourse] = useState({
    id: null,
    name: null
  });
  const [generalLoadingState, setGeneralLoadingState] = useState(false);

  const Updater = (selectedCourse) => {
    setSelectedCourse({
      ...selectedCourse,
      id: selectedCourse.id,
      name: selectedCourse.name
    });
  };

  //   const [errors, setErrors] = useState({});
  useEffect(() => {
    const newErrors = {};

    // Validate name
    if (!selectedCourse.name) {
      newErrors.name = "Course name is required.";
    } else {
      newErrors.name = ""; // Clear the error if valid
    }

    // Validate id
    if (!selectedCourse.id) {
      newErrors.id = "Course ID is required.";
    } else {
      newErrors.id = ""; // Clear the error if valid
    }

    setErrors(newErrors);
  }, [selectedCourse]);

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  const CourseFailed = async () => {
    console.log("clicked failed");
    if (Object.keys(course).length > 0) {
      console.log(selectedStudent);
      console.log(selectedStudentData);
      setGeneralLoadingState(true);
      const failedResponse = await axios.put(`${DataServer}/course/failed`, {
        course,
        selectedStudent: selectedStudent,
        selectedStudentData: selectedStudentData,
        creator: { creatorId: user.$id, creatorName: user.name },
        academicYear: GetCurrentAcademicYear()
      });
      console.log(failedResponse);
      setGeneralLoadingState(false);
      mainRefresher();
      onClose();
    } else {
      setMainError("No Course Details is given...");
      setTimeout(() => {
        onclose();
      }, 3000);
    }
  };

  const CoursePassed = async () => {
    console.log("clicked passed");
    if (errors.id === "" && errors.name === "") {
      console.log(selectedStudent);
      console.log(selectedStudentData);
      setGeneralLoadingState(true);
      const passedResponse = await axios.put(`${DataServer}/course/passed`, {
        course: selectedCourse,
        selectedStudent: selectedStudent,
        selectedStudentData: selectedStudentData,
        creator: { creatorId: user.$id, creatorName: user.name },
        academicYear: GetCurrentAcademicYear()
      });
      console.log(passedResponse);
      setGeneralLoadingState(false);
      mainRefresher();
      onClose();
    } else {
      setMainError("No Course Details is given...");
      setTimeout(() => {
        onclose();
      }, 3000);
    }
  };

  return (
    <>
      {MainError && <Error errorMessage={MainError} reset={setMainError} />}
      <Button
        onPress={onOpen}
        color={
          type === "Passed"
            ? "success"
            : type === "Failed"
            ? "danger"
            : "secondary"
        }
      >
        {type} Course Completion
      </Button>
      <Modal
        size={type === "Passed" ? "xl" : "lg"}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                {type === "Passed"
                  ? ` You have selected ${selectedStudent.length}
                ${
                  selectedStudent.length > 1 ? "students" : "student"
                } to complete this course.`
                  : `Confirmation`}
              </ModalHeader>
              <ModalBody>
                {type === "Passed" ? (
                  <Completed
                    users={selectedStudent}
                    loadingstate={setGeneralLoadingState}
                    updateParent={Updater}
                  />
                ) : type === "Failed" ? (
                  <Failed users={selectedStudent} />
                ) : (
                  "Something went wrong"
                )}
              </ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  variant="light"
                  className=" rounded-xl"
                  onPress={onClose}
                >
                  Cancel
                </Button>
                <Button
                  isDisabled={
                    generalLoadingState ||
                    (type === "Passed" &&
                      (errors.name !== "" || errors.id !== ""))
                  }
                  color={type === "Passed" ? "secondary" : "warning"}
                  className=" rounded-xl"
                  onPress={async () => {
                    if (type === "Passed") {
                      await CoursePassed();
                      setSelectedCourse({
                        id: null,
                        name: null
                      });
                    } else if (type === "Failed") {
                      await CourseFailed();
                      setSelectedCourse({
                        id: null,
                        name: null
                      });
                    }
                  }}
                >
                  {generalLoadingState ? (
                    <span className=" flex gap-2">
                      <Spinner color="secondary" size="sm" />
                      <p>loading...</p>
                    </span>
                  ) : type === "Passed" ? (
                    "Move Up"
                  ) : (
                    "Fail"
                  )}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
