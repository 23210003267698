import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure
} from "@nextui-org/react";

export default function ForgotPassword() {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  return (
    <>
      <a
        className="text-sm text-secondary decoration-2 hover:underline font-medium dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
        onClick={onOpen}
      >
        Forgot password?
      </a>
      <Modal
        className=" font-poppins"
        isOpen={isOpen}
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 pb-0">
                Forgot Password
              </ModalHeader>
              <ModalBody>
                <p className=" text-md">
                  It looks like you’ve forgotten your password. Please contact
                  the registrar for assistance in resetting your password.
                </p>
              </ModalBody>
              <ModalFooter className=" pt-0">
                <Button color="danger" variant="flat" onPress={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
