import { Spacer } from "@nextui-org/react";
import StudentTable from "./RStudentTable";
import Statistics from "./D_Statistics";
import Announcement from "./EmployeeAnnouncement";

export default function Registrar_Dash() {
  return (
    <div className="flex flex-col flex-1 w-full h-full overflow-hidden gap-1">
      <Statistics />
      <div className="flex-1 overflow-auto w-full ">
        <div className="flex flex-col md:flex-row md:h-full h-full w-full">
          <StudentTable />
          <Spacer />
          <Announcement />
        </div>
      </div>
    </div>
  );
}
