import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure
} from "@nextui-org/react";

import AssignTeachers from "./actions/AssignTeachers";
import { useEffect, useState } from "react";
import CreateSchedules from "./actions/CreateSchedules";
import Cancelation from "../../../Components/modals/Cancel";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FinalCheck from "./components/FInalCheck";

export default function GenerateSchedule() {
  const { dept } = useParams();

  const [CreateSchedule, setCreateSchedule] = useState(false);
  const [isNextable, setIsNextable] = useState(false);
  const [isFinishable, setIsFinishable] = useState(false);
  const [ConfirmedAssignedTeachers, setConfirmedAssignedTeachers] = useState(
    []
  );
  const [ConfirmedSchedules, setConfirmedSchedules] = useState([]);
  const [step, setStep] = useState(0);

  useEffect(() => {

  }, [step]);

  const navigate = useNavigate();

  const location = useLocation();
  const { data } = location.state || {}; // Ensure that data is defined
  const [configs, setconfigs] = useState(location.state);

  useEffect(() => {
    
  }, [configs]);

  useEffect(() => {
    if (isNextable && step === 1) {
      setCreateSchedule(true);
    } else {
      setCreateSchedule(false);
    }
  }, [step, isNextable]);

  //used for cancellation
  const nav = () => {
    navigate(`/user/employee/manage/schedules`);
  };
  return (
    // <!-- Stepper -->
    <div className=" w-full h-full relative">
      <div
        data-hs-stepper='{
          "currentIndex": 1
        }'
        className="flex flex-col pb-4 relative "
      >
        {/* <!-- Stepper Nav --> */}
        <ul
          id="top"
          className=" items-center flex-initial top-0 flex flex-row gap-x-2 bg-[#ededed]/.2 rounded-lg p-2 py-4"
        >
          <li
            className="flex items-center gap-x-2 shrink basis-0 flex-1 group active"
            data-hs-stepper-nav-item='{
              "index": 1
              
            }'
          >
            <span className="min-w-7 min-h-7 group inline-flex items-center text-xs align-middle">
              <span className="size-7 flex justify-center items-center flex-shrink-0 bg-gray-100 font-medium text-gray-800 rounded-full group-focus:bg-gray-200 hs-stepper-active:bg-secondary-400 hs-stepper-active:text-white hs-stepper-success:bg-secondary-400 hs-stepper-success:text-white hs-stepper-completed:bg-teal-500 hs-stepper-completed:group-focus:bg-teal-600 dark:bg-neutral-700 dark:text-white dark:group-focus:bg-gray-600 dark:hs-stepper-active:bg-blue-500 dark:hs-stepper-success:bg-blue-500 dark:hs-stepper-completed:bg-teal-500 dark:hs-stepper-completed:group-focus:bg-teal-600">
                <span className="hs-stepper-success:hidden hs-stepper-completed:hidden">
                  1
                </span>
                <svg
                  className="hidden flex-shrink-0 size-3 hs-stepper-success:block"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
              </span>
              <span className="ms-2 text-sm font-medium text-gray-800 dark:text-neutral-200">
                Assign Teachers to Subject
              </span>
            </span>
            <div className="w-full h-px flex-1 bg-gray-200 group-last:hidden hs-stepper-success:bg-blue-600 hs-stepper-completed:bg-teal-600 dark:bg-neutral-700 dark:hs-stepper-success:bg-blue-600 dark:hs-stepper-completed:bg-teal-600"></div>
          </li>

          <li
            className="flex items-center gap-x-2 shrink basis-0 flex-1 group active"
            data-hs-stepper-nav-item='{
              "index": 2
            }'
          >
            <span className="min-w-7 min-h-7 group inline-flex items-center text-xs align-middle">
              <span className="size-7 flex justify-center items-center flex-shrink-0 bg-gray-100 font-medium text-gray-800 rounded-full group-focus:bg-gray-200 hs-stepper-active:bg-secondary-400 hs-stepper-active:text-white hs-stepper-success:bg-secondary-400 hs-stepper-success:text-white hs-stepper-completed:bg-teal-500 hs-stepper-completed:group-focus:bg-teal-600 dark:bg-neutral-700 dark:text-white dark:group-focus:bg-gray-600 dark:hs-stepper-active:bg-blue-500 dark:hs-stepper-success:bg-blue-500 dark:hs-stepper-completed:bg-teal-500 dark:hs-stepper-completed:group-focus:bg-teal-600">
                <span className="hs-stepper-success:hidden hs-stepper-completed:hidden">
                  2
                </span>
                <svg
                  className="hidden flex-shrink-0 size-3 hs-stepper-success:block"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
              </span>
              <span className="ms-2 text-sm font-medium text-gray-800 dark:text-neutral-200">
                Create Schedule
              </span>
            </span>
            <div className="w-full h-px flex-1 bg-gray-200 group-last:hidden hs-stepper-success:bg-blue-600 hs-stepper-completed:bg-teal-600 dark:bg-neutral-700 dark:hs-stepper-success:bg-blue-600 dark:hs-stepper-completed:bg-teal-600"></div>
          </li>

          {/* <!-- End Item --> */}
        </ul>
        {/* <!-- End Stepper Nav --> */}

        {/* <!-- Stepper Content --> */}
        <div className="flex-1">
          {/* <!-- First Content --> */}
          <div
            data-hs-stepper-content-item='{
              "index": 1,
              "isCompleted": true
            }'
            className="success"
            style={{ display: "none" }}
          >
            <div className="p-4 flex justify-center items-center ">
              <AssignTeachers
                confirmTeachers={setConfirmedAssignedTeachers}
                isNextable={setIsNextable}
                currentStep={step}
                Configurations={configs}
              />
            </div>
          </div>
          {/* <!-- End First Content --> */}

          {/* <!-- First Content --> */}
          <div
            data-hs-stepper-content-item='{
              "index": 2
            }'
            className="active"
          >
            <div className="p-4 flex justify-center items-center ">
              <CreateSchedules
                isCreateSched={CreateSchedule}
                AssignedTeachers={ConfirmedAssignedTeachers}
                Configurations={{
                  ...configs?.scheduleConfig,
                  isAdvisorPriority: configs?.isAdvisorPriority,
                  advisors: configs?.advisors,
                  advisorySubject: configs?.advisorySubject
                }}
                isFinishable={setIsFinishable}
                confirmSchedule={setConfirmedSchedules}
                currentStep={step}
              />
            </div>
          </div>
          {/* <!-- End First Content --> */}

          {/* <!-- Final Content --> */}
          <div
            data-hs-stepper-content-item='{
              "isFinal": true
            }'
            style={{ display: "none" }}
          >
            <div className="p-4   flex justify-center items-center   rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
              <FinalCheck
                AssignedTeachers={ConfirmedAssignedTeachers}
                ValidSchedules={ConfirmedSchedules}
                currentStep={step}
                academicYear={
                  configs?.scheduleConfig?.ScheduleConfigs.academicYear
                }
                department={dept}
              />
            </div>
          </div>
          {/* <!-- End Final Content --> */}

          {/* <!-- Button Group --> */}
          <div className="mt-5 flex justify-between items-center gap-x-2">
            {step === 0 && <Cancelation navigate={nav} />}
            <button
              type="button"
              className={` ${
                step === 0 && "hidden"
              } py-2 px-3 inline-flex items-center gap-x-1 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none`}
              data-hs-stepper-back-btn=""
              onClick={() => {
                setStep(step - 1);
              }}
            >
              <svg
                className="flex-shrink-0 size-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="m15 18-6-6 6-6"></path>
              </svg>
              Back
            </button>
            <a
              id="topclick"
              href="#top"
              className="font-medium hidden hover:text-[#FFDE59] cursor-pointer dark:text-gray-400 dark:hover:text-gray-500 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
            >
              top
            </a>
            <button
              type="button"
              className="py-2 bg-secondary-500 px-3 inline-flex items-center gap-x-1 text-sm font-semibold rounded-lg border border-transparent  text-white hover:bg-secondary-400 disabled:opacity-50 disabled:pointer-events-none"
              data-hs-stepper-next-btn=""
              disabled={!isNextable}
              onClick={() => {
                setStep(step + 1);
                document.querySelector("#topclick").click();
                setCreateSchedule(true);
              }}
            >
              Next
              <svg
                className="flex-shrink-0 size-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="m9 18 6-6-6-6"></path>
              </svg>
            </button>

            <SaveVerify />

            <button
              id="FINISHBTN"
              type="button"
              onClick={() => {
                setStep(step + 1);
              }}
              className="py-2 px-3 bg-secondary-500 hidden inline-flex items-center gap-x-1 text-sm font-semibold rounded-lg border border-transparent  text-white hover:bg-secondary-400 disabled:opacity-50 disabled:pointer-events-none"
              data-hs-stepper-finish-btn=""
              style={{ display: "none" }}
            >
              Finish
            </button>
          </div>
          {/* <!-- End Button Group --> */}
        </div>
        {/* <!-- End Stepper Content --> */}
      </div>
    </div>

    // <!-- End Stepper -->
  );

  function SaveVerify() {
    const { isOpen, onOpen, onOpenChange } = useDisclosure();

    return (
      <>
        <button
          type="button"
          disabled={!isFinishable}
          onClick={onOpen}
          className={` ${
            step === 1 ? "inline-flex" : "hidden"
          } py-2 px-3 bg-secondary-500 items-center gap-x-1 text-sm font-semibold rounded-lg border border-transparent  text-white hover:bg-secondary-400 disabled:opacity-50 disabled:pointer-events-none`}
        >
          Finish
        </button>
        <Modal
          isOpen={isOpen}
          className=" font-poppins "
          onOpenChange={onOpenChange}
        >
          <ModalContent>
            {(onClose) => (
              <>
                <ModalHeader className="flex flex-col gap-1 pb-0">
                  Save Schedules?
                </ModalHeader>
                <ModalBody>
                  <p>Are All teachers, and schedules satisfy you?</p>
                </ModalBody>
                <ModalFooter className=" pt-0">
                  <Button color="danger" variant="light" onPress={onClose}>
                    Cancel
                  </Button>
                  <Button
                    color="secondary"
                    onPress={() => {
                      document.querySelector("#FINISHBTN").click();
                      setStep(step + 1);
                      onClose();
                    }}
                  >
                    Save Schedule
                  </Button>
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>
      </>
    );
  }
}
