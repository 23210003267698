import AddPrefferedSubject from "../action/AddPrefferedSubject";
import PrefferdSubjects from "./render/PrefferedSubjects";

export default function PreferredSubjectCanvas() {
  return (
    <>
      <button
        type="button"
        className="ml-2 bg-secondary-400 py-2 px-4  inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent  text-white hover:bg-secondary-300 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
        data-hs-overlay="#hs-overlay-right"
      >
        Preffered Subjects
      </button>

      <div
        id="hs-overlay-right"
        className="hs-overlay bg-[#ededed] border-0 hs-overlay-open:translate-x-0 hidden translate-x-full fixed top-0 end-0 transition-all duration-300 transform h-full max-w-sm w-full z-40  dark:bg-gray-800 dark:border-gray-700"
        tabindex="-1"
      >
        <div className="flex w-full h-full relative flex-col ">
          <div className="flex flex-initial bg-[#bf99f0] justify-between items-center py-6 px-4 border-b dark:border-gray-700">
            <span className="font-semibold text-lg text-gray-800 dark:text-white">
              Preffered Subjects
            </span>
            <button
              type="button"
              className="flex justify-center items-center size-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-[#b48aeb] disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
              data-hs-overlay="#hs-overlay-right"
            >
              <span className="sr-only">Close modal</span>
              <svg
                className="flex-shrink-0 size-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M18 6 6 18" />
                <path d="m6 6 12 12" />
              </svg>
            </button>
          </div>

          <div className="p-4 h-full  overflow-x-hidden overflow-y-auto  [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-slate-700  dark:[&::-webkit-scrollbar-thumb]:bg-slate-500  flex-1">
            <PrefferdSubjects />
          </div>
        </div>
      </div>
    </>
  );
}
