import TableNoWrapper from "../../../../../Components/Table/TableNoWrapper";
import Filter from "../../../UserManagment/components/CoursenSectionFilter";
import Main from "../../../../Layout/Main";
import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Spinner,
  AccordionItem,
  Skeleton
} from "@nextui-org/react";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import axios from "axios";
import SelectCourse from "../../../../../Components/AssignTeacher/selectCourse";
import useRealtimeSubscription from "../../../../../utils/AppwriteConfig/RealtimeServices";
import SectionInSubject from "./SectionInSubject";
import AssignTeacher from "../../actions/AssignTeacher";

export default function SubjectsInCourse({
  courseId,
  courseName,
  courselevel,
  updatesubjects,
  updateCourseSubjects,
  loadingstate,
  sem,
  refresher,
  dept
}) {
  /**
   *
   * THIS COMPONENT IS USED TO GET ALL SUBJECTS IN A COURSE
   *
   */
  const { user, DataServer } = useAuth();

  const [isLoading, setisLoading] = useState(false);
  const [isRefresh, setisRefresh] = useState(true);
  const [MainError, setMainError] = useState();

  const [selectedKeys, setSelectedKeys] = React.useState(new Set([]));
  const [Course, setCourse] = useState();
  const [Subjects, setSubjects] = useState([]);
  const [courseSubjects, setCourseSubjects] = useState([]);
  const [AssignedTeacher, setAssignedTeacher] = useState({});
  const [CNames, setNames] = useState({});

  //Used to get all the names need for UI viewing
  useEffect(() => {
    setNames((prevName) => ({
      ...prevName,
      course: courseName
    }));
  }, [courseName]);

  async function GetSubjectsinSpecificCourse() {
    console.log("Called All Subjects in a course on server");
    return await axios.get(`${DataServer}/course/subjects`, {
      params: { CourseId: courseId }
    });
  }
  async function GetSubjectsinSpecificCourseNSem() {
    console.log("Called Sem Subjects on Server");
    return await axios.get(`${DataServer}/course/${sem}/subjects`, {
      params: { CourseId: courseId }
    });
  }

  useEffect(() => {
    if (loadingstate) loadingstate(isLoading);
  }, [isLoading]);

  useEffect(() => {
    async function GetCoursenSubject() {
      try {
        setisLoading(true);
        // Check if Course is not undefined or null
        let subjects =
          sem && parseInt(sem) > 0
            ? await GetSubjectsinSpecificCourseNSem()
            : await GetSubjectsinSpecificCourse();

        if (sem && parseInt(sem) > 0) {
          setSubjects(subjects.data.semSubj);
          setCourseSubjects(subjects.data.courseSubj);
        } else {
          console.log(subjects);
          setSubjects(subjects.data);
        }
      } catch (e) {
        setMainError(e.message);
      } finally {
        setisLoading(false);
      }
    }
    GetCoursenSubject();
  }, [courseId, isRefresh, sem, refresher]);

  //Used to update the subject when realtime updates
  useEffect(() => {
    updatesubjects(Subjects);
    if (updateCourseSubjects) {
      updateCourseSubjects(courseSubjects);
    }
  }, [Subjects, courseSubjects]);

  const refreshSubjects = () => {
    setisRefresh(!isRefresh);
  };

  const handleRealtimeMessage = (message) => {
    if (
      message.changetype === "create" ||
      (message.changetype === "update" &&
        JSON.stringify(message.payload.subject) !== JSON.stringify(Subjects))
    ) {
      setSubjects(message.payload.subject);
    } else if (message.changetype === "update") {
      setSubjects((prevSubjects) => {
        const updatedSubjects = Subjects ? [...prevSubjects] : [];
        const subjectIndex = updatedSubjects.findIndex(
          (subject) => subject.$id === message.payload.$id
        );
        if (subjectIndex !== -1) {
          updatedSubjects[subjectIndex] = message.payload;
        }
        return updatedSubjects;
      });
    } else if (message.changetype === "delete") {
      setSubjects((prevSubjects) =>
        prevSubjects.filter((course) => course.$id !== message.payload.$id)
      );
    }
  };
  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_COURSE,
    handleRealtimeMessage
  );

  return (
    <div className=" w-full h-full flex flex-col ">
      {/* BODY */}
      <div className="flex-1 max-h-full py-2 relative overflow-auto">
        {!isLoading ? (
          <>
            {Subjects && Subjects.length > 0 ? (
              <Accordion
                variant="splitted"
                selectedKeys={selectedKeys}
                onSelectionChange={setSelectedKeys}
              >
                {Subjects &&
                  Subjects.length > 0 &&
                  Subjects.map((subject) => (
                    <AccordionItem
                      selectedKeys={selectedKeys}
                      onSelectionChange={setSelectedKeys}
                      className="bg-[#72539b] shadow-sm"
                      key={subject?.$id}
                      aria-label="Accordion 1"
                      title={
                        <span className=" pl-2 border-warning border-l-1">
                          {subject?.subjectName}
                        </span>
                      }
                    >
                      <SectionInSubject
                        key={subject.$id}
                        Course={courseId}
                        Subject={subject}
                        setselected={setAssignedTeacher}
                        AccordionKeys={setSelectedKeys}
                        setName={setNames}
                        subject={subject.subjectName}
                        department={dept}
                      />
                    </AccordionItem>
                  ))}
              </Accordion>
            ) : (
              <div className=" w-full h-full flex justify-center items-center">
                No Subjects yet
              </div>
            )}
          </>
        ) : (
          <div className=" space-y-2">
            <Skeleton className=" rounded-lg ">
              <div className=" w-full h-14  flex justify-center items-center"></div>
            </Skeleton>{" "}
            <Skeleton className=" rounded-lg ">
              <div className=" w-full h-14  flex justify-center items-center"></div>
            </Skeleton>{" "}
            <Skeleton className=" rounded-lg ">
              <div className=" w-full h-14  flex justify-center items-center"></div>
            </Skeleton>
          </div>
        )}

        {/* ADD ADD TEACHER MODAL HERE */}
        <AssignTeacher
          AssignedData={AssignedTeacher}
          updatedata={setAssignedTeacher}
          Names={CNames}
          AccordionKey={setSelectedKeys}
        />
      </div>
    </div>
  );
}
