import {
  Client,
  Account,
  Storage,
  Databases,
  Functions,
  Realtime,
  ID,
  Messaging
} from "appwrite";

let client = new Client();

client
  .setEndpoint(process.env.REACT_APP_ENDPOINT)
  .setProject(process.env.REACT_APP_PROJECT);

const storage = new Storage(client);
const databases = new Databases(client);
const functions = new Functions(client);
const messaging = new Messaging(client);
const id = ID.unique();
const account = new Account(client);

export { client, storage, id, account, functions, databases, messaging };
