import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Tabs,
  Tab,
  User,
  Card,
  Chip,
  CardBody,
  CardHeader,
  useDisclosure
} from "@nextui-org/react";
import TableNoWrapper from "../../../../Components/Table/TableNoWrapper";
import { useAuth } from "../../../../utils/AppwriteConfig/AuthContext";
import axios from "axios";

export default function AssignTeacher({
  AssignedData,
  Names,
  updatedata,
  AccordionKey
}) {
  const { server, user } = useAuth();

  const [isLoading, setisLoading] = useState(false);
  const [MainError, setMainError] = useState("");
  const { isOpen, onOpen, onClose, onOpenChange } = useDisclosure();

  const close = () => {
    updatedata({});
    AccordionKey(new Set([AssignedData.SubjectId]));
    onClose();
  };

  const open = () => {
    AccordionKey(new Set([]));
    onOpen();
  };

  console.log("ETOOsO", AssignedData);

  const [selected, setSelected] = React.useState("photos");
  const [Teacher, setTeacher] = useState({});

  const AssignTeachertosubject = async (e) => {
    try {
      const datas = {
        ...AssignedData,
        teacherid: Teacher.teacherId,
        userid: Teacher.$id,
        teachername: Teacher?.userCredentials?.Username,
        creator: user.$id,
        creatorname: user.name,
        subjectname: Names.subject,
        sectionname: Names.section,
        coursename: Names.course
      };

      setisLoading(true);
      const Assignteacher = await axios.get(
        `${server}/v1/subject/teacher/assign`,
        { params: datas }
      );

      close();
    } catch (e) {
      setMainError(e.message);
    } finally {
      setisLoading(false);
    }
  };

  return (
    <>
      <Button id="AssignTeacher" className="hidden" onPress={open}>
        Open Modal
      </Button>
      <Modal
        size="2xl"
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        onOpen={open}
        onClose={close}
        isDismissable={false}
        isKeyboardDismissDisabled={true}
      >
        <ModalContent className=" font-poppins">
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Assign Teacher
              </ModalHeader>
              <ModalBody>
                <div className="flex-initial">
                  <div className="w-full p-2 bg-slate-200 rounded-lg">
                    <label>Selected Teacher</label>
                    <div className="flex items-center justify-between">
                      <User
                        name={
                          Teacher?.userCredentials?.Username
                            ? Teacher?.userCredentials?.Username
                            : "Select A Teacher"
                        }
                        description={
                          Teacher !== null
                            ? Teacher?.userCredentials?.Email
                            : ""
                        }
                        avatarProps={{
                          src: "https://images.unsplash.com/broken"
                        }}
                      />
                      <div>
                        <span className="font-semibold">{Names.subject}</span>{" "}
                        {Names.course} | {Names.section}
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" flex-1 flex w-full flex-col">
                  <Tabs
                    color="secondary"
                    className=" overflow-auto max-h-200"
                    aria-label="Options"
                    selectedKey={selected}
                    onSelectionChange={setSelected}
                  >
                    <Tab
                      key="Available"
                      title={<div className="">Available Teacher </div>}
                      className="relative"
                    >
                      <Card onScroll={true}>
                        <CardBody className="max-h-[300px] p-1  overflow-auto">
                          <TableNoWrapper
                            updateteacher={setTeacher}
                            data={AssignedData}
                          />
                        </CardBody>
                      </Card>
                    </Tab>
                  </Tabs>
                </div>
              </ModalBody>
              <ModalFooter>
                {isLoading !== true && (
                  <Button color="danger" variant="light" onPress={close}>
                    Close
                  </Button>
                )}
                {isLoading ? (
                  <Button
                    isLoading
                    color="secondary"
                    spinner={
                      <svg
                        className="animate-spin h-5 w-5 text-current"
                        fill="none"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          fill="currentColor"
                        />
                      </svg>
                    }
                  >
                    Processing...
                  </Button>
                ) : (
                  <>
                    <Button
                      color="secondary"
                      isDisabled={!Object.keys(Teacher).length}
                      onPress={AssignTeachertosubject}
                    >
                      Assign to {Names.course} | {Names.section}
                    </Button>
                  </>
                )}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
