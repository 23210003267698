import React, { useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure
} from "@nextui-org/react";
import axios from "axios";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import Error from "../../../../../Components/ErrorIndicator";

export default function DeleteRoomConfirm({ refresher, data }) {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const { DataServer, user } = useAuth();
  const [isLoading, setisLoading] = useState(false);
  const [MainError, setMainError] = useState();

  const handledelete = async () => {
    // Check the updated state value after setting the errors

    if (true) {
      setisLoading(true);
      try {
        const response = await axios.delete(`${DataServer}/info/room/delete`, {
          params: {
            ...data,
            creator: user
          }
        });

        onClose();
        refresher && refresher();
      } catch (e) {
        setMainError(e.message);
      } finally {
        setisLoading(false);
      }
    } else {
      console.log("Errors found:");
      // Optionally, you can handle errors in UI or perform other actions
    }
  };

  return (
    <>
      {MainError && <Error errorMessage={MainError} reset={setMainError} />}
      <Button color="danger" isIconOnly variant="flat" onPress={onOpen}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className={`bi bi-trash `}
          viewBox="0 0 16 16"
        >
          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
          <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
        </svg>
      </Button>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        isDismissable={false}
        isKeyboardDismissDisabled={true}
        scrollBehavior="inside"
        backdrop="opaque"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col w-full max-w-full break-words gap-1 ">
                <div>Are you sure you want to delete {data.roomName} ?</div>
              </ModalHeader>
              <ModalBody>
                <p>Any changes made will not be saved and be discarded.</p>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Cancel
                </Button>
                {isLoading ? (
                  <Button
                    isLoading
                    color="secondary"
                    spinner={
                      <svg
                        className="animate-spin h-5 w-5 text-current"
                        fill="none"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          fill="currentColor"
                        />
                      </svg>
                    }
                  >
                    Loading
                  </Button>
                ) : (
                  <Button
                    color="secondary"
                    onClick={() => {
                      handledelete();
                    }}
                  >
                    Continue
                  </Button>
                )}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
