import axios from "axios";
import { useEffect, useState } from "react";
import { useAuth } from "../../../../utils/AppwriteConfig/AuthContext";
import { Skeleton } from "@nextui-org/react";

export default function TeacherStatistics({ setParent }) {
  const { DataServer } = useAuth();
  const [SHSisLoading, setSHSisLoading] = useState(false);
  const [PreisLoading, setPreisLoading] = useState(false);
  const [ElemisLoading, setElemisLoading] = useState(false);
  const [JHSisLoading, setJHSisLoading] = useState(false);
  const [MainError, setMainError] = useState("");

  const [SHSrefresher, setSHSRefresher] = useState(false);
  const [Prerefresher, setPreRefresher] = useState(false);
  const [Elemrefresher, setElemRefresher] = useState(false);
  const [JHSrefresher, setJHSRefresher] = useState(false);

  const [total, setTotal] = useState({ courseTime: 0, needed: 0, current: 0 });

  const [department, setDepartment] = useState({
    Pre: {
      Needed: 0,
      Total: 0,
      TotalCourse: 0
    },
    Elem: {
      Needed: 0,
      Total: 0,
      TotalCourse: 0
    },
    JHS: {
      Needed: 0,
      Total: 0,
      TotalCourse: 0
    },
    SHS: {
      Needed: 0,
      Total: 0,
      TotalCourse: 0
    }
  });

  useEffect(() => {
    // Calculate the sum for courseTime, needed, and current across all departments
    const courseTimeTotal = Object.values(department).reduce(
      (sum, dept) => sum + dept.TotalCourse,
      0
    );
    const neededTotal = Object.values(department).reduce(
      (sum, dept) => sum + dept.Needed,
      0
    );
    const currentTotal = Object.values(department).reduce(
      (sum, dept) => sum + dept.Total,
      0
    );

    // Update the total state with the new values
    setTotal({
      courseTime: courseTimeTotal,
      needed: neededTotal,
      current: currentTotal
    });
  }, [department]);

  useEffect(() => {
    setParent(
      total.current || 0,
      total.needed || 0,
      total.courseTime || 0,
      department
    );
  }, [total, department]);

  useEffect(() => {
    async function preDept() {
      try {
        setPreisLoading(true);
        const PreDeptResponse = await axios.get(
          `${DataServer}/info/Pre/teachers/total/0`
        );
        setDepartment((prevDepartment) => ({
          ...prevDepartment,
          Pre: {
            Total: PreDeptResponse.data.total,
            Needed: PreDeptResponse.data.needed,
            TotalCourse: PreDeptResponse.data.totalDeptTime + 4000
          }
        }));
      } catch (error) {
        setMainError(error.message);
      } finally {
        setPreisLoading(false);
      }
    }
    preDept();
  }, [Prerefresher]);

  useEffect(() => {
    async function ElemDept() {
      try {
        setElemisLoading(true);
        const ElemDeptResponse = await axios.get(
          `${DataServer}/info/Elem/teachers/total/0`
        );
        setDepartment((prevDepartment) => ({
          ...prevDepartment,
          Elem: {
            Total: ElemDeptResponse.data.total,
            Needed: ElemDeptResponse.data.needed,
            TotalCourse: ElemDeptResponse.data.totalDeptTime
          }
        }));
      } catch (error) {
        setMainError(error.message);
      } finally {
        setElemisLoading(false);
      }
    }
    ElemDept();
  }, [Elemrefresher]);

  useEffect(() => {
    async function JHSDept() {
      try {
        setJHSisLoading(true);
        const JHSDeptResponse = await axios.get(
          `${DataServer}/info/JHS/teachers/total/0`
        );
        setDepartment((prevDepartment) => ({
          ...prevDepartment,
          JHS: {
            Total: JHSDeptResponse.data.total,
            Needed: JHSDeptResponse.data.needed,
            TotalCourse: JHSDeptResponse.data.totalDeptTime
          }
        }));
      } catch (error) {
        setMainError(error.message);
      } finally {
        setJHSisLoading(false);
      }
    }
    JHSDept();
  }, [JHSrefresher]);

  useEffect(() => {
    async function SHSDept() {
      try {
        setSHSisLoading(true);
        const SHSDeptResponse = await axios.get(
          `${DataServer}/info/SHS/teachers/total/1`
        );
        setDepartment((prevDepartment) => ({
          ...prevDepartment,
          SHS: {
            Total: SHSDeptResponse.data.total,
            Needed: SHSDeptResponse.data.needed,
            TotalCourse: SHSDeptResponse.data.totalDeptTime
          }
        }));
      } catch (error) {
        setMainError(error.message);
      } finally {
        setSHSisLoading(false);
      }
    }
    SHSDept();
  }, [SHSrefresher]);

  return (
    <div class=" w-full">
      <div class="grid sm:grid-cols-4 border-y border-gray-200 dark:border-neutral-800">
        <div class="p-4 md:p-5 relative before:absolute before:top-0 before:start-0 before:w-full before:h-px sm:before:w-px sm:before:h-full before:bg-gray-200 before:first:bg-transparent dark:before:bg-neutral-800">
          <div>
            <div class="mt-3">
              <div class="flex items-center gap-x-2">
                <p class="text-base font-semibold uppercase tracking-wide text-secondary-600 dark:text-neutral-500">
                  Pre-School
                </p>
                <div class="hs-tooltip">
                  <div class="hs-tooltip-toggle">
                    <span
                      class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm dark:bg-neutral-700"
                      role="tooltip"
                    >
                      The number of daily users
                    </span>
                  </div>
                </div>
              </div>
              <div class="mb-2 mt-1 lg:flex lg:justify-between lg:items-center">
                <Skeleton className=" rounded-lg" isLoaded={!PreisLoading}>
                  <h3 class="text-xl sm:text-4xl font-semibold text-secondary-800 dark:text-neutral-200">
                    {department.Pre.Total} / {department.Pre.Needed}
                  </h3>
                </Skeleton>
              </div>{" "}
              {department.Pre.Total > department.Pre.Needed ? (
                <Exceed text={department.Pre.Total - department.Pre.Needed} />
              ) : department.Pre.Total + 1 === department.Pre.Needed ? (
                <Warning />
              ) : (
                department.Pre.Needed > department.Pre.Total && <Lacking />
              )}
            </div>
          </div>
        </div>
        <div class="p-4 md:p-5 relative before:absolute before:top-0 before:start-0 before:w-full before:h-px sm:before:w-px sm:before:h-full before:bg-gray-200 before:first:bg-transparent dark:before:bg-neutral-800">
          <div>
            <div class="mt-3">
              <p class="text-base font-semibold uppercase tracking-wide text-secondary-600 dark:text-neutral-500">
                Elementary
              </p>
              <div class="mb-2 mt-1 lg:flex lg:justify-between lg:items-center">
                <Skeleton className=" rounded-lg" isLoaded={!ElemisLoading}>
                  <h3 class="text-xl sm:text-4xl font-semibold text-secondary-800 dark:text-neutral-200">
                    {department.Elem.Total} / {department.Elem.Needed}
                  </h3>
                </Skeleton>
              </div>
              {department.Elem.Total > department.Elem.Needed ? (
                <Exceed text={department.Elem.Total - department.Elem.Needed} />
              ) : department.Elem.Total + 1 === department.Elem.Needed ? (
                <Warning />
              ) : (
                department.Elem.Needed > department.Elem.Total && <Lacking />
              )}
            </div>
          </div>
        </div>
        <div class="p-4 md:p-5 relative before:absolute before:top-0 before:start-0 before:w-full before:h-px sm:before:w-px sm:before:h-full before:bg-gray-200 before:first:bg-transparent dark:before:bg-neutral-800">
          <div>
            <div class="mt-3">
              <div class="flex items-center gap-x-2">
                <p class="text-base font-semibold uppercase tracking-wide text-secondary-600 dark:text-neutral-500">
                  Junior High-School
                </p>
              </div>
              <div class="mb-2 mt-1 lg:flex lg:justify-between lg:items-center">
                <Skeleton className=" rounded-lg" isLoaded={!JHSisLoading}>
                  <h3 class="text-xl sm:text-4xl font-semibold text-secondary-800 dark:text-neutral-200">
                    {department.JHS.Total} / {department.JHS.Needed}
                  </h3>
                </Skeleton>
              </div>
              {department.JHS.Total > department.JHS.Needed ? (
                <Exceed text={department.JHS.Total - department.JHS.Needed} />
              ) : department.JHS.Total + 1 === department.JHS.Needed ? (
                <Warning />
              ) : (
                department.JHS.Needed > department.JHS.Total && <Lacking />
              )}
            </div>
          </div>
        </div>
        <div class="p-4 md:p-5 relative before:absolute before:top-0 before:start-0 before:w-full before:h-px sm:before:w-px sm:before:h-full before:bg-gray-200 before:first:bg-transparent dark:before:bg-neutral-800">
          <div>
            <div class="mt-3">
              <p class="text-base font-semibold uppercase tracking-wide text-secondary-600 dark:text-neutral-500">
                Senior High-School
              </p>
              <div class="mb-2 mt-1 lg:flex lg:justify-between lg:items-center">
                <Skeleton className=" rounded-lg" isLoaded={!SHSisLoading}>
                  <h3 class="text-xl sm:text-4xl font-semibold text-secondary-800 dark:text-neutral-200">
                    {department.SHS.Total} / {department.SHS.Needed}
                  </h3>
                </Skeleton>
              </div>
              {department.SHS.Total > department.SHS.Needed ? (
                <Exceed text={department.SHS.Total - department.SHS.Needed} />
              ) : department.SHS.Total + 1 === department.SHS.Needed ? (
                <Warning />
              ) : (
                department.SHS.Needed > department.SHS.Total && <Lacking />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Exceed({ text }) {
  return (
    <a
      class="mt-1 lg:mt-0 min-h-[24px] inline-flex items-center gap-x-1 py-0.5 px-2 text-gray-800 bg-success-400/70  cursor-default focus:outline-none focus:bg-success-500 rounded-md dark:bg-neutral-700 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800 dark:text-neutral-200"
      href="#"
    >
      <svg
        class="inline-block size-3 self-center"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        viewBox="0 0 16 16"
      >
        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
      </svg>
      <span class="inline-block text-xs font-semibold">{text}</span>
    </a>
  );
}

function Warning({ text }) {
  return (
    <a
      class="mt-1 lg:mt-0 min-h-[24px] inline-flex items-center gap-x-1 py-0.5 px-2 text-orange-700 bg-orange-200/70 hover:bg-orange-200 focus:outline-none focus:bg-orange-200 rounded-md dark:bg-orange-700 dark:text-orange-100 dark:hover:bg-orange-800 dark:focus:bg-orange-800"
      href="#"
    >
      <svg
        class="inline-block size-3 self-center"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        viewBox="0 0 16 16"
      >
        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
      </svg>
      <span class="inline-block text-xs font-semibold"> warning</span>
    </a>
  );
}

function Lacking({ text }) {
  return (
    <a
      class="mt-1 lg:mt-0 min-h-[24px] inline-flex items-center gap-x-1 py-0.5 px-2 text-red-700 bg-red-200/70 hover:bg-red-200 focus:outline-none focus:bg-red-200 rounded-md dark:bg-red-700 dark:text-red-100 dark:hover:bg-red-800 dark:focus:bg-red-800"
      href="#"
    >
      <svg
        class="inline-block size-3 self-center"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        viewBox="0 0 16 16"
      >
        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
      </svg>
      <span class="inline-block text-xs font-semibold">Need attention</span>
    </a>
  );
}
