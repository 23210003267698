import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Spinner,
  Skeleton
} from "@nextui-org/react";
import axios from "axios";
import { useAuth } from "../../../../utils/AppwriteConfig/AuthContext";
import DocumentRender from "./DocumentRender";
import NewFile from "../actions/NewFile";
import useRealtimeSubscription from "../../../../utils/AppwriteConfig/RealtimeServices";

export default function Viewdocument({ user, rerenderparent }) {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const { DataServer } = useAuth();
  const [isLoading, setisLoading] = useState(true);
  const [isrefresh, setisrefresh] = useState(true);
  const [isrefreshData, setisrefreshData] = useState(true);
  const [selectedDocumentType, setSelectedDocumentType] = useState();
  const [DocumentTypes, setDocumentTypes] = useState([]);

  useEffect(() => {
    setisLoading(true);
    async function getDocumentTypes() {
      try {
        const response = await axios.get(
          `${DataServer}/user/document/type`,
          {
            params: {
              role: user.role ? user.role : user?.student?.userCredentials?.role
            }
          }
        );
        setDocumentTypes(response.data.documents);
      } catch (error) {
        // Handle any errors
        console.error(error);
      } finally {
        setisLoading(false);
      }
    }
    if (user) {
      getDocumentTypes();
    }
  }, [user, isrefresh]);

  const refreshdocument = (dataonly) => {
    if (dataonly) {
      setisrefreshData(!isrefreshData);
    } else {
      setisrefresh(!isrefresh);
    }
  };

  useEffect(() => {
    if (DocumentTypes) setSelectedDocumentType(DocumentTypes[0]);
  }, [DocumentTypes]);

  return (
    <>
      <button className="hidden" id="viewdocs" onClick={onOpen}>
        Open Modal
      </button>
      <Modal
        size="3xl"
        className="min-h-[300px] font-poppins"
        isOpen={isOpen}
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex items-center space-x-2 pb-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill={user.deleteFlag === null ? "#F44336" : "#4CAF50"}
                  class="bi bi-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <circle cx="8" cy="8" r="8" />
                </svg>
                <div className="text-xl flex flex-col">
                  <span>{user.Username}</span>{" "}
                  <span className="pl-2 text-slate-400 font-normal text-medium mt-[-8px]">
                    {user.role} | {user.keyword.split(" ")[2]}
                  </span>
                </div>
              </ModalHeader>
              <ModalBody className="flex-1 px-3">
                <div className="flex-1 flex w-full h-full flex-col sm:flex-row  overflow-auto">
                  <Skeleton className="rounded-lg pl-4" isLoaded={!isLoading}>
                    <div className="flex-initial  overflow-auto">
                      <span className="text-lg text-left font-semibold">
                        Documents
                      </span>
                      <div className="  flex-1 max-h-[310px] overflow-auto">
                        <div className="  whitespace-nowrap ">
                          {DocumentTypes &&
                            DocumentTypes.map((doc) => (
                              <button
                                key={doc?.$id} // Using optional chaining here to guard against undefined doc
                                type="button"
                                className={`hs-tab-active:border sm:w-full  w-auto  ${
                                  doc?.$id === selectedDocumentType?.$id
                                    ? "border-secondary-500 hs-tab-active:text-secondary-600 dark:hs-tab-active:text-secondary-600"
                                    : "border-transparent text-gray-500 hover:text-secondary-600 focus:text-secondary-600 dark:text-gray-400 dark:hover:text-blue-500"
                                } py-1 pe-4 text-left  items-center gap-x-2 border-b-2 sm:border-b-0 sm:border-e-2 text-sm inline-flex sm:block focus:outline-none disabled:opacity-50 disabled:pointer-events-none`}
                                onClick={() => setSelectedDocumentType(doc)}
                              >
                                {doc?.type}{" "}
                                {/* Using optional chaining here to guard against undefined doc */}
                              </button>
                            ))}
                        </div>
                      </div>
                    </div>
                  </Skeleton>

                  <div className="flex-1  p-3">
                    {selectedDocumentType ? (
                      <DocumentRender
                        currentUser={user}
                        Cdocument={selectedDocumentType}
                        refresher={refreshdocument}
                        isRefresh={isrefreshData}
                      />
                    ) : (
                      <div className="text-lg font-semibold bg-slate-200 rounded-lg  flex justify-center items-center w-full h-full">
                        Select a Document to view
                      </div>
                    )}
                  </div>
                </div>
              </ModalBody>
              {!isLoading && (
                <ModalFooter className="pt-0">
                  <Button color="danger" variant="light" onPress={onClose}>
                    Close
                  </Button>
                  <NewFile
                    type={"document"}
                    CurrentDoctype={selectedDocumentType?.$id}
                    currentuser={user}
                    docTypes={DocumentTypes}
                    refreshparent={refreshdocument}
                  />
                  {/* <Button color="primary" onPress={onClose}>
                    Action
                  </Button> */}
                </ModalFooter>
              )}
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
