import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import axios from "axios";
import { Card, Button, Spinner } from "@nextui-org/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

// import required modules
import { FreeMode, Pagination } from "swiper/modules";
import { useAuth } from "../../../utils/AppwriteConfig/AuthContext";
import { InfoCard } from "../components/Cardcontainer";
import useRealtimeSubscription from "../../../utils/AppwriteConfig/RealtimeServices";

export default function Picture({ container, specific = false }) {
  const { user, server, setisContainerLoading } = useAuth();

  const [isLoading, setisLoading] = useState(false);
  const [MainError, setMainError] = useState();
  const [isRefresh, setisRefresh] = useState(true);

  const [Items, setItems] = useState([]);
  const [slidesPerView, setSlidesPerView] = useState(3);

  useEffect(() => {
    async function GetItems() {
      try {
        setisLoading(true);
        const getItems = await axios.get(`${server}/v1/home/container/items`, {
          params: { id: container.$id },
        });
        setItems(getItems.data.documents);
      } catch (e) {
        setMainError(e.message);
      } finally {
        setisLoading(false);
      }
    }
    GetItems();
  }, [container, isRefresh]);

  useEffect(() => {
    setisContainerLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth;
      if (width >= 450) {
        setSlidesPerView(2);
      } else {
        setSlidesPerView(1);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleRealtimechanges = (changes) => {

    const changedata = Items.findIndex(
      (item) => item.$id === changes.payload.$id
    );
    if (
      (changes.changetype === "create" || changes.changetype === "delete") &&
      !specific &&
      changes.channels &&
      changes.channels.includes(
        "databases.65dfbcb25e60c6433044.collections.661a715eecb168cc611d.documents"
      )
    ) {
      setisRefresh(!isRefresh);
    }
    if (changes.changetype === "create") {
      setItems((prevItems) => [...prevItems, changes.payload]);
    } else if (changes.changetype === "delete") {
      setItems((prevItems) =>
        prevItems.filter((item) => item.$id !== changes.payload.$id)
      );
    }
  };

  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_CONTAINER_ITEM,
    handleRealtimechanges
  );

  return (
    <div className="pt-6 p-0 flex flex-col w-full text-xl font-bold ">
      <span className="p-6 text-xl font-bold">
        {container?.isActive && container?.title}
      </span>
      <div className="py-4 bg-[#ededed] rounded-lg">
        {isLoading ? (
          <div className=" bg-slate-200 h-[300px] w-full p-4 flex justify-center items-center">
            <Spinner size="xl">Loading...</Spinner>
          </div>
        ) : (
          <Swiper
            slidesPerView={slidesPerView}
            spaceBetween={5}
            // freeMode={true}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]} //freemode removed
            className="mySwiper h-auto  p-4 "
          >
            {Items && Items.length > 0 ? (
              Items.map((item) => (
                <SwiperSlide key={item.$id} className=" h-full">
                  <InfoCard
                    key={item.$id}
                    img={item.itemPicture}
                    title={item.itemTitle}
                    isimgonly={true}
                  />
                </SwiperSlide>
              ))
            ) : (
              <>
                <SwiperSlide>
                  <div className="flex flex-1 h-full">
                    <a
                      className="flex flex-1 m-4 h-full flex-col group bg-white border shadow-sm rounded-xl overflow-hidden hover:shadow-lg transition dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7]"
                      href="#"
                    >
                      <div className="relative pt-[50%] sm:pt-[60%] lg:pt-[60%] rounded-t-xl overflow-hidden">
                        <img
                          className="absolute top-0 start-0 object-cover w-full h-full group-hover:scale-105 transition-transform duration-500 ease-in-out rounded-t-xl"
                          src={require("../../../Img/LandingPage/bsaintro5.jpg")}
                          alt="Image Description"
                        />
                        <span className=" bottom-4 left-2 z-50 absolute text-purple-500">
                          Teamwork Triumphs
                        </span>
                      </div>
                    </a>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-1 h-full">
                    <a
                      className="flex flex-1 m-4 h-full flex-col group bg-white border shadow-sm rounded-xl overflow-hidden hover:shadow-lg transition dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7]"
                      href="#"
                    >
                      <div className="relative pt-[50%] sm:pt-[60%] lg:pt-[60%] rounded-t-xl overflow-hidden">
                        <img
                          className="absolute top-0 start-0 object-cover w-full h-full group-hover:scale-105 transition-transform duration-500 ease-in-out rounded-t-xl"
                          src={require("../../../Img/LandingPage/bsaintro5.jpg")}
                          alt="Image Description"
                        />
                        <span className=" bottom-4 left-2 z-50 absolute text-purple-500">
                          Harmonies of the Heart
                        </span>
                      </div>
                    </a>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-1 h-full">
                    <a
                      className="flex flex-1 m-4 h-full flex-col group bg-white border shadow-sm rounded-xl overflow-hidden hover:shadow-lg transition dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7]"
                      href="#"
                    >
                      <div className="relative pt-[50%] sm:pt-[60%] lg:pt-[60%] rounded-t-xl overflow-hidden">
                        <img
                          className="absolute top-0 start-0 object-cover w-full h-full group-hover:scale-105 transition-transform duration-500 ease-in-out rounded-t-xl"
                          src={require("../../../Img/LandingPage/bsaintro6.jpg")}
                          alt="Image Description"
                        />
                        <span className=" bottom-4 left-2 z-50 absolute text-purple-500">
                          Capturing Creativity
                        </span>
                      </div>
                    </a>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-1 h-full">
                    <a
                      className="flex flex-1 m-4 h-full flex-col group bg-white border shadow-sm rounded-xl overflow-hidden hover:shadow-lg transition dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7]"
                      href="#"
                    >
                      <div className="relative pt-[50%] sm:pt-[60%] lg:pt-[60%] rounded-t-xl overflow-hidden">
                        <img
                          className="absolute top-0 start-0 object-cover w-full h-full group-hover:scale-105 transition-transform duration-500 ease-in-out rounded-t-xl"
                          src={require("../../../Img/LandingPage/bsaintro7.jpg")}
                          alt="Image Description"
                        />
                        <span className=" bottom-4 left-2 z-50 absolute text-purple-500">
                          Team Sports
                        </span>
                      </div>
                    </a>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-1 h-full">
                    <a
                      className="flex flex-1 m-4 h-full flex-col group bg-white border shadow-sm rounded-xl overflow-hidden hover:shadow-lg transition dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7]"
                      href="#"
                    >
                      <div className="relative pt-[50%] sm:pt-[60%] lg:pt-[60%] rounded-t-xl overflow-hidden">
                        <span className=" bottom-4 left-2 z-50 absolute text-purple-500">
                          Enjoy vibes
                        </span>
                        <img
                          className="absolute top-0 start-0 object-cover w-full h-full group-hover:scale-105 transition-transform duration-500 ease-in-out rounded-t-xl"
                          src={require("../../../Img/LandingPage/bsaintro8.jpg")}
                          alt="Image Description"
                        />
                      </div>
                    </a>
                  </div>
                </SwiperSlide>
              </>
            )}
          </Swiper>
        )}
      </div>
    </div>
  );
}
