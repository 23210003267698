import { useAuth } from "../../utils/AppwriteConfig/AuthContext";

export default function ArchivedUser() {
  const { logoutUser } = useAuth();
  return (
    <div class="max-w-[50rem] flex flex-col mx-auto size-full">
      <header class="mb-auto flex justify-center z-50 w-full py-4">
        <nav class="px-4 sm:px-6 lg:px-8 pt-4">
          <div class="flex-none text-xl font-semibold items-center gap-2 flex-col md:flex-row justify-center flex sm:text-3xl dark:text-white">
            <img
              alt="BSA"
              src={require("../../Img/BSA.jpg")}
              className=" size-10 rounded-full"
            />
            <p className=" text-center"> Bulacan Standard Academy</p>
          </div>
        </nav>
      </header>
      <main id="content">
        <div class="text-center py-10 px-4 sm:px-6 lg:px-8 flex flex-col ">
          <h1 class="block text-7xl font-bold text-gray-800 sm:text-9xl dark:text-white">
            403
          </h1>
          <p class="mt-3 text-gray-600 dark:text-neutral-400">
            Oops, Your account has been archived.
          </p>
          <p class="text-gray-600 dark:text-neutral-400">
            Please reach out to the registrar for further questions.
          </p>
          <button
            onClick={() => {
              logoutUser();
            }}
            class="mt-5 flex flex-col justify-center items-center gap-2 sm:flex-row sm:gap-3"
          >
            <div class="w-full sm:w-auto py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-secondary-600 text-white hover:bg-secondary-700 focus:outline-none focus:bg-secondary-700 disabled:opacity-50 disabled:pointer-events-none">
              <svg
                class="shrink-0 size-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="m15 18-6-6 6-6" />
              </svg>
              Back to Login page
            </div>
          </button>
        </div>
      </main>

      <footer class="mt-auto text-center py-5">
        <div class="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8">
          <p class="text-sm text-gray-500 dark:text-neutral-500">
            © 2024 Edusync. All Rights Reserved.
          </p>
        </div>
      </footer>
    </div>
  );
}
