import Announcement from "./EmployeeAnnouncement";
import Statistics from "./D_Statistics";
import { Spacer } from "@nextui-org/react";
import LeftSide_ActivityLogs from "./LeftSide_ActivityLogs";
export default function Admin_Dash() {
  return (
    <div className="flex flex-col  flex-1 w-full h-full gap-1 overflow-hidden">
      <Statistics />
      <div className="flex-1 overflow-auto w-full ">
        <div className="flex flex-col md:flex-row md:h-full h-full w-full">
          <LeftSide_ActivityLogs />
          <Spacer />
          <Announcement />
        </div>
      </div>
    </div>
  );
}
