import { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Image,
  Button,
  useDisclosure,
  CheckboxGroup,
  Checkbox,
  usePagination,
  PaginationItemType,
  cn,
  Skeleton,
  User
} from "@nextui-org/react";
import axios from "axios";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import { CustomCheckbox } from "./CustomCheckbox";
import { Link } from "react-router-dom";

export default function TeacherExperience({
  setdatas,
  Validate,
  isEdit = false,
  editData,
  isSmall = false
}) {
  const { DataServer, user } = useAuth();

  const [isLoading, setisLoading] = useState(false);
  const [MainError, setMainError] = useState();
  const [errors, setErrors] = useState({});

  const [SelectedTab, setSelectedTab] = useState("Degree");
  const [degree, setDegree] = useState("");
  const [alldegree, setallDegree] = useState("");

  //Teached SUbejcts
  const [TeachedSubjects, setTeachedSubjects] = useState([]);
  const [combinedSubjects, setCombinedSubjects] = useState([]);
  const [removedTaughtSubjects, setRemovedTaughtSubjects] = useState([]);
  const [filteredautocorrect, setFilteredAutocorrect] = useState([]);
  const [currentInput, setCurrentInput] = useState("");
  const [activeInputId, setActiveInputId] = useState(null);
  const [autoccorrect, setautocorrect] = useState([]);
  const [istyping, setistyping] = useState(false);

  //PreferredSUbejcts
  const [selectedID, setSelectedID] = useState([]);
  const [selected, setSelected] = useState([]);
  const [newselected, setnewSelected] = useState([]);
  const [selectednames, setSelectednames] = useState([]);
  const [removedSubjects, setRemovedSubjects] = useState([]);
  const [Subjects, setSubjects] = useState([]);
  const [Pages, setPages] = useState({
    total: 0,
    current: 1
  });
  const [subjectSearch, setsubjectSearch] = useState("");
  const { activePage, range, setPage, onNext, onPrevious } = usePagination({
    total: Pages.total,
    showControls: true
  });

  useEffect(() => {
    require("preline/preline");
  }, [SelectedTab, degree]);

  useEffect(() => {
    if (window.HSStaticMethods) {
      window.HSStaticMethods.autoInit();
    }
  }, [SelectedTab, degree]);

  const [search, setSearch] = useState("");
  // used to prevent multiple request, Itll only request after the user is done typing

  useEffect(() => {
    setisLoading(true);
    const timerId = setTimeout(() => {
      setsubjectSearch(search);
    }, 500);

    // Clean up the timer
    return () => clearTimeout(timerId);
  }, [search]);

  useEffect(() => {
    console.log("Selected hsser: ", subjectSearch);
  }, [subjectSearch]);

  //Teeached subejcts functions
  const handleInputFocus = (id) => {
    setActiveInputId(id); // Set active input ID when the input is focused
    setCurrentInput(
      TeachedSubjects.find((subject) => subject.id === id)?.subjectName || ""
    );
  };

  const handleInputChange = (id, value) => {
    handleTeachedSubjectChange(id, "subjectName", value);
    setCurrentInput(value);
  };

  const addTeachedSubjects = () => {
    setTeachedSubjects([
      ...TeachedSubjects,
      {
        id: isEdit ? `new-${Date.now()}` : `${Date.now()}`,
        subjectName: "",
        courseName: ""
      }
    ]);
  };

  const handleTeachedSubjectChange = (id, field, value) => {
    console.log(id, field, value);
    setTeachedSubjects(
      TeachedSubjects.map((group) =>
        group.id === id ? { ...group, [field]: value } : group
      )
    );
  };

  const handleDeleteGroup = (id) => {
    setTeachedSubjects(TeachedSubjects.filter((group) => group.id !== id));
    if (isEdit && editData) {
      const removedSubject = TeachedSubjects.find((sid) => sid.id === id);
      setRemovedTaughtSubjects([...removedTaughtSubjects, removedSubject]);
    }
  };

  //COmbine t string to match the database subject data structure
  useEffect(() => {
    const newCombinedSubjects = isEdit
      ? TeachedSubjects.map((subject) => ({
          id: subject.id,
          subject: `${subject.subjectName} | ${subject.courseName}`
        }))
      : TeachedSubjects.map((subject) => ({
          subject: `${subject.subjectName} | ${subject.courseName}`
        }));
    console.log(newCombinedSubjects);
    setCombinedSubjects(newCombinedSubjects);
  }, [TeachedSubjects]);

  //Get All subjects
  useEffect(() => {
    async function fetchsubj() {
      try {
        setisLoading(true);
        const response = await axios.get(
          `${DataServer}/course/subject/toadd/v2`,
          {
            params: {
              page: activePage,
              search: subjectSearch,
              selected: selected.map((subj) => {
                return subj.id;
              })
            }
          }
        );
        console.log(selected);
        console.log(response.data);
        if (false) {
          setSubjects({
            total: newselected.length + response.data.total,
            documents: [...newselected, ...response.data.documents]
          });
        } else {
          setSubjects(response.data);
        }
      } catch (error) {
        // Handle any errors
        setMainError(error);
      } finally {
        setisLoading(false);
      }
    }
    if (selected) fetchsubj();
  }, [subjectSearch, selected]);

  useEffect(() => {
    console.log(activeInputId);
    console.log(filteredautocorrect.length);
  }, [TeachedSubjects]);

  // Handle deselecting preferred subjects
  const handleDeselect = (subjectId, name) => {
    const updatedSelected = selected.filter((subj) => subj.id !== subjectId);
    const updatedSelectednames = selectednames.filter((id) => id !== name);
    const removedSubject = selected.find((subj) => subj.id === subjectId);
    setRemovedSubjects([...removedSubjects, removedSubject]);
    setSelected(updatedSelected);
    setSelectednames(updatedSelectednames);
  };

  // Handle reselecting subjects
  const handleSelect = (subjectId, name) => {
    const updatedRemovedSubjects = removedSubjects.filter(
      (subj) => subj.id !== subjectId
    );
    const type = selectedID.includes(subjectId)
      ? "old"
      : isEdit
      ? "new"
      : "old";
    setSelected([...selected, { type: type, id: subjectId }]);
    console.log(selectednames);
    setSelectednames([...selectednames, name]);
    setRemovedSubjects(updatedRemovedSubjects);
  };

  useEffect(() => {
    console.log(selectednames);
  }, [selectednames]);

  //Validations
  const validate = () => {
    let errors = {};

    TeachedSubjects.forEach((group) => {
      if (!group.subjectName) {
        errors[`subjectName-${group.id}`] = "subjectName is required";
      }
      if (!group.courseName) {
        errors[`courseSelect-${group.id}`] = "Course  is required";
      }
    });

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Validate input fields whenever data or input groups change
  useEffect(() => {
    Validate(validate());
  }, [TeachedSubjects]);

  //Update parent
  useEffect(() => {
    console.log("gg:", combinedSubjects);
    if (isEdit && editData) {
      console.log(degree);
      setdatas(
        combinedSubjects,
        removedTaughtSubjects,
        selected,
        removedSubjects,
        degree,
        errors
      );
    } else {
      setdatas(combinedSubjects, selected, degree, selectednames);
    }
  }, [
    TeachedSubjects,
    removedTaughtSubjects,
    selected,
    removedSubjects,
    degree,
    combinedSubjects,
    selectednames
  ]);

  // EDITS

  useEffect(() => {
    if (isEdit && editData) {
      setDegree(editData?.degree);
      setSelected(
        editData.preferredSubjects.map((pref) => ({
          type: "old",
          id: pref.subject.$id
        }))
      );
      setSelectedID(editData.preferredSubjects.map((pref) => pref.subject.$id));
      //subject sample is Math | Grade 1
      setTeachedSubjects(
        editData.teachedSubjects.map((subject) => {
          const data = subject.subject.split(" | ");
          return {
            id: subject.$id, // Assuming subject.$id is the correct way to access the ID
            subjectName: data[0],
            courseName: data[1]
          };
        })
      );
    }
  }, [editData]);

  useEffect(() => {
    console.log("GsssSs: ", selected);
  }, [selected]);

  useEffect(() => {
    fetch("/degree.json")
      .then((response) => response.json())
      .then((data) => setallDegree(data))
      .catch((error) => console.error("Error fetching the JSON file:", error));
  }, []);

  useEffect(() => {
    fetch("/subjects.json")
      .then((response) => response.json())
      .then((data) => setautocorrect(data))
      .catch((error) => console.error("Error fetching thes JSON file:", error));
  }, []);

  useEffect(() => {
    if (currentInput && activeInputId !== null) {
      const filtered = autoccorrect.filter((item) =>
        item.toLowerCase().includes(currentInput.toLowerCase())
      );
      setFilteredAutocorrect(filtered);
    } else {
      setFilteredAutocorrect([]);
    }
  }, [currentInput, autoccorrect, activeInputId, TeachedSubjects]);

  useEffect(() => {
    console.log(newselected);
  }, [newselected]);

  const [searchQuery, setSearchQuery] = useState("");

  // const handleSearchChange = (e) => {
  //   setSearchQuery(e.target.value);
  // };

  const handleItemClick = (value) => {
    setistyping(false);
    setDegree(value);
    setSearchQuery(value); // Optionally clear the search query
  };

  // Filter items based on the search query
  const filteredDegrees =
    alldegree &&
    alldegree.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

  return (
    <div className=" h-full w-full flex flex-col">
      {!isEdit ? (
        <span className="flex-initial text-4xl font-semibold text-[#72539b]">
          Academic Background
        </span>
      ) : (
        <div>
          <span className="flex-initial text-md font-meduim text-black">
            Academic Background
          </span>
          <div className="border-b-2 border-[#FFDE59] mt-1"></div>
        </div>
      )}

      <div
        className={`flex-1 p-2 flex w-full h-full flex-col ${
          !isEdit && "sm:flex-row"
        }   overflow-auto `}
      >
        <div className="flex-initial  overflow-auto">
          <div className="  flex-1 max-h-[310px] overflow-auto">
            <div className="  whitespace-nowrap mt-6 ">
              <button // Using optional chaining here to guard against undefined doc
                type="button"
                className={`hs-tab-active:border ${
                  !isEdit && "sm:w-full"
                }  w-auto  ${
                  SelectedTab === "Degree"
                    ? "border-secondary-500 text-secondary-500 hs-tab-active:text-secondary-600 dark:hs-tab-active:text-secondary-600"
                    : "border-transparent text-gray-500 hover:text-secondary-600 focus:text-secondary-600 dark:text-gray-400 dark:hover:text-blue-500"
                } py-1 pe-4  items-center sm:justify-start gap-x-2 border-b-2 ${
                  !isEdit && "sm:border-b-0 sm:border-e-2"
                } ${!isEdit ? " text-lg " : " text-md "} inline-flex ${
                  !isEdit && "sm:flex"
                } focus:outline-none disabled:opacity-50 disabled:pointer-events-none`}
                onClick={() => setSelectedTab("Degree")}
              >
                Educational Background
              </button>
              <button // Using optional chaining here to guard against undefined doc
                type="button"
                className={`hs-tab-active:border ${
                  !isEdit && "sm:w-full"
                }  w-auto  ${
                  SelectedTab === "PreferredSubjects"
                    ? "border-secondary-500 text-secondary-500 hs-tab-active:text-secondary-600 dark:hs-tab-active:text-secondary-600"
                    : "border-transparent text-gray-500 hover:text-secondary-600 focus:text-secondary-600 dark:text-gray-400 dark:hover:text-blue-500"
                } py-1 pe-4  items-center gap-x-2 border-b-2 ${
                  !isEdit && "sm:border-b-0 sm:border-e-2"
                } ${!isEdit ? " text-lg " : " text-md "} inline-flex ${
                  !isEdit && "sm:flex"
                } sm:justify-start focus:outline-none disabled:opacity-50 disabled:pointer-events-none`}
                onClick={() => setSelectedTab("PreferredSubjects")}
              >
                Preferred Subjects
              </button>
              <button // Using optional chaining here to guard against undefined doc
                type="button"
                className={`hs-tab-active:border ${
                  !isEdit && "sm:w-full"
                }  w-auto  ${
                  SelectedTab === "TeachedSubjects"
                    ? "border-secondary-500 text-secondary-500 hs-tab-active:text-secondary-600 dark:hs-tab-active:text-secondary-600"
                    : "border-transparent text-gray-500 hover:text-secondary-600 focus:text-secondary-600 dark:text-gray-400 dark:hover:text-blue-500"
                } py-1 pe-4  items-center sm:justify-start gap-x-2 border-b-2 ${
                  !isEdit && "sm:border-b-0 sm:border-e-2"
                } ${!isEdit ? " text-lg " : " text-md "} inline-flex ${
                  !isEdit && "sm:flex"
                } focus:outline-none disabled:opacity-50 disabled:pointer-events-none`}
                onClick={() => setSelectedTab("TeachedSubjects")}
              >
                Teached Subjects
              </button>
            </div>
          </div>
        </div>
        <div className="flex-1 pt-2 sm:pt:0 sm:px-3 overflow-hidden">
          {SelectedTab === "Degree" && (
            <div className=" w-full rounded-lg px-6 py-2 bg-gray-50 min-h-[400px]">
              <div className="flex-1 h-full p-4 overflow-auto">
                <div className="flex flex-col flex-1 space-y-4 justify-center items-center h-full ">
                  <Image
                    isZoomed
                    width={240}
                    alt="NextUI Fruit Image with Zoom"
                    src={require("../../../../../Img/course.jpg")}
                  />
                  <span className="text-xl"></span>
                  <span className="text-sm">Academic Degree</span>
                  {/* <input
                    defaultValue={degree}
                    onChange={(e) => {
                      setDegree(e.target.value);
                    }}
                    type="text"
                    placeholder="Degree"
                    className={`py-2 px-3  border-gray-200 rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600
              `}
                  ></input> */}
                  <div className="w-3/4 relative">
                    <div class="relative">
                      <div class="relative">
                        <div class="absolute inset-y-0 start-0 flex items-center pointer-events-none z-20 ps-3.5">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-book"
                            viewBox="0 0 16 16"
                          >
                            <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783" />
                          </svg>
                        </div>
                        <input
                          class="py-3 ps-10 pe-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                          type="text"
                          role="combobox"
                          aria-expanded="true"
                          placeholder="Type the Degree"
                          id="INPUTDEGREE"
                          value={searchQuery}
                          onChange={(e) => {
                            setistyping(true);
                            setSearchQuery(e.target.value);
                          }}
                        />
                      </div>
                      <div
                        className="absolute mt-[-348px] top-full left-0 w-full bg-white border border-gray-200 rounded-lg dark:bg-neutral-800 dark:border-neutral-700  z-50"
                        style={{ display: istyping ? "block" : "none" }}
                      >
                        <div
                          onClick={() => {
                            console.log("hhs");
                          }}
                          className="max-h-72 rounded-b-lg overflow-hidden overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500"
                        >
                          {filteredDegrees.length > 0 ? (
                            filteredDegrees.map((item) => (
                              <div
                                key={item.name}
                                className="flex items-center cursor-pointer py-2 px-4 w-full text-sm text-gray-800 hover:bg-gray-100"
                                onClick={() => {
                                  console.log("hh");
                                  handleItemClick(item.name);
                                }}
                              >
                                <div className=" flex s flex-col">
                                  <div className="text-gray-800 text-md font-semibold">
                                    {item.name}
                                  </div>
                                  <span className=" text-xs text-secondary-400">
                                    {item.degreetype} | {item.gradtype}
                                  </span>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className="py-2 px-4 text-gray-500">
                              No results found
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* Main here */}
          {SelectedTab === "TeachedSubjects" && (
            <div className=" w-full rounded-lg px-6 py-2 bg-gray-50">
              <button
                type="button"
                onClick={addTeachedSubjects}
                className="mt-4 py-2 px-4 mb-2 bg-secondary text-sm text-white rounded-lg"
              >
                Add Subject
              </button>
              <div className="max-h-[450px] min-h-[400px] border-t-2 p-2 border-warning-300 overflow-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500">
                {TeachedSubjects && TeachedSubjects.length > 0 ? (
                  TeachedSubjects.map((group) => (
                    <div key={group.id} className="my-1">
                      <div className="relative">
                        <button
                          type="button"
                          onClick={() => handleDeleteGroup(group.id)}
                          className="absolute right-0 top-0 h-full px-2 py-1 text-secondary rounded-lg"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            className="bi bi-x-circle-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                          </svg>
                        </button>
                        <input
                          type="text"
                          placeholder="Subject name"
                          id={`subjectName-${group.id}`}
                          className={`py-3 px-4 block w-full ps-44 border-gray-200 rounded-lg text-sm focus:border-secondary focus:ring-secondary disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600 ${
                            errors[`subjectName-${group.id}`]
                              ? "border-red-500"
                              : ""
                          }`}
                          onFocus={() => handleInputFocus(group.id)}
                          value={group.subjectName}
                          onChange={(e) => {
                            handleTeachedSubjectChange(
                              group.id,
                              "subjectName",
                              e.target.value
                            );
                            setCurrentInput(e.target.value);
                          }}
                        />
                        <div
                          className={`${
                            activeInputId === group.id &&
                            filteredautocorrect.length > 0
                              ? ""
                              : "hidden"
                          } absolute top-[50px] z-20 bg-white border rounded-md shadow-lg`}
                        >
                          {/* Display suggestions based on filtered data */}
                          {filteredautocorrect.map((suggestion, index) => (
                            <div
                              key={index}
                              className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                              onClick={() => {
                                handleInputChange(group.id, suggestion);
                                handleTeachedSubjectChange(
                                  group.id,
                                  "subjectName",
                                  suggestion
                                );
                                setFilteredAutocorrect([]);
                                setActiveInputId(null);
                                setCurrentInput("");
                              }}
                            >
                              {suggestion}
                            </div>
                          ))}
                        </div>
                        <div className="absolute inset-y-0 start-0 flex items-center text-gray-500 ps-px">
                          <label
                            htmlFor={`courseSelect-${group.id}`}
                            className="sr-only"
                          >
                            Course
                          </label>

                          <select
                            id={`courseSelect-${group.id}`}
                            name={`courseSelect-${group.id}`}
                            className={`block w-full rounded-lg overflow-auto focus:ring-secondary text-black focus:border-secondary dark:text-neutral-500 dark:bg-neutral-800 ${
                              errors[`courseSelect-${group.id}`]
                                ? "border-red-500"
                                : "border-transparent"
                            }`}
                            value={group.courseName}
                            onChange={(e) =>
                              handleTeachedSubjectChange(
                                group.id,
                                "courseName",
                                e.target.value
                              )
                            }
                          >
                            <option value="">Select Course</option>
                            <option value="Kindergarten">Kindergarten</option>
                            <option value="Nursery 1">Nursery 1</option>
                            <option value="Nursery 2">Nursery 2</option>
                            <option value="Grade 1">Grade 1</option>
                            <option value="Grade 2">Grade 2</option>
                            <option value="Grade 3">Grade 3</option>
                            <option value="Grade 4">Grade 4</option>
                            <option value="Grade 5">Grade 5</option>
                            <option value="Grade 6">Grade 6</option>
                            <option value="Grade 7">Grade 7</option>
                            <option value="Grade 8">Grade 8</option>
                            <option value="Grade 9">Grade 9</option>
                            <option value="Grade 10">Grade 10</option>
                            <option value="Grade 11">Grade 11</option>
                            <option value="Grade 12">Grade 12</option>
                            <option value="College">College</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="flex w-full min-h-[400px] h-full justify-center items-center">
                    No Teached Subjects Yet
                  </div>
                )}
              </div>
            </div>
          )}

          {SelectedTab === "PreferredSubjects" && (
            <div className=" w-full rounded-lg px-6 py-2 bg-gray-50 min-h-[400px] h-[800px]">
              <div className="flex-1 h-auto p-4 ">
                <div className="flex flex-col flex-1 space-y-4 justify-center items-center h-full ">
                  <Image
                    isZoomed
                    width={240}
                    alt="NextUI Fruit Image with Zoom"
                    src={require("../../../../../Img/subject.jpg")}
                  />

                  <div className="max-w-[600px] w-full  relative">
                    <div class="relative">
                      <div class="relative">
                        <div class="absolute inset-y-0 start-0 flex items-center pointer-events-none z-20 ps-3.5">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-book"
                            viewBox="0 0 16 16"
                          >
                            <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783" />
                          </svg>
                        </div>
                        <input
                          class="py-3 ps-10 pe-4 block w-full border-gray-200 rounded-lg text-sm focus:border-secondary focus:ring-secondary disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                          type="text"
                          role="combobox"
                          aria-expanded="true"
                          placeholder="search Subjects"
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                        />
                      </div>
                      <div className="absolute mt-2 top-full left-0 w-full bg-white border border-gray-200 rounded-lg dark:bg-neutral-800 dark:border-neutral-700  z-50">
                        <div className="flex flex-col gap-1 w-full rounded-b-lg overflow-hidden overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500">
                          {Subjects && Subjects.documents.length > 0 ? (
                            <CheckboxGroup
                              color="warning"
                              value={selected.map((subj) => {
                                return subj.id;
                              })}
                              onValueChange={(value) => {
                                setSelected(value);
                                setRemovedSubjects([]);
                              }}
                              className="flex flex-row overflow-auto max-h-[450px] flex-wrap"
                            >
                              {Subjects &&
                                Subjects.documents &&
                                Subjects.documents.map((subj) => (
                                  <Skeleton
                                    isLoaded={!isLoading}
                                    className="rounded-lg"
                                  >
                                    <Checkbox
                                      aria-label={subj.$id}
                                      classNames={{
                                        base: cn(
                                          "inline-flex  w-full bg-content1 m-0",
                                          "hover:bg-content2 items-center justify-start",
                                          "cursor-pointer rounded-lg gap-2 p-4 border-2 border-transparent",
                                          "data-[selected=true]:border-secondary"
                                        ),
                                        label: "w-full"
                                      }}
                                      key={subj.$id}
                                      value={subj.$id}
                                      checked={selected.map((subj) => {
                                        return subj.id;
                                      })}
                                      onChange={() => {
                                        if (
                                          selected
                                            .map((subj) => {
                                              return subj.id;
                                            })
                                            .includes(subj.$id)
                                        ) {
                                          handleDeselect(
                                            subj.$id,
                                            subj.subjectName
                                          );
                                          const updatednewSelected =
                                            selected.filter(
                                              (newsubj) =>
                                                newsubj.$id !== subj.$id
                                            );
                                          setnewSelected(updatednewSelected);
                                        } else {
                                          console.log("g");
                                          setnewSelected([
                                            ...newselected,
                                            subj
                                          ]);
                                          handleSelect(
                                            subj.$id,
                                            subj.subjectName
                                          );
                                        }
                                        setSearch("");
                                        setsubjectSearch("");
                                      }}
                                    >
                                      <div className="w-full flex justify-between gap-2">
                                        <div className="flex flex-col">
                                          <div className=" text-medium">
                                            {subj.subjectName}
                                          </div>
                                          <span className=" text-xs">
                                            {subj.subjectDescription}
                                          </span>
                                        </div>
                                      </div>
                                    </Checkbox>
                                  </Skeleton>
                                ))}
                            </CheckboxGroup>
                          ) : (
                            <Skeleton isLoaded={!isLoading}>
                              <div className=" flex justify-center items-center text-medium p-6">
                                No Preferred Subject yet
                              </div>
                            </Skeleton>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
