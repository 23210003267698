import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Spinner
} from "@nextui-org/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useAuth } from "../../../../utils/AppwriteConfig/AuthContext";

export default function AssignRoom({ data, section, refresh }) {
  const { DataServer, user } = useAuth();
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();

  const [isLoading, setIsLoading] = useState(true);
  const [mainError, setMainError] = useState(null);
  const [rooms, setRooms] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState({});
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const fetchRooms = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${DataServer}/info/${data.department}/rooms`
        );
        setRooms(response.data.documents);
      } catch (error) {
        setMainError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (isOpen) fetchRooms();
  }, [isOpen, DataServer, data.department]);

  const toggleDropdown = () => setDropdownOpen((prev) => !prev);

  const handleRoomSelect = (room) => {
    setSelectedRoom(room);
    setDropdownOpen(false);
  };

  const clearSelection = () => {
    setSelectedRoom(null);
  };

  useEffect(() => {
    console.log(selectedRoom);
  }, [selectedRoom]);

  const handleSubmit = async () => {
    // Replace with your submit logic

    if (Object.keys(selectedRoom).length > 0) {
      //edit
      try {
        setIsLoading(true);
        const NewRoomResponse = await axios.put(
          `${DataServer}/info/room/assign/section`,
          { selectedRoom, user, section }
        );
        console.log(NewRoomResponse.data);
      } catch (error) {
        setMainError(error.message);
      } finally {
        setIsLoading(false);
        closeModal();
        refresh();
      }
    }
  };

  function closeModal() {
    if (mainError) {
      setTimeout(() => {
        selectedRoom({});
        onClose();
      }, 2500); // Delay of 2.5 seconds
    } else {
      // Immediate close if there's no MainError
      setSelectedRoom({});
      onClose();
    }
  }

  return (
    <>
      {/* Trigger Button */}
      <button onClick={onOpen}>
        <div className="border rounded-lg p-3 px-4 bg-[#9353d3] hover:shadow-lg transition-transform transform hover:scale-105 cursor-pointer">
          <div className="text-sm text-white text-center font-medium">
            No Room Assigned Yet
          </div>
        </div>
      </button>

      {/* Modal */}
      <Modal
        className="font-poppins"
        isOpen={isOpen}
        scrollBehavior="inside"
        onOpenChange={onOpenChange}
        onClose={closeModal}
      >
        <ModalContent>
          {(closeModal) => (
            <>
              {/* Modal Header */}
              <ModalHeader className="flex flex-col gap-1 text-lg font-semibold text-gray-800">
                Assign a Room
              </ModalHeader>

              {/* Modal Body */}
              <ModalBody>
                {isLoading ? (
                  <div className="text-center text-gray-500">
                    {isLoading && Object.keys(selectedRoom).length > 0
                      ? "Assigning Room"
                      : "Loading rooms..."}
                  </div>
                ) : mainError ? (
                  <div className="text-center text-red-500">
                    {mainError || "An error occurred."}
                  </div>
                ) : rooms.length === 0 ? (
                  <div className="text-center text-gray-500">
                    No rooms available for the{" "}
                    <strong>{data.department}</strong> department.
                  </div>
                ) : (
                  <div className="relative">
                    {/* Dropdown Toggle */}
                    <div
                      className="border rounded-lg p-3 relative flex items-center justify-between cursor-pointer bg-gray-50 hover:bg-gray-100"
                      onClick={toggleDropdown}
                    >
                      {Object.keys(selectedRoom).length > 0 ? (
                        <div className="font-medium  flex items-center gap-2">
                          {selectedRoom.roomName}
                          {/* X Button to Clear Selection */}
                          <button
                            className="text-red-500 absolute right-2 text-sm px-2 py-1 border rounded-full hover:bg-gray-200"
                            onClick={(e) => {
                              e.stopPropagation();
                              clearSelection();
                            }}
                          >
                            ✕
                          </button>
                        </div>
                      ) : (
                        <div className="text-gray-500">Select a Room</div>
                      )}
                    </div>
                    <div className={dropdownOpen ? " h-48" : ""}>
                      {/* Dropdown Options */}
                      {dropdownOpen && (
                        <div
                          className={`absolute z-50 mt-2 w-full max-h-48 overflow-y-auto border bg-white shadow-lg rounded-lg`}
                        >
                          {rooms.map((room) => (
                            <div
                              key={room.$id}
                              onClick={() => handleRoomSelect(room)}
                              className={`p-3 hover:bg-gray-100 cursor-pointer ${
                                selectedRoom?.$id === room.$id
                                  ? "bg-gray-100"
                                  : ""
                              }`}
                            >
                              <div className="font-medium">{room.roomName}</div>
                              <div className="text-xs text-gray-500">
                                Capacity: {room.capacity} | Location:{" "}
                                {room.location}
                              </div>
                              <span
                                className={`text-xs ${
                                  room.Maintainance
                                    ? "text-yellow-600"
                                    : "text-green-600"
                                }`}
                              >
                                {room.Maintainance ? "Maintenance" : "Active"}
                              </span>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </ModalBody>

              {/* Modal Footer */}
              <ModalFooter>
                <Button color="danger" variant="light" onPress={closeModal}>
                  Close
                </Button>
                <Button
                  color="secondary"
                  isDisabled={Object.keys(selectedRoom).length <= 0}
                  onPress={() => {
                    console.log(`Selected Room: ${selectedRoom?.roomName}`);
                    handleSubmit();

                    setSelectedRoom({});
                  }}
                >
                  {isLoading && Object.keys(selectedRoom).length > 0 ? (
                    <div className=" flex items-center gap-1">
                      <Spinner size="sm" color=" white" />
                      <div>Assigning Room</div>
                    </div>
                  ) : (
                    "Assign Room"
                  )}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
