import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Checkbox,
  Input,
  Link
} from "@nextui-org/react";
import { useAuth } from "../../../../utils/AppwriteConfig/AuthContext";
import axios from "axios";
import SubjectAddForm from "../components/renders/SubjectAddForm";
import Error from "../../../../Components/ErrorIndicator";
import DeleteSubject from "./DeleteSubject";

export default function SubjectModal({ reload, modalType, SubjectToEdit }) {
  /**
   *
   * THIS COMPONENT IS USED TO CREATE NEW COURSES
   *
   */

  const { DataServer, user } = useAuth();

  useEffect(() => {
   
  }, [SubjectToEdit]);

  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const [data, setdata] = useState({});
  const [isLoading, setisLoading] = useState(false);
  const [MainError, setMainError] = useState();
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
  
  }, [data]);

  //Handles the Add process of the new course
  const handleSubject = async (type) => {
    if (isValid && type) {
      setisLoading(true);
      try {
        let response;
      
        if (type === "new") {
          response = await axios.post(`${DataServer}/course/subject/new`, data);
        } else {
          response = await axios.put(
            `${DataServer}/course/subject/${SubjectToEdit.$id}/edit`,
            data
          );
        }

      
      } catch (e) {
        setMainError(e.message);
      } finally {
        setisLoading(false);
        setdata({});
        reload();
        if (MainError === "") {
          setTimeout(() => {
            onClose();
          }, 3000);
        } else {
          onClose();
        }
      }
    } else {
      setMainError("Some Data that you have entered is wrong...");
    }
  };

  return (
    <>
      <Button
        onPress={onOpen}
        id={`SubjectModalbtn-${modalType}`}
        className={`${
          modalType === "edit" ? "hidden" : "flex"
        } bg-[#FFDE59]  flex-initial  ml-1 rounded-lg text-black font-semibold hover:bg-[#fcd84b] overflow-hidden relative transition-transform duration-300 transform hover:scale-105`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-window-plus"
          viewBox="0 0 16 16"
        >
          <path d="M2.5 5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1M4 5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1m2-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0" />
          <path d="M0 4a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v4a.5.5 0 0 1-1 0V7H1v5a1 1 0 0 0 1 1h5.5a.5.5 0 0 1 0 1H2a2 2 0 0 1-2-2zm1 2h13V4a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1z" />
          <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0m-3.5-2a.5.5 0 0 0-.5.5v1h-1a.5.5 0 0 0 0 1h1v1a.5.5 0 0 0 1 0v-1h1a.5.5 0 0 0 0-1h-1v-1a.5.5 0 0 0-.5-.5" />
        </svg>{" "}
        Subject
      </Button>
      <Modal
        className=" font-poppins"
        size="lg"
        isOpen={isOpen}
        scrollBehavior="inside"
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          {(onClose) => (
            <>
              {MainError && (
                <Error errorMessage={MainError} reset={setMainError} />
              )}
              <ModalHeader className="flex items-center gap-1">
                <span>
                  {modalType && SubjectToEdit
                    ? `Edit ${SubjectToEdit.subjectName}`
                    : "Add new"}{" "}
                  Subject
                </span>
                {modalType && SubjectToEdit && (
                  <DeleteSubject
                    subject={SubjectToEdit}
                    refresher={() => {
                      reload();
                      onClose();
                    }}
                  />
                )}
              </ModalHeader>
              <ModalBody>
                <SubjectAddForm
                  Validator={setIsValid}
                  pushData={setdata}
                  editData={SubjectToEdit}
                />
                <Button
                  id="AddNewSubjectBtn"
                  className=" hidden"
                  onPress={() => {
                 
                    handleSubject(modalType);
                  }}
                ></Button>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
