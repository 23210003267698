import {
  Badge,
  Button,
  Card,
  CardFooter,
  Image,
  Skeleton
} from "@nextui-org/react";
import Edit from "./modals/Edit";
import CourseDetails from "../pages/{Employee}/CoursePage/components/modals/Coursedetails";
import { useAuth } from "../utils/AppwriteConfig/AuthContext";
import { useEffect, useState } from "react";
import Error from "./ErrorIndicator";
import axios from "axios";
import useRealtimeSubscription from "../utils/AppwriteConfig/RealtimeServices";
import { GetCurrentAcademicYear } from "../utils/helpers/stringformat";
import SectionCard from "./SectionCard";

export default function CScard({ data, reload, type, selectcourse, lrefresh }) {
  const { role, server } = useAuth();

  const [isLoading, setisLoading] = useState(false);
  const [isrefresh, setisrefresh] = useState(false);
  const [MainError, setMainError] = useState();

  const [students, setstudents] = useState();

  //used to get the total current enrolled student for the specific course
  useEffect(() => {
    setisLoading(true);
    async function fetchenrollee() {
      try {
        const response = await axios.get(`${server}/v1/course/students`, {
          params: { id: data.$id, academicYear: GetCurrentAcademicYear() }
        });
        setstudents(response.data.total);
      } catch (error) {
        // Handle any errors
        setMainError(error.message);
      } finally {
        setisLoading(false);
      }
    }
    fetchenrollee();
  }, [data, lrefresh, isrefresh]);

  //Get realtime updates
  const handleRealtimeMessage = (message) => {
    setisrefresh(!isrefresh);
    // Handle the Realtime message as needed
  };

  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_ENROLLMENT,
    handleRealtimeMessage
  );
 

  return (
    <>
      {/* // <Badge
    //   content={students ? students : null}
    //   color="danger"
    //   shape="circle"
    //   showOutline={false}
    // > */}
      {MainError && <Error errorMessage={MainError} />}
      <Card
        isPressable
        shadow="sm"
        className=" rounded-md overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
        onPress={() => {
          selectcourse(data);
        }}
      >
        <Image
          removeWrapper
          alt="Card background"
          className="z-0 w-full h-full object-cover"
          src={require("../Img/BSA.jpg")}
        />
        <CardFooter className="absolute bg-[#faf5ff]/95 bottom-0 z-10 h-full rounded-md">
          <div className=" w-full h-full flex flex-col p-2 space-y-2">
            <div className=" text-center">
              {/* HEADER */}
              <div className=" font-semibold text-2xl text-[#4a306d]">
                {/* Grade level */}
                {data.courseName}
              </div>
              <div className=" text-[#7d5ca3] flex justify-center gap-1 items-center">
                {/* TOTAL SUBJECTS */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-book-half"
                  viewBox="0 0 16 16"
                >
                  <path d="M8.5 2.687c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783" />
                </svg>
                Total Subjects:{" "}
                {data && data?.subject ? data.subject.length : 0}
              </div>
            </div>
            <div className=" flex flex-col gap-1 pr-2  overflow-x-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-slate-700  dark:[&::-webkit-scrollbar-thumb]:bg-slate-500 ">
              {/* SECTIONS */}
              {data &&
                data?.section &&
                data.section.map((section) => (
                  <SectionCard Csection={section} CcourseId={data.$id} />
                ))}
            </div>
          </div>
        </CardFooter>
      </Card>
      {/* </Badge> */}
    </>
  );
}
{
  /* <div className="flex flex-grow gap-2 items-center">
            <div className="flex flex-col">
              <p className=" text-medium text-white/90">
                {data.subjectName}
                {data.courseName}
              </p>
              <p className="text-tiny text-white/90">
                {data.subjectDescription}
                {data.courseDescription}
              </p>
            </div>
          </div>
          {type === "course" ? (
            <Button
              className="bg-[#FFDE59] opacity-100 rounded-lg p-3 py-2 text-black hover:bg-[#fcd84b] text-sm"
              onClick={() => {
                selectcourse(data);
              }}
            >
              View
            </Button>
          ) : (
            <Edit Title={"Subject"} CurrentData={data} Refresh={reload} />
          )} */
}
