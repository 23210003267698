import { useEffect, useState } from "react";
import CardSkeleton from "../../../../Components/skeleton/Cards";
import Main from "../../../Layout/Main";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  Button,
  Card,
  CardBody,
  CardFooter,
  Image
} from "@nextui-org/react";
import { useAuth } from "../../../../utils/AppwriteConfig/AuthContext";
import axios from "axios";
import PrefferdSubjects from "./components/render/PrefferedSubjects";
import PreferredSubjectCanvas from "./components/PreferredSubjectCanvas";
export default function AssignedSubjects() {
  /**
   *
   * THIS COMPONENT IS USED TO GET ASSIGNED SUBJECTS FOR THE TEACHER TO TEACH
   * should teaching time be included?
   */
  const { server, user } = useAuth();

  const [isLoading, setisLoading] = useState(false);
  const [Refresh, setRefresh] = useState(false);
  const [MainError, setMainError] = useState();
  const [isOpen, setIsOpen] = useState(false); /** Used for Popover controll */

  const [AssignedSubjects, setAssignedSubjects] = useState([]);

  useEffect(() => {
    async function GetSubjects() {
      try {
        setisLoading(true);
        setAssignedSubjects([]);
        const teacher = await axios.get(
          `${server}/v1/teacher/subjects/assigned`,
          {
            params: { accid: user.$id }
          }
        );
        setAssignedSubjects(teacher.data.documents);
      } catch (e) {
        setMainError(e.message);
      } finally {
        setisLoading(false);
      }
    }
    GetSubjects();
  }, [Refresh]);

  return (
    <Main
      headerTitle={"Assigned Subjects"}
      leftContent={<PreferredSubjectCanvas />}
      leftCN={"flex items-end"}
    >
      <div
        className={`${
          AssignedSubjects.length > 0
            ? "grid p-3 gap-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
            : "flex max-h-full h-full"
        }  overflow-auto `}
      >
        {isLoading ? (
          <>
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
          </>
        ) : (
          <>
            {AssignedSubjects && AssignedSubjects.length > 0 ? (
              AssignedSubjects.map((subject) => (
                <Card
                  shadow="none"
                  className=" rounded-md min-h-44 max-h-52 overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
                >
                  <Image
                    removeWrapper
                    alt="Card background"
                    className="z-0 w-full h-full object-cover"
                    src={require("../../../../Img/BSA.jpg")}
                  />
                  <CardFooter className="absolute bg-[#72539b]/80 bottom-0 z-10 rounded-md">
                    <div className="flex  w-full gap-2 items-center">
                      <div className="flex w-full text-white flex-col">
                        <p className=" text-lg text-white/90">
                          {subject.subject.subjectName}
                        </p>
                        <div className=" flex  bg-secondary-50 w-full text-sm p-1 px-2 text-secondary-700 rounded-lg">
                          {subject.course.courseName} |{" "}
                          {subject.section.sectionName}
                        </div>
                      </div>
                    </div>
                  </CardFooter>
                </Card>
              ))
            ) : (
              <div className=" w-full h-full flex justify-center items-center">
                No Subjects Assigned yet
              </div>
            )}
          </>
        )}
      </div>
    </Main>
  );
}
