import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../utils/AppwriteConfig/AuthContext";
import { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Card,
  CardFooter,
  Image,
  Pagination,
  Skeleton
} from "@nextui-org/react";
import Error from "../../../../Components/ErrorIndicator";
import axios from "axios";
import NewRoom from "./actions/NewRoom";
import useRealtimeSubscription from "../../../../utils/AppwriteConfig/RealtimeServices";

export default function Rooms() {
  const { DataServer, role } = useAuth();

  const [isLoading, setisLoading] = useState(true);
  const [isrefresh, setisrefresh] = useState(true);
  const [MainError, setMainError] = useState();

  const [rooms, setRooms] = useState([]);

  const [selectedRoom, setSelectedRoom] = useState({});
  const [Pages, setPages] = useState({
    total: 0,
    current: 1
  });

  useEffect(() => {
    setisLoading(true);
    const fetchCourses = async () => {
      try {
        const response = await axios.get(`${DataServer}/info/rooms`, {
          params: {
            page: Pages.current
          }
        });
        const totalPages = Math.ceil(response.data.total / 15);
        setPages((prevPages) => ({ ...prevPages, total: totalPages }));

        setRooms(response.data.documents);
      } catch (error) {
        setMainError(error.message);
      } finally {
        setisLoading(false);
      }
    };

    fetchCourses();
  }, [isrefresh, Pages.current, DataServer]);

  const handleRealtimeMessage = (message) => {
    console.log(message, "gg");
    setisrefresh(!isrefresh);
  };
  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_ROOM,
    handleRealtimeMessage
  );

  function refresh() {
    setisrefresh(!isrefresh);
  }

  const handleEditClick = (room) => {
    setSelectedRoom(room);

    document.querySelector("#editRoomBtn").click();
  };

  return (
    <div className="w-full h-full sm:pt-4 overflow-hidden">
      {MainError && <Error errorMessage={MainError} />}
      <div className="flex flex-col h-full space-y-2 justify-center">
        <div className="flex-initial flex w-full sm:flex-row flex-col justify-between items-center p-0 sm:p-2 mb-4">
          <span className="font-bold flex-initial text-3xl sm:mb-0 mb-2 text-[#72539b]">
            Rooms
          </span>
          <div className=" flex-1 flex justify-end">
            {" "}
            <NewRoom
              refresher={refresh}
              room={selectedRoom}
              selectedReset={setSelectedRoom}
            />
          </div>
        </div>
        <div className="flex-initial mb-2 h-full w-full bg-white rounded-lg py-4 p-1 overflow-auto overflow-x-hidden shadow-md relative">
          <div className="grid p-3 gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4  xl:grid-cols-5   flex-1">
            {!isLoading && rooms && rooms.length > 0 ? (
              rooms.map((room) => <RoomCard data={room} />)
            ) : isLoading ? (
              <RoomCardLoading />
            ) : (
              <div className="absolute font-medium text-secondary-700 text-lg flex h-full w-full justify-center items-center overflow-hidden">
                No Rooms yet
              </div>
            )}
          </div>
        </div>
        <div className="flex-initial flex justify-center">
          <Pagination
            isDisabled={isLoading}
            className="flex-initial p-4"
            loop
            showControls
            color="secondary"
            total={Pages.total}
            initialPage={1}
            page={Pages.current}
            onChange={(page) =>
              setPages((prevPages) => ({ ...prevPages, current: page }))
            }
          />
        </div>
      </div>
    </div>
  );

  function RoomCard({ data }) {
    return (
      <div
        onClick={() => {
          handleEditClick(data);
        }}
        className={`relative  cursor-pointer h-full rounded-lg border shadow-lg  overflow-hidden transition-transform duration-300 transform hover:scale-105 `}
        // ${
        //     isSelected ? "border-4 border-blue-500" : ""
        //   }
        // className={`  rounded-md min-h-44 max-h-52 overflow-hidden relative transition-transform duration-300 transform hover:scale-105`}
      >
        <Image
          removeWrapper
          alt="Card background"
          className="z-0 w-full h-full object-cover"
          src={require("../../../../Img/BSA.jpg")}
        />
        <div className="absolute bg-[#e5dbf9]/90 bottom-0 w-full h-full z-10 rounded-lg">
          <div className=" relative p-4 flex flex-col justify-end w-full h-full">
            <div
              className={` absolute top-2 right-2 text-secondary-600 ${
                data.Maintainance ? " text-warning-600" : "text-secondary-700"
              }`}
            >
              {data.Maintainance ? "Maintainance" : "Active"}{" "}
            </div>
            <div className=" flex flex-col justify-start text-start w-full">
              <div className=" text-xl flex items-center  gap-1 text-[#4a306d] font-semibold mb-1">
                <div className=" w-full max-w-full text-ellipsis whitespace-nowrap truncate">
                  {data.roomName}
                </div>
              </div>
              <div className="text-base text-[#7d5ca3]">
                Capacity : {data.capacity}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// function RoomCard({ data }) {
//   return (
//     <div
//       //   onClick={() => select(sectionData)}
//       className={`relative w-[280px] cursor-pointer h-full rounded-lg border shadow-lg  overflow-hidden transition-transform duration-300 transform hover:scale-105 ${
//         isSelected ? "border-4 border-blue-500" : ""
//       }`}

//       // className={`  rounded-md min-h-44 max-h-52 overflow-hidden relative transition-transform duration-300 transform hover:scale-105`}
//     >
//       <Image
//         removeWrapper
//         alt="Card background"
//         className="z-0 w-full h-full object-cover"
//         src={require("../../../Img/BSA.jpg")}
//       />
//       <div className="absolute bg-[#e5dbf9]/90 bottom-0 w-full h-full z-10 rounded-lg">
//         <div className=" relative p-4 flex flex-col justify-end w-full h-full">
//           {/* <div className={` absolute top-2 right-2 text-secondary-600 `}>
//             Academicyear
//           </div> */}
//           <div className=" flex flex-col justify-start text-start w-full">
//             <div className=" text-xl flex items-center  gap-1 text-[#4a306d] font-semibold mb-1">
//               <Avatar
//                 src="https://i.pravatar.cc/150?u=a042581f4e29026024d"
//                 className="w-5 h-5 text-tiny"
//               />
//               <div> {sectionData.courseName} </div>
//             </div>
//             <div className="text-base text-[#7d5ca3]">
//               {sectionData.sectionName}
//             </div>
//           </div>
//         </div>
//         {/* <div className=" text-medium flex flex-col items-start text-black/90">
//           <span>{user?.accountType}</span>
//           <span className="">
//             {user?.Username}
//             {user?.student?.firstName}{" "}
//             {user?.student?.lastName}
//           </span>
//         </div>
//         <p className="text-tiny text-white/90">
//           {user?.role}
//           {user.student && (
//             <>Student | {user?.course?.courseName}</>
//           )}
//         </p> */}
//       </div>
//     </div>
//   );
// }

function RoomCardLoading() {
  return (
    <>
      {/* <Skeleton isLoaded={false} className=" w-full rounded-lg ">
        <Card
          shadow="sm"
          className=" rounded-md overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
        >
          <Image
            removeWrapper
            alt="Card background"
            className="z-0 w-full h-full object-cover"
            src={require("../../../../Img/BSA.jpg")}
          />
          <CardFooter className="absolute bg-[#72539b]/80 bottom-0 flex-wrap z-10 rounded-md">
            <div className="flex flex-grow gap-2 items-center">
              <div className="flex flex-col">
                <p className=" text-medium text-white/90">idagfsdkjfnsda</p>
              </div>
            </div>
            <button
              // onClick={() => handleEditClick(subj)}
              className="bg-[#FFDE59] opacity-100 rounded-lg p-3 py-2 text-black hover:bg-[#fcd84b] text-sm"
            >
              Edit
            </button>
          </CardFooter>{" "}
        </Card>{" "}
      </Skeleton>
      <Skeleton isLoaded={false} className=" w-full rounded-lg ">
        <Card
          shadow="sm"
          className=" rounded-md overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
        >
          <Image
            removeWrapper
            alt="Card background"
            className="z-0 w-full h-full object-cover"
            src={require("../../../../Img/BSA.jpg")}
          />
          <CardFooter className="absolute bg-[#72539b]/80 bottom-0 flex-wrap z-10 rounded-md">
            <div className="flex flex-grow gap-2 items-center">
              <div className="flex flex-col">
                <p className=" text-medium text-white/90">idagfsdkjfnsda</p>
              </div>
            </div>
            <button
              // onClick={() => handleEditClick(subj)}
              className="bg-[#FFDE59] opacity-100 rounded-lg p-3 py-2 text-black hover:bg-[#fcd84b] text-sm"
            >
              Edit
            </button>
          </CardFooter>{" "}
        </Card>{" "}
      </Skeleton> */}
      {/* <Skeleton isLoaded={false} className=" w-full rounded-lg ">
        <Card
          shadow="sm"
          className=" rounded-md overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
        >
          <Image
            removeWrapper
            alt="Card background"
            className="z-0 w-full h-full object-cover"
            src={require("../../../../Img/BSA.jpg")}
          />
          <CardFooter className="absolute bg-[#72539b]/80 bottom-0 flex-wrap z-10 rounded-md">
            <div className="flex flex-grow gap-2 items-center">
              <div className="flex flex-col">
                <p className=" text-medium text-white/90">idagfsdkjfnsda</p>
              </div>
            </div>
            <button
              // onClick={() => handleEditClick(subj)}
              className="bg-[#FFDE59] opacity-100 rounded-lg p-3 py-2 text-black hover:bg-[#fcd84b] text-sm"
            >
              Edit
            </button>
          </CardFooter>{" "}
        </Card>{" "}
      </Skeleton> */}
      <Skeleton isLoaded={false} className=" w-full rounded-lg ">
        <Card
          shadow="sm"
          className=" rounded-md overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
        >
          <Image
            removeWrapper
            alt="Card background"
            className="z-0 w-full h-full object-cover"
            src={require("../../../../Img/BSA.jpg")}
          />
          <CardFooter className="absolute bg-[#72539b]/80 bottom-0 flex-wrap z-10 rounded-md">
            <div className="flex flex-grow gap-2 items-center">
              <div className="flex flex-col">
                <p className=" text-medium text-white/90">idagfsdkjfnsda</p>
              </div>
            </div>
            <button
              // onClick={() => handleEditClick(subj)}
              className="bg-[#FFDE59] opacity-100 rounded-lg p-3 py-2 text-black hover:bg-[#fcd84b] text-sm"
            >
              Edit
            </button>
          </CardFooter>{" "}
        </Card>{" "}
      </Skeleton>
      <Skeleton isLoaded={false} className=" w-full rounded-lg ">
        <Card
          shadow="sm"
          className=" rounded-md overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
        >
          <Image
            removeWrapper
            alt="Card background"
            className="z-0 w-full h-full object-cover"
            src={require("../../../../Img/BSA.jpg")}
          />
          <CardFooter className="absolute bg-[#72539b]/80 bottom-0 flex-wrap z-10 rounded-md">
            <div className="flex flex-grow gap-2 items-center">
              <div className="flex flex-col">
                <p className=" text-medium text-white/90">idagfsdkjfnsda</p>
              </div>
            </div>
            <button
              // onClick={() => handleEditClick(subj)}
              className="bg-[#FFDE59] opacity-100 rounded-lg p-3 py-2 text-black hover:bg-[#fcd84b] text-sm"
            >
              Edit
            </button>
          </CardFooter>{" "}
        </Card>{" "}
      </Skeleton>
      <Skeleton isLoaded={false} className=" w-full rounded-lg ">
        <Card
          shadow="sm"
          className=" rounded-md overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
        >
          <Image
            removeWrapper
            alt="Card background"
            className="z-0 w-full h-full object-cover"
            src={require("../../../../Img/BSA.jpg")}
          />
          <CardFooter className="absolute bg-[#72539b]/80 bottom-0 flex-wrap z-10 rounded-md">
            <div className="flex flex-grow gap-2 items-center">
              <div className="flex flex-col">
                <p className=" text-medium text-white/90">idagfsdkjfnsda</p>
              </div>
            </div>
            <button
              // onClick={() => handleEditClick(subj)}
              className="bg-[#FFDE59] opacity-100 rounded-lg p-3 py-2 text-black hover:bg-[#fcd84b] text-sm"
            >
              Edit
            </button>
          </CardFooter>{" "}
        </Card>{" "}
      </Skeleton>
      <Skeleton isLoaded={false} className=" w-full rounded-lg ">
        <Card
          shadow="sm"
          className=" rounded-md overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
        >
          <Image
            removeWrapper
            alt="Card background"
            className="z-0 w-full h-full object-cover"
            src={require("../../../../Img/BSA.jpg")}
          />
          <CardFooter className="absolute bg-[#72539b]/80 bottom-0 flex-wrap z-10 rounded-md">
            <div className="flex flex-grow gap-2 items-center">
              <div className="flex flex-col">
                <p className=" text-medium text-white/90">idagfsdkjfnsda</p>
              </div>
            </div>
            <button
              // onClick={() => handleEditClick(subj)}
              className="bg-[#FFDE59] opacity-100 rounded-lg p-3 py-2 text-black hover:bg-[#fcd84b] text-sm"
            >
              Edit
            </button>
          </CardFooter>{" "}
        </Card>{" "}
      </Skeleton>
      <Skeleton isLoaded={false} className=" w-full rounded-lg ">
        <Card
          shadow="sm"
          className=" rounded-md overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
        >
          <Image
            removeWrapper
            alt="Card background"
            className="z-0 w-full h-full object-cover"
            src={require("../../../../Img/BSA.jpg")}
          />
          <CardFooter className="absolute bg-[#72539b]/80 bottom-0 flex-wrap z-10 rounded-md">
            <div className="flex flex-grow gap-2 items-center">
              <div className="flex flex-col">
                <p className=" text-medium text-white/90">idagfsdkjfnsda</p>
              </div>
            </div>
            <button
              // onClick={() => handleEditClick(subj)}
              className="bg-[#FFDE59] opacity-100 rounded-lg p-3 py-2 text-black hover:bg-[#fcd84b] text-sm"
            >
              Edit
            </button>
          </CardFooter>{" "}
        </Card>{" "}
      </Skeleton>
      <Skeleton isLoaded={false} className=" w-full rounded-lg ">
        <Card
          shadow="sm"
          className=" rounded-md overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
        >
          <Image
            removeWrapper
            alt="Card background"
            className="z-0 w-full h-full object-cover"
            src={require("../../../../Img/BSA.jpg")}
          />
          <CardFooter className="absolute bg-[#72539b]/80 bottom-0 flex-wrap z-10 rounded-md">
            <div className="flex flex-grow gap-2 items-center">
              <div className="flex flex-col">
                <p className=" text-medium text-white/90">idagfsdkjfnsda</p>
              </div>
            </div>
            <button
              // onClick={() => handleEditClick(subj)}
              className="bg-[#FFDE59] opacity-100 rounded-lg p-3 py-2 text-black hover:bg-[#fcd84b] text-sm"
            >
              Edit
            </button>
          </CardFooter>{" "}
        </Card>{" "}
      </Skeleton>
      <Skeleton isLoaded={false} className=" w-full rounded-lg ">
        <Card
          shadow="sm"
          className=" rounded-md overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
        >
          <Image
            removeWrapper
            alt="Card background"
            className="z-0 w-full h-full object-cover"
            src={require("../../../../Img/BSA.jpg")}
          />
          <CardFooter className="absolute bg-[#72539b]/80 bottom-0 flex-wrap z-10 rounded-md">
            <div className="flex flex-grow gap-2 items-center">
              <div className="flex flex-col">
                <p className=" text-medium text-white/90">idagfsdkjfnsda</p>
              </div>
            </div>
            <button
              // onClick={() => handleEditClick(subj)}
              className="bg-[#FFDE59] opacity-100 rounded-lg p-3 py-2 text-black hover:bg-[#fcd84b] text-sm"
            >
              Edit
            </button>
          </CardFooter>{" "}
        </Card>{" "}
      </Skeleton>
      <Skeleton isLoaded={false} className=" w-full rounded-lg ">
        <Card
          shadow="sm"
          className=" rounded-md overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
        >
          <Image
            removeWrapper
            alt="Card background"
            className="z-0 w-full h-full object-cover"
            src={require("../../../../Img/BSA.jpg")}
          />
          <CardFooter className="absolute bg-[#72539b]/80 bottom-0 flex-wrap z-10 rounded-md">
            <div className="flex flex-grow gap-2 items-center">
              <div className="flex flex-col">
                <p className=" text-medium text-white/90">idagfsdkjfnsda</p>
              </div>
            </div>
            <button
              // onClick={() => handleEditClick(subj)}
              className="bg-[#FFDE59] opacity-100 rounded-lg p-3 py-2 text-black hover:bg-[#fcd84b] text-sm"
            >
              Edit
            </button>
          </CardFooter>{" "}
        </Card>{" "}
      </Skeleton>
      <Skeleton isLoaded={false} className=" w-full rounded-lg ">
        <Card
          shadow="sm"
          className=" rounded-md overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
        >
          <Image
            removeWrapper
            alt="Card background"
            className="z-0 w-full h-full object-cover"
            src={require("../../../../Img/BSA.jpg")}
          />
          <CardFooter className="absolute bg-[#72539b]/80 bottom-0 flex-wrap z-10 rounded-md">
            <div className="flex flex-grow gap-2 items-center">
              <div className="flex flex-col">
                <p className=" text-medium text-white/90">idagfsdkjfnsda</p>
              </div>
            </div>
            <button
              // onClick={() => handleEditClick(subj)}
              className="bg-[#FFDE59] opacity-100 rounded-lg p-3 py-2 text-black hover:bg-[#fcd84b] text-sm"
            >
              Edit
            </button>
          </CardFooter>{" "}
        </Card>{" "}
      </Skeleton>
      <Skeleton isLoaded={false} className=" w-full rounded-lg ">
        <Card
          shadow="sm"
          className=" rounded-md overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
        >
          <Image
            removeWrapper
            alt="Card background"
            className="z-0 w-full h-full object-cover"
            src={require("../../../../Img/BSA.jpg")}
          />
          <CardFooter className="absolute bg-[#72539b]/80 bottom-0 flex-wrap z-10 rounded-md">
            <div className="flex flex-grow gap-2 items-center">
              <div className="flex flex-col">
                <p className=" text-medium text-white/90">idagfsdkjfnsda</p>
              </div>
            </div>
            <button
              // onClick={() => handleEditClick(subj)}
              className="bg-[#FFDE59] opacity-100 rounded-lg p-3 py-2 text-black hover:bg-[#fcd84b] text-sm"
            >
              Edit
            </button>
          </CardFooter>{" "}
        </Card>{" "}
      </Skeleton>
      <Skeleton isLoaded={false} className=" w-full rounded-lg ">
        <Card
          shadow="sm"
          className=" rounded-md overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
        >
          <Image
            removeWrapper
            alt="Card background"
            className="z-0 w-full h-full object-cover"
            src={require("../../../../Img/BSA.jpg")}
          />
          <CardFooter className="absolute bg-[#72539b]/80 bottom-0 flex-wrap z-10 rounded-md">
            <div className="flex flex-grow gap-2 items-center">
              <div className="flex flex-col">
                <p className=" text-medium text-white/90">idagfsdkjfnsda</p>
              </div>
            </div>
            <button
              // onClick={() => handleEditClick(subj)}
              className="bg-[#FFDE59] opacity-100 rounded-lg p-3 py-2 text-black hover:bg-[#fcd84b] text-sm"
            >
              Edit
            </button>
          </CardFooter>{" "}
        </Card>{" "}
      </Skeleton>
      <Skeleton isLoaded={false} className=" w-full rounded-lg ">
        <Card
          shadow="sm"
          className=" rounded-md overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
        >
          <Image
            removeWrapper
            alt="Card background"
            className="z-0 w-full h-full object-cover"
            src={require("../../../../Img/BSA.jpg")}
          />
          <CardFooter className="absolute bg-[#72539b]/80 bottom-0 flex-wrap z-10 rounded-md">
            <div className="flex flex-grow gap-2 items-center">
              <div className="flex flex-col">
                <p className=" text-medium text-white/90">idagfsdkjfnsda</p>
              </div>
            </div>
            <button
              // onClick={() => handleEditClick(subj)}
              className="bg-[#FFDE59] opacity-100 rounded-lg p-3 py-2 text-black hover:bg-[#fcd84b] text-sm"
            >
              Edit
            </button>
          </CardFooter>{" "}
        </Card>{" "}
      </Skeleton>
    </>
  );
}
