import { useEffect, useState } from "react";
import { useAuth } from "../../../utils/AppwriteConfig/AuthContext";
import { Skeleton, Spinner } from "@nextui-org/react";
import axios from "axios";
import useRealtimeSubscription from "../../../utils/AppwriteConfig/RealtimeServices";

export default function Bio() {
  const { user, server } = useAuth();

  const [isLoading, setisLoading] = useState(false);
  const [MainError, setMainError] = useState();
  const [Errors, setErrors] = useState({});

  const [UserCred, setUserCred] = useState({});
  const [Bio, setBio] = useState();

  useEffect(() => {
    async function GetUserCred() {
      try {
        
        setisLoading(true);
        const usercred = await axios.get(`${server}/v1/usercred`, {
          params: { accid: user.$id },
        });

        setUserCred(usercred.data);
        setBio(usercred.data.bio);
      } catch (e) {
        setMainError(e.message);
      } finally {
        setisLoading(false);
      }
    }
    GetUserCred();
  }, [user]);

  const handleRealtimeChanges = (changes) => {
    if (changes.changetype === "update" && changes.payload.bio) {
      setBio(changes.payload.bio);
    } else if (changes.changetype === "update" && !changes.payload.bio) {
      setBio("");
    }
  };

  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_USERCRED,
    handleRealtimeChanges
  );
  return (
    <div className="flex p-2 flex-col space-y-1">
      <Skeleton isLoaded={!isLoading} className=" rounded-lg w-11 bg-[#72539b]">
        <label className="text-lg  font-normal">Bio</label>
      </Skeleton>
      {isLoading ? (
        <Skeleton isLoaded={!isLoading} className="rounded-lg">
          <div className=" w-full bg-slate-200 h-16 rounded-lg flex justify-center items-center h-18 rounded-lg">
            <Spinner size="md" content="Loading..."></Spinner>
          </div>
        </Skeleton>
      ) : (
        <div className=" bg-slate-200 p-4 rounded-lg min-h-16">{Bio}</div>
      )}
    </div>
  );
}
