import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  birthdateformat,
  calculateAge
} from "../../../../utils/helpers/stringformat";
import CurrentID from "../components/renders/CurrentIDrender";
import User from "../../../../Components/skeleton/user";
import axios from "axios";
import { useAuth } from "../../../../utils/AppwriteConfig/AuthContext";
import Validate from "../../../../utils/helpers/edituserValidation";
import Confirmation from "../../../../Components/modals/SaveConfirm";
import Cancelation from "../../../../Components/modals/Cancel";
import Error from "../../../../Components/ErrorIndicator";
import ChangeEmailModal from "../components/modal/ChangeEmailModal";
import ResetPasswordModal from "../components/modal/ResetPasswordModal";
import { functions } from "../../../../utils/AppwriteConfig/Config";
import { Chip } from "@nextui-org/react";
import TeacherExperience from "../components/renders/TeacherExperiences";
//Add confirmation when canceld or saved
export default function EditUser({ setEditState, setCurrentRole }) {
  const { users, id, action } = useParams();
  let role = users.slice(0, -1).toLocaleLowerCase();
  const { server, DataServer, user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const [Edit, SetEdit] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [Errors, SetErrors] = useState({});
  const [ErrorsCourse, SetErrorsCourse] = useState({});
  const [MainError, setMainError] = useState();

  const [renderedrole, setrenderedrole] = useState();
  const [Userinfo, SetUserInfo] = useState({});
  const [changes, setchanges] = useState({});
  const [isAccValid, setisAccValid] = useState(false);

  //Used to change action on first render
  useEffect(() => {
    if (action === "edit") {
      SetEdit(true);
    } else {
      SetEdit(false);
    }
  }, []);

  //Pass the Edit value to parent
  useEffect(() => {
    setEditState(Edit);
  }, [Edit]);

  useEffect(() => {
    setEditState(Edit);
  }, [Edit]);

  //GIve the role in the parent component
  useEffect(() => {
    setCurrentRole(Userinfo?.userCredentials?.role);
  }, [Userinfo]);

  //Get specific user data
  useEffect(() => {
    setisLoading(true);
    async function fetchUser() {
      try {
        //If the data comes from archived table, it will get the role in usercred table
        if (role === "archive" || role === "account") {
          // const usercred = await axios.get(`${server}/v1/archive/user/role`, {
          //   params: { id: id },
          // });
          const body = { id: id };
          // const usercred = await functions.createExecution(
          //   "667bc0d000106435b059",
          //   body,
          //   false,
          //   `/User/v1/archive/user/role`,
          //   "GET"
          // );
          const usercred = await axios.get(
            `${DataServer}/user/archive/role`,
            {
              params: body
            }
          );
          //usercred.responseBody = usercred.responseBody.replace(/"/g, "");
          //usercred.responseBody = [usercred.responseBody.replace("\"/g","")]
          console.log(usercred.data);
          if (
            usercred.data.includes("Adminhead") ||
            usercred.data.includes("Registrar") ||
            usercred.data.includes("Scheduler")
          ) {
            role = "employee";
          } else {
            role = usercred.data.toLocaleLowerCase();
          }
        }
        setrenderedrole(role);

        //Get data based on role and UserID
        let UserDetails;
        // if (users === "accounts") {
        //   UserDetails = await axios.get(`${server}/v1/${role}`, {
        //     params: { id: id },
        //   });
        // } else {
        UserDetails = await axios.get(
          `${DataServer}/user/${role}/${id}/data`
        );

        // const body = JSON.stringify({ id: id });
        // UserDetails = await functions.createExecution(
        //   "667bc0d000106435b059",
        //   body,
        //   false,
        //   `/User/v1/${role}`,
        //   "GET"
        // );
        // UserDetails.responseBody = JSON.parse(UserDetails.responseBody);
        // console.log(changes);
        SetUserInfo(UserDetails.data);
        console.log(UserDetails.data);
        const merge = { ...changes, ...UserDetails.data };
        // console.log(merge);
        setchanges({ ...merge, creator: user.$id });
      } catch (error) {
        // Handle any errors
        setMainError(error.message);
        console.log(error);
      } finally {
        setisLoading(false);
      }
    }
    fetchUser();
  }, [users, id, Edit]);

  //set role on render
  useEffect(() => {
    if (role === "teacher" || role === "student") {
      setchanges({ ...changes, role: users.slice(0, -1) });
    } else if (Userinfo.userCredentials) {
      setchanges({ ...changes, role: Userinfo.userCredentials.role });
    }
  }, [Userinfo]);

  //handler in birthdate
  const handleInputChange = (e) => {
    const birthdate = e.target.value;
    const age = calculateAge(birthdate);
    console.log(age);
    setchanges({ ...changes, Birthdate: birthdate, Age: age });
  };

  //cancel clicked
  const cancel = () => {
    SetEdit(false);
    SetErrors({});
  };
  //Save clicked
  const save = () => {
    document.querySelector("#savebtn").click();
  };

  useEffect(() => {
    try {
      if (Object.keys(changes).length > 0) {
        console.log("GUMANA");
        SetErrors(Validate(changes, users));
      }
    } catch (err) {
      console.log(err);
    }
  }, [changes, Userinfo, users]);
  console.log("g");
  //save handler
  const handleSave = async (e) => {
    e.preventDefault();
    let errors;
    errors = Validate(changes, users);

    SetErrors(errors);
    // Check the updated state value after setting the errors
    console.log(changes);
    if (
      Object.keys(errors).length === 0 &&
      Object.keys(ErrorsCourse).length === 0
    ) {
      setisLoading(true);
      console.log("No errors, proceed with saving.");
      //const response = await axios.post(`${server}/v1/${role}/update`, changes);
      const response = await updateuser(changes);
      console.log("User created:", response.data);
      setisLoading(false);
      SetEdit(false);
    } else {
      console.log("Errors found:", errors);
      // Optionally, you can handle errors in UI or perform other actions
    }
  };
  useEffect(() => {
    console.log(changes);
  }, [changes]);

  async function updateuser(changes) {
    const body = changes;
    // const response = await functions.createExecution(
    //   "667bc0d000106435b059",
    //   body,
    //   false,
    //   `/User/v2/${role}/update`,
    //   "POST"
    // );
    const response = await axios.put(
      `${DataServer}/user/${role}/${id}/update`,
      body
    );
    console.log(response);
    return response;
  }

  const GetAccProf = (
    NewTeachedSubjects,
    RemovedTeachedSubjectIDs,
    NewPreferredSubejcts,
    RemovedPreferredSubejctsIDs,
    Degree,
    err
  ) => {
    console.log(NewTeachedSubjects);
    setchanges((prevUser) => ({
      ...prevUser, // Retain previous data
      degree: Degree, // Add new data or update existing data
      newteachedSubjects: NewTeachedSubjects,
      removedteachedSubjects: RemovedTeachedSubjectIDs,
      newpreferredSubjects: NewPreferredSubejcts,
      removedpreferredSubjects: RemovedPreferredSubejctsIDs
    }));
    SetErrorsCourse(err);
    console.log(
      "HERREEE:",
      "t",
      NewTeachedSubjects.filter((subj) => subj.id.startsWith("new-")),
      RemovedTeachedSubjectIDs,
      "a",
      NewPreferredSubejcts,
      RemovedPreferredSubejctsIDs,
      Degree
    );
  };

  return (
    <div className="flex flex-col h-full bg-white rounded-md w-full">
      {MainError && <Error errorMessage={MainError} />}
      <div className="flex justify-between bg-[#A16AE8] py-4 pr-2 rounded-lg">
        <div className="flex items-center">
          <Link
            className={`p-2 hover:bg-[#8048c9] m-2 rounded-full ${
              Edit ? "hidden" : ""
            }`}
            to={`/user/employee/manage/${users}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="white"
              class="bi bi-chevron-left"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
              />
            </svg>
          </Link>
          <div className={`flex flex-col ${Edit ? "pl-4" : ""}`}>
            <div className="text-xl font-bold capitalize text-white sm:text-xl dark:text-white">
              {Edit
                ? `Edit ${users.slice(0, -1)}`
                : `${users.slice(0, -1)} Information`}
            </div>
            <p className=" ml-3 text-white dark:text-gray-400">
              {Userinfo &&
                Userinfo.userCredentials &&
                Userinfo.userCredentials.Username}{" "}
              {Userinfo.studentId}
              {Userinfo.teacherId}
              {Userinfo.employeeId}
            </p>
          </div>
        </div>
        <div className="flex">
          {!isLoading && (
            <>
              {Edit ? (
                <div className={`flex items-center  ${Edit ? "" : "hidden"}`}>
                  <Cancelation navigate={cancel} />
                  <Confirmation user={changes} save={save} />
                </div>
              ) : (
                <div className={`  items-center flex ${Edit ? "hidden" : ""}`}>
                  {users !== "archived" && users !== "accounts" && (
                    <button
                      onClick={() => {
                        SetEdit(!Edit);
                      }}
                      className="p-2 rounded-md flex text-white justify-center items-center space-x-2 hover:bg-[#8048c9]"
                    >
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="white"
                          class="bi bi-pencil-square"
                          viewBox="0 0 16 16"
                        >
                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                          <path
                            fill-rule="evenodd"
                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                          />
                        </svg>
                      </span>{" "}
                      <span className="pl-1 text-md">Edit</span>
                    </button>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {isLoading ? (
        <User />
      ) : (
        <form
          className=" w-full h-full  p-4 overflow-auto "
          onSubmit={(e) => {
            e.preventDefault();
          }}
          noValidate
        >
          <button id="savebtn" className="hidden" onClick={handleSave}></button>
          <div className="flex flex-col">
            {/* Forms */}
            <div className="">
              {/* User Basic INformation */}
              <span className="text-md font-meduim  ">User information</span>

              <div className="border-b-2 border-[#FFDE59] mt-1"></div>

              {/* Name */}
              <div className="flex flex-1 flex-col gap-2 sm:flex-row p-2">
                <div className="flex-1  ">
                  {/* Firstname */}
                  <div>
                    <label
                      htmlFor="Firstname"
                      className="block mb-2 text-md text-gray-700 dark:text-white"
                    >
                      First name
                    </label>
                    {!Edit ? (
                      <span className="py-3 px-4 min-h-[48.5px] bg-slate-100 overflow-auto [&::-webkit-scrollbar]:h-2 [&::-webkit-scrollbar-thumb]:rounded-full  [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-slate-700  dark:[&::-webkit-scrollbar-thumb]:bg-slate-500 bg-slate-100 block capitalize w-full whitespace-nowrap  block capitalize w-full  border-gray-200 rounded-lg">
                        {Userinfo.firstName}
                      </span>
                    ) : (
                      <>
                        <input
                          type="text"
                          name="Firstname"
                          id="Firstname"
                          className={`py-2 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
                            Errors.firstName == null
                              ? "border-slate-200"
                              : "border-red-500"
                          }`}
                          required
                          defaultValue={Userinfo.firstName}
                          onChange={(e) =>
                            setchanges({
                              ...changes,
                              firstName: e.target.value
                            })
                          }
                        />
                        {Errors.firstName && (
                          <span className="text-red-500 text-sm mt-1">
                            {Errors.firstName}
                          </span>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="  ">
                  {/* Middlename */}
                  <div>
                    <label
                      htmlFor="Middlename"
                      className="block mb-2 text-md text-gray-700 dark:text-white"
                    >
                      Middle name
                    </label>
                    {Edit ? (
                      <input
                        type="text"
                        name="Middlename"
                        id="Middlename"
                        className={`py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 `}
                        required
                        defaultValue={Userinfo.middleName}
                        onChange={(e) =>
                          setchanges({
                            ...changes,
                            middleName: e.target.value
                          })
                        }
                      />
                    ) : (
                      <span className="py-3 px-4 min-h-[48.5px] bg-slate-100  overflow-auto [&::-webkit-scrollbar]:h-2 [&::-webkit-scrollbar-thumb]:rounded-full  [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-slate-700  dark:[&::-webkit-scrollbar-thumb]:bg-slate-500 bg-slate-100 block capitalize w-full whitespace-nowrap  block capitalize w-full  border-gray-200 rounded-lg">
                        {Userinfo.middleName}
                      </span>
                    )}
                  </div>
                </div>
                <div className="flex-1 ">
                  {/* Lastname */}
                  <div>
                    <label
                      htmlFor="Lastname"
                      className="block mb-2 text-md text-gray-700 dark:text-white"
                    >
                      Last name
                    </label>
                    {Edit ? (
                      <>
                        <input
                          type="text"
                          name="Lastname"
                          id="Lastname"
                          className={`py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 
                        ${
                          Errors.lastName == null
                            ? "border-gray-200"
                            : "border-red-500"
                        }
              `}
                          required
                          defaultValue={Userinfo.lastName}
                          onChange={(e) =>
                            setchanges({ ...changes, lastName: e.target.value })
                          }
                        />
                        {Errors.lastName && (
                          <span className="text-red-500 text-sm mt-1">
                            {Errors.lastName}
                          </span>
                        )}
                      </>
                    ) : (
                      <span className="py-3 px-4 min-h-[48.5px] bg-slate-100 overflow-auto [&::-webkit-scrollbar]:h-2 [&::-webkit-scrollbar-thumb]:rounded-full  [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-slate-700  dark:[&::-webkit-scrollbar-thumb]:bg-slate-500 bg-slate-100 block capitalize w-full whitespace-nowrap  block capitalize w-full  border-gray-200 rounded-lg">
                        {Userinfo.lastName}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex flex-1 flex-col sm:flex-row p-2 mb-3">
                <div className=" flex-grow-[1] m-1">
                  <label
                    htmlFor="birthdate"
                    className="block mb-2 text-sm text-gray-700 dark:text-white"
                  >
                    Contact Number
                  </label>
                  {Edit ? (
                    <div className="flex rounded-lg shadow-sm">
                      <span
                        className={`px-4 inline-flex items-center min-w-fit rounded-s-md border bg-slate-300 border-e-0 border-gray-200 text-sm dark:text-gray-400 ${
                          Errors.contactNumber
                            ? "border-red-500"
                            : "border-gray-200"
                        }`}
                      >
                        +63
                      </span>
                      <input
                        type="text"
                        defaultValue={Userinfo.contactNumber}
                        name="contactNumber"
                        id="contactNumber"
                        className={`py-2 px-3 pe-11 block w-full border shadow-sm rounded-e-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
                          Errors.contactNumber
                            ? "border-red-500"
                            : "border-gray-200"
                        }`}
                        onKeyPress={(event) => {
                          if (
                            event.key === "0" &&
                            changes.contactNumber.length === 0
                          ) {
                            console.log("gg");
                            event.preventDefault(); // Prevents entering '0' as the first digit
                          } else if (!/^\d$/.test(event.key)) {
                            console.log("ggss");
                            event.preventDefault(); // Prevents entering non-digit characters
                          } else if (changes.contactNumber.length >= 10) {
                            console.log("gggfd");
                            event.preventDefault(); // Prevents entering more than 10 digits
                          }
                        }}
                        required
                        onChange={(e) => {
                          const userInput = e.target.value;
                          if (userInput.length === 1 && userInput[0] === "0") {
                            setchanges({
                              ...changes,
                              contactNumber: ""
                            });
                          } else {
                            setchanges({
                              ...changes,
                              contactNumber: userInput
                            });
                          }
                        }}
                      />
                    </div>
                  ) : (
                    <span className="py-3 px-4 min-h-[48.5px] bg-slate-100 overflow-auto [&::-webkit-scrollbar]:h-2 [&::-webkit-scrollbar-thumb]:rounded-full  [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-slate-700  dark:[&::-webkit-scrollbar-thumb]:bg-slate-500 bg-slate-100 block capitalize w-full whitespace-nowrap  block capitalize w-full  border-gray-200 rounded-lg">
                      {`+63${Userinfo.contactNumber}`}
                    </span>
                  )}
                </div>
              </div>
              {/* Additional info */}
              <div className="flex flex-1 flex-col sm:flex-row p-2 mb-3">
                {/* Birthdate */}
                <div className=" flex-grow-[1] m-1">
                  <label
                    htmlFor="birthdate"
                    className="block mb-2 text-sm text-gray-700 dark:text-white"
                  >
                    Birthdate {Edit&&"(DD/MM/YYYY)"}
                  </label>
                  {Edit ? (
                    <>
                      <input
                        type="date"
                        name="birthdate"
                        id="birthdate"
                        className={`py-3 px-4 min-h-[48.5px] bg-slate-100 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 
                      ${
                        Errors.birthDate == null
                          ? "border-gray-200"
                          : "border-red-500"
                      }
              `}
                        required
                        defaultValue={birthdateformat(
                          Userinfo.birthDate,
                          "format"
                        )}
                        onChange={handleInputChange}
                        onBlur={(e) =>
                          setchanges({
                            ...changes,
                            birthDate: e.target.value,
                            Age: calculateAge(e.target.value)
                          })
                        }
                      />
                      {Errors.birthDate && (
                        <span className="text-red-500 text-sm mt-1">
                          {Errors.birthDate}
                        </span>
                      )}
                    </>
                  ) : (
                    <span className="py-3 px-4 min-h-[48.5px] bg-slate-100 overflow-auto [&::-webkit-scrollbar]:h-2 [&::-webkit-scrollbar-thumb]:rounded-full  [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-slate-700  dark:[&::-webkit-scrollbar-thumb]:bg-slate-500 bg-slate-100 block capitalize w-full whitespace-nowrap  block capitalize w-full  border-gray-200 rounded-lg">
                      {birthdateformat(Userinfo.birthDate)}
                    </span>
                  )}
                </div>

                {/* Age */}
                <div className="flex-initial m-1">
                  <label
                    htmlFor="Lastname"
                    className="block mb-2 text-sm text-gray-700 dark:text-white"
                  >
                    Age
                  </label>
                  <span className="py-3 px-4 min-h-[48.5px] bg-slate-100 overflow-auto [&::-webkit-scrollbar]:h-2 [&::-webkit-scrollbar-thumb]:rounded-full  [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-slate-700  dark:[&::-webkit-scrollbar-thumb]:bg-slate-500 bg-slate-100 block capitalize w-full whitespace-nowrap  block bg-slate-100 w-full  border-gray-200 rounded-lg">
                    {Userinfo.age}
                    {Userinfo.Age}
                  </span>
                </div>

                {/* Gender */}
                <div className="flex-1 m-1">
                  <label
                    htmlFor="Gender"
                    className="block mb-2 text-sm text-gray-700 dark:text-white"
                  >
                    Gender
                  </label>
                  {Edit ? (
                    <>
                      <div className="relative">
                        <select
                          className={`py-3 px-4 min-h-[48.5px] bg-slate-100 pe-9 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 
                        ${
                          Errors.gender == null
                            ? "border-gray-200"
                            : "border-red-500"
                        }
              `}
                          defaultValue={Userinfo.gender}
                          onChange={(e) =>
                            setchanges({
                              ...changes,
                              gender: e.target.value
                            })
                          }
                        >
                          <option value="" disabled selected hidden>
                            Select Gender
                          </option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                        <div className="absolute top-1/2 end-3 -translate-y-1/2">
                          <svg
                            className="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="m7 15 5 5 5-5" />
                            <path d="m7 9 5-5 5 5" />
                          </svg>
                        </div>
                      </div>
                      {Errors.gender && (
                        <span className="text-red-500 text-sm mt-1">
                          {Errors.gender}
                        </span>
                      )}
                    </>
                  ) : (
                    <span className="py-3 px-4 min-h-[48.5px] bg-slate-100 overflow-auto [&::-webkit-scrollbar]:h-2 [&::-webkit-scrollbar-thumb]:rounded-full  [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-slate-700  dark:[&::-webkit-scrollbar-thumb]:bg-slate-500 bg-slate-100 block capitalize w-full whitespace-nowrap  block capitalize w-full  border-gray-200 rounded-lg">
                      {Userinfo.gender}
                    </span>
                  )}
                </div>
              </div>

              {/* Address Info */}
              <span className="text-md font-meduim">Address</span>

              <div className="border-b-2 border-[#FFDE59] mt-1"></div>

              <div className="flex flex-1 flex-col p-2">
                <div className="flex-1 m-1">
                  <div className="flex flex-col sm:flex-row">
                    <div className="flex-1 m-1">
                      <label
                        htmlFor="block"
                        className="block mb-2 text-md text-gray-700 dark:text-white"
                      >
                        Block
                      </label>
                      {Edit ? (
                        <>
                          <input
                            type="text"
                            name="block"
                            id="block"
                            className={`py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 
${Errors.block == null ? "border-gray-200" : "border-red-500"}
              `}
                            required
                            defaultValue={Userinfo.block}
                            onChange={(e) =>
                              setchanges({
                                ...changes,
                                block: e.target.value
                              })
                            }
                          />
                          {Errors.block && (
                            <span className="text-red-500 text-sm mt-1">
                              {Errors.block}
                            </span>
                          )}
                        </>
                      ) : (
                        <span className="py-3 px-4 min-h-[48.5px] bg-slate-100 overflow-auto [&::-webkit-scrollbar]:h-2 [&::-webkit-scrollbar-thumb]:rounded-full  [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-slate-700  dark:[&::-webkit-scrollbar-thumb]:bg-slate-500 bg-slate-100 block capitalize w-full whitespace-nowrap  block capitalize w-full  border-gray-200 rounded-lg">
                          {Userinfo.block}
                        </span>
                      )}
                    </div>
                    <div className="flex-1 m-1">
                      <label
                        htmlFor="lot"
                        className="block mb-2 text-md text-gray-700 dark:text-white"
                      >
                        Lot
                      </label>
                      {Edit ? (
                        <>
                          <input
                            type="text"
                            name="lot"
                            id="lot"
                            className={`py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 
${Errors.lot == null ? "border-gray-200" : "border-red-500"}
              `}
                            required
                            defaultValue={Userinfo.lot}
                            onChange={(e) =>
                              setchanges({
                                ...changes,
                                lot: e.target.value
                              })
                            }
                          />
                          {Errors.lot && (
                            <span className="text-red-500 text-sm mt-1">
                              {Errors.lot}
                            </span>
                          )}
                        </>
                      ) : (
                        <span className="py-3 px-4 min-h-[48.5px] bg-slate-100 overflow-auto [&::-webkit-scrollbar]:h-2 [&::-webkit-scrollbar-thumb]:rounded-full  [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-slate-700  dark:[&::-webkit-scrollbar-thumb]:bg-slate-500 bg-slate-100 block capitalize w-full whitespace-nowrap  block capitalize w-full whitespace-nowrap overflow-auto  border-gray-200 rounded-lg">
                          {Userinfo.lot}
                        </span>
                      )}
                    </div>
                    <div className="flex-1 m-1">
                      <label
                        htmlFor="street"
                        className="block mb-2 text-md text-gray-700 dark:text-white"
                      >
                        Street
                      </label>
                      {Edit ? (
                        <input
                          type="text"
                          name="street"
                          id="street"
                          className={`py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 `}
                          required
                          defaultValue={Userinfo.street}
                          onChange={(e) =>
                            setchanges({
                              ...changes,
                              street: e.target.value
                            })
                          }
                        />
                      ) : (
                        <span className="py-3 px-4 min-h-[48.5px] max-w-[250px] overflow-auto [&::-webkit-scrollbar]:h-2 [&::-webkit-scrollbar-thumb]:rounded-full  [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-slate-700  dark:[&::-webkit-scrollbar-thumb]:bg-slate-500 bg-slate-100 block capitalize w-full whitespace-nowrap   border-gray-200 rounded-lg">
                          {Userinfo.street}
                        </span>
                      )}
                    </div>
                    <div className="flex-1 m-1">
                      <label
                        htmlFor="subdivision"
                        className="block mb-2 text-md text-gray-700 dark:text-white"
                      >
                        Subdivision
                      </label>
                      {Edit ? (
                        <>
                          <input
                            type="text"
                            name="subdivision"
                            id="subdivision"
                            className={`py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 
                              ${
                                Errors.subdivision == null
                                  ? "border-gray-200"
                                  : "border-red-500"
                              }    
                            `}
                            required
                            defaultValue={Userinfo.subdivision}
                            onChange={(e) =>
                              setchanges({
                                ...changes,
                                subdivision: e.target.value
                              })
                            }
                          />
                          {Errors.subdivision && (
                            <span className="text-red-500 text-sm mt-1">
                              {Errors.subdivision}
                            </span>
                          )}
                        </>
                      ) : (
                        <span className="py-3 px-4 min-h-[48.5px] bg-slate-100 overflow-auto [&::-webkit-scrollbar]:h-2 [&::-webkit-scrollbar-thumb]:rounded-full  [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-slate-700  dark:[&::-webkit-scrollbar-thumb]:bg-slate-500 bg-slate-100 block capitalize w-full whitespace-nowrap  block capitalize w-full  border-gray-200 rounded-lg overflow-auto">
                          {Userinfo.subdivision}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex-1 m-1">
                  <div className="flex flex-col sm:flex-row">
                    <div className="flex-1 m-1">
                      <label
                        htmlFor="barangay"
                        className="block mb-2 text-md text-gray-700 dark:text-white"
                      >
                        Barangay
                      </label>
                      {Edit ? (
                        <>
                          <input
                            type="text"
                            name="barangay"
                            id="barangay"
                            className={`py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 
${Errors.barangay == null ? "border-gray-200" : "border-red-500"}
              `}
                            required
                            defaultValue={Userinfo.barangay}
                            onChange={(e) =>
                              setchanges({
                                ...changes,
                                barangay: e.target.value
                              })
                            }
                          />
                          {Errors.barangay && (
                            <span className="text-red-500 text-sm mt-1">
                              {Errors.barangay}
                            </span>
                          )}
                        </>
                      ) : (
                        <span className="py-3 px-4 min-h-[48.5px] bg-slate-100 overflow-auto [&::-webkit-scrollbar]:h-2 [&::-webkit-scrollbar-thumb]:rounded-full  [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-slate-700  dark:[&::-webkit-scrollbar-thumb]:bg-slate-500 bg-slate-100 block capitalize w-full whitespace-nowrap  block capitalize w-full  border-gray-200 rounded-lg">
                          {Userinfo.barangay}
                        </span>
                      )}
                    </div>
                    <div className="flex-1 m-1">
                      <label
                        htmlFor="city"
                        className="block mb-2 text-md text-gray-700 dark:text-white"
                      >
                        City
                      </label>
                      {Edit ? (
                        <>
                          <input
                            type="text"
                            name="city"
                            id="city"
                            className={`py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 
${Errors.city == null ? "border-gray-200" : "border-red-500"}
              `}
                            required
                            defaultValue={Userinfo.city}
                            onChange={(e) =>
                              setchanges({
                                ...changes,
                                city: e.target.value
                              })
                            }
                          />
                          {Errors.city && (
                            <span className="text-red-500 text-sm mt-1">
                              {Errors.city}
                            </span>
                          )}
                        </>
                      ) : (
                        <span className="py-3 px-4 min-h-[48.5px] bg-slate-100 overflow-auto [&::-webkit-scrollbar]:h-2 [&::-webkit-scrollbar-thumb]:rounded-full  [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-slate-700  dark:[&::-webkit-scrollbar-thumb]:bg-slate-500 bg-slate-100 block capitalize w-full whitespace-nowrap  block capitalize w-full  border-gray-200 rounded-lg">
                          {Userinfo.city}
                        </span>
                      )}
                    </div>
                    <div className="flex-1 m-1">
                      <label
                        htmlFor="Country"
                        className="block mb-2 text-md text-gray-700 dark:text-white"
                      >
                        Country
                      </label>
                      <span className="py-3 px-4 min-h-[48.5px] bg-slate-100 overflow-auto [&::-webkit-scrollbar]:h-2 [&::-webkit-scrollbar-thumb]:rounded-full  [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-slate-700  dark:[&::-webkit-scrollbar-thumb]:bg-slate-500 bg-slate-100 block capitalize w-full whitespace-nowrap  block text-center bg-slate-100 w-full  border-gray-200 rounded-lg">
                        Philippines
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {/* Account Info */}
              <>
                <span className="text-md font-meduim">Account Information</span>

                <div className="border-b-2 border-[#FFDE59] mt-1"></div>

                <div className="flex flex-1 flex-col sm:flex-row p-2">
                  {/* ID
                ID depends on the users(student/teacher/employee) variable that is from the URL parameter 
                */}
                  <div className="flex-1 m-1">
                    <label
                      htmlFor="ID"
                      className="block mb-2 capitalize text-md text-gray-700 dark:text-white"
                    >
                      {renderedrole} ID
                    </label>

                    {/* //Render the latest ID to create new account */}
                    {renderedrole === "student" && (
                      <CurrentID ID={Userinfo.studentId} />
                    )}
                    {renderedrole === "teacher" && (
                      <CurrentID ID={Userinfo.teacherId} />
                    )}
                    {renderedrole === "employee" && (
                      <CurrentID ID={Userinfo.employeeId} />
                    )}

                    <input
                      type="text"
                      name="ID"
                      id="ID"
                      className={`py-2 hidden px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600
              ${Edit ? "" : "hidden"}`}
                      required
                      onChange={(e) =>
                        setchanges({ ...changes, ID: e.target.value })
                      }
                    />
                  </div>

                  {/* LRN ID */}
                  {users === "students" && (
                    <div className="flex-1 m-1">
                      <label
                        htmlFor="lrnID"
                        className="block mb-2 capitalize text-md text-gray-700 dark:text-white"
                      >
                        LRN ID
                      </label>
                      {Edit ? (
                        <>
                          <input
                            type="text"
                            name="lrnID"
                            id="lrnID"
                            className={`py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 
${Errors.lrnId == null ? "border-gray-200" : "border-red-500"}
              `}
                            required
                            defaultValue={Userinfo.lrnId}
                            onChange={(e) =>
                              setchanges({ ...changes, lrnId: e.target.value })
                            }
                          />
                          {Errors.lrnId && (
                            <span className="text-red-500 text-sm mt-1">
                              {Errors.lrnId}
                            </span>
                          )}
                        </>
                      ) : (
                        <span className="py-3 px-4 min-h-[48.5px] bg-slate-100 overflow-auto [&::-webkit-scrollbar]:h-2 [&::-webkit-scrollbar-thumb]:rounded-full  [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-slate-700  dark:[&::-webkit-scrollbar-thumb]:bg-slate-500 bg-slate-100 block capitalize w-full whitespace-nowrap  block capitalize w-full  border-gray-200 rounded-lg">
                          {Userinfo.lrnId}
                        </span>
                      )}
                    </div>
                  )}

                  {users === "teachers" && (
                    <>
                      <div className="flex-initial m-1">
                        <label
                          htmlFor="Department"
                          className="block mb-3 text-sm text-gray-700  dark:text-white"
                        >
                          Department{" "}
                          {Errors.department && (
                            <span className="text-red-500">*</span>
                          )}
                        </label>

                        {Edit ? (
                          <div className="relative">
                            <select
                              className={` pe-9 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 
                      ${
                        Errors.department == null
                          ? "border-gray-200"
                          : "border-red-500"
                      }
                      `}
                              onChange={(e) =>
                                setchanges({
                                  ...changes,
                                  department: e.target.value
                                })
                              }
                            >
                              <option value="" disabled hidden>
                                Select Department
                              </option>
                              <option
                                selected={Userinfo.department === "Pre"}
                                value="Pre"
                              >
                                Pre-School
                              </option>
                              <option
                                selected={Userinfo.department === "Elem"}
                                value="Elem"
                              >
                                Elementary
                              </option>
                              <option
                                selected={Userinfo.department === "JHS"}
                                value="JHS"
                              >
                                Junior High-School
                              </option>
                              <option
                                selected={Userinfo.department === "SHS"}
                                value="SHS"
                              >
                                Senior High-School
                              </option>
                            </select>
                          </div>
                        ) : (
                          <span className="py-3 px-4 min-h-[48.5px] capitalize text-center block bg-slate-100 w-full  border-gray-200 rounded-lg">
                            {Userinfo.department === "Pre"
                              ? "Pre-School"
                              : Userinfo.department === "Elem"
                              ? "Elementary"
                              : Userinfo.department === "JHS"
                              ? "Junior High-School"
                              : Userinfo.department === "SHS"
                              ? "Senior High-School"
                              : Userinfo.department}
                          </span>
                        )}
                      </div>
                      <div className="flex-initial m-1">
                        <label
                          for={`teachingExperience`}
                          className="block mb-3 text-sm text-gray-700  dark:text-white"
                        >
                          Experience in Teaching
                          {Errors.teachingExperience && (
                            <span className="text-red-500">*</span>
                          )}
                        </label>

                        {Edit ? (
                          <input
                            type="text" // Use type text
                            name={`teachingExperience`}
                            id={`teachingExperience`}
                            className={`py-2 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
                              Errors.teachingExperience == null
                                ? "border-slate-200"
                                : "border-red-500"
                            }`}
                            required
                            defaultValue={Userinfo.teachingExperience} // Use value instead of defaultValue
                            onChange={(e) =>
                              setchanges({
                                ...changes,
                                teachingExperience: e.target.value.replace(
                                  /\D/g,
                                  ""
                                ) // Ensure only numeric characters are stored in state
                              })
                            }
                            onKeyDown={(e) => {
                              const isNumeric = /^\d+$/.test(e.key);
                              const isBackspaceOrDelete =
                                e.key === "Backspace" || e.key === "Delete";
                              const isMaxLengthReached =
                                e.target.value.length >= 4;
                              const isInputEmpty = e.target.value === "";
                              // Allow only numeric keys, backspace, delete, arrow keys, and navigation keys
                              if (
                                (isNumeric || isBackspaceOrDelete) &&
                                (!isMaxLengthReached || isBackspaceOrDelete)
                              ) {
                                return;
                              } else {
                                e.preventDefault();
                              }
                            }}
                          />
                        ) : (
                          <span className="py-3 px-4 min-h-[48.5px] capitalize text-center block bg-slate-100 w-full  border-gray-200 rounded-lg">
                            {Userinfo.teachingExperience}
                          </span>
                        )}
                      </div>
                      <div className="flex-initial m-1">
                        <label
                          for={`HoursPerWeek`}
                          className="block mb-3 text-sm text-gray-700  dark:text-white"
                        >
                          Teaching hours per Week
                          {Errors.HoursPerWeek && (
                            <span className="text-red-500">*</span>
                          )}
                        </label>

                        {Edit ? (
                          <input
                            type="text" // Use type text
                            name={`HoursPerWeek`}
                            defaultValue={Userinfo.teachingHoursPerWeek}
                            id={`HoursPerWeek`}
                            className={`py-2 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
                              Errors.HoursPerWeek == null
                                ? "border-slate-200"
                                : "border-red-500"
                            }`}
                            required
                            onChange={(e) =>
                              setchanges({
                                ...changes,
                                teachingHoursPerWeek: e.target.value.replace(
                                  /\D/g,
                                  ""
                                ) // Ensure only numeric characters are stored in state
                              })
                            }
                            onKeyDown={(e) => {
                              const isNumeric = /^\d+$/.test(e.key);
                              const isBackspaceOrDelete =
                                e.key === "Backspace" || e.key === "Delete";
                              const isMaxLengthReached =
                                e.target.value.length >= 4;
                              const isInputEmpty = e.target.value === "";
                              // Allow only numeric keys, backspace, delete, arrow keys, and navigation keys
                              if (
                                (isNumeric || isBackspaceOrDelete) &&
                                (!isMaxLengthReached || isBackspaceOrDelete)
                              ) {
                                return;
                              } else {
                                e.preventDefault();
                              }
                            }}
                          />
                        ) : (
                          <span className="py-3 px-4 min-h-[48.5px] capitalize text-center block bg-slate-100 w-full  border-gray-200 rounded-lg">
                            {Userinfo.teachingHoursPerWeek}
                          </span>
                        )}
                      </div>
                    </>
                  )}
                </div>
                <div className="flex flex-1 flex-col sm:flex-row p-2">
                  <div className="flex-1 m-1">
                    <label
                      htmlFor="email"
                      className="block mb-2 text-md text-gray-700 dark:text-white"
                    >
                      Email
                    </label>
                    {Edit ? (
                      <div className="flex space-x-1">
                        <span className="py-3 px-4 min-h-[48.5px] bg-slate-100 overflow-auto [&::-webkit-scrollbar]:h-2 [&::-webkit-scrollbar-thumb]:rounded-full  [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-slate-700  dark:[&::-webkit-scrollbar-thumb]:bg-slate-500 bg-slate-100 block w-full whitespace-nowrap  block w-full  border-gray-200 rounded-lg">
                          {Userinfo.userCredentials.Email}
                        </span>
                        <ChangeEmailModal
                          UserDetails={Userinfo}
                          edituserdetails={SetUserInfo}
                        />
                      </div>
                    ) : (
                      <span className="py-3 px-4 min-h-[48.5px] bg-slate-100 overflow-auto [&::-webkit-scrollbar]:h-2 [&::-webkit-scrollbar-thumb]:rounded-full  [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-slate-700  dark:[&::-webkit-scrollbar-thumb]:bg-slate-500 bg-slate-100  w-full whitespace-nowrap  block w-full  border-gray-200 rounded-lg">
                        {Userinfo && Userinfo.userCredentials && (
                          <>{Userinfo.userCredentials.Email}</>
                        )}
                      </span>
                    )}
                  </div>
                  {Edit && (
                    <div className="flex-1 m-1">
                      <label
                        htmlFor="Password"
                        className="block mb-2 text-md text-gray-700 dark:text-white"
                      >
                        Password
                      </label>
                      <ResetPasswordModal UserDetails={Userinfo} />
                    </div>
                  )}
                </div>
                {role === "teacher" &&
                  (Edit ? (
                    <TeacherExperience
                      setdatas={GetAccProf}
                      Validate={setisAccValid}
                      isEdit={true}
                      editData={Userinfo}
                    />
                  ) : (
                    <div className=" flex flex-col">
                      <div className="flex-1 m-1 p-2">
                        <label
                          htmlFor="degree"
                          className="block mb-2 text-md text-gray-700 dark:text-white"
                        >
                          Degree
                        </label>
                        <span className="py-3 px-4 bg-slate-100 overflow-auto [&::-webkit-scrollbar]:h-2 [&::-webkit-scrollbar-thumb]:rounded-full  [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-slate-700  dark:[&::-webkit-scrollbar-thumb]:bg-slate-500 bg-slate-100 block capitalize w-full whitespace-nowrap  block capitalize w-full  border-gray-200 rounded-lg">
                          {Userinfo.degree}
                        </span>
                      </div>
                      <div className="w-full p-2 flex ">
                        <div className="flex-nowrap flex whitespace-nowrap p-2">
                          Preferred Subjects:
                        </div>
                        <div className="py-3 px-4 bg-slate-100 w-full flex min-h-[45px] gap-2 flex-wrap border-gray-200 rounded-lg">
                          {Userinfo.preferredSubjects &&
                            Userinfo.preferredSubjects.length > 0 &&
                            Userinfo.preferredSubjects.map((subj, index) => (
                              <Chip
                                key={index}
                                variant="shadow"
                                className="bg-secondary text-white"
                              >
                                {subj.subject?.subjectName}
                              </Chip>
                            ))}
                        </div>
                      </div>
                      <div className="w-full p-2 flex">
                        <div className="flex-nowrap flex whitespace-nowrap p-2">
                          Teached Subjects:
                        </div>
                        <div className="py-3 px-4 bg-slate-100 w-full flex min-h-[45px] gap-2 flex-wrap border-gray-200 rounded-lg">
                          {Userinfo.teachedSubjects &&
                            Userinfo.teachedSubjects.length > 0 &&
                            Userinfo.teachedSubjects.map((subj, index) => (
                              <Chip
                                key={`subjTeached${index}`}
                                variant="shadow"
                                className="bg-gradient-to-br from-indigo-500 to-pink-500 text-white"
                              >
                                {subj?.subject}
                              </Chip>
                            ))}
                        </div>
                      </div>
                    </div>
                  ))}
              </>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}
