import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Image,
  Textarea,
  Switch,
  cn,
  Spinner,
  Skeleton
} from "@nextui-org/react";
import axios from "axios";
import { useAuth } from "../../../../utils/AppwriteConfig/AuthContext";
import { id, storage } from "../../../../utils/AppwriteConfig/Config";
import Cancelation from "../../../../Components/modals/Cancel";
import Error from "../../../../Components/ErrorIndicator";
import { ID } from "appwrite";

export default function NewAnnouncement({ rerenderParent }) {
  const { DataServer, user } = useAuth();
  const { isOpen, onOpen, onClose, onOpenChange } = useDisclosure();
  const [MainError, setMainError] = useState("");
  const [file, setFile] = useState();
  const [image, setImage] = useState(null);
  const [data, setdata] = useState({
    creator: user.$id,
    title: "",
    sendType: "all", // default value
    content: "",
    imageid: "",
    isEmail: false,
    fileid: "",
    post: false,
    priority: false
  });
  const [isLoading, setisLoading] = useState(false);
  const [Errors, setErrors] = useState({});

  const handlechanges = (e) => {
    const { name, value } = e.target;
    setdata({ ...data, [name]: value });
  };

  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];
    const validTypes = ["image/jpeg", "image/jpg", "image/png"];

    if (selectedImage && validTypes.includes(selectedImage.type)) {
      setImage(selectedImage);
    } else {
      setMainError("Only JPG, JPEG, and PNG files are allowed.");
      event.target.value = ""; // Reset the input if invalid file type is selected
    }
  };

  useEffect(() => {
    if (!data.post) {
      setdata({ ...data, isEmail: data.post });
    }
  }, [data.post]);

  let errors = {};
  const validate = () => {
    // Validate name
    if (!data?.title.trim()) {
      errors.title = `title is required`;
    } else if (data?.title.length > 50) {
      errors.title = `title is too long`;
    }
    if (!data?.sendType.trim()) {
      errors.sendType = `sendType is required`;
    } else if (data?.sendType.length > 50) {
      errors.sendType = `sendType is too long`;
    }
    if (!data?.content.trim()) {
      errors.content = `content is required`;
    } else if (data?.content.length > 9999) {
      errors.content = `content is too long`;
    }
    setErrors(errors);
  };

  useEffect(() => {
    validate();
  }, [data]);

  const handleAddAnnouncement = async () => {
    validate();
    if (Object.keys(errors).length === 0) {
      setisLoading(true);
      var uploadimage;
      if (image) {
        //Save picture in the bucket of appwrite
        uploadimage = await storage.createFile(
          "66024b7d93a9464cacb6",
          ID.unique(),
          image
        );

   
      }
      let announcementnew;
      if (uploadimage) {
        announcementnew = {
          ...data,
          creator: user.$id,
          creatorData: user,
          imageid: uploadimage.$id
        };
      } else {
        announcementnew = {
          creator: user.$id,
          creatorData: user,
          ...data
        };
      }

      //Save announcement
      const response = await axios.post(
        `${DataServer}/announcement/new`,
        announcementnew
      );

      setdata({
        title: "",
        sendType: "all", // default value
        content: "",
        imageid: "",
        isEmail: false,
        fileid: "",
        post: false,
        priority: false
      });
      setImage();
      setisLoading(false);
      rerenderParent();
      onClose();
    } else {
      console.log(errors);
    }
  };

  const cancelnew = () => {
    setImage();
    setdata({
      title: "",
      sendType: "all", // default value
      content: "",
      imageid: image?.$id,
      fileid: "",
      post: false,
      priority: false
    });
    onClose();
  };

  return (
    <>
      {MainError && <Error errorMessage={MainError} reset={setMainError} />}
      <Button className="bg-[#FFDE59]" onPress={onOpen}>
        Create New Announcement
      </Button>
      <Modal
        size="5xl"
        hideCloseButton
        isKeyboardDismissDisabled
        isDismissable={false}
        isOpen={isOpen}
        className="flex flex-col "
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex  gap-1">
                New Announcement
              </ModalHeader>
              <ModalBody className="min-h-[100px] max-h-[400px] flex sm:flex-row flex-col overflow-auto">
                <div className=" w-full hidden bg-slate-200 rounded-lg items-center sm:min-w-[200px] justify-center sm:flex sm:max-w-[300px] flex-initial">
                  {image ? (
                    <Image
                      isZoomed
                      alt="NextUI hero Image"
                      src={URL.createObjectURL(image)}
                    />
                  ) : (
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="45"
                        height="45"
                        fill="currentColor"
                        class="bi bi-image"
                        viewBox="0 0 16 16"
                      >
                        <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                        <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1z" />
                      </svg>
                    </div>
                  )}
                </div>
                <div className="flex-1 overflow-auto">
                  <div className=" w-full sm:hidden items-center sm:min-w-[200px] justify-center flex sm:max-w-[300px] flex-initial">
                    {image ? (
                      <Image
                        isZoomed
                        alt="NextUI hero Image"
                        src={URL.createObjectURL(image)}
                      />
                    ) : (
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="45"
                          height="45"
                          fill="currentColor"
                          class="bi bi-image"
                          viewBox="0 0 16 16"
                        >
                          <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                          <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1z" />
                        </svg>
                      </div>
                    )}
                  </div>
                  <form className=" space-y-1">
                    <div>
                      <label class="block">
                        <span class="block text-sm mb-2  font-medium dark:text-white">
                          Choose photo
                        </span>
                        <input
                          type="file"
                          onChange={handleImageChange}
                          accept=".jpg, .jpeg, .png"
                          class="block w-full text-sm text-gray-500
         file:me-4 file:py-2 file:px-4
         file:rounded-lg file:border-0
         file:text-sm file:font-semibold
         file:bg-secondary file:text-white
         hover:file:bg-blue-700
         file:disabled:opacity-50 file:disabled:pointer-events-none
         dark:file:bg-blue-500
         dark:hover:file:bg-blue-400"
                        />
                      </label>
                    </div>

                    <div>
                      <label
                        htmlFor="title"
                        className="block text-sm mb-2 font-medium dark:text-white"
                      >
                        Announcement Title
                      </label>
                      <input
                        type="text"
                        name="title"
                        id="title"
                        className={`py-2 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
                          Errors.title ? "border-red-500" : "border-slate-200"
                        }`}
                        required
                        onChange={handlechanges}
                        value={data.title}
                      />
                      {Errors.title && (
                        <span className="text-red-500 text-sm mt-1">
                          {Errors.title}
                        </span>
                      )}
                    </div>

                    <div>
                      <label
                        htmlFor="sendType"
                        className="block text-sm mb-2 font-medium dark:text-white"
                      >
                        Announce to
                      </label>
                      <select
                        name="sendType"
                        id="sendType"
                        className={`py-2 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
                          Errors.sendType
                            ? "border-red-500"
                            : "border-slate-200"
                        }`}
                        required
                        onChange={handlechanges}
                        value={data.sendType}
                      >
                        <option selected value="all">
                          All
                        </option>
                        <option value="employees">Employees</option>
                        <option value="teachers">Teachers</option>
                        <option value="students">Students</option>
                      </select>
                      {Errors.sendType && (
                        <span className="text-red-500 text-sm mt-1">
                          {Errors.sendType}
                        </span>
                      )}
                    </div>

                    <div>
                      <label
                        htmlFor="content"
                        className="block text-sm mb-2 font-medium dark:text-white"
                      >
                        Announcement content
                      </label>
                      <textarea
                        name="content"
                        id="content"
                        class={`py-2 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
                          Errors.content ? "border-red-500" : "border-slate-200"
                        }`}
                        rows="4"
                        required
                        onChange={handlechanges}
                      ></textarea>
                      {Errors.content && (
                        <span className="text-red-500 text-sm mt-1">
                          {Errors.content}
                        </span>
                      )}
                    </div>

                    <div className="">
                      <Switch
                        name="post"
                        onValueChange={(e) => {
                          setdata({ ...data, post: e });
                        }}
                        classNames={{
                          base: cn(
                            "inline-flex flex-row-reverse w-full max-w-full bg-content1 hover:bg-content2 items-center",
                            "justify-between cursor-pointer rounded-lg gap-2 p-4 border-2 border-transparent"
                          ),
                          wrapper: "p-0 h-4 overflow-visible",
                          thumb: cn(
                            "w-6 h-6 border-2 shadow-lg",
                            "group-data-[hover=true]:border-primary",
                            //selected
                            "group-data-[selected=true]:ml-6",
                            // pressed
                            "group-data-[pressed=true]:w-7",
                            "group-data-[selected]:group-data-[pressed]:ml-4"
                          )
                        }}
                      >
                        <div className="flex flex-col gap-1">
                          <p className="text-medium">Post Announcement</p>
                          <p className="text-tiny text-default-400">
                            The announcement will be post immediately or will be
                            drafted
                          </p>
                        </div>
                      </Switch>
                    </div>

                    <div className="">
                      <Switch
                        onValueChange={(e) => {
                          setdata({ ...data, priority: e });
                        }}
                        classNames={{
                          base: cn(
                            "inline-flex flex-row-reverse w-full max-w-full bg-content1 hover:bg-content2 items-center",
                            "justify-between cursor-pointer rounded-lg gap-2 p-4 border-2 border-transparent"
                          ),
                          wrapper: "p-0 h-4 overflow-visible",
                          thumb: cn(
                            "w-6 h-6 border-2 shadow-lg",
                            "group-data-[hover=true]:border-primary",
                            //selected
                            "group-data-[selected=true]:ml-6",
                            // pressed
                            "group-data-[pressed=true]:w-7",
                            "group-data-[selected]:group-data-[pressed]:ml-4"
                          )
                        }}
                      >
                        <div className="flex flex-col gap-1">
                          <p className="text-medium">Prioritize?</p>
                          <p className="text-tiny text-default-400">
                            The announcement will be shown at the top of all
                            announcements
                          </p>
                        </div>
                      </Switch>
                    </div>
                  </form>
                </div>
              </ModalBody>
              <ModalFooter className=" justify-between flex-col sm:flex-row">
                <Switch
                  isDisabled={!data.post}
                  isSelected={data.isEmail}
                  onValueChange={(value) => {
                    setdata({ ...data, isEmail: value });
                  }}
                  defaultSelected
                  size="md"
                  color="secondary"
                  thumbIcon={({ isSelected, className }) =>
                    isSelected ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        fill="black"
                        class="bi bi-envelope-plus-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.5 4.5 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586zM16 4.697v4.974A4.5 4.5 0 0 0 12.5 8a4.5 4.5 0 0 0-1.965.45l-.338-.207z" />
                        <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0m-3.5-2a.5.5 0 0 0-.5.5v1h-1a.5.5 0 0 0 0 1h1v1a.5.5 0 0 0 1 0v-1h1a.5.5 0 0 0 0-1h-1v-1a.5.5 0 0 0-.5-.5" />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15px"
                        height="15px"
                        fill="black"
                        class="bi bi-envelope-x-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.5 4.5 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586zM16 4.697v4.974A4.5 4.5 0 0 0 12.5 8a4.5 4.5 0 0 0-1.965.45l-.338-.207z" />
                        <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0m-4.854-1.354a.5.5 0 0 0 0 .708l.647.646-.647.646a.5.5 0 0 0 .708.708l.646-.647.646.647a.5.5 0 0 0 .708-.708l-.647-.646.647-.646a.5.5 0 0 0-.708-.708l-.646.647-.646-.647a.5.5 0 0 0-.708 0" />
                      </svg>
                    )
                  }
                >
                  Send Email
                </Switch>
                <div className=" flex justify-end gap-2">
                  {isLoading ? (
                    <Button
                      isDisabled
                      className=" font-semibold bg-slate-200 rounded-lg"
                    >
                      Cancel
                    </Button>
                  ) : (
                    <Cancelation navigate={cancelnew} />
                  )}

                  <Button
                    isDisabled={Object.keys(Errors).length > 0 || isLoading}
                    color="secondary"
                    className=""
                    onPress={handleAddAnnouncement}
                  >
                    {isLoading ? (
                      <span className="flex space-x-2">
                        <Spinner
                          size="sm"
                          className="text-white flex"
                        ></Spinner>
                        <a>Announcing</a>
                      </span>
                    ) : (
                      "Announce"
                    )}
                  </Button>
                </div>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
