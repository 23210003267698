import { useEffect, useState } from "react";
import useRealtimeSubscription from "../utils/AppwriteConfig/RealtimeServices";
import { useAuth } from "../utils/AppwriteConfig/AuthContext";
import axios from "axios";
import { Skeleton } from "@nextui-org/react";

export default function SectionCard({ Csection, CcourseId }) {
  const { server } = useAuth();

  const [isLoading, setisLoading] = useState(false);
  const [MainError, setMainError] = useState();

  const [isRefresh, setIsRefresh] = useState(false);

  const [TotalStudents, setTotalStudents] = useState(0);

  useEffect(() => {
    async function GetData() {
      try {
        setisLoading(true);
        const EnrolledStudentsInSection = await axios.get(
          `${server}/v1/course/Csection/students`,
          {
            params: {
              courseId: CcourseId,
              sectionid: Csection.$id
            }
          }
        );

        setTotalStudents(EnrolledStudentsInSection.data.total);
      } catch (e) {
        console.log(e);
        setMainError(e.message);
      } finally {
        setisLoading(false);
      }
    }
    if (Csection && CcourseId) {
      GetData();
    }
  }, [Csection, isRefresh]);

  const HandleRealtimeChanges = (changes) => {
    if (changes?.payload?.firstName && changes.changetype === "update") {
      setIsRefresh(!isRefresh);
    }

    if (changes?.payload?.course?.$id && changes?.payload?.student?.$id) {
      setIsRefresh(!isRefresh);
    }
  };

  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_ENROLLMENT,
    HandleRealtimeChanges
  );

  return (
    <div class=" bg-[#e5dbf9] p-[12px] rounded-lg text-[#4a306d] shadow-lg flex justify-between items-center">
      <div class=" text-sm font-semibold">{Csection.sectionName}</div>
      <div class=" flex text-[#7d5ca3] gap-1 items-center text-sm">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-people-fill"
          viewBox="0 0 16 16"
        >
          <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />
        </svg>
        <Skeleton className=" rounded-full " isLoaded={!isLoading}>
          {TotalStudents} /
          {Csection && Csection?.capacity ? Csection.capacity : 0} students
        </Skeleton>
      </div>
    </div>
  );
}
