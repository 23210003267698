import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import axios from "axios";

export default function IDrender({ IDtype, setuserinfo }) {
  /**
   *
   * THIS COMPONENTE GETS THE LATEST USER ID To assign fo the new user
   *
   */
  const { users } = useParams();
  const { DataServer } = useAuth();
  const user = users.slice(0, -1);
  const [ID, setID] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  useEffect(() => {
    async function fetchid() {
      try {
        setisLoading(true);
        const response = await axios.get(
          `${DataServer}/user/${user}/latestid`
        );
        // Handle the data received from the DataServer
        console.log(response.data);
        setID(parseInt(response.data) + 1);
        const newid = parseInt(response.data) + 1;
        setuserinfo(newid.toString());
      } catch (error) {
        // Handle any errors
        console.error(error);
      } finally {
        setisLoading(false);
      }
    }

    fetchid();
  }, [users]);
  return (
    <span
      id="IDrender"
      className="py-2 px-3 block w-full bg-slate-100 text-center rounded-lg"
    >
      {isLoading ? "Loading..." : ID}
    </span>
  );
}
