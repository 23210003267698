export default function Logsskeleton() {
  return (
    <>
      <div className="flex gap-x-3 animate-pulse">
        <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-[#452063] dark:after:bg-[#452063]">
          <div className="relative z-10 size-7 flex justify-center items-center">
            <div className="size-2 rounded-full bg-[#452063] dark:bg-[#452063]"></div>
          </div>
        </div>

        <div className="grow pt-0.5 pb-8">
          <h3 className="flex p-2 bg-gray-400 rounded-full w-3/4 text-gray-800 dark:text-white"></h3>
          <p className="mt-1 text-sm p-2 bg-gray-400 rounded-full w-1/4  text-gray-600 dark:text-gray-400"></p>
          <div className="flex justify-between items-center">
            <button
              type="button"
              className="-ms-1 mt-2 p-2 bg-gray-400 rounded-full w-1/2 inline-flex items-center gap-x-2 text-xs rounded-lg border border-transparent text-gray-500 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
            ></button>
            <p className="text-sm p-2 bg-gray-400 rounded-full text-gray-600 w-24 dark:text-gray-400"></p>
          </div>
        </div>
      </div>
      <div className="flex gap-x-3 animate-pulse">
        <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-[#452063] dark:after:bg-[#452063]">
          <div className="relative z-10 size-7 flex justify-center items-center">
            <div className="size-2 rounded-full bg-[#452063] dark:bg-[#452063]"></div>
          </div>
        </div>

        <div className="grow pt-0.5 pb-8">
          <h3 className="flex p-2 bg-gray-400 rounded-full w-3/4 text-gray-800 dark:text-white"></h3>
          <p className="mt-1 text-sm p-2 bg-gray-400 rounded-full w-1/4  text-gray-600 dark:text-gray-400"></p>
          <div className="flex justify-between items-center">
            <button
              type="button"
              className="-ms-1 mt-2 p-2 bg-gray-400 rounded-full w-1/2 inline-flex items-center gap-x-2 text-xs rounded-lg border border-transparent text-gray-500 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
            ></button>
            <p className="text-sm p-2 bg-gray-400 rounded-full text-gray-600 w-24 dark:text-gray-400"></p>
          </div>
        </div>
      </div>
      <div className="flex gap-x-3 animate-pulse">
        <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-[#452063] dark:after:bg-[#452063]">
          <div className="relative z-10 size-7 flex justify-center items-center">
            <div className="size-2 rounded-full bg-[#452063] dark:bg-[#452063]"></div>
          </div>
        </div>

        <div className="grow pt-0.5 pb-8">
          <h3 className="flex p-2 bg-gray-400 rounded-full w-3/4 text-gray-800 dark:text-white"></h3>
          <p className="mt-1 text-sm p-2 bg-gray-400 rounded-full w-1/4  text-gray-600 dark:text-gray-400"></p>
          <div className="flex justify-between items-center">
            <button
              type="button"
              className="-ms-1 mt-2 p-2 bg-gray-400 rounded-full w-1/2 inline-flex items-center gap-x-2 text-xs rounded-lg border border-transparent text-gray-500 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
            ></button>
            <p className="text-sm p-2 bg-gray-400 rounded-full text-gray-600 w-24 dark:text-gray-400"></p>
          </div>
        </div>
      </div>
      <div className="flex gap-x-3 animate-pulse">
        <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-[#452063] dark:after:bg-[#452063]">
          <div className="relative z-10 size-7 flex justify-center items-center">
            <div className="size-2 rounded-full bg-[#452063] dark:bg-[#452063]"></div>
          </div>
        </div>

        <div className="grow pt-0.5 pb-8">
          <h3 className="flex p-2 bg-gray-400 rounded-full w-3/4 text-gray-800 dark:text-white"></h3>
          <p className="mt-1 text-sm p-2 bg-gray-400 rounded-full w-1/4  text-gray-600 dark:text-gray-400"></p>
          <div className="flex justify-between items-center">
            <button
              type="button"
              className="-ms-1 mt-2 p-2 bg-gray-400 rounded-full w-1/2 inline-flex items-center gap-x-2 text-xs rounded-lg border border-transparent text-gray-500 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
            ></button>
            <p className="text-sm p-2 bg-gray-400 rounded-full text-gray-600 w-24 dark:text-gray-400"></p>
          </div>
        </div>
      </div>
      <div className="flex gap-x-3 animate-pulse">
        <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-[#452063] dark:after:bg-[#452063]">
          <div className="relative z-10 size-7 flex justify-center items-center">
            <div className="size-2 rounded-full bg-[#452063] dark:bg-[#452063]"></div>
          </div>
        </div>

        <div className="grow pt-0.5 pb-8">
          <h3 className="flex p-2 bg-gray-400 rounded-full w-3/4 text-gray-800 dark:text-white"></h3>
          <p className="mt-1 text-sm p-2 bg-gray-400 rounded-full w-1/4  text-gray-600 dark:text-gray-400"></p>
          <div className="flex justify-between items-center">
            <button
              type="button"
              className="-ms-1 mt-2 p-2 bg-gray-400 rounded-full w-1/2 inline-flex items-center gap-x-2 text-xs rounded-lg border border-transparent text-gray-500 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
            ></button>
            <p className="text-sm p-2 bg-gray-400 rounded-full text-gray-600 w-24 dark:text-gray-400"></p>
          </div>
        </div>
      </div>
    </>
  );
}
