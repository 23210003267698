import React from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
} from "@nextui-org/react";
import AccountHeader from "./components/AccountHeader";
import UserData from "./components/UserData";
import Bio from "./components/Bio";
import { useAuth } from "../../utils/AppwriteConfig/AuthContext";

export default function Account() {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  return (
    <>
      <Button onPress={onOpen} className="hidden" id="accbtn">
        gg
      </Button>
      <Modal
        isOpen={isOpen}
        size="4xl"
        hideCloseButton
        className=" font-poppins z-[70]"
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalBody className="max-h-[500px]  overflow-auto p-0">
                <div className="">
                  <div className="bg-white rounded-xl shadow dark:bg-slate-900">
                    <AccountHeader />

                    <div className="pt-0 p-4 sm:pt-0 sm:p-7">
                      <div className="space-y-2">
                        <div>
                          <UserData close={onClose} />
                        </div>
                        <div className="p-10 py-0">
                          <Bio />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
