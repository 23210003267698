import { useEffect, useState } from "react";
import img1 from "../../../Img/LandingPage/bsaintro2.jpg";
import axios from "axios";
import { useAuth } from "../../../utils/AppwriteConfig/AuthContext";
import { Card, Button, Spinner } from "@nextui-org/react";
import ImgRender from "../components/renders/ImgRender";
import { Swiper, SwiperSlide } from "swiper/react";

import { EffectFade, Autoplay, Pagination, Navigation } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import useRealtimeSubscription from "../../../utils/AppwriteConfig/RealtimeServices";

export default function Whole({ container, specific = false }) {
  const {
    user,
    server,
    setisContainerLoading,
    isContainerLoading,
    isSContainerLoading,
    setisSContainerLoading,
  } = useAuth();

  const [isLoading, setisLoading] = useState(false);
  const [MainError, setMainError] = useState();
  const [isRefresh, setisRefresh] = useState(true);

  const [Items, setItems] = useState([]); //storage of The item saved in a container

  //Gets the items
  useEffect(() => {
    async function GetItems() {
      try {
        setisLoading(true);
        const getItems = await axios.get(`${server}/v1/home/container/items`, {
          params: { id: container.$id },
        });
     
        setItems(getItems.data.documents);
      } catch (e) {
        setMainError(e.message);
      } finally {
        setisLoading(false);
      }
    }
    GetItems();
  }, [container, isRefresh]);

  useEffect(() => {
    if (specific) {
      setisSContainerLoading(isLoading);
    } else {
      setisContainerLoading(isLoading);
    }
  }, [isLoading]);

  const handleRealtimechanges = (changes) => {
    // console.log(changes);
    const changedata = Items.findIndex(
      (item) => item.$id === changes.payload.$id
    );
    if (
      (changes.changetype === "create" || changes.changetype === "delete") &&
      !specific &&
      changes.channels &&
      changes.channels.includes(
        "databases.65dfbcb25e60c6433044.collections.661a715eecb168cc611d.documents"
      )
    ) {
      setisRefresh(!isRefresh);
    }
    if (changes.changetype === "create") {
      setItems((prevItems) => [...prevItems, changes.payload]);
    } else if (changes.changetype === "delete") {
      setItems((prevItems) =>
        prevItems.filter((item) => item.$id !== changes.payload.$id)
      );
    }
  };

  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_CONTAINER_ITEM,
    handleRealtimechanges
  );

  return (
    <Swiper
      spaceBetween={0}
      centeredSlides={true}
      autoplay={{
        delay: 3500,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      modules={[Autoplay, Pagination, Navigation, EffectFade]}
      className="mySwiper bg-purple-500 max-h-[550px]"
    >
      {isLoading ? (
        <div className=" bg-purple-500 min-h-[550px] w-full p-4 flex justify-center items-center">
          <Spinner size="xl">Loading...</Spinner>
        </div>
      ) : (
        <>
          {Items && Items.length > 0 ? (
            Items.map((item) => (
              <SwiperSlide key={item.$id}>
                <ImgRender key={item.$id} ImgURL={item.itemPicture} />
              </SwiperSlide>
            ))
          ) : (
            <>
              {/* THIS PICTURES DOESNT LOAD */}
              <SwiperSlide className="h-full">
                <img
                  className="bg-cover bg-center w-full"
                  src={require("../../../Img/LandingPage/bsaintro2.jpg")}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="bg-cover bg-center w-full"
                  src={require("../../../Img/LandingPage/bsaintro3.jpg")}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="bg-cover bg-center w-full"
                  src={require("../../../Img/LandingPage/bsaintro9.jpg")}
                />
              </SwiperSlide>
            </>
          )}
        </>
      )}
    </Swiper>
  );
}
