import { Button, Skeleton } from "@nextui-org/react";

import { useEffect, useState } from "react";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import axios from "axios";
import useRealtimeSubscription from "../../../../../utils/AppwriteConfig/RealtimeServices";

import {
  ApprovedAndAdjustedRequestCard,
  RequestCardModern
} from "./RequestCard";

export default function AdminAppliedRequest({
  refreshMain,
  RefreshStatus,
  tab
}) {
  const { DataServer, user } = useAuth();

  const [isLoading, setisLoading] = useState(true);
  const [isrefresh, setisrefresh] = useState(true);
  const [MainError, setMainError] = useState();
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    setisLoading(true);
    const fetchRequest = async () => {
      try {
        const response = await axios.get(
          `${DataServer}/info/requests/applied`,
          {}
        );
        console.log(response);
        setRequests(response.data.documents);
      } catch (error) {
        setMainError(error.message);
      } finally {
        setisLoading(false);
      }
    };

    if (tab === "applied") {
      fetchRequest();
    }
  }, [isrefresh, DataServer, RefreshStatus]);

  const handleRealtimeMessage = (message) => {
    console.log(message, "gsg");
    setisrefresh(!isrefresh);
  };

  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_ROOM,
    handleRealtimeMessage
  );

  return (
    <div className="flex flex-col w-full h-full max-w-full   overflow-hidden">
      <div className="flex-1 overflow-auto p-2 b space-y-2 [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-slate-700 dark:[&::-webkit-scrollbar-thumb]:bg-slate-500">
        {isLoading ? (
          <>
            <Skeleton className=" rounded-lg ">
              <RequestCardModern />
            </Skeleton>
            <Skeleton className=" rounded-lg ">
              <RequestCardModern />
            </Skeleton>
            <Skeleton className=" rounded-lg ">
              <RequestCardModern />
            </Skeleton>
          </>
        ) : requests && requests.length > 0 ? (
          requests.map((request) => (
            <ApprovedAndAdjustedRequestCard data={request} />
          ))
        ) : (
          <div className=" w-full h-[calc(100vh-180px)]">
            <div className=" font-medium flex bg-secondary-50 rounded-lg h-full w-full items-center justify-center">
              No Applied Request Yet
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
