import React, { useEffect, useState } from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  Spinner,
  TableRow,
  TableCell,
  Pagination,
  User,
  Progress
} from "@nextui-org/react";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import TeacherTooltip from "./TeacherTooltip";

export default function SuitableTeacherTable({
  updateteacher,
  teachers,
  teachertimes,
  subject,
  setTotal,
  count
}) {
  const [isLoading, setisLoading] = useState(false);
  const [Teachers, setTeachers] = useState(teachers || []);
  const [selectedTeacher, setselectedTeacher] = useState({});
  const [searchQuery, setsearchQuery] = useState("");
  const [search, setSearch] = useState("");
  const [Pages, setPages] = useState({
    total: 0,
    current: 1
  });

  //Sort teachers in highest to lowest SUitability score
  useEffect(() => {
    const sortedTeachers = [...teachers].sort(
      (a, b) => b._totalSuitabilityScore - a._totalSuitabilityScore
    );
    let filteredTeacher;
    if (count.dept === "SHS" || count.dept === "JHS") {
      filteredTeacher = sortedTeachers.filter(
        (teacher) =>
          teacher.assignedTeachingTime + subject.subjectTimePerDay <= 1700
      );
    } else if (count.dept === "Elem") {
      // console.log("hh");
      filteredTeacher = sortedTeachers.filter((teacher) => {
        const assignedCount =
          count.assignedSubjectsCount[teacher.teacherId] || 0;
        console.log(assignedCount);
        return assignedCount < 6;
      });
    } else if (count.dept === "Pre") {
      // console.log("hh");
      filteredTeacher = sortedTeachers.filter((teacher) => {
        const assignedCount =
          count.assignedSubjectsCount[teacher.teacherId] || 0;
        console.log(assignedCount);
        return assignedCount < 4;
      });
    }

    console.log(filteredTeacher.length);
    setTeachers(filteredTeacher);
  }, [teachers]);

  //Pagination
  useEffect(() => {
    const totalPages = Math.ceil(teachers.length / 10);
    setPages((prev) => ({ ...prev, total: totalPages }));
  }, [teachers]);

  // used to prevent multiple request, Itll only request after the user is done typing
  useEffect(() => {
    setisLoading(true);
    const timerId = setTimeout(() => {
      setsearchQuery(search);
    }, 500);
    setisLoading(false);
    return () => clearTimeout(timerId);
  }, [search]);

  useEffect(() => {
    updateteacher(selectedTeacher);
  }, [selectedTeacher]);
  // Filter teachers based on search query using teacher.keyword
  const filteredTeachers = Teachers.filter((teacher) =>
    teacher.keyword.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Update total pages based on filtered teachers
  useEffect(() => {
    const totalPages = Math.ceil(filteredTeachers.length / 10);
    setPages((prev) => ({ ...prev, total: totalPages }));
  }, [filteredTeachers]);

  // Get current page teachers after filtering
  const currentTeachers = filteredTeachers.slice(
    (Pages.current - 1) * 10,
    Pages.current * 10
  );
  return (
    <div className="h-full flex flex-col">
      <div className="flex-initial flex">
        <div className="flex-1 flex w-full pr-0 border-0 ring-0 sm:mb-0 relative">
          <div className="absolute inset-y-0 left-3 flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-search"
              viewBox="0 0 16 16"
            >
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
            </svg>
          </div>
          <input
            type="text"
            placeholder="Search"
            className="py-2 pl-10 pr-2 border-0 shadow-md block w-full rounded-lg bg-default text-black/90 dark:text-white/90 text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          {searchQuery && (
            <div
              className="absolute inset-y-0 right-9 flex cursor-pointer items-center "
              onClick={() => {
                setSearch("");
                setsearchQuery("");
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708" />
              </svg>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-1 mt-2 overflow-hidden">
        <div className="w-full max-h-full h-full  overflow-hidden">
          <div className="block  max-h-full h-full overflow-hidden">
            <div className="bg-white rounded-lg max-h-[300px] flex overflow-auto">
              {isLoading ? (
                <div className="w-full h-full flex pt-1 p-4 shadow-md flex-col border-1 rounded-xl  justify-center item-center">
                  <div className="bg-slate-200 p-5 rounded-xl"></div>
                  <Spinner color="secondary" className="m-4" />
                </div>
              ) : (
                <>
                  {currentTeachers && currentTeachers.length > 0 ? (
                    <Table
                      selectionMode="single"
                      removeWrapper
                      onSelectionChange={(keys) => {
                        const st = Teachers.find(
                          (teacher) => teacher.$id === keys.anchorKey
                        );
                        setselectedTeacher(st);
                      }}
                      isHeaderSticky
                      className="overflow-auto max-h-full h-full"
                      aria-label="Example static collection table"
                    >
                      <TableHeader>
                        <TableColumn>NAME</TableColumn>
                        <TableColumn>SUITABILITY SCORE</TableColumn>
                      </TableHeader>
                      <TableBody>
                        {currentTeachers.map((teacher, index) => (
                          <TableRow key={teacher.$id}>
                            <TableCell>
                              {/* <User
                                name={teacher?.userCredentials?.Username}
                                description={`${teacher?.teacherId} | ${teacher?.department}`}
                                avatarProps={{}}
                              /> */}
                              <TeacherTooltip key={index} Data={teacher} />
                            </TableCell>

                            <TableCell>
                              <Progress
                                size="sm"
                                radius="sm"
                                color="secondary"
                                classNames={{
                                  base: "max-w-md",
                                  track: "drop-shadow-md border border-default",
                                  label:
                                    "tracking-wider font-medium text-default-600",
                                  value: "text-foreground/60"
                                }}
                                maxValue={100}
                                value={teacher._totalSuitabilityScore.toFixed(
                                  2
                                )}
                                showValueLabel={true}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  ) : (
                    <div className="w-full h-full flex p-4  flex-col rounded-xl  justify-center item-center">
                      No Teacher named {searchQuery}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <Pagination
          className="flex-initial p-4"
          loop
          showControls
          color="secondary"
          total={Pages.total}
          initialPage={1}
          page={Pages.current}
          onChange={(newPage) =>
            setPages((prevPages) => ({
              ...prevPages,
              current: newPage
            }))
          }
        />
      </div>
    </div>
  );
}
