import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  ButtonGroup,
  Dropdown,
  User,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem
} from "@nextui-org/react";
import StudentTable from "../renders/StudentTable";
import EditSection from "../../actions/EditSection";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import axios from "axios";
import AssignedStudentTotal from "../renders/TotalAssignedstudent";
import AssignEnrolledStudents from "../../actions/AssigneEnrolledstudent";
import Adviser from "../renders/Adviser";
import CourseCompletionModal from "./CourseCompletion";
import AssignedRoom from "./AssignedRoom";
import AssignRoom from "../../actions/AssignRoom";

export default function SectionDetails({
  courseData,
  data,
  refresher,
  ModalCloseState
}) {
  const { role, server, user } = useAuth();

  const { isOpen, onOpen, onClose, onOpenChange } = useDisclosure();
  const [isLoading, setisLoading] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [MainError, setMainError] = useState();

  const [selectedStudents, setSelectedStudents] = useState([]);
  const [selectedStudentsData, setSelectedStudentsData] = useState([]);
  const [studentdata, setstudentdata] = useState();
  const [selectedOption, setSelectedOption] = useState("Remove");

  useEffect(() => {
    console.log(studentdata);
  }, [studentdata]);

  useEffect(() => {
    if (ModalCloseState) {
      onClose();
    }
  }, [ModalCloseState]);

  useEffect(() => {
    const sectionid = data.$id;
    console.log(courseData, data);
    setstudentdata({
      courseid: courseData.$id,
      courseName: courseData.courseName,
      sectionid,
      sectionName: data.sectionName,
      selectedStudents,
      creator: user.$id
    });
  }, [courseData, data, selectedStudents]);

  const handleUnenrollstudent = async () => {
    if (true) {
      setisLoading(true);
      try {
        console.log("GG", studentdata);
        const response = await axios.post(
          `${server}/v1/students/unenroll`,
          studentdata
        );
        console.log(response);
      } catch (e) {
        setMainError(e.message);
      } finally {
        setRefresh(!refresh);
        setisLoading(false);
      }
      setSelectedStudents([]);
      refresher();
    } else {
      console.log("Errors found:");
      // Optionally, you can handle errors in UI or perform other actions
    }
  };

  const handleUnassignstudent = async () => {
    if (true) {
      setisLoading(true);
      try {
        console.log(studentdata);
        const response = await axios.post(
          `${server}/v1/students/section/unassign`,
          studentdata
        );
        console.log(response);
      } catch (e) {
        setMainError(e.message);
      } finally {
        setRefresh(!refresh);
        setisLoading(false);
      }
      setSelectedStudents([]);
      refresher();
    } else {
      console.log("Errors found:");
      // Optionally, you can handle errors in UI or perform other actions
    }
  };

  useEffect(() => {
    console.log(isLoading);
  }, [isLoading]);

  const refreshsectiondetails = () => {
    setRefresh(!refresh);
    refresher();
    onClose();
  };

  console.log(courseData);

  return (
    <>
      <button
        id="Sectiondetails"
        className="hidden font-poppins"
        onClick={onOpen}
      >
        Open Modal
      </button>

      <Modal
        backdrop="opaque"
        isDismissable={true}
        isKeyboardDismissDisabled={true}
        isOpen={isOpen}
        size="4xl"
        className="font-poppins"
        onOpenChange={onOpenChange}
        motionProps={{
          variants: {
            enter: {
              y: 0,
              opacity: 1,
              transition: {
                duration: 0.3,
                ease: "easeOut"
              }
            },
            exit: {
              y: -20,
              opacity: 0,
              transition: {
                duration: 0.2,
                ease: "easeIn"
              }
            }
          }
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex gap-1 flex-col sm:flex-row pb-0 items-center">
                <div>
                  {" "}
                  <span className=" mr-2">
                    {courseData.courseName} {data.sectionName}
                  </span>
                  {role.includes("Adminhead") && (
                    <EditSection
                      CurrentData={data}
                      courseId={courseData.$id}
                      Refresh={refreshsectiondetails}
                    />
                  )}
                </div>
                {data && data.rooms ? (
                  <AssignedRoom
                    data={(data && data.rooms && data.rooms) || {}}
                    section={data}
                    refresh={refreshsectiondetails}
                  />
                ) : (
                  <AssignRoom
                    data={{ ...courseData }}
                    section={data}
                    refresh={refreshsectiondetails}
                  />
                )}
              </ModalHeader>
              <ModalBody className="">
                <div className="flex flex-col  w-full">
                  <Adviser
                    sectionName={data.sectionName}
                    courseName={courseData.courseName}
                    courseid={courseData.$id}
                    sectionid={data.$id}
                  />

                  {/* <span className="flex-1 sm:flex-initial">
                    {false !== true && (
                      <AssignEnrolledStudents
                        Course={courseid}
                        Section={data}
                        isrefreshed={refresh}
                        refreshparent={refreshsectiondetails}
                      />
                    )}
                  </span> */}
                </div>

                <StudentTable
                  section={data}
                  course={courseData?.$id}
                  SelectedStudents={setSelectedStudents}
                  SelectedStudentsData={setSelectedStudentsData}
                  isrefreshed={refresh}
                />
              </ModalBody>
              <ModalFooter className="pt-0 items-center flex-col sm:flex-row sm:justify-between">
                <span className=" flex">
                  <span className="flex-initial">
                    <AssignedStudentTotal
                      id={courseData.$id}
                      sectionid={data.$id}
                      isrefreshed={refresh}
                    />
                  </span>
                </span>
                <div className="flex gap-2 flex-col sm:flex-row">
                  {isLoading !== true && (
                    <Button color="danger" variant="light" onPress={onClose}>
                      Close
                    </Button>
                  )}
                  {isLoading ? (
                    <Button
                      isLoading
                      color="secondary"
                      spinner={
                        <svg
                          className="animate-spin h-5 w-5 text-current"
                          fill="none"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          />
                          <path
                            className="opacity-75"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            fill="currentColor"
                          />
                        </svg>
                      }
                    >
                      Processing...
                    </Button>
                  ) : (
                    <>
                      <ButtonGroup
                        variant="flat"
                        isDisabled={!selectedStudents.length > 0}
                        className=" flex"
                        color="secondary"
                      >
                        {selectedOption === "Un-enroll" && (
                          <Button
                            color="secondary"
                            className={`rounded-l-xl`}
                            onPress={handleUnenrollstudent}
                          >
                            Un-Enroll
                          </Button>
                        )}
                        {selectedOption === "Remove" && (
                          <Button
                            className={` rounded-l-xl`}
                            color="secondary"
                            onPress={handleUnassignstudent}
                          >
                            Remove
                          </Button>
                        )}
                        {(selectedOption === "Passed" ||
                          selectedOption === "Failed") && (
                          <CourseCompletionModal
                            type={selectedOption}
                            course={courseData}
                            mainRefresher={refreshsectiondetails}
                            selectedStudent={selectedStudents}
                            selectedStudentData={selectedStudentsData}
                          />
                        )}

                        <Dropdown placement="bottom-end">
                          <DropdownTrigger>
                            <Button
                              isIconOnly
                              color={
                                selectedOption === "Passed"
                                  ? "success"
                                  : selectedOption === "Failed"
                                  ? "danger"
                                  : "secondary"
                              }
                            >
                              <svg
                                fill="none"
                                height="14"
                                viewBox="0 0 24 24"
                                width="14"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.9188 8.17969H11.6888H6.07877C5.11877 8.17969 4.63877 9.33969 5.31877 10.0197L10.4988 15.1997C11.3288 16.0297 12.6788 16.0297 13.5088 15.1997L15.4788 13.2297L18.6888 10.0197C19.3588 9.33969 18.8788 8.17969 17.9188 8.17969Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </Button>
                          </DropdownTrigger>
                          <DropdownMenu
                            disallowEmptySelection
                            aria-label="Merge options"
                            selectedKeys={[selectedOption]}
                            selectionMode="single"
                            onSelectionChange={(e) => {
                              // Extract the selected value from the event
                              const selectedValue = [...e][0];

                              // Perform any action with the selected value if needed
                              console.log(selectedValue);

                              // Set the selected value to your state
                              setSelectedOption(selectedValue);
                            }}
                            className="max-w-[300px]"
                          >
                            <DropdownItem
                              key="Un-enroll"
                              description={
                                "This will remove selected student to this course"
                              }
                            >
                              {"Un-Enroll Student"}
                            </DropdownItem>
                            <DropdownItem
                              key="Remove"
                              description={
                                "This will remove selected student to this section"
                              }
                            >
                              {"Remove to section"}
                            </DropdownItem>
                            <DropdownItem
                              key="Passed"
                              description={
                                "This will mark the student/s selected as passed"
                              }
                            >
                              {"Course Completed"}
                            </DropdownItem>
                            <DropdownItem
                              key="Failed"
                              description={
                                "This will mark the student/s selected as failed"
                              }
                            >
                              {"Course Failed"}
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </ButtonGroup>
                      {/* <Button
                        color="secondary"
                        isDisabled={!selectedStudents.length > 0}
                        className="hidden sm:flex"
                        onPress={handleUnenrollstudent}
                      >
                        Un-Enroll to Course
                      </Button>
                      <Button
                        className="hidden sm:flex"
                        isDisabled={!selectedStudents.length > 0}
                        color="secondary"
                        onPress={handleUnassignstudent}
                      >
                        Remove to Section
                      </Button> */}
                    </>
                  )}
                </div>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
