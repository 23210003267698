import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Image,
  Textarea,
  Switch,
  cn,
  Spinner
} from "@nextui-org/react";
import { useAuth } from "../../../../utils/AppwriteConfig/AuthContext";
import axios from "axios";
import { id, storage } from "../../../../utils/AppwriteConfig/Config";

export default function Editannouncement({
  rerenderParent,
  currentimage,
  currentdata,
  Loadingstate
}) {
  const { DataServer, server, user } = useAuth();

  const [file, setFile] = useState();
  const [image, setImage] = useState(null);
  const [data, setdata] = useState({ ...currentdata, creator: user.$id });
  const [isLoading, setisLoading] = useState(false);
  const [Errors, setErrors] = useState({});

  const handlechanges = (e) => {
    const { name, value } = e.target;
    setdata({ ...data, [name]: value });
  };

  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];
    setImage(selectedImage);
  };

  let errors = {};
  const validate = () => {
    // Validate name
    if (!data?.title.trim()) {
      errors.title = `title is required`;
    } else if (data?.title.length > 50) {
      errors.title = `title is too long`;
    }
    if (!data?.sendType.trim()) {
      errors.sendType = `sendType is required`;
    } else if (data?.sendType.length > 50) {
      errors.sendType = `sendType is too long`;
    }
    if (!data?.content.trim()) {
      errors.content = `content is required`;
    } else if (data?.content.length > 999) {
      errors.content = `content is too long`;
    }
    setErrors(errors);
  };

  useEffect(() => {
    validate();
  }, [data]);

  useEffect(() => {
 
  }, [data]);

  const handleAddAnnouncement = async () => {
    validate();
    if (data === currentdata) {
    } else {
      if (Object.keys(errors).length === 0) {
        setisLoading(true);

        const response = await axios.post(
          `${DataServer}/announcement/${data.$id}/edit`,
          data
        );
  
        setdata({
          title: "",
          sendType: "all", // default value
          content: "",
          imageid: "",
          fileid: "",
          post: false,
          priority: false
        });
        setisLoading(false);
        Loadingstate(false);
        rerenderParent();
      } else {
        console.log("Errors found:", errors);
      }
    }
  };

  const cancelnew = () => {
    setImage();
    setdata({
      title: "",
      sendType: "all", // default value
      content: "",
      imageid: image?.$id,
      fileid: "",
      post: false,
      priority: false
    });
  };

  useEffect(() => {
    Loadingstate(isLoading);
  }, [isLoading]);

  return (
    <div className="min-h-[100px] relative max-h-[400px] w-full flex sm:flex-row flex-col overflow-auto">
      {isLoading ? (
        <div className="flex flex-col bg-black p-2 absolute  w-full h-full justify-center items-center ">
          <Spinner
            size="lg"
            color="secondary"
            label={`Creating new Announcement...`}
          />
        </div>
      ) : (
        <>
          <button
            id="savechangesbtn"
            className="hidden"
            onClick={handleAddAnnouncement}
          >
            gg
          </button>
          {/* <div className=" hidden aspect-[16/9] items-center sm:min-w-[200px] justify-center sm:flex sm:max-w-[400px] flex-initial">
            <Image
              alt="NextUI hero Image"
              className=" w-full h-full aspect-[16/9] bg-cover object-cover"
              src={require("../../../../Img/BSA.jpg")}
            />
          </div> */}
          <div className="flex-1 overflow-auto">
            <div className="w-full mb-2 items-center justify-center flex  flex-initial">
              <div className=" aspect-[16/9]  w-full">
                <img
                  alt="ANNOUCNEMNT IMG"
                  className="  w-full h-full bg-cover object-cover  "
                  src={
                    currentimage
                      ? currentimage
                      : require("../../../../Img/BSA.jpg")
                  }
                />
              </div>
            </div>
            <form className=" space-y-1 p-2">
              <label
                htmlFor="title"
                className="block text-sm mb-2 font-medium dark:text-white"
              >
                Announcement Title
              </label>
              <input
                type="text"
                name="title"
                id="title"
                className={`py-2 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
                  Errors.title ? "border-red-500" : "border-slate-200"
                }`}
                required
                onChange={handlechanges}
                value={data.title}
              />
              {Errors.title && (
                <span className="text-red-500 text-sm mt-1">
                  {Errors.title}
                </span>
              )}

              <div>
                <label
                  htmlFor="sendType"
                  className="block text-sm mb-2 font-medium dark:text-white"
                >
                  Announce to
                </label>
                <select
                  name="sendType"
                  id="sendType"
                  className={`py-2 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
                    Errors.sendType ? "border-red-500" : "border-slate-200"
                  }`}
                  required
                  onChange={handlechanges}
                  value={data.sendType}
                >
                  <option selected value="all">
                    All
                  </option>
                  <option value="employees">Employees</option>
                  <option value="teachers">Teachers</option>
                  <option value="students">Students</option>
                  <option value="specific">Specific</option>
                </select>
                {Errors.sendType && (
                  <span className="text-red-500 text-sm mt-1">
                    {Errors.sendType}
                  </span>
                )}
              </div>
              {data.sendType === "specific" && (
                <div>
                  {/* Add select specific user through usercredentials */}
                  Select account here MULTI
                </div>
              )}

              <div>
                <label
                  htmlFor="content"
                  className="block text-sm mb-2 font-medium dark:text-white"
                >
                  Announcement content
                </label>
                <textarea
                  name="content"
                  id="content"
                  defaultValue={data.content}
                  class={`py-2 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
                    Errors.content ? "border-red-500" : "border-slate-200"
                  }`}
                  rows="4"
                  required
                  onChange={handlechanges}
                  placeholder="This is a textarea placeholder"
                ></textarea>
                {Errors.content && (
                  <span className="text-red-500 text-sm mt-1">
                    {Errors.content}
                  </span>
                )}
              </div>
              <div className="">
                <Switch
                  name="post"
                  isSelected={Boolean(data.post)}
                  onValueChange={(e) => {
                    setdata({ ...data, post: e });
                  }}
                  classNames={{
                    base: cn(
                      "inline-flex flex-row-reverse w-full max-w-full bg-content1 hover:bg-content2 items-center",
                      "justify-between cursor-pointer rounded-lg gap-2 p-4 border-2 border-transparent"
                    ),
                    wrapper: "p-0 h-4 overflow-visible",
                    thumb: cn(
                      "w-6 h-6 border-2 shadow-lg",
                      "group-data-[hover=true]:border-primary",
                      //selected
                      "group-data-[selected=true]:ml-6",
                      // pressed
                      "group-data-[pressed=true]:w-7",
                      "group-data-[selected]:group-data-[pressed]:ml-4"
                    )
                  }}
                >
                  <div className="flex flex-col gap-1">
                    <p className="text-medium">Post Announcement</p>
                    <p className="text-tiny text-default-400">
                      The announcement will be post immediately.
                    </p>
                  </div>
                </Switch>
              </div>
              <div className="">
                <Switch
                  isSelected={data.priority}
                  onValueChange={(e) => {
                    setdata({ ...data, priority: e });
                  }}
                  classNames={{
                    base: cn(
                      "inline-flex flex-row-reverse w-full max-w-full bg-content1 hover:bg-content2 items-center",
                      "justify-between cursor-pointer rounded-lg gap-2 p-4 border-2 border-transparent"
                    ),
                    wrapper: "p-0 h-4 overflow-visible",
                    thumb: cn(
                      "w-6 h-6 border-2 shadow-lg",
                      "group-data-[hover=true]:border-primary",
                      //selected
                      "group-data-[selected=true]:ml-6",
                      // pressed
                      "group-data-[pressed=true]:w-7",
                      "group-data-[selected]:group-data-[pressed]:ml-4"
                    )
                  }}
                >
                  <div className="flex flex-col gap-1">
                    <p className="text-medium">Prioritize?</p>
                    <p className="text-tiny text-default-400">
                      The announcement will be shown at the top of all
                      announcements
                    </p>
                  </div>
                </Switch>
              </div>
            </form>
          </div>
        </>
      )}
    </div>
  );
}
