import axios from "axios";
import { useEffect, useState } from "react";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import { Skeleton, Spinner } from "@nextui-org/react";
import AssignEnrolledStudents from "../../actions/AssigneEnrolledstudent";
import { GetCurrentAcademicYear } from "../../../../../utils/helpers/stringformat";

export default function AssignedEnrolledStudentTotal({
  courseid,
  isrefreshed,
  refresher
}) {
  const { role, server } = useAuth();

  const [isLoading, setisLoading] = useState(false);
  const [MainError, setMainError] = useState();

  const [students, setstudents] = useState([]);
  const [Unassignedstudents, setUnassignedstudents] = useState(0);

  useEffect(() => {
    setisLoading(true);
    async function fetchenrollee() {
      try {
        const response = await axios.get(`${server}/v1/course/students`, {
          params: { id: courseid.$id, academicYear: GetCurrentAcademicYear() }
        });
        const TotalUnassigned = await axios.get(
          `${server}/v1/students/assigned`,
          {
            params: {
              courseid: courseid.$id,
              academicYear: GetCurrentAcademicYear()
            }
          }
        );
        setUnassignedstudents(TotalUnassigned.data.total);
        setstudents(response.data);
      } catch (error) {
        // Handle any errors
        console.error(error);
      } finally {
        setisLoading(false);
      }
    }
    fetchenrollee();
  }, [courseid, isrefreshed]);

  return isLoading ? (
    <Skeleton isLoaded={false} className=" rounded-full">
      <span className="text-sm ">
        Total Enrolled students : {students.total}
      </span>
    </Skeleton>
  ) : (
    <>
      {students && students.total > 0 && !isLoading ? (
        <AssignEnrolledStudents Course={courseid} refreshparent={refresher}>
          <span className="text-sm font-normal p-2 rounded-lg border-1 shadow-sm hover:bg-slate-200">
            <span className="font-medium">Total Students :</span>{" "}
            {Unassignedstudents} / {students.total}
          </span>
        </AssignEnrolledStudents>
      ) : (
        <div className="text-sm font-normal">
          <span className="font-medium">Total Students :</span> {students.total}
        </div>
      )}
    </>
  );
}
