import React, { useEffect, useRef, useState } from "react";
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownSection,
  DropdownItem,
  Button,
  cn,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Badge,
  Slider,
  TimeInput
} from "@nextui-org/react";
import { functions } from "../../../../utils/AppwriteConfig/Config";
import ResponsiveToolTip from "../../../../Components/ResponsiveTooltip";
import { Time } from "@internationalized/date";
import Error from "../../../../Components/ErrorIndicator";

export default function OptionSched({
  loading,
  isSchedDone, // is schedule is done? used for loading purposes
  data,
  updateConfigs
}) {
  const [isPopOverOpen, setIsPopOverOpen] = useState(false);
  const [MainError, setMainError] = useState("");
  const [Errors, setErrors] = useState({
    start: "",
    dismissal: "",
    break: "",
    lunch: ""
  });

  //Schedule COnfigs
  const [BLConfigs, setBLConfigs] = useState(
    data?.BLConfigs || {
      BLConfig: "default", // default or alternate
      break: new Time(10), //time,
      totalBreak: 15,
      lunch: new Time(12), //time
      totalLunch: 30
    }
  );

  const [ScheduleConfigs, setScheduleConfigs] = useState(
    data?.ScheduleConfigs || {
      scheduleConfig: "normalScheduling",
      start: new Time(7),
      dismissal: new Time(15)
    }
  );
  // Helper function to convert time to minutes since midnight
  const timeToMinutes = (time) => time.hour * 60 + time.minute;

  // VALIDATOR
  useEffect(() => {
    try {
      const startMinutes = timeToMinutes(ScheduleConfigs.start);
      const dismissalMinutes = timeToMinutes(ScheduleConfigs.dismissal);
      const breakMinutes = timeToMinutes(BLConfigs.break);
      const lunchMinutes = timeToMinutes(BLConfigs.lunch);

      // 6:30 AM is 390 minutes from midnight, 8:00 PM is 1200 minutes from midnight
      const MIN_START_TIME = 390;
      const MAX_END_TIME = 1200;
      const MIN_TIME_BEFORE_BREAK = 30;
      const MIN_TIME_BEFORE_LUNCH = 30;

      let errors = {}; // Initialize an empty object for errors

      // Validation for start time
      if (startMinutes < MIN_START_TIME) {
        errors.start = "Start time should be 6:30 AM or later";
      }

      // Validation for dismissal time
      if (dismissalMinutes > MAX_END_TIME) {
        errors.dismissal = "Dismissal time should be no later than 8:00 PM";
      }

      // Validation for break time
      if (breakMinutes - startMinutes < MIN_TIME_BEFORE_BREAK) {
        errors.break = "There must be at least 30 minutes before the break";
      }

      // Validation for lunch time
      if (lunchMinutes - breakMinutes < MIN_TIME_BEFORE_LUNCH) {
        errors.lunch =
          "There must be at least 30 minutes between the break and lunch";
      }

      // If errors object is not empty, set the errors and throw an error
      if (Object.keys(errors).length > 0) {
        setErrors(errors); // Set the errors in the state
        const errorMessages = Object.values(errors).filter(
          (error) => error !== ""
        );

        if (errorMessages.length > 0) {
          // Concatenate the errors with a comma separator
          const concatenatedErrors = errorMessages.join(", ");
          setMainError(concatenatedErrors);
        }
        throw new Error("Validation failed");
      }

      // Clear errors if all validations pass
      setErrors({
        start: "",
        dismissal: "",
        break: "",
        lunch: ""
      });
    } catch (err) {
      console.error("Validation error:", err.message);
    }
  }, [BLConfigs, ScheduleConfigs]);

  const [isChanged, setIsChanged] = useState(false);

  //Checks if there are CHanges in Schedule CONfigs
  useEffect(() => {
    const isSchedConfigsChanges =
      JSON.stringify(data?.ScheduleConfigs) !== JSON.stringify(ScheduleConfigs);
    const isBLCOnfigsChanges =
      JSON.stringify(data?.BLConfigs) !== JSON.stringify(BLConfigs);

    if (isBLCOnfigsChanges || isSchedConfigsChanges) {
      setIsChanged(true);
      console.log(true);
    } else {
      setIsChanged(false);
      console.log(false);
    }
  }, [BLConfigs, ScheduleConfigs, data]);

  return (
    <>
      {MainError && (
        <Error
          errorMessage={MainError}
          reset={setMainError}
          title="Input Errors "
        />
      )}
      <Popover
        isOpen={isPopOverOpen}
        onOpenChange={(isOpen) => setIsPopOverOpen(isOpen)}
        placement="bottom-end"
        shouldBlockScroll
        showArrow={true}
      >
        <PopoverTrigger>
          <Button
            isDisabled={loading || isSchedDone}
            variant="bordered"
            color="secondary"
            className="bg-[#ededed] px-1 s group button-container"
          >
            <div className="button-content">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-sliders2 button-icon"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M10.5 1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4H1.5a.5.5 0 0 1 0-1H10V1.5a.5.5 0 0 1 .5-.5M12 3.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m-6.5 2A.5.5 0 0 1 6 6v1.5h8.5a.5.5 0 0 1 0 1H6V10a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5M1 8a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2A.5.5 0 0 1 1 8m9.5 2a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V13H1.5a.5.5 0 0 1 0-1H10v-1.5a.5.5 0 0 1 .5-.5m1.5 2.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5"
                />
              </svg>
              <span className="button-text">Configurations</span>
            </div>
          </Button>
        </PopoverTrigger>
        <PopoverContent className="p-3">
          <div className="max-w-[390px] max-h-[300px] overflow-auto  [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-secondary-500 dark:[&::-webkit-scrollbar-track]:bg-slate-700  dark:[&::-webkit-scrollbar-thumb]:bg-slate-500">
            <div className="">
              <div className="py-1">
                <span className="text-xs font-normal text-[#71717a]">
                  Subject Scheduling Presets
                </span>
              </div>
              <button
                onClick={() => {
                  // setScheduleConfigs({
                  //   ...ScheduleConfigs,
                  //   scheduleConfig: "normalScheduling"
                  // });
                }}
                className={`${
                  ScheduleConfigs.scheduleConfig === "normalScheduling" &&
                  "bg-secondary-50"
                } flex w-full items-center gap-x-3.5 py-2 px-3 rounded-lg  text-secondary-600 hover:bg-gray-100 focus:outline-none  dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700`}
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="bi bi-calendar-check"
                    viewBox="0 0 16 16"
                  >
                    <path d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0" />
                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                  </svg>
                </div>
                <div className="flex flex-col">
                  <div className="w-full flex flex-col items-start justify-center">
                    <span
                      id="react-aria1555630211-:r69:"
                      className="flex-1 text-small font-normal truncate"
                    >
                      Normal Scheduling
                    </span>
                    <span
                      id="react-aria1555630211-:r6a:"
                      className="w-full  text-tiny text-left text-foreground-500 group-hover:text-current"
                    >
                      Every subject is scheduled to be taught every day
                      throughout the academic week.
                    </span>
                  </div>
                </div>
              </button>
              <button
                onClick={() => {
                  // setScheduleConfigs({
                  //   ...ScheduleConfigs,
                  //   scheduleConfig: "allSubject1h"
                  // });
                }}
                className={`${
                  ScheduleConfigs.scheduleConfig === "allSubject1h" &&
                  "bg-secondary-50"
                } flex w-full items-center gap-x-3.5 py-2 px-3 rounded-lg  text-secondary-600 hover:bg-gray-100 focus:outline-none  dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700`}
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="bi bi-calendar3"
                    viewBox="0 0 16 16"
                  >
                    <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857z" />
                    <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                  </svg>
                </div>
                <div className="flex flex-col">
                  <div className="w-full flex flex-col items-start justify-center">
                    <span
                      id="react-aria1555630211-:r69:"
                      className="flex-1 text-small font-normal truncate"
                    >
                      1 Hour per Subject Scheduling
                    </span>
                    <span
                      id="react-aria1555630211-:r6a:"
                      className="w-full text-tiny text-left text-foreground-500 group-hover:text-current"
                    >
                      Each subject is taught for 1 hour daily
                    </span>
                  </div>
                </div>
              </button>
              <button
                onClick={() => {
                  // setScheduleConfigs({
                  //   ...ScheduleConfigs,
                  //   scheduleConfig: "Subject1Min"
                  // });
                }}
                className={`${
                  ScheduleConfigs.scheduleConfig === "Subject1Min" &&
                  "bg-secondary-50"
                } flex w-full items-center gap-x-3.5 py-2 px-3 rounded-lg  text-secondary-600 hover:bg-gray-100 focus:outline-none  dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700`}
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="bi bi-calendar-week"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5z" />
                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                  </svg>
                </div>
                <div className="flex flex-col">
                  <div className="w-full flex flex-col items-start justify-center">
                    <span
                      id="react-aria1555630211-:r69:"
                      className="flex-1 text-small font-normal truncate"
                    >
                      1 Hour Major Minimum Scheduling
                    </span>
                    <span
                      id="react-aria1555630211-:r6a:"
                      className="w-full text-tiny text-left text-foreground-500 group-hover:text-current"
                    >
                      Major subjects must be taught for at least 1 hour daily,
                      while other subjects can have variable scheduling.
                    </span>
                  </div>
                </div>
              </button>
            </div>
            <div className="m-0"></div>
            {/* General Schedule Info */}
            <div className=" flex space-y-2 flex-col">
              <div className="py-1">
                <span className="text-xs font-normal text-[#71717a]">
                  Schedule Time Configuration
                </span>
              </div>
              <div className=" flex space-x-2">
                <TimeInput
                  className={` ${
                    Errors.start && "border border-red-300 rounded-xl"
                  }`}
                  color="warning"
                  label="Start Time"
                  value={ScheduleConfigs.start}
                  onChange={(value) => {
                    setScheduleConfigs({
                      ...ScheduleConfigs,
                      start: value
                    });
                  }}
                />
                {/* <TimeInput
                  className={` ${
                    Errors.dismissal && "border border-red-300 rounded-xl"
                  }`}
                  color="warning"
                  label="Dismissal Time"
                  value={ScheduleConfigs.dismissal}
                  onChange={(value) => {
                    setScheduleConfigs({
                      ...ScheduleConfigs,
                      dismissal: value
                    });
                  }}
                /> */}
              </div>
              <div>
                {/* <div className="pb-4">
                  <div className="py-1">
                    <span className="text-xs font-normal text-[#71717a]">
                      Breaks and Lunches Configuration
                    </span>
                  </div>
                  <button
                    onClick={() => {
                      setBLConfigs({
                        ...BLConfigs,
                        BLConfig: "default"
                      });
                    }}
                    className={`${
                      BLConfigs.BLConfig === "default" && "bg-secondary-50"
                    } flex w-full items-center gap-x-3.5 py-2 px-3 rounded-lg  text-secondary-600 hover:bg-gray-100 focus:outline-none focus:bg-secondary-50 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700`}
                  >
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        class="bi bi-border-all"
                        viewBox="0 0 16 16"
                      >
                        <path d="M0 0h16v16H0zm1 1v6.5h6.5V1zm7.5 0v6.5H15V1zM15 8.5H8.5V15H15zM7.5 15V8.5H1V15z" />
                      </svg>
                    </div>
                    <div className="flex flex-col">
                      <div className="w-full flex flex-col items-start justify-center">
                        <span
                          id="react-aria1555630211-:r69:"
                          className="flex-1 text-small font-normal truncate"
                        >
                          Default Break and Lunches
                        </span>
                        <span
                          id="react-aria1555630211-:r6a:"
                          className="w-full  text-tiny text-left text-foreground-500 group-hover:text-current"
                        >
                          Same breaks and lunches for each grade level
                        </span>
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() => {
                      setBLConfigs({
                        ...BLConfigs,
                        BLConfig: "alternate"
                      });
                    }}
                    className={`${
                      BLConfigs.BLConfig === "alternate" && "bg-secondary-50"
                    } flex w-full items-center gap-x-3.5 py-2 px-3 rounded-lg  text-secondary-600 hover:bg-gray-100 focus:outline-none focus:bg-secondary-50 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700`}
                  >
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        class="bi bi-border-center"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.969 0H0v.969h.5V1h.469V.969H1V.5H.969zm.937 1h.938V0h-.938zm1.875 0h.938V0H3.78v1zm1.875 0h.938V0h-.938zM7.531.969V1h.938V.969H8.5V.5h-.031V0H7.53v.5H7.5v.469zM9.406 1h.938V0h-.938zm1.875 0h.938V0h-.938zm1.875 0h.938V0h-.938zm1.875 0h.469V.969h.5V0h-.969v.5H15v.469h.031zM1 2.844v-.938H0v.938zm6.5-.938v.938h1v-.938zm7.5 0v.938h1v-.938zM1 4.719V3.78H0v.938h1zm6.5-.938v.938h1V3.78h-1zm7.5 0v.938h1V3.78h-1zM1 6.594v-.938H0v.938zm6.5-.938v.938h1v-.938zm7.5 0v.938h1v-.938zM0 8.5v-1h16v1zm0 .906v.938h1v-.938zm7.5 0v.938h1v-.938zm8.5.938v-.938h-1v.938zm-16 .937v.938h1v-.938zm7.5 0v.938h1v-.938zm8.5.938v-.938h-1v.938zm-16 .937v.938h1v-.938zm7.5 0v.938h1v-.938zm8.5.938v-.938h-1v.938zM0 16h.969v-.5H1v-.469H.969V15H.5v.031H0zm1.906 0h.938v-1h-.938zm1.875 0h.938v-1H3.78v1zm1.875 0h.938v-1h-.938zm1.875-.5v.5h.938v-.5H8.5v-.469h-.031V15H7.53v.031H7.5v.469zm1.875.5h.938v-1h-.938zm1.875 0h.938v-1h-.938zm1.875 0h.938v-1h-.938zm1.875-.5v.5H16v-.969h-.5V15h-.469v.031H15v.469z" />
                      </svg>
                    </div>
                    <div className="flex flex-col">
                      <div className="w-full flex flex-col items-start justify-center">
                        <span
                          id="react-aria1555630211-:r69:"
                          className="flex-1 text-small font-normal truncate"
                        >
                          Alternating Break and Lunches
                        </span>
                        <span
                          id="react-aria1555630211-:r6a:"
                          className="w-full text-tiny text-left text-foreground-500 group-hover:text-current"
                        >
                          Breaks and Lunches is sequeantially alternated to
                          reduce canteen capacity
                        </span>
                      </div>
                    </div>
                  </button>
                </div> */}
                <div className="py-1">
                  <span className="text-xs font-normal text-[#71717a]">
                    Break and Lunch Time
                  </span>
                </div>
                <div className="flex flex-col px-4 space-y-2">
                  <div className="flex gap-2">
                    <TimeInput
                      className={` ${
                        Errors.break && "border border-red-300 rounded-xl"
                      } w-full flex-1 `}
                      color="secondary"
                      label="Break Time"
                      value={BLConfigs.break}
                      onChange={(value) => {
                        setBLConfigs({
                          ...BLConfigs,
                          break: value
                        });
                      }}
                    />
                    <input
                      type="text"
                      className={`${
                        (parseInt(BLConfigs.totalBreak) > 60 ||
                          parseInt(BLConfigs.totalBreak) < 5 ||
                          !BLConfigs.totalBreak) &&
                        "border-red-500 focus:border-red-500 focus:ring-red-500"
                      } py-2 px-3 rounded-xl w-32 bg-secondary-50 text-secondary-600 flex-initial text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600`}
                      required
                      placeholder="Total mins"
                      value={BLConfigs.totalBreak}
                      onChange={(e) =>
                        setBLConfigs({
                          ...BLConfigs,
                          totalBreak: e.target.value.replace(/\D/g, "") // Ensure only numeric characters are stored in state
                        })
                      }
                      onKeyDown={(e) => {
                        const isNumeric = /^\d$/.test(e.key);
                        const isBackspaceOrDelete =
                          e.key === "Backspace" || e.key === "Delete";
                        const isArrowKey = e.key.startsWith("Arrow");
                        const isNavigationKey =
                          e.key === "Tab" || e.key === "Enter";
                        const isMaxLengthReached = e.target.value.length >= 2;

                        // Allow numeric keys, backspace, delete, arrow keys, tab, and enter
                        if (
                          isNumeric ||
                          isBackspaceOrDelete ||
                          isArrowKey ||
                          isNavigationKey
                        ) {
                          return;
                        }

                        // Prevent non-numeric inputs and check length constraints
                        if (!isMaxLengthReached || isBackspaceOrDelete) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                  <div className="flex gap-2">
                    <TimeInput
                      className={` ${
                        Errors.lunch && "border  border-red-300 rounded-xl"
                      } flex-1 `}
                      color="secondary"
                      label="Lunch Time"
                      value={BLConfigs.lunch}
                      onChange={(value) => {
                        setBLConfigs({
                          ...BLConfigs,
                          lunch: value
                        });
                      }}
                    />
                    <input
                      type="text"
                      className={`${
                        parseInt(BLConfigs.totalLunch) <= 29 &&
                        "border-red-500 focus:border-red-500 focus:ring-red-500"
                      } py-2 px-3 rounded-xl w-32 bg-secondary-50 text-secondary-600 flex-initial text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600`}
                      required
                      placeholder="Total mins"
                      value={BLConfigs.totalLunch}
                      onChange={(e) =>
                        setBLConfigs({
                          ...BLConfigs,
                          totalLunch: e.target.value.replace(/\D/g, "") // Ensure only numeric characters are stored in state
                        })
                      }
                      onKeyDown={(e) => {
                        const isNumeric = /^\d$/.test(e.key);
                        const isBackspaceOrDelete =
                          e.key === "Backspace" || e.key === "Delete";
                        const isArrowKey = e.key.startsWith("Arrow");
                        const isNavigationKey =
                          e.key === "Tab" || e.key === "Enter";
                        const isMaxLengthReached = e.target.value.length >= 2;

                        // Allow numeric keys, backspace, delete, arrow keys, tab, and enter
                        if (
                          isNumeric ||
                          isBackspaceOrDelete ||
                          isArrowKey ||
                          isNavigationKey
                        ) {
                          return;
                        }

                        // Prevent non-numeric inputs and check length constraints
                        if (!isMaxLengthReached || isBackspaceOrDelete) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Apply btn */}
            <div
              className={` ${
                !isChanged && "hidden"
              } flex justify-end space-x-2 pt-3 sticky p-2 bottom-0 bg-white`}
            >
              <Button
                onPress={() => {
                  setBLConfigs(data?.BLConfigs);
                  setScheduleConfigs(data?.ScheduleConfigs);
                }}
                variant="light"
                size="sm"
              >
                Cancel
              </Button>

              <Button
                size="sm"
                isDisabled={
                  Errors.start !== "" ||
                  Errors.dismissal !== "" ||
                  Errors.break !== "" ||
                  Errors.lunch !== ""
                }
                color="secondary"
                onPress={() => {
                  updateConfigs(BLConfigs, ScheduleConfigs);
                  setIsPopOverOpen(false);
                }}
              >
                Apply
              </Button>
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </>
  );
}
