import { useEffect, useState } from "react";
import AddPrefferedSubject from "../../action/AddPrefferedSubject";
import PrefferdSubjectCard from "../PrefferedSubjectCard";
import { useAuth } from "../../../../../../utils/AppwriteConfig/AuthContext";
import axios from "axios";
import { Skeleton } from "@nextui-org/react";
import useRealtimeSubscription from "../../../../../../utils/AppwriteConfig/RealtimeServices";

export default function PrefferdSubjects({ popoverstate, setpopooverstate }) {
  /**
   *
   * This renders all preffered subject to teach of an teacher
   *
   */
  const { server, user } = useAuth();

  const [isLoading, setisLoading] = useState(false);
  const [isRefresh, setisRefresh] = useState(false);
  const [MainError, setMainError] = useState();

  const [PreferredSubjects, setPreferredSubjects] = useState([]);
  const [Subjects, setSubjects] = useState([]);

  useEffect(() => {
    setSubjects(PreferredSubjects.map((subj) => subj.subject.$id));
  }, [PreferredSubjects]);

  useEffect(() => {
    async function fetchsubj() {
      try {
        setisLoading(true);
        const preferredSubjects = await axios.get(
          `${server}/v1/teacher/subjects/preferred`,
          {
            params: {
              user: user.$id
            }
          }
        );
        setPreferredSubjects(preferredSubjects.data.documents);
      } catch (error) {
        // Handle any errors
        setMainError(error);
      } finally {
        setisLoading(false);
      }
    }
    fetchsubj();
  }, [isRefresh]);

  const HandleRealtimeMessages = (changes) => {
    console.log("Changess: ", changes);
    setisRefresh(!isRefresh);

    // if (changes.changetype === "update") {
    //   //
    //   const prefSubjIndex = PreferredSubjects.findIndex(
    //     (subj) => subj.$id === changes.payload.$id
    //   );
    //   if (prefSubjIndex !== -1) {
    //     const updatedPrefSubj = [...PreferredSubjects];
    //     updatedPrefSubj[prefSubjIndex] = changes.payload;
    //     setPreferredSubjects(updatedPrefSubj);
    //   } else {
    //     setisRefresh(!isRefresh);
    //   }
    // } else if (changes.changetype === "delete") {
    //   const filteredSubj = PreferredSubjects.filter(
    //     (subj) => subj.$id !== changes.payload.$id
    //   );
    //   setPreferredSubjects(filteredSubj);
    // } else if (changes.changetype === "create") {
    //   setPreferredSubjects((prevDocs) => [changes.payload, ...prevDocs]);
    // }
  };

  console.log(process.env.REACT_APP_PREFERRED_SUBJECT);

  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_PREFERRED_SUBJECT,
    HandleRealtimeMessages
  );

  return (
    <div className="flex flex-col pb-2  h-full w-full ">
      <div className="mb-2 flex-initial">
        {/* <AddPrefferedSubject subjectsdata={Subjects} /> */}
      </div>

      <div className=" flex flex-1 w-full flex-col max-h-full relative  space-y-2">
        {isLoading ? (
          <>
            <Skeleton isLoaded={!isLoading} className="rounded-lg">
              <PrefferdSubjectCard
                pstate={popoverstate}
                setpstate={setpopooverstate}
              />
            </Skeleton>
            <Skeleton isLoaded={!isLoading} className="rounded-lg">
              <PrefferdSubjectCard
                pstate={popoverstate}
                setpstate={setpopooverstate}
              />
            </Skeleton>
            <Skeleton isLoaded={!isLoading} className="rounded-lg">
              <PrefferdSubjectCard
                pstate={popoverstate}
                setpstate={setpopooverstate}
              />
            </Skeleton>
          </>
        ) : (
          <div className="flex flex-1 flex-col">
            {PreferredSubjects && PreferredSubjects.length > 0 ? (
              PreferredSubjects.map((subject) => (
                <PrefferdSubjectCard
                  key={subject.$id}
                  pstate={popoverstate}
                  data={subject}
                  setpstate={setpopooverstate}
                />
              ))
            ) : (
              <div className=" w-full flex-1 flex h-full justify-center items-center">
                No preferred Subject yet
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
