import React, { useEffect, useState } from "react";
import {
  Chip,
  Avatar,
  Skeleton,
  Tooltip,
  select,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Button
} from "@nextui-org/react";
import TecaherSuitabilityTooltip from "./renders/TeacherSuitabilityTooltip";
import ReAssignTeacher from "./modal/Re-AssignTeacher";
import ReAssignTeacherbtn from "./modal/Re_AssignBTN";

export default function AssignedSubjectCourses({
  Data,
  factors,
  Count,
  AddSchedWaning,
  selectSubject,
  totals,
  suitableTeachers,
  updateAssigned
}) {
  // Initialize Preline when assignedTeachers or viewType changes
  useEffect(() => {
    require("preline/preline");
  }, [Data]);

  // Initialize HSStaticMethods if available
  useEffect(() => {
    if (window.HSStaticMethods) {
      window.HSStaticMethods.autoInit();
    }
  }, [Data]);

  return (
    <div className="p-2 space-y-2">
      <div>
        {Data &&
          Data.sections &&
          Data?.sections.length > 0 &&
          Data.sections.map((data) => (
            <div key={data.sectionId} className="font-poppins flex flex-col">
              <div className="flex flex-initial bg-slate-300 p-2 rounded-md">
                <div className="text-medium font-medium">
                  {data.sectionName}
                </div>
              </div>
              <div className="flex-col flex p-2">
                {data.subjects?.length > 0 &&
                  data.subjects.map((subject, index) => (
                    <Subjects
                      key={index}
                      section={{
                        id: data.id,
                        sectionId: data.sectionId,
                        sectionName: data.sectionName
                      }}
                      subjectInfo={subject}
                      Cfactors={factors}
                      subject={selectSubject}
                    />
                  ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  );

  function Subjects({ subjectInfo, Cfactors, subject, section }) {
    return (
      <div
        className={`${
          subjectInfo.teacherId === null
            ? "bg-warning-200"
            : subjectInfo.status === "updated"
            ? "bg-warning-50"
            : "bg-secondary-200"
        } flex flex-col sm:flex-row sm:flex my-1 p-2 rounded-md items-center text-left`}
      >
        <div className="flex-1 flex gap-2">
          {subjectInfo.subjectName}
          <div className="  hidden md:flex">
            <Tooltip
              className="p-0 m-0 flex-initial  "
              placement="bottom"
              showArrow
              content={
                <TecaherSuitabilityTooltip
                  suitability={subjectInfo}
                  currentFactors={Cfactors}
                />
              }
            >
              <svg
                className="ml-2"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-question-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94" />
              </svg>
            </Tooltip>
          </div>
          <div className="flex md:hidden">
            <Popover placement="bottom">
              <PopoverTrigger>
                <svg
                  className="ml-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-question-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                  <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94" />
                </svg>
              </PopoverTrigger>
              <PopoverContent className="p-0">
                <TecaherSuitabilityTooltip
                  suitability={subjectInfo}
                  currentFactors={Cfactors}
                />
              </PopoverContent>
            </Popover>
          </div>
        </div>
        <div className="flex-initial flex justify-start items-start">
          <Teacher info={subjectInfo} sec={section} selectSubject={subject} />
        </div>
      </div>
    );
  }

  function Teacher({ info, selectSubject, sec }) {
    // State to store teacher information
    const [teacher, setTeacher] = useState({});
    // State to store teacher's initials
    const [initials, setInitials] = useState("NA");

    // Effect to update teacher information when 'info' prop changes
    useEffect(() => {
      setTeacher({ name: info.teacherName, id: info.teacherId });
    }, [info]);

    // Effect to calculate initials from teacher's name whenever the teacher state changes
    useEffect(() => {
      const name = teacher.name && teacher.name.split(" ");
      setInitials(
        teacher.name && name.length > 1 ? `${name[0][0]}${name[1][0]}` : "NA"
      );
    }, [teacher]);

    return teacher && teacher.name ? (
      <Chip
        key={teacher.id}
        variant="flat"
        onClose={() => {
          setTeacher({});
          AddSchedWaning({
            id: info.id,
            courseName: Data.courseName,
            courseId: Data.courseId,
            sectionId: sec.sectionId,
            subjectId: info.subjectId,
            subjectDaysInAWeek: info.subjectDaysInAWeek,
            subjectName: info.subjectName,
            sectionName: sec.sectionName,
            teacherId: null,
            teacherName: null,
            userId: null,
            subjectTimePerDay: info.subjectTimePerDay,
            totalSubjectTimeInAWeek: info.totalSubjectTimeInAWeek
          });
          setInitials("NA");
        }}
        avatar={<Avatar name={initials} />}
      >
        {teacher.name}
      </Chip>
    ) : (
      <ReAssignTeacherbtn
        Data={{
          id: info.id,
          courseName: Data.courseName,
          courseId: Data.courseId,
          sectionId: sec.sectionId,
          subjectId: info.subjectId,
          subjectDaysInAWeek: info.subjectDaysInAWeek,
          subjectName: info.subjectName,
          sectionName: sec.sectionName,
          subjectTimePerDay: info.subjectTimePerDay,
          totalSubjectTimeInAWeek: info.totalSubjectTimeInAWeek
        }}
        totalCounts={Count}
        totalTimes={totals}
        updateAssignedTeacher={updateAssigned}
        suitableTeachers={
          suitableTeachers.find(
            (subject) => subject.subjectId === info.subjectId
          )?.teachers || []
        }
      />
    );
  }
}
