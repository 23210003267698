import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Image,
  useDisclosure,
  Spacer,
  Avatar
} from "@nextui-org/react";
import { useAuth } from "../../../utils/AppwriteConfig/AuthContext";
import axios from "axios";
import { id, storage } from "../../../utils/AppwriteConfig/Config";
import Error from "../../../Components/ErrorIndicator";
import { ID } from "appwrite";

export default function AddAccountPicture({ imageurl }) {
  const { user, server, role } = useAuth();
  const { isOpen, onOpen, onClose, onOpenChange } = useDisclosure();

  const [isLoading, setisLoading] = useState(false);
  const [MainError, setMainError] = useState();
  const [type, settype] = useState();
  const [Errors, setErrors] = useState({});

  const [UserCred, setUserCred] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    async function GetUserCred() {
      try {
      
        setisLoading(true);
        const usercred = await axios.get(`${server}/v1/usercred`, {
          params: { accid: user.$id }
        });

        setUserCred(usercred.data);
      } catch (e) {
        setMainError(e.message);
      } finally {
        setisLoading(false);
      }
    }
    GetUserCred();
  }, [user]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const fileType = file.type;
      if (fileType === "image/jpeg" || fileType === "image/png") {
        setSelectedImage(file);
      } else {
        // Handle invalid file type
        setMainError("Jpeg or PNG file only");
      }
    }
  };

  const Handleupload = async () => {
    //Upload image to Appwrite storage first
    if (user && selectedImage && type) {
      setisLoading(true);
      try {
        //This code saves the image in the storage
        var uploadimage = await storage.createFile(
          "66198605bb373176f557",
          ID.unique(),
          selectedImage
        );

        const newfile = {
          accid: user.$id,
          profile: uploadimage.$id
        };

        //image details in db
        const response = await axios.post(
          `${server}/v1/usercred/update/${type}`,
          newfile
        );
        setSelectedImage();
        onClose();
      } catch (err) {
        setMainError(err.message);
      } finally {
        setisLoading(false);
      }
    }
  };

  return (
    <>
      {MainError && <Error errorMessage={MainError} />}
      <Button
        id="profpic"
        className="bg-[#FFDE59] hidden px-6"
        onPress={() => {
          onOpen();
          settype("profile");
        }}
      >
        Upload Files
      </Button>
      <Button
        id="coverpic"
        className="bg-[#FFDE59] hidden px-6"
        onPress={() => {
          onOpen();
          settype("coverpicture");
        }}
      >
        Upload Files
      </Button>
      <Modal
        size={type === "coverpicture" ? "3xl" : "lg"}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        isKeyboardDismissDisabled
        isDismissable={false}
        hideCloseButton
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex pb-0 flex-col gap-1">
                Select a Profile Picture {type}
              </ModalHeader>
              <ModalBody>
                <div className="flex-1 max-h-96 min-h-56 overflow-auto">
                  <div>
                    <form>
                      <div className="p-2 w-full rounded-md bg-slate-200">
                        <div className="flex items-center gap-2">
                          <Avatar
                            alt={user.name}
                            className="flex-shrink-0"
                            size="sm"
                          />
                          <div className="flex flex-col">
                            <span>{user.name}</span>
                            <span className="text-default-500 text-tiny">
                              {user.email}
                            </span>
                          </div>
                        </div>
                      </div>
                      <Spacer />
                      <label className="block">
                        <input
                          type="file"
                          accept=".jpg, .jpeg, .png"
                          onChange={handleFileChange}
                          className="block w-full text-sm bg-default-100 p-2 rounded-lg text-black file:me-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-secondary-400 file:text-white hover:file:bg-secondary-300 file:disabled:opacity-50 file:disabled:pointer-events-none dark:file:bg-blue-500 dark:hover:file:bg-blue-400"
                        />
                      </label>
                    </form>
                  </div>
                  <div
                    className={`flex overflow-auto p-2 ${
                      type === "coverpicture" ? "" : "rounded-full"
                    } mt-1 bg-slate-200 items-center justify-center`}
                  >
                    {selectedImage ? (
                      <Image
                        alt="NextUI hero Image"
                        src={URL.createObjectURL(selectedImage)}
                        fallbackSrc={require("../../../Img/BSA.jpg")}
                      />
                    ) : (
                      <div className="relative">
                        <Image
                          className={`${
                            type === "coverpicture" ? "" : "rounded-full"
                          }`}
                          alt="NextUI hero Image"
                          src={require("../../../Img/BSA.jpg")}
                          fallbackSrc={require("../../../Img/BSA.jpg")}
                        />
                        <span
                          className={`absolute ${
                            type === "coverpicture" ? "" : "rounded-full"
                          } top-0 z-10 bg-slate-500/60 text-white w-full h-full flex justify-center items-center`}
                        >
                          No selected Image yet
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                {!isLoading && (
                  <Button color="danger" variant="light" onPress={onClose}>
                    Cancel
                  </Button>
                )}
                {selectedImage && user && type && (
                  <>
                    {isLoading ? (
                      <Button isLoading disabled color="secondary">
                        Uploading
                      </Button>
                    ) : (
                      <Button color="secondary" onPress={Handleupload}>
                        Upload
                      </Button>
                    )}
                  </>
                )}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
