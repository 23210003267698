import { Route, Routes } from "react-router-dom";
import Sidebar from "../../../Components/Sidebar";
import { UserHeader } from "../../../Components/Headers/UserHeader";
import Dashboard from "../Dashboard/Dashboard";
import EnrolledSubjects from "./EnrolledSubjectPage/EnrolledSubjects";
import MissionVission from "../MissionVisionPage/MissionVission";

import { useAuth } from "../../../utils/AppwriteConfig/AuthContext";
import UserCalendar from "../../CalendarPage/Calendar";
import Schedules from "../WeeklySchedulePage/Schedules";
import EditDetails from "../../AccountDetails/components/EditDetails";
import PagenotFound from "../../Statuspages/PagenotFound";

export default function Student() {
  const { user, role, logoutUser } = useAuth();
  
  return role.includes("student") ? (
    <div className="flex flex-col w-screen h-screen">
      <UserHeader />
      <Sidebar Role={role} />
      <div class=" w-screen h-screen flex lg:ps-[276px] p-2 md:px-6  overflow-auto ">
        {/* <!-- Page Heading --> */}
        <div className="flex w-full h-full">
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route
              path="/view/subjects/enrolled"
              element={<EnrolledSubjects />}
            />
            <Route path="/view/bsapurpose" element={<MissionVission />} />
            <Route path="/view/schedule" element={<Schedules />} />
            <Route path="/manage/calendar" element={<UserCalendar />} />
            <Route path="/manage/account" element={<EditDetails />} />
            <Route path="/*" element={<PagenotFound />} />
          </Routes>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}
