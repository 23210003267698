import { useEffect, useState } from "react";
import { storage } from "../../../../utils/AppwriteConfig/Config";
import { Button, Skeleton } from "@nextui-org/react";
import Delete from "../../../../Components/modals/DeleteDocument";
import { birthdateformat } from "../../../../utils/helpers/stringformat";

export default function DocumentView({
  CurrentDocument,
  currenttype,
  ParentReloader
}) {
  const [isLoading, setisLoading] = useState(true);
  const [MainError, setMainError] = useState();

  const [download, setdownload] = useState("");

  useEffect(() => {
    setdownload();
  }, [currenttype]);

  //Get file on first render
  useEffect(() => {
    setisLoading(true);
    async function fetchFile() {
      try {
        var x = await storage.getFileDownload(
          "65fcd52c23d228e24999",
          CurrentDocument.fileId
        );
        setdownload(x);
      } catch (error) {
        // Handle any errors
        setMainError(error.message);
      } finally {
        setisLoading(false);
      }
    }
    fetchFile();
  }, [CurrentDocument, currenttype]);

  //Click the download btn hidden
  const handledownload = async () => {
    document.querySelector("#download").click();
  };

  //Refresh Parent
  const rerender = () => {
    ParentReloader();
  };

  return (
    <Skeleton className="rounded-md " isLoaded={!isLoading}>
      <div className="flex-initial bg-slate-200 rounded-lg p-2 flex sm:flex-row flex-col justify-between">
        {CurrentDocument?.fileTitle ? (
          <span className="text-md font-semibold flex items-center">
            {CurrentDocument?.fileTitle} |{" "}
            {birthdateformat(CurrentDocument?.dateAdded)}
          </span>
        ) : (
          <span className="text-md font-semibold flex items-center">
            Please select a File.
          </span>
        )}

        <div className=" space-x-1 flex items-center">
          <Button
            isDisabled={!CurrentDocument?.fileTitle || !download}
            onPress={handledownload}
            className="p-2 bg-slate-400 flex-1 rounded-lg"
          >
            Download
          </Button>
          {CurrentDocument?.fileTitle && download ? (
            <Delete
              title={"document"}
              docs={CurrentDocument}
              refresher={rerender}
            />
          ) : (
            <Button color="danger" isDisabled variant="flat">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-trash"
                viewBox="0 0 16 16"
              >
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
              </svg>
              <span>Delete</span>
            </Button>
          )}
        </div>

        {/* Hidden download button */}
        <a className="hidden" id="download" href={download} download>
          gg
        </a>
      </div>
    </Skeleton>
  );
}
