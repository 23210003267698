import axios from "axios";
import { useEffect, useState } from "react";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import { Skeleton, Tooltip } from "@nextui-org/react";
import useRealtimeSubscription from "../../../../../utils/AppwriteConfig/RealtimeServices";

export default function SectionCapacityChecker({ sectionid, courseid, total }) {
  const { server } = useAuth();

  const [isLoading, setisLoading] = useState(false);
  const [MainError, setMainError] = useState();

  const [isRefresh, setIsRefresh] = useState(false);

  const [TotalStudents, setTotalStudents] = useState(0);

  useEffect(() => {
    async function GetData() {
      try {
        setisLoading(true);
        const EnrolledStudentsInSection = await axios.get(
          `${server}/v1/course/section/students`,
          {
            params: {
              courseid: courseid,
              sectionid: sectionid
            }
          }
        );
        console.log(EnrolledStudentsInSection);
        setTotalStudents(EnrolledStudentsInSection.data.total);
      } catch (e) {
        console.log(e);
        setMainError(e.message);
      } finally {
        setisLoading(false);
      }
    }
    if (sectionid && courseid) {
      GetData();
    }
  }, [sectionid, courseid, isRefresh]);

  const HandleRealtimeChanges = (changes) => {
    if (changes?.payload?.firstName && changes.changetype === "update") {
      setIsRefresh(!isRefresh);
    }

    if (changes?.payload?.course?.$id && changes?.payload?.student?.$id) {
      setIsRefresh(!isRefresh);
    }
  };

  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_ENROLLMENT,
    HandleRealtimeChanges
  );

  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_ENROLLMENT,
    HandleRealtimeChanges
  );
  return (
    <Skeleton isLoaded={!isLoading}>
      <div className=" text-xs font-normal flex space-x-1">
        {isLoading ? (
          <span className=" p-2"></span>
        ) : (
          <span>
            {TotalStudents}/{total}{" "}
          </span>
        )}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-people-fill"
          viewBox="0 0 16 16"
        >
          <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />
        </svg>
      </div>
    </Skeleton>
  );
}
