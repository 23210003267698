import { useEffect, useState } from "react";
import { useAuth } from "../../../utils/AppwriteConfig/AuthContext";
import { Skeleton, Spinner } from "@nextui-org/react";
import ImageViewer from "../../../Components/ImageViewer";
import axios from "axios";
import { storage } from "../../../utils/AppwriteConfig/Config";
import useRealtimeSubscription from "../../../utils/AppwriteConfig/RealtimeServices";

export default function Profile({ ctype }) {
  const { user, server } = useAuth();

  const [isLoading, setisLoading] = useState(false);
  const [MainError, setMainError] = useState();

  const [ProfilePicture, setProfilePicture] = useState();
  const [UserCred, setUserCred] = useState({});
  const [imageURL, setImageURL] = useState(null);
  useEffect(() => {
    async function GetUserCred() {
      try {
     
        setisLoading(true);
        const usercred = await axios.get(`${server}/v1/usercred`, {
          params: { accid: user.$id },
        });

        setUserCred(usercred.data);
        setProfilePicture(usercred.data.profilePicture);
      } catch (e) {
        setMainError(e.message);
      } finally {
        setisLoading(false);
      }
    }
    GetUserCred();
  }, [user]);
  useEffect(() => {
  
  }, [imageURL]);
  useEffect(() => {
    const loadImage = async () => {
      try {
        if (ProfilePicture) {
          // Load image from storage using data.fileid
          const result = await storage.getFilePreview(
            "66198605bb373176f557",
            ProfilePicture
          );
       
          setImageURL(result);
        }
      } catch (error) {
        console.error("Error loading image:", error);
      }
    };

    loadImage();
  }, [ProfilePicture]);

  const handleRealtimeChanges = (changes) => {
    if (changes.changetype === "update" && changes.payload.profilePicture) {
      setProfilePicture(changes.payload.profilePicture);
    } else if (
      changes.changetype === "update" &&
      !changes.payload.profilePicture
    ) {
      setProfilePicture();
    }
  };

  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_USERCRED,
    handleRealtimeChanges
  );

  return (
    <div className="-mt-16 flex-initial relative z-10 bg-[#72539b] inline-block size-48 mx-auto sm:mx-0 rounded-full ring-4 ring-white dark:ring-gray-800">
      {isLoading ? (
        <Skeleton
          isLoaded={!isLoading}
          className="w-full h-full bg-[#72539b] rounded-full"
        >
          <div className=" w-full h-full bg-slate-200 rounded-full flex justify-center items-center">
            <Spinner size="md" content="Loading..."></Spinner>
          </div>
        </Skeleton>
      ) : (
        <>
          {ProfilePicture ? (
            <>
              <div className="flex justify-center relative items-center w-full h-full rounded-full">
                <img
                  className="relative object-cover bg-slate-200 inline-block size-48 mx-auto sm:mx-0 rounded-full ring-4 ring-white dark:ring-gray-800"
                  src={imageURL}
                  alt="Image Description"
                />
                {ctype === "edit" && (
                  <button
                    onClick={() => {
                      document.querySelector("#profpic").click();
                    }}
                    className="absolute top-0 left-0 w-full h-full bg-slate-200 rounded-full flex justify-center items-center opacity-0 transition-opacity duration-300 hover:opacity-50"
                  >
                    {/* Content to be shown on hover */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      fill="#72539b"
                      class="bi bi-file-arrow-up"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 11a.5.5 0 0 0 .5-.5V6.707l1.146 1.147a.5.5 0 0 0 .708-.708l-2-2a.5.5 0 0 0-.708 0l-2 2a.5.5 0 1 0 .708.708L7.5 6.707V10.5a.5.5 0 0 0 .5.5" />
                      <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1" />
                    </svg>
                  </button>
                )}
              </div>

              <ImageViewer />
            </>
          ) : (
            <div className="flex justify-center relative items-center w-full h-full rounded-full">
              {/* This is a Profile picture div */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="150"
                height="150"
                fill="#b6b1b2"
                className="bi bi-person-fill"
                viewBox="0 0 16 16"
              >
                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
              </svg>
              <button
                onClick={() => {
                  document.querySelector("#profpic").click();
                }}
                className="absolute top-0 left-0 w-full h-full bg-slate-200 rounded-full flex justify-center items-center opacity-0 transition-opacity duration-300 hover:opacity-50"
              >
                {/* Content to be shown on hover */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="#72539b"
                  class="bi bi-file-arrow-up"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 11a.5.5 0 0 0 .5-.5V6.707l1.146 1.147a.5.5 0 0 0 .708-.708l-2-2a.5.5 0 0 0-.708 0l-2 2a.5.5 0 1 0 .708.708L7.5 6.707V10.5a.5.5 0 0 0 .5.5" />
                  <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1" />
                </svg>
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
}
