import React, { useState } from "react";
import { Card, CardHeader, CardBody, Image } from "@nextui-org/react";

export default function EditAccount() {
  return (
    <Card shadow="none" className=" rounded-md">
      <CardHeader className="pb-0 bg-[#f7f7f7f7] rounded-md rounded-b-none p-2 border-[#a16ae8] border-b-3 pt-2 px-4 flex-col items-start">
        <span className="text-lg">Edit Info</span>
      </CardHeader>
      <CardBody className="overflow-auto space-y-2 py-2">
        {/* Use overflow-auto to enable scrolling */}
        <div className=" w-full  flex flex-col p-4 rounded-md">
          <p className="text-black text-justify">
            Please be informed that certain restrictions apply to changes made
            to your personal information. These restrictions are in place to
            ensure compliance with regulations set by the Department of
            Education (DepEd) and to maintain the integrity of our records.
            <br />
            <br />
            If you need to update or modify any of your information, kindly
            reach out to the school registrar for assistance. They will guide
            you through the process and ensure that any necessary changes are
            made accurately and in compliance with applicable regulations.
            <br />
            Thank you for your cooperation.
            <br />
          </p>
        </div>
      </CardBody>
    </Card>
  );
}
