import EditPages from "./components/EditPage";
import Mediatabs from "../../../../Components/Mediatabs";
import Picture_Tittle_Item from "../../../../Components/Cards/Picture_Title_Item";
import Landing from "../../../LandingPage/Landing";
import Containers from "./components/Containers";
import ContainerViewer from "./components/ContainerViewer";
import { useState } from "react";
import {
  Card,
  Button,
  Tooltip,
  Popover,
  PopoverTrigger,
  PopoverContent
} from "@nextui-org/react";

export default function Pages() {
  const [isOpen, setIsOpen] = useState(false);
  const [SelectedContainer, setSelectedContainer] = useState({
    name: "Landing"
  });
  return (
    <div className="flex flex-1 space-y-2 flex-col font-poppins overflow-auto p-2 [&::-webkit-scrollbar]:w-[4px] [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-slate-700 dark:[&::-webkit-scrollbar-thumb]:bg-slate-500 ">
      <div className="flex-initial flex justify-between p-2">
        <div className=" text-2xl font-bold text-secondary-500">
          Edit Home Page
        </div>
        <Button
          onPress={() => {
            setSelectedContainer({ name: "Landing" });
          }}
        >
          View Home Page
        </Button>
      </div>
      <div className="bg-white rounded-lg  py-2">
        <div className="text-lg py-1 px-2 flex-row flex">
          <span className="font-semibold">Containers</span>
          <Tooltip
            className=" "
            // DI KO SYOR... ETO YUNG DELAY

            // isOpen={isOpen}
            // onOpenChange={(open) => setIsOpen(open)}
            // onPress={() => {
            //   console.log("gg");
            // }}
            // closeDelay={2000}
            placement="right-start"
            content={
              <div className="px-1 py-2 font-poppins max-w-[300px] space-y-2">
                <div className="text-small font-bold">What Are Containers?</div>
                <div className="text-tiny">
                  Containers are like virtual boxes where you can put different
                  kinds of things. Think of them as sections or areas on our
                  homepage where you can add and organize your content.
                </div>
                <div className="text-small font-bold">
                  How Do Containers Work?
                </div>
                <div className="text-tiny">
                  Each container holds specific types of content that you can
                  customize to fit your needs. For example, one container might
                  hold images, another might display text, and another could
                  show a combination of both.
                </div>
              </div>
            }
          >
            <span className="sm:flex hidden">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="1.75"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-circle-help"
              >
                <circle cx="12" cy="12" r="10" />
                <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                <path d="M12 17h.01" />
              </svg>
            </span>
          </Tooltip>
          <Popover
            placement="right-end"
            className={"flex sm:hidden"}
            showArrow={true}
          >
            <PopoverTrigger className="p-0">
              <button className={"flex sm:hidden p-0"}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1.75"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-circle-help"
                >
                  <circle cx="12" cy="12" r="10" />
                  <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                  <path d="M12 17h.01" />
                </svg>
              </button>
            </PopoverTrigger>
            <PopoverContent>
              <div className="px-1 py-2 max-w-[300px] font-poppins space-y-2">
                <div className="text-small font-bold">What Are Containers?</div>
                <div className="text-tiny">
                  Containers are like virtual boxes where you can put different
                  kinds of things. Think of them as sections or areas on our
                  homepage where you can add and organize your content.
                </div>
                <div className="text-small font-bold">
                  How Do Containers Work?
                </div>
                <div className="text-tiny">
                  Each container holds specific types of content that you can
                  customize to fit your needs. For example, one container might
                  hold images, another might display text, and another could
                  show a combination of both.
                </div>
              </div>
            </PopoverContent>
          </Popover>
        </div>
        <div className="flex flex-row flex-1 pl-2 min-h-44 [&::-webkit-scrollbar]:h-[10px] [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-slate-700 dark:[&::-webkit-scrollbar-thumb]:bg-slate-500    max-w-full overflow-auto w-full ">
          <Containers setcontainer={setSelectedContainer} />
        </div>
      </div>
      <ContainerViewer view={SelectedContainer} />
    </div>
  );
}
