import { useEffect, useState } from "react";
import { convertTo12HourFormat } from "../../../../../utils/helpers/helper";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import { Skeleton, Spinner, Tooltip } from "@nextui-org/react";
import axios from "axios";

export function RequestCardModern({
  data = {
    type: "Full-time Adjustment",
    requestMessage: "Requesting a Full-time Adjustment",
    startHour: 7,
    startMinute: 0,
    endHour: 9,
    endMinute: 0,
    day: "Mon",
    requestReason: "ersg",
    schedules: []
  },
  onCancel = () => alert("Request Canceled"),
  onApprove = () => alert("Request Canceled"),
  type
}) {
  const { user, DataServer } = useAuth();
  // State to manage active tab
  const [activeTab, setActiveTab] = useState("details");

  // Example usage:
  const { startTime, endTime } = convertTo12HourFormat(
    data?.startHour,
    data?.startMinute,
    data?.endHour,
    data?.endMinute
  );

  const daysOfWeek = {
    Mon: "Monday",
    Tue: "Tuesday",
    Wed: "Wednesday",
    Thu: "Thursday",
    Fri: "Friday"
  };

  console.log(data.schedules);

  const [subjects, setSubjects] = useState([]);

  const [isLoading, setIsLoading] = useState(false); // State to handle loading

  useEffect(() => {
    const fetchSubjects = async () => {
      if (data && data.schedules && data.schedules.length > 0) {
        setIsLoading(true); // Set loading to true when fetching starts
        try {
          const subjects = await Promise.all(
            data.schedules.map(async (schedule) => {
              const response = await axios.get(
                `${DataServer}/info/schedule/subject`,
                {
                  params: { id: schedule.courseSubjectTeacher.$id }
                }
              );
              return response.data; // Extract data from response
            })
          );
          setSubjects(subjects); // Update the subjects state
        } catch (error) {
          console.error("Error fetching subjects:", error);
        } finally {
          setIsLoading(false); // Stop loading regardless of success or error
        }
      }
    };

    fetchSubjects();
  }, [data.schedules]);

  return (
    <div className="w-full bg-secondary-100 border border-secondary-100 flex flex-col gap-4 p-6 rounded-xl shadow-lg hover:shadow-2xl transition-transform duration-300 transform hover:scale-[102%]">
      {/* Header Section */}
      <div className="flex items-center justify-between">
        <span className="text-xl font-bold text-gray-800">
          {user.$id === data?.currentTeacher?.$id
            ? `${data?.requestMessage}`
            : `${data?.currentTeacher?.firstName} 
           ${data?.currentTeacher?.lastName}`}
        </span>
        <span className="text-sm font-medium text-gray-500 capitalize">
          {data?.day}
        </span>
      </div>

      {/* Time and Action Section */}
      <div className="flex justify-between items-center">
        <div className="text-lg flex gap-2 items-center text-gray-600">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-clock"
            viewBox="0 0 16 16"
          >
            <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0" />
          </svg>
          <div>
            {data.type === "Temporary Leave"
              ? `Whole Day`
              : `${startTime} - ${endTime}`}
          </div>
        </div>
      </div>
      <div className=" flex gap-1">
        <button
          onClick={() => {
            type("approve");
            onApprove(data);
          }}
          className="px-4 flex-1 py-2 text-sm font-medium text-white bg-success-600 rounded-md hover:bg-success-600 focus:outline-none focus:ring focus:ring-success-600"
        >
          Approve Request
        </button>
        <button
          onClick={() => {
            type("decline");
            onCancel(data);
          }}
          className="px-4 py-2 flex-1 text-sm font-medium text-white bg-danger-600 rounded-md hover:bg-danger-600 focus:outline-none focus:ring focus:ring-danger-600"
        >
          Decline Request
        </button>
      </div>

      {/* Tabs Navigation */}
      <div className="flex border-b border-gray-300 ">
        <button
          className={`px-4 py-2 text-sm font-medium ${
            activeTab === "details"
              ? "text-secondary-600 border-b-2 border-secondary-600"
              : "text-gray-500"
          }`}
          onClick={() => setActiveTab("details")}
        >
          Details
        </button>
        <button
          className={`px-4 py-2 text-sm font-medium ${
            activeTab === "reason"
              ? "text-secondary-600 border-b-2 border-secondary-600"
              : "text-gray-500"
          }`}
          onClick={() => setActiveTab("reason")}
        >
          Reason
        </button>
      </div>

      {/* Tabs Content */}
      <div className="pt-4 text-sm text-gray-700 w-full">
        {activeTab === "details" && (
          <div className="space-y-2">
            <div>
              <strong>{data?.requestMessage}</strong>
            </div>
            <div>
              <strong>Time:</strong>{" "}
              {data.type === "Temporary Leave"
                ? `Whole Day`
                : `${startTime} - ${endTime}`}
            </div>
            <div>
              <strong>Day:</strong> {data && data.day && daysOfWeek[data.day]}
            </div>
            {data && data.schedules && data.schedules.length > 0 && (
              <div className=" flex items-center">
                <strong className=" flex-initial">Subjects to Fill: </strong>{" "}
                <Tooltip
                  color="secondary"
                  content={
                    <div className="px-1 py-2">
                      {isLoading ? (
                        <Spinner
                          size="md"
                          color="white"
                          className=" text-white "
                          title="Loading..."
                        >
                          <span className="text-white">Loading...</span>
                        </Spinner>
                      ) : (
                        <>
                          {subjects &&
                            subjects.length > 0 &&
                            subjects.map((subject) => <div>{subject}</div>)}
                        </>
                      )}
                    </div>
                  }
                >
                  <div className="p-1 px-2 ml-2 flex-initial bg-slate-300 rounded-lg relative transition-transform duration-300 transform hover:scale-[102%]">
                    {data.schedules.length}
                  </div>
                </Tooltip>
              </div>
            )}
          </div>
        )}
        {activeTab === "reason" && (
          <div>
            📄 <span className="italic">{data?.requestReason}</span>
          </div>
        )}
      </div>
    </div>
  );
}

export function ApprovedButPendingRequestCard({
  data = {
    type: "Full-time Adjustment",
    requestMessage: "Requesting a Full-time Adjustment",
    startHour: 7,
    startMinute: 0,
    endHour: 9,
    endMinute: 0,
    day: "Mon",
    requestReason: "ersg"
  },
  onCancel = () => {
    alert("CANCEL");
  },
  sendData,
  adminResponse = "The schedule has been approved but will soon be adjusted"
}) {
  // State to manage active tab
  const { user, DataServer } = useAuth();
  // State to manage active tab
  const [activeTab, setActiveTab] = useState("details");

  // Example usage:
  const { startTime, endTime } = convertTo12HourFormat(
    data?.startHour,
    data?.startMinute,
    data?.endHour,
    data?.endMinute
  );

  const daysOfWeek = {
    Mon: "Monday",
    Tue: "Tuesday",
    Wed: "Wednesday",
    Thu: "Thursday",
    Fri: "Friday"
  };

  const [subjects, setSubjects] = useState([]);

  const [isLoading, setIsLoading] = useState(false); // State to handle loading

  useEffect(() => {
    const fetchSubjects = async () => {
      if (data && data.schedules && data.schedules.length > 0) {
        setIsLoading(true); // Set loading to true when fetching starts
        try {
          const subjects = await Promise.all(
            data.schedules.map(async (schedule) => {
              const response = await axios.get(
                `${DataServer}/info/schedule/subject`,
                {
                  params: { id: schedule.courseSubjectTeacher.$id }
                }
              );
              return response.data; // Extract data from response
            })
          );
          setSubjects(subjects); // Update the subjects state
        } catch (error) {
          console.error("Error fetching subjects:", error);
        } finally {
          setIsLoading(false); // Stop loading regardless of success or error
        }
      }
    };

    fetchSubjects();
  }, [data.schedules]);

  useEffect(() => {
    require("preline/preline");
  }, [data]);

  useEffect(() => {
    if (window.HSStaticMethods) {
      window.HSStaticMethods.autoInit();
    }
  }, [data]);
  return (
    <div className="w-full cursor-pointer bg-blue-50 border border-blue-200 flex flex-col gap-4 p-6 rounded-xl shadow-lg hover:shadow-2xl transition-transform duration-300 transform hover:scale-[102%]">
      <button
        id="ToCLose"
        className="hidden"
        aria-haspopup="dialog"
        aria-expanded="false"
        aria-controls="hs-offcanvas-body-scrolling-with-backdrop"
        data-hs-overlay="#hs-offcanvas-body-scrolling-with-backdrop"
      ></button>
      {/* Header Section */}
      <div className="flex items-center justify-between">
        <span className="text-xl font-bold text-gray-800">
          {user.$id === data?.currentTeacher?.$id
            ? `${data?.requestMessage}`
            : `${data?.currentTeacher?.firstName} 
           ${data?.currentTeacher?.lastName}`}
        </span>
        <span className="text-sm font-medium text-gray-500 capitalize">
          {data.day}
        </span>
      </div>

      {/* Time Section */}
      <div className="text-lg flex gap-2 items-center text-gray-600">
        <div className="flex w-full justify-between items-center">
          <div className="text-lg flex gap-2 items-center text-gray-600">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-clock"
              viewBox="0 0 16 16"
            >
              <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
              <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0" />
            </svg>
            <div>
              {data.type === "Temporary Leave"
                ? `Whole Days`
                : `${startTime} - ${endTime}`}
            </div>
          </div>
        </div>
      </div>
      <div className=" w-full flex gap-2">
        <button
          onClick={() => {
            document.querySelector("#ToCLose").click();
            sendData(data, data.type);
          }}
          className="px-4 flex-1 py-2 text-sm font-medium text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300"
        >
          Apply Request
        </button>
        <button
          onClick={(event) => {
            event.stopPropagation();
            onCancel(data);
          }}
          className="px-4 flex-1 py-2 text-sm font-medium text-white bg-red-500 rounded-md hover:bg-red-600 focus:outline-none focus:ring focus:ring-red-300"
        >
          Cancel Request
        </button>
      </div>
      {/* Tabs Navigation */}
      <div className="flex border-b border-gray-300">
        <button
          className={`px-4 py-2 text-sm font-medium ${
            activeTab === "details"
              ? "text-blue-600 border-b-2 border-blue-600"
              : "text-gray-500"
          }`}
          onClick={(event) => {
            event.stopPropagation();
            setActiveTab("details");
          }}
        >
          Details
        </button>
        <button
          className={`px-4 py-2 text-sm font-medium ${
            activeTab === "reason"
              ? "text-blue-600 border-b-2 border-blue-600"
              : "text-gray-500"
          }`}
          onClick={(event) => {
            event.stopPropagation();
            setActiveTab("reason");
          }}
        >
          Reason
        </button>
        <button
          className={`px-4 py-2 text-sm font-medium ${
            activeTab === "response"
              ? "text-blue-600 border-b-2 border-blue-600"
              : "text-gray-500"
          }`}
          onClick={(event) => {
            event.stopPropagation();
            setActiveTab("response");
          }}
        >
          Admin Response
        </button>
      </div>

      {/* Tabs Content */}
      <div className="pt-4 text-sm text-gray-700 w-full">
        {activeTab === "details" && (
          <div className="space-y-2">
            <div>
              <strong>{data?.requestMessage}</strong>
            </div>
            <div>
              <strong>Time:</strong>{" "}
              {data.type === "Temporary Leave"
                ? `Whole Day`
                : `${startTime} - ${endTime}`}
            </div>
            <div>
              <strong>Day:</strong> {data && data.day && daysOfWeek[data.day]}
            </div>
            {data && data.schedules && data.schedules.length > 0 && (
              <div className=" flex items-center">
                <strong className=" flex-initial">Subjects to Fill: </strong>{" "}
                <Tooltip
                  color="secondary"
                  content={
                    <div className="px-1 py-2">
                      {isLoading ? (
                        <Spinner
                          size="md"
                          color="white"
                          className=" text-white "
                          title="Loading..."
                        >
                          <span className="text-white">Loading...</span>
                        </Spinner>
                      ) : (
                        <>
                          {subjects &&
                            subjects.length > 0 &&
                            subjects.map((subject) => <div>{subject}</div>)}
                        </>
                      )}
                    </div>
                  }
                >
                  <div className="p-1 px-2 ml-2 flex-initial bg-slate-300 rounded-lg relative transition-transform duration-300 transform hover:scale-[102%]">
                    {data.schedules.length}
                  </div>
                </Tooltip>
              </div>
            )}
          </div>
        )}
        {activeTab === "reason" && (
          <div>
            📄 <span className="italic">{data?.requestReason}</span>
          </div>
        )}
        {activeTab === "response" && (
          <div>
            ✅ <span className="font-medium">Admin Response:</span>{" "}
            {data?.adminResponse || adminResponse}
          </div>
        )}
      </div>
    </div>
  );
}

export function ApprovedAndAdjustedRequestCard({
  data = {
    type: "Full-time Adjustment",
    requestMessage: "Requesting a Full-time Adjustment",
    startHour: 7,
    startMinute: 0,
    endHour: 9,
    endMinute: 0,
    day: "Mon",
    requestReason: "ersg"
  },
  adminResponse = "The schedule has been adjusted as requested."
}) {
  // State to manage active tab
  const { user } = useAuth();
  // State to manage active tab
  const [activeTab, setActiveTab] = useState("details");

  // Example usage:
  const { startTime, endTime } = convertTo12HourFormat(
    data?.startHour,
    data?.startMinute,
    data?.endHour,
    data?.endMinute
  );

  const daysOfWeek = {
    Mon: "Monday",
    Tue: "Tuesday",
    Wed: "Wednesday",
    Thu: "Thursday",
    Fri: "Friday"
  };

  return (
    <div className="w-full bg-green-50 border border-green-50 flex flex-col gap-4 p-6 rounded-xl shadow-lg hover:shadow-2xl transition-transform duration-300 transform hover:scale-[102%]">
      {/* Header Section */}
      <div className="flex items-center justify-between">
        <span className="text-xl font-bold text-gray-800">
          {user.$id === data?.currentTeacher?.$id
            ? `${data?.requestMessage}`
            : `${data?.currentTeacher?.firstName} 
           ${data?.currentTeacher?.lastName}`}
        </span>
        <span className="text-sm font-medium text-gray-500 capitalize">
          {data.day}
        </span>
      </div>

      {/* Time Section */}
      <div className="text-lg flex gap-2 items-center text-gray-600">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-clock"
          viewBox="0 0 16 16"
        >
          <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0" />
        </svg>
        <div>
          {data.type === "Temporary Leave"
            ? `Whole Day`
            : `${startTime} - ${endTime}`}
        </div>
      </div>

      {/* Tabs Navigation */}
      <div className="flex border-b border-gray-300">
        <button
          className={`px-4 py-2 text-sm font-medium ${
            activeTab === "details"
              ? "text-green-600 border-b-2 border-green-600"
              : "text-gray-500"
          }`}
          onClick={() => setActiveTab("details")}
        >
          Details
        </button>
        <button
          className={`px-4 py-2 text-sm font-medium ${
            activeTab === "reason"
              ? "text-green-600 border-b-2 border-green-600"
              : "text-gray-500"
          }`}
          onClick={() => setActiveTab("reason")}
        >
          Reason
        </button>
        <button
          className={`px-4 py-2 text-sm font-medium ${
            activeTab === "response"
              ? "text-green-600 border-b-2 border-green-600"
              : "text-gray-500"
          }`}
          onClick={() => setActiveTab("response")}
        >
          Admin Response
        </button>
      </div>

      {/* Tabs Content */}
      <div className="pt-4 text-sm text-gray-700 w-full">
        {activeTab === "details" && (
          <div className="space-y-2">
            <div>
              <strong>{data?.requestMessage}</strong>
            </div>
            <div>
              <strong>Time:</strong>{" "}
              {data.type === "Temporary Leave"
                ? `Whole Day`
                : `${startTime} - ${endTime}`}
            </div>
            <div>
              <strong>Day:</strong> {data && data.day && daysOfWeek[data.day]}
            </div>
          </div>
        )}
        {activeTab === "reason" && (
          <div>
            📄 <span className="italic">{data?.requestReason}</span>
          </div>
        )}
        {activeTab === "response" && (
          <div>
            ✅ <span className="font-medium">Admin Response:</span>{" "}
            {data?.adminResponse || adminResponse}
          </div>
        )}
      </div>
    </div>
  );
}

export function DeclinedRequestCard({
  timeStart = "8:00 AM",
  timeEnd = "9:00 AM",
  day = "Monday",
  teacher = "Teacher 1",
  requestingTeacher = "Teacher 2",
  subject = "Mathematics",
  reason = "The original schedule conflicts with another class.",
  adminResponse = "The schedule request has been declined."
}) {
  // State to manage active tab
  const [activeTab, setActiveTab] = useState("details");

  return (
    <div className="w-full bg-red-50 border border-red-50 flex flex-col gap-4 p-6 rounded-xl shadow-lg hover:shadow-2xl transition-transform duration-300 transform hover:scale-[102%]">
      {/* Header Section */}
      <div className="flex items-center justify-between">
        <span className="text-xl font-bold text-gray-800">{teacher}</span>
        <span className="text-sm font-medium text-gray-500 capitalize">
          {day}
        </span>
      </div>

      {/* Time Section */}
      <div className="text-lg flex gap-2 items-center text-gray-600">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-clock"
          viewBox="0 0 16 16"
        >
          <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0" />
        </svg>
        <div>
          {timeStart} - {timeEnd}
        </div>
      </div>

      {/* Tabs Navigation */}
      <div className="flex border-b border-gray-300">
        <button
          className={`px-4 py-2 text-sm font-medium ${
            activeTab === "details"
              ? "text-red-600 border-b-2 border-red-600"
              : "text-gray-500"
          }`}
          onClick={() => setActiveTab("details")}
        >
          Details
        </button>
        <button
          className={`px-4 py-2 text-sm font-medium ${
            activeTab === "reason"
              ? "text-red-600 border-b-2 border-red-600"
              : "text-gray-500"
          }`}
          onClick={() => setActiveTab("reason")}
        >
          Reason
        </button>
        <button
          className={`px-4 py-2 text-sm font-medium ${
            activeTab === "response"
              ? "text-red-600 border-b-2 border-red-600"
              : "text-gray-500"
          }`}
          onClick={() => setActiveTab("response")}
        >
          Admin Response
        </button>
      </div>

      {/* Tabs Content */}
      <div className="pt-4 text-sm text-gray-700 w-full">
        {activeTab === "details" && (
          <div className="space-y-2">
            <div>
              <strong>Request Type:</strong> {day}
            </div>
            <div>
              <strong>Subject:</strong> {subject}
            </div>
            <div>
              <strong>Time:</strong> {timeStart} - {timeEnd}
            </div>
            <div>
              <strong>Day:</strong> {day}
            </div>
            <div>
              <strong>Assigned Teacher:</strong> {teacher}
            </div>
            <div>
              <strong>Requesting Teacher:</strong> {requestingTeacher}
            </div>
          </div>
        )}
        {activeTab === "reason" && (
          <div>
            📄 <span className="italic">{reason}</span>
          </div>
        )}
        {activeTab === "response" && (
          <div>
            ❌ <span className="font-medium">Admin Response:</span>{" "}
            {adminResponse}
          </div>
        )}
      </div>
    </div>
  );
}
