import axios from "axios";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";

export default function TodoRender({ tododetails, rerenderparent, loading }) {
  const { server } = useAuth();
  const deletetodo = async () => {
    try {
      loading(true);
      const response = await axios.delete(`${server}/v1/todos/delete`, {
        params: { id: tododetails?.$id },
      });
      rerenderparent();
    } catch (error) {
      // Handle any errors
      console.error(error);
    }
  };
  return (
    <div className=" w-full flex capitalize mb-1 bg-[#bf99f0]  relative transition-transform duration-300 transform hover:scale-[102%] items-center p-2 justify-between rounded-md  ">
      {tododetails?.toDoTitle}
      <span className=" hover:bg-[#966dca] rounded-full" onClick={deletetodo}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="currentColor"
          class="bi bi-x"
          viewBox="0 0 16 16"
        >
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
        </svg>
      </span>
    </div>
  );
}
