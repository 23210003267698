import { Link } from "react-router-dom";
import { AlinkP, AlinkY } from "../../Components/ui/Alink";

export default function PagenotFound() {
  return (
    <div className=" flex flex-col text-white h-full">
      <div className="flex flex-initial bg-[#8c52ff] p-2 ">
        <div className="flex-1 flex justify-center items-center">
          <span className=" mr-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-left"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
              />
            </svg>
          </span>
          <Link to={"/user"}>
            <AlinkY>Go back to Landing page</AlinkY>
          </Link>
        </div>
        <div className="flex-1 flex flex-col text-xljustify-center items-center">
          <span className=" w-20 h-20 rounded-full">
            <img
              className=" rounded-full"
              src={require("../../Img/BSA.jpg")}
              alt="BSA"
            />
          </span>
          Bulacan standard Academy
        </div>
        <div className="flex-1"></div>
      </div>
      <div className="flex-1 p-4 flex just">
        <div className="flex w-full justify-center items-center">
          <span className=" w-[220px] h-[220px] flex flex-col justify-center items-center">
            <img alt="404" src={require("../../Img/Status/404.png")} />
            <span className="text-lg font-bold text-[#72539b] text-center">
              Sorry, <a className="text-black">page not found</a> !
            </span>
          </span>
        </div>
      </div>
      <div className=" flex-initial bg-slate-500 p-2 flex justify-center items-center">
        Powered by Edusync 2024
      </div>
    </div>
  );
}
