import Picture_Tittle_Item from "../../../../../Components/Cards/Picture_Title_Item";
import { useEffect, useState } from "react";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import Error from "../../../../../Components/ErrorIndicator";
import axios from "axios";

import React from "react";
import { Card, Skeleton } from "@nextui-org/react";
import ContainerSkeleton from "../../../../../Components/skeleton/Containerskeleton";
import useRealtimeSubscription from "../../../../../utils/AppwriteConfig/RealtimeServices";
import NewContainer from "../actions/NewContainer";

export default function Containers({ setcontainer }) {
  /**
   *
   * THIS COMPONENT RENDERS ALL AVAILABLE CONTAINERS MADE
   *
   */
  const {
    server,
    user,
    isContainerLoading,
    isItemLoading,
    isSContainerLoading,
    setisContainerLoading
  } = useAuth();

  const [isLoading, setisLoading] = useState(false);
  const [Refresh, setRefresh] = useState(false);
  const [MainError, setMainError] = useState();

  const [Containers, setContainers] = useState([]);
  useEffect(() => {
    async function GetData() {
      try {
        setisLoading(true);
        const getcontainer = await axios.get(`${server}/v1/home/containers`, {
          params: { id: user.$id }
        });
        setContainers(getcontainer.data.documents);
      } catch (e) {
        setMainError(e.message);
      } finally {
        setisLoading(false);
      }
    }
    GetData();
  }, []);

  const handleRealtimechanges = (changes) => {
    const changedata = Containers.findIndex(
      (container) => container.$id === changes.payload.$id
    );

    if (changes.changetype === "create") {
      setContainers((prevAnnouncements) => [
        ...prevAnnouncements,
        changes.payload
      ]);
    } else if (changes.changetype === "update") {
      setContainers((prevContainers) => {
        const updatedContainers = [...prevContainers];
        updatedContainers[changedata] = changes.payload;
        return updatedContainers;
      });
    } else if (changes.changetype === "delete") {
      setContainers((prevContainers) =>
        prevContainers.filter(
          (container) => container.$id !== changes.payload.$id
        )
      );
    }
  };

  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_CONTAINER,
    handleRealtimechanges
  );
  console.log(
    isContainerLoading,
    isItemLoading,
    isContainerLoading || isItemLoading
  );
  return (
    <div className="flex flex-col h-auto pb-2 relative">
      <div className="flex flex-initial flex-row flex-nowrap space-x-2 overflow-x-auto">
        {MainError && <Error errorMessage={MainError} reset={setMainError} />}
        <NewContainer totalContainers={Containers.length || 0} />
        {isLoading ? (
          <>
            <ContainerSkeleton />
            <ContainerSkeleton />
            <ContainerSkeleton />
            <ContainerSkeleton />
          </>
        ) : (
          <>
            {Containers.length > 0 && Containers ? (
              Containers.map((container) => (
                <Picture_Tittle_Item
                  key={container.$id}
                  Currentcontainer={setcontainer}
                  container={container}
                  loadingstate={
                    isContainerLoading || isItemLoading || isSContainerLoading
                  }
                />
              ))
            ) : (
              <div className="flex justify-center items-center w-full">
                No containers yet
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
