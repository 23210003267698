import { useAuth } from "../../utils/AppwriteConfig/AuthContext";

export function UserHeader() {
  const { currentPage, role } = useAuth();
  console.log(role, "gg");
  return (
    <div className="sticky top-0 inset-x-0 flex w-full justify-between pr-0 z-10 bg-[#C3B1E1] dark:bg-[#A16AE8] border-y pl-4 sm:pl-6 md:pl-8 lg:hidden dark:border-gray-700">
      <div className="flex items-center py-4">
        {/* Navigation Toggle */}
        <button
          type="button"
          className="text-gray-500 hover:text-gray-600"
          data-hs-overlay="#application-sidebar"
          aria-controls="application-sidebar"
          aria-label="Toggle navigation"
        >
          <span className="sr-only">Toggle Navigation</span>
          <svg
            className="w-5 h-5"
            width="16"
            height="16"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
            />
          </svg>
        </button>

        {/* Breadcrumb */}
        <ol className="ms-3 flex items-center whitespace-nowrap">
          <li className="flex items-center text-sm text-gray-800 dark:text-gray-400">
            Edusync
            <svg
              className="flex-shrink-0 mx-3 w-2.5 h-2.5 text-gray-400 dark:text-gray-600"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 1L10.6869 7.16086C10.8637 7.35239 10.8637 7.64761 10.6869 7.83914L5 14"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </li>
          <li className="flex capitalize items-center text-sm text-gray-800 dark:text-gray-400">
            {role}
            <svg
              className="flex-shrink-0 mx-3 w-2.5 h-2.5 text-gray-400 dark:text-gray-600"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 1L10.6869 7.16086C10.8637 7.35239 10.8637 7.64761 10.6869 7.83914L5 14"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </li>
          <li
            className="text-sm font-semibold text-gray-800 truncate dark:text-gray-400"
            aria-current="page"
          >
            {currentPage || "Dashboard"}
          </li>
        </ol>
      </div>
    </div>
  );
}
