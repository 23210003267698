import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  CheckboxGroup,
  Checkbox,
  usePagination,
  PaginationItemType,
  cn,
  Skeleton
} from "@nextui-org/react";
import { useAuth } from "../../../../utils/AppwriteConfig/AuthContext";
import axios from "axios";
import Error from "../../../../Components/ErrorIndicator";
import EditSubjects from "../components/modals/NewSubject";

export default function AddSubject({
  coursedata,
  subjectsdata,
  allSubjects,
  refresh,
  sem,
  subjectstate
}) {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const { DataServer, server, user } = useAuth();

  const [isLoading, setisLoading] = useState(false);
  const [issaveLoading, setissaveLoading] = useState(false);
  const [MainError, setMainError] = useState();

  const [selected, setSelected] = useState([]);

  const [news, setnews] = useState([]); // New added Subjects
  const [removedSubjects, setRemovedSubjects] = useState([]); // new Remove SUbjects
  const [Subjects, setSubjects] = useState([]); //Current subjects

  const [Pages, setPages] = useState({
    total: 0,
    current: 1
  });
  const [currentSemArray, setCurrentSemArray] = useState([]);

  function closeModal() {
    setSelected([]);
    setnews([]);
    setRemovedSubjects([]);
    setCurrentSemArray([]);
    onClose();
  }

  const { activePage, range, setPage, onNext, onPrevious } = usePagination({
    total: Pages.total,
    showControls: true
  });

  useEffect(() => {
    async function fetchsubj() {
      try {
        setisLoading(true);
        const response = await axios.get(`${DataServer}/course/v2/subjects`, {
          params: { CourseId: coursedata.$id }
        });

        setSubjects(response.data.subjects);
        setCurrentSemArray(response.data.subjectSem);
      } catch (error) {
        // Handle any errors
        setMainError(error);
      } finally {
        setisLoading(false);
      }
    }
    if (parseInt(coursedata.gradeLevel) > 10) fetchsubj();
  }, [coursedata]);

  // Select subject and store the ID to a state
  useEffect(() => {
    setSelected(subjectsdata ? subjectsdata.map((subject) => subject.$id) : []);
  }, [subjectsdata]);

  useEffect(() => {
    console.log(subjectsdata);
  }, [isOpen]);

  // Handle adding/removing subject in a course process
  const handleAddSubj = async (e) => {
    try {
      const data = {
        removed: removedSubjects,
        creator: user.$id,
        AllSubj: Subjects.map((subj) => subj.$id),
        subjects: news.map((subj) => subj),
        remSubj: removedSubjects,
        courseid: coursedata.$id,
        gradeLevel: coursedata.gradeLevel,
        currentSemester: sem,
        semData: currentSemArray,
        subjectsz: subjectsdata
      };

      setissaveLoading(true);
      console.log(data);
      const response = await axios.post(
        `${DataServer}/course/add_subject`,
        data
      );

      refresh();

      closeModal();
    } catch (error) {
      // Handle any errors
      setMainError(error.message);
    } finally {
      setissaveLoading(false);
    }
  };

  // Handle deselecting subjects
  const handleDeselect = (subjectId) => {
    const updatedSelected = selected.filter((id) => id !== subjectId);
    const removedSubject = selected.find((id) => id === subjectId);
    setRemovedSubjects([...removedSubjects, removedSubject]);
    setSelected(updatedSelected);
  };

  // Handle reselecting subjects
  const handleSelect = (subjectId) => {
    const updatedRemovedSubjects = removedSubjects.filter(
      (id) => id !== subjectId
    );
    setSelected([...selected, subjectId]);
    setRemovedSubjects(updatedRemovedSubjects);
  };

  function SetSubjectdata(newd, remove, load) {
    console.log("ETO", newd, remove, load);
    setnews(newd);
    setRemovedSubjects(remove);
    setisLoading(load);
  }

  return (
    <>
      {MainError && <Error errorMessage={MainError} />}
      <Button
        isDisabled={subjectstate}
        className="bg-[#FFDE59] ml-1 rounded-lg text-black font-semibold hover:bg-[#fcd84b]"
        onPress={onOpen}
      >
        Edit
      </Button>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        isDismissable={false}
        isKeyboardDismissDisabled={true}
        onClose={closeModal}
      >
        <ModalContent>
          {
            <>
              <ModalHeader className="flex flex-col">Subjects</ModalHeader>
              <ModalBody className="px-1 md:px-6">
                {/* <div className="flex flex-col w-full">
                  <div>
                    Select available subjects to add to {coursedata.courseName}
                  </div>
                  <div className="flex flex-col gap-3">
                    <CheckboxGroup
                      color="warning"
                      value={selected}
                      onValueChange={(value) => {
                        setSelected(value);
                        setRemovedSubjects([]);
                      }}
                      orientation="horizontal"
                      className="flex flex-row flex-wrap"
                    >
                      {Subjects &&
                        Subjects.documents &&
                        Subjects.documents.map((subj) => (
                          <Skeleton
                            isLoaded={!isLoading}
                            className="rounded-lg"
                          >
                            <Checkbox
                              key={subj.$id}
                              value={subj.$id}
                              checked={selected.includes(subj.$id)}
                              onChange={() => {
                                if (selected.includes(subj.$id)) {
                                  handleDeselect(subj.$id);
                                } else {
                                  handleSelect(subj.$id);
                                }
                              }}
                            >
                              {subj.subjectName}
                            </Checkbox>
                          </Skeleton>
                        ))}
                    </CheckboxGroup>
                  </div>

                  <div className="flex justify-center m-1">
                    <Skeleton
                      isLoaded={!isLoading}
                      className="rounded-xl bg-warning"
                    >
                      <div className="flex flex-col gap-2">
                        <ul className="flex gap-2 items-center p-1">
                          {range.map((page) => {
                            if (page === PaginationItemType.NEXT) {
                              return (
                                <li
                                  key={page}
                                  aria-label="next page"
                                  className="w-4 h-4"
                                >
                                  <button
                                    className="w-full h-full bg-default-200 rounded-full"
                                    onClick={onNext}
                                  >
                                    <svg
                                      aria-hidden="true"
                                      fill="none"
                                      focusable="false"
                                      height="1em"
                                      role="presentation"
                                      viewBox="0 0 24 24"
                                      width="1em"
                                      className="rotate-180"
                                    >
                                      <path
                                        d="M15.5 19l-7-7 7-7"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1.5"
                                      />
                                    </svg>
                                  </button>
                                </li>
                              );
                            }

                            if (page === PaginationItemType.PREV) {
                              return (
                                <li
                                  key={page}
                                  aria-label="previous page"
                                  className="w-4 h-4"
                                >
                                  <button
                                    className="w-full h-full bg-default-200 rounded-full"
                                    onClick={onPrevious}
                                  >
                                    <svg
                                      aria-hidden="true"
                                      fill="none"
                                      focusable="false"
                                      height="1em"
                                      role="presentation"
                                      viewBox="0 0 24 24"
                                      width="1em"
                                    >
                                      <path
                                        d="M15.5 19l-7-7 7-7"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1.5"
                                      />
                                    </svg>
                                  </button>
                                </li>
                              );
                            }

                            if (page === PaginationItemType.DOTS) {
                              return (
                                <li key={page} className="w-4 h-4">
                                  ...
                                </li>
                              );
                            }

                            return (
                              <li
                                key={page}
                                aria-label={`page ${page}`}
                                className="w-4 h-4"
                              >
                                <button
                                  className={cn(
                                    "w-full h-full bg-default-300 rounded-full",
                                    activePage === page && "bg-warning"
                                  )}
                                  onClick={() => setPage(page)}
                                />
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </Skeleton>
                  </div>
                </div> */}
                <EditSubjects
                  currentCourse={coursedata}
                  subjects={selected}
                  currentSem={sem}
                  setParent={SetSubjectdata}
                />
              </ModalBody>
              <ModalFooter>
                {isLoading || issaveLoading ? (
                  <Button
                    isLoading
                    color="secondary"
                    spinner={
                      <svg
                        className="animate-spin h-5 w-5 text-current"
                        fill="none"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          fill="currentColor"
                        />
                      </svg>
                    }
                  >
                    Loading
                  </Button>
                ) : (
                  <>
                    <Button color="danger" variant="light" onPress={closeModal}>
                      Close
                    </Button>
                    <Button color="secondary" onPress={handleAddSubj}>
                      Save
                    </Button>
                  </>
                )}
              </ModalFooter>
            </>
          }
        </ModalContent>
      </Modal>
    </>
  );
}
