import { useEffect, useState } from "react";
import { Button, CircularProgress } from "@nextui-org/react";
import { LoginHeader } from "./components/LoginHeader";
import Whole from "./containers/Whole";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/AppwriteConfig/AuthContext";
import axios from "axios";
import Card from "./containers/CardsContainer";
import Cards from "./containers/CardsContainer";
import Picture from "./containers/Picture";
import useRealtimeSubscription from "../../utils/AppwriteConfig/RealtimeServices";
import Admission from "./components/Admission";
import Login from "./actions/Login";
import Purpose from "./components/Purpose";
import Footer from "./components/footer";

export default function Landing({ isenabled = true, click }) {
  const navigate = useNavigate();
  const location = useLocation();

  const { server, user, loginerr } = useAuth();
  const [isLoading, setisLoading] = useState(true);
  const [MainError, setMainError] = useState();
  const [isRefresh, setisRefresh] = useState(true);

  const [Containers, setContainers] = useState([]); //Get containers to show in landing page
  const [isShow, setisShow] = useState(false); //This Gets the Landing page status

  //Dont know yet FIX
  useEffect(() => {
    if (isenabled) {
      if (
        user &&
        isenabled &&
        (user?.labels.includes("Adminhead") ||
          user?.labels.includes("Registrar") ||
          user?.labels.includes("Scheduler"))
      ) {
        navigate("employee");
      } else {
        navigate(user && user?.labels[0]);
      }
    }
  }, [user, isenabled]);

  //GET CONTAINERS
  useEffect(() => {
    async function GetContainers() {
      try {
        setisLoading(true);
        const Landing = await axios.get(`${server}/v1/landingstatus`);
        const getContainers = await axios.get(
          `${server}/v1/home/live/containers`
        );

        setisShow(Landing.data.documents[0].isActive);
        setContainers(getContainers.data.documents);
      } catch (e) {
        setMainError(e.message);
      } finally {
        setisLoading(false);
      }
    }
    GetContainers();
  }, [isRefresh]);

  // needs imporvment FIX
  const handleRealtimechanges = (changes) => {
    const changedata = Containers.findIndex(
      (container) => container.$id === changes.payload.$id
    );
    if (changes.changetype === "update" && isenabled === false) {
      setisRefresh(!isRefresh);
    }
    if (
      (changes.changetype === "create" || changes.changetype === "delete") &&
      changes.channels &&
      changes.channels.includes(
        "databases.65dfbcb25e60c6433044.collections.661a715eecb168cc611d.documents"
      ) &&
      isenabled === false
    ) {
      setisRefresh(!isRefresh);
    }
    if (changes.changetype === "update") {
      setContainers((prevContainers) => {
        const updatedContainers = [...prevContainers];
        updatedContainers[changedata] = changes.payload;
        return updatedContainers;
      }); // needs imporvment
    } else if (changes.changetype === "delete") {
      setContainers((prevContainers) =>
        prevContainers.filter(
          (container) => container.$id !== changes.payload.$id
        )
      );
    }
  };

  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_CONTAINER,
    handleRealtimechanges
  );

  const handleRealtimechanges2 = (changes) => {
    if (changes.changetype === "update") {
      setisRefresh(!isRefresh);
    }

    if (changes.changetype === "create") {
      setisRefresh(!isRefresh);
    }

    if (changes.changetype === "delete") {
      setisRefresh(!isRefresh);
    }
  };

  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_CONTAINER_ITEM,
    handleRealtimechanges2
  );

  return isLoading ? (
    <div className=" h-full w-full bg-gradient-to-r from-[#9065c9] via-[#925ba0] to-[#7d458b] ">
      <div className="flex flex-1 h-full justify-center items-center">
        <div className="flex flex-col my-6">
          <span className="text-[#fcd84b] text-lg font-bold w-full text-center">
            BSA
          </span>
          <div className=" h-44 w-44 bg-red-200 rounded-full">
            <img className=" rounded-full" src={require("../../Img/BSA.jpg")} />
          </div>

          <span className=" text-[#6e27c5] text-lg font-bold w-full text-center">
            Edusync
          </span>
          <span className="w-full flex justify-center m-1">
            <CircularProgress color="secondary" aria-label="Loading..." />
          </span>
        </div>
      </div>
    </div>
  ) : (
    <div className="h-full w-full flex bg-[#f7f7f7] flex-col">
      {/* <header className="flex flex-initial z-50 flex-wrap sm:justify-start sm:flex-nowrap w-full text-white bg-[#A16AE8] text-sm py-4 dark:bg-gray-800">
        <nav className="max-w-[85rem] w-full mx-auto px-4 flex flex-wrap basis-full items-center ">
          <a
            className="sm:order-1 flex-1 flex items-center gap-2 text-xl font-semibold dark:text-white"
            href="#"
          >
            <span className=" w-10 h-10 rounded-full">
              <img
                className=" bg-cover rounded-full"
                src={require("../../Img/BSA.jpg")}
                alt="BSA logo"
              />
            </span>
            BSA Edusync
          </a>
          <div>
            <button
              className="text-sm z-50 font-semibold rounded-lg border border-transparent bg-[#FFDE59] text-black hover:bg-[#fcd84b] disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
              onClick={() => {
       
                document.querySelector("#loginbtn").click();
              }}
            >
              gg
            </button>
          </div>
        </nav>
      </header> */}
      <LoginHeader enabled={isenabled} />
      {/* USED FOR TESTING TO SEE EMAIL  */}
      {/* <Button
        onPress={() => {
          navigate("/trysddss");
        }}
      >
        SHOW
      </Button> */}
      <Login />
      <div className="flex-1 w-full overflow-y-auto">
        {isShow && Containers && Containers.length > 0 ? (
          Containers.map((ccontainer) => (
            <div key={ccontainer.$id}>
              {ccontainer.type === "Whole" && <Whole container={ccontainer} />}
              {ccontainer.type === "Card" && <Cards container={ccontainer} />}
              {ccontainer.type === "Picture" && (
                <Picture container={ccontainer} />
              )}
              {ccontainer.type === "Text" && <Whole />}
            </div>
          ))
        ) : (
          <>
            <Whole />
            {/* Life at BSA section */}
            <div className="pt-6 p-0 mt-2 flex flex-col w-full  h-auto">
              <Card />
            </div>
            {/* Activities at BSA section */}
            <div className="pt-6 p-0 mt-2 flex flex-col w-full text-xl font-bold h-auto">
              <span className="p-6">
                Activities At Bulacan Standard Academy
              </span>
              <Picture />
            </div>
          </>
        )}
        <Purpose />
        <Admission />
        <Footer />
      </div>
    </div>
  );
}
