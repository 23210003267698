import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Spinner
} from "@nextui-org/react";

import axios from "axios";
import { useEffect, useState } from "react";
import Error from "../../../../../Components/ErrorIndicator";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
export default function RemoveSchedule({ groupId, department, reset }) {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();

  const { SchedulingServer } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [MainError, setMainError] = useState("");

  const [confirmationText, setConfirmationText] = useState("");
  const [error, setError] = useState("");

  const handleChange = (e) => {
    setConfirmationText(e.target.value);
    if (e.target.value !== "I confirm to delete this schedule") {
      setError("Please enter the exact confirmation text to proceed.");
    } else {
      setError("");
    }
  };
  async function deleteSchedules() {
    try {
      setIsLoading(true);
      const isdeleted = await axios.delete(
        ` ${SchedulingServer}/schedule/department/delete`,
        { params: { department: department.code, groupId: groupId } }
      );
      console.log(isdeleted.data);
      onClose();
      reset([]);
    } catch (error) {
      setMainError(error.message);
      setTimeout(() => {
        onClose();
      }, 5000);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      {MainError && <Error errorMessage={MainError} reset={setMainError} />}
      <Button onPress={onOpen} color="danger" isIconOnly>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-trash-fill"
          viewBox="0 0 16 16"
        >
          <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0" />
        </svg>
      </Button>
      <Modal
        className=" font-poppins"
        isOpen={isOpen}
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex pb-1 flex-col gap-1 font-bold cursor-default text-xl ">
                Delete {department.name} Schedule
              </ModalHeader>
              <ModalBody>
                <div className=" text-justify cursor-default mt-2">
                  {" "}
                  <p>
                    This action will delete the schedule, permanently removing
                    it from active view.
                    <br />
                    <div className="p-3 bg-danger-100 rounded-xl mt-5 text-[12px] text-danger-600 cursor-default">
                      {" "}
                      Warning: Deleting this schedule is irreversible.
                    </div>
                  </p>{" "}
                  <br />
                  <p>
                    To confirm deletion, type the following: I confirm to delete
                    this schedule
                  </p>
                </div>
                <div className=" w-full">
                  <div className="w-full flex gap-2  items-center">
                    <input
                      type="text"
                      id="confirmationText"
                      value={confirmationText}
                      placeholder="I confirm to delete
                    this schedule"
                      onChange={handleChange}
                      className={`w-full p-2  rounded-xl flex-1 bg-[#f4f4f5] text-[15px] py-3 ${
                        error ? "border-red-500 border" : "border-none"
                      }`}
                    />
                  </div>
                  {error && (
                    <p className="text-red-500 text-sm mt-1">{error}</p>
                  )}
                  {/* <span className=" flex-initial text-sm max-w-[85px] ">
                              Academic year:
                            </span>
                            <span className="flex-1 bg-[#f4f4f5] rounded-xl flex justify-center items-center">
                              {GetCurrentAcademicYear()}
                            </span> */}
                </div>
              </ModalBody>
              <ModalFooter className=" pt-0">
                <Button
                  isDisabled={isLoading}
                  color="secondary"
                  variant="light"
                  onPress={onClose}
                >
                  Close
                </Button>
                <Button
                  isDisabled={
                    isLoading ||
                    error ===
                      "Please enter the exact confirmation text to proceed." ||
                    confirmationText === ""
                  }
                  color="danger"
                  onPress={deleteSchedules}
                >
                  {isLoading ? (
                    <div className=" flex justify-center gap-2 items-center">
                      <Spinner size="sm" className="" />
                      <div>Processing...</div>
                    </div>
                  ) : (
                    "Delete"
                  )}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
