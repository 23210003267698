import { useState, useEffect } from "react";

export default function Error({
  errorMessage,
  reset,
  title = "Something went wrong..."
}) {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      if (reset) {
        reset();
      }
    }, 5000);

    return () => clearTimeout(timer);
  }, [errorMessage]);

  return isVisible ? (
    <div
      id="cookies-with-stacked-buttons"
      className="fixed bottom-0 end-0  z-[100] sm:max-w-sm w-full mx-auto p-6"
    >
      <div
        className={`p-4 bg-red-400 backdrop-blur-lg bg- ease-in-out rounded-xl shadow-2xl dark:bg-slate-900/[.6] dark:shadow-black/[.7] ${
          isVisible
            ? "opacity-100 transition-opacity duration-1000"
            : "opacity-0"
        }`}
      >
        <div
          className={`${
            title === "" && "hidden"
          } flex justify-between items-center gap-x-5 sm:gap-x-10`}
        >
          <h2 className="font-semibold text-gray-800 dark:text-white">
            {title}
          </h2>
          <button
            type="button"
            className="inline-flex rounded-full p-2 text-gray-500 hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-gray-600 dark:hover:bg-gray-800 dark:text-gray-300"
            data-hs-remove-element="#cookies-with-stacked-buttons"
          >
            <span className="sr-only">Dismiss</span>
            <svg
              className="flex-shrink-0 size-4"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M18 6 6 18"></path>
              <path d="m6 6 12 12"></path>
            </svg>
          </button>
        </div>
        <p className="mt-2 text-sm text-gray-800 dark:text-gray-200 whitespace-break-spaces  flex flex-wrap">
          {errorMessage}
        </p>
      </div>
    </div>
  ) : null;
}
