import { useEffect, useState } from "react";
import { Switch, cn, Button } from "@nextui-org/react";
import axios from "axios";
import Cancelation from "../../../../../Components/modals/Cancel";
import ContainerDelete from "./DeleteConfirmation";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";

export default function ConfigurationForm({
  data,
  type = "edit",
  pushdata,
  isform = false,
  containerLength
}) {
  const { server, user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [mainError, setMainError] = useState("");
  const [errors, setErrors] = useState({});
  const [changes, setChanges] = useState({
    creator: user.$id,
    creatorname: user.name,
    isActive: false,
    name: "",
    order: containerLength + 1,
    showTitle: false,
    title: "",
    type: ""
  });

  const [edit, setEdit] = useState(type === "new" ? true : false);

  useEffect(() => {
   
    setChanges({ ...changes, ...data });
  }, [data]);

  const validate = () => {
    const newErrors = {};
    if (!changes.name) newErrors.name = "Name is required.";
    if (!changes.title) newErrors.title = "Title is required.";
    if (!changes.type) newErrors.type = "Type is required.";
    if (!changes.order) newErrors.order = "Order is required.";

    setErrors(newErrors);
    
    return Object.keys(newErrors).length === 0;
  };

  const save = async () => {
    if (!validate()) return;

    
    if (JSON.stringify(changes) !== JSON.stringify(data)) {
      try {
        setIsLoading(true);
        await axios.post(`${server}/v1/home/container/${type}`, changes);
        
        setEdit(false);
      } catch (e) {
        console.error("Save error:", e.message);
        setMainError(e.message);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleDelete = async () => {
    if (data.$id) {
     
      try {
        setIsLoading(true);
        await axios.delete(`${server}/v1/home/container/delete`, {
          params: { id: data.$id }
        });
       
        setEdit(false);
      } catch (e) {
        console.error("Delete error:", e.message);
        setMainError(e.message);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission
   
    save(); // Only call save if validation passes
  };

  useEffect(() => {

    if (type === "new") {
      pushdata(changes);
    }
  }, [changes]);

  return (
    <div className="px-4 space-y-2 my-2">
      <Button
        onClick={() => setEdit(!edit)}
        className={`p-2 rounded-md flex justify-center items-center space-x-2 hover:bg-slate-200 ${
          edit ? "hidden" : ""
        }`}
      >
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-pencil-square"
            viewBox="0 0 16 16"
          >
            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
            <path
              fillRule="evenodd"
              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
            />
          </svg>
        </span>{" "}
        <span className="pl-1 text-md">Edit</span>
      </Button>
      <div
        className={`${
          edit && type !== "new" ? "" : "hidden"
        } flex justify-between`}
      >
        <ContainerDelete
          title={"Container"}
          route={"container"}
          id={data?.$id}
        />
        <div className={`flex items-center space-x-1`}>
          <Cancelation navigate={() => setEdit(false)} />
          <Button
            className="bg-[#FFDE59]"
            onClick={handleSubmit}
            isDisabled={JSON.stringify(changes) === JSON.stringify(data)}
          >
            Save
          </Button>
        </div>
      </div>

      <div className="m-1">
        {/* Name */}
        <div>
          <label
            htmlFor="Name"
            className="block mb-2 text-md text-gray-700 font-medium dark:text-white"
          >
            Name
          </label>
          {edit ? (
            <input
              type="text"
              name="Name"
              id="Name"
              className="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
              required
              value={changes.name}
              onChange={(e) => {
                
                setChanges({
                  ...changes,
                  name: e.target.value
                });
              }}
            />
          ) : (
            <span className="py-3 px-4 bg-slate-100 block capitalize w-full h-[45px] border-gray-200 rounded-lg">
              {data?.name}
            </span>
          )}
          {errors.name && <p className="text-red-500 text-xs">{errors.name}</p>}
        </div>
      </div>

      <div className="m-1">
        {/* Title */}
        <div>
          <label
            htmlFor="Title"
            className="block mb-2 text-md text-gray-700 font-medium dark:text-white"
          >
            Title
          </label>
          {edit ? (
            <input
              type="text"
              name="Title"
              id="Title"
              className="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
              required
              value={changes.title}
              onChange={(e) => {
                
                setChanges({
                  ...changes,
                  title: e.target.value
                });
              }}
            />
          ) : (
            <span className="py-3 px-4 bg-slate-100 block capitalize w-full h-[45px] border-gray-200 rounded-lg">
              {data?.title}
            </span>
          )}
          {errors.title && (
            <p className="text-red-500 text-xs">{errors.title}</p>
          )}
        </div>
      </div>

      <div className="flex-1 space-x-1 flex sm:flex-row flex-col">
        {/* Type */}
        <div className="flex-1">
          <label
            htmlFor="Type"
            className="block mb-2 text-md text-gray-700 font-medium dark:text-white"
          >
            Type
          </label>
          {edit && isform ? (
            <select
              className="py-2 px-4 bg-slate-100 pe-9 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
              value={changes.type}
              onChange={(e) => {
             
                setChanges({ ...changes, type: e.target.value });
              }}
            >
              <option value="" disabled hidden>
                Select Container Type
              </option>
              <option value="Whole">Wide Whole Image container</option>
              <option value="Card">3 Swipe Card Container</option>
              <option value="Picture">2 Swipe Image Card Container</option>
            </select>
          ) : (
            <span className="py-3 px-4 bg-slate-100 block capitalize w-full h-[45px] border-gray-200 rounded-lg">
              {data?.type}
            </span>
          )}
          {errors.type && <p className="text-red-500 text-xs">{errors.type}</p>}
        </div>

        {/* Order */}
        <div className="flex-1">
          <label
            htmlFor="Order"
            className="block mb-2 text-md text-gray-700 font-medium dark:text-white"
          >
            Order
          </label>
          <span
            className={`${
              !edit ? "py-3 " : "py-2 "
            } px-4 bg-slate-100 block capitalize w-full h-[40px] ${
              !edit ? "h-[45px] " : "h-[40px] "
            } border-gray-200 rounded-lg`}
          >
            {edit ? changes?.order : data?.order}
          </span>
          {/* {edit ? (
            <input
              type="number"
              name="Order"
              id="Order"
              className="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
              required
              value={changes.order}
              onChange={(e) => {
                console.log("Order input changed:", e.target.value);
                setChanges({
                  ...changes,
                  order: e.target.value
                });
              }}
            />
          ) : (
            <span className="py-3 px-4 bg-slate-100 block capitalize w-full h-[45px] border-gray-200 rounded-lg">
              {data?.order}
            </span>
          )} */}
          {errors.order && (
            <p className="text-red-500 text-xs">{errors.order}</p>
          )}
        </div>
      </div>

      {/* Activate Container */}
      <div className="flex-1">
        <Switch
          isDisabled={!edit}
          isSelected={changes.isActive}
          onValueChange={(e) => {
            console.log(e);
            setChanges({ ...changes, isActive: e });
          }}
          classNames={{
            base: cn(
              "inline-flex flex-1 flex-row-reverse w-full max-w-full bg-content1 hover:bg-content2 items-center",
              "justify-between cursor-pointer rounded-lg gap-2 p-4 border-2 border-transparent"
            ),
            wrapper: "p-0 h-4 overflow-visible",
            thumb: cn(
              "w-6 h-6 border-2 shadow-lg",
              "group-data-[hover=true]:border-primary",
              "group-data-[selected=true]:ml-6",
              "group-data-[pressed=true]:w-7",
              "group-data-[selected]:group-data-[pressed]:ml-4"
            )
          }}
        >
          <div className="flex flex-col gap-1">
            <p className="text-medium">Activate Container</p>
            <p className="text-tiny text-default-400">
              This will be shown at the Home Page
            </p>
          </div>
        </Switch>
      </div>

      {/* Show Title */}
      <div className="flex-1">
        <Switch
          isDisabled={!edit}
          isSelected={changes.showTitle}
          onValueChange={(e) => {
            console.log(e);
            setChanges({ ...changes, showTitle: e });
          }}
          classNames={{
            base: cn(
              "inline-flex flex-1 flex-row-reverse w-full max-w-full bg-content1 hover:bg-content2 items-center",
              "justify-between cursor-pointer rounded-lg gap-2 p-4 border-2 border-transparent"
            ),
            wrapper: "p-0 h-4 overflow-visible",
            thumb: cn(
              "w-6 h-6 border-2 shadow-lg",
              "group-data-[hover=true]:border-primary",
              "group-data-[selected=true]:ml-6",
              "group-data-[pressed=true]:w-7",
              "group-data-[selected]:group-data-[pressed]:ml-4"
            )
          }}
        >
          <div className="flex flex-col gap-1">
            <p className="text-medium">Show Title</p>
            <p className="text-tiny text-default-400">
              This will show the Title of the data
            </p>
          </div>
        </Switch>
      </div>
    </div>
  );
}
