import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import LoginLoading from "./Statuspages/LoginLoading";
import PagenotFound from "./Statuspages/PagenotFound";
import { useAuth } from "../utils/AppwriteConfig/AuthContext";
import Employee from "./{Employee}/Employee";
import Student from "./{Normal_User}/{Student}/Student";
import Teacher from "./{Normal_User}/{Teacher}/Teacher";
import useRealtimeSubscription from "../utils/AppwriteConfig/RealtimeServices";
import axios from "axios";
import { databases } from "../utils/AppwriteConfig/Config";

export default function Loggedin() {
  /**
   *
   * USED FOR REDERICTING BASED ON LOGGED IN USER ROLE
   *
   */
  const navigate = useNavigate();
  const location = useLocation();
  const { user, logoutUser, setloginerr, setUser, role } = useAuth();
  useEffect(() => {
    // Save the last location pathname to localStorage before navigating
    if (location.pathname !== "/user") {
      localStorage.setItem("lastLocation", location.pathname);
    }
  }, [location.pathname]);

  const handleRealtimeMessage = (message) => {

    if (message.changetype === "update" && user.$id === message.payload.$id) {
      if (message.payload.deleteFlag && message.payload.deleteFlag === true) {
        navigate("/archived");
      }
    } else if (
      message.changetype === "delete" &&
      user.$id === message.payload.$id
    ) {
    }
  };

  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_USERCRED,
    handleRealtimeMessage
  );

  useEffect(() => {
    // Save the last location pathname to localStorage before navigating
    if (location.pathname !== "/user") {
      localStorage.setItem("lastLocation", location.pathname);
    }
  }, [location.pathname]);

  const SessionChecker = (message) => {
 

    if (
      message &&
      message.payload &&
      message.payload.userId &&
      message.payload.userId === user.$id
    ) {
     
      setloginerr({
        show: true,
        err: {
          message:
            "Your account has been logged in elsewhere. You have been logged out."
        }
      });
      setUser(null);
      localStorage.removeItem("lastLocation");
      navigate("/");
    }
  };
 

  useRealtimeSubscription(user.$id, "session", SessionChecker);

  useEffect(() => {
    async function ValidateAcc() {
      try {
        const CheckerResponse = await databases.getDocument(
          process.env.REACT_APP_MAIN_DB,
          process.env.REACT_APP_USERCRED,
          user.$id
        );

        const isAccouontValid =
          CheckerResponse.deleteFlag === false ? true : false;
        if (isAccouontValid) {
          const lastLocation = localStorage.getItem("lastLocation");
          if (lastLocation && user && lastLocation !== "/user") {
            navigate(lastLocation);
          } else {
       
            // Handle cases where there is no last location or user not logged in
            if (
              user &&
              (role.includes("Adminhead") ||
                role.includes("Registrar") ||
                role.includes("Scheduler"))
            ) {
              navigate("employee/dashboard");
            } else if (user && role.includes("teacher")) {
              navigate("teacher/dashboard");
            } else if (user && role.includes("student")) {
              navigate("student/dashboard");
            } else {
            }
          }
        } else {
          if (CheckerResponse.deleteFlag === true) {
            navigate("/archived");
          } else {
            navigate("/Achuchu");
          }
        }
      } catch (error) {
        console.log(error);
      }
    }

    ValidateAcc();
  }, [user, role]);

  return user ? (
    <div className="font-poppins h-screen w-screen overflow-hidden bg-[#ededed]">
      <Routes>
        <Route path="/" element={<LoginLoading />} />
        <Route path="/employee/*" element={<Employee role={role} />} />
        <Route path="/student/*" element={<Student />} />
        <Route path="/teacher/*" element={<Teacher />} />
        <Route element={<PagenotFound />} />
      </Routes>
    </div>
  ) : (
    <div>Login first</div>
  );
}
