import { useEffect, useState } from "react";
import { useAuth } from "../../../../utils/AppwriteConfig/AuthContext";
import Error from "../../../../Components/ErrorIndicator";
import axios from "axios";
import { Spinner, Snippet } from "@nextui-org/react";

export default function UserDataRender({}) {
  const { server, user } = useAuth();

  const [isLoading, setisLoading] = useState(false);
  const [Refresh, setRefresh] = useState(false);
  const [MainError, setMainError] = useState();

  const [AccountData, setAccountData] = useState({});

  let currentrole;

  if (
    user.labels.includes("Adminhead") ||
    user.labels.includes("Registrar") ||
    user.labels.includes("Scheduler")
  ) {
    currentrole = "employee";
  } else if (user.labels.includes("teacher")) {
    currentrole = "teacher";
  } else if (user.labels.includes("student")) {
    currentrole = "student";
  } else {
  }

  useEffect(() => {
    async function GetData() {
      try {
        setisLoading(true);

        const Accountdata = await axios.get(`${server}/v1/${currentrole}`, {
          params: { id: user.$id },
        });
    
        setAccountData(Accountdata.data);
      } catch (e) {
        setMainError(e.message);
      } finally {
        setisLoading(false);
      }
    }
    GetData();
  }, [currentrole]);
  return (
    <div className="pl-2">
      {MainError && <Error errorMessage={MainError} />}
      {isLoading ? (
        <div className="w-1/2 animate pulse h-32 bg-slate-200 flex justify-center items-center rounded-lg">
          <Spinner></Spinner>
        </div>
      ) : (
        <>
          {AccountData && (
            <>
              <div className=" flex items-center">
                <Snippet
                  hideSymbol
                  className="border-0 "
                  variant="bordered"
                  tooltipProps={{
                    color: "foreground",
                    content: "Copy Account ID",
                    disableAnimation: true,
                    placement: "right",
                    closeDelay: 0,
                  }}
                  codeString={user?.$id}
                >
                  <div className="  text-black font-poppins text-2xl space-x-2 font-semibold">
                    {AccountData.firstName + " "}
                    {AccountData.middleName
                      ? AccountData.middleName[0] + ". "
                      : ""}

                    {AccountData.lastName}
                  </div>
                </Snippet>
              </div>
              <div className="flex space-x-2 p-2 text-md font-normal ">
                <div className="flex flex-col px-2">
                  <div>@{AccountData.userCredentials?.Username}</div>
                  <div>{AccountData.userCredentials?.Email}</div>
                </div>
                <div className="flex flex-col">
                  <div>
                    ID: {AccountData?.studentId}
                    {AccountData?.teacheId}
                    {AccountData?.employeeId}
                  </div>
                  {AccountData.lrnId && <div>LRN: {AccountData?.lrnId}</div>}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}
