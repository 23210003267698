import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
} from '@nextui-org/react';
import { useAuth } from '../../../../../utils/AppwriteConfig/AuthContext';
import { Spinner } from '@nextui-org/react';
import axios from 'axios';
import Error from '../../../../../Components/ErrorIndicator';

export default function ChangeEmailModal({ UserDetails, edituserdetails }) {
  const { user } = useAuth();
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();

  const { DataServer } = useAuth();
  const [isLoading, setisLoading] = useState(false);
  const [EmailLoading, setEmailLoading] = useState(false);
  const [isUserTyping, setisUserTyping] = useState(false);
  const [MainError, setMainError] = useState();

  const [ValidationErrors, setValidationErrors] = useState();
  const [emailduplicate, setemailduplicate] = useState(0);

  const [Email, setEmail] = useState({
    currentEmail: '',
    newEmail: '',
  });

  useEffect(() => {
    console.log('email change', Email);
  }, [Email]);
  //This Will Assign the User email to the current email
  useEffect(() => {
    if (UserDetails?.userCredentials?.Email) {
      setEmail((prevEmail) => ({ ...prevEmail, currentEmail: '' }));
    }
  }, [UserDetails]);

  //Validations
  const validate = async () => {
    // Validate Email
    let errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!Email.newEmail.trim()) {
      errors.Email = 'Email is required';
    } else if (!emailRegex.test(Email.newEmail)) {
      errors.Email = 'Invalid email format';
    } else if (emailduplicate > 0) {
      errors.Email = 'Email is Already used';
    }

    setValidationErrors(errors);
    console.log('gg', errors);
  };

  //validate every time email and userdetails changes
  useEffect(() => {
    validate();
  }, [UserDetails, Email, emailduplicate]);

  //Email Duplicate checker
  useEffect(() => {
    setEmailLoading(true);
    setisUserTyping(true);
    const timerId = setTimeout(() => {
      const fetchData = async () => {
        try {
          setEmailLoading(true);
          const response = await axios.get(`${DataServer}/user/verify/email`, {
            params: {
              email: Email?.newEmail,
            },
          });
          console.log(response.data);
          setemailduplicate(response.data.total);
        } catch (error) {
          setMainError(error.message);
        } finally {
          setEmailLoading(false);
          setisUserTyping(false);
        }
      };

      fetchData();
    }, 500);
    setEmailLoading(false);
    return () => clearTimeout(timerId); // Clear the timer when the component unmounts or when the effect is re-run
  }, [Email.newEmail]);

  const UpdateEmail = async () => {
    if (UserDetails) {
      try {
        setisLoading(true);
        const data = {
          creator: user.name,
          creatorid: user.$id,
          userData: UserDetails,
          newEmail: Email.newEmail,
        };
        const User = await axios.post(`${DataServer}/user/change/email`, data);
      } catch (e) {
        setMainError(e.message);
      } finally {
        edituserdetails({
          ...UserDetails,
          userCredentials: {
            ...UserDetails.userCredentials,
            Email: Email.newEmail,
          },
        });

        setisLoading(false);
        onClose();
        setEmail({
          currentEmail: '',
          newEmail: '',
        });
      }
    }
  };

  return (
    <>
      {MainError && <Error errorMessage={MainError} reset={setMainError} />}
      <Button
        onPress={onOpen}
        className="bg-[#FFDE59] flex-initial p-2 h-[45px] text-black text-sm font-semibold hover:bg-[#fcd84b] capitalize block  text-center border-gray-200 rounded-lg"
      >
        Change
      </Button>
      <Modal
        isOpen={isOpen}
        className=" font-poppins"
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 pb-0">
                Change Email
              </ModalHeader>
              <ModalBody>
                <div className="flex flex-col">
                  <span>Current Email</span>
                  <span className="p-2 bg-slate-200 rounded-md font-meduim">
                    {UserDetails?.userCredentials?.Email}
                  </span>
                </div>
                <div>
                  <label
                    htmlFor="Email"
                    className="flex space-x-2 text-md text-gray-700  dark:text-white"
                  >
                    New Email{' '}
                    {ValidationErrors.Email && (
                      <span className="text-red-500 text-xs mt-1 ml-1">
                        {emailduplicate > 0 &&
                          !EmailLoading &&
                          ValidationErrors.Email}
                        *
                      </span>
                    )}
                    {EmailLoading && (
                      <span className="flex space-x-2">
                        <Spinner
                          size="sm"
                          className="flex ml-2"
                          color="secondary"
                        ></Spinner>
                        <span>Checking for duplicates</span>
                      </span>
                    )}
                  </label>
                  <div
                    className={` shadow-sm mt-0  flex w-full border rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
                      ValidationErrors.Email == null
                        ? 'border-gray-200'
                        : 'border-red-500'
                    }`}
                  >
                    <input
                      type="text"
                      name="Email"
                      id="Email"
                      className={`py-2 px-3 block w-full border-gray-200 rounded-l-lg rounded-bl-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600
                    
                  `}
                      onKeyPress={(event) => {
                        if (event.key === '@') {
                          event.preventDefault();
                        }
                      }}
                      required
                      onChange={(e) =>
                        setEmail({
                          ...Email,
                          newEmail:
                            e.target.value + '@bsa.online.ph'.toLowerCase(),
                        })
                      }
                    />
                    <span class="px-4 inline-flex items-center min-w-fit rounded-e-md border border-s-0 border-gray-200 bg-slate-300  text-sm dark:bg-gray-700 dark:border-gray-700 ">
                      <span class="text-sm text-gray-500 dark:text-gray-400">
                        @bsa.online.ph
                      </span>
                    </span>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                {isLoading ? (
                  <Button
                    isLoading
                    color="secondary"
                    spinner={
                      <svg
                        className="animate-spin h-5 w-5 text-current"
                        fill="none"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          fill="currentColor"
                        />
                      </svg>
                    }
                  >
                    Loading
                  </Button>
                ) : (
                  <>
                    <Button color="danger" variant="light" onPress={onClose}>
                      Close
                    </Button>
                    <Button
                      isDisabled={
                        Object.keys(ValidationErrors).length > 0 ||
                        EmailLoading ||
                        isUserTyping
                      }
                      color="secondary"
                      onPress={UpdateEmail}
                    >
                      Update
                    </Button>
                  </>
                )}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
