import { Spinner, Button } from "@nextui-org/react";
import { useEffect, useState } from "react";
import axios from "axios";
import Error from "../../../../../Components/ErrorIndicator";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import SubjectTeacher from "./SubjectTeacher";
export default function SectionInSubject({
  Course,
  Subject,
  setName,
  setselected,
  AccordionKeys,
  department
}) {
  /**
   *
   * THIS component is used to render all Section in a course
   *  per subject for Adding or removing a teacher
   *
   */
  const { user, server } = useAuth();

  const [isLoading, setisLoading] = useState(true);
  const [isRefresh, setisRefresh] = useState(true);
  const [MainError, setMainError] = useState();

  const [selectedCourse, setselectedCourse] = useState(Course);
  const [selectedSubject, setselectedSubject] = useState(Subject);

  const [Sections, setSections] = useState([]);
  const [isActive, setisActive] = useState(false);
  //getsection
  useEffect(() => {
    async function GetSections() {
      try {
        setisLoading(true);
        const sections = await axios.get(`${server}/v1/course/sections`, {
          params: { CourseId: Course }
        });

        const checker = await axios.get(
          `${server}/v1/subject/teacher/isActive/${department}`
        );
        setisActive(checker.data.isDeptActive);
        console.log(department, checker.data.isDeptActive);
        setSections(sections.data);
      } catch (e) {
        setMainError(e.message);
      } finally {
        setisLoading(false);
      }
    }
    GetSections();
  }, [Course, department]);
  return (
    <div className=" w-fullflex flex-col">
      {MainError && <Error errorMessage={MainError} />}
      {isLoading ? (
        <div className=" w-full p-2 flex justify-center">
          <Spinner color="secondary" size="md">
            Loading...
          </Spinner>
        </div>
      ) : (
        <>
          {Sections && Sections.length > 0 ? (
            Sections.map((section) => (
              <SubjectTeacher
                key={section.$id}
                Section={section}
                subject={Subject}
                name={setName}
                id={{
                  CourseId: selectedCourse,
                  SubjectId: selectedSubject.$id
                }}
                AssignedTeacher={setselected}
                accords={AccordionKeys}
                isCurrentlyActive={isActive}
              />
            ))
          ) : (
            <div className="p-2">Add Section First</div>
          )}
        </>
      )}
    </div>
  );
}
