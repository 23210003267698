import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Switch,
  cn,
  Image,
  useDisclosure
} from "@nextui-org/react";

import axios from "axios";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import Error from "../../../../../Components/ErrorIndicator";
import { id, storage } from "../../../../../utils/AppwriteConfig/Config";
import { ID } from "appwrite";

export default function Add_ContainerItem({ Container, loadingstatus }) {
  const { isOpen, onOpen, onClose, onOpenChange } = useDisclosure();
  const { server, user } = useAuth();

  const [isLoading, setisLoading] = useState(false);
  const [MainError, setMainError] = useState();

  const [validationErrors, setValidationErrors] = useState({});

  const [changes, setChanges] = useState({
    container: Container.$id,
    containername: Container.name,
    creator: user.$id,
    creatorname: user.name,
    title: "",
    content: "",
    picture: ""
  });
  const [selectedImage, setSelectedImage] = useState(null);

  //Handles the IMG input by user
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const fileType = file.type;
      if (fileType === "image/jpeg" || fileType === "image/png") {
        setSelectedImage(file);
        setChanges({ ...changes, picture: file });
      } else {
        // Handle invalid file type
        setMainError("Jpeg or PNG file only");
      }
    }
  };

  //Validation changes depending on the cointaner type
  const validateInputs = () => {
    const errors = {};

    switch (Container.type) {
      case "Whole":
        if (!changes.picture) {
          errors.picture = "Picture is required";
        }
        if (changes.container && !changes.container.trim()) {
          errors.container = "container is required";
        }
        break;
      case "Picture":
        if (!changes.picture) {
          errors.picture = "Picture is required";
        }
        if (changes.container && !changes.container.trim()) {
          errors.container = "container is required";
        }
        if (!changes.title.trim()) {
          errors.title = "Title is required";
        }
        break;
      case "Card":
        if (!changes.picture) {
          errors.picture = "Picture is required";
        }
        if (changes.container && !changes.container.trim()) {
          errors.container = "container is required";
        }
        if (!changes.title.trim()) {
          errors.title = "Title is required";
        }

        if (!changes.content.trim()) {
          errors.content = "Content is required";
        }
        break;
      default:
        break;
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  //To validate every time changes cand loadingstatus change
  useEffect(() => {
    validateInputs();
  }, [changes, loadingstatus]);

  const createItem = async () => {
    try {
      if (!validateInputs()) return;

      setisLoading(true);

      var uploadimage = await storage.createFile(
        process.env.REACT_APP_MEDIA,
        ID.unique(),
        selectedImage
      );
      console.log("umabot pa");
      const data = {
        ...changes,
        picture: uploadimage.$id
      };
      console.log(data);
      const additem = await axios.post(
        `${server}/v1/home/container/item/add`,
        data
      );
    } catch (e) {
      setMainError(e.message);
    } finally {
      setSelectedImage(null);
      setChanges({
        container: Container.$id,
        title: "",
        content: "",
        picture: ""
      });
      onClose();
      setisLoading(false);
    }
  };

  return (
    <>
      <Button
        isDisabled={loadingstatus}
        onPress={!loadingstatus && onOpen}
        className="bg-[#ffde59]"
      >
        Add Item
      </Button>
      {MainError && <Error errorMessage={MainError} reset={setMainError} />}
      <Modal
        size="4xl"
        isOpen={isOpen}
        onClose={() => {
          setSelectedImage(null);
          setChanges({
            container: Container.$id,
            title: "",
            content: "",
            picture: ""
          });
          onClose();
        }}
        className="max-h-[500px]"
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Add Item
              </ModalHeader>
              <ModalBody className=" overflow-auto flex md:flex-row flex-col">
                <div className="px-4 space-y-2 my-2 min-w-[300px]">
                  <div className="m-1">
                    {/* Title */}
                    {(Container.type === "Picture" ||
                      Container.type === "Card") && (
                      <div>
                        <label
                          htmlFor="Title"
                          className="block mb-2 text-md text-gray-700 font-medium dark:text-white"
                        >
                          Title <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          name="Title"
                          id="Title"
                          className={`py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
                            validationErrors.title ? "border-red-500" : ""
                          }`}
                          required
                          onChange={(e) =>
                            setChanges({
                              ...changes,
                              title: e.target.value
                            })
                          }
                        />
                        {validationErrors.title && (
                          <p className="text-red-500 text-xs mt-1">
                            {validationErrors.title}
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                  {Container.type === "Card" && (
                    <div className="m-1">
                      {/* Content */}
                      <div>
                        <label
                          htmlFor="Content"
                          className="block mb-2 text-md text-gray-700 font-medium dark:text-white"
                        >
                          Content <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          name="Content"
                          id="Content"
                          className={`py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
                            validationErrors.content ? "border-red-500" : ""
                          }`}
                          required
                          onChange={(e) =>
                            setChanges({
                              ...changes,
                              content: e.target.value
                            })
                          }
                        />
                        {validationErrors.content && (
                          <p className="text-red-500 text-xs mt-1">
                            {validationErrors.content}
                          </p>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="flex-1 space-x-1 flex sm:flex-row flex-col">
                    {/* Other input fields with validation */}
                    <div className="flex-1">
                      <label
                        htmlFor="Type"
                        className="block mb-2 text-md text-gray-700 font-medium dark:text-white"
                      >
                        Container
                      </label>
                      <div className="p-2 bg-slate-200 rounded-lg">
                        {Container.name}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex-1">
                  <label className="block">
                    <input
                      type="file"
                      accept=".jpg, .jpeg, .png"
                      onChange={handleFileChange}
                      className="block w-full text-sm bg-default-100 p-2 rounded-lg text-black file:me-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-secondary-400 file:text-white hover:file:bg-secondary-300 file:disabled:opacity-50 file:disabled:pointer-events-none dark:file:bg-blue-500 dark:hover:file:bg-blue-400"
                    />
                  </label>
                  {selectedImage ? (
                    <Image
                      alt="NextUI hero Image"
                      src={URL.createObjectURL(selectedImage)}
                      fallbackSrc={require("../../../../../Img/BSA.jpg")}
                    />
                  ) : (
                    <div className="relative">
                      <Image
                        alt="NextUI hero Image"
                        src={require("../../../../../Img/BSA.jpg")}
                        fallbackSrc={require("../../../../../Img/BSA.jpg")}
                      />
                      <span
                        className={`absolute top-0 z-10 bg-slate-500/60 text-white w-full h-full flex justify-center items-center`}
                      >
                        No selected Image yet
                      </span>
                    </div>
                  )}
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>

                {isLoading ? (
                  <Button isLoading disabled color="secondary">
                    Uploading
                  </Button>
                ) : (
                  <Button
                    color="secondary"
                    isDisabled={Object.keys(validationErrors).length > 0}
                    onPress={createItem}
                  >
                    Add Item
                  </Button>
                )}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
