import Announcement from "./EmployeeAnnouncement";
import Statistics from "./D_Statistics";
import { useEffect, useState } from "react";
import { useAuth } from "../../../../utils/AppwriteConfig/AuthContext";
import axios from "axios";
import {
  GetCurrentAcademicYear,
  getTodayFormatted
} from "../../../../utils/helpers/stringformat";
import { Spinner } from "@nextui-org/react";
import SectionFilter from "./renders/sectionFilter";
import StudentSchedule from "../../../{Normal_User}/WeeklySchedulePage/components/studentSchedule";

export default function Scheduler_Dash() {
  return (
    <div className="flex flex-col flex-1 w-full h-full overflow-hidden gap-1">
      <Scheduler_Stats />
      <div className="flex-1 overflow-hidden w-full flex">
        <div className="flex gap-1 flex-col md:flex-row md:h-full h-full w-full">
          <div className="flex flex-col flex-1 overflow-hidden">
            {/* Ensure this container can scroll */}
            <div className="flex flex-col gap-1 h-full">
              <Scheduler_Timetable />
            </div>
          </div>
          <Announcement />
        </div>
      </div>
    </div>
  );
}

function Scheduler_Stats() {
  const { DataServer } = useAuth();
  const [isLoading, setisLoading] = useState(false);
  const [Refresh, setRefresh] = useState(false);
  const [MainError, setMainError] = useState();

  const [info, setInfo] = useState({});

  useEffect(() => {
    async function GetSubjects() {
      try {
        setisLoading(true);
        const infoResponse = await axios.get(`${DataServer}/info/scheduler`);
      
        setInfo(infoResponse.data);
      } catch (e) {
        setMainError(e.message);
      } finally {
        setisLoading(false);
      }
    }
    GetSubjects();
  }, [Refresh]);

  return (
    <div className="bg-white hidden md:flex flex-col rounded-lg shadow-lg p-4 w-full">
      {/* Period and Date Info */}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold">School Period</h2>
      </div>
      <div className="border-t pt-2 mb-4">
        <div className="flex flex-col md:flex-row justify-between text-gray-600 mb-4">
          <span>{GetCurrentAcademicYear()}</span>
          <span>Today : {getTodayFormatted()}</span>
        </div>
      </div>

      {/* Statistics Section */}
      <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
        {/* Subjects */}
        <div className="flex flex-col items-center justify-center bg-purple-100 rounded-lg p-4 shadow-lg">
          <div className="flex items-center">
            <h2 className="text-4xl font-bold text-purple-600 mr-2">
              {isLoading ? (
                <Spinner color="secondary" size="md" />
              ) : (
                info && info.subjects && info.subjects
              )}
            </h2>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              className="bi bi-book text-purple-600"
              viewBox="0 0 16 16"
            >
              <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783" />
            </svg>
          </div>
          <p className="text-gray-600">Subjects</p>
        </div>

        {/* Classes */}
        <div className="flex flex-col items-center justify-center bg-green-100 rounded-lg p-4 shadow-lg">
          <div className="flex items-center">
            <h2 className="text-4xl font-bold text-green-600 mr-2">
              {isLoading ? (
                <Spinner color="secondary" size="md" />
              ) : (
                info && info.classes && info.classes
              )}
            </h2>
            <img
              src={require("../../../../Img/Dashboard/cap.png")}
              alt="Classes"
              className="w-6 h-6"
            />
          </div>
          <p className="text-gray-600">Classes</p>
        </div>
        {/* Students */}
        <div className="flex flex-col items-center justify-center bg-blue-100 rounded-lg p-4 shadow-lg">
          <div className="flex items-center">
            <h2 className="text-4xl font-bold text-blue-600 mr-2">
              {isLoading ? (
                <Spinner color="secondary" size="md" />
              ) : (
                info && info.students && info.students
              )}
            </h2>
            <img
              src={require("../../../../Img/Dashboard/reading.png")}
              alt="Students"
              className="w-6 h-6"
            />
          </div>
          <p className="text-gray-600">Students</p>
        </div>

        {/* Teachers */}
        <div className="flex flex-col items-center justify-center bg-red-100 rounded-lg p-4 shadow-lg">
          <div className="flex items-center">
            <h2 className="text-4xl font-bold text-red-600 mr-2">
              {isLoading ? (
                <Spinner color="secondary" size="md" />
              ) : (
                info && info.teachers && info.teachers
              )}
            </h2>
            <img
              src={require("../../../../Img/Dashboard/teacher.png")}
              alt="Teachers"
              className="w-6 h-6"
            />
          </div>
          <p className="text-gray-600">Teachers</p>
        </div>
      </div>
    </div>
  );
}
function Scheduler_Timetable() {
  const { DataServer, role, user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [MainError, setMainError] = useState("");
  const [isRefresh, setIsRefresh] = useState(true);
  const [schedule, setSchedule] = useState({});
  const [currentDay, setCurrentDay] = useState("");

  useEffect(() => {
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const today = new Date();
    const dayName = daysOfWeek[today.getDay()]; // Get the current day in "Mon", "Tue", etc.
    setCurrentDay(dayName);
  }, []);

  const [section, setSection] = useState({});

  useEffect(() => {
 
  }, [section, currentDay]);

  // Get schedule
  useEffect(() => {
    async function scheduleResponse() {
      try {
        setIsLoading(true);
        const scheduleResponse = await axios.get(
          `${DataServer}/user/${role}/${user.$id}/schedule/${currentDay}`,
          { params: { section: section } }
        );

        setSchedule(scheduleResponse.data);
      } catch (error) {
        setMainError(error.message);
      } finally {
        setIsLoading(false);
      }
    }
    if (currentDay !== "" && section) scheduleResponse();
  }, [isRefresh, currentDay, section]);

  useEffect(() => {
    
  }, [schedule]);

  return (
    <div className="bg-white rounded-lg flex flex-col shadow-lg p-4 w-full h-full ">
      <div className="flex flex-col flex-initial md:flex-row justify-between items-center mb-4">
        <h2 className="text-lg font-semibold">Today</h2>
        <SectionFilter select={setSection} />
      </div>
      <div className="border-t pt-2  flex-1 flex flex-col h-full overflow-auto">
        <p className="text-gray-600 mb-4 flex-initial">
          Active schedules of {section?.course?.courseName} |{" "}
          {section?.sectionName} 2024 - 2025
        </p>
        {isLoading ? (
          <div className="w-full relative flex flex-col h-full justify-center items-center p-2">
            <div className="absolute w-[90%] h-[80%] rounded-lg bg-secondary-200 transition-opacity animate-pulse"></div>
            <img
              className="z-10 size-40 bg-cover object-cover rounded-xl"
              src={require("../../../../Img/scheduleFetching.gif")}
            />
            <div className="z-10 font-xs text-secondary p-2 text-xl w-full flex justify-center">
              Fetching {section?.course?.courseName} | {section?.sectionName}{" "}
              Schedules
            </div>
          </div>
        ) : (
          <>
            {schedule &&
            Object.keys(schedule).length > 0 &&
            schedule.schedules &&
            Object.keys(schedule.schedules).length > 2 ? (
              <StudentSchedule
                schedule={schedule.schedules}
                dayz={currentDay}
              />
            ) : (
              <div className=" w-full h-full flex flex-col items-center justify-center">
                <div className=" w-full flex justify-center items-center p-2">
                  <img
                    className=" size-52 bg-cover object-cover rounded-lg"
                    src={require("../../../../Img/loading schedule.gif")}
                  />
                </div>
                <div className=" font-xs p-2 text-xl w-full flex justify-center">
                  {section && Object.keys(section).length > 0 ? (
                    <>
                      {schedule.courseName && schedule.sectionName
                        ? "No Schedule yet"
                        : schedule.courseName && !schedule?.sectionName
                        ? "You are not assigned to any section yet"
                        : role === "teacher"
                        ? "You dont have any subject scheduled yet"
                        : "You are not assigned to any grade level yet"}
                    </>
                  ) : (
                    "Select One section first"
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
