import { useEffect, useState } from "react";
import ScheduleCard from "../ScheduleCard";
import ShowScheduleModal from "./ShowScheduleModal";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import axios from "axios";
import useRealtimeSubscription from "../../../../../utils/AppwriteConfig/RealtimeServices";
import { Card, CardFooter, Image, Skeleton } from "@nextui-org/react";

export default function OldSchedules({ AcademicYear }) {
  const { SchedulingServer } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [MainError, setMainError] = useState("");
  const [refresher, setIsRefresher] = useState(false);
  const [OldSchedules, setOldSchedules] = useState([]);

  const [SelectedSchedule, setSelectedSchedule] = useState({});

  useEffect(() => {
    async function getSectionSchedules() {
      try {
        setIsLoading(true);
        const sectionResponse = await axios.get(
          `${SchedulingServer}/schedule/old`
        );
        console.log(sectionResponse);
        setOldSchedules(sectionResponse.data.documents);
      } catch (error) {
        setMainError(
          error?.response?.data?.error
            ? error.response.data.error
            : error.message
        );
      } finally {
        setIsLoading(false);
      }
    }
    getSectionSchedules();
  }, [refresher]);

  const handleRealtimeMessage = (message) => {
    console.log("Received Realtime message:", message);
    setIsRefresher(!refresher);
  };

  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_GROUP_SCHED,
    handleRealtimeMessage
  );
  return (
    <div className=" w-full h-full max-h-full overflow-auto">
      <div className="grid p-3 gap-3 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 mt-4 ">
        {isLoading ? (
          <>
            <Skeleton className={"rounded-lg"}>
              <Card
                // key={user?.$id}
                isPressable
                shadow="sm"
                className=" rounded-md min-h-44 max-h-52 overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
              >
                <Image
                  removeWrapper
                  alt="Card background"
                  className="z-0 w-full h-full object-cover"
                  src={require("../../../../../Img/BSA.jpg")}
                />
                <CardFooter className="absolute bg-[#e5dbf9]/90 bottom-0 h-full z-10 rounded-md">
                  <div className=" relative flex flex-col justify-end w-full h-full">
                    <div
                      className={` absolute top-2 right-2 text-secondary-600 `}
                    >
                      year
                    </div>
                    <div className=" flex flex-col justify-start text-start w-full">
                      <div className=" text-lg flex items-center  gap-1 text-[#4a306d] font-semibold mb-1">
                        <div>name</div>
                      </div>
                      <div className="text-sm text-[#7d5ca3]">Role:</div>
                    </div>
                  </div>
                </CardFooter>
              </Card>
            </Skeleton>
            <Skeleton className={"rounded-lg"}>
              <Card
                // key={user?.$id}
                isPressable
                shadow="sm"
                className=" rounded-md min-h-44 max-h-52 overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
              >
                <Image
                  removeWrapper
                  alt="Card background"
                  className="z-0 w-full h-full object-cover"
                  src={require("../../../../../Img/BSA.jpg")}
                />
                <CardFooter className="absolute bg-[#e5dbf9]/90 bottom-0 h-full z-10 rounded-md">
                  <div className=" relative flex flex-col justify-end w-full h-full">
                    <div
                      className={` absolute top-2 right-2 text-secondary-600 `}
                    >
                      year
                    </div>
                    <div className=" flex flex-col justify-start text-start w-full">
                      <div className=" text-lg flex items-center  gap-1 text-[#4a306d] font-semibold mb-1">
                        <div>name</div>
                      </div>
                      <div className="text-sm text-[#7d5ca3]">Role:</div>
                    </div>
                  </div>
                </CardFooter>
              </Card>
            </Skeleton>
            <Skeleton className={"rounded-lg"}>
              <Card
                // key={user?.$id}
                isPressable
                shadow="sm"
                className=" rounded-md min-h-44 max-h-52 overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
              >
                <Image
                  removeWrapper
                  alt="Card background"
                  className="z-0 w-full h-full object-cover"
                  src={require("../../../../../Img/BSA.jpg")}
                />
                <CardFooter className="absolute bg-[#e5dbf9]/90 bottom-0 h-full z-10 rounded-md">
                  <div className=" relative flex flex-col justify-end w-full h-full">
                    <div
                      className={` absolute top-2 right-2 text-secondary-600 `}
                    >
                      year
                    </div>
                    <div className=" flex flex-col justify-start text-start w-full">
                      <div className=" text-lg flex items-center  gap-1 text-[#4a306d] font-semibold mb-1">
                        <div>name</div>
                      </div>
                      <div className="text-sm text-[#7d5ca3]">Role:</div>
                    </div>
                  </div>
                </CardFooter>
              </Card>
            </Skeleton>
            <Skeleton className={"rounded-lg"}>
              <Card
                // key={user?.$id}
                isPressable
                shadow="sm"
                className=" rounded-md min-h-44 max-h-52 overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
              >
                <Image
                  removeWrapper
                  alt="Card background"
                  className="z-0 w-full h-full object-cover"
                  src={require("../../../../../Img/BSA.jpg")}
                />
                <CardFooter className="absolute bg-[#e5dbf9]/90 bottom-0 h-full z-10 rounded-md">
                  <div className=" relative flex flex-col justify-end w-full h-full">
                    <div
                      className={` absolute top-2 right-2 text-secondary-600 `}
                    >
                      year
                    </div>
                    <div className=" flex flex-col justify-start text-start w-full">
                      <div className=" text-lg flex items-center  gap-1 text-[#4a306d] font-semibold mb-1">
                        <div>name</div>
                      </div>
                      <div className="text-sm text-[#7d5ca3]">Role:</div>
                    </div>
                  </div>
                </CardFooter>
              </Card>
            </Skeleton>
            <Skeleton className={"rounded-lg"}>
              <Card
                // key={user?.$id}
                isPressable
                shadow="sm"
                className=" rounded-md min-h-44 max-h-52 overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
              >
                <Image
                  removeWrapper
                  alt="Card background"
                  className="z-0 w-full h-full object-cover"
                  src={require("../../../../../Img/BSA.jpg")}
                />
                <CardFooter className="absolute bg-[#e5dbf9]/90 bottom-0 h-full z-10 rounded-md">
                  <div className=" relative flex flex-col justify-end w-full h-full">
                    <div
                      className={` absolute top-2 right-2 text-secondary-600 `}
                    >
                      year
                    </div>
                    <div className=" flex flex-col justify-start text-start w-full">
                      <div className=" text-lg flex items-center  gap-1 text-[#4a306d] font-semibold mb-1">
                        <div>name</div>
                      </div>
                      <div className="text-sm text-[#7d5ca3]">Role:</div>
                    </div>
                  </div>
                </CardFooter>
              </Card>
            </Skeleton>
            <Skeleton className={"rounded-lg"}>
              <Card
                // key={user?.$id}
                isPressable
                shadow="sm"
                className=" rounded-md min-h-44 max-h-52 overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
              >
                <Image
                  removeWrapper
                  alt="Card background"
                  className="z-0 w-full h-full object-cover"
                  src={require("../../../../../Img/BSA.jpg")}
                />
                <CardFooter className="absolute bg-[#e5dbf9]/90 bottom-0 h-full z-10 rounded-md">
                  <div className=" relative flex flex-col justify-end w-full h-full">
                    <div
                      className={` absolute top-2 right-2 text-secondary-600 `}
                    >
                      year
                    </div>
                    <div className=" flex flex-col justify-start text-start w-full">
                      <div className=" text-lg flex items-center  gap-1 text-[#4a306d] font-semibold mb-1">
                        <div>name</div>
                      </div>
                      <div className="text-sm text-[#7d5ca3]">Role:</div>
                    </div>
                  </div>
                </CardFooter>
              </Card>
            </Skeleton>
          </>
        ) : (
          <>
            {OldSchedules && OldSchedules.length > 0 ? (
              OldSchedules.map((sched) => (
                <ScheduleCard
                  setselected={setSelectedSchedule}
                  data={sched}
                  type="Old"
                />
              ))
            ) : (
              <div className="">No Old Schedules yet</div>
            )}
          </>
        )}
      </div>
      <ShowScheduleModal academicYear={AcademicYear} data={SelectedSchedule} />
    </div>
  );
}
