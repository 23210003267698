import { Button, Skeleton } from "@nextui-org/react";

import { useEffect, useState } from "react";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import axios from "axios";
import useRealtimeSubscription from "../../../../../utils/AppwriteConfig/RealtimeServices";

import {
  ApprovedAndAdjustedRequestCard,
  ApprovedButPendingRequestCard,
  RequestCardModern
} from "./RequestCard";
import RequestConfirm from "./AcceptConfim";

export default function AdminToApplyRequest({
  refreshMain,
  tab,
  RefreshStatus,
  selectAdjust
}) {
  const { DataServer, user } = useAuth();

  const [isLoading, setisLoading] = useState(true);
  const [isrefresh, setisrefresh] = useState(true);
  const [MainError, setMainError] = useState();
  const [FTArequests, setFTARequests] = useState([]);

  const [TLrequests, setTLRequests] = useState([]);

  const [ConfimData, setConfimData] = useState(null);

  useEffect(() => {
    setisLoading(true);
    const fetchRequest = async () => {
      try {
        const [FTAresponse, TLresponse] = await Promise.all([
          axios.get(`${DataServer}/info/requests/toapply/fta`),
          axios.get(`${DataServer}/info/requests/toapply/tl`)
        ]);

        setFTARequests(FTAresponse.data.documents);
        setTLRequests(TLresponse.data.documents);
      } catch (error) {
        setMainError(error.message);
      } finally {
        setisLoading(false);
      }
    };

    if (tab === "toapply") {
      fetchRequest();
    }
  }, [isrefresh, DataServer, RefreshStatus]);

  const handleRealtimeMessage = (message) => {
    console.log(message, "gsg");
    setisrefresh(!isrefresh);
  };

  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_ROOM,
    handleRealtimeMessage
  );

  return (
    <div className="flex flex-col w-full h-full max-w-full   overflow-hidden">
      <div class="hs-accordion-group">
        <div
          class="hs-accordion hs-accordion-active:border-secondary-200 bg-white border border-transparent rounded-xl dark:hs-accordion-active:border-neutral-700 dark:bg-neutral-800 dark:border-transparent"
          id="hs-active-bordered-heading-one"
        >
          <button
            class="hs-accordion-toggle hs-accordion-active:text-secondary-600 inline-flex justify-between items-center gap-x-3 w-full font-semibold text-start text-secondary-800 py-4 px-5 hover:text-secondary-500 disabled:opacity-50 disabled:pointer-events-none dark:hs-accordion-active:text-secondary-500 dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
            aria-expanded="false"
            aria-controls="hs-basic-active-bordered-collapse-one"
          >
            Temporary Leave
            <svg
              class="hs-accordion-active:hidden block size-3.5"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M5 12h14"></path>
              <path d="M12 5v14"></path>
            </svg>
            <svg
              class="hs-accordion-active:block hidden size-3.5"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M5 12h14"></path>
            </svg>
          </button>
          <div
            id="hs-basic-active-bordered-collapse-one"
            class="hs-accordion-content hidden w-full overflow-hidden transition-[height]  duration-300"
            role="region"
            aria-labelledby="hs-active-bordered-heading-one"
          >
            <div class="pb-4 px-2 w-full h-[calc(100vh-255px)] sm:h-[calc(100vh-245px)]">
              <div className=" w-full h-full max-h-full flex flex-col gap-2 overflow-auto p-2  [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500">
                {isLoading ? (
                  <>
                    <Skeleton className=" rounded-lg ">
                      <RequestCardModern />
                    </Skeleton>
                    <Skeleton className=" rounded-lg ">
                      <RequestCardModern />
                    </Skeleton>
                    <Skeleton className=" rounded-lg ">
                      <RequestCardModern />
                    </Skeleton>
                  </>
                ) : TLrequests && TLrequests.length > 0 ? (
                  TLrequests.map((request) => (
                    <ApprovedButPendingRequestCard
                      data={request}
                      onCancel={setConfimData}
                      sendData={selectAdjust}
                    />
                  ))
                ) : (
                  <div className=" w-full  h-full">
                    <div className=" font-medium flex bg-secondary-50 rounded-lg h-full w-full items-center justify-center">
                      No Temporary Leave to apply yet
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          class="hs-accordion hs-accordion-active:border-secondary-200 active bg-white border border-transparent rounded-xl dark:hs-accordion-active:border-neutral-700 dark:bg-neutral-800 dark:border-transparent"
          id="hs-active-bordered-heading-two"
        >
          <button
            class="hs-accordion-toggle hs-accordion-active:text-secondary-600 inline-flex justify-between items-center gap-x-3 w-full font-semibold text-start text-secondary-800 py-4 px-5 hover:text-secondary-500 disabled:opacity-50 disabled:pointer-events-none dark:hs-accordion-active:text-secondary-500 dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
            aria-expanded="true"
            aria-controls="hs-basic-active-bordered-collapse-two"
          >
            Full Time Adjustments
            <svg
              class="hs-accordion-active:hidden block size-3.5"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M5 12h14"></path>
              <path d="M12 5v14"></path>
            </svg>
            <svg
              class="hs-accordion-active:block hidden size-3.5"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M5 12h14"></path>
            </svg>
          </button>
          <div
            id="hs-basic-active-bordered-collapse-two"
            class="hs-accordion-content w-full overflow-hidden transition-[height] duration-300"
            role="region"
            aria-labelledby="hs-active-bordered-heading-two"
          >
            <div class="pb-4 px-2 h-[calc(100vh-255px)] sm:h-[calc(100vh-245px)]">
              <div className=" w-full h-full max-h-full flex flex-col gap-2 overflow-auto p-2  [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500">
                {isLoading ? (
                  <>
                    <Skeleton className=" rounded-lg ">
                      <RequestCardModern />
                    </Skeleton>
                    <Skeleton className=" rounded-lg ">
                      <RequestCardModern />
                    </Skeleton>
                    <Skeleton className=" rounded-lg ">
                      <RequestCardModern />
                    </Skeleton>
                  </>
                ) : FTArequests && FTArequests.length > 0 ? (
                  FTArequests.map((request) => (
                    <ApprovedButPendingRequestCard
                      data={request}
                      onCancel={setConfimData}
                      sendData={selectAdjust}
                    />
                  ))
                ) : (
                  <div className=" w-full h-full">
                    <div className=" font-medium flex bg-secondary-50 rounded-lg h-full w-full items-center justify-center">
                      No Full-Time Adjustment to apply yet
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="flex-1 overflow-auto p-2 b space-y-2 [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-secondary-100 [&::-webkit-scrollbar-thumb]:bg-secondary-300 dark:[&::-webkit-scrollbar-track]:bg-slate-700 dark:[&::-webkit-scrollbar-thumb]:bg-slate-500">
        {isLoading ? (
          <>
            <Skeleton className=" rounded-lg ">
              <RequestCardModern />
            </Skeleton>
            <Skeleton className=" rounded-lg ">
              <RequestCardModern />
            </Skeleton>
            <Skeleton className=" rounded-lg ">
              <RequestCardModern />
            </Skeleton>
          </>
        ) : requests && requests.length > 0 ? (
          requests.map((request) => (
            <ApprovedButPendingRequestCard
              data={request}
              onCancel={setConfimData}
              sendData={selectAdjust}
            />
          ))
        ) : (
          <div className=" w-full h-[calc(100vh-180px)]">
            <div className=" font-medium flex bg-secondary-50 rounded-lg h-full w-full items-center justify-center">
              No Applied Request Yet
            </div>
          </div>
        )}
      </div> */}
      <RequestConfirm
        type={"decline"}
        data={ConfimData}
        unselector={setConfimData}
        refresher={refreshMain}
      />
    </div>
  );
}
