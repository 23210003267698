import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Skeleton
} from "@nextui-org/react";
import { useEffect, useState } from "react";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import axios from "axios";
import {
  GetCurrentAcademicYear,
  formatTime
} from "../../../../../utils/helpers/stringformat";
import { useParams } from "react-router-dom";

export default function Enrollments({ userid }) {
  const { user, DataServer } = useAuth();

  const { id } = useParams();

  const [isLoading, setisLoading] = useState(true);
  const [MainError, setMainError] = useState();

  const [Enrollments, setEnrollments] = useState([]);

  useEffect(() => {
    async function GetEnrollment() {
      try {
        setisLoading(true);
        const enrollments = await axios.get(
          `${DataServer}/user/student/enrollments`,
          {
            params: { accid: id, academicyear: GetCurrentAcademicYear() }
          }
        );
        setEnrollments(enrollments.data.documents);
      } catch (e) {
        setMainError(e.message);
      } finally {
        setisLoading(false);
      }
    }
    GetEnrollment();
  }, [userid]);

  return (
    <div className=" w-full bg-white rounded-md min-h-[300px] max-h-[400px] overflow-auto flex flex-col">
      <div className=" flex-initial flex items-center justify-between w-full rounded-md py-3 p-2 text-lg font-medium text-white bg-[#A16AE8]">
        <span>Enrollments</span>
        <div className="text-sm font-normal flex space-x-3">
          <span className="flex items-center space-x-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              fill="#4CAF50"
              class="bi bi-circle-fill"
              viewBox="0 0 16 16"
            >
              <circle cx="8" cy="8" r="8" />
            </svg>
            <span>Taken</span>
          </span>
          <span className="flex items-center space-x-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              fill="#FFA726"
              class="bi bi-circle-fill"
              viewBox="0 0 16 16"
            >
              <circle cx="8" cy="8" r="8" />
            </svg>
            <span>In-Progress</span>
          </span>
          <span className="flex items-center space-x-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              fill="#F44336"
              class="bi bi-circle-fill"
              viewBox="0 0 16 16"
            >
              <circle cx="8" cy="8" r="8" />
            </svg>
            <span>Incomplete</span>
          </span>
        </div>
      </div>
      <div
        className={`flex-1 max-h-full relative space-y-2 ${
          Enrollments.length > 0 ? "p-2" : ""
        }  overflow-auto `}
      >
        {isLoading ? (
          <>
            <div className=" h-auto animate-pulse p-4 rounded-lg w-full bg-slate-100 space-y-2 border-1 shadow-md flex flex-col">
              <header className=" flex justify-between border-b-1 border-[#FFDE59]">
                <div className="flex flex-col">
                  <Skeleton className=" rounded-lg">
                    <div className="text-lg">Coursename</div>
                  </Skeleton>
                  <Skeleton className=" rounded-lg mt-1">
                    <div className="text-sm">
                      <span className="">Enrolled by | </span>
                      <span>dateenrolled</span>
                    </div>
                  </Skeleton>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    fill="gray"
                    class="bi bi-circle-fill animate-pulse"
                    viewBox="0 0 16 16"
                  >
                    <circle cx="8" cy="8" r="8" />
                  </svg>
                </div>
              </header>
              <div className="flex-initial flex justify-between">
                <Skeleton className=" rounded-lg">
                  <div>Section</div>
                </Skeleton>
                <Skeleton className=" rounded-lg">
                  <div>Academic year</div>
                </Skeleton>
              </div>
            </div>
            <div className=" h-auto animate-pulse p-4 rounded-lg w-full bg-slate-100 space-y-2 border-1 shadow-md flex flex-col">
              <header className=" flex justify-between border-b-1 border-[#FFDE59]">
                <div className="flex flex-col">
                  <Skeleton className=" rounded-lg">
                    <div className="text-lg">Coursename</div>
                  </Skeleton>
                  <Skeleton className=" rounded-lg mt-1">
                    <div className="text-sm">
                      <span className="">Enrolled by | </span>
                      <span>dateenrolled</span>
                    </div>
                  </Skeleton>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    fill="gray"
                    class="bi bi-circle-fill animate-pulse"
                    viewBox="0 0 16 16"
                  >
                    <circle cx="8" cy="8" r="8" />
                  </svg>
                </div>
              </header>
              <div className="flex-initial flex justify-between">
                <Skeleton className=" rounded-lg">
                  <div>Section</div>
                </Skeleton>
                <Skeleton className=" rounded-lg">
                  <div>Academic year</div>
                </Skeleton>
              </div>
            </div>
            <div className=" h-auto animate-pulse p-4 rounded-lg w-full bg-slate-100 space-y-2 border-1 shadow-md flex flex-col">
              <header className=" flex justify-between border-b-1 border-[#FFDE59]">
                <div className="flex flex-col">
                  <Skeleton className=" rounded-lg">
                    <div className="text-lg">Coursename</div>
                  </Skeleton>
                  <Skeleton className=" rounded-lg mt-1">
                    <div className="text-sm">
                      <span className="">Enrolled by | </span>
                      <span>dateenrolled</span>
                    </div>
                  </Skeleton>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    fill="gray"
                    class="bi bi-circle-fill animate-pulse"
                    viewBox="0 0 16 16"
                  >
                    <circle cx="8" cy="8" r="8" />
                  </svg>
                </div>
              </header>
              <div className="flex-initial flex justify-between">
                <Skeleton className=" rounded-lg">
                  <div>Section</div>
                </Skeleton>
                <Skeleton className=" rounded-lg">
                  <div>Academic year</div>
                </Skeleton>
              </div>
            </div>
          </>
        ) : (
          <>
            {Enrollments && Enrollments.length > 0 ? (
              Enrollments.map((enrollment) => (
                <div className=" h-auto  p-4 rounded-lg w-full bg-white space-y-2 border-1 shadow-md flex flex-col">
                  <header className=" flex justify-between border-b-1 border-[#FFDE59]">
                    <div className="flex flex-col">
                      <div className="text-lg">
                        {enrollment.course.courseName}
                      </div>

                      <div className="text-sm">
                        <span>{formatTime(enrollment?.$createdAt)}</span>
                      </div>
                    </div>
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        fill={
                          enrollment?.status === "inProgress"
                            ? "#FFA726"
                            : enrollment?.status === "Failed"
                            ? "#F44336"
                            : "#4CAF50"
                        }
                        class="bi bi-circle-fill"
                        viewBox="0 0 16 16"
                      >
                        <circle cx="8" cy="8" r="8" />
                      </svg>
                    </div>
                  </header>
                  <div className="flex-initial flex justify-between">
                    <div>
                      {enrollment?.section?.sectionName
                        ? enrollment?.section?.sectionName
                        : "Not Assigned"}
                    </div>
                    <div>{enrollment?.academicYear}</div>
                  </div>
                </div>
              ))
            ) : (
              <div className=" absolute h-full w-full flex justify-center items-center">
                No Enrollments yet
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
