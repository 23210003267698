import { useEffect, useState } from "react";
import { storage } from "../../../../utils/AppwriteConfig/Config";

export default function ImgRender({ ImgURL }) {
  const [imgurl, setimgurl] = useState();
  useEffect(() => {
    const loadImage = async () => {
      try {
        if (ImgURL) {
          // Load image from storage using data.fileid
          const result = await storage.getFilePreview(
            "66024b7d93a9464cacb6",
            ImgURL
          );
          setimgurl(result);
        }
      } catch (error) {
        console.error("Error loading image:", error);
      }
    };

    loadImage();
  }, [ImgURL]);
  return imgurl ? (
    <img className=" object-cover bg-center w-full h-[550px]" src={imgurl} />
  ) : (
    <img src={"../../img.jpg"} />
  );
}
