import { Spinner } from "@nextui-org/react";
import { tailChase } from "ldrs";

export default function LoginLoading({ msg }) {
  tailChase.register();

  // Default values shown

  const loadingImg = "https://cdn.auth0.com/blog/hello-auth0/loader.svg";
  return (
    <div className=" font-poppins flex bg-slate-200 flex-1 h-screen w-screen justify-center items-center">
      <div className=" bg-white px-20 py-14 flex flex-col justify-center items-center rounded-md">
        <span className="text-2xl font-bold">
          Logging <a className="text-[#8c52ff]">you</a> {msg ? msg : "in"}
        </span>
        <div className=" flex justify-center items-center w-20 h-20 my-8">
          {/* <img src={loadingImg} alt="Loading..." /> */}
          <l-tail-chase size="60" speed="1.75" color="#8c52ff"></l-tail-chase>
        </div>
        <span className="text-xl">Please wait</span>
      </div>
    </div>
  );
}
