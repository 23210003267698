import AssignedSubjectTeachers from "../components/AssignedSubjects_Teachers";
import React, { useEffect, useMemo, useState } from "react";
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownSection,
  DropdownItem,
  Button,
  cn,
  Skeleton,
  Spinner,
  User,
  CircularProgress,
  Progress,
  Pagination
} from "@nextui-org/react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { functions } from "../../../../utils/AppwriteConfig/Config";
import { useAuth } from "../../../../utils/AppwriteConfig/AuthContext";
import Error from "../../../../Components/ErrorIndicator";
import ScheduleErrors from "../components/renders/ScheduleErrors";
import ScheduleOptions, { SheduleOptions } from "../components/OptionDropDown";
import {
  ByCourseView,
  ByTeachersView,
  SpecificAssignments
} from "../../../../utils/helpers/ScheduleSortings";
import AssignedSubjectCourses from "../components/AssignedSubjects_Courses";
import ReAssignTeacher from "../components/modal/Re-AssignTeacher";
import TeacherTooltip from "../components/renders/TeacherTooltip";
import AssignedSubjectsSpecific from "../components/AssignedSubjects_Specific";
import { quantum } from "ldrs";
import LoadingInfo from "../components/renders/LoadingInfo";

quantum.register();

export default function AssignTeachers({
  confirmTeachers,
  isNextable,
  currentStep,
  Configurations
}) {
  const { DataServer, SchedulingServer } = useAuth();
  const { dept, schedtype } = useParams();

  //Paginations
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10; // Number of items per page

  // State variables
  const [mainError, setMainError] = useState("");

  //Scheduling States
  const [isLoading, setIsLoading] = useState(false);
  const [isAssigningTeacher, setIsAssigningTeacher] = useState(true);
  const [isAssignedTeacherDone, setIsAssignedTeacherDone] = useState(false);
  const [isDone1, setIsDone1] = useState(false);
  const [isDone2, setIsDone2] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isDegreeSuitabilityLoading, setIsDegreeSuitabilitLoading] =
    useState(false);
  const [isAssigningError, setIsAssigningError] = useState(false);

  //Teacher Assignments created
  const [teacherSuitability, setTeacherSuitablity] = useState([]); //Array of all suitable teachers in each subjects
  const [assignedTeachers, setAssignedTeachers] = useState([]); //Array of the original output of assigned teachers
  const [updatesChanges, setupdatesChanges] = useState([]); //Array of new changed assigned teachers
  const [changesAssignedTeachers, setChangesAssignedTeachers] = useState([]); //Array of new changed assigned teachers
  const [changedAssignedTeachers, setChangedAssignedTeachers] = useState([]); //Array of specific changed teachers only
  const [sortedAssignedTeachers, setSortedAssignedTeachers] = useState([]); // Array of sorted assigend subjects
  const [isChangingAssignedTecahers, setIsChangingAssignedTecahers] =
    useState(false);
  const [isApplyChanges, setIsApplyChanges] = useState(false);

  //Getter and setter of Schedule Configs
  const [ScheduleType, setScheduleType] = useState("");
  const [viewType, setViewType] = useState(Configurations?.view || "teachers");
  const [PriorityType, setPriorityType] = useState(
    Configurations?.assignmentPriority || "DefaultAssignments"
  );
  const [isSchedConfigChanges, setIsSchedConfigChanges] = useState(false);
  const [schedConfigs, setSchedConfigs] = useState({
    isAdvisorPriority: Configurations?.isAdvisorPriority || false,
    schedType: Configurations?.schedType || "normalScheduling",
    advisorySubject: Configurations?.advisorySubject || {},
    advisors: Configurations?.advisors || {},
    priorities: Configurations?.priority || [],
    configs: Configurations?.assignmentPriority || "DefaultAssignments",
    factors: Configurations?.suitabilityFactors || [],
    totalFactors: Configurations?.totalSuitabilityFactors || {
      subjectTaught: 0,
      degree: 0,
      experience: 0,
      preferences: 0
    },
    weights: Configurations?.weights || {
      subjectTaught: 80,
      degree: 40,
      experience: 20,
      preference: 15
    },
    changes: changedAssignedTeachers || []
  });

  //Getter and setter of Schedule error and warnings
  const [SchedulerErrors, setSchedulerErrors] = useState([]);
  const [ScheduleWarnings, setScheduleWarnings] = useState([]);
  const [selectedWarning, setSelectedWarning] = useState({});

  //Getter and setter of all Data accquired
  const [teachers, setTeachers] = useState([]);
  const [initialTeacherTime, setInitialTeacherTime] = useState({});
  const [courses, setCourses] = useState([]);
  const [sections, setSections] = useState(0);
  const [subjects, setSubjects] = useState(0);
  const [totalTeachingTimes, setTotalTeachingTimes] = useState([]);
  const [subjectCount, setSubjectCount] = useState({});

  //Re-Assign Tecaher
  const [selectedSubject, setSelectedSubject] = useState({});

  function SchedulWarningChanges(data) {
    setScheduleWarnings([...ScheduleWarnings, data]); // Wrap data in an array
    ChangeAssignedTeachers(data);
  }
  // useEffect(() => {
  //   console.log(ScheduleWarnings);
  //   setScheduleWarnings([...ScheduleWarnings,...changedAssignedTeachers])
  // }, [ScheduleWarnings, changedAssignedTeachers]);

  //FUNCTION USED TO HANDLE A+TEACHER ASSIGNATION CHANGES
  function ChangeAssignedTeachers(data) {
    // Find the index of the teacher to be updated in assignedTeachers

    let assignedArray =
      changedAssignedTeachers.length > 0
        ? changesAssignedTeachers
        : assignedTeachers;

    // if (updatesChanges.length > 0) {
    //   const updatedAssignedArray = assignedArray.filter(
    //     (assigned) =>
    //       !updatesChanges.some((change) => change.id === assigned.id)
    //   );

    //   // Combine the filtered assignedArray with updatesChanges
    //   assignedArray = [...updatedAssignedArray, ...updatesChanges];

    //   // Now you can use newAssignedArray as the combined result
    // }

    const index = assignedArray.findIndex(
      (assigned) => assigned.id === data.id
    );

    if (index !== -1) {
      // Create a new array with the updated teacher data
      const NewChanges = [...assignedArray];
      NewChanges[index] = data; // Directly update the teacher data at the found index
      setChangesAssignedTeachers(NewChanges);
    } else {
      // If no teacher with the matching id is found in assignedTeachers
      console.warn(
        "Teacher with idss:",
        data.id,
        "not found in assignedTeachers."
      );
    }

    // Update changedAssignedTeachers array
    const specificIndex = changedAssignedTeachers.findIndex(
      (assigned) => assigned.id === data.id
    );

    if (specificIndex !== -1) {
      // If the teacher is already in changedAssignedTeachers, replace the existing entry
      const updatedChangedTeachers = [...changedAssignedTeachers];
      updatedChangedTeachers[specificIndex] = data;
      setChangedAssignedTeachers(updatedChangedTeachers);
    } else {
      // If the teacher is not in changedAssignedTeachers, just add them
      setChangedAssignedTeachers([...changedAssignedTeachers, data]);
    }
  }

  //Used to know if user is editing the assigned teachers
  useEffect(() => {
    if (changedAssignedTeachers.length > 0) {
      setIsChangingAssignedTecahers(true);
    } else {
      setIsChangingAssignedTecahers(false);
    }
  }, [changedAssignedTeachers]);

  // Initialize Preline when assignedTeachers or viewType changes
  useEffect(() => {
    require("preline/preline");
  }, [sortedAssignedTeachers]);

  // Initialize HSStaticMethods if available
  useEffect(() => {
    if (window.HSStaticMethods) {
      window.HSStaticMethods.autoInit();
    }
  }, [sortedAssignedTeachers]);

  // Configuration map for department details
  const configMap = useMemo(
    () => ({
      pre: {
        department: "Pre-School",
        range: "Nursery - Kinder 2",
        deptCode: "Pre"
      },
      elem: {
        department: "Elementary",
        range: "Grade 1 - 6",
        deptCode: "Elem"
      },
      jhs: {
        department: "Junior High-School",
        range: "Grade 7 - 10",
        deptCode: "JHS"
      },
      shs: {
        department: "Senior High-School",
        range: "Grade 11 - 12",
        deptCode: "SHS"
      }
    }),
    []
  );

  // Initial configuration state
  const [configs, setConfigs] = useState({
    department: dept,
    range: "",
    deptCode: "",
    type: schedtype
  });

  // Set department configurations when dept or schedtype changes
  useEffect(() => {
    const newDeptCode = configMap[dept.toLocaleLowerCase()]?.deptCode;

    if (newDeptCode) {
      setConfigs((prev) => {
        const newConfig = {
          ...configMap[dept.toLocaleLowerCase()],
          type: schedtype
        };

        // Only update state if something has changed
        if (
          prev.department !== newConfig.department ||
          prev.range !== newConfig.range ||
          prev.deptCode !== newConfig.deptCode ||
          prev.type !== newConfig.type
        ) {
          return newConfig;
        }

        return prev;
      });
    }
  }, [dept, schedtype, configMap]);

  // RE-LOAD ASSIGNED TEACHER ALOGIRGHTM
  useEffect(() => {
    if (assignedTeachers && assignedTeachers.length > 0) {
      setIsAssignedTeacherDone(true);
    } else {
      setIsAssignedTeacherDone(false);
    }
  }, [assignedTeachers]);

  // Fetch teachers and courses data
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        // Fetch teachers and courses data concurrently
        const [teachersResponse, coursesResponse] = await Promise.all([
          axios.get(`${DataServer}/user/teacher/sched/allTeachers`),
          axios.get(
            `${DataServer}/course/department/${configs.deptCode}/totals`,
            { params: { sem: parseInt(Configurations.sem) } }
          )
        ]);
        // console.log("EYY", coursesResponse.data);
        // Set state with the fetched data
        setTeachers(teachersResponse.data);

        // Create an initialTeachingTime Map
        const initialTeachingTime = {};

        teachersResponse.data.documents.forEach((teacher) => {
          const { teacherId, assignedTeachingTime } = teacher;

          // If the teacherId key doesn't exist, store the initial assignedTeachingTime
          if (!initialTeachingTime[teacherId]) {
            initialTeachingTime[teacherId] = assignedTeachingTime;
          }
        });

        // console.log("INITIAL TEACHING TIME: ", initialTeachingTime);
        setInitialTeacherTime(initialTeachingTime);
        setCourses(coursesResponse.data.Courses);

        setSections(coursesResponse.data.TotalSections);
        setSubjects(coursesResponse.data.TotalSubjects);
      } catch (error) {
        console.log("ggs", error);
        setMainError(
          error?.response?.data?.error
            ? error?.response?.data?.error
            : error.message
        );
        setIsAssigningError(true);
      } finally {
        setIsLoading(false);
      }
    };

    if (configs.deptCode && (!teachers || !courses || !sections || !subjects)) {
      fetchData();
    }
  }, [
    configs.deptCode,
    teachers,
    courses,
    sections,
    subjects,
    SchedulingServer
  ]);

  //Required Data Checker
  useEffect(() => {
    if (
      teachers.documents &&
      teachers.documents.length > 0 &&
      courses.documents &&
      courses.documents.length > 0
    ) {
      setIsDataLoaded(true);
    } else {
      setIsDataLoaded(false);
    }
  }, [teachers, courses]);

  //LOADING STATE UPDATE OF DEGREE WHEN DATA IS LOADED
  useEffect(() => {
    if (isDataLoaded) {
      setIsDegreeSuitabilitLoading(true);
    }
  }, [isDataLoaded]);

  // Function to return a promise that resolves after 40 seconds
  function EndLoadingDegree() {
    return new Promise((resolve) => {
      const timer = setTimeout(() => {
        resolve(true);
      }, 40000); // 40 seconds in milliseconds

      // Return a function to clear the timer
      return () => clearTimeout(timer);
    });
  }

  //WHEN LOADING IS DONE CANCEL 40 SEC DELAY IN DEGREE LOADING
  useEffect(() => {
    let isMounted = true; // Track whether the component is mounted

    async function handleDegreeSuitabilityLoading() {
      if (isDegreeSuitabilityLoading) {
        const cleanup = EndLoadingDegree();

        try {
          const completed = await cleanup;

          if (isMounted) {
            setIsDegreeSuitabilitLoading(!completed);
          }
        } catch (error) {
          if (isMounted) {
            // Handle errors if needed
          }
        }
      }
    }

    handleDegreeSuitabilityLoading();

    // Cleanup function to run when the component unmounts or `isDegreeSuitabilityLoading` changes
    return () => {
      isMounted = false; // Mark component as unmounted
    };
  }, [isDegreeSuitabilityLoading]);

  //MAIN
  // Assign teachers and handle errors
  useEffect(() => {
    const getAssignedTeachers = async () => {
      setIsAssigningTeacher(true);
      try {
        let assignedTeachersParsed;

        //Prevents unnecesarry reload of assigned data
        if (!isAssignedTeacherDone && isDataLoaded) {
          //Update the suitable teacher with initial assigend time
          const suitableTeacherWithInitialTime = teacherSuitability.map(
            (subj) => {
              return {
                ...subj,
                teachers: subj.teachers.map((teacher) => {
                  const { teacherId } = teacher;

                  // Create a new teacher object with the updated assignedTeachingTime
                  return {
                    ...teacher,
                    assignedTeachingTime:
                      initialTeacherTime[teacherId] !== undefined
                        ? initialTeacherTime[teacherId]
                        : teacher.assignedTeachingTime
                  };
                })
              };
            }
          );

          const body = {
            Department: dept,
            Configs: schedConfigs,
            Teachers: teachers.documents,
            Courses: courses.documents,
            Suitability: suitableTeacherWithInitialTime || []
          };

          //CALLS SERVER
          const assignedTeachersResponse = await assignTeacher(body);

          assignedTeachersParsed = assignedTeachersResponse.data
            .AssignedTeachers.AssignedTeachers
            ? assignedTeachersResponse.data.AssignedTeachers.AssignedTeachers
            : assignedTeachersResponse.data.AssignedTeachers;
          setAssignedTeachers(assignedTeachersParsed);
          const ATPchanges = [];

          //THIS GETS THE CHANGES MADE BY THE USER
          assignedTeachersParsed.map((ass) => {
            if (ass.status === "updated") {
              ATPchanges.push(ass);
            }
          });

          if (ATPchanges.length > 0) {
            setupdatesChanges(ATPchanges);
          }
          setTeacherSuitablity(
            assignedTeachersResponse.data.TeacherSuitability
          );
          // console.log(assignedTeachersResponse.data);
          setSubjectCount(assignedTeachersResponse.data.ReassignData);
          // setTeacherTimes(
          // assignedTeachersResponse.data.AssignedTeachers.timeToSave
          // );
          // console.log(
          //   "GG",
          //   assignedTeachersResponse.data.AssignedTeachers.timeToSave
          // );
        }

        //Sorting the Teacher Assignments created
        const SchedErr = [];
        const SchedWarn = [];

        assignedTeachersParsed =
          assignedTeachers && assignedTeachers.length > 0
            ? changesAssignedTeachers.length > 0
              ? changesAssignedTeachers
              : assignedTeachers
            : assignedTeachersParsed;

        let transformedData;

        //FORMATS THE DATA IN A NEEDED STRUCTURE
        if (assignedTeachersParsed && assignedTeachersParsed.length > 0) {
          // Transform the data based on viewType
          switch (viewType.toLocaleLowerCase()) {
            case "teachers":
              transformedData = ByTeachersView(
                assignedTeachersParsed,
                SchedErr,
                SchedWarn
              );

              break;

            case "courses":
              transformedData = ByCourseView(
                assignedTeachersParsed,
                SchedErr,
                SchedWarn
              );

              break;

            case "specific assignments":
              transformedData = SpecificAssignments(
                assignedTeachersParsed,
                SchedErr,
                SchedWarn
              );
              break;

            default:
              transformedData = ByTeachersView(
                assignedTeachersParsed,
                SchedErr,
                SchedWarn
              );
              break;
          }

          //ADDED 1s DELAY for UI visualizations
          if (isAssigningTeacher) {
            setTimeout(() => {}, 1000);
          }

          //Save data if sorting is completed
          setSchedulerErrors(SchedErr);
          setScheduleWarnings(SchedWarn);
          // Calculate the index of the last item on the current page
          const indexOfLastItem = currentPage * itemsPerPage;
          // Calculate the index of the first item on the current page
          const indexOfFirstItem = indexOfLastItem - itemsPerPage;
          // Slice the data to get items for the current page
          const items = transformedData.slice(
            indexOfFirstItem,
            indexOfLastItem
          );
          const totalPages = Math.ceil(transformedData.length / itemsPerPage);
          setTotalPages(totalPages);

          setSortedAssignedTeachers(items);
          setIsAssignedTeacherDone(true);
          setIsAssigningError(false);
        }
      } catch (error) {
        setMainError(
          error?.response?.data?.error
            ? error?.response?.data?.error
            : error.message
        );
        setIsAssigningError(true);
        console.log(error);
      } finally {
        setIsAssigningTeacher(false);
      }
    };
    getAssignedTeachers();
  }, [
    configs.deptCode,
    viewType,
    schedConfigs,
    isDataLoaded,
    isAssignedTeacherDone,
    currentPage
  ]);

  //ERROR IN ASSIGNING CHECKER
  useEffect(() => {
    if (assignedTeachers?.status === "error") {
      setIsAssigningError(true);
    }
  }, [assignedTeachers]);

  //Change the AssignedTeacherDone state only if sched configs changes and the teachers are already assigned
  useEffect(() => {
    // console.log(
    //   isAssignedTeacherDone,
    //   isSchedConfigChanges || changedAssignedTeachers.length > 0
    // );
    if (isAssignedTeacherDone && isSchedConfigChanges) {
      setIsAssignedTeacherDone(false);
      setIsSchedConfigChanges(false);
    }
  }, [schedConfigs, isSchedConfigChanges, changedAssignedTeachers]);

  //Update SchedCOnfigs
  useEffect(() => {
    setSchedConfigs({ ...schedConfigs, changes: changedAssignedTeachers });
  }, [changedAssignedTeachers]);

  //Updated Isapplied when applied
  useEffect(() => {
    //Reset when applied
    if (
      isAssignedTeacherDone &&
      isApplyChanges &&
      assignedTeachers?.some((teacher) => teacher.status === "updated")
    ) {
      setChangedAssignedTeachers([]);
      setChangesAssignedTeachers([]);
      setIsApplyChanges(false);
    }
  }, [isApplyChanges, assignedTeachers, isAssignedTeacherDone]);

  useEffect(() => {}, [sortedAssignedTeachers]);
  // useEffect(() => {
  //   console.log("TEACHER SUITABILITY BY SUBJECTS: ", teacherSuitability);
  // }, [teacherSuitability]);
  useEffect(() => {}, [assignedTeachers]);

  // Set isNextable based on current step and assigned teachers
  useEffect(() => {
    if (currentStep === 0) {
      if (assignedTeachers.length > 0) {
        if (
          isAssignedTeacherDone &&
          assignedTeachers.length > 0 &&
          SchedulerErrors.length <= 0 &&
          ScheduleWarnings.length <= 0
        ) {
          isNextable(true);
          confirmTeachers(assignedTeachers);
        } else {
          isNextable(false);
        }
      }
    }
  }, [
    isAssignedTeacherDone,
    assignedTeachers,
    SchedulerErrors,
    ScheduleWarnings,
    isNextable,
    currentStep
  ]);

  //Backend Server v2 Function to assign teachers
  const assignTeacher = async (data) => {
    return await axios.post(
      `${SchedulingServer}/schedule/create/subject_assignments/${configs.deptCode}`,
      data
    );
  };

  //Get ALL TECAHING TIME FOR EACH TEACHERID
  useEffect(() => {
    // Initialize an empty object to store the result
    const teacherSubjects = {};
    if (assignedTeachers && assignedTeachers.length > 0) {
      try {
        assignedTeachers.forEach((assignment) => {
          const { teacherId, totalSubjectTimeInAWeek } = assignment;

          // If the teacherId key already exists, add to the existing value
          if (teacherSubjects[teacherId]) {
            teacherSubjects[teacherId] += totalSubjectTimeInAWeek;
          } else {
            // Otherwise, create a new key with the initial value
            teacherSubjects[teacherId] = totalSubjectTimeInAWeek;
          }
        });
        // console.log(teacherSubjects, "HH");
        // Update the state with the accumulated results
        setTotalTeachingTimes(teacherSubjects);
      } catch (e) {
        console.error(e);
      }
    }
  }, [assignedTeachers]);

  //DELAY LOGIC FOR UI VISUALIZATION
  useEffect(() => {
    if (isAssignedTeacherDone) {
      // console.log("Delay called");
      // Delay the execution of the first action by 1 second
      const timer1 = setTimeout(() => {
        setIsDone1(true);
        // console.log("Delayed action 1 executed after 1 second");
      }, 1000);

      // Delay the execution of the second action by 2 seconds
      const timer2 = setTimeout(() => {
        setIsDone2(true);
        // console.log("Delayed action 2 executed after 2 secondss");
      }, 2000);

      // Cleanup the timers if the component unmounts or condition changes
      return () => {
        clearTimeout(timer1);
        clearTimeout(timer2);
      };
    }
  }, [isAssignedTeacherDone]);

  //EVERY VIEW CHANGE SET THE CURRENNT PAGE TO 1
  useEffect(() => {
    setCurrentPage(1);
  }, [viewType]);

  return (
    <div className="w-full">
      {mainError && <Error errorMessage={mainError} reset={setMainError} />}
      <div className="max-w-[85rem] px-4 pt-10 pb-5 sm:px-6 lg:px-8  mx-auto">
        <div className="grid items-center lg:grid-cols-12 gap-6 lg:gap-12">
          <div className="lg:col-span-4">
            {/* TITLES */}
            <div className="lg:pe-6 xl:pe-12">
              <p className="text-4xl font-bold break-words leading-10 text-[#72539bcc]">
                {configs.department}
              </p>
              <p className="mt-2 sm:mt-3 text-gray-500 dark:text-neutral-500">
                {configs.range}
              </p>
            </div>
          </div>
          {/* Department States (Teachers,subjects and sections) */}
          <div className="lg:col-span-8 relative lg:before:absolute lg:before:top-0 lg:before:-start-12 lg:before:w-px lg:before:h-full lg:before:bg-gray-200 lg:before:dark:bg-neutral-700">
            <div className="grid gap-6 grid-cols-2 md:grid-cols-4 lg:grid-cols-3 sm:gap-8">
              <div>
                {isLoading ? (
                  <Spinner size="md" color="secondary" />
                ) : (
                  <p className="text-3xl font-semibold text-[#72539bcc]">
                    {subjects}
                  </p>
                )}
                <p className="mt-1 text-gray-500 dark:text-neutral-500">
                  Total Subjects
                </p>
              </div>
              <div>
                {isLoading ? (
                  <Spinner size="md" color="secondary" />
                ) : (
                  <p className="text-3xl font-semibold text-[#72539bcc]">
                    {sections}
                  </p>
                )}
                <p className="mt-1 text-gray-500 dark:text-neutral-500">
                  Total Sections
                </p>
              </div>
              <div>
                {isLoading ? (
                  <Spinner size="md" color="secondary" />
                ) : (
                  <p className="text-3xl font-semibold text-[#72539bcc]">
                    {teachers.total}
                  </p>
                )}
                <p className="mt-1 text-gray-500 dark:text-neutral-500">
                  Total Teachers
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Options or configurations */}
      <div className="relative flex flex-col py-2 sticky p-2 my-2 top-3 z-20 right-2 justify-between">
        {/* Background with opacity */}
        <div className="flex flex-col sm:flex-row ">
          <div className="absolute inset-0 bg-secondary rounded-lg opacity-80 z-[-1]"></div>

          <div className=" flex-1">
            <Pagination
              isDisabled={!isAssignedTeacherDone || !isDone2}
              color="secondary"
              showControls
              total={totalPages}
              page={currentPage}
              onChange={setCurrentPage}
              classNames={{
                prev: " bg-secondary-400",
                item: "bg-secondary-500 text-white",
                next: "bg-secondary-400",
                cursor: "bg-[#ededed]  text-black"
              }}
              initialPage={1}
            />
          </div>
          <div className=" hidden sm:flex sm:flex-initial">
            <ScheduleOptions
              isSchedDone={isAssignedTeacherDone && isDone2}
              selectedView={viewType}
              setSelectedView={setViewType}
              selectedPriority={PriorityType}
              setSelectedPriority={setPriorityType}
              Configs={schedConfigs}
              isChangedSchedConfigs={setIsSchedConfigChanges}
              setConfigs={setSchedConfigs}
              data={{ teachers, courses }}
            />
          </div>
        </div>
        {/* Changes Shower */}
        {changesAssignedTeachers.length > 0 && (
          <div className=" w-full sticky bg-warning-100 shadow-sm my-2 flex justify-left items-center flex-col sm:flex-row sm:justify-between  py-2 px-4 rounded-lg">
            <span className="  text-left font-medium">
              {changedAssignedTeachers.length} changes
            </span>
            <div className=" flex-initial space-x-2">
              <Button
                variant="flat"
                onPress={() => {
                  setChangedAssignedTeachers([]);
                  setChangesAssignedTeachers([]);
                }}
                color="warning"
              >
                Cancel
              </Button>
              <Button
                color="warning"
                onPress={() => {
                  setIsAssignedTeacherDone(false);
                  setIsApplyChanges(true);
                }}
                variant="shadow"
              >
                Apply
              </Button>
            </div>
          </div>
        )}
      </div>

      {/* OUTPUT SHOWER */}
      <div
        className="hs-accordion-group space-y-3 w-full"
        data-hs-accordion-always-open=""
      >
        {/* WARNINGS AND ERRORS SHOWERS */}
        {SchedulerErrors.length > 0 && (
          <ScheduleErrors
            errors={SchedulerErrors}
            selectedWarning={setSelectedWarning}
          />
        )}
        {ScheduleWarnings.length > 0 && (
          <ScheduleErrors
            type="warn"
            totals={totalTeachingTimes}
            suitableTeachers={teacherSuitability}
            updateAssigned={ChangeAssignedTeachers}
            warnings={ScheduleWarnings}
            selectedWarning={setSelectedWarning}
          />
        )}
        {isDone2 &&
        sortedAssignedTeachers &&
        sortedAssignedTeachers.length > 0 ? (
          sortedAssignedTeachers.map((data, index) => (
            // Main Teacher SHower
            <Skeleton
              key={index}
              className="rounded-lg"
              isLoaded={!isAssigningTeacher}
            >
              <div className="hs-accordion bg-white rounded-lg active">
                <button
                  className="hs-accordion-toggle bg-[#72539bcc] text-white px-2 py-3 inline-flex items-center justify-between gap-x-3 w-full font-semibold text-start rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:hs-accordion-active:text-blue-500 dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
                  aria-controls={`collapse-${index}`}
                >
                  {viewType !== "courses" && (
                    <TeacherTooltip key={index} Data={data} />
                  )}
                  {viewType === "courses" && `${data.courseName}`}
                  <svg
                    className="hs-accordion-active:hidden block size-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="m6 9 6 6 6-6"></path>
                  </svg>
                  <svg
                    className="hs-accordion-active:block hidden size-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="m18 15-6-6-6 6"></path>
                  </svg>
                </button>
                <div
                  id={`collapse-${index}`}
                  className="hs-accordion-content w-full overflow-auto transition-[height] duration-300"
                  ariaLabelledby={`heading-${index}`}
                >
                  <div className="p-2">
                    {/* COURSE VIEW */}
                    {viewType === "courses" && (
                      <AssignedSubjectCourses
                        key={index}
                        Data={{
                          sections: data.sections,
                          courseId: data.courseId,
                          courseName: data.courseName
                        }}
                        Count={subjectCount}
                        factors={schedConfigs.factors}
                        selectSubject={setSelectedSubject}
                        totals={totalTeachingTimes}
                        suitableTeachers={teacherSuitability}
                        updateAssigned={ChangeAssignedTeachers}
                        AddSchedWaning={SchedulWarningChanges}
                      />
                    )}
                    {/* TEACHER VIEW */}
                    {viewType === "teachers" && (
                      <AssignedSubjectTeachers
                        key={index}
                        Data={data.AssignedSubjects}
                        factors={schedConfigs.factors}
                      />
                    )}
                    {/* SPECIFIC VIEW */}
                    {viewType === "specific assignments" && (
                      <AssignedSubjectsSpecific
                        key={index}
                        Data={data}
                        factors={schedConfigs.factors}
                      />
                    )}
                  </div>
                </div>
              </div>
            </Skeleton>
          ))
        ) : (
          <>
            {(isAssigningTeacher || isLoading || !isDone1 || !isDone2) &&
            !isAssigningError ? (
              // Loading Teachers
              <div
                className="bg-secondary-100 w-full flex flex-col  text-gray-800 rounded-lg p-4 dark:bg-blue-800/10 dark:border-blue-900 dark:text-white"
                role="alert"
              >
                <div className="flex w-full">
                  <div className="flex-shrink-0">
                    <svg
                      className="flex-shrink-0 size-4 mt-1"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <circle cx="12" cy="12" r="10"></circle>
                      <path d="M12 16v-4"></path>
                      <path d="M12 8h.01"></path>
                    </svg>
                  </div>
                  <div className="ms-3 w-full">
                    <h3 className="font-semibold flex items-center gap-2">
                      Assigning Subjects to Teachers
                      <l-quantum
                        size="21"
                        speed="1.75"
                        color="hsl(270 66.67% 47.06% / 1)"
                      ></l-quantum>
                    </h3>
                  </div>
                </div>
                {/* Loading states */}
                <div className=" text-sm flex flex-col p-2 space-y-2">
                  <LoadingInfo
                    isCompleted={isDataLoaded}
                    completedMessage="Required Data is loaded such as Teachers, Courses, and Subjects"
                    title="Loading All Required Data such as Teachers, Courses, and Subjects"
                  />
                  <LoadingInfo
                    isPending={!isDataLoaded}
                    isCompleted={
                      (isDataLoaded && !isDegreeSuitabilityLoading) ||
                      isAssignedTeacherDone
                    }
                    completedMessage="The Relevance of each teacher's degree to each subjects to assign is analyzed"
                    pendingMessage="Waiting for Required data to be loaded"
                    title="Analysing Each teacher's degree relevance to each subjects to assign"
                  />
                  <LoadingInfo
                    isPending={!isDataLoaded}
                    isCompleted={isAssignedTeacherDone}
                    completedMessage="The Relevance of each teacher's taught subjects to each subjects to assign is analyzed"
                    pendingMessage="Waiting for Required data to be loaded"
                    title="Analysing Each teacher's taught subjects relevance to each subjects to assign"
                  />
                  {/* After done assigning wait for 1.5 s then show */}
                  <LoadingInfo
                    isCompleted={isDone1}
                    completedMessage="Assigned Suitable Teachers to each subjects in each course and sections"
                    title="Assigning suitable teachers to each subjects in each course and sections"
                    pendingMessage="Waiting for the best result of teacher Suitabilities in each subjects"
                    isPending={true}
                  />
                </div>
              </div>
            ) : (
              //Teachers data Errors
              <div
                className="bg-yellow-100 border text-sm text-yellow-800 rounded-lg p-4 dark:bg-yellow-800/10 dark:border-yellow-900 dark:text-yellow-500"
                role="alert"
              >
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg
                      className="flex-shrink-0 size-4 mt-0.5"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3Z"></path>
                      <path d="M12 9v4"></path>
                      <path d="M12 17h.01"></path>
                    </svg>
                  </div>
                  <div className="ms-4">
                    <h3 className="text-sm font-semibold">
                      Something went wrong...
                    </h3>
                    <div className="mt-1 text-sm text-yellow-700">
                      We are unable to assign subjects to specific Teachers.
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <div className=" flex justify-end sticky absolute bottom-2 right-2 z-20  sm:hidden">
        <ScheduleOptions
          isSchedDone={isAssignedTeacherDone && isDone2}
          selectedView={viewType}
          setSelectedView={setViewType}
          selectedPriority={PriorityType}
          setSelectedPriority={setPriorityType}
          Configs={schedConfigs}
          setConfigs={setSchedConfigs}
          data={{ teachers, courses }}
        />
      </div>
      {/* Reassign Modal */}
      {/* <ReAssignTeacher Data={selectedWarning} totalTimes={totalTeachingTimes} /> */}
    </div>
  );
}
