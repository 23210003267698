import React, { useEffect, useState } from "react";
import {
  Tooltip,
  Button,
  Avatar,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Spinner,
  Skeleton,
  Chip,
  User,
  Popover,
  PopoverTrigger,
  PopoverContent
} from "@nextui-org/react";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import axios from "axios";
import { storage } from "../../../../../utils/AppwriteConfig/Config";

export default function TeacherTooltip({ children, Data }) {
  const { DataServer } = useAuth();
  let load = 0;
  if (Data?.AssignedSubjects) {
    for (const subj of Data?.AssignedSubjects) {
      load += subj.load.length;
    }
  }

  const [isLoading, setIsLoading] = useState(true);
  const [mainError, setMainError] = useState("");

  const [teacher, setTeacher] = useState({});

  //profile
  const [ProfilePicture, setProfilePicture] = useState();
  const [UserCred, setUserCred] = useState({});
  const [imageURL, setImageURL] = useState(null);

  useEffect(() => {
    try {
      setIsLoading(true);
      async function GetTeacherData() {
        const teacherData = await axios.get(
          `${DataServer}/user/teacher/${Data.teacherId}/allData`
        );
        setTeacher(teacherData.data);
        setProfilePicture(teacherData.data.userCredentials?.profilePicture);
      }

      if (Data.teacherId) {
        GetTeacherData();
      }
    } catch (e) {
      setMainError(e.message);
    } finally {
      setIsLoading(false);
    }
  }, [Data, DataServer]);

  useEffect(() => {
    const loadImage = async () => {
      try {
        if (ProfilePicture) {
          // Load image from storage using data.fileid
          const result = await storage.getFilePreview(
            "66198605bb373176f557",
            ProfilePicture
          );
          setImageURL(result);
        }
      } catch (error) {
        console.error("Error loading image:", error);
      }
    };

    loadImage();
  }, [ProfilePicture]);
  return (
    <>
      <div className="  hidden md:flex">
        <Tooltip
          placement="bottom-start"
          closeDelay={0}
          key={Data.teacherId}
          content={
            <Card
              shadow="none"
              className="max-w-[450px] min-w-[300px] border-none bg-transparent"
            >
              <CardHeader className="flex max-h-[200px] w-full justify-between">
                <div className="flex gap-3">
                  <Avatar
                    isBordered
                    radius="full"
                    size="md"
                    name={Data?.teacherName || Data?.userCredentials?.Username}
                    src={imageURL}
                  />
                  <div className="flex flex-col items-start justify-center">
                    <h4 className="text-small font-semibold leading-none text-default-600">
                      {Data?.teacherName || Data?.userCredentials?.Username}
                    </h4>
                    <Skeleton isLoaded={!isLoading}>
                      <h5 className="text-small tracking-tight text-default-500">
                        {teacher?.userCredentials?.Email}
                      </h5>
                    </Skeleton>
                  </div>
                </div>
                <Button
                  //   className={
                  //     isFollowed
                  //       ? "bg-transparent text-foreground border-default-200"
                  //       : ""
                  //   }
                  color="secondary"
                  radius="full"
                  size="sm"
                  className="px-6 py-[20px]"
                  //variant={isFollowed ? "bordered" : "solid"}
                  //   onPress={() => setIsFollowed(!isFollowed)}
                >
                  {/* {isFollowed ? "Unfollow" : "Follow"} */}
                  {/* <div className="flex flex-col ">
                    <span>
                      {teacher.assignedTeachingTime !== null ? (
                        teacher.assignedTeachingTime
                      ) : (
                        <Spinner size="sm" />
                      )}
                      /1800
                    </span>
                    <small>Current Teaching Time</small>
                  </div> */}
                </Button>
              </CardHeader>
              <CardBody className="px-3 py-0 max-h-[250px] [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-500 dark:[&::-webkit-scrollbar-track]:bg-slate-700  dark:[&::-webkit-scrollbar-thumb]:bg-slate-500">
                <div className=" flex flex-col space-y-2">
                  {teacher?.teachedSubjects?.length > 0 && (
                    <div>
                      <span className=" text-sm font-medium">
                        Taught Subjects
                        {teacher?.teachedSubjects?.length > 1 && "s"}
                      </span>
                      <div className="text-small text-default-500">
                        {teacher.teachedSubjects &&
                          teacher.teachedSubjects.map((subj, index) => (
                            <Chip size="md" color="secondary" className="m-1">
                              {subj.subject}
                            </Chip>
                          ))}
                      </div>
                    </div>
                  )}
                  {teacher?.preferredSubjects?.length > 0 && (
                    <div>
                      <span className=" text-sm font-medium">
                        Preferred subject
                        {teacher?.preferredSubjects?.length > 1 && "s"}
                      </span>
                      <div className="text-small text-default-500">
                        {teacher.preferredSubjects &&
                          teacher.preferredSubjects.map((subj, index) => (
                            <Chip size="md" color="secondary" className="m-1">
                              {subj.subject.subjectName}
                            </Chip>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              </CardBody>
              <CardFooter className="gap-3">
                <div className="flex gap-1">
                  <p className="font-semibold text-default-600 text-small">
                    {teacher.degree}
                  </p>
                </div>
                <div className="flex gap-1">
                  <Skeleton isLoaded={!isLoading}>
                    <p className="font-semibold text-default-600 text-small">
                      {teacher.teachingExperience}
                    </p>
                  </Skeleton>
                  <p className="text-default-500 text-small">
                    Experience of teaching
                  </p>
                </div>
              </CardFooter>
            </Card>
          }
        >
          <User
            name={Data?.teacherName || Data?.userCredentials?.Username}
            description={`${Data.teacherId} | ${
              Data?.teacherDept || Data?.department
            }`}
            avatarProps={{
              src: imageURL
            }}
          />
        </Tooltip>
      </div>
      <div className="flex md:hidden">
        <Popover placement="bottom">
          <PopoverTrigger>
            <User
              name={Data?.teacherName || Data?.userCredentials?.Username}
              description={`${Data.teacherId} | ${
                Data?.teacherDept || Data?.department
              }`}
              avatarProps={{
                src: imageURL
              }}
            />
          </PopoverTrigger>
          <PopoverContent className="p-0">
            <Card
              shadow="none"
              className="max-w-[450px] min-w-[100px] border-none bg-transparent"
            >
              <CardHeader className="flex flex-col items-start sm:flex-row max-h-[200px] w-full justify-between">
                <div className="flex gap-3">
                  <Avatar
                    isBordered
                    radius="full"
                    size="md"
                    name={Data?.teacherName || Data?.userCredentials?.Username}
                    src={imageURL}
                  />
                  <div className="flex flex-col items-start justify-center">
                    <h4 className="text-small font-semibold leading-none text-default-600">
                      {Data?.teacherName || Data?.userCredentials?.Username}
                    </h4>
                    <Skeleton isLoaded={!isLoading}>
                      <h5 className="text-small tracking-tight text-default-500">
                        {teacher?.userCredentials?.Email}
                      </h5>
                    </Skeleton>
                  </div>
                </div>
                <Button
                  color="secondary"
                  radius="full"
                  size="sm"
                  className="px-6 py-[20px] flex w-full sm:w-auto"
                >
                  <div className="flex flex-col ">
                    <span>
                      {teacher.assignedTeachingTime !== null ? (
                        teacher.assignedTeachingTime
                      ) : (
                        <Spinner size="sm" />
                      )}
                      /1800
                    </span>
                    <small>Current Teaching Time</small>
                  </div>
                </Button>
              </CardHeader>
              <CardBody className="px-3 py-0 max-h-[250px] [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-500 dark:[&::-webkit-scrollbar-track]:bg-slate-700  dark:[&::-webkit-scrollbar-thumb]:bg-slate-500">
                <div className=" flex flex-col space-y-2">
                  {teacher?.teachedSubjects?.length > 0 && (
                    <div>
                      <span className=" text-sm font-medium">
                        Taught Subjects
                        {teacher?.teachedSubjects?.length > 1 && "s"}
                      </span>
                      <div className="text-small text-default-500">
                        {teacher.teachedSubjects &&
                          teacher.teachedSubjects.map((subj, index) => (
                            <Chip size="md" color="secondary" className="m-1">
                              {subj.subject}
                            </Chip>
                          ))}
                      </div>
                    </div>
                  )}
                  {teacher?.preferredSubjects?.length > 0 && (
                    <div>
                      <span className=" text-sm font-medium">
                        Preferred subject
                        {teacher?.preferredSubjects?.length > 1 && "s"}
                      </span>
                      <div className="text-small text-default-500">
                        {teacher.preferredSubjects &&
                          teacher.preferredSubjects.map((subj, index) => (
                            <Chip size="md" color="secondary" className="m-1">
                              {subj.subject.subjectName}
                            </Chip>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              </CardBody>
              <CardFooter className="gap-3">
                <div className="flex gap-1">
                  <p className="font-semibold text-default-600 text-small">
                    {teacher.degree}
                  </p>
                </div>
                <div className="flex gap-1">
                  <Skeleton isLoaded={!isLoading}>
                    <p className="font-semibold text-default-600 text-small">
                      {teacher.teachingExperience} Experience of teaching
                    </p>
                  </Skeleton>
                </div>
              </CardFooter>
            </Card>
          </PopoverContent>
        </Popover>
      </div>
    </>
  );
}
