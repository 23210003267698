import { useEffect, useState } from "react";
import Error from "../../../../../Components/ErrorIndicator";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import { Spinner } from "@nextui-org/react";
import axios from "axios";

export default function Dashcard({ info }) {
  const { server, user } = useAuth();

  const [isLoading, setisLoading] = useState(true);
  const [Refresh, setRefresh] = useState(false);
  const [MainError, setMainError] = useState();

  const [TotalUser, setTotalUser] = useState(0);

  useEffect(() => {
    async function getData() {
      setisLoading(true);
      
      const total = await axios.get(`${server}/v1/totalusers`, {
        params: {
          user: info?.user,
        },
      });
      setTotalUser(total.data);
      try {
      } catch (e) {
        setMainError(e.message);
      } finally {
        setisLoading(false);
      }
    }
    getData();
  }, [info]);
  return (
    <div class="flex flex-col bg-gradient-to-r from-[#f3ddf7] via-[#f3d9f8] to-white  border shadow-sm rounded-xl dark:bg-slate-900 dark:border-gray-800">
      {MainError && <Error errorMessage={MainError} />}
      <div class="p-4 md:p-5 flex  items-center gap-x-4">
        <div class="flex-shrink-0 flex justify-center items-center size-[46px] bg-white rounded-lg dark:bg-gray-800">
          <svg
            class="flex-shrink-0 size-5 text-black dark:text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        </div>

        <div class="grow">
          <div class="flex items-center gap-x-2">
            <p class="text-xs uppercase tracking-wide text-black">
              {info?.title}
            </p>

            <div class="hs-tooltip">
              <div class="hs-tooltip-toggle">
                <svg
                  class="flex-shrink-0 size-4 text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <circle cx="12" cy="12" r="10" />
                  <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                  <path d="M12 17h.01" />
                </svg>
                <span
                  class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm dark:bg-slate-700"
                  role="tooltip"
                >
                  {info?.description}
                </span>
              </div>
            </div>
          </div>
          <div class="mt-1 flex items-center gap-x-2">
            {isLoading ? (
              <Spinner size="sm" color="secondary" />
            ) : (
              <h3 class="text-lg sm:text-xl font-medium text-gray-800 dark:text-gray-200">
                {TotalUser}
              </h3>
            )}

            {/* <span class="inline-flex items-center gap-x-1 py-0.5 px-2 rounded-full bg-green-100 text-green-900 dark:bg-green-800 dark:text-green-100">
              <svg class="inline-block size-4 self-center" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="22 7 13.5 15.5 8.5 10.5 2 17"/><polyline points="16 7 22 7 22 13"/></svg>
              <span class="inline-block text-xs font-medium">
                12.5%
              </span>
            </span> */}
          </div>
        </div>
      </div>
    </div>
  );
}

// <div class="flex flex-col bg-gradient-to-r from-[#9065c9] via-[#925ba0] to-[#7d458b]  border shadow-sm rounded-xl dark:bg-slate-900 dark:border-gray-800">
//   <div class="p-4 md:p-5 flex gap-x-4">
//     <div class="flex-shrink-0 flex justify-center items-center size-[46px] bg-gray-100 rounded-lg dark:bg-gray-800">
//       <svg
//         class="flex-shrink-0 size-5 text-gray-600 dark:text-gray-400"
//         xmlns="http://www.w3.org/2000/svg"
//         width="24"
//         height="24"
//         viewBox="0 0 24 24"
//         fill="none"
//         stroke="currentColor"
//         stroke-width="2"
//         stroke-linecap="round"
//         stroke-linejoin="round"
//       >
//         <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
//         <circle cx="9" cy="7" r="4" />
//         <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
//         <path d="M16 3.13a4 4 0 0 1 0 7.75" />
//       </svg>
//     </div>

//     <div class="grow">
//       <div class="flex items-center gap-x-2">
//         <p class="text-xs uppercase tracking-wide text-gray-500">Total users</p>
//         <div class="hs-tooltip">
//           <div class="hs-tooltip-toggle">
//             <svg
//               class="flex-shrink-0 size-4 text-gray-500"
//               xmlns="http://www.w3.org/2000/svg"
//               width="24"
//               height="24"
//               viewBox="0 0 24 24"
//               fill="none"
//               stroke="currentColor"
//               stroke-width="2"
//               stroke-linecap="round"
//               stroke-linejoin="round"
//             >
//               <circle cx="12" cy="12" r="10" />
//               <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
//               <path d="M12 17h.01" />
//             </svg>
//             <span
//               class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm dark:bg-slate-700"
//               role="tooltip"
//             >
//               The number of daily users
//             </span>
//           </div>
//         </div>
//       </div>
//       <div class="mt-1 flex items-center gap-x-2">
//         <h3 class="text-xl sm:text-2xl font-medium text-gray-800 dark:text-gray-200">
//           72,540
//         </h3>
//         <span class="inline-flex items-center gap-x-1 py-0.5 px-2 rounded-full bg-green-100 text-green-900 dark:bg-green-800 dark:text-green-100">
//           <svg
//             class="inline-block size-4 self-center"
//             xmlns="http://www.w3.org/2000/svg"
//             width="24"
//             height="24"
//             viewBox="0 0 24 24"
//             fill="none"
//             stroke="currentColor"
//             stroke-width="2"
//             stroke-linecap="round"
//             stroke-linejoin="round"
//           >
//             <polyline points="22 7 13.5 15.5 8.5 10.5 2 17" />
//             <polyline points="16 7 22 7 22 13" />
//           </svg>
//           <span class="inline-block text-xs font-medium">12.5%</span>
//         </span>
//       </div>
//     </div>
//   </div>
// </div>;
