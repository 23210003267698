import { useEffect, useState } from "react";
import Cardsample from "../../../Components/skeleton/Cards";
import AddNewCourse from "./actions/AddNewCourse";
import axios from "axios";
import { useAuth } from "../../../utils/AppwriteConfig/AuthContext";
import CScard from "../../../Components/SubjectCard";
import CourseDetails from "./components/modals/Coursedetails";
import StudentCanvas from "./components/StudentCanvas";
import { useNavigate } from "react-router-dom";
import Error from "../../../Components/ErrorIndicator";
import {
  Card,
  CardFooter,
  Image,
  Pagination,
  Skeleton
} from "@nextui-org/react";
import useRealtimeSubscription from "../../../utils/AppwriteConfig/RealtimeServices";

export default function Courses() {
  const { DataServer, role } = useAuth();
  const navigate = useNavigate();

  const [isLoading, setisLoading] = useState(true);
  const [isrefresh, setisrefresh] = useState(true);
  const [lightrefresh, setlightrefresh] = useState(true);
  const [MainError, setMainError] = useState();
  const [courses, setcourses] = useState([]);
  const [SelectedCourse, setSelectedCourse] = useState({});
  const [Pages, setPages] = useState({
    total: 0,
    current: 1
  });

  // Redirect if the user is not authorized
  useEffect(() => {
    if (!role.includes("Adminhead") && !role.includes("Registrar")) {
      navigate("/unauthorized");
    }
  }, [role, navigate]);

  // if (!role.includes("Adminhead") && !role.includes("Registrar")) {
  //   return null; // Prevent rendering if unauthorized
  // }

  const handleRealtimeMessage = (message) => {
    if (message.changetype === "create" || message.changetype === "delete") {
      setisrefresh((prev) => !prev);
    } else if (message.changetype === "update") {
      const courseIndex = courses.findIndex(
        (course) => course.$id === message.payload.$id
      );
      if (courseIndex !== -1) {
        const updatedCourses = [...courses];
        updatedCourses[courseIndex] = message.payload;
        setcourses(updatedCourses);
      }
    }
  };

  const lightrefreshed = () => {
    setlightrefresh((prev) => !prev);
  };

  useEffect(() => {
    setisLoading(true);
    const fetchCourses = async () => {
      try {
        const response = await axios.get(`${DataServer}/course/all`, {
          params: {
            page: Pages.current
          }
        });
        const totalPages = Math.ceil(response.data.total / 8);
        setPages((prevPages) => ({ ...prevPages, total: totalPages }));
        const preCourses = response.data.documents.filter(
          (course) => course.department === "Pre"
        );
        const otherCourses = response.data.documents.filter(
          (course) => course.department !== "Pre"
        );
        const sortedCourses = [...preCourses, ...otherCourses];
        setcourses(sortedCourses);
      } catch (error) {
        setMainError(error.message);
      } finally {
        setisLoading(false);
      }
    };

    fetchCourses();
  }, [isrefresh, Pages.current, DataServer]);

  const SelectCourse = (course) => {
    setSelectedCourse(course);
    document.querySelector("#CourseDetailBtn").click();
  };
  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_COURSE,
    handleRealtimeMessage
  );
  return (
    <div className="w-full h-full sm:pt-4 overflow-hidden">
      {MainError && <Error errorMessage={MainError} />}
      <div className="flex flex-col h-full space-y-2 justify-center">
        <div className="flex-initial flex w-full sm:flex-row flex-col justify-between items-center p-0 sm:p-2 mb-4">
          <span className="font-bold flex-initial text-3xl sm:mb-0 mb-2 text-[#72539b]">
            Courses
          </span>
          <div className=" flex-1 flex justify-end">
            {role.includes("Adminhead") && (
              <AddNewCourse
                Title={"Courses"}
                reload={() => setisrefresh((prev) => !prev)}
              />
            )}
            <StudentCanvas isrefreshed={lightrefresh} />
          </div>
        </div>
        <div className="flex-initial mb-2 w-full h-full bg-white rounded-lg py-4 p-1 overflow-auto shadow-md relative">
          <div className="grid p-3 gap-3 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4  overflow-auto flex-1">
            {!isLoading && courses && courses.length > 0 ? (
              courses.map((course) => (
                <CScard
                  key={course.$id}
                  data={course}
                  selectcourse={SelectCourse}
                  reload={() => setisrefresh((prev) => !prev)}
                  type={"course"}
                  lrefresh={lightrefresh}
                />
              ))
            ) : isLoading ? (
              <>
                {" "}
                <Skeleton isLoaded={false} className=" w-full  h-full">
                  <Card
                    shadow="sm"
                    className=" rounded-md overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
                  >
                    <Image
                      removeWrapper
                      alt="Card background"
                      className="z-0 w-full h-full object-cover"
                      src={require("../../../Img/BSA.jpg")}
                    />
                    <CardFooter className="absolute bg-[#72539b]/80 bottom-0 flex-wrap z-10 rounded-md">
                      <div className="flex flex-grow gap-2 items-center">
                        <div className="flex flex-col">
                          <p className=" text-medium text-white/90">
                            idagfsdkjfnsda
                          </p>
                        </div>
                      </div>
                      <button
                        // onClick={() => handleEditClick(subj)}
                        className="bg-[#FFDE59] opacity-100 rounded-lg p-3 py-2 text-black hover:bg-[#fcd84b] text-sm"
                      >
                        Edit
                      </button>
                    </CardFooter>{" "}
                  </Card>{" "}
                </Skeleton>
                <Skeleton isLoaded={false} className=" w-full ">
                  <Card
                    shadow="sm"
                    className=" rounded-md overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
                  >
                    <Image
                      removeWrapper
                      alt="Card background"
                      className="z-0 w-full h-full object-cover"
                      src={require("../../../Img/BSA.jpg")}
                    />
                    <CardFooter className="absolute bg-[#72539b]/80 bottom-0 flex-wrap z-10 rounded-md">
                      <div className="flex flex-grow gap-2 items-center">
                        <div className="flex flex-col">
                          <p className=" text-medium text-white/90">
                            idagfsdkjfnsda
                          </p>
                        </div>
                      </div>
                      <button
                        // onClick={() => handleEditClick(subj)}
                        className="bg-[#FFDE59] opacity-100 rounded-lg p-3 py-2 text-black hover:bg-[#fcd84b] text-sm"
                      >
                        Edit
                      </button>
                    </CardFooter>{" "}
                  </Card>{" "}
                </Skeleton>
                <Skeleton isLoaded={false} className=" w-full ">
                  <Card
                    shadow="sm"
                    className=" rounded-md overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
                  >
                    <Image
                      removeWrapper
                      alt="Card background"
                      className="z-0 w-full h-full object-cover"
                      src={require("../../../Img/BSA.jpg")}
                    />
                    <CardFooter className="absolute bg-[#72539b]/80 bottom-0 flex-wrap z-10 rounded-md">
                      <div className="flex flex-grow gap-2 items-center">
                        <div className="flex flex-col">
                          <p className=" text-medium text-white/90">
                            idagfsdkjfnsda
                          </p>
                        </div>
                      </div>
                      <button
                        // onClick={() => handleEditClick(subj)}
                        className="bg-[#FFDE59] opacity-100 rounded-lg p-3 py-2 text-black hover:bg-[#fcd84b] text-sm"
                      >
                        Edit
                      </button>
                    </CardFooter>{" "}
                  </Card>{" "}
                </Skeleton>
                <Skeleton isLoaded={false} className=" w-full ">
                  <Card
                    shadow="sm"
                    className=" rounded-md overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
                  >
                    <Image
                      removeWrapper
                      alt="Card background"
                      className="z-0 w-full h-full object-cover"
                      src={require("../../../Img/BSA.jpg")}
                    />
                    <CardFooter className="absolute bg-[#72539b]/80 bottom-0 flex-wrap z-10 rounded-md">
                      <div className="flex flex-grow gap-2 items-center">
                        <div className="flex flex-col">
                          <p className=" text-medium text-white/90">
                            idagfsdkjfnsda
                          </p>
                        </div>
                      </div>
                      <button
                        // onClick={() => handleEditClick(subj)}
                        className="bg-[#FFDE59] opacity-100 rounded-lg p-3 py-2 text-black hover:bg-[#fcd84b] text-sm"
                      >
                        Edit
                      </button>
                    </CardFooter>{" "}
                  </Card>{" "}
                </Skeleton>
                <Skeleton isLoaded={false} className=" w-full ">
                  <Card
                    shadow="sm"
                    className=" rounded-md overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
                  >
                    <Image
                      removeWrapper
                      alt="Card background"
                      className="z-0 w-full h-full object-cover"
                      src={require("../../../Img/BSA.jpg")}
                    />
                    <CardFooter className="absolute bg-[#72539b]/80 bottom-0 flex-wrap z-10 rounded-md">
                      <div className="flex flex-grow gap-2 items-center">
                        <div className="flex flex-col">
                          <p className=" text-medium text-white/90">
                            idagfsdkjfnsda
                          </p>
                        </div>
                      </div>
                      <button
                        // onClick={() => handleEditClick(subj)}
                        className="bg-[#FFDE59] opacity-100 rounded-lg p-3 py-2 text-black hover:bg-[#fcd84b] text-sm"
                      >
                        Edit
                      </button>
                    </CardFooter>{" "}
                  </Card>{" "}
                </Skeleton>
                <Skeleton isLoaded={false} className=" w-full ">
                  <Card
                    shadow="sm"
                    className=" rounded-md overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
                  >
                    <Image
                      removeWrapper
                      alt="Card background"
                      className="z-0 w-full h-full object-cover"
                      src={require("../../../Img/BSA.jpg")}
                    />
                    <CardFooter className="absolute bg-[#72539b]/80 bottom-0 flex-wrap z-10 rounded-md">
                      <div className="flex flex-grow gap-2 items-center">
                        <div className="flex flex-col">
                          <p className=" text-medium text-white/90">
                            idagfsdkjfnsda
                          </p>
                        </div>
                      </div>
                      <button
                        // onClick={() => handleEditClick(subj)}
                        className="bg-[#FFDE59] opacity-100 rounded-lg p-3 py-2 text-black hover:bg-[#fcd84b] text-sm"
                      >
                        Edit
                      </button>
                    </CardFooter>{" "}
                  </Card>{" "}
                </Skeleton>
                <Skeleton isLoaded={false} className=" w-full ">
                  <Card
                    shadow="sm"
                    className=" rounded-md overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
                  >
                    <Image
                      removeWrapper
                      alt="Card background"
                      className="z-0 w-full h-full object-cover"
                      src={require("../../../Img/BSA.jpg")}
                    />
                    <CardFooter className="absolute bg-[#72539b]/80 bottom-0 flex-wrap z-10 rounded-md">
                      <div className="flex flex-grow gap-2 items-center">
                        <div className="flex flex-col">
                          <p className=" text-medium text-white/90">
                            idagfsdkjfnsda
                          </p>
                        </div>
                      </div>
                      <button
                        // onClick={() => handleEditClick(subj)}
                        className="bg-[#FFDE59] opacity-100 rounded-lg p-3 py-2 text-black hover:bg-[#fcd84b] text-sm"
                      >
                        Edit
                      </button>
                    </CardFooter>{" "}
                  </Card>{" "}
                </Skeleton>
                <Skeleton isLoaded={false} className=" w-full ">
                  <Card
                    shadow="sm"
                    className=" rounded-md overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
                  >
                    <Image
                      removeWrapper
                      alt="Card background"
                      className="z-0 w-full h-full object-cover"
                      src={require("../../../Img/BSA.jpg")}
                    />
                    <CardFooter className="absolute bg-[#72539b]/80 bottom-0 flex-wrap z-10 rounded-md">
                      <div className="flex flex-grow gap-2 items-center">
                        <div className="flex flex-col">
                          <p className=" text-medium text-white/90">
                            idagfsdkjfnsda
                          </p>
                        </div>
                      </div>
                      <button
                        // onClick={() => handleEditClick(subj)}
                        className="bg-[#FFDE59] opacity-100 rounded-lg p-3 py-2 text-black hover:bg-[#fcd84b] text-sm"
                      >
                        Edit
                      </button>
                    </CardFooter>{" "}
                  </Card>{" "}
                </Skeleton>
              </>
            ) : (
              <div className="absolute flex h-full w-full justify-center items-center overflow-hidden">
                No Courses yet
              </div>
            )}
            <CourseDetails
              course={SelectedCourse}
              refresher={() => setisrefresh((prev) => !prev)}
              lrefresher={lightrefreshed}
            />
          </div>
        </div>
        <div className="flex-initial flex justify-center">
          <Pagination
            isDisabled={isLoading}
            className="flex-initial p-4"
            loop
            showControls
            color="secondary"
            total={Pages.total}
            initialPage={1}
            page={Pages.current}
            onChange={(page) =>
              setPages((prevPages) => ({ ...prevPages, current: page }))
            }
          />
        </div>
      </div>
    </div>
  );
}
