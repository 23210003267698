import CurrentID from "./renders/CurrentIDrender";
import { birthdateformat } from "../../../../utils/helpers/stringformat";
import { Chip } from "@nextui-org/react";

export default function VerifyStep({ user }) {
  //   [
  //     "Activity",
  //     "Work Period 1",
  //     "Reading/Language"
  // ]
  return (
    user && (
      <div className="flex flex-col w-full">
        {/* Name */}
        {user.Role === "student" && (
          <>
            {user && user.courseid ? (
              <div className="py-3 px-4 flex justify-between bg-secondary-200 capitalize w-full min-h-[45px] border-gray-200 rounded-lg">
                <span>{user.coursename}</span>
                <span>{user.sectionname}</span>
              </div>
            ) : (
              <span className="py-3 px-4 bg-secondary-200 block capitalize w-full min-h-[45px] border-gray-200 rounded-lg">
                Not Enrolled
              </span>
            )}
          </>
        )}

        <div className="flex">
          <div className="flex-1 m-1">
            <label
              htmlFor="Firstname"
              className="block mb-2 text-md text-gray-700 font-medium dark:text-white"
            >
              First name
            </label>
            <span className="py-3 px-4 bg-slate-100 block capitalize w-full min-h-[45px] border-gray-200 rounded-lg">
              {user.Firstname}
            </span>
          </div>
          <div className="flex-1 m-1">
            <label
              htmlFor="Firstname"
              className="block mb-2 text-md text-gray-700 font-medium dark:text-white"
            >
              Middle name
            </label>
            <span className="py-3 px-4 bg-slate-100 block capitalize w-full min-h-[45px] border-gray-200 rounded-lg">
              {user.Middlename}
            </span>
          </div>
          <div className="flex-1 m-1">
            <label
              htmlFor="Firstname"
              className="block mb-2 text-md text-gray-700 font-medium dark:text-white"
            >
              Last name
            </label>
            <span className="py-3 px-4 bg-slate-100 block capitalize w-full min-h-[45px] border-gray-200 rounded-lg">
              {user.Lastname}
            </span>
          </div>
        </div>
        <div className="flex">
          <div className="flex-1 m-1">
            <label
              htmlFor="birthdate"
              className="block mb-2 text-md text-gray-700 font-medium dark:text-white"
            >
              Birth date
            </label>
            <span className="py-3 px-4 bg-slate-100 block capitalize w-full min-h-[45px] border-gray-200 rounded-lg">
              {birthdateformat(user.Birthdate)}
            </span>
          </div>
          <div className=" flex-initial m-1">
            <label
              htmlFor="age"
              className="block mb-2 text-md text-gray-700 font-medium dark:text-white"
            >
              Age
            </label>
            <span className="py-3 px-4 bg-slate-100 block capitalize w-full min-h-[45px] border-gray-200 rounded-lg">
              {user.Age}
            </span>
          </div>
          <div className="flex-1 m-1">
            <label
              htmlFor="gender"
              className="block mb-2 text-md text-gray-700 font-medium dark:text-white"
            >
              Gender
            </label>
            <span className="py-3 px-4 bg-slate-100 block capitalize w-full min-h-[45px] border-gray-200 rounded-lg">
              {user.Gender}
            </span>
          </div>
        </div>
        <span>Address</span>
        <div className="flex">
          <div className="flex m-1">
            <div className="flex-1">
              <label
                htmlFor="block"
                className="block mb-2 text-md text-gray-700 font-medium dark:text-white"
              >
                Block
              </label>
              <span className="py-3 px-4 bg-slate-100 block capitalize w-full min-h-[45px] border-gray-200 rounded-lg">
                {user.Address.Block}
              </span>
            </div>
          </div>
          <div className="flex m-1">
            <div className="flex-1">
              <label
                htmlFor="lot"
                className="block mb-2 text-md text-gray-700 font-medium dark:text-white"
              >
                Lot
              </label>
              <span className="py-3 px-4 bg-slate-100 block capitalize w-full min-h-[45px] border-gray-200 rounded-lg">
                {user.Address.Lot}
              </span>
            </div>
          </div>
          <div className="flex m-1 flex-1">
            <div className="flex-1">
              <label
                htmlFor="street"
                className="block mb-2 text-md text-gray-700 font-medium dark:text-white"
              >
                Street
              </label>
              <span className="py-3 px-4 bg-slate-100 block capitalize w-full min-h-[45px] border-gray-200 rounded-lg">
                {user.Address.Street}
              </span>
            </div>
          </div>
          <div className="flex m-1 flex-1">
            <div className="flex-1">
              <label
                htmlFor="subdivision"
                className="block mb-2 text-md text-gray-700 font-medium dark:text-white"
              >
                Subdivision
              </label>
              <span className="py-3 px-4 bg-slate-100 block capitalize w-full min-h-[45px] border-gray-200 rounded-lg">
                {user.Address.Subd}
              </span>
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="flex-1 m-1">
            <label
              htmlFor="barangay"
              className="block mb-2 text-md text-gray-700 font-medium dark:text-white"
            >
              Barangay
            </label>
            <span className="py-3 px-4 bg-slate-100 block capitalize w-full min-h-[45px] border-gray-200 rounded-lg">
              {user.Address.Barangay}
            </span>
          </div>
          <div className="flex-1 m-1">
            <label
              htmlFor="city"
              className="block mb-2 text-md text-gray-700 font-medium dark:text-white"
            >
              City
            </label>
            <span className="py-3 px-4 overflow-auto break-keep bg-slate-100 block capitalize w-full min-h-[45px] border-gray-200 rounded-lg">
              {user.Address.City}
            </span>
          </div>
          <div className="flex-1 m-1">
            <label
              htmlFor="country"
              className="block mb-2 text-md text-gray-700 font-medium dark:text-white"
            >
              Country
            </label>
            <span className="py-3 px-4 wor bg-slate-100 block capitalize w-full min-h-[45px] border-gray-200 rounded-lg">
              {user.Address.Country}
            </span>
          </div>
        </div>
        <span>Account Information</span>
        <div className="flex">
          <div className="flex-1 m-1">
            <label
              htmlFor="ID"
              className="block mb-2 text-md text-gray-700 font-medium dark:text-white"
            >
              ID
            </label>
            <CurrentID ID={user.ID} />
          </div>
          {user.Department && (
            <div className="flex-1 m-1">
              <label
                htmlFor="ID"
                className="block mb-2 text-md text-gray-700 font-medium dark:text-white"
              >
                Department
              </label>

              <span className="py-3 px-4 wor bg-slate-100 block capitalize w-full min-h-[45px] border-gray-200 rounded-lg">
                {user.Department === "Pre"
                  ? "Pre-School"
                  : user.Department === "Elem"
                  ? "Elementary"
                  : user.Department === "JHS"
                  ? "Junior High-School"
                  : user.Department === "SHS"
                  ? "Senior High-School"
                  : user.Department}
              </span>
            </div>
          )}
          {user.HoursPerWeek && (
            <div className="flex-1 m-1">
              <label
                htmlFor="ID"
                className="block mb-2 text-md text-gray-700 font-medium dark:text-white"
              >
                Teaching Hours per Week
              </label>
              <span className="py-3 px-4 wor bg-slate-100 block capitalize w-full min-h-[45px] border-gray-200 rounded-lg">
                {user.HoursPerWeek}
              </span>
            </div>
          )}
        </div>
        {user.Role === "student" && (
          <div className="flex">
            <div className="flex-1 m-1">
              <label
                htmlFor="lrn"
                className="block mb-2 text-md text-gray-700 font-medium dark:text-white"
              >
                LRN ID
              </label>
              <span className="py-3 px-4 wor bg-slate-100 block capitalize w-full min-h-[45px] border-gray-200 rounded-lg">
                {user.LRNID}
              </span>
            </div>
          </div>
        )}

        <div className="flex">
          <div className="flex-1 m-1">
            <label
              htmlFor="email"
              className="block mb-2 text-md text-gray-700 font-medium dark:text-white"
            >
              Email
            </label>
            <span className="py-3 px-4 wor bg-slate-100 block w-full min-h-[45px] border-gray-200 rounded-lg">
              {user.Email}
            </span>
          </div>
          <div className="flex-1 m-1">
            <label
              htmlFor="role"
              className="block mb-2 text-md text-gray-700 font-medium dark:text-white"
            >
              Role
            </label>
            <span className="py-3 px-4 wor bg-slate-100 block capitalize w-full min-h-[45px] border-gray-200 rounded-lg">
              {user.Role}
            </span>
          </div>
        </div>
        {user.Role === "teacher" && (
          <div className=" flex flex-col">
            <div className="w-full p-2 flex ">
              <div className="flex-nowrap flex whitespace-nowrap p-2">
                Preferred Subjects:
              </div>
              <div className="py-3 px-4 bg-slate-100 w-full flex min-h-[45px] gap-2 flex-wrap border-gray-200 rounded-lg">
                {user.subj &&
                  user.subj.length > 0 &&
                  user.subj.map((subj, index) => (
                    <Chip
                      key={index}
                      variant="shadow"
                      className="bg-secondary text-white"
                    >
                      {subj}
                    </Chip>
                  ))}
              </div>
            </div>
            <div className="w-full p-2 flex">
              <div className="flex-nowrap flex whitespace-nowrap p-2">
                Teached Subjects:
              </div>
              <div className="py-3 px-4 bg-slate-100 w-full flex min-h-[45px] gap-2 flex-wrap border-gray-200 rounded-lg">
                {user.teachedSubjects &&
                  user.teachedSubjects.length > 0 &&
                  user.teachedSubjects.map((subj, index) => (
                    <Chip
                      key={`subjTeached${index}`}
                      variant="shadow"
                      className="bg-gradient-to-br from-indigo-500 to-pink-500 text-white"
                    >
                      {subj.subject}
                    </Chip>
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
    )
  );
}
