import { useEffect, useState } from "react";
import SubjectInfo from "./1SubjectInfo";
import SubjectTime from "./2SubjectTime";
import { Spinner } from "@nextui-org/react";

export default function SubjectAddForm({ Validator, pushData, editData }) {
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mainError, setMainError] = useState("");
  const [isValid1, setIsValid1] = useState(false);
  const [isValid2, setIsValid2] = useState(false);
  const [step, setStep] = useState(1);

  const [subjectData, setSubjectData] = useState({});

  const [subjEditData, setSubjEditData] = useState(editData);

  useEffect(() => {
   
  }, [subjEditData]);

  useEffect(() => {
    require("preline/preline");
  }, [editData, Validator]);

  useEffect(() => {
    if (window.HSStaticMethods) {
      window.HSStaticMethods.autoInit();
    }
  }, [editData, Validator]);

  useEffect(() => {
    pushData(subjectData);
  }, [subjectData]);

  useEffect(() => {
    Validator(isValid1 && isValid2);
  }, [isValid1, isValid2]);

  useEffect(() => {
    
  }, [step]);

  return (
    <div data-hs-stepper="" className=" space-y-2">
      <ul class="relative flex flex-row gap-x-2">
        <li
          class={`flex items-center gap-x-2 shrink basis-0 flex-1 group ${
            step === 1 ? "active" : ""
          }`}
          data-hs-stepper-nav-item='{"index": 1}'
        >
          <span class="min-w-7 min-h-7 group inline-flex items-center text-xs align-middle focus:outline-none disabled:opacity-50 disabled:pointer-events-none">
            <span class="size-7 flex justify-center items-center flex-shrink-0 bg-gray-100 font-medium text-gray-800 rounded-full group-focus:bg-gray-200 hs-stepper-active:bg-secondary hs-stepper-active:text-white hs-stepper-success:bg-secondary hs-stepper-success:text-white hs-stepper-completed:bg-teal-500 hs-stepper-completed:group-focus:bg-teal-600 dark:bg-neutral-700 dark:text-white dark:group-focus:bg-gray-600 dark:hs-stepper-active:bg-blue-500 dark:hs-stepper-success:bg-blue-500 dark:hs-stepper-completed:bg-teal-500 dark:hs-stepper-completed:group-focus:bg-teal-600">
              <span class="hs-stepper-success:hidden hs-stepper-completed:hidden">
                1
              </span>
              <svg
                class="hidden flex-shrink-0 size-3 hs-stepper-success:block"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </span>
            <span class="ms-2 text-sm font-medium text-gray-800 group-focus:text-gray-500 dark:text-white dark:group-focus:text-gray-400">
              Info
            </span>
          </span>
          <div class="w-full h-px flex-1 bg-gray-200 group-last:hidden hs-stepper-success:bg-secondary hs-stepper-completed:bg-secondary-600 dark:bg-neutral-700 dark:hs-stepper-success:bg-blue-500 dark:hs-stepper-completed:bg-teal-500"></div>
        </li>
        <li
          class={`flex items-center gap-x-2 shrink basis-0 flex-1 group ${
            step === 2 ? "active" : ""
          }`}
          data-hs-stepper-nav-item='{"index": 2}'
        >
          <span class="min-w-7 min-h-7 group inline-flex items-center text-xs align-middle focus:outline-none disabled:opacity-50 disabled:pointer-events-none">
            <span class="size-7 flex justify-center items-center flex-shrink-0 bg-gray-100 font-medium text-gray-800 rounded-full group-focus:bg-gray-200 hs-stepper-active:bg-secondary hs-stepper-active:text-white hs-stepper-success:bg-secondary hs-stepper-success:text-white hs-stepper-completed:bg-teal-500 hs-stepper-completed:group-focus:bg-teal-600 dark:bg-neutral-700 dark:text-white dark:group-focus:bg-gray-600 dark:hs-stepper-active:bg-blue-500 dark:hs-stepper-success:bg-blue-500 dark:hs-stepper-completed:bg-teal-500 dark:hs-stepper-completed:group-focus:bg-teal-600">
              <span class="hs-stepper-success:hidden hs-stepper-completed:hidden">
                2
              </span>
              <svg
                class="hidden flex-shrink-0 size-3 hs-stepper-success:block"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </span>
            <span class="ms-2 text-sm font-medium text-gray-800 group-focus:text-gray-500 dark:text-white dark:group-focus:text-gray-400">
              Subject Time
            </span>
          </span>
          <div class="w-full h-px flex-1 bg-gray-200 group-last:hidden hs-stepper-success:bg-secondary hs-stepper-completed:bg-secondary-600 dark:bg-neutral-700 dark:hs-stepper-success:bg-blue-500 dark:hs-stepper-completed:bg-teal-500"></div>
        </li>
      </ul>
      <div class="mt-2">
        <div
          data-hs-stepper-content-item='{"index": 1}'
          style={{ display: step === 1 ? "block" : "none" }}
        >
          <div class="p-4 max-h-52 overflow-auto flex justify-center items-center dark:bg-neutral-800 dark:border-neutral-700">
            <SubjectInfo
              Valid={setIsValid1}
              Getdata={subjectData}
              pushData={setSubjectData}
              EditData={subjEditData}
            />
          </div>
        </div>
        <div
          data-hs-stepper-content-item='{"index": 2}'
          style={{ display: step === 2 ? "block" : "none" }}
        >
          <div class="p-2 flex justify-center items-center dark:bg-neutral-800 dark:border-neutral-700">
            <SubjectTime
              Valid={setIsValid2}
              Getdata={subjectData}
              EditData={subjEditData}
              pushData={setSubjectData}
            />
          </div>
        </div>
        <div data-hs-stepper-content-item='{"isFinal": true}'>
          <div class="p-4 h-48 flex justify-center items-center dark:bg-neutral-800 dark:border-neutral-700">
            <Spinner size="lg" color="secondary">
              Saving New Subject
            </Spinner>
          </div>
        </div>
        <div class="mt-2 flex justify-between items-center mb-2 gap-x-2">
          <button
            type="button"
            class="py-2 px-3 inline-flex items-center gap-x-1 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
            data-hs-stepper-back-btn=""
            onClick={() => {
              setStep(step - 1);
            }}
            disabled={step === 1}
          >
            <svg
              class="flex-shrink-0 size-4"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="m15 18-6-6 6-6"></path>
            </svg>
            Back
          </button>
          <button
            type="button"
            class={`py-2 px-3 inline-flex items-center gap-x-1 text-sm font-semibold rounded-lg border border-transparent bg-secondary-500 text-white hover:bg-secondary-600 disabled:opacity-50 disabled:pointer-events-none 
             
            `}
            data-hs-stepper-next-btn=""
            onClick={() => {
              if (step < 2) {
                setStep(step + 1);
              }
            }}
            disabled={!isValid1}
          >
            Next
            <svg
              class="flex-shrink-0 size-4"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="m9 18 6-6-6-6"></path>
            </svg>
          </button>
          <button
            type="button"
            class={`py-2 px-3 inline-flex items-center gap-x-1 text-sm font-semibold rounded-lg border border-transparent bg-secondary-500 text-white hover:bg-secondary-600 disabled:opacity-50 disabled:pointer-events-none 
                ${step !== 2 && "hidden"}
            `}
            data-hs-stepper-finish-btn=""
            onClick={() => {
              // handle finish action here
              setStep(0);
              document.querySelector("#AddNewSubjectBtn").click();
            }}
            disabled={!isValid1 || !isValid2}
          >
            Finish
            <svg
              class="flex-shrink-0 size-4"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="m9 18 6-6-6-6"></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}
