import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Checkbox,
  Input,
  Link
} from "@nextui-org/react";
import { useAuth } from "../../../../utils/AppwriteConfig/AuthContext";
import axios from "axios";

export default function NewTodo({ reload }) {
  const { server, user } = useAuth();

  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const [data, setdata] = useState({
    toDoTitle: "",
    userCredentials: user?.$id
  });
  const [isLoading, setisLoading] = useState(false);
  const [Errors, setErrors] = useState({});

  useEffect(() => {

  }, [data]);

  let errors = {};

  const validate = () => {
    // Validate toDoTitle
    if (!data.toDoTitle.trim()) {
      errors.toDoTitle = `Title is required`;
    } else if (data.toDoTitle.length > 50) {
      errors.toDoTitle = `Title is too long`;
    }

    setErrors(errors);
  };

  useEffect(() => {
    validate();
  }, [data]);

  const handleAdd = async (e) => {
    e.preventDefault();
    validate();
    if (Object.keys(errors).length === 0) {
      setisLoading(true);
      const response = await axios.post(`${server}/v1/todos/new`, data);
     
      setisLoading(false);
      setdata({
        ...data,
        toDoTitle: ""
      });
      reload();
      onClose();
    } else {
      console.log(errors);
      // Optionally, you can handle errors in UI or perform other actions
    }
    // Additional logic for handling add action
  };

  return (
    <>
      <Button
        isIconOnly
        onPress={onOpen}
        className="bg-[#FFDE59] ml-1 p-2 py-3 text-sm rounded-lg text-black font-semibold hover:bg-[#fcd84b] overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-plus"
          viewBox="0 0 16 16"
        >
          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
        </svg>
      </Button>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange} placement="top-center">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Add new To Do
              </ModalHeader>
              <ModalBody>
                <form className="w-full flex flex-col">
                  <>
                    <label
                      for={`Todoname`}
                      className="block text-sm mb-2 dark:text-white font-medium"
                    >
                      Title
                    </label>
                    <input
                      type="text"
                      name={`Todoname`}
                      id={`Todoname`}
                      className={`py-2 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
                        Errors.toDoTitle == null
                          ? "border-slate-200"
                          : "border-red-500"
                      }`}
                      required
                      defaultValue={data.toDoTitle}
                      onChange={(e) =>
                        setdata({
                          ...data,
                          toDoTitle: e.target.value
                        })
                      }
                    />
                    {Errors.toDoTitle && (
                      <span className="text-red-500 text-sm mt-1">
                        {Errors.toDoTitle}
                      </span>
                    )}
                  </>
                  <button
                    className="hidden"
                    id="addtodo"
                    type="submit"
                    onClick={handleAdd}
                  >
                    gg
                  </button>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="flat" onPress={onClose}>
                  Close
                </Button>
                {isLoading ? (
                  <Button
                    isLoading
                    color="secondary"
                    spinner={
                      <svg
                        className="animate-spin h-5 w-5 text-current"
                        fill="none"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          fill="currentColor"
                        />
                      </svg>
                    }
                  >
                    Loading
                  </Button>
                ) : (
                  <Button
                    color="secondary"
                    onPress={() => {
                      document.querySelector("#addtodo").click();
                    }}
                  >
                    Add To Do
                  </Button>
                )}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
