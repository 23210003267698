import {
  Checkbox,
  CheckboxGroup,
  cn,
  Image,
  Skeleton
} from "@nextui-org/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";

export default function EditSubjects({
  currentCourse,
  subjects,
  setParent,
  currentSem
}) {
  const { DataServer } = useAuth();
  const [isLoading, setisLoading] = useState(true);
  const [MainError, setMainError] = useState();
  //PreferredSUbejcts
  const [selectedID, setSelectedID] = useState([]);
  const [selected, setSelected] = useState(
    subjects
      ? subjects.map((subject) => {
          return { type: "old", id: subject };
        })
      : []
  );
  const [newselected, setnewSelected] = useState([]);
  const [selectednames, setSelectednames] = useState([]);
  const [removedSubjects, setRemovedSubjects] = useState([]);
  const [Subjects, setSubjects] = useState({
    total: subjects?.length,
    documents: [...subjects]
  });

  const [subjectSearch, setsubjectSearch] = useState("");
  const [search, setSearch] = useState("");
  useEffect(() => {
    console.log("lahatbatoh: ", selected);
  }, [selected]);

  useEffect(() => {
    setSelected(
      subjects.map((subject) => {
        return { type: "old", id: subject };
      })
    );
  }, [subjects]);

  //update parent
  useEffect(() => {
    setParent(newselected, removedSubjects, isLoading);
  }, [newselected, removedSubjects, isLoading, setParent]);

  // used to prevent multiple request, Itll only request after the user is done typing

  useEffect(() => {
    setisLoading(true);
    const timerId = setTimeout(() => {
      setsubjectSearch(search);
    }, 500);

    // Clean up the timer
    return () => clearTimeout(timerId);
  }, [search]);

  //Get All subjects
  useEffect(() => {
    async function fetchsubj() {
      try {
        setisLoading(true);
        const response = await axios.get(
          `${DataServer}/course/subject/toadd/v2`,
          {
            params: {
              search: subjectSearch,
              sem: currentSem,
              // course: currentCourse,
              selected:
                selected && selected.length > 0
                  ? selected.map((subj) => {
                      return subj.id;
                    })
                  : []
            }
          }
        );
        console.log(selected);
        console.log(response.data);
        setSubjects(response.data);
      } catch (error) {
        // Handle any errors
        setMainError(error);
      } finally {
        setisLoading(false);
      }
    }
    if (selected) fetchsubj();
  }, [subjectSearch, selected, currentSem]);

  // Handle deselecting preferred subjects
  const handleDeselect = (subjectId, name) => {
    const updatedSelected = selected.filter((subj) => subj.id !== subjectId);
    const updatedSelectednames = selectednames.filter((id) => id !== name);
    const removedSubject = selected.find((subj) => subj.id === subjectId);
    setRemovedSubjects([...removedSubjects, removedSubject]);
    setSelected(updatedSelected);
    setSelectednames(updatedSelectednames);
  };

  // Handle reselecting subjects
  const handleSelect = (subjectId, name) => {
    const updatedRemovedSubjects = removedSubjects.filter(
      (subj) => subj.id !== subjectId
    );
    const type = selectedID.includes(subjectId) ? "old" : "new";
    setSelected([...selected, { type: type, id: subjectId }]);
    console.log(selectednames);
    setSelectednames([...selectednames, name]);
    setRemovedSubjects(updatedRemovedSubjects);
  };

  return (
    <div className=" w-full rounded-lg lg:px-6 lg:py-2 bg-gray-50 min-h-[400px] ">
      <div className="flex-1 h-auto lg:p-4 ">
        <div className="flex flex-col flex-1 pt-2 space-y-4 justify-center items-center h-full ">
          <Image
            isZoomed
            className=" size-40 "
            alt="NextUI Fruit Image with Zoom"
            src={require("../../../../../Img/subject.jpg")}
          />

          <div className="max-w-[600px] w-full h-auto relative">
            <div class="relative">
              <div class="relative">
                <div class="absolute inset-y-0 start-0 flex items-center pointer-events-none z-20 ps-3.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-book"
                    viewBox="0 0 16 16"
                  >
                    <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783" />
                  </svg>
                </div>
                <input
                  class="py-3 ps-10 pe-4 block w-full border-gray-200 rounded-lg text-sm focus:border-secondary focus:ring-secondary disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                  type="text"
                  role="combobox"
                  aria-expanded="true"
                  placeholder="search Subjects"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </div>
              <div className=" mt-2 top-full left-0 w-full bg-white border border-gray-200 rounded-lg dark:bg-neutral-800 dark:border-neutral-700  z-50">
                <div className="flex flex-col gap-1 w-full  rounded-b-lg overflow-hidden overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500">
                  {Subjects && Subjects?.documents.length > 0 ? (
                    <CheckboxGroup
                      color="warning"
                      value={selected.map((subj) => {
                        return subj.id;
                      })}
                      onValueChange={(value) => {
                        setSelected(value);
                        setRemovedSubjects([]);
                      }}
                      className="flex flex-row w-full justify-center items-stretch  overflow-auto max-h-[300px] flex-wrap"
                    >
                      {Subjects &&
                        Subjects?.documents &&
                        Subjects?.documents.map((subj) => (
                          <Skeleton
                            isLoaded={!isLoading}
                            className="rounded-lg flex bg-secondary min-w-[250px] w-full"
                          >
                            <Checkbox
                              aria-label={subj.$id}
                              classNames={{
                                base: cn(
                                  "inline-flex  w-full bg-content1 m-0",
                                  "hover:bg-content2 items-center justify-start",
                                  "cursor-pointer rounded-lg gap-2 p-4 border-2 border-transparent",
                                  "data-[selected=true]:border-secondary"
                                ),
                                label: "w-full"
                              }}
                              key={subj.$id}
                              value={subj.$id}
                              checked={
                                selected &&
                                selected?.length > 0 &&
                                selected.map((subj) => {
                                  return subj.id;
                                })
                              }
                              onChange={() => {
                                if (
                                  selected &&
                                  selected?.length > 0 &&
                                  selected
                                    .map((subj) => {
                                      return subj.id;
                                    })
                                    .includes(subj.$id)
                                ) {
                                  handleDeselect(subj.$id, subj.subjectName);
                                  // const updatednewSelected =
                                  //   selected.filter(
                                  //     (newsubj) =>
                                  //       newsubj.$id !== subj.$id
                                  //   );
                                  // setnewSelected(updatednewSelected);
                                } else {
                                  setnewSelected([...newselected, subj]);
                                  handleSelect(subj.$id, subj.subjectName);
                                }
                                setSearch("");
                                setsubjectSearch("");
                              }}
                            >
                              <div className="w-full flex justify-between gap-2">
                                <div className="flex flex-col">
                                  <div className=" text-medium">
                                    {subj.subjectName}
                                  </div>
                                  <span className=" text-xs">
                                    {subj.subjectDescription}
                                  </span>
                                </div>
                              </div>
                            </Checkbox>
                          </Skeleton>
                        ))}
                    </CheckboxGroup>
                  ) : (
                    <Skeleton isLoaded={!isLoading}>
                      <div className=" flex justify-center items-center text-medium p-6">
                        No Preferred Subject yet
                      </div>
                    </Skeleton>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
