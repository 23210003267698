import React, { useState } from "react";
import { Card, CardHeader, CardBody, Image } from "@nextui-org/react";
import EditAccount from "../actions/EditAccount";
import EditBio from "../actions/EditBio";
import EditPassword from "../actions/EditPassword";
import EditPicture from "../actions/EditPicture";

export default function EditDetails() {
  const [Edit, SetEdit] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [Errors, SetErrors] = useState({});
  const [MainError, setMainError] = useState();

  const [renderedrole, setrenderedrole] = useState();
  const [Userinfo, SetUserInfo] = useState({});
  const [SelectedTab, setSelectedTab] = useState("Info");

  return (
    <div className=" h-full w-full flex flex-col">
      <span className="flex-initial text-4xl font-semibold text-[#72539b]">
        Account Details
      </span>
      <div className="flex-1 p-2 flex w-full h-full flex-col sm:flex-row  overflow-auto">
        <div className="flex-initial  overflow-auto">
          <div className="  flex-1 max-h-[310px] overflow-auto">
            <div className="  whitespace-nowrap mt-2 ">
              <button // Using optional chaining here to guard against undefined doc
                type="button"
                className={`hs-tab-active:border sm:w-full  w-auto  ${
                  SelectedTab === "Info"
                    ? "border-secondary-500 text-secondary-500 hs-tab-active:text-secondary-600 dark:hs-tab-active:text-secondary-600"
                    : "border-transparent text-gray-500 hover:text-secondary-600 focus:text-secondary-600 dark:text-gray-400 dark:hover:text-blue-500"
                } py-1 pe-4  items-center sm:justify-start gap-x-2 border-b-2 sm:border-b-0 sm:border-e-2 text-lg inline-flex sm:flex focus:outline-none disabled:opacity-50 disabled:pointer-events-none`}
                onClick={() => setSelectedTab("Info")}
              >
                Info
              </button>
              <button // Using optional chaining here to guard against undefined doc
                type="button"
                className={`hs-tab-active:border sm:w-full  w-auto  ${
                  SelectedTab === "Bio"
                    ? "border-secondary-500 text-secondary-500 hs-tab-active:text-secondary-600 dark:hs-tab-active:text-secondary-600"
                    : "border-transparent text-gray-500 hover:text-secondary-600 focus:text-secondary-600 dark:text-gray-400 dark:hover:text-blue-500"
                } py-1 pe-4  items-center gap-x-2 border-b-2 sm:border-b-0 sm:border-e-2 text-lg inline-flex sm:flex sm:justify-start focus:outline-none disabled:opacity-50 disabled:pointer-events-none`}
                onClick={() => setSelectedTab("Bio")}
              >
                Bio
              </button>
              <button // Using optional chaining here to guard against undefined doc
                type="button"
                className={`hs-tab-active:border sm:w-full  w-auto  ${
                  SelectedTab === "Picture"
                    ? "border-secondary-500 text-secondary-500 hs-tab-active:text-secondary-600 dark:hs-tab-active:text-secondary-600"
                    : "border-transparent text-gray-500 hover:text-secondary-600 focus:text-secondary-600 dark:text-gray-400 dark:hover:text-blue-500"
                } py-1 pe-4  items-center gap-x-2 border-b-2 sm:border-b-0 sm:border-e-2 text-lg inline-flex sm:flex sm:justify-start focus:outline-none disabled:opacity-50 disabled:pointer-events-none`}
                onClick={() => setSelectedTab("Picture")}
              >
                Picture
              </button>
              <button // Using optional chaining here to guard against undefined doc
                type="button"
                className={`hs-tab-active:border sm:w-full  w-auto  ${
                  SelectedTab === "Password"
                    ? "border-secondary-500 text-secondary-500 hs-tab-active:text-secondary-600 dark:hs-tab-active:text-secondary-600"
                    : "border-transparent text-gray-500 hover:text-secondary-600 focus:text-secondary-600 dark:text-gray-400 dark:hover:text-blue-500"
                } py-1 pe-4  items-center gap-x-2 border-b-2 sm:border-b-0 sm:border-e-2 text-lg inline-flex sm:flex sm:justify-start focus:outline-none disabled:opacity-50 disabled:pointer-events-none`}
                onClick={() => setSelectedTab("Password")}
              >
                Password
              </button>
            </div>
          </div>
        </div>
        <div className="flex-1 pt-2 sm:pt:0 sm:px-3 overflow-hidden">
          {/* Main here */}
          {SelectedTab === "Info" && <EditAccount />}
          {SelectedTab === "Bio" && <EditBio />}
          {SelectedTab === "Password" && <EditPassword />}
          {SelectedTab === "Picture" && <EditPicture />}
          {SelectedTab === "Def" && <div></div>}
        </div>
      </div>
    </div>
  );
}
