import React from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  usePagination
} from "@nextui-org/react";
import { birthdateformat } from "../../utils/helpers/stringformat";
import CurrentID from "../../pages/{Employee}/UserManagment/components/renders/CurrentIDrender";
import { useParams } from "react-router-dom";
export default function Confirmation({ user, save }) {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const { users } = useParams();
  const role = users.slice(0, -1);
  return (
    <>
      <Button
        onPress={onOpen}
        className="bg-[#FFDE59] ml-1 rounded-lg text-black font-semibold hover:bg-[#fcd84b]"
      >
        Save
      </Button>
      <Modal
        className=" font-poppins gap-0"
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        isDismissable={false}
        isKeyboardDismissDisabled={true}
        scrollBehavior="inside"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col pb-2">
                Are all changes correct?
              </ModalHeader>
              <ModalBody className=" text-sm">
                You are about to save all changes that you have made.
              </ModalBody>
              <ModalFooter className="py-2">
                <Button color="danger" variant="light" onPress={onClose}>
                  Cancel
                </Button>
                <Button
                  color="secondary"
                  onPress={onClose}
                  onClick={() => {
                    save();
                  }}
                >
                  Save
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
