import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Spinner,
  Skeleton
} from "@nextui-org/react";
import axios from "axios";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";

export default function AdvisorSubjectSelect({ selectSubject, disable, dept }) {
  const { DataServer } = useAuth();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const [isLoading, setIsLoading] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState({});
  const [subjectSearch, setSubjectSearch] = useState("");

  const [searchQuery, setsearchQuery] = useState("");
  // used to prevent multiple requests, It will only request after the user is done typing
  useEffect(() => {
    setIsLoading(true);
    const timerId = setTimeout(() => {
      setsearchQuery(subjectSearch);
    }, 500);

    // Clean up the timer
    return () => clearTimeout(timerId);
  }, [subjectSearch]);

  useEffect(() => {
    console.log(searchQuery, "g");
  }, [searchQuery]);

  useEffect(() => {
    async function GetSubjects() {
      try {
        setIsLoading(true);
        const response = await axios.get(`${DataServer}/course/subject/same`, {
          params: { dept: dept }
        });
        console.log(response);
        setSubjects(response.data);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    }
    GetSubjects();
  }, []);

  return (
    <>
      <button
        disabled={disable}
        onClick={onOpen}
        className={`${
          disable && "opacity-50 cursor-not-allowed"
        }  w-full text-sm text-white font-medium hover:scale-105 transition-all delay-75 ease-in-out p-2 bg-secondary-400 h-full rounded-md`}
      >
        Select Subject
      </button>
      <Modal
        className=" font-poppins"
        isOpen={isOpen}
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex pb-0 flex-col gap-1">
                Subject of Each Advisor
              </ModalHeader>
              <ModalBody>
                <div className=" bg-[#ededed] rounded-md p-2">
                  <div className="  rounded-md gap-2 flex-col flex w-full h-full p-1 pr-2 max-h-[300px] overflow-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-secondary-500 dark:[&::-webkit-scrollbar-track]:bg-slate-700  dark:[&::-webkit-scrollbar-thumb]:bg-slate-500 ">
                    {isLoading ? (
                      <Skeleton className=" rounded-l bg-secondary-400 w-full h-[200px]"></Skeleton>
                    ) : (
                      <>
                        {" "}
                        {subjects && subjects.length > 0 ? (
                          subjects.map((subj) => (
                            <SubjectFormat
                              key={subj.subjectId}
                              title={subj.subjectName}
                              content={subj.subjectDescription}
                              selectSubject={setSelectedSubject}
                              subjectData={subj}
                              isSelected={
                                selectedSubject.subjectId === subj.subjectId
                              } // Pass selected status
                            />
                          ))
                        ) : (
                          <div>No Same Subjects for each sections</div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className=" pt-0">
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                <Button
                  isDisabled={Object.keys(selectedSubject).length <= 0}
                  color="secondary"
                  onPress={() => {
                    selectSubject(selectedSubject);
                    onClose();
                  }}
                >
                  Select
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );

  function SubjectFormat({
    title,
    content,
    selectSubject,
    subjectData,
    isSelected
  }) {
    return (
      <div
        onClick={() => {
          selectSubject(subjectData);
        }}
        className={`w-full cursor-pointer flex flex-col text-secondary-50 bg-secondary-300 rounded-lg p-3 
          hover:scale-[102%] transition delay-75 ease-in-out
          ${isSelected ? "bg-secondary-600 text-white" : "bg-secondary-300"}`} // Change color if selected
      >
        <div className=" text-medium font-medium text-ellipsis">{title}</div>
        <div className=" text-sm text-ellipsis break-words">{content}</div>
      </div>
    );
  }
}

{
  /* {Object.keys(selectedSubject).length > 0 ? (
                  <div className=" w-full text-white rounded-md flex bg-secondary-400 p-2 justify-between">
                    <span>{selectedSubject.subjectName}</span>
                    <button
                      onClick={() => {
                        setSubjectSearch("");
                        setSelectedSubject({});
                      }}
                    >
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-x"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                      </svg>
                    </button>
                  </div>
                ) : (
                  <div className=" flex flex-col w-full">
                    <div className="flex relative">
                      <input
                        placeholder="Search Subject to Assign"
                        value={subjectSearch}
                        onChange={(event) => {
                          setSubjectSearch(event.target.value);
                        }}
                        type="text"
                        className={`${
                          subjectSearch && "pe-12"
                        } flex-1 text-sm  bg-[#F4F4F5] rounded-md  h-10 focus:ring-0 border-none w-full `}
                      />

                      <button
                        onClick={() => {
                          setSubjectSearch("");
                        }}
                        className={` ${
                          subjectSearch
                            ? " opacity-100 visible"
                            : "opacity-0 invisible"
                        }  px-3 transition-all texbl absolute  right-0 h-10 ease-in-out delay-75  bg-[#F4F4F5] rounded-e-md`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-x"
                          viewBox="0 0 16 16"
                        >
                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                        </svg>
                      </button>
                    </div>
                    {subjectSearch && (
                      <div className=" bg mt-2 bg-slate-200 rounded-md p-2 flex">
                        {isLoading ? (
                          <div className=" w-full flex justify-center pr-2 items-center">
                            <Spinner
                              size="md"
                              color="secondary"
                              title="Loading"
                            />
                          </div>
                        ) : (
                          <div className=" max-h-[250px]  flex flex-col gap-2 p-2  overflow-auto w-full [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-secondary-500 dark:[&::-webkit-scrollbar-track]:bg-slate-700  dark:[&::-webkit-scrollbar-thumb]:bg-slate-500">
                            {subjects && subjects.length > 0 ? (
                              subjects.map((subject) => (
                                <div
                                  onClick={() => {
                                    setSelectedSubject(subject);
                                  }}
                                  key={subject.$id}
                                  className=" w-full p-2 cursor-pointer hover:scale-[102%] transition-transform delay-75 ease-in-out text-white  bg-secondary-400 rounded-md"
                                >
                                  <span className="text-sm font-medium">
                                    {subject.subjectName}
                                  </span>
                                  <p className="pl-2 text-xs">
                                    {subject.subjectDescription}
                                  </p>
                                </div>
                              ))
                            ) : (
                              <div className=" flex w-full justify-center items-center">
                                No Subject named {searchQuery}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )} */
}
