import { useEffect } from "react";
import { DefaultButton } from "./ui/Button";
import { useParams } from "react-router-dom";
import { ProfilePicture } from "./ProfilePictureImg";

export default function Hoverprofile({ cuser }) {
  const { users } = useParams();

  //to render tooltip preline
  useEffect(() => {
    require("preline/preline");
  }, [cuser]);

  useEffect(() => {
    if (window.HSStaticMethods) {
      window.HSStaticMethods.autoInit();
    }
  }, [cuser]);

  return (
    <div className="hs-tooltip md:[--trigger:hover]  [--trigger:none] md:[--placement:right]">
      {cuser && (
        <div className="hs-tooltip-toggle  max-w-full w-full p-3 flex items-center gap-x-3 bg-white  hover:bg-[#f7dff8] rounded-xl shadow-sm dark:bg-slate-900 dark:border-gray-700">
          <ProfilePicture
            key={cuser.$id}
            userId={cuser.$id}
            gender={cuser?.gender}
          />
          <div className="grow">
            <h4 className="font-semibold text-sm  text-gray-800 dark:text-white">
              {cuser?.firstName?.charAt(0).toUpperCase() +
                cuser?.firstName?.slice(1) +
                " " +
                (cuser?.middleName
                  ? cuser?.middleName?.charAt(0).toUpperCase() + ". "
                  : "") +
                cuser?.lastName?.charAt(0).toUpperCase() +
                cuser?.lastName?.slice(1)}
            </h4>
            <p className="text-xs text-gray-800 md:text-gray-500 dark:text-white md:dark:text-gray- 500">
              {cuser?.userCredentials?.Email}
            </p>
          </div>
          <div
            className="hs-tooltip-content border-[#452063] bg-[#f7dff8] hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible hidden opacity-0 transition-opacity inline-block absolute invisible z-10 max-w-xs w-full border  text-start rounded-xl shadow-md after:absolute after:top-0 after:-start-4 after:w-4 after:h-full dark:bg-gray-800 dark:border-gray-700"
            role="tooltip"
            style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}
          >
            <div className="py-3.5 px-4 border-b border-[#452063] dark:border-gray-700">
              <div className="flex items-center gap-x-3">
                {/* <img
                  className="flex-shrink-0  bg-gray-500 h-10 w-10 rounded-full ring-2 ring-white dark:ring-gray-800"
                  src={
                    cuser?.gender === "Male"
                      ? require("../Img/Profile/studentboy.png")
                      : cuser?.gender === "Female"
                      ? require("../Img/Profile/studentgirl.png")
                      : require("../Img/BSA.jpg")
                  }
                  alt="Image Description"
                /> */}
                <ProfilePicture
                  key={cuser.$id}
                  userId={cuser.$id}
                  gender={cuser?.gender}
                />
                <div className="grow">
                  <h4 className="font-semibold flex text-gray-800 dark:text-white">
                    <span className="ms-0.5 whitespace-pre-wrap items-center align-middle gap-x-8.5 py-1.5 px-1.5 rounded-md text-[15px] font-medium  bg-gray-800 text-white dark:bg-white dark:text-gray-800">
                      {cuser && cuser?.userCredentials
                        ? cuser?.userCredentials.Username
                        : cuser?.firstName + " " + cuser?.lastName}
                    </span>
                  </h4>
                </div>
              </div>
            </div>

            <ul className="py-3 px-4 space-y-3">
              <li>
                <div className="inline-flex items-center gap-x-3 text-sm text-gray-800 dark:text-neutral-200">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-person-vcard"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4m4-2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5M9 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 9 8m1 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5" />
                    <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H8.96q.04-.245.04-.5C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1 1 0 0 1 1 12z" />
                  </svg>
                  {cuser?.lrnId} {cuser?.employeeId} {cuser?.teacherId}
                </div>
              </li>

              <li>
                <div className="inline-flex items-center gap-x-3 text-sm text-gray-800 dark:text-neutral-200">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-telephone"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                  </svg>

                  {cuser?.contactNumber}
                </div>
              </li>

              <li>
                <div className="inline-flex items-center gap-x-3 text-sm text-gray-800 dark:text-neutral-200">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-envelope"
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                  </svg>

                  {cuser?.userCredentials?.Email}
                </div>
              </li>
            </ul>

            <div className="py-2 px-4 flex justify-center items-center dark:bg-gray-800">
              <button
                type="button"
                className="py-1.5 px-2 inline-flex items-center bg-gray-800 text-white font-semibold rounded-lg border border-transparent"
                onClick={() => {
                  // Add your click event handler here
                }}
              >
                Documents
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
