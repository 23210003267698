import { useParams } from "react-router-dom";

export default function User() {
  const { users } = useParams();
  return (
    <div class="flex flex-col p-4 animate-pulse">
      <div class="flex-shrink-0">
        <span class="size-12 block bg-gray-200 rounded-full dark:bg-gray-700"></span>
      </div>

      <div class=" mt-2 mb-2 w-full">
        <h3
          class="h-4 bg-gray-200 rounded-full dark:bg-gray-700"
          style={{width:"30%"}}
        ></h3>

        <ul class="mt-5 space-y-3">
          <li class="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
          <li class="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
          <li class="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
        </ul>
      </div>
      <div className="mt-10" >
      <h3
          class="h-4 bg-gray-200 rounded-full dark:bg-gray-700"
          style={{width:"30%"}}
        ></h3>
        <ul class="mt-5 flex">
          <li class="flex-1 h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
          <li class="flex-1 h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
          <li class="flex-1 h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
        </ul>
        <ul class="mt-5 flex">
          <li class="flex-1 h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
          <li class="flex-1 h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
          <li class="flex-1 h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
        </ul>
        <ul class="mt-5 flex">
          <li class="flex-1 h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
          <li class="flex-1 h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
        </ul>
      </div>

         <div className="mt-10" >
      <h3
          class="h-4 bg-gray-200 rounded-full dark:bg-gray-700"
          style={{width:"30%"}}
        ></h3>
        <ul class="mt-5 flex">
          <li class="flex-1 h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
          <li class="flex-1 h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
        </ul>
        
        <ul class="mt-5 flex">
          <li class="flex-1 h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
          <li class="flex-1 h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
          <li class="flex-1 h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
        </ul>
        <ul class="mt-5 flex">
          <li class="flex-1 h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
          <li class="flex-1 h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
          <li class="flex-1 h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
        </ul>
      </div>

    </div>
  );
}
