import { useEffect, useState } from "react";
import axios from "axios";
import { storage } from "../utils/AppwriteConfig/Config";
import useRealtimeSubscription from "../utils/AppwriteConfig/RealtimeServices";
import { useAuth } from "../utils/AppwriteConfig/AuthContext";

export function ProfilePicture({ userId, gender }) {
  const { user, DataServer } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [mainError, setMainError] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);
  const [userCred, setUserCred] = useState({});
  const [imageURL, setImageURL] = useState(null);

  useEffect(() => {
    async function getUserCred() {
      try {
        setIsLoading(true);
        const usercred = await axios.get(
          `${DataServer}/user/account/usercred`,
          {
            params: { accid: userId }
          }
        );

        setUserCred(usercred.data);
        setProfilePicture(usercred.data.profilePicture);
      } catch (e) {
        setMainError(e.message);
      } finally {
        setIsLoading(false);
      }
    }
    getUserCred();
  }, [user, DataServer]);

  useEffect(() => {
    const loadImage = async () => {
      try {
        if (profilePicture) {
          const result = storage.getFilePreview(
            "66198605bb373176f557",
            profilePicture
          );
          setImageURL(result);
        }
      } catch (error) {
        console.error("Error loading image:", error);
      }
    };

    loadImage();
  }, [profilePicture]);

  const handleRealtimeChanges = (changes) => {
    if (changes.changetype === "update" && changes.payload.profilePicture) {
      setProfilePicture(changes.payload.profilePicture);
    } else if (
      changes.changetype === "update" &&
      !changes.payload.profilePicture
    ) {
      setProfilePicture(null);
    }
  };

  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_USERCRED,
    handleRealtimeChanges
  );

  const getDefaultImage = () => {
    if (gender === "Male") {
      return require("../Img/Profile/studentboy.png");
    } else if (gender === "Female") {
      return require("../Img/Profile/studentgirl.png");
    } else {
      return require("../Img/BSA.jpg");
    }
  };

  return profilePicture && imageURL ? (
    <img
      alt="User profile"
      src={imageURL.href}
      className="w-[40px] object-cover h-[40px] rounded-full"
    />
  ) : (
    <img
      className="flex-shrink-0 bg-gray-500 h-10 w-10 rounded-full ring-2 ring-white dark:ring-gray-800"
      src={getDefaultImage()}
      alt="profile"
    />
  );
}
