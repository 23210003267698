import { useEffect, useState } from "react";
import TeacherStatistics from "./TeacherStatistics";
import { GetCurrentAcademicYear } from "../../../../utils/helpers/stringformat";
import { Button, Tooltip } from "@nextui-org/react";
import EditAcademicYear from "./EditAcademicYear";
import axios from "axios";
import { useAuth } from "../../../../utils/AppwriteConfig/AuthContext";
import FinishAcademicSchedule from "./FinishAcademicSchedule";

export default function ScheduleStatistics({
  ScheduleStatus,
  setParent,
  setAcademic
}) {
  const [AcademicYear, setAcademicYear] = useState(
    ScheduleStatus?.hasActiveSchedule
      ? ScheduleStatus?.AcademicYear
      : GetCurrentAcademicYear()
  );
  const [department, setDepartment] = useState({
    Pre: {
      Needed: 0,
      Total: 0,
      TotalCourse: 0
    },
    Elem: {
      Needed: 0,
      Total: 0,
      TotalCourse: 0
    },
    JHS: {
      Needed: 0,
      Total: 0,
      TotalCourse: 0
    },
    SHS: {
      Needed: 0,
      Total: 0,
      TotalCourse: 0
    }
  });
  const [total, setTotal] = useState({ courseTime: 0, needed: 0, current: 0 });

  function updateTotal(Current, Needed, CourseTime, dept) {
    setTotal({ courseTime: CourseTime, needed: Needed, current: Current });
    setDepartment({ ...dept, totalNeeded: Needed, totalCurrent: Current });
  }

  useEffect(() => {
    console.log(ScheduleStatus);
    setAcademicYear(
      ScheduleStatus?.hasActiveSchedule
        ? ScheduleStatus?.AcademicYear
        : GetCurrentAcademicYear()
    );
  }, [ScheduleStatus]);

  useEffect(() => {
    setParent(department);
  }, [department]);
  useEffect(() => {
    setAcademic(AcademicYear);
  }, [AcademicYear]);

  return (
    <div className=" w-full h-full bg flex flex-col ">
      <div className=" flex flex-col md:flex-row items-center w-full mt-5">
        <div className=" flex-1 text py-2  text-4xl font-bold text-secondary-600 ">
          Academic Year: {AcademicYear}
          <EditAcademicYear
            update={setAcademicYear}
            currentStatus={ScheduleStatus}
          />
        </div>

        {!ScheduleStatus?.hasAllDeptScheduled ? (
          <Tooltip
            placement="bottom"
            content={
              <div className=" flex p-2 max-w-[235px] gap-2 items-center text-justify">
                
                <div className=" flex-initial">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-info-circle-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2" />
                  </svg>
                </div>
                <div className=" flex-1">
                Every department must have a schedule for the academic year before the overall academic schedule can be archived.
                </div>
              </div>
            }
          >
            <Button
              color="secondary"
              className=" flex-initial cursor-not-allowed"
            >
              {" "}
              Incomplete Schedule{" "}
            </Button>
          </Tooltip>
        ) : (
          <FinishAcademicSchedule
            status={ScheduleStatus}
            CurrentAcademicYear={AcademicYear}
          />
        )}
      </div>
      <div className=" flex w-full bg-white p-4 mt-6 rounded-lg">
        <TeacherStatistics setParent={updateTotal} />
      </div>
      <div className=" flex w-full p-2 mt-8 flex-col ">
        <div class="mx-auto max-w-2xl mb-8 lg:mb-14 text-center">
          <h2 class="text-3xl lg:text-4xl text-secondary-600 font-semibold dark:text-neutral-200">
            Teaching Load Assessment
          </h2>
          <p class="mt-3 text-secondary-800 dark:text-neutral-200">
            Ensure all teaching hours align with course time requirements.
          </p>
        </div>

        <div class="mx-auto max-w-3xl grid grid-cols-12 gap-6 lg:gap-8">
          <div class="col-span-6 sm:col-span-4 text-center">
            {" "}
            <svg
              class="mx-auto h-auto text-secondary w-7 md:w-9 lucide lucide-brain-circuit dark:text-neutral-200"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M12 5a3 3 0 1 0-5.997.125 4 4 0 0 0-2.526 5.77 4 4 0 0 0 .556 6.588A4 4 0 1 0 12 18Z" />
              <path d="M9 13a4.5 4.5 0 0 0 3-4" />
              <path d="M6.003 5.125A3 3 0 0 0 6.401 6.5" />
              <path d="M3.477 10.896a4 4 0 0 1 .585-.396" />
              <path d="M6 18a4 4 0 0 1-1.967-.516" />
              <path d="M12 13h4" />
              <path d="M12 18h6a2 2 0 0 1 2 2v1" />
              <path d="M12 8h8" />
              <path d="M16 8V5a2 2 0 0 1 2-2" />
              <circle cx="16" cy="13" r=".5" />
              <circle cx="18" cy="3" r=".5" />
              <circle cx="20" cy="21" r=".5" />
              <circle cx="20" cy="8" r=".5" />
            </svg>
            <div class="mt-2 sm:mt-4 text-xl font-semibold">
              <h3 class="text-lg font-normal text-gray-800 dark:text-neutral-200">
                Current Teachers
              </h3>{" "}
              {total.current}
            </div>
          </div>

          <div class="col-span-6 sm:col-span-4 text-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="mx-auto h-auto text-secondary w-7 md:w-9 lucide lucide-brain-circuit dark:text-neutral-200"
            >
              <path d="M12 7v14" />
              <path d="M16 12h2" />
              <path d="M16 8h2" />
              <path d="M3 18a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h5a4 4 0 0 1 4 4 4 4 0 0 1 4-4h5a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1h-6a3 3 0 0 0-3 3 3 3 0 0 0-3-3z" />
              <path d="M6 12h2" />
              <path d="M6 8h2" />
            </svg>
            <div class="mt-2 sm:mt-4  text-xl font-semibold">
              <h3 class="text-lg font-normal text-gray-800 dark:text-neutral-200">
                Total Course Time
              </h3>
              {total.courseTime}
            </div>
          </div>

          <div class="col-span-6 col-start-4 sm:col-span-4 text-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              class="mx-auto h-auto text-secondary w-7 md:w-9 lucide lucide-brain-circuit dark:text-neutral-200 bi bi-person-exclamation"
              viewBox="0 0 16 16"
            >
              <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m.256 7a4.5 4.5 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1z" />
              <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0m-3.5-2a.5.5 0 0 0-.5.5v1.5a.5.5 0 0 0 1 0V11a.5.5 0 0 0-.5-.5m0 4a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1" />
            </svg>
            <div class="mt-2 sm:mt-4 text-xl font-semibold">
              <h3 class="text-lg font-normal text-gray-800 dark:text-neutral-200">
                Teacher needed
              </h3>
              {total.needed}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
