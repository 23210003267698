import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardBody, Button, Spinner } from "@nextui-org/react";
import axios from "axios";
import { useAuth } from "../../../utils/AppwriteConfig/AuthContext";
import useRealtimeSubscription from "../../../utils/AppwriteConfig/RealtimeServices";

export default function EditBio() {
  const { user, server } = useAuth();

  const [isLoading, setisLoading] = useState(false);
  const [MainError, setMainError] = useState();
  const [Errors, setErrors] = useState({});

  const [UserCred, setUserCred] = useState({});
  const [Bio, setBio] = useState({
    current: "",
    new: ""
  });
  const [imageURL, setImageURL] = useState(null);
  const [charCount, setCharCount] = useState(0);

  useEffect(() => {
    async function GetUserCred() {
      try {
        
        setisLoading(true);
        const usercred = await axios.get(`${server}/v1/usercred`, {
          params: { accid: user.$id }
        });

        setUserCred(usercred.data);
        setBio((prevBio) => ({ ...prevBio, current: usercred.data.bio }));
        setCharCount(usercred.data.bio.length || 0); // Set initial char count
      } catch (e) {
        setMainError(e.message);
      } finally {
        setisLoading(false);
      }
    }
    GetUserCred();
  }, [user]);

  const HandleUpdate = async () => {
    if (Bio.current !== Bio.new && Bio.new && charCount <= 1000) {
      const data = {
        accid: user.$id,
        bio: Bio.new
      };
      try {
        setisLoading(true);
        const updatebio = await axios.post(
          `${server}/v1/usercred/update/bio`,
          data
        );
      } catch (e) {
        setMainError(e.message);
      } finally {
        setisLoading(false);
      }
    }
  };

  const handleRealtimeChanges = (changes) => {
    if (changes.changetype === "update" && changes.payload.bio) {
      setBio({
        current: changes.payload.bio,
        new: ""
      });
      setCharCount(changes.payload.bio.length || 0); // Update char count on realtime changes
    } else if (changes.changetype === "update" && !changes.payload.bio) {
      setBio({
        current: "",
        new: ""
      });
      setCharCount(0); // Reset char count
    }
  };

  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_USERCRED,
    handleRealtimeChanges
  );

  const handleBioChange = (e) => {
    const newBio = e.target.value;
    setBio({ ...Bio, new: newBio });
    setCharCount(newBio.length);
    setErrors({
      bio: newBio.length > 1000 ? "Bio cannot exceed 1000 characters" : ""
    });
  };

  return (
    <Card shadow="none" className=" rounded-md">
      <CardHeader className="pb-0 bg-[#f7f7f7f7] rounded-md rounded-b-none p-2 border-[#a16ae8] border-b-3 pt-2 px-4 flex-col items-start">
        <span className="text-lg">Edit Bio</span>
      </CardHeader>
      <CardBody className="overflow-auto space-y-2 py-2">
        <div className="w-full h-full flex flex-col">
          <div className="h-full w-full max-h-full overflow-auto">
            <div className="p-2">
              <div className="flex flex-col">
                <div>
                  <label
                    htmlFor="Bio"
                    className="block mb-2 text-md text-gray-700 font-medium dark:text-white"
                  >
                    Bio
                  </label>
                  {isLoading ? (
                    <div className="w-full bg-slate-200 flex justify-center items-center h-24 rounded-lg">
                      <Spinner size="md" content="Loading..."></Spinner>
                    </div>
                  ) : (
                    <div className="relative">
                      <textarea
                        type="text"
                        defaultValue={Bio.current}
                        onChange={handleBioChange}
                        rows={4}
                        name="Bio"
                        id="Bio"
                        className={`py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600
                          `}
                      />
                      <div className="absolute bottom-2 right-2 text-sm text-gray-500">
                        {charCount}/1000
                      </div>
                      {Errors.bio && (
                        <p className="text-red-500 text-xs mt-1">
                          {Errors.bio}
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end w-full">
            {Bio.current !== Bio.new && Bio.new && charCount <= 1000 && (
              <>
                {isLoading ? (
                  <Button isLoading disabled color="secondary">
                    Updating
                  </Button>
                ) : (
                  <Button color="secondary" onPress={HandleUpdate}>
                    Upload
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  );
}
