export default function Main({ headerTitle, children, leftContent, leftCN }) {
  /**
   *
   * Main layout where header and one Main content at the middle only
   *
   */
  return (
    <div className="w-full h-full flex flex-col  overflow-hidden">
      {/* Outer container */}
      <div className="flex-initial flex justify-between  ">
        <span className=" text-4xl px-6 py-6 font-semibold text-[#72539b]">
          {headerTitle}
        </span>
        <div className={leftCN}>{leftContent}</div>
      </div>
      <div className="flex flex-1  overflow-auto">
        <div className="w-full max-h-full h-full  overflow-hidden">
          <div className="p-2 block  max-h-full h-full overflow-hidden">
            {/* This section will be the only one scrollable */}
            <div className="bg-white rounded-xl p-2 max-h-full h-full overflow-auto">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
