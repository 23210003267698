import React, { useEffect, useState } from 'react';
import Papa from 'papaparse';
import { Button, Spinner } from '@nextui-org/react';
import SaveAccountModal from './MultipleCSVcomponents/Errormodal';
import axios from 'axios';
import { useAuth } from '../../../utils/AppwriteConfig/AuthContext';
import { parseDate } from '@internationalized/date';

function Table({
  Cdata = [],
  columns = [],
  title,
  visibleColumns,
  rem,
  setRem,
  seterror,
  type,
}) {
  const { DataServer } = useAuth();
  const [validationResults, setValidationResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(Cdata);

  //   console.log("Data:", Cdata);
  //   console.log("Columns:", columns);
  //   console.log("Visible Columns:", visibleColumns);

  // Remove row function
  const removeRow = (indexToRemove) => {
    setValidationResults((prevResults) =>
      prevResults.filter((result) => result.row !== indexToRemove)
    );
    setRem([...rem, data[indexToRemove].mainId]);
    // Remove data for that row
    setData((prevData) =>
      prevData.filter((_, index) => index !== indexToRemove)
    );
  };

  useEffect(() => {
    const validateData = async () => {
      const results = [];

      const cdata = data.filter((result) => !rem.includes(result.mainId));
      for (const [index, record] of cdata.entries()) {
        const userType = detectUserType(record);
        let requiredFields = [
          'id',
          'firstName',
          'lastName',
          'contactNumber',
          'age',
          'birthDate',
          'gender',
          'nationality',
          'block',
          'lot',
          'street',
          'subdivision',
          'barangay',
          'city',
          'province',
          'country',
          'userCredentials',
          'email',
          'deleteFlag',
        ];

        if (userType === 'student') {
          requiredFields.push('lrnId');
        } else if (userType === 'teacher') {
          requiredFields.push(
            'teachingMinutePerWeek',
            'teachingExperience',
            'assignedTeachingTime',
            'department'
          );
        } else if (userType === 'employee') {
          requiredFields.push('role');
        } else {
          results.push({
            row: index + 1,
            errors: ['Unable to determine entity type'],
          });
          continue;
        }

        const recordErrors = await validateRecord(record, requiredFields);
        if (recordErrors.length > 0) {
          results.push({
            row: index + 1,
            entity: userType,
            errors: recordErrors,
          });
        }
      }
      setValidationResults(results);

      setLoading(false);
    };

    if (data.length > 0) {
      validateData();
    }
  }, [data, rem]);

  useEffect(() => {
    seterror(validationResults, type);
  }, [validationResults]);

  function detectUserType(record) {
    const id = record?.id?.toString() || record;
    if (id?.startsWith('2')) return 'student';
    if (id?.startsWith('4')) return 'teacher';
    if (id?.startsWith('6')) return 'employee';
    return null;
  }

  const validateRecord = async (record, requiredFields) => {
    const errors = [];
    await Promise.all(
      requiredFields.map(async (field) => {
        const value = record[field];
        let isEmailUnique = false;
        let islrnUnique = false;
        let isIdUnique = false;

        function containsSpecialCharacters(str) {
          const specialCharRegex = /[^a-zA-Z0-9 ]/;
          return specialCharRegex.test(str);
        }

        if (value == null || value.toString().trim() === '') {
          errors.push(`${field} is missing`);
        } else if (
          typeof value === 'string' &&
          containsSpecialCharacters(value)
        ) {
          errors.push(`${field} must not contain special characters.`);
        } else if (field === 'id') {
          const usertype = detectUserType(value);
          if (value && value.length !== 8) {
            errors.push(`${field} must be 8 digit number`);
          } else if (value && !usertype) {
            errors.push(`${field} Uknown ID`);
          } else if (value && usertype) {
            try {
              const response = await axios.get(
                `${DataServer}/user/account/${usertype}/id`,
                { params: { id: value } }
              );
              isIdUnique = response.data.total === 0;
            } catch (error) {
              errors.push(`Error verifying ${field}`);
            }
            if (!isIdUnique) {
              errors.push(`${field} is currently used`);
            }
          }
        } else if (
          field === 'age' &&
          (isNaN(Number(value)) || Number(value) <= 0)
        ) {
          errors.push(`${field} must be a positive integer`);
        } else if (field === 'birthDate' && isNaN(new Date(value).getTime())) {
          errors.push(`${field} must be a valid date (MM/DD/YYYY)`);
        } else if (field === 'DeleteFlag' && typeof value !== 'boolean') {
          errors.push(`${field} must be a boolean (true or false).`);
        } else if (
          (field === 'Block' || field === 'Lot') &&
          !Number.isInteger(value)
        ) {
          errors.push(`${field} must be an integer.`);
        } else if (
          field === 'AssignedTeachingTime' &&
          !/^([0-9]{1,2}:[0-9]{2} (AM|PM)) - ([0-9]{1,2}:[0-9]{2} (AM|PM))$/.test(
            value
          )
        ) {
          errors.push(`${field} must be in the format '8:30 AM - 3:30 PM'`);
        } else if (field === 'lrnId') {
          const regex = /^\d{12}$/;
          if (!value.trim()) {
            errors.push(`${field} is required`);
          } else if (!regex.test(value)) {
            errors.push(`${field} must be a 12 number input`);
          }
          if (value && regex.test(value)) {
            try {
              const response = await axios.get(
                `${DataServer}/user/accounts/lrnverify`,
                {
                  params: {
                    lrn: value,
                  },
                }
              );
              islrnUnique = response.data.total === 0;
            } catch (error) {
              errors.push(`Error verifying ${field}`);
            }
            if (!islrnUnique) {
              errors.push(`${field} is currently used`);
            }
          }
        } else if (
          field === 'contactNumber' &&
          !/^\+63[9][0-9]{9}$/.test(value)
        ) {
          errors.push(`${field} must be a valid phone number (+63)`);
        } else if (
          field === 'gender' &&
          !(value === 'Male' || value === 'Female')
        ) {
          errors.push(`${field} must be Male or Female only`);
        } else if (
          field === 'teachingMinutePerWeek' &&
          (!Number.isInteger(Number(value)) || Number(value) < 0)
        ) {
          errors.push(`${field} must be a non-negative integer`);
        } else if (
          field === 'teachingExperience' &&
          !Number.isInteger(Number(value))
        ) {
          errors.push(`${field} must be an integer`);
        } else if (field === 'email') {
          if (!value.endsWith('@bsa.online.ph')) {
            errors.push(`${field} must end with @bsa.online.ph`);
          }

          if (value.endsWith('@bsa.online.ph')) {
            try {
              const response = await axios.get(
                `${DataServer}/user/verify/email`,
                {
                  params: { email: value },
                }
              );
              isEmailUnique = response.data.total === 0;
            } catch (error) {
              errors.push(`Error verifying ${field}`);
            }
            if (!isEmailUnique) {
              errors.push(`${field} is currently used`);
            }
          }
        } else if (
          field === 'department' &&
          !['Elem', 'Pre', 'JHS', 'SHS'].includes(value)
        ) {
          errors.push(`${field} must be one of "Elem", "Pre", "JHS", or "SHS"`);
        } else if (
          field === 'role' &&
          !['Adminhead', 'Scheduler', 'Registrar'].includes(value)
        ) {
          errors.push(
            `${field} must be one of "Adminhead", "Scheduler", or "Registrar"`
          );
        }
      })
    );
    return errors;
  };

  if (!data.length || !columns.length) {
    return <div className="text-gray-600"></div>;
  }

  // Filtered columns
  const filteredColumns = columns.filter((column) =>
    visibleColumns.includes(column.key)
  );

  if (loading) {
    return (
      <div className="text-black rounded-lg p-6 flex-1 w-full h-full flex items-center justify-center bg-white text-base font-medium">
        <Spinner size="sm" className=" mr-2 " color="secondary" />
        Validating data...
      </div>
    );
  }

  return (
    <div className="w-full max-h-[500px] flex flex-col h-full bg-white shadow-md rounded-lg p-4 sm:p-6">
      <h3 className="text-lg flex-initial font-semibold text-gray-700 mb-4">
        {title}
      </h3>
      {validationResults.length > 0 && (
        <div className="mb-4 text-red-500">
          Some rows have errors. Check the highlighted rows for details.
        </div>
      )}
      <div className="overflow-auto flex-1 max-h-full border border-gray-200 bg-white shadow-md rounded-lg   [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar]:h-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-slate-700  dark:[&::-webkit-scrollbar-thumb]:bg-slate-500 ">
        <table className="w-full min-w-max relative border-collapse border border-gray-200">
          <thead className="relative  ">
            <tr className="z-20 top-0 sticky">
              <th className="border border-gray-400 bg-gray-200 px-4 py-2 text-left">
                No.
              </th>
              {filteredColumns.map((column) => (
                <th
                  key={column.key}
                  className="border border-gray-400 bg-gray-200 px-4 py-2 text-left"
                >
                  {column.label}
                </th>
              ))}
              <th className="border border-gray-400 bg-gray-200 px-4 py-2 text-left">
                Column Errors
              </th>
              <th className="border z-50 sticky right-0 border-gray-400 bg-gray-200 px-4 py-2 text-left">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="z-10 relative">
            {data.map((row, index) => {
              const rowErrors =
                validationResults.find((result) => result.row === index + 1)
                  ?.errors || [];
              const rowErrorsByColumn = {}; // To store errors per column

              rowErrors.forEach((error) => {
                const columnKey = error.split(' ')[0]; // Get the field name from error
                rowErrorsByColumn[columnKey] = error; // Associate error with column
              });

              const rowErrorColumns = {}; // To track errors that are already shown in the specific column

              return (
                <tr
                  key={index}
                  className={
                    rowErrors.length > 0 ? 'bg-warning-100' : 'bg-white'
                  }
                >
                  <td className="border-solid border border-gray-400 px-5 py-2">
                    {index + 1}
                  </td>
                  {filteredColumns.map((column) => {
                    const error = rowErrorsByColumn[column.key];

                    if (error) {
                      rowErrorColumns[column.key] = error;
                    }

                    return (
                      <td
                        key={column.key}
                        className={`border-solid border border-gray-400 px-5 py-2 ${
                          error ? 'text-red-500' : ''
                        }`}
                      >
                        {row[column.key] || 'N/A'}
                        {error && (
                          <div className="text-xs text-red-500">{error}</div>
                        )}
                      </td>
                    );
                  })}
                  <td className="border-solid border border-gray-400 px-5 py-2 text-red-500">
                    {/* Show errors in the 'Errors' column if it's not already shown in the specific column */}
                    {rowErrors
                      .map((error) => {
                        // Check if the error is already shown in a specific column
                        const columnKey = error.split(' ')[0];
                        return !rowErrorColumns[columnKey] ? error : null;
                      })
                      .filter(Boolean) // Filter out null values
                      .join(', ') || 'No errors'}
                  </td>
                  <td className="border-solid border bg-inherit border-gray-400 sticky right-0 py-2 px-4">
                    <Button
                      color="danger"
                      variant="flat"
                      onClick={() => removeRow(index)}
                    >
                      Remove
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default function MultipleCSV() {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [categorizedData, setCategorizedData] = useState({
    students: [],
    teachers: [],
    employees: [],
  });

  const [mainError, setMainError] = useState({
    teachers: [],
    employees: [],
    students: [],
  });

  const [removed, setRemoved] = useState([]);

  //   useEffect(() => {
  //     console.log(data);
  //   }, [data]);

  const categoryFields = {
    students: [
      'mainId',
      'id',
      'firstName',
      'lastName',
      'middleName',
      'contactNumber',
      'age',
      'birthDate',
      'gender',
      'nationality',
      'block',
      'lot',
      'street',
      'subdivision',
      'barangay',
      'city',
      'province',
      'country',
      'email',
      'deleteFlag',
      'lrnId',
    ],
    teachers: [
      'id',
      'mainId',
      'firstName',
      'lastName',
      'middleName',
      'contactNumber',
      'age',
      'birthDate',
      'gender',
      'nationality',
      'block',
      'lot',
      'street',
      'subdivision',
      'barangay',
      'city',
      'province',
      'country',
      'email',
      'deleteFlag',
      'teachingMinutePerWeek',
      'assignedTeachingTime',
      'teachingExperience',
      'department',
    ],
    employees: [
      'id',
      'mainId',
      'firstName',
      'lastName',
      'middleName',
      'contactNumber',
      'age',
      'birthDate',
      'gender',
      'nationality',
      'block',
      'lot',
      'street',
      'subdivision',
      'barangay',
      'city',
      'province',
      'country',
      'email',
      'role',
      'deleteFlag',
    ],
  };

  const handleCSVUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          //   console.log(results);
          // Use map instead of forEach to return a new array
          const parsedData = results.data.map((data, index) => {
            data.mainId = index; // Set mainId to the index
            return data;
          });

          const students = parsedData.filter(
            (row) => row.id && row.id.startsWith('2')
          );
          const teachers = parsedData.filter(
            (row) => row.id && row.id.startsWith('4')
          );
          const employees = parsedData.filter(
            (row) => row.id && row.id.startsWith('6')
          );
          //   console.log("hh");

          const parsedColumns = Object.keys(parsedData[0] || {}).map((key) => ({
            key,
            label: key.charAt(0).toUpperCase() + key.slice(1),
          }));

          setCategorizedData({ students, teachers, employees });
          setColumns(parsedColumns);
          setData(parsedData);
        },
        error: (error) => {
          //   setMainError(error);
          console.error(error);
        },
      });
    }
  };
  const [isSuccess, setIsSuccess] = useState(false);
  const handleBack = () => {
    setData([]);
    setColumns([]);
    setRemoved([]);
    setIsSuccess(false);
    setCategorizedData({ students: [], teachers: [], employees: [] });
  };

  const handleAddAccounts = () => {
    setIsSaveModalOpen(true);
  };

  const AddError = (error, type) => {
    setMainError((prevMainError) => {
      return { ...prevMainError, [type]: [...error] };
    });
  };

  useEffect(() => {
    console.log(isSuccess);
  }, [isSuccess]);

  return (
    <div className="flex flex-col items-center justify-center w-full h-full  ">
      {isSuccess ? (
        <div className=" w-[300px] gap-2 text-base font-medium text-success-500 p-6 flex-col bg-white rounded-lg flex justify-center items-center">
          <div className=" flex flex-col justify-center items-center">
            <img src={require('../../../Img/COmpleted.gif')} />
            Data Succesfully Added
          </div>
          <Button
            onPress={() => {
              handleBack();
            }}
            color="secondary"
            variant="flat"
          >
            Continue
          </Button>
        </div>
      ) : (
        <>
          {data.length > 0 ? (
            <div className=" flex flex-col w-full px-2 h-[calc(100vh-10px)] overflow-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar]:h-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-secondary-300 dark:[&::-webkit-scrollbar-track]:bg-slate-700  dark:[&::-webkit-scrollbar-thumb]:bg-slate-500">
              {/* Tables Section */}
              <div className="w-full max-w-full flex-1 flex flex-col gap-2  ]">
                {!(
                  categorizedData.students.length > 0 ||
                  categorizedData.teachers.length > 0 ||
                  categorizedData.employees.length > 0
                ) ||
                data.filter((data) => !removed.includes(data.mainId)).length ===
                  0 ? (
                  <div className=" w-full h-full flex justify-center items-center">
                    <div className="p-4 bg-yellow-50 border-l-4 border-yellow-400 rounded-lg text-yellow-700 text-base font-medium flex items-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-yellow-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M13 16h-1v-4h-1m1 4h.01M12 8h.01M21 12c0 4.97-4.03 9-9 9s-9-4.03-9-9 4.03-9 9-9 9 4.03 9 9z"
                        />
                      </svg>
                      <span>
                        No account information was provided in the given CSV.
                      </span>
                    </div>
                  </div>
                ) : (
                  <>
                    {categorizedData.students.length > 0 && (
                      <Table
                        Cdata={categorizedData.students}
                        columns={columns}
                        title="Student Data"
                        visibleColumns={categoryFields.students}
                        rem={removed}
                        setRem={setRemoved}
                        seterror={AddError}
                        type={'students'}
                      />
                    )}
                    {categorizedData.teachers.length > 0 && (
                      <Table
                        Cdata={categorizedData.teachers}
                        columns={columns}
                        title="Teacher Data"
                        visibleColumns={categoryFields.teachers}
                        rem={removed}
                        setRem={setRemoved}
                        seterror={AddError}
                        type={'teachers'}
                      />
                    )}
                    {categorizedData.employees.length > 0 && (
                      <Table
                        Cdata={categorizedData.employees}
                        columns={columns}
                        title="Employee Data"
                        visibleColumns={categoryFields.employees}
                        rem={removed}
                        setRem={setRemoved}
                        seterror={AddError}
                        type={'employees'}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          ) : (
            <>
              {/* Upload Section */}
              <div className="w-full max-w-sm bg-white shadow-md rounded-lg p-4 sm:p-6">
                <h2 className="text-lg font-semibold text-gray-700 text-center mb-4">
                  Upload CSV File
                </h2>
                <form>
                  <label className="block">
                    <span className="sr-only">Choose CSV file</span>
                    <input
                      type="file"
                      accept=".csv"
                      onChange={handleCSVUpload}
                      className="block w-full text-sm text-gray-500 
                    file:mr-4 file:py-2 file:px-4
                    file:rounded-md file:border-0
                    file:font-semibold
                    file:bg-[#A16AE8] file:text-white
                    hover:file:bg-opacity-90
                    file:disabled:opacity-50 file:disabled:pointer-events-none
                    dark:text-neutral-500
                  "
                    />
                  </label>
                </form>
              </div>
            </>
          )}

          {data && data.length > 0 && (
            <div className="w-full flex-initial flex justify-between mt-2">
              <Button variant="flat" color="danger" onPress={handleBack}>
                Cancel
              </Button>
              <Button color="secondary" onPress={handleAddAccounts}>
                Add Accounts
              </Button>
            </div>
          )}
        </>
      )}

      {/* Error Modal */}
      <SaveAccountModal
        isOpen={isSaveModalOpen}
        onClose={() => setIsSaveModalOpen(false)}
        data={categorizedData}
        removeRow={removed}
        currentErr={mainError}
        success={setIsSuccess}
      />
    </div>
  );
}
