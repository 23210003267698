import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Input
} from "@nextui-org/react";
import { useAuth } from "../../../utils/AppwriteConfig/AuthContext";
import Error from "../../../Components/ErrorIndicator";
import axios from "axios";

export default function EditEvent({ eventdata, RefreshParent }) {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const { user, server } = useAuth();

  const [isLoading, setisLoading] = useState(false);
  const [MainError, setMainError] = useState();
  const [Errors, setErrors] = useState([]);
  const [Data, setData] = useState({
    title: "",
    start: "",
    end: "",
    userCredentials: "",
    description: "",
    group: ""
  });

  let errors = {};

  useEffect(() => {
    setData({
      ...Data,
      ...eventdata,
      start: formatDateTime(eventdata.start),
      end: formatDateTime(eventdata.end)
    });
  }, [eventdata]);

  useEffect(() => {}, [Data.start, Data.end]);

  const handleChanges = (e) => {
    setData({ ...Data, [e.target.name]: e.target.value });
  };

  const validate = () => {
    if (!Data.title.trim()) {
      errors.title = `Event title is required`;
    } else if (Data.title.length > 50) {
      errors.title = `Event title is too long`;
    }

    if (typeof Data.start !== "string" || !Data.start.trim()) {
      errors.start = `Event start is required`;
    }

    if (typeof Data.end !== "string" || !Data.end.trim()) {
      errors.end = `Event end is required`;
    }
    setErrors(errors);
  };

  useEffect(() => {
    validate();
  }, [Data]);

  const handleEditEvent = async () => {
    if (Object.keys(Errors).length === 0) {
      try {
        setisLoading(true);
        const response = await axios.post(`${server}/v1/calendar/edit`, Data);

        setisLoading(false);
        onClose();
        setData({
          title: "",
          start: "",
          end: "",
          userCredentials: user.$id,
          description: "",
          group: ""
        });
        RefreshParent();
      } catch (e) {
        setMainError(e.message);
        console.log(e);
      }
    }
  };

  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  return (
    <div className="flex flex-1 flex-col max-h-[500px] ">
      {MainError && <Error errorMessage={MainError} />}
      <div className=" w-full h-[300px] max-h-[500px]">
        <div className=" h-full overflow-auto">
          <button id="savebtnedit" className="hidden" onClick={handleEditEvent}>
            gg {Data.$id}
          </button>
          <div className=" p-1 space-y-1 flex flex-col">
            <label
              htmlFor="title"
              className="block text-sm font-medium dark:text-white"
            >
              Title
            </label>
            <input
              type="text"
              name="title"
              id="title"
              defaultValue={Data.title}
              className={`py-2 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
                Errors.title == null ? "border-slate-200" : "border-red-500"
              }`}
              required
              onChange={handleChanges}
            />
            {Errors.title && (
              <span className="text-red-500 text-sm">{Errors.title}</span>
            )}
          </div>
          <div className=" p-1 space-y-1 flex flex-col">
            <label
              htmlFor="start"
              className="block text-sm font-medium dark:text-white"
            >
              Event start
            </label>
            <input
              type="datetime-local"
              name="start"
              id="start"
              value={Data.start}
              className={`py-2 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
                Errors.start == null ? "border-slate-200" : "border-red-500"
              }`}
              required
              onChange={handleChanges}
            />
            {Errors.start && (
              <span className="text-red-500 text-sm mt-1">{Errors.start}</span>
            )}
          </div>
          <div className=" p-1 space-y-1 flex flex-col">
            <label
              htmlFor="end"
              className="block text-sm font-medium dark:text-white"
            >
              Event end
            </label>
            <input
              type="datetime-local"
              name="end"
              id="end"
              value={Data.end}
              className={`py-2 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
                Errors.end == null ? "border-slate-200" : "border-red-500"
              }`}
              required
              onChange={handleChanges}
            />
            {Errors.end && (
              <span className="text-red-500 text-sm mt-1">{Errors.end}</span>
            )}
          </div>
          <div className=" p-1 space-y-1 flex flex-col">
            <label
              htmlFor="description"
              className="block text-sm font-medium dark:text-white"
            >
              Description
            </label>
            <textarea
              type="text"
              name="description"
              id="description"
              defaultValue={Data.description}
              className={`py-2 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
                Errors.description == null
                  ? "border-slate-200"
                  : "border-red-500"
              }`}
              required
              onChange={handleChanges}
            />
            {Errors.description && (
              <span className="text-red-500 text-sm">{Errors.description}</span>
            )}
          </div>

          <div className="relative">
            {Errors.group && (
              <span className="text-red-500 text-sm mt-1">{Errors.group}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
