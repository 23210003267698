import { Select, SelectItem, Spinner } from "@nextui-org/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useAuth } from "../../../../utils/AppwriteConfig/AuthContext";

export default function SelectedCourse({ updatecourse }) {
  const { DataServer } = useAuth();

  const [isLoading, setisLoading] = useState(false);
  const [Refresh, setRefresh] = useState(false);
  const [MainError, setMainError] = useState();

  const [Courses, setCourses] = useState([]);

  useEffect(() => {
    async function Getcourses() {
      try {
        setisLoading(true);
        const courses = await axios(`${DataServer}/course/all`);
        setCourses(courses.data.documents);
      } catch (e) {
        setMainError(e.message);
      } finally {
        setisLoading(false);
      }
    }
    Getcourses();
  }, []);
  return (
    <Select
      placeholder="Select User Type"
      isRequired
      defaultSelectedKeys={["All"]}
      className="min-w-[120px]"
      onChange={(value) => {
        updatecourse(value.target.value);
      }}
    >
      <SelectItem key={"All"} value={"All"}>
        All
      </SelectItem>
      {!isLoading
        ? Courses.map((type) => (
            <SelectItem key={type.$id} value={type.courseName}>
              {type.courseName}
            </SelectItem>
          ))
        : "<Spinner />"}
    </Select>
  );
}
