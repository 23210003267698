import React from "react";
import { Card, CardFooter, Image, Button, Tooltip } from "@nextui-org/react";
import ContainerDelete from "../../pages/{Employee}/{Admin_Head}/EditPages_Page/actions/DeleteConfirmation";

export default function Picture_Tittle_Item({
  container,
  type,
  Currentcontainer,
  loadingstate,
}) {
  const isItem = type === "item";

  return (
    <Card
      isDisabled={loadingstate}
      isFooterBlurred
      radius="lg"
      isPressable
      onPress={() => {
        if (!loadingstate) Currentcontainer(container);
      }}
      className={`border-none relative flex ${isItem ? "max-w-60" : "w-60"}`}
    >
      {/* Conditional rendering for tooltip or delete icon */}
      <div className="absolute top-2 right-2 z-20">
        {isItem ? (
          <ContainerDelete
            route="container/item"
            title="Item"
            id={container.$id}
          />
        ) : (
          <Tooltip
            content={
              container?.isActive ? "Shown on Home Page" : "Hidden Container"
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              fill={container?.isActive ? "#4CAF50" : "#F44336"}
              viewBox="0 0 16 16"
            >
              <circle cx="8" cy="8" r="8" />
            </svg>
          </Tooltip>
        )}
      </div>

      {/* Image section */}
      <Image
        alt={container?.name || "Container image"}
        className="object-cover w-full h-full"
        src={require("../../Img/BSA.jpg")}
      />

      {/* Footer section */}
      <CardFooter className="justify-between bg-[#A16AE8]/40 border-white/20 border-1 py-1 absolute bottom-1 w-[calc(100%_-_8px)] shadow-small ml-1 z-10">
        <p className="text-md text-black/80">
          {container?.name} {container?.itemTitle}
          {container?.order && ` | ${container.order}`}
        </p>
        <p className="text-tiny text-white/80">{container?.type}</p>
      </CardFooter>
    </Card>
  );
}
