import { Button, Card, CardFooter, Image } from "@nextui-org/react";
import { birthdateformat } from "../../../../utils/helpers/stringformat";
import { useEffect, useState } from "react";
import { storage } from "../../../../utils/AppwriteConfig/Config";

export default function Announcementcard({ data, select }) {
  const [imageURL, setImageURL] = useState(null);
  useEffect(() => {
    const loadImage = async () => {
      try {
        if (data && data.imageid) {
          // Load image from storage using data.fileid
          const result = await storage.getFilePreview(
            "66024b7d93a9464cacb6",
            data.imageid
          );
          console.log(result);
          setImageURL(result);
        }
      } catch (error) {
        console.error("Error loading image:", error);
      }
    };

    loadImage();
  }, [data]);
  return (
    <Card
      key={data.$id}
      shadow="none"
      className="rounded-md min-h-48 max-h-52 overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
    >
      <form className="relative top-2 right-2">
        <span className="absolute top-2 right-2 z-40 hover:bg-[#492874] bg-[#72539b] rounded-full p-2">
          {data?.priority ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              className="bi bi-star-fill text-yellow-400"
              viewBox="0 0 16 16"
            >
              <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              className="bi bi-star text-yellow-400"
              viewBox="0 0 16 16"
            >
              <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z" />
            </svg>
          )}
        </span>
      </form>

      {imageURL ? (
        <Image
          removeWrapper
          alt="Card background"
          className="z-0 w-full h-full object-cover"
          src={imageURL}
          onClick={() => {
            select(data, imageURL);
          }}
        />
      ) : (
        <Image
          removeWrapper
          alt="Card background"
          className="z-0 w-full h-full object-cover"
          src={require("../../../../Img/BSA.jpg")}
          onClick={() => {
            select(data, imageURL);
          }}
        />
      )}

      <CardFooter
        className="absolute bg-[#72539b]/80 bottom-0 z-10 rounded-md"
        onClick={() => {
          select(data, imageURL);
        }}
      >
        <div className="flex flex-grow gap-2">
          <div className="flex flex-col justify-start">
            <p className="text-white">{data?.title}</p>
            <p className="text-tiny text-white/90">
              {birthdateformat(data?.dateAdded)}
            </p>
          </div>
        </div>
      </CardFooter>
    </Card>
  );
}
