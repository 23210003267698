import React, { useEffect, useMemo, useState } from "react";
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Button,
  Spinner
} from "@nextui-org/react";
import axios from "axios";
import { useAuth } from "../../../../utils/AppwriteConfig/AuthContext";

export default function CustomDropdown({ onSelect }) {
  const { DataServer } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState(["All Subjects"]);

  useEffect(() => {
    async function fetchCourses() {
      try {
        setIsLoading(true);
        const response = await axios.get(`${DataServer}/course/all`);
        const courseNames = response.data.documents.map(
          (course) => course.courseName
        );
        setOptions(["All Subjects", ...courseNames]);
      } catch (error) {
        // Handle any errors
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchCourses();
  }, [DataServer]);

  useEffect(() => {
  
  }, [options]);

  const [selectedKeys, setSelectedKeys] = useState(new Set(["All Subjects"]));

  const selectedValue = useMemo(
    () => Array.from(selectedKeys).join(", ").replaceAll("_", " "),
    [selectedKeys]
  );

  const handleSelectionChange = (selected) => {
    setSelectedKeys(new Set([selected]));
    onSelect(selected); // Pass selected value back to parent component
  };

  return (
    <Dropdown className=" rounded-md">
      <>
        <DropdownTrigger>
          <Button
            isDisabled={isLoading}
            variant="bordered"
            className="capitalize flex gap-2 border-[#72539b]"
          >
            {isLoading && <Spinner size="sm" color="secondary"></Spinner>}
            {selectedValue}
          </Button>
        </DropdownTrigger>
      </>

      <DropdownMenu
        aria-label="Single selection example"
        variant="flat"
        disallowEmptySelection
        selectionMode="single"
        selectedKeys={selectedKeys}
        className=" max-h-[500px] overflow-auto"
      >
        {options.map((option, index) => (
          <DropdownItem
            key={option}
            onClick={() => handleSelectionChange(option)}
          >
            {option}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}
