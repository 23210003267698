import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Checkbox,
  Input,
  Link
} from "@nextui-org/react";
import { useAuth } from "../../utils/AppwriteConfig/AuthContext";
import axios from "axios";
import Delete from "./DeleteConfirm";
import Error from "../ErrorIndicator";
import DeleteCourse from "../../pages/{Employee}/CoursePage/actions/DeleteCourse";

export default function Edit({ Title, CurrentData, Refresh, closeParent }) {
  /**
   *
   * THIS COMPONENT IS AN EDIT COMP FOR COURSE AND SUBJECT
   *
   */
  const { server, user } = useAuth();
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();

  const [isLoading, setisLoading] = useState(false);
  const [MainError, setMainError] = useState();
  function closeAll() {
   
    closeParent();
    onClose();
  }
  //This data is used to pass on delete modal
  const [data, setdata] = useState({
    creator: user.$id,
    id: CurrentData.$id,
    name: "",
    description: "",
    type: "",
    totalTimePerWeek: ""
  });
  const [currentTitle, setcurrentTitle] = useState();

  //Default value of the course & user changes are stored here
  const [Defvalue, setDefvalue] = useState();

  //VAlidation errors are stored here
  const [Errors, setErrors] = useState({});

  //Added Process when the modal is opened by a user
  const handleOpen = () => {
   
    if (Title.toLowerCase() === "course") {
      //when the opened edit is on course
      setcurrentTitle(CurrentData.courseName);
      setDefvalue({
        name: CurrentData.courseName,
        description: CurrentData.courseDescription
      });
      setdata({
        creator: user.$id,
        id: CurrentData.$id,
        CID: CurrentData.$collectionId,
        name: CurrentData.courseName,
        description: CurrentData.courseDescription,
        ...CurrentData
      });
    } else if (Title.toLowerCase() === "subject") {
      //when the opened edit is on subject
      setcurrentTitle(CurrentData.subjectName);
      setDefvalue({
        name: CurrentData.subjectName,
        description: CurrentData.subjectDescription,
        type: CurrentData.type,
        totalTimePerWeek: CurrentData.totalTimetoFinishPerWeek
      });
      setdata({
        creator: user.$id,
        id: CurrentData.$id,
        name: CurrentData.subjectName,
        description: CurrentData.subjectDescription,
        type: CurrentData.type,
        totalTimePerWeek: CurrentData.totalTimetoFinishPerWeek
      });
    } else {
      setcurrentTitle("err");
    }
    onOpen();
  };

  useEffect(() => {
    console.log(Errors);
  }, [Errors]);
  let errors = {};
  //VAlidation
  const validate = () => {
    // Validate name
    if (!data.name.trim()) {
      errors.name = `${Title} name is required`;
    } else if (data.name.length > 50) {
      errors.name = `${Title} name is too long`;
    }

    if (!data.department) {
      errors.department = `Please select a department`;
    }

    if (
      isNaN(data.gradeLevel) ||
      data.gradeLevel < 0 ||
      data.gradeLevel > 20 ||
      data.gradeLevel === ""
    ) {
      errors.gradeLevel = `Grade level must be between 1 and 12`;
    }

    if (
      isNaN(data.daysToAttend) ||
      data.daysToAttend < 1 ||
      data.daysToAttend > 5
    ) {
      errors.daysToAttend = `Days to attend must be between 1 and 5`;
    }
    setErrors(errors);
    return Object.keys(errors).length === 0; // Return true if there are no errors
  };

  //Validate each user changes
  useEffect(() => {
    validate();
  }, [Defvalue]);

  //Save section process
  const handleSave = async () => {
    if (validate()) {
      // Validate before saving
      setisLoading(true);
      try {
        const type = Title.toLowerCase();
        const response = await axios.post(`${server}/v1/${type}/edit`, data);

        //Refresh PArent

        onClose();
      } catch (error) {
        console.error("Error saving data:", error);
      } finally {
        setisLoading(false);
      }
    }
  };

  return (
    <>
      <button
        onClick={handleOpen}
        className="bg-[#FFDE59] opacity-100 rounded-lg p-3 py-2 text-black hover:bg-[#fcd84b] text-sm"
      >
        Edit
      </button>

      <Modal
        isOpen={isOpen}
        size="lg"
        onOpenChange={onOpenChange}
        hideCloseButton="true"
      >
        <ModalContent className=" font-poppins">
          {(onClose) => (
            <>
              {MainError && (
                <Error errorMessage={MainError} reset={setMainError} />
              )}
              <ModalHeader className="flex relative justify-between items-center gap-1">
                <span className=" font-bold text-2xl">{currentTitle}</span>
                <DeleteCourse
                  title={Title.toLowerCase()}
                  course={CurrentData}
                  refresher={Refresh}
                  close={closeAll}
                />
              </ModalHeader>
              <ModalBody>
                <form className="w-full flex flex-col space-y-2">
                  <>
                    <label
                      for={`${Title}name`}
                      className="block text-sm mb-2 dark:text-white font-medium"
                    >
                      {Title} Name
                    </label>
                    <input
                      type="text"
                      name={`${Title}name`}
                      id={`${Title}name`}
                      className={`py-2 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
                        Errors.name == null
                          ? "border-slate-200"
                          : "border-red-500"
                      }`}
                      required
                      defaultValue={data.name}
                      onChange={(e) =>
                        setdata({
                          ...data,
                          name: e.target.value
                        })
                      }
                    />
                    {Errors.name && (
                      <span className="text-red-500 text-sm mt-1">
                        {Errors.name}
                      </span>
                    )}
                    <div>
                      <label
                        for={`Department`}
                        className="block text-sm mb-2 dark:text-white font-medium"
                      >
                        Department
                      </label>
                      <select
                        className={` pe-9 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 
                      ${
                        Errors.department == null
                          ? "border-gray-200"
                          : "border-red-500"
                      }
                      `}
                        onChange={(e) =>
                          setdata({ ...data, department: e.target.value })
                        }
                      >
                        <option value="" disabled selected hidden>
                          Select Department
                        </option>
                        <option value="Pre">Pre-school</option>
                        <option value="Elem">Elementary</option>
                        <option value="JHS">Junior High School</option>
                        <option value="SHS">Senior High School</option>
                      </select>
                      {Errors.department && (
                        <span className="text-red-500 text-sm mt-1">
                          {Errors.department}
                        </span>
                      )}
                    </div>

                    <div className="flex  space-x-2">
                      <div className="flex-1">
                        <label
                          for={`gardelvl`}
                          className="block text-sm mb-2 dark:text-white font-medium"
                        >
                          Grade level{" "}
                          {Errors.gradeLevel && (
                            <span className="text-red-500">*</span>
                          )}
                        </label>
                        <select
                          disabled={!data.department}
                          className={` pe-9 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 
                      ${
                        Errors.gradeLevel == null
                          ? "border-gray-200"
                          : "border-red-500"
                      }
                      `}
                          onChange={(e) =>
                            setdata({ ...data, gradeLevel: e.target.value })
                          }
                        >
                          <option value="" disabled selected hidden>
                            Select Grade level
                          </option>

                          {data.department === "Pre" && (
                            <>
                              <option value="1">1</option>
                              <option value="2">2</option>
                            </>
                          )}
                          {data.department === "Elem" &&
                            Array.from({ length: 6 }, (_, index) => (
                              <option key={index + 1} value={index + 1}>
                                {index + 1}
                              </option>
                            ))}
                          {data.department === "JHS" &&
                            Array.from({ length: 4 }, (_, index) => (
                              <option key={index + 7} value={index + 7}>
                                {index + 7}
                              </option>
                            ))}
                          {data.department === "SHS" &&
                            Array.from({ length: 2 }, (_, index) => (
                              <option key={index + 11} value={index + 11}>
                                {index + 11}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="flex-initial">
                        <label
                          for={`daystoattend`}
                          className="block text-sm mb-2 dark:text-white font-medium"
                        >
                          Days to Attend per week{" "}
                          {Errors.daysToAttend && (
                            <span className="text-red-500">*</span>
                          )}
                        </label>
                        <input
                          type="text"
                          name={`daystoattend`}
                          id={`daystoattend`}
                          className={`py-2 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
                            Errors.daysToAttend == null
                              ? "border-slate-200"
                              : "border-red-500"
                          }`}
                          required
                          defaultValue={data.daysToAttend}
                          onChange={(e) =>
                            setdata({
                              ...data,
                              daysToAttend: e.target.value
                            })
                          }
                          onKeyDown={(e) => {
                            const isNumeric = /^\d+$/.test(e.key);
                            const isBackspaceOrDelete =
                              e.key === "Backspace" || e.key === "Delete";
                            const isLengthOne = e.target.value.length === 1;
                            // Allow only numeric keys, backspace, delete, arrow keys, and navigation keys
                            if (
                              (isNumeric || isBackspaceOrDelete) &&
                              !(isLengthOne && !isBackspaceOrDelete)
                            ) {
                              return;
                            } else {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        for={`${Title}description`}
                        className="block text-sm mb-2 font-medium dark:text-white"
                      >
                        Short {Title} Description
                      </label>
                      <input
                        type="text"
                        name={`${Title}description`}
                        id={`${Title}description`}
                        className={`py-2 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
                          Errors.description == null
                            ? "border-slate-200"
                            : "border-red-500"
                        }`}
                        required
                        defaultValue={data.description}
                        onChange={(e) =>
                          setdata({
                            ...data,
                            description: e.target.value
                          })
                        }
                      />
                      {Errors.description && (
                        <span className="text-red-500 text-sm mt-1">
                          {Errors.description}
                        </span>
                      )}
                    </div>
                  </>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button
                  className=" bg-slate-300"
                  variant="flat"
                  onPress={onClose}
                >
                  Close
                </Button>
                {isLoading ? (
                  <Button
                    isLoading
                    color="secondary"
                    spinner={
                      <svg
                        className="animate-spin h-5 w-5 text-current"
                        fill="none"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          fill="currentColor"
                        />
                      </svg>
                    }
                  >
                    Loading
                  </Button>
                ) : (
                  <Button
                    isDisabled={Object.keys(Errors).length > 0}
                    color="secondary"
                    onPress={handleSave}
                  >
                    Save
                  </Button>
                )}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
