import { Link, useParams } from "react-router-dom";
import Hoverprofile from "../../../../Components/UM_Hoverprofile";
import { Tooltip } from "@nextui-org/react";
import Archive from "../Actions/Archiveconfirm";
import { useEffect, useState } from "react";
import { useAuth } from "../../../../utils/AppwriteConfig/AuthContext";
import IsEnrolled from "./renders/IsEnrolled";

export default function TableUserRender({
  Users,
  type,
  rerenderTable,
  isSearch,
  isloaded
}) {
  const { users } = useParams();
  const { user } = useAuth();
  const currentUserID = user.$id;

  console.log(Users);
  return (
    <table className="divide-y w-full divide-gray-200 dark:divide-gray-700">
      <thead className="bg-gray-50 dark:bg-gray-700 sticky top-0 z-10">
        <tr>
          <th
            scope="col"
            className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
          >
            Name
          </th>
          <th
            scope="col"
            className="px-6 hidden sm:table-cell py-3 text-end text-xs font-medium text-gray-500 uppercase"
          >
            ID
          </th>
          <th
            scope="col"
            className={`px-6 py-3 ${
              users === "archived" ? "" : "hidden"
            } sm:table-cell text-end text-xs font-medium text-gray-500 uppercase`}
          >
            {users === "students" && (Users[0].student ? "Section" : "Grade")}
            {/*&&  */}
            {users === "teachers" && "Department"}
            {users === "employees" && "Role"}
            {users === "archived" && "Role"}
            {users === "accounts" && "Role"}
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-end text-xs font-medium text-gray-500 uppercase"
          >
            Action
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 dark:divide-gray-700 overflow-auto">
        {Users &&
          Users.length > 0 &&
          Users.map((user) => (
            <tr key={user.$id} className="">
              <td className=" whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200 ">
                {users === "archived" || users === "accounts" ? (
                  <Link
                    to={`/user/employee/manage/account/${users}/view/${user.$id}`}
                  >
                    <div className="hs-tooltip-toggle  max-w-full w-full p-3 flex items-center gap-x-3 bg-white  hover:bg-[#f7dff8] rounded-xl shadow-sm dark:bg-slate-900 dark:border-gray-700">
                      <img
                        src={require("../../../../Img/BSA.jpg")}
                        alt=""
                        className="w-[40px] h-[40px] rounded-full"
                      />
                      <div className="grow">
                        <h4 className="font-semibold text-sm  text-gray-800 dark:text-white">
                          {user?.Username} {user?.name}{" "}
                          {user?.userCredentials?.Username}
                        </h4>
                        <p className="text-sm text-gray-800 md:text-gray-500 dark:text-white md:dark:text-gray- 500">
                          {user?.email}
                          {user?.userCredentials?.Email}
                        </p>
                      </div>
                    </div>
                  </Link>
                ) : (
                  <>
                    <Link
                      to={`/user/employee/manage/account/${users}/view/${
                        user?.student ? user.student.$id : user.$id
                      }`}
                    >
                      <Hoverprofile
                        key={user?.student ? user.student.$id : user.$id}
                        cuser={user?.student ? user.student : user}
                      />
                    </Link>
                  </>
                )}
              </td>
              <td className="px-6 hidden sm:table-cell py-4 whitespace-nowrap text-end text-sm font-medium">
                {user.studentId}
                {user?.student?.studentId}
                {user?.UserID}
                {/* {users === "archived" && user?.$id} */}
                {user.teacherId}
                {user.employeeId}
              </td>
              <td
                className={`px-6 py-3 ${
                  users === "archived" ? "" : "hidden"
                } sm:table-cell text-end text-xs font-medium text-gray-500 uppercase`}
              >
                {/* {users === "students" && <>{user.gender}</>} */}
                {users === "students" &&
                  (user.student ? (
                    user?.section?.sectionName
                  ) : (
                    <IsEnrolled userid={user.$id} isDataLoaded={isloaded} />
                  ))}
                {/* {user?.student?.gender} *Used when student is rendered */}
                {user?.role} {/**Used when Employee is rendered */}
                {/**Used when accounts is rendered */}
                {users === "accounts" &&
                  user &&
                  user?.labels &&
                  user?.labels[0]}
                {users === "teachers" && <>{user.department}</>}
                {users === "employees" && <>{user?.userCredentials?.role}</>}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-end text-sm font-medium">
                {user.$id !== currentUserID && (
                  <div className="flex w-full flex-row-reverse">
                    <Action User={user} refresh={rerenderTable} />
                  </div>
                )}
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}

function Action({ User, refresh }) {
  const { users } = useParams();
  return (
    <div className="relative flex items-center gap-2">
      <Link
        to={`/user/employee/manage/account/${users}/view/${
          User?.student ? User.student.$id : User.$id
        }`}
      >
        <Tooltip content="Details">
          <span className="text-lg text-default-400 cursor-pointer active:opacity-50">
            <svg
              aria-hidden="true"
              fill="none"
              focusable="false"
              height="1em"
              role="presentation"
              viewBox="0 0 20 20"
              width="1em"
            >
              <path
                d="M12.9833 10C12.9833 11.65 11.65 12.9833 10 12.9833C8.35 12.9833 7.01666 11.65 7.01666 10C7.01666 8.35 8.35 7.01666 10 7.01666C11.65 7.01666 12.9833 8.35 12.9833 10Z"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
              />
              <path
                d="M9.99999 16.8916C12.9417 16.8916 15.6833 15.1583 17.5917 12.1583C18.3417 10.9833 18.3417 9.00831 17.5917 7.83331C15.6833 4.83331 12.9417 3.09998 9.99999 3.09998C7.05833 3.09998 4.31666 4.83331 2.40833 7.83331C1.65833 9.00831 1.65833 10.9833 2.40833 12.1583C4.31666 15.1583 7.05833 16.8916 9.99999 16.8916Z"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
              />
            </svg>
          </span>
        </Tooltip>
      </Link>
      {users !== "archived" && users !== "accounts" && (
        <Link
          to={`/user/employee/manage/account/${users}/edit/${
            User?.student ? User.student.$id : User.$id
          }`}
        >
          <Tooltip content="Edit user" color="secondary">
            <span className="text-lg text-secondary cursor-pointer active:opacity-50">
              <svg
                aria-hidden="true"
                fill="none"
                focusable="false"
                height="1em"
                role="presentation"
                viewBox="0 0 20 20"
                width="1em"
              >
                <path
                  d="M11.05 3.00002L4.20835 10.2417C3.95002 10.5167 3.70002 11.0584 3.65002 11.4334L3.34169 14.1334C3.23335 15.1084 3.93335 15.775 4.90002 15.6084L7.58335 15.15C7.95835 15.0834 8.48335 14.8084 8.74168 14.525L15.5834 7.28335C16.7667 6.03335 17.3 4.60835 15.4583 2.86668C13.625 1.14168 12.2334 1.75002 11.05 3.00002Z"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit={10}
                  strokeWidth={1.5}
                />
                <path
                  d="M9.90833 4.20831C10.2667 6.50831 12.1333 8.26665 14.45 8.49998"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit={10}
                  strokeWidth={1.5}
                />
                <path
                  d="M2.5 18.3333H17.5"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit={10}
                  strokeWidth={1.5}
                />
              </svg>
            </span>
          </Tooltip>
        </Link>
      )}
      {users === "archived" && (
        <>
          <IsAccountActive UserData={User} ParentResfresh={refresh} />
        </>
      )}
      {users !== "accounts" && users !== "archived" && (
        <Archive data={User?.student ? User.student : User} refresher={refresh}>
          <Tooltip
            color="warning"
            content="Archive user"
            className=" text-white"
          >
            <span className="text-lg text-warning-400 cursor-pointer active:opacity-50">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="currentColor"
                class="bi bi-archive"
                viewBox="0 0 16 16"
              >
                <path d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 12.5V5a1 1 0 0 1-1-1zm2 3v7.5A1.5 1.5 0 0 0 3.5 14h9a1.5 1.5 0 0 0 1.5-1.5V5zm13-3H1v2h14zM5 7.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5" />
              </svg>
            </span>
          </Tooltip>
        </Archive>
      )}
    </div>
  );
}

function IsAccountActive({ AccountID, Email, UserData, ParentResfresh }) {
  const [isActive, setisActive] = useState(true);
  //This Gets the Account,
  useEffect(() => {}, []);

  return (
    isActive && (
      <>
        <Archive data={UserData} refresher={ParentResfresh} type="activate">
          <Tooltip content="Activate user">
            <span className="text-lg text-green-500 cursor-pointer active:opacity-50">
              <svg
                aria-hidden="true"
                fill="none"
                focusable="false"
                height="1em"
                role="presentation"
                viewBox="0 0 20 20"
                width="1em"
              >
                <path
                  d="M11.05 3.00002L4.20835 10.2417C3.95002 10.5167 3.70002 11.0584 3.65002 11.4334L3.34169 14.1334C3.23335 15.1084 3.93335 15.775 4.90002 15.6084L7.58335 15.15C7.95835 15.0834 8.48335 14.8084 8.74168 14.525L15.5834 7.28335C16.7667 6.03335 17.3 4.60835 15.4583 2.86668C13.625 1.14168 12.2334 1.75002 11.05 3.00002Z"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit={10}
                  strokeWidth={1.5}
                />
                <path
                  d="M9.90833 4.20831C10.2667 6.50831 12.1333 8.26665 14.45 8.49998"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit={10}
                  strokeWidth={1.5}
                />
                <path
                  d="M2.5 18.3333H17.5"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit={10}
                  strokeWidth={1.5}
                />
              </svg>
            </span>
          </Tooltip>
        </Archive>
        <Archive
          data={UserData?.student ? UserData.student : UserData}
          refresher={ParentResfresh}
          type={"delete"}
        >
          <Tooltip color="danger" content="Delete user">
            <span className="text-lg text-danger cursor-pointer active:opacity-50">
              <svg
                aria-hidden="true"
                fill="none"
                focusable="false"
                height="1em"
                role="presentation"
                viewBox="0 0 20 20"
                width="1em"
              >
                <path
                  d="M17.5 4.98332C14.725 4.70832 11.9333 4.56665 9.15 4.56665C7.5 4.56665 5.85 4.64998 4.2 4.81665L2.5 4.98332"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                />
                <path
                  d="M7.08331 4.14169L7.26665 3.05002C7.39998 2.25835 7.49998 1.66669 8.90831 1.66669H11.0916C12.5 1.66669 12.6083 2.29169 12.7333 3.05835L12.9166 4.14169"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                />
                <path
                  d="M15.7084 7.61664L15.1667 16.0083C15.075 17.3166 15 18.3333 12.675 18.3333H7.32502C5.00002 18.3333 4.92502 17.3166 4.83335 16.0083L4.29169 7.61664"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                />
                <path
                  d="M8.60834 13.75H11.3833"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                />
                <path
                  d="M7.91669 10.4167H12.0834"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                />
              </svg>
            </span>
          </Tooltip>
        </Archive>
      </>
    )
  );
}
