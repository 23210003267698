import Userprofile from "./userprofile";

export default function TableRowkeleton() {
  return (
    <table className="divide-y w-full divide-gray-200 animate-pulse dark:divide-gray-700">
      <thead className="bg-gray-50 dark:bg-gray-700 sticky top-0 z-10">
        <tr>
          <th
            scope="col"
            className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
          ></th>
          <th
            scope="col"
            className="px-6 hidden sm:table-cell py-3 text-end text-xs font-medium text-gray-500 uppercase"
          ></th>
          <th
            scope="col"
            className="px-6 py-3 hidden sm:table-cell text-end text-xs font-medium text-gray-500 uppercase"
          ></th>
          <th
            scope="col"
            className="px-6 py-3 text-end text-xs font-medium text-gray-500 uppercase"
          ></th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 dark:divide-gray-700 overflow-auto">
        <Userprofile />
        <Userprofile />
        <Userprofile />
        <Userprofile />
        <Userprofile />
        <Userprofile />
        <Userprofile />
        <Userprofile />
        <Userprofile />
        <Userprofile />
      </tbody>
    </table>
  );
}
