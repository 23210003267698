import { useState } from "react";
import { convertTo12HourFormat } from "../../../../../utils/helpers/helper";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";

export function RequestCardModern({
  data = {
    type: "Full-time Adjustment",
    requestMessage: "Requesting a Full-time Adjustment",
    startHour: 7,
    startMinute: 0,
    endHour: 9,
    endMinute: 0,
    day: "Mon",
    requestReason: "ersg"
  },
  onCancel = () => alert("Request Canceled")
}) {
  const { user } = useAuth();
  // State to manage active tab
  const [activeTab, setActiveTab] = useState("details");

  // Example usage:
  const { startTime, endTime } = convertTo12HourFormat(
    data?.startHour,
    data?.startMinute,
    data?.endHour,
    data?.endMinute
  );

  const daysOfWeek = {
    Mon: "Monday",
    Tue: "Tuesday",
    Wed: "Wednesday",
    Thu: "Thursday",
    Fri: "Friday"
  };

  return (
    <div className="w-full bg-secondary-100 border border-secondary-100 flex flex-col gap-4 p-6 rounded-xl shadow-lg hover:shadow-2xl transition-transform duration-300 transform hover:scale-[102%]">
      {/* Header Section */}
      <div className="flex items-center justify-between">
        <span className="text-xl font-bold text-gray-800">
          {user.$id === data?.currentTeacher?.$id
            ? `${data?.requestMessage}`
            : `${data?.currentTeacher?.firstName} 
           ${data?.currentTeacher?.lastName}`}
        </span>
        <span className="text-sm font-medium text-gray-500 capitalize">
          {data?.day}
        </span>
      </div>

      {/* Time and Action Section */}
      <div className="flex justify-between items-center">
        <div className="text-lg flex gap-2 items-center text-gray-600">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-clock"
            viewBox="0 0 16 16"
          >
            <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0" />
          </svg>
          <div>
            {data.type === "Temporary Leave"
              ? `Whole Day`
              : `${startTime} - ${endTime}`}
          </div>
        </div>
        <button
          onClick={() => {
            onCancel(data?.$id);
          }}
          className="px-4 py-2 text-sm font-medium text-white bg-red-500 rounded-md hover:bg-red-600 focus:outline-none focus:ring focus:ring-red-300"
        >
          Cancel Request
        </button>
      </div>

      {/* Tabs Navigation */}
      <div className="flex border-b border-gray-300 mt-4">
        <button
          className={`px-4 py-2 text-sm font-medium ${
            activeTab === "details"
              ? "text-secondary-600 border-b-2 border-secondary-600"
              : "text-gray-500"
          }`}
          onClick={() => setActiveTab("details")}
        >
          Details
        </button>
        <button
          className={`px-4 py-2 text-sm font-medium ${
            activeTab === "reason"
              ? "text-secondary-600 border-b-2 border-secondary-600"
              : "text-gray-500"
          }`}
          onClick={() => setActiveTab("reason")}
        >
          Reason
        </button>
      </div>

      {/* Tabs Content */}
      <div className="pt-4 text-sm text-gray-700 w-full">
        {activeTab === "details" && (
          <div className="space-y-2">
            <div>
              <strong>{data?.requestMessage}</strong>
            </div>
            <div>
              <strong>Time:</strong>{" "}
              {data.type === "Temporary Leave"
                ? `Whole Day`
                : `${startTime} - ${endTime}`}
            </div>
            <div>
              <strong>Day:</strong> {data && data.day && daysOfWeek[data.day]}
            </div>

            {data && data.Date && (
              <div className=" flex items-center">
                <strong className=" flex-initial">Date: </strong> {data.Date}
              </div>
            )}
          </div>
        )}
        {activeTab === "reason" && (
          <div>
            📄 <span className="italic">{data?.requestReason}</span>
          </div>
        )}
      </div>
    </div>
  );
}

export function ApprovedButPendingRequestCard({
  data = {
    type: "Full-time Adjustment",
    requestMessage: "Requesting a Full-time Adjustment",
    startHour: 7,
    startMinute: 0,
    endHour: 9,
    endMinute: 0,
    day: "Mon",
    requestReason: "ersg"
  },
  adminResponse = "The schedule has been approved but will soon be adjusted"
}) {
  // State to manage active tab
  const { user } = useAuth();
  // State to manage active tab
  const [activeTab, setActiveTab] = useState("details");

  // Example usage:
  const { startTime, endTime } = convertTo12HourFormat(
    data?.startHour,
    data?.startMinute,
    data?.endHour,
    data?.endMinute
  );

  const daysOfWeek = {
    Mon: "Monday",
    Tue: "Tuesday",
    Wed: "Wednesday",
    Thu: "Thursday",
    Fri: "Friday"
  };
  return (
    <div className="w-full bg-blue-50 border border-blue-200 flex flex-col gap-4 p-6 rounded-xl shadow-lg hover:shadow-2xl transition-transform duration-300 transform hover:scale-[102%]">
      {/* Header Section */}
      <div className="flex items-center justify-between">
        <span className="text-xl font-bold text-gray-800">
          {user.$id === data?.currentTeacher?.$id
            ? `${data?.requestMessage}`
            : `${data?.currentTeacher?.firstName} 
           ${data?.currentTeacher?.lastName}`}
        </span>
        <span className="text-sm font-medium text-gray-500 capitalize">
          {data.day}
        </span>
      </div>

      {/* Time Section */}
      <div className="text-lg flex gap-2 items-center text-gray-600">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-clock"
          viewBox="0 0 16 16"
        >
          <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0" />
        </svg>
        <div>
          {data.type === "Temporary Leave"
            ? `Whole Day`
            : `${startTime} - ${endTime}`}
        </div>
      </div>

      {/* Tabs Navigation */}
      <div className="flex border-b border-gray-300">
        <button
          className={`px-4 py-2 text-sm font-medium ${
            activeTab === "details"
              ? "text-blue-600 border-b-2 border-blue-600"
              : "text-gray-500"
          }`}
          onClick={() => setActiveTab("details")}
        >
          Details
        </button>
        <button
          className={`px-4 py-2 text-sm font-medium ${
            activeTab === "reason"
              ? "text-blue-600 border-b-2 border-blue-600"
              : "text-gray-500"
          }`}
          onClick={() => setActiveTab("reason")}
        >
          Reason
        </button>
        <button
          className={`px-4 py-2 text-sm font-medium ${
            activeTab === "response"
              ? "text-blue-600 border-b-2 border-blue-600"
              : "text-gray-500"
          }`}
          onClick={() => setActiveTab("response")}
        >
          Admin Response
        </button>
      </div>

      {/* Tabs Content */}
      <div className="pt-4 text-sm text-gray-700 w-full">
        {activeTab === "details" && (
          <div className="space-y-2">
            <div>
              <strong>{data?.requestMessage}</strong>
            </div>
            <div>
              <strong>Time:</strong>{" "}
              {data.type === "Temporary Leave"
                ? `Whole Day`
                : `${startTime} - ${endTime}`}
            </div>
            <div>
              <strong>Day:</strong> {data && data.day && daysOfWeek[data.day]}
            </div>
          </div>
        )}
        {activeTab === "reason" && (
          <div>
            📄 <span className="italic">{data?.requestReason}</span>
          </div>
        )}
        {activeTab === "response" && (
          <div>
            ✅ <span className="font-medium">Admin Response:</span>{" "}
            {data?.adminResponse || adminResponse}
          </div>
        )}
      </div>
    </div>
  );
}

export function ApprovedAndAdjustedRequestCard({
  data = {
    type: "Full-time Adjustment",
    requestMessage: "Requesting a Full-time Adjustment",
    startHour: 7,
    startMinute: 0,
    endHour: 9,
    endMinute: 0,
    day: "Mon",
    requestReason: "ersg"
  },
  adminResponse = "The schedule has been adjusted as requested."
}) {
  // State to manage active tab
  const { user } = useAuth();
  // State to manage active tab
  const [activeTab, setActiveTab] = useState("details");

  // Example usage:
  const { startTime, endTime } = convertTo12HourFormat(
    data?.startHour,
    data?.startMinute,
    data?.endHour,
    data?.endMinute
  );

  const daysOfWeek = {
    Mon: "Monday",
    Tue: "Tuesday",
    Wed: "Wednesday",
    Thu: "Thursday",
    Fri: "Friday"
  };

  return (
    <div className="w-full bg-green-50 border border-green-50 flex flex-col gap-4 p-6 rounded-xl shadow-lg hover:shadow-2xl transition-transform duration-300 transform hover:scale-[102%]">
      {/* Header Section */}
      <div className="flex items-center justify-between">
        <span className="text-xl font-bold text-gray-800">
          {user.$id === data?.currentTeacher?.$id
            ? `${data?.requestMessage}`
            : `${data?.currentTeacher?.firstName} 
           ${data?.currentTeacher?.lastName}`}
        </span>
        <span className="text-sm font-medium text-gray-500 capitalize">
          {data.day}
        </span>
      </div>

      {/* Time Section */}
      <div className="text-lg flex gap-2 items-center text-gray-600">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-clock"
          viewBox="0 0 16 16"
        >
          <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0" />
        </svg>
        <div>
          {data.type === "Temporary Leave"
            ? `Whole Day`
            : `${startTime} - ${endTime}`}
        </div>
      </div>

      {/* Tabs Navigation */}
      <div className="flex border-b border-gray-300">
        <button
          className={`px-4 py-2 text-sm font-medium ${
            activeTab === "details"
              ? "text-green-600 border-b-2 border-green-600"
              : "text-gray-500"
          }`}
          onClick={() => setActiveTab("details")}
        >
          Details
        </button>
        <button
          className={`px-4 py-2 text-sm font-medium ${
            activeTab === "reason"
              ? "text-green-600 border-b-2 border-green-600"
              : "text-gray-500"
          }`}
          onClick={() => setActiveTab("reason")}
        >
          Reason
        </button>
        <button
          className={`px-4 py-2 text-sm font-medium ${
            activeTab === "response"
              ? "text-green-600 border-b-2 border-green-600"
              : "text-gray-500"
          }`}
          onClick={() => setActiveTab("response")}
        >
          Admin Response
        </button>
      </div>

      {/* Tabs Content */}
      <div className="pt-4 text-sm text-gray-700 w-full">
        {activeTab === "details" && (
          <div className="space-y-2">
            <div>
              <strong>{data?.requestMessage}</strong>
            </div>
            <div>
              <strong>Time:</strong>{" "}
              {data.type === "Temporary Leave"
                ? `Whole Day`
                : `${startTime} - ${endTime}`}
            </div>
            <div>
              <strong>Day:</strong> {data && data.day && daysOfWeek[data.day]}
            </div>
          </div>
        )}
        {activeTab === "reason" && (
          <div>
            📄 <span className="italic">{data?.requestReason}</span>
          </div>
        )}
        {activeTab === "response" && (
          <div>
            ✅ <span className="font-medium">Admin Response:</span>{" "}
            {data?.adminResponse || adminResponse}
          </div>
        )}
      </div>
    </div>
  );
}

export function DeclinedRequestCard({
  data = {
    type: "Full-time Adjustment",
    requestMessage: "Requesting a Full-time Adjustment",
    startHour: 7,
    startMinute: 0,
    endHour: 9,
    endMinute: 0,
    day: "Mon",
    requestReason: "ersg"
  },
  adminResponse = "The schedule request has been declined."
}) {
  const { user } = useAuth();
  // State to manage active tab
  const [activeTab, setActiveTab] = useState("details");

  // Example usage:
  const { startTime, endTime } = convertTo12HourFormat(
    data?.startHour,
    data?.startMinute,
    data?.endHour,
    data?.endMinute
  );

  const daysOfWeek = {
    Mon: "Monday",
    Tue: "Tuesday",
    Wed: "Wednesday",
    Thu: "Thursday",
    Fri: "Friday"
  };

  return (
    <div className="w-full bg-red-50 border border-red-50 flex flex-col gap-4 p-6 rounded-xl shadow-lg hover:shadow-2xl transition-transform duration-300 transform hover:scale-[102%]">
      {/* Header Section */}
      <div className="flex items-center justify-between">
        <span className="text-xl font-bold text-gray-800">
          {user.$id === data?.currentTeacher?.$id
            ? `${data?.requestMessage}`
            : `${data?.currentTeacher?.firstName} 
           ${data?.currentTeacher?.lastName}`}
        </span>
        <span className="text-sm font-medium text-gray-500 capitalize">
          {data.day}
        </span>
      </div>

      {/* Time Section */}
      <div className="text-lg flex gap-2 items-center text-gray-600">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-clock"
          viewBox="0 0 16 16"
        >
          <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0" />
        </svg>
        <div>
          {data.type === "Temporary Leave"
            ? `Whole Day`
            : `${startTime} - ${endTime}`}
        </div>
      </div>

      {/* Tabs Navigation */}
      <div className="flex border-b border-gray-300">
        <button
          className={`px-4 py-2 text-sm font-medium ${
            activeTab === "details"
              ? "text-red-600 border-b-2 border-red-600"
              : "text-gray-500"
          }`}
          onClick={() => setActiveTab("details")}
        >
          Details
        </button>
        <button
          className={`px-4 py-2 text-sm font-medium ${
            activeTab === "reason"
              ? "text-red-600 border-b-2 border-red-600"
              : "text-gray-500"
          }`}
          onClick={() => setActiveTab("reason")}
        >
          Reason
        </button>
        <button
          className={`px-4 py-2 text-sm font-medium ${
            activeTab === "response"
              ? "text-red-600 border-b-2 border-red-600"
              : "text-gray-500"
          }`}
          onClick={() => setActiveTab("response")}
        >
          Admin Response
        </button>
      </div>

      {/* Tabs Content */}
      <div className="pt-4 text-sm text-gray-700 w-full">
        {activeTab === "details" && (
          <div className="space-y-2">
            <div>
              <strong>{data?.requestMessage}</strong>
            </div>
            <div>
              <strong>Time:</strong>{" "}
              {data.type === "Temporary Leave"
                ? `Whole Day`
                : `${startTime} - ${endTime}`}
            </div>
            <div>
              <strong>Day:</strong> {data && data.day && daysOfWeek[data.day]}
            </div>
          </div>
        )}
        {activeTab === "reason" && (
          <div>
            📄 <span className="italic">{data?.requestReason}</span>
          </div>
        )}
        {activeTab === "response" && (
          <div>
            ❌ <span className="font-medium">Admin Response:</span>{" "}
            {data?.adminResponse}
          </div>
        )}
      </div>
    </div>
  );
}
