import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/AppwriteConfig/AuthContext";
import { useEffect } from "react";
import { CircularProgress } from "@nextui-org/react";

export default function EdusyncLoading({ type }) {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if ((user && Object.keys(user).length <= 0) || user === null) {
      navigate("/login");
    }
  }, [user]);

  return (
    <div className=" h-screen w-screen bg-gradient-to-r from-[#9065c9] via-[#925ba0] to-[#7d458b] ">
      <div className="flex flex-1 h-full justify-center items-center">
        <div className="flex flex-col">
          <span className="text-[#fcd84b] text-lg font-bold w-full text-center">
            BSA
          </span>
          <div className=" h-44 w-44 bg-red-200 rounded-full">
            <img className=" rounded-full" src={require("../../Img/BSA.jpg")} />
          </div>

          <span className=" text-[#6e27c5] text-lg font-bold w-full text-center">
            Edusync
          </span>
          <span className="w-full flex justify-center m-1">
            <CircularProgress color="secondary" aria-label="Loading..." />
          </span>
        </div>
      </div>
    </div>
  );
}
