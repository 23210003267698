import React, { useState } from "react";
import { Card, CardHeader, CardBody, Image } from "@nextui-org/react";
import AccountHeader from "../components/AccountHeader";
import UserData from "../components/UserData";
export default function EditPicture() {
  return (
    <Card shadow="none" className=" rounded-md">
      <CardHeader className="pb-0 bg-[#f7f7f7f7] rounded-md rounded-b-none p-2 border-[#a16ae8] border-b-3 pt-2 px-4 flex-col items-start">
        <span className="text-lg">Edit Picture</span>
      </CardHeader>
      <CardBody className="overflow-auto space-y-2 py-2">
        {" "}
        {/* Use overflow-auto to enable scrolling */}
        <div className=" w-full h-full flex flex-col">
          <div className="h-full w-full max-h-full overflow-auto">
            {" "}
            {/* Ensure max-h-full and overflow-auto are applied */}
            <div className="p-2 ">
              <AccountHeader type={"edit"} />
              <UserData type={"edit"} />
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}
