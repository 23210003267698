import { useEffect, useState } from "react";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import { Skeleton, Spinner } from "@nextui-org/react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Error from "../../../../../Components/ErrorIndicator";
import GetFileforDownload from "../../Actions/GetFileforDownload";
import NewFile from "../../../DocumentsPage/actions/NewFile";

export default function Documentrender() {
  const { id } = useParams();
  const { DataServer } = useAuth();

  const [isLoading, setisLoading] = useState(false);
  const [MainError, setMainError] = useState();

  const [Documents, setDocuments] = useState([]);
  const [groupedDocuments, setGroupedDocuments] = useState([]);

  const [isrefresh, setIsrefresh] = useState(false);
  const [User, setUser] = useState({});

  useEffect(() => {
    async function getUsercred() {
      try {
        setisLoading(true);
        const getuser = await axios.get(`${DataServer}/user/${id}/data`);
        setUser(getuser.data);
      } catch (error) {
        setMainError(error.message);
      } finally {
        setisLoading(false);
      }
    }
    getUsercred();
  }, [id]);
  useEffect(() => {
    setisLoading(true);
    async function fetchUser() {
      try {
        const response = await axios.get(
          `${DataServer}/user/document/all`,
          {
            params: { id: id }
          }
        );
        setDocuments(response.data.documents);
      } catch (error) {
        setMainError(error.message);
      } finally {
        setisLoading(false);
      }
    }
    if (id) {
      fetchUser();
    }
  }, [id, isrefresh]);

  useEffect(() => {
    const groupDocuments = () => {
      const groupedData = Documents.reduce((acc, doc) => {
        const type = doc.documentType?.type && doc.documentType?.type;
        if (!acc[type]) {
          acc[type] = [];
        }
        acc[type].push(doc);
        return acc;
      }, {});
      const groupedArray = Object.keys(groupedData).map((type) => ({
        documentType: type,
        documents: groupedData[type]
      }));
      setGroupedDocuments(groupedArray);
    };

    if (Documents && Documents.length > 0) groupDocuments();
  }, [Documents]);

  const refreshdocs = () => {
    setIsrefresh(!isrefresh);
  };
  return (
    <div className="flex flex-col w-full bg-white rounded-md mb-4">
      {MainError && <Error errorMessage={MainError} reset={setMainError} />}
      <div className="bg-[#A16AE8] w-full text-lg  text-white rounded-md p-2 py-3 flex items-center justify-between">
        <span>Documents</span>
        <NewFile currentuser={User} refreshparent={refreshdocs} />
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center h-[250px]">
          <Spinner size="lg">Loading documents...</Spinner>
        </div>
      ) : groupedDocuments.length > 0 ? (
        groupedDocuments.map((docType) => (
          <div className="flex flex-col w-full min-h-[250px] p-4">
            <span className="p-4 text-medium border-[#FFDE59] font-medium border-b ">
              {docType.documentType}
            </span>
            <div
              className={`flex-1 flex relative  max-w-full space-x-2 [&::-webkit-scrollbar]:h-2 [&::-webkit-scrollbar-thumb]:rounded-full  [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-slate-700  dark:[&::-webkit-scrollbar-thumb]:bg-slate-500 overflow-auto ${
                Documents.length > 0 ? "p-2" : ""
              }`}
            >
              {docType.documents && docType.documents.length > 0 ? (
                docType.documents.map((doc) => (
                  <div className="min-w-[200px] relative max-h-full group border-1 shadow-md rounded-lg">
                    <div className="w-full h-full absolute inset-0">
                      <img
                        className="w-full h-full object-cover transition duration-500 ease-in-out group-hover:blur-[2px]"
                        src={require("../../../../../Img/BSA.jpg")}
                        alt="Image"
                      />
                    </div>

                    <div className="absolute bottom-0 left-0 right-0 rounded-lg bg-[#A16AE8]  text-white p-2">
                      {doc.fileTitle}
                    </div>

                    <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition duration-500 ease-in-out">
                      <GetFileforDownload FileIDtoDownload={doc.fileId} />
                    </div>
                  </div>
                ))
              ) : (
                <div className="absolute w-full h-full flex justify-center items-center   ">
                  No Documents yet
                </div>
              )}
            </div>
          </div>
        ))
      ) : (
        <div className="flex justify-center items-center h-[250px]">
          No documents
        </div>
      )}
    </div>
  );
}
{
  /* <div
  className={`flex-1 flex relative max-w-full space-x-2 overflow-auto ${
    Documents.length > 0 ? "p-2" : ""
  }`}
>
  {Documents && Documents.length > 0 ? (
    Documents.map((doc) => (
      <div className="min-w-[200px] relative max-h-full group border-1 shadow-md rounded-lg">
        <div className="w-full h-full absolute inset-0">
          <img
            className="w-full h-full object-cover transition duration-500 ease-in-out group-hover:blur-[2px]"
            src={require("../../../../../Img/BSA.jpg")}
            alt="Image"
          />
        </div>

        <div className="absolute bottom-0 left-0 right-0 rounded-lg bg-[#A16AE8]  text-white p-2">
          {doc.fileTitle}
        </div>

        <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition duration-500 ease-in-out">
          <GetFileforDownload FileIDtoDownload={doc.fileId} />
        </div>
      </div>
    ))
  ) : (
    <div className="absolute w-full h-full flex justify-center items-center   ">
      No Documents yet
    </div>
  )}
</div>; */
}
