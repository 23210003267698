import React, { useEffect, useState } from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  RadioGroup,
  Radio,
  Button,
  Spinner,
  Pagination
} from "@nextui-org/react";
import axios from "axios";

import Error from "../../../../../Components/ErrorIndicator";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import Tableskeleton from "../../../../../Components/skeleton/TableRowkeleton";
import Enrollstudents from "../../../../../Components/modals/Enrollstudents";
export default function UnenrolledStudentTable() {
  const { server } = useAuth();

  const [isLoading, setisLoading] = useState(false);
  const [MainError, setMainError] = useState();
  const [isrefresh, setisrefresh] = useState(false);

  const [students, setstudents] = useState([]);
  const [searchQuery, setsearchQuery] = useState("");
  const [search, setSearch] = useState("");
  const [Pages, setPages] = useState({
    total: 0,
    current: 1
  });
  const [SelectedStudents, setSelectedStudents] = useState([]);
  //Refresh the unenrolled table and clear the selected users
  const handletablerefresh = () => {
    setisrefresh(!isrefresh);
    setSelectedStudents([]);
  };

  //Used to delay to prevent multiple requests
  useEffect(() => {
    setisLoading(true);

    const timerId = setTimeout(() => {
      // Perform search operation here with the current value of 'search'
  
      setsearchQuery(search);
    }, 500);
  }, [search]);

  //Get All unenrolled students
  useEffect(() => {
    setstudents([]);
    setisLoading(true);
    async function fetchenrollee() {
      try {
        const response = await axios.get(`${server}/v1/students/unenrolled`, {
          params: {
            search: searchQuery,
            page: Pages.current
          }
        });
        const totalPages = Math.ceil(response.data.total / 10);
        setPages({ ...Pages, total: totalPages });

      
        setstudents(response.data.documents);
      } catch (error) {
        // Handle any errors
        setMainError(error.message);
      } finally {
        setisLoading(false);
      }
    }
    fetchenrollee();
  }, [Pages.current, searchQuery, isrefresh]);

  //select students
  const handleSelectionChange = (keys) => {
    // Check if "all" option is selected
    if (keys === "all") {
      // Set SelectedStudents to an array containing all student IDs
      setSelectedStudents(students.map((student) => student.$id));
    } else {
      // Handle the case where individual rows are selected
      setSelectedStudents(Array.from(keys));
    }
  };
  return (
    <>
      {MainError && <Error errorMessage={MainError} />}
      <div className="flex-initial flex w-full pr-0 mb-2 border-0 ring-0 sm:mb-0 relative">
        {/* Search icon */}
        <div className="absolute inset-y-0 left-3 flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-search"
            viewBox="0 0 16 16"
          >
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
          </svg>
        </div>
        {/* Search user input */}
        <input
          type="text"
          placeholder="Search"
          className="py-2 pl-10 pr-2 border-0 shadow-md block w-full rounded-lg bg-default text-black/90 dark:text-white/90 text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {/* Clear button */}
        {searchQuery && (
          <div
            className="absolute inset-y-0 right-9 flex cursor-pointer items-center "
            onClick={() => setSearch("")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
            </svg>
          </div>
        )}
      </div>
      {isLoading ? (
        <div className="w-full h-full flex p-1 flex-col  rounded-xl">
          <div className=" bg-slate-200 p-5 rounded-xl"></div>
          <Spinner color="secondary" className="m-4" />
        </div>
      ) : (
        <>
          {students && students.length > 0 ? (
            <div className=" h-full relative w-full mt-2 overflow-auto bg-white rounded-xl">
              <div className="absolute bottom-2 right-2">
                {SelectedStudents.length > 0 ? (
                  <Enrollstudents
                    users={SelectedStudents}
                    refreshparent={handletablerefresh}
                  />
                ) : (
                  <>
                    <Button
                      className="bg-[#FFDE59] hidden sm:flex z-20 rounded-lg"
                      isDisabled
                    >
                      Enroll Students
                    </Button>
                    <Button
                      className="bg-[#FFDE59] flex sm:hidden z-20 rounded-lg"
                      isIconOnly
                      isDisabled
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-person-plus-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                        <path
                          fill-rule="evenodd"
                          d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5"
                        />
                      </svg>
                    </Button>
                  </>
                )}
              </div>
              <Table
                removeWrapper
                selectionMode="multiple"
                selectionBehavior="toggle"
                selectedKeys={SelectedStudents}
                onSelectionChange={handleSelectionChange}
                className=" overflow-auto rounded-lg "
                color={"secondary"}
                aria-label="Example static collection table"
              >
                <TableHeader className=" sticky top-0">
                  <TableColumn>NAME</TableColumn>
                  <TableColumn>ID</TableColumn>
                  <TableColumn>EMAIL</TableColumn>
                </TableHeader>
                <TableBody>
                  {students && students.length > 0 ? (
                    students.map((student) => (
                      <TableRow key={student.$id}>
                        <TableCell>
                          {student.userCredentials.Username}
                        </TableCell>
                        <TableCell>{student.studentId}</TableCell>
                        <TableCell>{student.userCredentials.Email}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <div className=" h-full w-full flex justify-center items-center">
                      <span>No Unenrolled Students</span>
                    </div>
                  )}
                </TableBody>
              </Table>
            </div>
          ) : (
            <div className=" h-full w-full flex justify-center items-center">
              <span>
                No Unenrolled Students{" "}
                {searchQuery !== "" && `named ${searchQuery}`}
              </span>
            </div>
          )}
        </>
      )}

      <div className=" flex-initial flex justify-center">
        {/* Pagination for the documents */}
        <Pagination
          className="flex-initial p-4"
          loop
          showControls
          color="secondary"
          total={Pages.total}
          initialPage={1}
          page={Pages.current}
          onChange={(setCurrentPage) =>
            setPages((prevPages) => ({
              ...prevPages,
              current: setCurrentPage
            }))
          }
        />
      </div>
    </>
  );
}
