import { useEffect, useState } from "react";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";

export default function SubjectInfo({ Valid, Getdata, pushData, EditData }) {
  const { user } = useAuth();
  const maxChars = 1000;
  const [data, setData] = useState({
    creator: user.$id,
    name: "",
    description: ""
  });
  const [errors, setErrors] = useState({});

  const validate = () => {
    let errors = {};
    if (!data.name.trim()) {
      errors.name = "Name is required";
    } else if (data.name.length > 50) {
      errors.name = "Name is too long";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    if (EditData)
      setData({
        ...data,
        name: EditData.subjectName ? EditData.subjectName : "",
        description: EditData.subjectDescription
          ? EditData.subjectDescription
          : ""
      });
  }, [EditData]);

  useEffect(() => {
    pushData({ ...Getdata, ...data });
  }, [data]);

  useEffect(() => {
    Valid(validate());
  }, [data]);

  return (
    <div className="flex flex-col w-full space-y-4">
      <div>
        <label
          htmlFor="subjectname"
          className="block text-sm mb-2 dark:text-white font-medium"
        >
          Subject Name
          {errors.name && <span className="text-red-500 text-sm mt-1">*</span>}
        </label>
        <input
          type="text"
          name="subjectname"
          id="subjectname"
          className={`py-2 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
            errors.name == null ? "border-slate-200" : "border-red-500"
          }`}
          required
          value={data.name}
          onChange={(e) =>
            setData({
              ...data,
              name: e.target.value
            })
          }
        />
      </div>
      <div className=" relative">
        <label
          htmlFor="subjectdescription"
          className="block text-sm mb-2 font-medium dark:text-white"
        >
          Short Subject Description
        </label>
        <textarea
          type="text"
          name="subjectdescription"
          id="subjectdescription"
          className="py-2 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 border-slate-200"
          value={data.description}
          onChange={(e) =>
            setData({
              ...data,
              description: e.target.value.slice(0, maxChars) // Limit input to maxChars
            })
          }
        />
        <div className="p-1 text-gray-500 absolute bottom-0 left-0 text-xs dark:text-gray-400">
          {data.description.length}/{maxChars}
        </div>
      </div>
    </div>
  );
}
