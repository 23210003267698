export default function CurrentID({ ID }) {
  return (
    <span
      id="IDrender"
      className="py-3 px-3 text-md block w-full bg-slate-100 rounded-lg"
    >
      {ID}
    </span>
  );
}
