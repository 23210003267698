import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Divider,
  Link,
  Image,
  Button
} from "@nextui-org/react";
import AnnouncementRender from "./renders/D_AnnouncementRender";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../utils/AppwriteConfig/AuthContext";

export default function Announcement() {
  const navigate = useNavigate();
  const { user } = useAuth();

  return (
    <Card className="flex-1 p-2 md:flex-initial md:w-1/3 h-full rounded-md shadow-sm">
      <CardHeader className=" justify-between">
        <div className="flex flex-col">
          <p className="text-xl font-semibold">Announcements</p>
        </div>
        {user.labels.includes("Adminhead") && (
          <Button
            className="m-0 bg-[#FFDE59]"
            onPress={() => {
              navigate("/user/employee/manage/announcements");
            }}
          >
            View all
          </Button>
        )}
      </CardHeader>
      <Divider />
      <CardBody>
        <AnnouncementRender />
      </CardBody>
    </Card>
  );
}
