import { useEffect, useRef } from "react";
import { useAuth } from "../../../utils/AppwriteConfig/AuthContext";
import { AlinkY } from "../../../Components/ui/Alink";
import { DefaultButton } from "../../../Components/ui/Button";
import { Link } from "react-router-dom";
import Login from "../actions/Login";
import Loge from "../actions/trylog";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Tooltip } from "@nextui-org/react";
export function LoginHeader({ enabled }) {
  const { user, loginerr } = useAuth();
  const lastLocation = localStorage.getItem("lastLocation");
 

  const location = useLocation();
  useEffect(() => {
    require("preline/preline");
  }, [location.pathname, user]);

  useEffect(() => {
    if (window.HSStaticMethods) {
      window.HSStaticMethods.autoInit();
    }
  }, [location.pathname, user]);
  return (
    <header className="flex flex-initial flex-wrap sm:justify-start sm:flex-nowrap w-full text-white bg-[#A16AE8] text-sm py-4 dark:bg-gray-800">
      <nav className="max-w-[85rem] w-full mx-auto px-4 flex flex-wrap basis-full items-center ">
        <a
          className="sm:order-1 flex-1 flex items-center gap-2 text-xl font-semibold dark:text-white"
          href="#"
        >
          <span className=" w-10 h-10 rounded-full">
            <img
              className=" bg-cover rounded-full"
              src={require("../../../Img/BSA.jpg")}
              alt="BSA logo"
            />
          </span>
          BSA Edusync
        </a>

        <div className="sm:order-3   flex flex-initial items-center gap-x-2">
          <button
            type="button"
            onClick={() => {
            
            }}
            className="sm:hidden  hs-collapse-toggle p-2.5 inline-flex justify-center items-center gap-x-2 rounded-lg  bg-[#FFDE59] text-black hover:bg-[#fcd84b]  shadow-sm disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-gray-700 dark:text-white dark:hover:bg-white/10 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
            data-hs-collapse="#navbar-alignment"
            aria-controls="navbar-alignment"
            aria-label="Toggle navigation"
          >
            <svg
              className="hs-collapse-open:hidden flex-shrink-0 w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="3" x2="21" y1="6" y2="6" />
              <line x1="3" x2="21" y1="12" y2="12" />
              <line x1="3" x2="21" y1="18" y2="18" />
            </svg>
            <svg
              className="hs-collapse-open:block hidden flex-shrink-0 w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          </button>

          {enabled === true ? (
            <>
              <div className="relative">
                <button
                  disabled={lastLocation}
                  className="text-sm p-4 py-2 font-semibold rounded-lg border border-transparent bg-[#FFDE59] text-black hover:bg-[#fcd84b] disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                  onClick={() => {
                    document.querySelector("#loginbtn").click();
                  }}
                >
                  {lastLocation ? "Logged in" : "Log in"}
                </button>
              </div>
            </>
          ) : (
            <button
              id="loginbtn"
              type="button"
              className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-[#FFDE59] text-black hover:bg-[#fcd84b] disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
            >
              Log in
            </button>
          )}
        </div>
        <div
          id="navbar-alignment"
          className="hs-collapse z-50 flex-1 hidden overflow-hidden transition-all duration-300 basis-full grow sm:grow-0 sm:basis-auto sm:block sm:order-2"
        >
          <div className="flex flex-col gap-5 mt-5 sm:flex-row sm:items-center sm:justify-end sm:mt-0 sm:ps-5">
            <a
              href="#admission"
              className="font-medium hover:text-[#FFDE59] cursor-pointer dark:text-gray-400 dark:hover:text-gray-500 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
            >
              Admissions
            </a>

            <div className="flex flex-col gap-5 mt-5 sm:flex-row sm:items-center sm:justify-end sm:mt-0 sm:ps-5">
              <a
                href="#about"
                className="font-medium hover:text-[#FFDE59] cursor-pointer dark:text-gray-400 dark:hover:text-gray-500 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
              >
                About BSA
              </a>

              <div className="border-t sm:border-t-0 sm:border-s border-gray-200 dark:border-gray-700">
                {" "}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}
