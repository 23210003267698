import axios from "axios";
import { useEffect, useState } from "react";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import { Spinner } from "@nextui-org/react";
import Error from "../../../../../Components/ErrorIndicator";

export default function SectionOrder({ CourseId ,setorder}) {
  const { server, user } = useAuth();

  const [sectionOrder, setSectionOrder] = useState(0);
  const [isLoading, setisLoading] = useState(true);
  const [MainError, setMainError] = useState();

  useEffect(() => {
    async function GetSectionOrder() {
      try {
        setisLoading(true);
        const getsectionorder = await axios.get(`${server}/v1/section/order`, {
          params: { courseId: CourseId },
        });
        setorder(getsectionorder.data);
        setSectionOrder(getsectionorder.data);
      } catch (e) {
        setMainError(e.message);
      } finally {
        setisLoading(false);
      }
    }
    GetSectionOrder();
  }, []);

  return isLoading ? (
    <span className=" bg-slate-200 flex py-2 px-4 mt-6 rounded-lg">
      {MainError && <Error errorMessage={MainError} />}
      <Spinner size="sm" color="secondary"></Spinner>
    </span>
  ) : (
    <span className=" bg-slate-200 flex py-2 px-4 mt-6 rounded-lg">
      {MainError && <Error errorMessage={MainError} />}
      {sectionOrder}
    </span>
  );
}
