import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure
} from "@nextui-org/react";
import axios from "axios";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import Error from "../../../../../Components/ErrorIndicator";

export default function RequestConfirm({
  type = "approve",
  refresher,
  data,
  unselector
}) {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const { DataServer, user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [mainError, setMainError] = useState(null);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (data) {
      document.querySelector("#ApproveREQ").click();
      onOpenChange(true);
    }
  }, [data]);

  const handleRequest = async () => {
    setIsLoading(true);
    try {
      await axios.put(`${DataServer}/info/request/${type}`, {
        request: data,
        message,
        creator: user
      });
      refresher && refresher();
    } catch (e) {
      setMainError(e.message);
    } finally {
      setIsLoading(false);
      unselector && unselector(null);
      onClose();
    }
  };

  const requestMessage = data?.requestMessage?.split(" ");
  const lastTwo = requestMessage?.slice(-2)?.join(" ");

  return (
    <>
      {mainError && <Error errorMessage={mainError} reset={setMainError} />}
      <button
        id="ApproveREQ"
        aria-haspopup="dialog"
        aria-expanded="false"
        aria-controls="hs-offcanvas-body-scrolling-with-backdrop"
        data-hs-overlay="#hs-offcanvas-body-scrolling-with-backdrop"
        className="hidden"
      ></button>

      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        isDismissable={false}
        isKeyboardDismissDisabled
        backdrop="opaque"
        className=" font-poppins"
        scrollBehavior="inside"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="text-lg font-semibold text-gray-800">
                Confirm Request
              </ModalHeader>
              <ModalBody className="space-y-4">
                <p className="text-gray-700 text-sm">
                  Are you sure you want to{" "}
                  <span className="font-bold">
                    {type === "approve" ? "approve" : "decline"}
                  </span>{" "}
                  the{" "}
                  {data?.status === "PendingRequest" ? "pending " : "approved "}{" "}
                  request from{" "}
                  <span className="font-bold">
                    {data?.currentTeacher?.userCredentials?.Username}
                  </span>{" "}
                  for <span className="font-bold">{lastTwo}</span>?
                </p>

                <div>
                  <label
                    htmlFor="message"
                    className="block text-sm font-medium text-gray-600"
                  >
                    Your Response (Optional)
                  </label>
                  <textarea
                    type="text"
                    id="message"
                    maxLength={1000}
                    placeholder="Add a note or comment"
                    className="mt-2 block w-full rounded-lg border-gray-300 shadow-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] text-sm"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
              </ModalBody>
              <ModalFooter className="flex justify-end space-x-3">
                <Button
                  color="danger"
                  variant="light"
                  onPress={onClose}
                  className="text-sm"
                >
                  Cancel
                </Button>
                <Button
                  color="secondary"
                  isDisabled={isLoading}
                  isLoading={isLoading}
                  className="text-sm"
                  onClick={handleRequest}
                >
                  {isLoading ? "Processing..." : "Confirm"}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
