import React from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Avatar
} from "@nextui-org/react";

export default function RandomSlotView({ schedule }) {
  // Convert the schedule object into an array for easier rendering
  const scheduleArray = schedule
    ? Object.entries(schedule).map(([time, subjects]) => ({
        time,
        subjects: subjects || [] // Ensure subjects is always an array
      }))
    : [];

  return (
    <div className="max-w-full print-area w-full print overflow-auto [&::-webkit-scrollbar]:h-2 [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-secondary-500 dark:[&::-webkit-scrollbar-track]:bg-slate-700  dark:[&::-webkit-scrollbar-thumb]:bg-slate-500 ">
      <Table
        removeWrapper
        className="px-4 py-2"
        aria-label="Example static collection table"
      >
        <TableHeader>
          <TableColumn className="font-semibold text-black text-center">
            TIME
          </TableColumn>
          <TableColumn className="text-center">MONDAY</TableColumn>
          <TableColumn className="text-center">TUESDAY</TableColumn>
          <TableColumn className="text-center">WEDNESDAY</TableColumn>
          <TableColumn className="text-center">THURSDAY</TableColumn>
          <TableColumn className="text-center">FRIDAY</TableColumn>
        </TableHeader>
        <TableBody>
          {scheduleArray.map((sched, index) => (
            <TableRow key={index}>
              <TableCell className="border-r-1 border-b text-center min-w-[120px] text-sm font-semibold">
                {sched.time}
              </TableCell>
              {["Mon", "Tue", "Wed", "Thu", "Fri"].map((day) => {
                const subjectForDay = sched.subjects.find(
                  (subj) => subj.day === day
                );
                return (
                  <TableCell
                    key={day}
                    className={`${
                      subjectForDay &&
                      (subjectForDay.type === "break" ||
                        subjectForDay.type === "lunch")
                        ? "bg-secondary-100"
                        : "border-r-1"
                    } ${
                      subjectForDay &&
                      (subjectForDay.type === "break" ||
                        subjectForDay.type === "lunch") &&
                      subjectForDay.day === "Mon" &&
                      "rounded-s-md"
                    } ${
                      subjectForDay &&
                      (subjectForDay.type === "break" ||
                        subjectForDay.type === "lunch") &&
                      subjectForDay.day === "Fri" &&
                      "rounded-e-md"
                    } min-w-[150px] border-b`}
                  >
                    {subjectForDay ? (
                      <>
                        {subjectForDay.subjectName ? (
                          <div className="rounded-md p-2 flex flex-col">
                            <span className="font-medium">
                              {subjectForDay.subjectName}
                            </span>
                            <div className=" gap-2 flex w-full bg-slate-200 p-1 justify-center items-center rounded-md">
                              {subjectForDay?.teacherName ? (
                                <>
                                  <Avatar
                                    src=""
                                    name={subjectForDay?.teacherName || "User"}
                                    className="w-6 h-6 text-tiny flex-initial"
                                  />
                                  <span
                                    className={` font-normal flex-1 text-xs"`}
                                  >
                                    {subjectForDay?.teacherName ||
                                      "No Teacher Yet"}
                                  </span>
                                </>
                              ) : (
                                <span
                                  className={` font-semibold p-2 flex-1 text-xs"`}
                                >
                                  {subjectForDay?.teacherName ||
                                    "No Teacher Yet"}
                                </span>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="rounded-md  p-2 flex flex-col">
                            <span className="font-medium text-center">
                              {subjectForDay.day === "Wed" &&
                                subjectForDay.type}
                            </span>
                          </div>
                        )}
                      </>
                    ) : (
                      <span>No Class</span>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
