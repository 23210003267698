import React, { useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
} from "@nextui-org/react";
import { useNavigation } from "react-router-dom";
import { useAuth } from "../../utils/AppwriteConfig/AuthContext";
import axios from "axios";
import Error from "../ErrorIndicator";

export default function UnassignTeacher({ SubjecTeacher, AccordionKey }) {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const { server, user } = useAuth();
  const [isLoading, setisLoading] = useState(false);
  const [MainError, setMainError] = useState();

  console.log(SubjecTeacher);

  const close = () => {
    AccordionKey(new Set([SubjecTeacher.subject.$id]));
    onClose();
  };

  const open = () => {
    // AccordionKey(new Set([]));
    onOpen();
  };
  const handledelete = async () => {
    // Check the updated state value after setting the errors
    console.log(SubjecTeacher);
    if (SubjecTeacher) {
      setisLoading(true);
      try {
        const response = await axios.delete(
          `${server}/v1/subject/teacher/unassign`,
          {
            params: {
              id: SubjecTeacher.$id,
              teachername: SubjecTeacher.teacher?.userCredentials.Username,
              creator: user.$id,
              creatorname: user.name,
              subjectname: SubjecTeacher.subject.subjectName,
              sectionname: SubjecTeacher.section.sectionName,
              coursename: SubjecTeacher.course.courseName,
            },
          }
        );
        console.log("User created:", response.data);
        close();
      } catch (e) {
        setMainError(e);
      } finally {
        setisLoading(false);
      }
    } else {
      console.log("Errors found:");
      // Optionally, you can handle errors in UI or perform other actions
    }
  };
  return (
    <>
      {MainError && <Error errorMessage={MainError} />}
      <Button variant="light" onPress={open} className=" justify-end">
        {/* When there is teacher */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="#F44336"
          class="bi bi-person-dash-fill"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M11 7.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5"
          />
          <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
        </svg>
        <span className="sm:flex hidden">Remove Teacher</span>
      </Button>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        onClose={close}
        isDismissable={false}
        isKeyboardDismissDisabled={true}
        scrollBehavior="inside"
        backdrop="opaque"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 ">
                Are you sure you want to remove{" "}
                {SubjecTeacher.teacher.userCredentials.Username}
              </ModalHeader>
              <ModalBody>
                <p>
                  This will remove{" "}
                  {SubjecTeacher.teacher.userCredentials.Username} from teaching{" "}
                  {SubjecTeacher.course.courseName} |{" "}
                  {SubjecTeacher.subject.subjectName} in{" "}
                  {SubjecTeacher.section.sectionName}, Any changes made will not
                  be saved and be discarded.
                </p>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={close}>
                  Cancel
                </Button>
                {isLoading ? (
                  <Button
                    isLoading
                    color="secondary"
                    spinner={
                      <svg
                        className="animate-spin h-5 w-5 text-current"
                        fill="none"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          fill="currentColor"
                        />
                      </svg>
                    }
                  >
                    Loading
                  </Button>
                ) : (
                  <Button
                    color="secondary"
                    onClick={() => {
                      handledelete();
                    }}
                  >
                    Continue
                  </Button>
                )}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
