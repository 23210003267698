import { useEffect, useState } from "react";
import { storage } from "../../utils/AppwriteConfig/Config";

export default function Announcementdashs({ data, select }) {
  const [imageURL, setImageURL] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Load image when component mounts or data changes
  useEffect(() => {
    const loadImage = async () => {
      if (data && data.imageid) {
        try {
          setIsLoading(true);
          const result = await storage.getFilePreview(
            "66024b7d93a9464cacb6",
            data.imageid
          );
          setImageURL(result);
        } catch (error) {
          console.error("Error loading image:", error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    loadImage();
  }, [data]);

  return (
    <a
      key={data.$id}
      className="group flex mb-4 h-auto bg-gradient-to-r shadow-md bg-[#cc78db] hover:from-[#8551c9] hover:to-[#5b2a68] rounded-lg border-[#452063]"
      href="#"
      onClick={() => select(data, imageURL)}
    >
      <div className="flex-shrink-0 relative rounded-s-lg overflow-hidden w-1/3 h-auto flex items-center justify-center">
        {!isLoading && data.imageid ? (
          <img
            className="group-hover:scale-105 h-full w-full object-cover transition-transform duration-500 ease-in-out absolute object-center"
            src={imageURL}
            alt={data.title || "Announcement Image"}
          />
        ) : (
          <img
            className="group-hover:scale-105 transition-transform duration-500 ease-in-out absolute top-6 left-18 object-center rounded-xl"
            src={require("../../Img/Dashboard/announce.png")}
            alt="Default Announcement"
          />
        )}
      </div>

      <div className="flex flex-col p-2 pl-4">
        <h3 className="text-xl font-semibold   break-words text-white mt-2">
          {data.title}
        </h3>
        <p className="mt-2 text-white text-sm">
          <em>{data.brief}</em>
        </p>
        <p className="mt-1 inline-flex items-center gap-x-1 text-[#ffde59] hover:text-[#ffce2a] decoration-2 hover:underline text-xs">
          Read more
          <svg
            className="flex-shrink-0 w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            viewBox="0 0 24 24"
          >
            <path d="M9 18l6-6-6-6" />
          </svg>
        </p>
      </div>
    </a>
  );
}
