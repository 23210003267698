import { Badge } from "@nextui-org/react";
import Usercanvastable from "./renders/UserCanvasTable";
import { useEffect, useState } from "react";
import useRealtimeSubscription from "../../../../utils/AppwriteConfig/RealtimeServices";
import { GetCurrentAcademicYear } from "../../../../utils/helpers/stringformat";
import { useAuth } from "../../../../utils/AppwriteConfig/AuthContext";
import axios from "axios";

export default function StudentCanvas({ isrefreshed }) {
  // const { server } = useAuth();

  // const [isLoading, setisLoading] = useState(false);
  const [MainError, setMainError] = useState();

  const [badge, setbadge] = useState(0);

  // useEffect(() => {
  //   async function GetBadge() {
  //     try {
  //       setisLoading(true);
  //       const badgeResponse = await axios.get(`${server}/enrollment/badge`, {params:{}})
  //     } catch (error) {
  //       console.log(error);
  //       setMainError(error.message);
  //     } finally {
  //       isLoading(false);
  //     }
  //   }

  // }, []);

  //Get realtime updates
  return (
    <>
      <Badge content={badge} color="danger">
        <button
          type="button"
          class="ml-2 bg-secondary-400 py-2 px-4  inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent  text-white hover:bg-secondary-300 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
          data-hs-overlay="#hs-overlay-right"
        >
          Enroll Students
        </button>
      </Badge>

      <div
        id="hs-overlay-right"
        class="hs-overlay bg-[#ededed] hs-overlay-open:translate-x-0 hidden translate-x-full fixed top-0 end-0 transition-all duration-300 transform h-full max-w-3xl w-full z-40 border-s dark:bg-gray-800 dark:border-gray-700"
        tabindex="-1"
      >
        <div className="flex w-full h-full relative flex-col ">
          <div class="flex flex-initial  justify-between items-center py-3 px-4 border-b dark:border-gray-700">
            <span class="font-semibold text-lg text-gray-800 dark:text-white">
              Enroll Students
            </span>
            <button
              type="button"
              class="flex justify-center items-center size-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
              data-hs-overlay="#hs-overlay-right"
            >
              <span class="sr-only">Close modal</span>
              <svg
                class="flex-shrink-0 size-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M18 6 6 18" />
                <path d="m6 6 12 12" />
              </svg>
            </button>
          </div>

          <div class="p-4 h-full overflow-auto flex-1">
            <Usercanvastable
              isparentrefreshed={isrefreshed}
              setBadge={setbadge}
            />
          </div>
        </div>
      </div>
    </>
  );
}
