import React, { Children, useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Tooltip
} from "@nextui-org/react";
import { useNavigation, useParams } from "react-router-dom";
import { useAuth } from "../../../../utils/AppwriteConfig/AuthContext";
import axios from "axios";
import Error from "../../../../Components/ErrorIndicator";
import { functions } from "../../../../utils/AppwriteConfig/Config";
import { ExecutionMethod } from "node-appwrite";

export default function Archive({
  children,
  data,
  refresher,
  type = "archive"
}) {
  const { users } = useParams();
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const { DataServer, user } = useAuth();

  const [isLoading, setisLoading] = useState(false);
  const [MainError, setMainError] = useState();

  const [ConfirmationDelete, setConfirmationDelete] = useState("");

  const username = data?.Username
    ? data.Username
    : data?.userCredentials?.Username;

  useEffect(() => {
    console.log(
      type === "delete" &&
        ConfirmationDelete.toLocaleLowerCase() !== username.toLocaleLowerCase()
    );
  }, [ConfirmationDelete]);
  console.log(data);
  const handledelete = async () => {
    console.log(data);
    // Check the updated state value after setting the errors
    let un;
    if (data) {
      console.log(data);
      try {
        if (type === "archive" || type === "delete") {
          console.log(users);
          if (users === "students" && data.student) {
            un = data.student.userCredentials.Username;
          } else {
            un =
              type === "delete" ? data.Username : data.userCredentials.Username;
          }
        } else {
          un = data.Username;
        }
        setisLoading(true);
        console.log(un);
        const id = data.student ? data.student.$id : data.$id; //This gets the data based on
        const col = data.student
          ? data.student.$collectionId
          : data.$collectionId;
        console.log(col);

        const params = {
          sid: id,
          creator: user.$id,
          user: un,
          collection: col,
          role: data.userCredentials ? data.userCredentials.role : data.role
        };
        const response =
          type === "delete"
            ? await axios.delete(`${DataServer}/user/accounts/${type}`, {
                params: params
              })
            : await axios.put(`${DataServer}/user/accounts/${type}`, params);

        // const response = await functions.createExecution(
        //   "667bc0d000106435b059",
        //   params,
        //   false,
        //   `/User/v1/${type}`,
        //   type === 'delete' ? "DELETE" : "PUT"
        // );

        console.log("User created:", response.responseBody);
        onClose();
        refresher();
      } catch (e) {
        setMainError(e.message);
      } finally {
        setisLoading(false);
      }
    } else {
      console.log("Errors found:");
      // Optionally, you can handle errors in UI or perform other actions
    }
  };
  return (
    <>
      {MainError && <Error errorMessage={MainError} reset={setMainError} />}
      <button onClick={onOpen}>{children}</button>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        isDismissable={false}
        isKeyboardDismissDisabled={true}
        scrollBehavior="inside"
        backdrop="opaque"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 pb-0 ">
                Are you sure you want to {type} {data?.Username}
                {data?.userCredentials?.Username}
                {data?.name}?
              </ModalHeader>
              <ModalBody>
                {type === "delete" ? (
                  <>
                    <p>
                      This account will be {type}d. This action is irreversable,
                      please re-type the full name of the user you want to
                      delete to proceed on deleting the account
                    </p>
                    <input
                      type="text"
                      name="Firstname"
                      id="Firstname"
                      className={`py-2 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 `}
                      required
                      onChange={(e) => setConfirmationDelete(e.target.value)}
                    />
                  </>
                ) : (
                  <p>
                    This account will only be {type}. You can always revert this
                    back.
                  </p>
                )}
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Cancel
                </Button>
                {isLoading ? (
                  <Button
                    isLoading
                    color="secondary"
                    spinner={
                      <svg
                        className="animate-spin h-5 w-5 text-current"
                        fill="none"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          fill="currentColor"
                        />
                      </svg>
                    }
                  >
                    Loading
                  </Button>
                ) : (
                  <Button
                    color="secondary"
                    isDisabled={
                      type === "delete" &&
                      ConfirmationDelete.toLocaleLowerCase() !==
                        username.toLocaleLowerCase()
                    }
                    onClick={() => {
                      handledelete();
                    }}
                  >
                    Continue
                  </Button>
                )}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
