import Profile from "./Profile";
import { Button } from "@nextui-org/react";
import UserDataRender from "./Renders/UserDataRender";
import { Link } from "react-router-dom";
import { useAuth } from "../../../utils/AppwriteConfig/AuthContext";
import { useNavigate } from "react-router-dom";

export default function UserData({ type, close }) {
  const navigate = useNavigate();
  return (
    <div className="flex p-2 items-center justify-center flex-col sm:flex-row">
      <Profile ctype={type} />
      <div className="flex-1 flex flex-col sm:flex-row ">
        <div className="flex-1 ">
          <UserDataRender />
        </div>

        <div className="sm:flex-initial flex flex-1 justify-center sm:justify-end ">
          {type !== "edit" && (
            <Button
              onPress={() => {
                close();
                navigate(`manage/account`);
              }}
            >
              Edit Profile
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
