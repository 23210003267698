import { Chip, Avatar, Spinner } from "@nextui-org/react";

import { useEffect, useState } from "react";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import axios from "axios";
import useRealtimeSubscription from "../../../../../utils/AppwriteConfig/RealtimeServices";
import { storage } from "../../../../../utils/AppwriteConfig/Config";
export default function GetTeacher({ courseid, sectionid, subjectid }) {
  const { server, user } = useAuth();

  const [isLoading, setisLoading] = useState(false);
  const [Refresh, setRefresh] = useState(false);
  const [MainError, setMainError] = useState();

  const [Teacher, setTeacher] = useState();

  useEffect(() => {
    async function GetSubjects() {
      try {
        setisLoading(true);
        setTeacher([]);
        const currentTeacher = await axios.get(`${server}/v1/subject/teacher`, {
          params: {
            CourseId: courseid,
            SubjectId: subjectid,
            SectionId: sectionid
          }
        });
 
        setTeacher(currentTeacher.data.documents[0]);
      } catch (e) {
        setMainError(e.message);
      } finally {
        setisLoading(false);
      }
    }
    GetSubjects();
  }, [Refresh, courseid, subjectid, sectionid]);

  const [profilePicture, setProfilePicture] = useState(null);
  const [isProfileLoading, setIsProfileLoading] = useState(false);
  // Load image when component mounts or data changes
  useEffect(() => {
    const loadImage = async () => {
      if (
        Teacher &&
        Teacher.teacher &&
        Teacher.teacher.userCredentials &&
        Teacher.teacher.userCredentials.profilePicture
      ) {
        try {
          setIsProfileLoading(true);
          const result = await storage.getFilePreview(
            "66198605bb373176f557",
            Teacher.teacher.userCredentials.profilePicture
          );
    
          setProfilePicture(result);
        } catch (error) {
          console.error("Error loading image:", error);
        } finally {
          setIsProfileLoading(false);
        }
      }
    };

    loadImage();
  }, [Teacher]);

  const handleRealtimeChanges = (changes) => {
  
    setRefresh(!Refresh);
  };

  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_SUBJECT_TEACHER,
    handleRealtimeChanges
  );
  return Teacher && !MainError ? (
    <Chip
      variant="flat"
      avatar={
        <Avatar
          getInitials={(name) => name.charAt(0)}
          src={Teacher ? profilePicture : null}
        />
      }
    >
      {isLoading ? (
        <Spinner size="sm" color="secondary"></Spinner>
      ) : (
        <>
          {Teacher?.teacher?.userCredentials?.Username
            ? Teacher?.teacher?.userCredentials?.Username
            : "No Teacher"}
        </>
      )}
    </Chip>
  ) : (
    <Chip
      variant="flat"
      avatar={
        <Avatar
          getInitials={(name) => name.charAt(0)}
          name="U"
          src={Teacher ? profilePicture : null}
        />
      }
    >
      {isLoading ? (
        <Spinner size="sm" color="secondary"></Spinner>
      ) : (
        <>
          {Teacher?.teacher?.userCredentials?.Username
            ? Teacher?.teacher?.userCredentials?.Username
            : "No Teacher yet"}
        </>
      )}
    </Chip>
  );
}
