import { useEffect, useState } from "react";
import AnnouncementRender from "../../DashboardPage/components/renders/AcitivitiesRender";
import CreateUser from "../Actions/CreateUser";
import { Link, useNavigate, useParams } from "react-router-dom";
import Coursestep from "../../../../Components/Coursestep";
import VerifyStep from "./VerifyStep";
import axios from "axios";
import { useAuth } from "../../../../utils/AppwriteConfig/AuthContext";
import { Spinner, Progress } from "@nextui-org/react";
import Cancelation from "../../../../Components/modals/Cancel";
import Error from "../../../../Components/ErrorIndicator";
import { GetCurrentAcademicYear } from "../../../../utils/helpers/stringformat";
import TeacherExperience from "./renders/TeacherExperiences";

export default function Creationstep() {
  const { users } = useParams();

  const navigate = useNavigate();
  const { DataServer, user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [MainError, setMainError] = useState();

  const [NewUser, SetNewUser] = useState();
  const [Errors, SetErrors] = useState({});
  const [step, setstep] = useState(1);
  const [next, setnext] = useState(true);
  const [next2, setnext2] = useState(true);

  //Used to determine where creation final is
  let sfinal = users.toLowerCase() !== "employees" && step === 3;
  let final = users.toLowerCase() === "employees" && step === 2;

  //This gets the user details and errors from the child component
  const GetNewUser = (currentuser, err, state, state2) => {
    SetNewUser({ ...currentuser, creator: user?.$id });
    console.log(err);
    SetErrors(err);
    setIsFormLoading(state || state2);
  };

  //Get enrollment details if enrolling student upon creation
  const GetEnrolldetails = (course, section, cname, sname) => {
    SetNewUser((prevUser) => ({
      ...prevUser, // Retain previous data
      courseid: course, // Add new data or update existing data
      sectionid: section,
      coursename: cname,
      sectionname: sname,
      academicYear: GetCurrentAcademicYear()
    }));
    console.log(course, section);
  };

  const GetAccProf = (TeachedSubjects, PreferredSubejcts, Degree, subjname) => {
    SetNewUser((prevUser) => ({
      ...prevUser, // Retain previous data
      degree: Degree, // Add new data or update existing data
      teachedSubjects: TeachedSubjects,
      preferredSubjects: PreferredSubejcts,
      subj: subjname
    }));
  };

  //used for cancellation
  const nav = () => {
    navigate(`/user/employee/manage/${users}`);
  };

  useEffect(() => {
    console.log(NewUser);
  }, [NewUser]);

  const handlecreateuser = async (e) => {
    e.preventDefault();

    if (
      (Object.keys(Errors).length === 1 ||
        (Object.keys(Errors).length === 2 && Errors.Email === null)) &&
      !isFormLoading &&
      (Errors.Email === null || !Errors.Email) &&
      NewUser
    ) {
      try {
        const user = users.slice(0, -1).toLocaleLowerCase();
        const response = await axios.post(
          `${DataServer}/user/${user}/create`,
          NewUser
        );

        // Redirect or handle success as needed
      } catch (error) {
        //FIX add refresh
        setMainError(error.message);
        // Handle error
      } finally {
        let time = MainError ? 3000 : 0;
        setTimeout(() => {
          navigate(`/user/employee/manage/${users}`);
        }, time); // if there is an error wait for 3 sec before redirect
      }
    } else {
      // Optionally, you can display validation errors to the user
    }
  };

  useEffect(() => {
    console.log(isFormLoading);
    if (
      (Object.keys(Errors).length === 1 ||
        (Object.keys(Errors).length === 2 && Errors.Email === null)) &&
      !isFormLoading &&
      (Errors.Email === null || !Errors.Email)
    ) {
      setnext(true);
    } else {
      setnext(false);
    }
  }, [Errors]);

  return (
    <div data-hs-stepper className=" flex flex-col w-full">
      {MainError && <Error errorMessage={MainError} reset={setMainError} />}
      <ul className=" flex-initial justify-center items-center m-2 flex flex-row gap-x-2">
        <li
          className="flex items-center gap-x-2 shrink basis-0 flex-1 group"
          data-hs-stepper-nav-item='{
            "index": 1
            }'
        >
          <span className="min-w-7 min-h-7 group inline-flex items-center text-xs align-middle">
            <span className="size-7 flex justify-center items-center flex-shrink-0 bg-gray-100 font-medium text-gray-800 rounded-full group-focus:bg-gray-200 dark:bg-gray-700 dark:text-white dark:group-focus:bg-gray-600 hs-stepper-active:bg-secondary-400 hs-stepper-active:text-white hs-stepper-success:bg-secondary-400 hs-stepper-success:text-white hs-stepper-completed:bg-secondary-400 hs-stepper-completed:group-focus:bg-secondary-400">
              <span className="hs-stepper-success:hidden hs-stepper-completed:hidden">
                1
              </span>
              <svg
                className="hidden flex-shrink-0 size-3 hs-stepper-success:block"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <polyline points="20 6 9 17 4 12" />
              </svg>
            </span>
            <span className="ms-2 text-sm font-medium text-gray-800 capitalize">
              Create {users.slice(0, -1)}
            </span>
          </span>
          <div className="w-full h-px flex-1 bg-gray-200 group-last:hidden hs-stepper-success:bg-secondary-400 hs-stepper-completed:bg-secondary-400"></div>
        </li>
        {users.toLocaleLowerCase() === "students" ? (
          <>
            <li
              className="flex items-center gap-x-2 shrink basis-0 flex-1 group"
              data-hs-stepper-nav-item='{
            "index": 2
            }'
            >
              <span className="min-w-7 min-h-7 group inline-flex items-center text-xs align-middle">
                <span className="size-7 flex justify-center items-center flex-shrink-0 bg-gray-100 font-medium text-gray-800 rounded-full group-focus:bg-gray-200 dark:bg-gray-700 dark:text-white dark:group-focus:bg-gray-600 hs-stepper-active:bg-secondary-400 hs-stepper-active:text-white hs-stepper-success:bg-secondary-400 hs-stepper-success:text-white hs-stepper-completed:bg-secondary-400 hs-stepper-completed:group-focus:bg-secondary-400">
                  <span className="hs-stepper-success:hidden hs-stepper-completed:hidden">
                    2
                  </span>
                  <svg
                    className="hidden flex-shrink-0 size-3 hs-stepper-success:block"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>
                </span>
                <span className="ms-2 text-sm font-medium text-gray-800">
                  Enroll User
                </span>
              </span>
              <div className="w-full h-px flex-1 bg-gray-200 group-last:hidden hs-stepper-success:bg-secondary-400 hs-stepper-completed:bg-secondary-400"></div>
            </li>

            <li
              className="flex items-center gap-x-2 shrink basis-0 flex-1 group"
              data-hs-stepper-nav-item='{
        "index": 3
      }'
            >
              <span className="min-w-7 min-h-7 group inline-flex items-center text-xs align-middle">
                <span className="size-7 flex justify-center items-center flex-shrink-0 bg-gray-100 font-medium text-gray-800 rounded-full group-focus:bg-gray-200 dark:bg-gray-700 dark:text-white dark:group-focus:bg-gray-600 hs-stepper-active:bg-secondary-400 hs-stepper-active:text-white hs-stepper-success:bg-blue-600 hs-stepper-success:text-white hs-stepper-completed:bg-secondary-400 hs-stepper-completed:group-focus:bg-secondary-400">
                  <span className="hs-stepper-success:hidden hs-stepper-completed:hidden">
                    3
                  </span>
                  <svg
                    className="hidden flex-shrink-0 size-3 hs-stepper-success:block"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>
                </span>
                <span className="ms-2 text-sm font-medium text-gray-800">
                  Verify Account
                </span>
              </span>
              <div className="w-full h-px flex-1 bg-gray-200 group-last:hidden hs-stepper-success:bg-blue-600 hs-stepper-completed:bg-teal-600"></div>
            </li>
          </>
        ) : users.toLocaleLowerCase() === "teachers" ? (
          <>
            <li
              className="flex items-center gap-x-2 shrink basis-0 flex-1 group"
              data-hs-stepper-nav-item='{
          "index": 2
          }'
            >
              <span className="min-w-7 min-h-7 group inline-flex items-center text-xs align-middle">
                <span className="size-7 flex justify-center items-center flex-shrink-0 bg-gray-100 font-medium text-gray-800 rounded-full group-focus:bg-gray-200 dark:bg-gray-700 dark:text-white dark:group-focus:bg-gray-600 hs-stepper-active:bg-secondary-400 hs-stepper-active:text-white hs-stepper-success:bg-secondary-400 hs-stepper-success:text-white hs-stepper-completed:bg-secondary-400 hs-stepper-completed:group-focus:bg-secondary-400">
                  <span className="hs-stepper-success:hidden hs-stepper-completed:hidden">
                    2
                  </span>
                  <svg
                    className="hidden flex-shrink-0 size-3 hs-stepper-success:block"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>
                </span>
                <span className="ms-2 text-sm font-medium text-gray-800">
                  Teaching Experiences
                </span>
              </span>
              <div className="w-full h-px flex-1 bg-gray-200 group-last:hidden hs-stepper-success:bg-secondary-400 hs-stepper-completed:bg-secondary-400"></div>
            </li>

            <li
              className="flex items-center gap-x-2 shrink basis-0 flex-1 group"
              data-hs-stepper-nav-item='{
      "index": 3
    }'
            >
              <span className="min-w-7 min-h-7 group inline-flex items-center text-xs align-middle">
                <span className="size-7 flex justify-center items-center flex-shrink-0 bg-gray-100 font-medium text-gray-800 rounded-full group-focus:bg-gray-200 dark:bg-gray-700 dark:text-white dark:group-focus:bg-gray-600 hs-stepper-active:bg-secondary-400 hs-stepper-active:text-white hs-stepper-success:bg-blue-600 hs-stepper-success:text-white hs-stepper-completed:bg-secondary-400 hs-stepper-completed:group-focus:bg-secondary-400">
                  <span className="hs-stepper-success:hidden hs-stepper-completed:hidden">
                    3
                  </span>
                  <svg
                    className="hidden flex-shrink-0 size-3 hs-stepper-success:block"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>
                </span>
                <span className="ms-2 text-sm font-medium text-gray-800">
                  Verify Account
                </span>
              </span>
              <div className="w-full h-px flex-1 bg-gray-200 group-last:hidden hs-stepper-success:bg-blue-600 hs-stepper-completed:bg-teal-600"></div>
            </li>
          </>
        ) : (
          <li
            className="flex items-center gap-x-2 shrink basis-0 flex-1 group"
            data-hs-stepper-nav-item='{
        "index": 2
      }'
          >
            <span className="min-w-7 min-h-7 group inline-flex items-center text-xs align-middle">
              <span className="size-7 flex justify-center items-center flex-shrink-0 bg-gray-100 font-medium text-gray-800 rounded-full group-focus:bg-gray-200 dark:bg-gray-700 dark:text-white dark:group-focus:bg-gray-600 hs-stepper-active:bg-secondary-400 hs-stepper-active:text-white hs-stepper-success:bg-blue-600 hs-stepper-success:text-white hs-stepper-completed:bg-secondary-400 hs-stepper-completed:group-focus:bg-secondary-400">
                <span className="hs-stepper-success:hidden hs-stepper-completed:hidden">
                  2
                </span>
                <svg
                  className="hidden flex-shrink-0 size-3 hs-stepper-success:block"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <polyline points="20 6 9 17 4 12" />
                </svg>
              </span>
              <span className="ms-2 text-sm font-medium text-gray-800">
                Verify Account
              </span>
            </span>
            <div className="w-full h-px flex-1 bg-gray-200 group-last:hidden hs-stepper-success:bg-blue-600 hs-stepper-completed:bg-teal-600"></div>
          </li>
        )}
      </ul>
      {/* Main pages */}
      <div className=" flex-1  overflow-auto">
        <div
          data-hs-stepper-content-item='{
            "index": 1
            }'
          className=" h-full flex flex-col "
        >
          <div className="p-4 flex-1 bg-gray-50 flex justify-center items-center overflow-auto border border-dashed border-gray-200 rounded-xl">
            <CreateUser setUser={GetNewUser} />
          </div>
        </div>
        {users.toLowerCase() === "students" ? (
          <>
            <div
              data-hs-stepper-content-item='{
            "index": 2
            }'
              className=" h-full flex flex-col"
              style={{ display: "none" }}
            >
              <div className="p-4 flex-1 bg-gray-50 flex justify-center items-center overflow-auto border border-dashed border-gray-200 rounded-xl">
                {/* Enroll */}
                <Coursestep user={NewUser} setenroll={GetEnrolldetails} />
              </div>
            </div>
            <div
              data-hs-stepper-content-item='{
            "index": 3
            }'
              className=" h-full flex flex-col"
              style={{ display: "none" }}
            >
              <div className="p-4 flex-1 bg-gray-50 flex  border border-dashed border-gray-200 rounded-xl">
                <VerifyStep user={NewUser} />
              </div>
            </div>
          </>
        ) : users.toLowerCase() === "teachers" ? (
          <>
            <div
              data-hs-stepper-content-item='{
              "index": 2
              }'
              className=" h-full flex flex-col"
              style={{ display: "none" }}
            >
              <div className="p-4 flex-1  flex justify-center items-center border border-dashed border-gray-200 rounded-xl">
                <TeacherExperience setdatas={GetAccProf} Validate={setnext2} />
              </div>
            </div>
            <div
              data-hs-stepper-content-item='{
            "index": 3
            }'
              className=" h-full flex flex-col"
              style={{ display: "none" }}
            >
              <div className="p-4 flex-1 bg-gray-50 flex  border border-dashed border-gray-200 rounded-xl">
                <VerifyStep user={NewUser} />
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              data-hs-stepper-content-item='{
          "index": 2
          }'
              className=" h-full flex flex-col"
              style={{ display: "none" }}
            >
              <div className="p-4 flex-1 bg-gray-50 flex justify-center items-center border border-dashed border-gray-200 rounded-xl">
                <VerifyStep user={NewUser} />
              </div>
            </div>
          </>
        )}

        <div
          data-hs-stepper-content-item='{
            "isFinal": true
            }'
          style={{ display: "none" }}
        >
          <div className="p-4 flex-1 bg-gray-50 flex justify-center items-center border border-dashed border-gray-200 rounded-xl">
            <div className="flex flex-col justify-center items-center w-64 h-64">
              {/* <Progress
                label={
                  CreatingValue > 1
                    ? "Creating Account"
                    : CreatingValue > 50
                    ? "Adding Account Details"
                    : "Initializing"
                }
                size="md"
                value={CreatingValue}
                maxValue={100}
                color="secondary"
                showValueLabel={true}
                className="max-w-lg"
              /> */}
              <Spinner
                size="lg"
                label="Creating user..."
                color="secondary"
              ></Spinner>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`mt-5  flex ${
          isLoading ? "hidden" : ""
        } flex-initial justify-between items-center gap-x-2`}
      >
        {step === 1 && <Cancelation navigate={nav} />}
        <button
          type="button"
          className={`py-2 ${
            step === 1 ? "hidden" : ""
          } px-3 inline-flex items-center gap-x-1 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none`}
          data-hs-stepper-back-btn
          onClick={() => {
            setstep(step - 1);
          }}
        >
          <svg
            className="flex-shrink-0 size-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="m15 18-6-6 6-6" />
          </svg>
          Back
        </button>

        {/* <Link
            to={`/user/employee/manage/${users}`}
            className="p-2 flex mx-1 bg-slate-200 text-center items-center rounded-lg"
          >
            <span className="flex-1 mx-1 bg-slate-200 rounded-lg">
              Cancel
            </span>
          </Link> */}

        <button
          type="button"
          className={`py-2  px-3 inline-flex items-center gap-x-1 text-sm font-semibold rounded-lg border border-transparent bg-secondary text-white hover:bg-secondary-400 disabled:opacity-50 disabled:pointer-events-none`}
          onClick={() => {
            setstep(step + 1);
          }}
          disabled={isFormLoading || !next || !next2}
          data-hs-stepper-next-btn
        >
          Next
          <svg
            className="flex-shrink-0 size-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="m9 18 6-6-6-6" />
          </svg>
        </button>
        <form
          className={`${sfinal || final ? "" : "hidden"}`}
          onSubmit={handlecreateuser}
        >
          <button
            type="submit"
            className="py-2 px-3 bg-secondary inline-flex items-center gap-x-1 text-sm font-semibold rounded-lg border border-transparent  text-white hover:bg-secondary-400 disabled:opacity-50 disabled:pointer-events-none"
            style={{ display: "none" }}
            data-hs-stepper-finish-btn
          >
            Finish
          </button>
        </form>
      </div>
    </div>
  );
}
