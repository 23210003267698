export default function Userprofile() {
  return (
    <tr class=" shadow animate-pulse rounded-md p-4">
      <td class=" flex space-x-4 p-4">
        <div class="rounded-full bg-slate-200 h-10 w-10"></div>
        <div className="grow">
          <h4 className="h-2 bg-slate-200 mb-3 mt-2 rounded"></h4>
          <p className="h-2 bg-slate-200 rounded"></p>
        </div>
      </td>
      <td>
        <div className="flex justify-end">
          <p className="h-2 w-16  bg-slate-200 rounded"></p>
        </div>
      </td>
      <td>
        <div className="flex justify-end">
          <p className="h-2 w-24  bg-slate-200 rounded"></p>
        </div>
      </td>
      <td>
        <div className="flex justify-end p-2">
          <p className="h-2 w-28 bg-slate-200 rounded"></p>
        </div>
      </td>
    </tr>
  );
}
