import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Tooltip
} from "@nextui-org/react";
import { useState } from "react";

export default function EditAcademicYear({ update, currentStatus }) {
  console.log(currentStatus?.hasActiveSchedule);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const [academicYear, setAcademicYear] = useState("");

  const [error, setError] = useState("");
  // Function to validate the academic year format (YYYY-YYYY)
  const validateAcademicYear = (value) => {
    const regex = /^\d{4}-\d{4}$/;
    const [startYear, endYear] = value.split("-").map(Number);

    // Validate format and logical order of years
    if (!regex.test(value) || endYear - startYear !== 1) {
      setError("Please enter a valid academic year (e.g., 2023-2024).");
    } else {
      setError("");
    }
  };
  const handleChange = (e) => {
    const value = e.target.value;
    setAcademicYear(value);
    validateAcademicYear(value);
  };

  return (
    <>
      {currentStatus?.hasActiveSchedule ? (
        <Tooltip
          content={
            <div className=" flex p-2 max-w-[200px] gap-2 items-center">
              <div className=" flex-initial">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-info-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2" />
                </svg>
              </div>
              <div className=" flex-1">
                The academic year cannot be changed while there is an active
                schedule.
              </div>
            </div>
          }
        >
          <Button
            // isDisabled={currentStatus?.hasActiveSchedule || true}
            className=" m-0 ml-2   items-center cursor-not-allowed"
            isIconOnly
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-pencil-square"
              viewBox="0 0 16 16"
            >
              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
              <path
                fill-rule="evenodd"
                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
              />
            </svg>
          </Button>
        </Tooltip>
      ) : (
        <Button
          isDisabled={currentStatus?.hasActiveSchedule}
          className=" m-0 ml-2   items-center"
          isIconOnly
          onPress={onOpen}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-pencil-square"
            viewBox="0 0 16 16"
          >
            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
            <path
              fill-rule="evenodd"
              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
            />
          </svg>
        </Button>
      )}

      <Modal
        className=" font-poppins"
        isOpen={isOpen}
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 pb-1">
                Enter Current Academic Year
              </ModalHeader>
              <ModalBody>
                <div className=" w-full">
                  <div className="w-full flex gap-2  items-center">
                    <input
                      type="text"
                      id="academicYear"
                      value={academicYear}
                      placeholder="YYYY-YYYY (e.g., 2023-2024)"
                      onChange={handleChange}
                      className={`w-full p-2  rounded-xl flex-1 bg-[#f4f4f5] ${
                        error ? "border-red-500 border" : "border-none"
                      }`}
                    />
                  </div>
                  {error && (
                    <p className="text-red-500 text-sm mt-1">{error}</p>
                  )}
                  {/* <span className=" flex-initial text-sm max-w-[85px] ">
                              Academic year:
                            </span>
                            <span className="flex-1 bg-[#f4f4f5] rounded-xl flex justify-center items-center">
                              {GetCurrentAcademicYear()}
                            </span> */}
                </div>
              </ModalBody>
              <ModalFooter className=" pt-1">
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                <Button
                  isDisabled={!academicYear || error !== ""}
                  color="secondary"
                  onPress={() => {
                    update(academicYear);
                    onClose();
                  }}
                >
                  Save
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
