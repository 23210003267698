import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure
} from "@nextui-org/react";
import { useAuth } from "../../../../utils/AppwriteConfig/AuthContext";
import { Spinner } from "@nextui-org/react";
import axios from "axios";
import Error from "../../../../Components/ErrorIndicator";

export default function SectionNameValidation({
  SectionName,
  defname,
  isEdit = false
}) {
  const { user } = useAuth();
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();

  const { server } = useAuth();
  const [isLoading, setisLoading] = useState(false);
  const [isValidating, setisValidating] = useState(false);
  const [isUserTyping, setisUserTyping] = useState(false);
  const [MainError, setMainError] = useState();

  const [ValidationErrors, setValidationErrors] = useState({});
  const [duplicate, setduplicate] = useState(0);

  const [Data, setdata] = useState({ name: defname || "" });

  //Validations
  const validate = async () => {
    // Validate Data
    let errors = {};
    if (!Data?.name.trim()) {
      errors.name = "section name is required";
    } else if (duplicate > 0) {
      if (isEdit && Data.name === defname) {
   
      } else {

        errors.name = "Section name is Already used";
      }
    }

    setValidationErrors(errors);
  };

  //validate every time email and userdetails changes
  useEffect(() => {
    validate();
  }, [Data, duplicate]);

  // UPDATE PARENT
  useEffect(() => {
    SectionName(Data.name, isValidating, isUserTyping, duplicate);
  }, [isValidating, isUserTyping, duplicate, Data.name]);

  //Data Duplicate checker
  useEffect(() => {
    setisValidating(true);
    setisUserTyping(true);
    const timerId = setTimeout(() => {
      const fetchData = async () => {
        try {
          setisValidating(true);
          const response = await axios.get(`${server}/v1/section/name_verify`, {
            params: {
              name: Data.name
            }
          });
          setduplicate(response.data.total);
        } catch (error) {
          setMainError(error.message);
        } finally {
          setisValidating(false);
          setisUserTyping(false);
        }
      };
      if (Data.name !== "") {
        fetchData();
      }
    }, 500);
    setisValidating(false);
    return () => clearTimeout(timerId); // Clear the timer when the component unmounts or when the effect is re-run
  }, [Data.name]);

  return (
    <>
      {MainError && <Error errorMessage={MainError} reset={setMainError} />}

      <div>
        <label
          htmlFor="Data"
          className="flex space-x-2 text-md text-gray-700  dark:text-white"
        >
          Section Name{" "}
          {ValidationErrors?.name && (
            <span className="text-red-500 text-xs mt-1 ml-1">
              {duplicate > 0 && !isValidating && ValidationErrors.name}*
            </span>
          )}
          {isValidating && (
            <span className="flex space-x-2">
              <Spinner
                size="sm"
                className="flex ml-2"
                color="secondary"
              ></Spinner>
              <span>Checking for duplicates</span>
            </span>
          )}
        </label>
        <input
          type="text"
          name={`sectionname`}
          id={`sectionname`}
          className={`py-2 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
            ValidationErrors?.name == null
              ? "border-slate-200"
              : "border-red-500"
          }`}
          onKeyPress={(event) => {
            if (Data.name.length >= 50) {
              event.preventDefault();
            }
          }}
          required
          defaultValue={defname}
          onChange={(e) =>
            setdata({
              ...Data,
              name: e.target.value
            })
          }
        />
      </div>
    </>
  );
}
