import { Image } from "@nextui-org/react";
import { birthdateformat } from "../../utils/helpers/stringformat";

export default function Announcementskeleton() {
  return (
    <div className=" w-full shadow-sm border-1 animate-pulse  mb-1 bg-[#bf99f0] rounded-lg  relative transition-transform duration-300 transform hover:scale-[102%]">
      <div className="flex h-full">
        <div className="flex-initial flex w-1/3 h-full bg-white rounded-l-lg items-center justify-center">
          {/* image */}
        </div>
        <div className="flex-1 flex-col flex p-2">
          {/* Content */}
          <div className="flex-1 flex-col space-y-2 flex">
            <span className=" text-2xl p-4 font-meduim w-1/2"></span>
            <span className="text-md p-2 w-full"></span>
            <span className="text-md p-2 w-full"></span>
            <span className="text-md p-2 w-full"></span>
          </div>

          <span className="flex flex-initial p-2 w-[20px] justify-end"></span>
        </div>
      </div>
    </div>
  );
}
