import LatestAnnouncements from "./components/LatestAnnouncement";
import ToDolist from "./components/ToDo";

import { useAuth } from "../../../utils/AppwriteConfig/AuthContext";

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Divider,
  Link,
  Image,
  Button,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Skeleton
} from "@nextui-org/react";
import { Getweekday } from "../../../utils/helpers/stringformat";
import UpcomingClass from "./components/UpcomingClass";
import { useEffect, useState } from "react";
import axios from "axios";

export default function Dashboard() {
  /**
   *
   * DASHBOARD FOR STUDENT OR TEACHER
   *
   */
  const { user, role, DataServer } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [MainError, setMainError] = useState();

  const [currentName, setCurrentName] = useState("");

  useEffect(() => {
    async function GetName() {
      try {
        setIsLoading(true);
        const nameResponse = await axios.get(
          `${DataServer}/user/${user.$id}/name`
        );
        setCurrentName(nameResponse.data);
      } catch (error) {
        setMainError(error.message);
      } finally {
        setIsLoading(false);
      }
    }
    GetName();
  }, [user]);
  return (
    <div className=" flex flex-col  w-full overflow-auto">
      <div className=" pt-4 p-2 hidden md:flex flex-initial">
        <Skeleton
          isLoaded={!isLoading}
          className="flex rounded-lg flex-col m-2 text-[#72539b]"
        >
          <div className=" flex w-full flex-col">
            <span className=" text-3xl font-semibold">
              Welcome, {currentName}
            </span>
            <span className=" text-xl">
              Today is <b>{Getweekday()}</b>, Keep{" "}
              <b>{role.includes("teacher") ? "teaching" : "Studying"}</b>
            </span>
          </div>
        </Skeleton>
      </div>
      <div className="  flex-1 overflow-auto">
        <div className="flex flex-col md:flex-row h-full">
          <div className=" pt-4 p-2 flex-initial md:hidden">
            <div className="flex flex-col m-2 text-[#72539b]">
              <span className=" text-3xl font-semibold">
                Welcome, {user.name}
              </span>
              <span className=" text-xl">
                Today is <b>{Getweekday()}</b>, Keep <b>studying!</b>
              </span>
            </div>
          </div>
          <div className="flex-1 min-h-[500px] md:min-h-full h-full max-h-full p-1">
            {/* Announcement */}
            <LatestAnnouncements />
          </div>
          <div className=" w-full md:w-[40%] min-h-[500px] md:min-h-full flex-initial overflow-auto ">
            <div className="flex flex-col h-full">
              <div className="flex-initial  p-1 ">
                {/* Sched */}
                <UpcomingClass />
              </div>
              <div className="flex-1  p-1 overflow-auto">
                {/* Todo */}
                <ToDolist />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
