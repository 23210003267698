import { Avatar, Image } from "@nextui-org/react";
import { birthdateformat } from "../../utils/helpers/stringformat";
import { useEffect, useState } from "react";
import { storage } from "../../utils/AppwriteConfig/Config";

export default function LatestAnnouncementRender({ select, announcementdata }) {
  const [isLoading, setIsLoading] = useState(false);
  //Userprofile
  const [profilePicture, setProfilePicture] = useState(null);
  const [isProfileLoading, setIsProfileLoading] = useState(false);
  // Load image when component mounts or data changes
  useEffect(() => {
    const loadImage = async () => {
      if (
        announcementdata &&
        announcementdata.userCredentials &&
        announcementdata.userCredentials.profilePicture
      ) {
        try {
          setIsProfileLoading(true);
          const result = await storage.getFilePreview(
            "66198605bb373176f557",
            announcementdata.userCredentials.profilePicture
          );
          setProfilePicture(result);
        } catch (error) {
          console.error("Error loading image:", error);
        } finally {
          setIsProfileLoading(false);
        }
      }
    };

    loadImage();
  }, [announcementdata]);

  //Announcementpicture
  const [imageURL, setImageURL] = useState(null);

  // Load image when component mounts or data changes
  useEffect(() => {
    const loadImage = async () => {
      if (announcementdata && announcementdata.imageid) {
        try {
          setIsLoading(true);
          const result = await storage.getFilePreview(
            "66024b7d93a9464cacb6",
            announcementdata.imageid
          );
          setImageURL(result);
        } catch (error) {
          console.error("Error loading image:", error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    loadImage();
  }, [announcementdata]);


  return (
    <div
      onClick={() => select(announcementdata, imageURL)}
      className=" flex flex-col  group rounded-lg shadow-lg transition-transform transform hover:scale-[102%]"
    >
      <div className="flex bg-red-200 flex-initial rounded-t-lg overflow-hidden">
        {!isLoading && announcementdata.imageid && imageURL ? (
          <div className=" aspect-[16/9] w-full bg-purple-400">
            <img
              radius="none"
              alt={announcementdata.title || "Announcement Image"}
              className="  w-full h-full bg-cover object-cover  transition-transform transform group-hover:scale-105"
              src={imageURL}
            />
          </div>
        ) : (
          <Image
            radius="none"
            isZoomed
            alt="Woman listening to music"
            className="aspect-[16/9] bg-cover transition-transform transform group-hover:scale-105"
            src={require("../../Img/BSA.jpg")}
          />
        )}{" "}
      </div>
      <div className="bg-secondary-200 flex-col p-4 flex flex-1 rounded-b-lg">
        <div className="text-xl font-semibold text-gray-800">
          {announcementdata?.title}
        </div>
        <div className="p-2 text-sm text-gray-600">
          {announcementdata?.content.slice(0, 100)}{" "}
          <a href="#" className="text-secondary-600 hover:underline">
            {" "}
            read more...
          </a>
        </div>
        <div className="flex justify-between items-center text-sm text-gray-500 mt-2">
          <div className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="lucide lucide-calendar-fold"
            >
              <path d="M8 2v4" />
              <path d="M16 2v4" />
              <path d="M21 17V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h11Z" />
              <path d="M3 10h18" />
              <path d="M15 22v-4a2 2 0 0 1 2-2h4" />
            </svg>
            <span>{birthdateformat(announcementdata?.dateAdded)}</span>
          </div>
          {announcementdata.userCredentials && (
            <div className="flex gap-1 items-center items-center">
              {profilePicture ? (
                <img
                  className="  rounded-full size-[26px] bg-cover object-cover"
                  src={profilePicture}
                  alt="gg"
                />
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-circle-user-round"
                >
                  <path d="M18 20a6 6 0 0 0-12 0" />
                  <circle cx="12" cy="10" r="4" />
                  <circle cx="12" cy="12" r="10" />
                </svg>
              )}

              <span>{announcementdata?.userCredentials?.Username}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
{
  /* <div className=" w-full shadow-sm border-1  mb-1 bg-[#bf99f0] rounded-lg  relative transition-transform duration-300 transform hover:scale-[102%]">
      <div className="flex h-full">
        <div className="flex-initial flex w-1/3 bg-white rounded-l-lg items-center justify-center">
          
          <Image
            radius="none"
            isZoomed
            alt="Woman listing to music"
            className="items-center justify-center  bg-cover"
            src={require("../../Img/BSA.jpg")}
          />
        </div>
        <div className="flex-1 flex-col flex p-2">
          <div className="flex-1 flex-col flex">
            <span className=" text-2xl  font-meduim">
              {announcementdata?.title}
            </span>
            <span className="text-md">{announcementdata?.content}</span>
          </div>

          <span className="flex flex-initial justify-end">
            {birthdateformat(announcementdata?.dateAdded)}
          </span>
        </div>
      </div>
    </div> */
}
