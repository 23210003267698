import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Divider,
  Link,
  Image,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Button
} from "@nextui-org/react";

import { GetDateToday } from "../../../../utils/helpers/stringformat";
import UserTable from "../../UserManagment/NewUsermanagment";
import EnrolledStudentTable from "./renders/EnrolledStudentTable";
import Filter from "../../UserManagment/components/CoursenSectionFilter";
import UnenrolledStudentTable from "./renders/UnenrolledStudentsTable";

export default function StudentTable() {
  const [isEnrolled, setisEnrolled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [Course, setCourse] = useState("All");
  const [Section, setSection] = useState("All");
  const [searchValue, setSearchValue] = useState("");
  const CoursenSection = (course, section) => {
    setCourse(course);
    setSection(section);
  };
 
  return (
    <Card className="max-w-full font-poppins flex-1 border shadow-lg shadow">
      <CardHeader className="flex gap-3">
        <div className="flex flex-1 flex-col sm:flex-row items-center justify-between pr-2">
          <Dropdown className="mb-1">
            <DropdownTrigger>
              <Button
                className="h-6 pr-2 text-small"
                endContent={
                  <svg
                    aria-hidden="true"
                    fill="none"
                    focusable="false"
                    height="1em"
                    role="presentation"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    viewBox="0 0 24 24"
                    width="1em"
                  >
                    <path d="m6 9 6 6 6-6" />
                  </svg>
                }
                radius="full"
                size="sm"
                variant="light"
              >
                <div className="text-xl font-semibold flex-1 flex">
                  {isEnrolled ? (
                    <span className="flex justify-center p-1 items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="#4CAF50
"
                        class="bi bi-circle-fill"
                        viewBox="0 0 16 16"
                      >
                        <circle cx="8" cy="8" r="8" />
                      </svg>
                    </span>
                  ) : (
                    <span className="flex justify-center p-1 items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="#F44336
"
                        class="bi bi-circle-fill"
                        viewBox="0 0 16 16"
                      >
                        <circle cx="8" cy="8" r="8" />
                      </svg>
                    </span>
                  )}
                  Student
                </div>
              </Button>
            </DropdownTrigger>
            <DropdownMenu aria-label="Routes">
              <DropdownItem
                endContent={
                  <span className="flex justify-center p-1 items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      fill="#4CAF50
"
                      class="bi bi-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <circle cx="8" cy="8" r="8" />
                    </svg>
                  </span>
                }
                onPress={() => {
                  setisEnrolled(true);
                }}
              >
                Enrolled
              </DropdownItem>
              <DropdownItem
                endContent={
                  <span className="flex justify-center p-1 items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      fill="#F44336
"
                      class="bi bi-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <circle cx="8" cy="8" r="8" />
                    </svg>
                  </span>
                }
                onPress={() => {
                  setisEnrolled(false);
                }}
              >
                Un-Enrolled
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          {isEnrolled && (
            <div className="flex-initial">
              <Filter
                currentcourse={Course}
                updatechanges={CoursenSection}
                isDataLoaded={!isLoading}
                isSearch={searchValue}
              />
            </div>
          )}
        </div>
      </CardHeader>
      <Divider />

      <CardBody>
        {isEnrolled ? (
          <EnrolledStudentTable
            course={Course}
            section={Section}
            status={setIsLoading}
            searchs={setSearchValue}
          />
        ) : (
          <UnenrolledStudentTable />
        )}
      </CardBody>
    </Card>
  );
}
