import { useEffect, useState } from "react";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import { Chip, Spinner, Avatar, Button } from "@nextui-org/react";
import axios from "axios";
import useRealtimeSubscription from "../../../../../utils/AppwriteConfig/RealtimeServices";
import UnassignTeacher from "../../../../../Components/modals/UnassignTeacher";

export default function SubjectTeacher({
  Section,
  id,
  AssignedTeacher,
  name,
  subject,
  accords,
  isCurrentlyActive
}) {
  /**
   *
   * THIS COMPONENT IS USED TO CHECK IF THERES A TEACHER IN A SPECIFIC
   * SUBJECT AND SECTION IN A SPECIFIC COURSE
   *
   */
  const { user, server } = useAuth();

  const [isLoading, setisLoading] = useState(true);
  const [isRefresh, setisRefresh] = useState(true);
  const [MainError, setMainError] = useState();

  const [selectedSection, setselectedSection] = useState(Section);
  const [selectedid, setselectedid] = useState(id);

  const [Teacher, setTeacher] = useState([]);

  useEffect(() => {
    name((prevNames) => ({
      ...prevNames,
      subject: subject.subjectName,
      section: Section.sectionName
    }));
  }, [Section, subject]);

  //Get Subject TEacher
  useEffect(() => {
    console.log(selectedSection.$id, selectedid);
    async function GetTeacher() {
      try {
        setisLoading(true);
        const teacher = await axios.get(`${server}/v1/subject/teacher`, {
          params: {
            CourseId: id.CourseId,
            SubjectId: id.SubjectId,
            SectionId: Section.$id
          }
        });
        console.log(teacher.data);
        setTeacher(teacher?.data.documents[0]);
      } catch (e) {
        setMainError(e.message);
      } finally {
        setisLoading(false);
      }
    }
    GetTeacher();
  }, [Section, id]);

  const realTimeChanges = (changes) => {
    console.log(changes);
  };

  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_TEACHER,
    realTimeChanges
  );

  const AssignTeacher = async () => {
    AssignedTeacher({
      sectionid: Section.$id,
      ...id,
      schedules: Teacher.schedule
      // GroupSchedId: Teacher.$id,
      // CurrentGroupSchedId: Teacher.GroupSchedId,
      // UserId: Teacher.teacher.$id
    });
    document.querySelector("#AssignTeacher").click();
  };
  return (
    <div className=" p-2 border-t-1 w-full flex items-center justify-between">
      {/* When there is no teacher */}
      <div className=" flex items-center flex-wrap">
        <Chip
          variant="flat"
          avatar={
            <Avatar
              getInitials={(name) => name.charAt(0)}
              name="U"
              src={
                Teacher
                  ? "https://i.pravatar.cc/300?u=a042581f4e29026709d"
                  : null
              }
            />
          }
        >
          {isLoading ? (
            <Spinner size="sm" color="secondary"></Spinner>
          ) : (
            <>
              {Teacher?.teacher?.userCredentials?.Username
                ? Teacher?.teacher?.userCredentials?.Username
                : "No Teacher"}
              {" | "}
              {Section?.sectionName}
            </>
          )}
        </Chip>
      </div>
      {isLoading ? (
        <Button
          variant="light"
          isLoading
          isDisabled
          color="secondary"
          className=" justify-end"
        >
          {/* When there is teacher */}
          <span className="sm:flex hidden">loading...</span>
        </Button>
      ) : (
        <div>
          {isCurrentlyActive && (
            <>
              {Teacher?.teacher ? (
                <UnassignTeacher
                  SubjecTeacher={Teacher}
                  AccordionKey={accords}
                />
              ) : (
                <Button
                  variant="light"
                  onPress={() => {
                    console.log(Teacher, "ey");
                    AssignTeacher();
                  }}
                  className=" justify-end"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#4CAF50"
                    class="bi bi-person-plus-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                    <path
                      fill-rule="evenodd"
                      d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5"
                    />
                  </svg>
                  <span className="sm:flex hidden">Add Teacher</span>
                </Button>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}
