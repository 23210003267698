import { Skeleton, Tooltip } from "@nextui-org/react";
import SectionCapacityChecker from "./SectionCapacityChecker";

export default function Sectioncard({ data, coursedata, loadingstate }) {
  return (
    <Tooltip content={`View ${data?.sectionName} Details`}>
      <div className=" w-full h-auto p-2 py-4 rounded-lg transition-transform duration-300 transform hover:scale-105 flex justify-between items-center bg-white border-1 shadow-md">
        {loadingstate ? (
          <Skeleton isLoaded={!loadingstate} className="rounded-full">
            <span className=" border-l-1 pl-2 border-warning">
              {data?.sectionName ? data?.sectionName : "Section"}
            </span>
          </Skeleton>
        ) : (
          <span className=" border-l-1 pl-2 border-warning">
            {data?.sectionName ? data?.sectionName : "Section"}
          </span>
        )}

        <Skeleton isLoaded={!loadingstate} className="rounded-full">
          {data && data.rooms && Object.keys(data.rooms).length > 0 ? (
            <SectionCapacityChecker
              sectionid={data?.$id}
              courseid={coursedata?.$id}
              total={data.rooms.capacity}
            />
          ) : (
            <span className=" text-xs text-warning-600">No Room yet</span>
          )}
        </Skeleton>
      </div>
    </Tooltip>
  );
}
