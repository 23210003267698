import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Divider,
  Link,
  Image,
  Button,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Skeleton
} from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../utils/AppwriteConfig/AuthContext";
import { useEffect, useState } from "react";
import { Time } from "@internationalized/date";
import axios from "axios";
export default function UpcomingClass() {
  const navigate = useNavigate();
  const { user, role, DataServer } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [MainError, setMainError] = useState("");
  const [isRefresh, setIsRefresh] = useState(true);

  const [currentDate, setCurrentDate] = useState({
    time: "",
    day: ""
  });

  const [schedule, setSchedule] = useState({});

  useEffect(() => {
    // Function to get the current time in the {hour, minute} format
    const updateDateTime = () => {
      const now = new Date();
      const hour = now.getHours();
      const minute = now.getMinutes();
      const day = now.toLocaleDateString("en-US", { weekday: "long" }); // Current day (e.g., "Monday")

      // Update the state with the current hour and minute
      setCurrentDate({
        time: {
          hour,
          minute
        },
        day: day
      });
    };

    // Function to calculate the remaining seconds to the next minute
    const calculateRemainingSeconds = () => {
      const now = new Date();
      const seconds = now.getSeconds();
      const remainingSeconds = 60 - seconds; // Time remaining until the next minute
      return remainingSeconds;
    };

    // Initial call to set the current time
    updateDateTime();

    // Calculate the remaining seconds to the next minute and set a timeout
    const remainingSeconds = calculateRemainingSeconds();
    const initialTimeout = setTimeout(() => {
      // Update the time once at the start of the next minute
      updateDateTime();

      // Set an interval to update the time every minute (60 seconds) from now
      const intervalId = setInterval(updateDateTime, 60000);

      // Clear the interval when the component unmounts
      return () => clearInterval(intervalId);
    }, remainingSeconds * 1000);

    // Cleanup the initial timeout when the component unmounts
    return () => clearTimeout(initialTimeout);
  }, []);

  // Get schedule
  useEffect(() => {
    async function scheduleResponse() {
      try {
        setIsLoading(true);
        
        const scheduleResponse = await axios.get(
          `${DataServer}/user/${role}/${user.$id}/schedule/now`,
          { params: currentDate }
        );
       
        setSchedule(scheduleResponse.data);
    
      } catch (error) {
        console.log(error);
        setMainError(error.message);
      } finally {
        setIsLoading(false);
      }
    }
    if (currentDate.time.hour > 0 && currentDate.day !== "" && !isLoading)
      scheduleResponse();
  }, [isRefresh, currentDate]);

  //REaltime

  return (
    <Card className="rounded-md shadow-sm h-full ">
      <CardHeader className="flex gap-3 justify-between">
        <div className="flex gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="lucide lucide-calendar-clock"
          >
            <path d="M21 7.5V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h3.5" />
            <path d="M16 2v4" />
            <path d="M8 2v4" />
            <path d="M3 10h5" />
            <path d="M17.5 17.5 16 16.3V14" />
            <circle cx="16" cy="16" r="6" />
          </svg>
          <p className="text-lg">On-Going Class</p>
        </div>
        <button
          onClick={() => {
            navigate(`/user/${role}/view/schedule`);
          }}
          className=" bg-[#FFDE59] text-sm rounded-lg py-2 px-3"
        >
          View Schedule
        </button>
      </CardHeader>
      <Divider />
      <CardBody
        className={`${
          isLoading && Object.keys(schedule).length === 0 ? "p-2" : "p-0"
        }`}
      >
        {isLoading && Object.keys(schedule).length === 0 ? (
          <Skeleton className=" w-full h-[80px] rounded-md"></Skeleton>
        ) : (
          <>
            {Object.keys(schedule).length > 0 ? (
              <Table
                color="secondary"
                radius="none"
                shadow="none"
                className="border-0 rounded-none"
                classNames={{ th: " bg-secondary-200 text-secondary-700" }}
                aria-label="Example static collection table"
              >
                <TableHeader className=" bg-secondary-500">
                  <TableColumn>Time</TableColumn>
                  <TableColumn>Subject</TableColumn>

                  <TableColumn>Teacher</TableColumn>
                </TableHeader>
                <TableBody>
                  <TableRow key="1">
                    <TableCell className=" min-w-[140px]">
                      {schedule.time}
                    </TableCell>
                    <TableCell>{schedule.subjectName}</TableCell>
                    <TableCell>{schedule.teacherName}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            ) : (
              <div className=" flex w-full gap-2 p-2 h-[80px] text-secondary-400 justify-center items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-calendar-x-2"
                >
                  <path d="M8 2v4" />
                  <path d="M16 2v4" />
                  <path d="M21 13V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h8" />
                  <path d="M3 10h18" />
                  <path d="m17 22 5-5" />
                  <path d="m17 17 5 5" />
                </svg>
                <span className=" text-black">No Class for now...</span>
              </div>
            )}
          </>
        )}
      </CardBody>
    </Card>
  );
}
