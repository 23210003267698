import { Button, Card, CardBody, CardFooter, Image } from "@nextui-org/react";
import { useAuth } from "../../../../utils/AppwriteConfig/AuthContext";
import { useEffect, useState } from "react";
import axios from "axios";
import CardSkeleton from "../../../../Components/skeleton/Cards";
import useRealtimeSubscription from "../../../../utils/AppwriteConfig/RealtimeServices";
import Error from "../../../../Components/ErrorIndicator";
import Main from "../../../Layout/Main";
import GetTeacher from "./components/GetTeacher";

export default function EnrolledSubjects() {
  const { server, user } = useAuth();

  const [isLoading, setisLoading] = useState(false);
  const [Refresh, setRefresh] = useState(false);
  const [MainError, setMainError] = useState();
  const [Enrolled, setEnrolled] = useState([]);

  const [EnrolledSubjects, setEnrolledSubjects] = useState([]);

  useEffect(() => {
    async function GetSubjects() {
      try {
        setisLoading(true);
        setEnrolled([]);
        setEnrolledSubjects([]);
        const enrollement = await axios.get(`${server}/v1/enrolled/student`, {
          params: { accid: user.$id }
        });
        setEnrolled({
          course: {
            id: enrollement.data.documents[0]?.course.$id,
            name: enrollement.data.documents[0]?.course.courseName
          },
          section: {
            id: enrollement.data.documents[0]?.section?.$id,
            name: enrollement.data.documents[0]?.section?.sectionName
          }
        });
        setEnrolledSubjects(
          enrollement.data.documents[0]?.course?.subject
            ? enrollement.data.documents[0]?.course?.subject
            : []
        );
      } catch (e) {
        console.log(e.message);
        setMainError(e.message);
      } finally {
        setisLoading(false);
      }
    }
    GetSubjects();
  }, [Refresh]);

  const handleRealtimeChanges = (changes) => {
 
    setRefresh(!Refresh);
  };

  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_COURSE,
    handleRealtimeChanges
  );

  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_ENROLLMENT,
    handleRealtimeChanges
  );

  return (
    <div className="flex flex-1 flex-col ">
      {MainError && <Error errorMessage={MainError} />}
      <div className="flex-initial flex flex-col justify-between p-1 pt-6">
        <span className=" text-4xl font-semibold text-[#72539b]">
          Enrolled Subjects
        </span>
        <div className=" text-lg font-medium flex justify-end items-end">
          {Enrolled?.course && Enrolled?.section?.name ? (
            <span className="mr-4">
              {Enrolled?.course?.name} | {Enrolled?.section?.name}
            </span>
          ) : (
            <span className="mr-4">
              {!isLoading && <>{Enrolled?.course?.name} | No section yet</>}
            </span>
          )}
        </div>
      </div>

      <div className="flex-1 overflow-auto p-1 bg-white  rounded-lg  border-1 shadow-sm">
        <div
          className={`${
            EnrolledSubjects.length > 0
              ? "grid p-3 gap-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
              : "flex max-h-full h-full"
          }  overflow-auto`}
        >
          {isLoading ? (
            <>
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
            </>
          ) : (
            <>
              {EnrolledSubjects && EnrolledSubjects.length > 0 ? (
                EnrolledSubjects.map((subject) => (
                  <Card
                    shadow="none"
                    className=" rounded-md min-h-44 max-h-52 overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
                  >
                    <Image
                      removeWrapper
                      alt="Card background"
                      className="z-0 w-full h-full object-cover"
                      src={require("../../../../Img/BSA.jpg")}
                    />
                    <CardFooter className="absolute bg-[#72539b]/80 bottom-0 z-10 rounded-md">
                      <div className="flex flex-grow gap-2 items-center">
                        <div className="flex flex-col">
                          <p className=" text-lg text-white/90">
                            {subject.subjectName}
                          </p>
                          <GetTeacher
                            courseid={Enrolled.course.id}
                            sectionid={Enrolled.section.id}
                            subjectid={subject.$id}
                          />
                        </div>
                      </div>
                    </CardFooter>
                  </Card>
                ))
              ) : (
                <div className=" w-full h-full flex flex-col justify-center items-center">
                  <div className=" max-w-[300px] max-h-[300px] ">
                    <img
                      className=" w-full h-full bg-cover object-cover"
                      alt="study"
                      src={require("../../../../Img/online-learning-concept.png")}
                    />
                  </div>
                  <span className=" font-medium text-medium">
                    {" "}
                    Not Enrolled Yet
                  </span>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
