import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  Popover,
  PopoverTrigger,
  PopoverContent
} from "@nextui-org/react";
import CoursesTooltip from "./renders/CoursesTooltip";
import { useEffect, useState } from "react";
import TecaherSuitabilityTooltip from "./renders/TeacherSuitabilityTooltip";

export default function AssignedSubjectsTeachers({
  Data = [],
  updater,
  factors
}) {
  // State for storing the data to be displayed in the table
  const [datas, setDatas] = useState(Data);
  // State for storing the total time calculation
  const [totaltime, setTotaltime] = useState(0);

  // Effect to update datas state when Data prop changes
  useEffect(() => {
    setDatas(Data);
  }, [Data]);

  // Effect to calculate total time whenever datas state changes
  useEffect(() => {
    const calculateTotalTime = () => {
      return datas.reduce((total, load) => {
        const loadTotalTime = load.totalSubjectTimeInAWeek * load.load.length;
        return total + loadTotalTime;
      }, 0);
    };

    setTotaltime(calculateTotalTime());
  }, [datas]);

  return (
    <div className="flex gg  flex-col">
      <Table
        removeWrapper
        className="px-4 py-2"
        aria-label="Example static collection table"
      >
        <TableHeader>
          <TableColumn className="font-semibold text-black text-center">
            SUBJECT
          </TableColumn>
          <TableColumn className="text-center">LOAD</TableColumn>
          <TableColumn className="text-center">MINUTES</TableColumn>
          <TableColumn className="text-center">DAYS</TableColumn>
          <TableColumn className="text-center">TOTAL</TableColumn>
        </TableHeader>
        <TableBody>
          {datas &&
            datas.map((subject, index) => (
              <TableRow key={subject.subjectId + index} className="">
                <TableCell className="border-r-1 w-[250px]">
                  <div className="rounded-md p-2 flex items-center">
                    <span className="font-normal flex-1">
                      {subject.subjectName}
                    </span>
                    <div className="  hidden md:flex">
                      <Tooltip
                        className="p-0 m-0 flex-initial"
                        placement="bottom"
                        showArrow
                        content={
                          <TecaherSuitabilityTooltip
                            suitability={subject}
                            currentFactors={factors}
                          />
                        }
                      >
                        <svg
                          className="ml-2"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-question-circle"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                          <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94" />
                        </svg>
                      </Tooltip>
                    </div>
                    <div className="flex md:hidden">
                      <Popover placement="bottom">
                        <PopoverTrigger>
                          <svg
                            className="ml-2"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-question-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                            <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94" />
                          </svg>
                        </PopoverTrigger>
                        <PopoverContent className="p-0">
                          <TecaherSuitabilityTooltip
                            suitability={subject}
                            currentFactors={factors}
                          />
                        </PopoverContent>
                      </Popover>
                    </div>
                  </div>
                </TableCell>
                <TableCell className="border-r-1">
                  <div className="  p-2 hidden md:flex">
                    <Tooltip
                      className="p-0 m-0"
                      placement="right"
                      closeDelay={0}
                      content={
                        <span class=" z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm dark:bg-neutral-700">
                          <div className="px-1 py-2 flex flex-col space-y-2 text-meduim font-poppins">
                            {subject.load.map((data) => (
                              <span className=" border-l-3 pl-1 border-[#72539bcc]">
                                {`${data.courseName} | ${data.sectionName}`}
                              </span>
                            ))}
                          </div>
                        </span>
                      }
                    >
                      <div className=" rounded-md p-2 w-full flex flex-col">
                        <span className=" font-medium bg-slate-200 rounded-lg p-2">
                          {subject.load.length}
                        </span>
                      </div>
                    </Tooltip>
                  </div>
                  <div className="flex md:hidden">
                    <Popover placement="bottom">
                      <PopoverTrigger>
                        <div className=" rounded-md p-2 flex flex-col">
                          <span className=" font-medium bg-slate-200 rounded-lg p-2">
                            {subject.load.length}
                          </span>
                        </div>
                      </PopoverTrigger>
                      <PopoverContent className="p-0">
                        <span class=" z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm dark:bg-neutral-700">
                          <div className="px-1 py-2 flex flex-col space-y-2 text-meduim font-poppins">
                            {subject.load.map((data) => (
                              <span className=" border-l-3 pl-1 border-[#72539bcc]">
                                {`${data.courseName} | ${data.sectionName}`}
                              </span>
                            ))}
                          </div>
                        </span>
                      </PopoverContent>
                    </Popover>
                  </div>
                </TableCell>
                <TableCell className="border-r-1">
                  <div className="rounded-md p-2 flex flex-col">
                    <span className="font-normal">
                      {subject.subjectTimePerDay}
                    </span>
                  </div>
                </TableCell>
                <TableCell className="border-r-1">
                  <div className="rounded-md p-2 flex flex-col">
                    <span className="font-normal">
                      {subject.subjectDaysInAWeek}
                    </span>
                  </div>
                </TableCell>
                <TableCell>
                  <div className="rounded-md p-2 flex flex-col">
                    <span className="font-normal">
                      {subject.totalSubjectTimeInAWeek * subject.load.length}
                    </span>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          <TableRow key="total" className="">
            <TableCell className="border-r-1"></TableCell>
            <TableCell className="border-r-1 font-medium rounded-s-lg ">
              TOTAL
            </TableCell>
            <TableCell className="border-r-1 "></TableCell>
            <TableCell className="border-r-1 "></TableCell>
            <TableCell className="rounded-e-lg font-medium pl-5">
              {totaltime}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}
