import logo from "./logo.svg";
import "./App.css";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { account, client } from "./utils/AppwriteConfig/Config"; // Import both client and account
import PrivateRoutes from "./utils/AppwriteConfig/PrivateRoutes";
import { AuthProvider, useAuth } from "./utils/AppwriteConfig/AuthContext";
import PagenotFound from "./pages/Statuspages/PagenotFound";
import Loggedin from "./pages/LoggedIn";
import EdusyncLoading from "./pages/Statuspages/EdusyncLoading";
import Employee from "./pages/{Employee}/Employee";
import Admission from "./pages/LandingPage/components/Admission";
import Landing from "./pages/LandingPage/Landing";
import ArchivedUser from "./pages/Statuspages/AccountHasBeenArchived";
import TRYLANG from "./pages/Statuspages/TRYPAGE";

function App() {
  const { user, Loading, prelineReload } = useAuth();
  const location = useLocation();

  useEffect(
    () => {
      require("preline/preline");
    },
    [location.pathname, user, prelineReload]
  );

  useEffect(
    () => {
      if (window.HSStaticMethods) {
        window.HSStaticMethods.autoInit();
      }
    },
    [location.pathname, user]
  );

  return (
    <div className="font-poppins h-screen w-screen">
      <Routes>
        <Route path="/" element={<EdusyncLoading />} />
        <Route path="/trysddss" element={<TRYLANG />} />
        <Route path="/login" element={<Landing />} />
        <Route path="/admission" element={<Admission />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/user/*" element={<Loggedin />} />
        </Route>
        <Route path="/archived" element={<ArchivedUser />} />
        <Route path="/*" element={<PagenotFound />} />
      </Routes>
    </div>
  );
}

export default App;
