import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  Button,
  Card,
  CardBody,
  CardFooter,
  Image
} from "@nextui-org/react";
import Delete from "../../../../../Components/modals/DeleteConfirm";
import IconDeleteConfirm from "../../../../../Components/modals/IconDeleteConfirm";

export default function PrefferdSubjectCard({
  pstate /**Popover state */,
  setpstate /**Used to change the popover state */,
  data,
  onDelete
}) {
  return (
    <div className="group min-h-[55px]  bg-[#e5dbf9]/90 shadow-md border-1 rounded-lg flex items-center space-x-1 w-full transition-transform duration-300 hover:scale-105">
      {/* Image container */}
      <div className="max-w-[150px] flex-initial h-full">
        <img className="size-full border-0" />
      </div>

      {/* Data Container */}
      <div className="flex-1 py-2 pl-6">
        <span className="text-md font-medium">
          {data?.subject?.subjectName}
        </span>
      </div>

      {/* Delete button
      <div className="flex-initial p-2">
        <IconDeleteConfirm
          RelatedComponentState={pstate}
          SetRelatedComponentState={setpstate}
          data={{ nameToDelete: data?.subject?.subjectName, ...data }}
          isicononly={true}
          route={"teacher/subjects/preffered"}
        />
      </div> */}
    </div>
  );
}
