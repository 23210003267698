import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { account } from "./Config";
import LoginLoading from "../../pages/Statuspages/LoginLoading";
import useRealtimeSubscription from "./RealtimeServices";

const { createContext, useState, useEffect } = require("react");

const AuthContext = createContext();

export const AuthProvider = (props) => {
  const isOnline = true;

  const server = isOnline
    ? "https://edusync-appwrite-server.vercel.app"
    : "http://localhost:5000";

  const SchedulingServer = isOnline
    ? "https://edusync-appwrite.vercel.app"
    : "http://localhost:8000";

  const DataServer = isOnline
    ? "https://edusync-appwrite-data-server.vercel.app"
    : "http://localhost:9000";

  const [Loading, setLoading] = useState({
    isLoading: true,
    msg: ""
  });
  const [currentPage, setCurrentPage] = useState("");
  const [prelineReload, setPrelineReload] = useState(false);
  const [isContainerLoading, setisContainerLoading] = useState(false);
  const [isSContainerLoading, setisSContainerLoading] = useState(false);
  const [isItemLoading, setisItemLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [role, setRole] = useState(null);
  const [UmLoad, setUmLoad] = useState(false);
  const [loginerr, setloginerr] = useState({
    show: false,
    err: "gg"
  });
  const navigate = useNavigate();

  //Used to Throw loadingstate through all application(sidebar)
  useEffect(() => {
    setLoading((prevState) => ({
      ...prevState,
      isLoading: false
    }));
  }, []);

  const loginUser = async (userInfo) => {
    setLoading({ isLoading: true, msg: "in" });

    try {
      // Attempt to create a session with the provided email and password
      const loggedIn = await account.createEmailPasswordSession(
        userInfo.email,
        userInfo.password
      );

      const acc = await account.get();
      // If the session creation is successful, update the user state
      setUser(acc);
      setRole(acc.labels[0]);
      setloginerr({
        show: false,
        err: ""
      });
      navigate("/user");
      // Navigate to the "user" route
    } catch (err) {
      // Handle login errors
      console.error("Error logging in:", err);
      setloginerr({
        show: true,
        err: err
      });
    } finally {
      setLoading({ isLoading: false, msg: "" });
    }
  };

  const logoutUser = async () => {
    setLoading({ isLoading: true, msg: "out" });
    await account.deleteSession("current");
    // Set item value to null
    localStorage.removeItem("lastLocation");

    setUser(null);
    setLoading({ isLoading: false, msg: "" });
    navigate("/login");
  };

  async function init() {
    try {
      const lastLocation = localStorage.getItem("lastLocation");

      const loggedIn = await account.get();

      setUser(loggedIn); // this doesnt saved but loggedin have value
      setRole(loggedIn.labels);

      //Get the last location saved
      let path = lastLocation ? lastLocation.split("/") : false;

      //GET THE ROLE OF THE SAVED LOCATION THE CHECKS IF IT CORRESPONDS TO THE LOGGED IN USER
      let isSavedLocationEmployee = path
        ? (loggedIn.labels.includes("Adminhead") ||
            loggedIn.labels.includes("Registrar") ||
            loggedIn.labels.includes("Scheduler")) &&
          path[2] === "employee"
        : false;
      let isSavedLocationNormalUser = path
        ? loggedIn.labels.includes(path[2])
        : false;

      if (isSavedLocationEmployee || isSavedLocationNormalUser) {
        //IF IT CORRESPOND, GO TO NEXT PAGE
        navigate("user");
      } else {
        //IF NOT THEN DELETE THE PATH THEN GO TO THE NEXT PAGE
        localStorage.removeItem("lastLocation");
        navigate("user");
      }
    } catch (err) {
      const lastLocation = localStorage.getItem("lastLocation");
      setUser(null);
      setRole(null); //edited

      //IF THERE IS NO LOGGED IN USER AND THERE IS A SAVAED LOCATION,DELETE THE LOCATION
      if (lastLocation) {
        localStorage.removeItem("lastLocation");
      }
      navigate("/login");
    }
  }

  //Used to CHECK IF THERE IS A LOGGED IN USER
  useEffect(() => {
    init();
  }, []);

  const handleRealtimeMessage = (message) => {
    if (message.changetype === "update" && user.$id === message.payload.$id) {
      if (message.deleteFlag && message.deleteFlag === true) {
        navigate("/archived");
      }
    } else if (
      message.changetype === "delete" &&
      user.$id === message.payload.$id
    ) {
    }
  };

  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_USERCRED,
    handleRealtimeMessage
  );

  const contextdata = {
    user,
    role,
    SchedulingServer,
    DataServer,
    server,
    loginerr,
    UmLoad,
    isContainerLoading,
    isSContainerLoading,
    isItemLoading,
    prelineReload,
    currentPage,
    setCurrentPage,
    setPrelineReload,
    loginUser,
    logoutUser,
    setUmLoad,
    setloginerr,
    setUser,
    setisContainerLoading,
    setisItemLoading,
    setisSContainerLoading
  };
  return (
    <AuthContext.Provider value={contextdata}>
      {Loading.isLoading ? <LoginLoading msg={Loading.msg} /> : props.children}
    </AuthContext.Provider>
  );
};
export const useAuth = () => {
  return useContext(AuthContext);
};
export default AuthContext;
